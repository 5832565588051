import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import {SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminEmailSetupCard } from "../AdminEmailSetupCard/AdminEmailSetupCard";

export const AdminEmailSetupList : React.FC = () => {
    const cardRef = useRef<PageContainerBase>(null);
    const navigate = useNavigate();

    return (
        <>
            <PageContainer endpoint="/odata/email/setup" pageType={SystemPageType.List} title="Email Setup" backAllowed insertAllowed updateAllowed deleteAllowed cardRef={cardRef}>               
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry iconName="WebTemplate" name="OpenStructureTemplate" label="Struttura" onClick={(req:any)=>{
                            navigate("/app/admin/administrative/emailStructure");
                        }}>                            
                        </ActionEntry>
                        <ActionEntry iconName="Mail" name="OpenEmailTemplate" label="Template" onClick={(req:any)=>{
                            navigate("/app/admin/administrative/emailTemplate");
                        }}>
                        </ActionEntry>
                        <ActionEntry iconName="MailTentative" name="OpenEmailLog" label="Log" onClick={(req:any)=>{
                            navigate("/app/admin/administrative/emailLog");
                        }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>                    
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Legal entity" fieldName="legalEntityCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Distributor" fieldName="distributorCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Partner business contract" fieldName="partnerBusinessContractCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Default host" fieldName="defaultHost" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Default port" fieldName="defaultPort" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Default email address" fieldName="defaultEmailAddress" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Default email password" fieldName="defaultEmailPassword" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Default display name" fieldName="defaultDisplayName" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Enable SSL" fieldName="enableSsl" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="User password template code" fieldName="userPasswordTemplateCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Tenant Id" fieldName="tenantId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Client Id" fieldName="clientId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Client Secret" fieldName="clientSecret" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Scopes" fieldName="scopes" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminEmailSetupCard ref={cardRef}/>
        </>
    );
}