import { DefaultButton, Dialog, DialogFooter, IModalProps, PrimaryButton, Stack } from "@fluentui/react";
import { Guid } from "guid-typescript";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Ticket } from "../../../Model/SystemModels";
import { PartnerCreateTicketForm } from "../PartnerCreateTicketForm/PartnerCreateTicketForm";

export interface ICustomPartnerIncidentCardProps {
    showCard: boolean;
    saveInProgress: boolean;
    record: Ticket;
    fromBC?: boolean;
    onCancel: () => void;
    onSave: (record: Ticket) => void;
}

export class CustomAttachment {
    id: string = Guid.create().toString();
    fileExtension: string = "";
    fileName: string = "";
    fileBlob: string = "";
}

export const CustomPartnerIncidentCard: React.FC<ICustomPartnerIncidentCardProps> = (props) =>  {
    const [record, setRecord] = useState(new Ticket());
    
    const pageCardModalProps: Partial<IModalProps> = {
        isBlocking: true,
        dragOptions: undefined,
        styles: {
            main: {
                width: '95% !important',
                maxWidth: 'calc(95%) !important',
                position: 'absolute',
                top: 50
            }
        }
    };

    useEffect(()=>{
        if(props.showCard)
            setRecord(props.record);
    },[props.showCard])
    
    return (
        <Dialog hidden={!props.showCard}
            modalProps={pageCardModalProps}
            onDismiss={props.onCancel}
            dialogContentProps={{
                title: "Ticket - " + record.ticketInfo.title
            }}
        >
            <PartnerCreateTicketForm
                showForm={props.showCard}
                record={record}
                saveInProgress={props.saveInProgress}
                fromBC={props.fromBC}
                customActionEnabled={false}
                onCreateTicket={props.onSave}
                onClosePage={props.onCancel}
                onSetRecord={(record:Ticket)=>{setRecord(record);console.log("Passed record: ",record)}}
            />
            <Stack.Item key="modalFooterContent">
                <DialogFooter>
                    <DefaultButton text={t('common:PageContainer:card:defaultButtonText')!} onClick={props.onCancel} disabled={props.saveInProgress} />
                    <PrimaryButton text={t('common:PageContainer:card:primaryButtonText')!} onClick={() => props.onSave(record)} disabled={props.saveInProgress} />
                </DialogFooter>
            </Stack.Item>
        </Dialog>
    );
}