import { DirectionalHint, IButtonProps, TeachingBubble } from "@fluentui/react";
import React from "react";

export interface ITeachingGuideProps {
    primaryButtonProps: IButtonProps;
    secondaryButtonProps?: IButtonProps | undefined;
    title: string;
    footerContent: string;
    targetId: string;
    direction: DirectionalHint;
    onDismissDialog: (ev: any) => void;
}

export const TeachingGuide: React.FC<ITeachingGuideProps> = (props) => {

    if (props.secondaryButtonProps != undefined)
    {
      return (
          <TeachingBubble
            target={`#${props.targetId}`}
            primaryButtonProps={props.primaryButtonProps}
            secondaryButtonProps={props.secondaryButtonProps}
            headline={props.title}
            footerContent={props.footerContent}
            calloutProps={{
                directionalHint: props.direction
            }}
            onDismiss={props.onDismissDialog}
          >
            {props.children}
          </TeachingBubble>
      );
    }
    else
    {
      return (
        <TeachingBubble
          target={`#${props.targetId}`}
          primaryButtonProps={props.primaryButtonProps}
          headline={props.title}
          footerContent={props.footerContent}
          calloutProps={{
              directionalHint: props.direction
          }}
          onDismiss={props.onDismissDialog}
        >
          {props.children}
        </TeachingBubble>
      );
    }

}