import { useRef } from "react";
import { SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminSystemPermissionCategoryCard } from "../AdminSystemPermissionCategoryCard/AdminSystemPermissionCategoryCard";

export const AdminSystemPermissionCategoryList : React.FC = () => {
    
    const cardRef = useRef<PageContainerBase>(null);
    
    return (
        <>
            <PageContainer endpoint="/odata/system/permission/category" pageType={SystemPageType.List} title="Lista System Permission Category" backAllowed insertAllowed updateAllowed deleteAllowed cardRef={cardRef}>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Codice categoria" fieldName="categoryCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="App Id" fieldName="appId" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Codice ruolo" fieldName="roleCode" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminSystemPermissionCategoryCard ref={cardRef} />
        </>
    );
}