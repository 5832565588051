import { AnimationClassNames, CompoundButton, Icon, Label, mergeStyles, Stack } from '@fluentui/react';
import { Module } from '../../../Model/DemoModels';

export interface ModuleBoxProps {
    sourceModule: Module;
    selected: boolean;
    customStyle?:any,
    onSelect: (module: Module) => void;
}

const moduleBoxStyle = mergeStyles([{
    minWidth: '210px',
    margin: '10px'
},
AnimationClassNames.scaleUpIn100]);

const ModuleBox = (props: ModuleBoxProps) => {
    if (props.selected)
    {
        return (
            <Stack onClick={() => props.onSelect(props.sourceModule)} key={props.sourceModule.code} styles={{
                root: {
                    width: props.customStyle ? props.customStyle.customWidth : 160,
                    height: props.customStyle ? props.customStyle.customHeight : 110,
                    border: '1px solid rgba(0, 0, 0, 0.2)',
                    margin: 5,
                    borderRadius: 8,
                    backgroundColor: '#29508f',
                    color: 'white',
                    boxShadow: '0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%)',
                    selectors: {
                        "&:hover": {
                            cursor: 'pointer',
                            color: 'white',
                            backgroundColor: '#29508f'
                        }
                    }
                }
            }}>
                <Stack.Item styles={{
                    root: {
                        fontSize: props.customStyle ? props.customStyle.fontSize :45,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingTop: 15,
                    }
                }}>
                    <Icon iconName={props.sourceModule.presaleIcon}></Icon>
                </Stack.Item>
                <Stack.Item style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    padding: 5
                }}>
                    <Label style={{
                        fontSize: 12,
                        color: 'inherit'
                    }}>{props.sourceModule.descriptionCaption}</Label>
                </Stack.Item>
            </Stack>
        );
    }
    else
    {
        return (
            <Stack onClick={() => props.onSelect(props.sourceModule)} key={props.sourceModule.code} styles={{
                root: {
                    width: props.customStyle ? props.customStyle.customWidth : 160,
                    height: props.customStyle ? props.customStyle.customHeight : 110,
                    border: '1px solid rgba(0, 0, 0, 0.2)',
                    margin: 5,
                    borderRadius: 8,
                    color: 'black',
                    boxShadow: '0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%)',
                    selectors: {
                        "&:hover": {
                            cursor: 'pointer',
                            color: 'white',
                            backgroundColor: '#29508f'
                        }
                    }
                }
            }}>
                <Stack.Item styles={{
                    root: {
                        fontSize: props.customStyle ? props.customStyle.fontSize :45,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingTop: 15,
                    }
                }}>
                    <Icon iconName={props.sourceModule.presaleIcon}></Icon>
                </Stack.Item>
                <Stack.Item style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    padding: 5
                }}>
                    <Label style={{
                        fontSize: 12,
                        color: 'inherit'
                    }}>{props.sourceModule.descriptionCaption}</Label>
                </Stack.Item>
            </Stack>
        );
    }
}

export default ModuleBox;