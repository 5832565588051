import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminSystemEventParameterCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda System Event Setup"} endpoint="odata/system/event/parameter" ref={ref}>
            <CardGroup name="general" label="General" isOpen={true}>
                <CardField name="eventName" type={SystemFieldType.String} label="Nome Evento" />
                <CardField name="parameterName" type={SystemFieldType.String} label="Nome Parametro" />
                <CardField name="type" type={SystemFieldType.String} label="Tipo" />
                <CardField name="isEntity" type={SystemFieldType.Boolean} label="Entità" />
                <CardField name="entityName" type={SystemFieldType.String} label="Nome Entità" />
            </CardGroup> 
        </PageContainer>
    );
});