import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { SystemCore } from "../../../../Core/System/SystemCore";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import {SystemActionCategory, SystemDataLoadingStatus, SystemOperation, SystemPageType } from "../../../../Model/SystemModels";
import { Loader } from "../../../Common/Loader/Loader";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminCSPSetupCard } from "../AdminCSPSetupCard/AdminCSPSetupCard";

export const AdminCSPSetupList : React.FC = () => {

    const cardRef = useRef<PageContainerBase>(null);
    const [dataStatus,setDataStatus] = useState(SystemDataLoadingStatus.Loaded);
    const [loaderMessage,setLoaderMessage] = useState("");
    const adminService = new AdminService();
    const navigate = useNavigate();

    const downloadCSPCustomersSubscriptions = () => {
        adminService.downloadCSPCustomersSubscriptions().then((resp:string)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Success,resp);
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error,jqXHR.message);
        })
    }

    const downloadCSPInvoices = () => {
        adminService.downloadCSPInvoices().then((resp:string)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Success,resp);
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error,jqXHR.message);
        })
    }

    const downloadCSPProducts = () => {
        adminService.downloadCSPProducts().then((resp:string)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Success,resp);
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error,jqXHR.message);
        })
    }    
 
    if (!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={loaderMessage}></Loader>;
    else
        return (
            <>
                <PageContainer endpoint="/odata/CSP/setup" pageType={SystemPageType.List} title="CSP setup" backAllowed insertAllowed updateAllowed cardRef={cardRef}
                    onNewRecord={() => {
                        cardRef.current?.openPage(SystemOperation.Insert);
                        cardRef.current?.setRecord({
                            languageCode: ''
                        });
                    }}>
                    <PageDefinitionContainer type={ContainerType.Action}>
                        <ActionArea category={SystemActionCategory.Navigation}>
                            <ActionEntry name="SubscriptionMatch" label="Match Sottoscrizioni" iconName="Processing" onClick={()=>{
                                    navigate("/app/admin/administrative/customerSubscriptionMatch");
                                }}>
                            </ActionEntry>
                        </ActionArea>
                        <ActionArea category={SystemActionCategory.Process}>
                            <ActionEntry name="cspChallenge" label="Challenge CSP" iconName="AuthenticatorApp" runOnRec onClick={(req: any)=>{
                                adminService.cspChallenge(req)
                                .then((resp) => {
                                    window.open(resp);
                                })
                                .catch((err) => {
                                    ToastService.showMessage(MessageType.Error, "Errore durante il challenge: " + err.message);
                                })
                            }}>
                            </ActionEntry>
                            <ActionEntry name="DownloadCSPCustomersSubscriptions" label="Sincronizza Clienti e Sottoscrizioni CSP" iconName="UserSync" onClick={(req:any)=>{
                                setDataStatus(SystemDataLoadingStatus.ToLoad)
                                setLoaderMessage("Sincronizzazione Clienti e Sottoscrizioni CSP in corso, Attendere...");
                                downloadCSPCustomersSubscriptions();
                            }}>
                            </ActionEntry>
                            <ActionEntry name="DownloadCSPInvoices" label="Sincronizza Fatture CSP" iconName="M365InvoicingLogo" onClick={(req:any)=>{
                                setDataStatus(SystemDataLoadingStatus.ToLoad)
                                setLoaderMessage("Sincronizzazione Fatture CSP in corso, Attendere...");
                                downloadCSPInvoices();
                            }}>
                            </ActionEntry>
                            <ActionEntry name="DownloadCSPProducts" label="Sincronizza Prodotti CSP" iconName="Product" onClick={(req:any)=>{
                                setDataStatus(SystemDataLoadingStatus.ToLoad)
                                setLoaderMessage("Sincronizzazione Prodotti CSP in corso, Attendere...");
                                downloadCSPProducts();
                            }}>
                            </ActionEntry>
                        </ActionArea>
                    </PageDefinitionContainer>                    
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry fieldName="legalEntityCode" name="Legal entity" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="distributorCode" name="Distributore" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="partnerBusinessContractCode" name="Partner business contract" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="languageCode" name="Lingua" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="clientId" name="Id client" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="clientSecret" name="Secret" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="domain" name="Dominio" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="tenantId" name="Tenant id" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="redirectUrl" name="Redirec URL" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="audience" name="Audience" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="scope" name="Scope" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="aadInstance" name="Istanza AAD" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="authType" name="Auth type" minWidth={150} maxWidth={250} onRender={(item: any, index, column) => {
                            if (item.authType === 0)
                                return "No auth"
                            else if (item.actionType === 1)
                                return "Credential"
                            else if (item.actionType === 1)
                                return "Custom"
                            else if (item.actionType === 1)
                                return "Autorization code"
                        }} />
                        <ListHeaderEntry fieldName="batchQueryChunks" name="Batch Query Chunks" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="defaultCountryCode" name="Default Country Code" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="defaultProductTargetView" name="Default Product Target View" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="defaultCulture" name="Default Culture" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="defaultLanguage" name="Default Language" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="defaultAgreementType" name="Default Agreement Type" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="generateMissingSubscriptionItem" name="Generate Missing Subscription Item" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="gdapBaseUrl" name="GDAP base URL" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="manageUserLicense" name="Gestisci licenze utenti" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="gdapAuthenticationSetupCode" name="Authentication Setup Code" minWidth={100} maxWidth={200} />
                    </PageDefinitionContainer>
                </PageContainer>
                <AdminCSPSetupCard ref={cardRef}/>
            </>
        );
}