import { ICommandBarItemProps,mergeStyles,PrimaryButton,Stack} from "@fluentui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PartnerService } from "../../../ApplicationCode/Partner/PartnerService";
import { SessionStateContext } from "../../../Core/State/SessionStateContext";
import { SystemCore } from "../../../Core/System/SystemCore";
import { Lead, PartnerDashboardCue, SharepointFile } from "../../../Model/PartnerModels";
import { SystemDataLoadingStatus, SystemOperation } from "../../../Model/SystemModels";
import { Banner, CueBox, DashboardCarousel, DashboardContentColumn, DashboardContentContainer, DashboardContentRow, DataBox, UserProfileCard } from "../../Common/Dashboard/DashboardComponents";
import { Loader } from "../../Common/Loader/Loader";
import { PageContainerBase } from "../../Common/Template/PageContainer";
import { PartnerLeadCard } from "../PartnerLeadCard/PartnerLeadCard";
import { ActionBar, CarouselItem, DashboardContainer, PartnerSocialSlide, SharepointDocumentList} from "./PartnerDashboardComponents";
import { t } from "i18next";
import { PartnerInformationContext } from "../../../ApplicationCode/Partner/PartnerInformationContext";

const dashboardCarouselClassName = mergeStyles([{
    width: "100%",
    height: "auto",
    position: "relative",
    margin: "auto"
}])

export const PartnerDashboard : React.FC = () => {
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [dashboardCue,setDashboardCue] = useState<PartnerDashboardCue>({} as PartnerDashboardCue);
    const [sharepointFiles, setSharepointFiles] = useState<SharepointFile[]>([] as SharepointFile[]);
    const sessionInfoContext = useContext(SessionStateContext);
    const partnerInfoContext = useContext(PartnerInformationContext);
    const leadCardRef = useRef<PageContainerBase>(null);
    const partnerService = new PartnerService();
    const navigate = useNavigate();

    useEffect(() => {
        if(dataStatus === SystemDataLoadingStatus.ToLoad){
            partnerService.getPartnerDashboardCue()
            .then((resp: PartnerDashboardCue) => {
                setDashboardCue(resp);
                partnerService.getSharepointFiles(resp.partnerId)
                .then((resp: SharepointFile[]) =>{
                    setSharepointFiles(resp);                    
                    setDataStatus(SystemDataLoadingStatus.Loaded);
                })
                .catch(() => {
                    setSharepointFiles([])
                    setDataStatus(SystemDataLoadingStatus.Loaded);
                })
            })
        }
    },[dataStatus])

    const buildSiteUrl = (): string => {
        if (partnerInfoContext.partner.legalEntityCode === 'ESS')
            return "https://www.so-smart.us/";
        else
            return "https://www.so-smart.it/";
    }

    const buildShopUrl = (): string => {
        if (partnerInfoContext.partner.legalEntityCode === 'ESS')
            return process.env.REACT_APP_SHOP_URL+".us/";
        else
            return process.env.REACT_APP_SHOP_URL+".it/";
    }

    const buildMailTo = (): string => {
        if (partnerInfoContext.partner.legalEntityCode === 'ESS')
            return "mailto:orders@so-smart.us";
        else
            return "mailto:orders@so-smart.it";
    }

    //#region ActionProps

    const Items: ICommandBarItemProps[] = [
        {
            key: 'NewLead',
            text: t('partnerCenter:partnerDashboard:actions:newLead')!,
            iconProps: { iconName: 'AddFriend' },
            onClick: () => {
                let newRecord = new Lead();
                newRecord.partnerId = dashboardCue.partnerId;
                newRecord.marketingQualificationCarriedOut = true;
                leadCardRef.current?.setRecord(newRecord);                
                leadCardRef.current?.openPage(SystemOperation.Insert);
            }
        },
        {
            key: 'WebSite',
            text: t('partnerCenter:partnerDashboard:actions:webSite')!,
            iconProps: { iconName: 'Globe' },
            onClick: () => { window.open(buildSiteUrl(),'_blank') }
        },
        {
            key: 'Shop',
            text: t('partnerCenter:partnerDashboard:actions:shop')!,
            iconProps: { iconName: 'ShoppingCart' },
            onClick: () => { window.open(buildShopUrl(),'_blank') }
        },
        {
            key: 'Ticket',
            text: t('partnerCenter:partnerDashboard:actions:incident')!,
            iconProps: { iconName: 'Comment' },
            onClick: () => {navigate("/app/partner/incidents")}
        }
    ]

    //#endregion

    if (! SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={t('partnerCenter:partnerDashboard:loadingMessage')!} />
    else
    {
        return (
            <>
                <DashboardContainer>
                    <ActionBar items={Items}></ActionBar>
                    <Banner 
                        leftContent={
                            <UserProfileCard/>
                        }
                        rightContent={
                            <DashboardCarousel containerClassName={dashboardCarouselClassName} activeSlide={1}>
                                <CarouselItem
                                    title={t('partnerCenter:partnerDashboard:carousel:newLeads')!}
                                    description={t('partnerCenter:partnerDashboard:carousel:newLeadsDescription', {value: dashboardCue.newLead})!}
                                    iconName="UserFollowed"
                                />
                                <CarouselItem
                                    title={t('partnerCenter:partnerDashboard:carousel:newCustomers')!}
                                    description={t('partnerCenter:partnerDashboard:carousel:newCustomersDescription', {value: dashboardCue.newCustomer})!}
                                    iconName="FabricUserFolder"
                                />
                                <CarouselItem
                                    title={t('partnerCenter:partnerDashboard:carousel:turnover', {value: dashboardCue.totalRevenue})!}
                                    description={t('partnerCenter:partnerDashboard:carousel:turnoverDescription', {value: dashboardCue.newRevenue})!}
                                    iconName="Money"
                                />
                                <CarouselItem
                                    title={t('partnerCenter:partnerDashboard:carousel:subscriptions', {value: dashboardCue.totalSubscription})!}                               
                                    description={t('partnerCenter:partnerDashboard:carousel:subscriptionsDescription', {value: dashboardCue.newSubscription})!}
                                    iconName="Subscribe"
                                />
                            </DashboardCarousel>
                        }
                    />
                    <DashboardContentRow id={'1'}>
                        <Stack key={"dashboardVerticalWidgets"} styles={{root: {width: '60%'}}} tokens={{childrenGap: 20}}>
                            <DashboardContentColumn>
                                <DashboardContentContainer title={t('partnerCenter:partnerDashboard:generalIndicators')!} name="generalIndicators">
                                    <Stack horizontal wrap tokens={{childrenGap: 10}}>
                                        <DataBox 
                                            name="ActiveLead"
                                            title={t('partnerCenter:partnerDashboard:activeLeads')!}
                                            iconName="UserFollowed" 
                                            value={dashboardCue.activeLead} 
                                            link={"/app/partner/leads?activeLeads=true"} 
                                        />
                                        <DataBox 
                                            name="TotalLead"
                                            title={t('partnerCenter:partnerDashboard:totalLeads')!}
                                            value={dashboardCue.lead} 
                                            iconName="CheckList" 
                                            link={"/app/partner/leads"} 
                                        />
                                        <DataBox 
                                            name="ActiveCustomer"
                                            title={t('partnerCenter:partnerDashboard:activeCustomers')!}
                                            value={dashboardCue.customer} 
                                            iconName="UserEvent" 
                                            link={buildMailTo()}
                                            openExternalLink 
                                        />
                                    </Stack> 
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                            <DashboardContentColumn>
                                <DashboardContentContainer title={t('partnerCenter:partnerDashboard:yourApps')!} name="appsContainer">
                                    <Stack id="appsSection" horizontal tokens={{
                                        childrenGap: 10
                                    }}>
                                        <CueBox name="Teams" isIconBox title="Teams" iconName="TeamsLogo" link={"https://aka.ms/mstfw?login_hint_safe=" + sessionInfoContext.currentUser.email} />
                                        <CueBox name="Outlook" isIconBox title="Outlook" iconName="OutlookLogo" link={"https://outlook.office365.com/mail/inbox"} />
                                        <CueBox name="OneDrive" isIconBox title="OneDrive" iconName="OneDriveLogo" link={"https://onedrive.live.com/login/"} />
                                        <CueBox name="PowerBi" isIconBox title="PowerBI" iconName="PowerBILogo16" link={"https://app.powerbi.com/home"} />
                                        <CueBox name="BusinessCentral" isIconBox title="Business Central" iconName="Dynamics365Logo" link={"https://businesscentral.dynamics.com/"} />
                                    </Stack>
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                        </Stack>
                        <Stack key={"dashboardSharepointDocs"} styles={{root: {width: '40%'}}} tokens={{childrenGap: 20}}>
                            <DashboardContentColumn>
                                <DashboardContentContainer  title={t('partnerCenter:partnerDashboard:sharepointDocs')!} name="sharepointDocumentation">
                                    <SharepointDocumentList items={sharepointFiles} partnerId={dashboardCue.partnerId} />
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                            <DashboardContentColumn>
                            <DashboardContentContainer name="messageCarouselContainer" title={t('partnerCenter:partnerDashboard:tipsForYou')!}>
                                    <DashboardCarousel containerClassName={dashboardCarouselClassName} activeSlide={1}>
                                        <PartnerSocialSlide />
                                        <Stack key={"message2"} style={{
                                            width: '100%',
                                            display: 'flex',
                                            minHeight: '320px',
                                            padding: 40,
                                            backgroundColor: 'rgb(217, 240, 242)'
                                        }}>
                                            <Stack.Item style={{
                                                fontSize: 32,
                                                padding: 10,
                                                display: 'flex',
                                                alignItems: 'stretch'
                                            }}>
                                                {t('partnerCenter:partnerDashboard:contacts:title')!}
                                            </Stack.Item>
                                            <Stack.Item style={{
                                                fontSize: 18,
                                                padding: 10,
                                                display: 'flex',
                                                alignItems: 'stretch'
                                            }}>
                                                <div>
                                                    {t('partnerCenter:partnerDashboard:contacts:text0001Lbl')!}<br />
                                                    {t('partnerCenter:partnerDashboard:contacts:text0002Lbl')!}
                                                    <br /><br />
                                                    <PrimaryButton
                                                        text={t('partnerCenter:partnerDashboard:contacts:contactUs')!}
                                                        styles={{
                                                            root: {
                                                                backgroundColor: '#008089',
                                                                borderColor: '#008089'
                                                            },
                                                            rootHovered: {
                                                                backgroundColor: 'rgb(1, 93, 99)',
                                                                borderColor: 'rgb(1, 93, 99)'
                                                            }
                                                        }}
                                                        onClick={() => {window.open(buildMailTo(), "_blank");}}
                                                    />
                                                </div>
                                            </Stack.Item>
                                        </Stack>
                                    </DashboardCarousel>
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                        </Stack>
                    </DashboardContentRow>
                </DashboardContainer>
                <PartnerLeadCard ref={leadCardRef} />
            </>
        );
    }
}