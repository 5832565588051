import { DefaultButton, DetailsList, Dialog, DialogFooter, Icon, IModalProps, Label, List, mergeStyles, PrimaryButton, SearchBox, SelectionMode, Stack } from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import { ISessionStateContext, SessionStateContext } from "../../../Core/State/SessionStateContext";
import { MenuEntry, SystemPageCategory, SystemPageType } from "../../../Model/SystemModels";

export interface IFeatureSearchBoxProps {
    showDialog: boolean;
    onSelectFeatute: (feature: MenuEntry) => void;
    onCancelModal: () => void;
}

const modalProps: Partial<IModalProps> = {
    isBlocking: true,
    dragOptions: undefined,
    styles: {
        main: {
            width: '95% !important',
            maxWidth: 'calc(95%) !important',
            top: 20,
            position: 'absolute'
        }
    }
  };
const contentBodyClassName = mergeStyles([{
    width: '100%',
    overflowY: "auto",
    overflowX: "hidden"
}]);

const listContainerClassName = mergeStyles([{
    paddingTop: 10,
    maxHeight: 'calc(100vh - 300px)',
    overflowY: 'auto'
}]);

const itemContainerClassName = mergeStyles([{
    width: '100%',
    border: '1px solid #d9d9d9',
    padding: 10,
    borderRadius: 5,
    cursor: 'pointer',
    selectors: {
        "&:hover": {
            border: '1px solid #303a96',
            backgroundColor: '#f5f5f5'
        }
    }
}]);

const iconContainerClassName = mergeStyles([{
    fontSize: 23,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#303a96',
    marginLeft: 5,
    marginRight: 10,
    cursor: 'pointer'
}]);

const textContentClassName = mergeStyles([{
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}]);

const labelClassName = mergeStyles([{
    cursor: 'pointer',
    fontSize: 14
}]);

const modalContentClassName = mergeStyles([{
    width: '100%'
}]);

const formContentClassName = mergeStyles([{
    width: '100%'
}]);


export const FeatureSearchBox: React.FC<IFeatureSearchBoxProps> = (props) => {
    const [currentItems, setCurrentItems] = useState([] as MenuEntry[]);

    let sessionStateContext: ISessionStateContext = useContext(SessionStateContext);
    
    const onRenderListCell = (item?: MenuEntry | undefined, index?: number | undefined, isScrolling?: boolean | undefined) : JSX.Element => {
        let iconName = "";
        switch(item!.category)
        {
            case SystemPageCategory.Administrator:
                iconName = "Settings";
                break;
            case SystemPageCategory.List:
                iconName = "PageList";
                break;
            case SystemPageCategory.History:
                iconName = "PageHeader";
                break;
        }

        return ( 
            <Stack id={item!.name} 
                   horizontal 
                   className={itemContainerClassName}
                   onClick={() => props.onSelectFeatute(item!)}>
                <Stack.Item className={iconContainerClassName}>
                    <Icon iconName={iconName}/>
                </Stack.Item>
                <Stack.Item className={textContentClassName}>
                    <Label className={labelClassName}>{item!.caption}</Label>
                </Stack.Item>
            </Stack>
        );
    }

    const onSearchBoxChange = (event?: React.ChangeEvent<HTMLInputElement> | undefined, newValue?: string | undefined) => {
        let filteredItems: MenuEntry[] = [];

        if (newValue)
            filteredItems = sessionStateContext.entries.filter(e => 
                e.caption.toLowerCase().indexOf(newValue!.toLocaleLowerCase()) >= 0
                && e.category != SystemPageCategory.None);
        setCurrentItems(filteredItems);
    }

    useEffect(()=>{
        setCurrentItems([]);
    },[props.showDialog])
    
    return (
        <Dialog hidden={! props.showDialog}
                modalProps={modalProps}
                onDismiss={() => {
                    setCurrentItems([]);
                    props.onCancelModal();
                }}
                dialogContentProps={{
                    title: "Ricerca una funzionalità"
                }}>
                    <Stack key="modalContent" className={modalContentClassName}>
                        <Stack.Item key="modalBodyContent" className={contentBodyClassName}>
                            <Stack key="row1" className={formContentClassName}>
                                <SearchBox autoFocus placeholder="Inserisci il nome di una pagina..."
                                           onChange={onSearchBoxChange}  />
                            </Stack>
                            <Stack key="row2" className={formContentClassName}>
                                <List 
                                    onRenderCell={onRenderListCell}
                                    items={currentItems}
                                    className={listContainerClassName} 
                                />
                            </Stack>
                        </Stack.Item>
                    </Stack>
        </Dialog>
    );
}