import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminUserRefreshTokenCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda user token"} endpoint="/odata/user/refresh/token" ref={ref}>
            <CardGroup name="entry" label="Entry" isOpen={true}>
                <CardField name="userName" type={SystemFieldType.String} label="Nome Utente" readonly />
                <CardField name="appTitle" type={SystemFieldType.String} label="App" readonly />
                <CardField name="userId" type={SystemFieldType.Number} label="Id Utente" readonly />
                <CardField name="appId" type={SystemFieldType.String} label="Id App" readonly />
                <CardField name="revokedAccess" type={SystemFieldType.Boolean} label="Accesso Revocato" />
            </CardGroup>
        </PageContainer>
    );
});