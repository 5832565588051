import { useRef, useState } from "react";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { SystemCore } from "../../../../Core/System/SystemCore";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { SystemActionCategory, SystemDataLoadingStatus, SystemPageType } from "../../../../Model/SystemModels";
import { Loader } from "../../../Common/Loader/Loader";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminBookingSetupCard } from "../AdminBookingSetupCard/AdminBookingSetupCard";
import { useNavigate } from "react-router-dom";

export const AdminBookingSetupList : React.FC = () => {
    
    const adminService = new AdminService();
    const [dataStatus,setDataStatus] = useState(SystemDataLoadingStatus.Loaded);
    const [loaderMessage,setLoaderMessage] = useState("");
    const cardRef = useRef<PageContainerBase>(null);
    const navigate = useNavigate();

    const syncBookingStaffMembers = () => {
        adminService.syncBookingStaffMembers().then((resp:Boolean)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Success, "Sincronizzazione staff members completata con successo");
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error,jqXHR.message);
        })
    }

    const syncBookingAppointments = () => {
        adminService.syncBookingAppointments().then((resp:Boolean)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Success, "Sincronizzazione appuntamenti completata con successo");
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error,jqXHR.message);
        })
    }

    const syncBookingServices = () => {
        adminService.syncBookingServices().then((resp:Boolean)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Success, "Sincronizzazione servizi completata con successo");
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error,jqXHR.message);
        })
    }
    
    if (!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={loaderMessage}></Loader>;
    else
        return (
            <>
                <PageContainer endpoint={'/odata/booking/setup'} pageType={SystemPageType.List} title={"Booking"} cardRef={cardRef} backAllowed insertAllowed updateAllowed>
                    <PageDefinitionContainer type={ContainerType.Action}>
                        <ActionArea category={SystemActionCategory.Process}>
                            <ActionEntry name="bookingChallenge" label="Challenge booking" iconName="AuthenticatorApp" runOnRec onClick={(req:any)=>{
                                adminService.bookingChallenge(req)
                                .then((resp) => {
                                    window.open(resp);
                                })
                                .catch((err) => {
                                    ToastService.showMessage(MessageType.Error, "Errore durante il challenge: " + err.message);
                                })
                            }}>
                            </ActionEntry>
                            <ActionEntry name="syncBookingStaffMembers" label="Sincronizza staff members" iconName="Teamwork" onClick={(req:any)=>{
                                setDataStatus(SystemDataLoadingStatus.Loading);
                                setLoaderMessage("Sincronizzazione staff members in corso, Attendere...");
                                syncBookingStaffMembers();
                            }}>                            
                            </ActionEntry>
                            <ActionEntry name="syncBookingServices" label="Sincronizza servizi" iconName="AppIconDefaultList" onClick={(req:any)=>{
                                setDataStatus(SystemDataLoadingStatus.Loading);
                                setLoaderMessage("Sincronizzazione servizi in corso, Attendere...");
                                syncBookingServices();
                            }}>                            
                            </ActionEntry>
                            <ActionEntry name="syncBookingAppointments" label="Sincronizza appuntamenti" iconName="Calendar" onClick={(req:any)=>{
                                setDataStatus(SystemDataLoadingStatus.Loading);
                                setLoaderMessage("Sincronizzazione appuntamenti in corso, Attendere...");
                                syncBookingAppointments();
                            }}>                            
                            </ActionEntry>
                        </ActionArea>
                        <ActionArea category={SystemActionCategory.Navigation}>
                            <ActionEntry name="openBookingService" label="Servizi" iconName="AppIconDefaultList" onClick={(req:any)=>{
                                navigate("/app/admin/administrative/bookingService");
                            }}></ActionEntry>
                        </ActionArea>
                    </PageDefinitionContainer>
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry name="Legal entity" fieldName="legalEntityCode" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Distributore" fieldName="distributorCode" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Partner business contract" fieldName="partnerBusinessContractCode" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Istanza" fieldName="instance" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name="Client Id" fieldName="clientId" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name="Client secret" fieldName="clientSecret" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name="Client Id application" fieldName="clientIdApplication" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name="Client secret application" fieldName="clientSecretApplication" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name="Tenant Id" fieldName="tenantId" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name="Redirect URL" fieldName="redirectUrl" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name="Scope" fieldName="scope" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name="Filtro paginazione" fieldName="pageFilter" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name="SoSmart calendar Id" fieldName="soSmartCalendarId" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name="Data ultima sincronizzazione" fieldName="lastSyncronizationDate" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name="Giorni ritardo sincronizzazione" fieldName="syncronizationDelayDays" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name="Sincronizzazione attiva" fieldName="syncActive" minWidth={100} maxWidth={200} />
                    </PageDefinitionContainer>
                </PageContainer>
                <AdminBookingSetupCard ref={cardRef} />
            </>
        );
}