import React from "react";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { SystemActionCategory,SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { useNavigate } from "react-router-dom";

export const AdminProvisioningLineList : React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
            <PageContainer endpoint="/odata/provisioning/line" pageType={SystemPageType.List} title="Lista avanzamenti" backAllowed>                            
            <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="OpenProvisioningDetails" label="Apri dettaglio avanzamento" iconName="BulletedList2" runOnRec isPromoted onClick={(req:any)=>{
                                navigate('/app/admin/administrative/provisioningLineDetails?lineNo='+req.lineNo);
                            }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Numero Documento" fieldName="documentNo" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Numero Linea" fieldName="lineNo" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Codice Template" fieldName="templateCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Codice Modulo" fieldName="moduleCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id Cliente" fieldName="customerId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Per Tipo Entità" fieldName="forEntityTipe" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id Sorgente" fieldName="sourceId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id Sotto sorgente" fieldName="sourceSubId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Nome Sorgente" fieldName="sourceName" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Processato" fieldName="processato" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="In errore" fieldName="hasError" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Iniziato il" fieldName="startedAt" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Completato il" fieldName="completedAt" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="System Id" fieldName="systemId" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="System Creation User Id" fieldName="systemCreationUserId" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="System Creation Date" fieldName="systemCreationDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Data aggiornamento sistema" fieldName="systemUpdateDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id utente aggiornamento sistema " fieldName="systemUpdateUserId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Timestamp" fieldName="timestamp" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
        </>
    );
}