import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminBusinessCentralCompanyCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Società Business Central"} endpoint="odata/businesscentral/company" ref={ref}>
            <CardGroup name="general" label="General" isOpen={true}>
                <CardField label="Nome" name="name" type={SystemFieldType.String} readonly />
                <CardField label="Id" name="id" type={SystemFieldType.String} readonly />
                <CardField label="Nr. Versione" name="versionNo" type={SystemFieldType.String} readonly />
                <CardField label="Aggiornamento disabilitato" name="disableUpdate" type={SystemFieldType.Boolean} />
            </CardGroup>
        </PageContainer>
    );
});