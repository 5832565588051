import React, { useRef } from "react";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { SystemOperation, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminSystemEventFilterCard } from "./AdminSystemEventFilterCard";

export const AdminSystemEventFilterList : React.FC = () => {
    
    const cardRef = useRef<PageContainerBase>(null);
    const eventName: string = HttpHelper.getParameter("eventName");
    const setupCode: string = HttpHelper.getParameter("setupCode");

    const buildEndpoint = () : string => {
    
        let endpoint: string = "/odata/system/event/setup/filter";
        let filter: string = "";
        
        if (eventName !== undefined && eventName > "")
            filter += "?$filter=eventName eq '" + eventName + "'"
        
        if (setupCode !== undefined && setupCode > ""){
            if (filter !== undefined && filter > "")
                filter += " and setupCode eq '" + setupCode+ "'"
            else
                filter += "?$filter=setupCode eq '" + setupCode+ "'"
        }
                
        return(endpoint+filter);
    }
    
    return(
        <>
            <PageContainer
                endpoint={buildEndpoint()}
                pageType={SystemPageType.List}
                title="Lista System Event Filter"
                backAllowed showAllowed insertAllowed updateAllowed deleteAllowed
                cardRef={cardRef}
                onNewRecord={() => {
                    cardRef.current?.openPage(SystemOperation.Insert);
                    cardRef.current?.setRecord({
                        eventName: eventName,
                        setupCode: setupCode
                    });
                }}>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Id" fieldName="entryNo" minWidth={40} maxWidth={50} />
                    <ListHeaderEntry name="Nome Evento" fieldName="eventName" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry name="Codice Setup" fieldName="setupCode" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry name="Nome Parametro" fieldName="parameterName" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry name="Entità" fieldName="isEntity" minWidth={80} maxWidth={100} />
                    <ListHeaderEntry name="Nome Entità" fieldName="entityName" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry name="Nome Campo" fieldName="fieldName" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry name="Valore Filtro" fieldName="filterValue" minWidth={300} maxWidth={400} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminSystemEventFilterCard ref={cardRef} eventName={eventName} />
        </>
    );
}