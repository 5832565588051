import React, { useRef } from "react";
import { SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminPartnerInvoiceSetupCard } from "../AdminPartnerInvoiceSetupCard/AdminPartnerInvoiceSetupCard";

export const AdminPartnerInvoiceSetupList : React.FC = () => {
    const cardRef = useRef<PageContainerBase>(null);
    
    return (
        <>
            <PageContainer endpoint="/odata/partner/invoice/setup" pageType={SystemPageType.List} title="Lista partner invoice setup" backAllowed insertAllowed updateAllowed cardRef={cardRef}>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>                    
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Partner Id" fieldName="partnerId" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Zone code" fieldName="zoneCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Country region code" fieldName="countryRegionCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Handle complete address" fieldName="handleCompleteAddress" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Handle SDI code" fieldName="handleSdiCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="General Business Posting Group" fieldName="erpGenBusPostingGroup" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="VAT Business Posting Group" fieldName="erpVATBusPostingGroup" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Customer Posting Group" fieldName="erpCustomerPostingGroup" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Language Code" fieldName="erpLanguageCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Currency Code" fieldName="erpCurrencyCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Payment Terms Code" fieldName="erpPaymentTermsCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Payment Method Code" fieldName="erpPaymentMethodCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Reminder Terms Code" fieldName="erpReminderTermsCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Sales Person Code" fieldName="erpSalesPersonCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Channel dimension" fieldName="erpChannelDimension" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id Bank Account" fieldName="erpEosOurBankAccount" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Posting description" fieldName="erpPostingDescription" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Discount description" fieldName="erpDiscountDescription" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Discount Item No" fieldName="erpDiscountItemNo" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Accrual Template" fieldName="erpAccrualTemplate" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminPartnerInvoiceSetupCard ref={cardRef} />
        </>
    );
}