import React, { useRef }  from "react";
import { SystemActionCategory, SystemPageType } from "../../../Model/SystemModels";
import { ActionArea } from "../../Common/Template/Action/ActionArea";
import { ListHeaderEntry } from "../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../Common/Template/PageDefinitionContainer";
import { HttpHelper } from "../../../Core/Http/HttpHelper";

export const CustomerRmrLedgerEntryList : React.FC = () => {
    
    const cardRef = useRef<PageContainerBase>(null);

    const buildEndpoint = () : string => {
        let customerId: number = HttpHelper.getParameter('customerId');
        let baseEndpoint = "/odata/customer/rmr/ledger/entry?$orderby=itemNo,postingDate";

        if (customerId)
            baseEndpoint += "&$filter=customerId eq "+customerId;

        return baseEndpoint;
    }

    return (
        <>
            <PageContainer endpoint={buildEndpoint()} pageType={SystemPageType.List} title="Lista Customer RMR Ledger Entry" cardRef={cardRef} backAllowed>               
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Action}>
                    </ActionArea>
                </PageDefinitionContainer>                                 
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Id cliente" fieldName="customerId" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Cliente" fieldName="customerName" minWidth={250} maxWidth={250} />
                    <ListHeaderEntry name="Tipo" fieldName="entryType" minWidth={100} maxWidth={250} onRender={(item: any, index, column) => {
                        if (item.entryType === 0)
                            return "New"
                        else if (item.entryType === 1)
                            return "Lost"
                    }} />
                    <ListHeaderEntry name="Stato" fieldName="entryStatus" minWidth={100} maxWidth={250} onRender={(item: any, index, column) => {
                        if (item.entryStatus === 0)
                            return "New"
                        else if (item.entryStatus === 1)
                            return "Update"
                        else if (item.entryStatus === 2)
                            return "Cancel"
                    }} />
                    <ListHeaderEntry name="Data" fieldName="postingDate" minWidth={170} maxWidth={250} />
                    <ListHeaderEntry name="Id articolo" fieldName="itemNo" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Descrizione articolo" fieldName="itemDescription" minWidth={300} maxWidth={250} />
                    <ListHeaderEntry name="Quantità" fieldName="quantity" minWidth={70} maxWidth={250} />
                    <ListHeaderEntry name="Quantità residua" fieldName="remainingQuantity" minWidth={120} maxWidth={250} />
                    <ListHeaderEntry name="Importo" fieldName="amount" minWidth={70} maxWidth={250} />
                    <ListHeaderEntry name="Id sottoscrizione" fieldName="subscriptionId" minWidth={120} maxWidth={250} />
                    <ListHeaderEntry name="Id riga sottoscrizione" fieldName="subscriptionLineId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="No. documento" fieldName="documentNo" minWidth={190} maxWidth={250} />
                    <ListHeaderEntry name="No. riga documento" fieldName="documentLineNo" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
        </>
    );
}