import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import {SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminDataSecurityEntityCard } from "./AdminDataSecurityEntityCard";

export const AdminDataSecurityEntityList : React.FC = () => {

    const cardRef = useRef<PageContainerBase>(null);
    const navigate = useNavigate();

    return (
        <>
            <PageContainer endpoint="/odata/datasecurity/entity" pageType={SystemPageType.List} title="Entità Data Security" backAllowed insertAllowed deleteAllowed updateAllowed cardRef={cardRef}>               
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="ViewStatus" isPromoted label="Stati" iconName="AllApps" onClick={(rec: any) => {
                            navigate("/app/admin/datasecurity/status?entityName=" + rec.entityName);
                        }} />
                    </ActionArea>
                </PageDefinitionContainer>                             
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Nome entità" fieldName="entityName" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Abilitato" fieldName="enabled" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminDataSecurityEntityCard ref={cardRef} />
        </>
    );
}