import { NavigateFunction } from "react-router-dom";
import { HttpConstants } from "../../Core/Http/HttpConstants";
import { HttpHelper } from "../../Core/Http/HttpHelper";
import { MessageType, ToastService } from "../../Core/Toast/ToastService";
import { Lead, Partner, LeadQuestionnaireData, LeadQuestionnaireDataModified, CrmSetup } from "../../Model/PartnerModels";
import { AbletechValidateVAT, AbletechValidateVATResponse, PageMetadata, ResetPassword, SystemEntity, SystemEventLog, SystemLanguage, SystemLog, SystemPage, SystemUser, User } from "../../Model/SystemModels";
import { LoginRequestResponse } from "../../Model/SecurityModel";

export class CommonFunctions {

    public static checkHashParameter(hash: string) : Promise<boolean> {
        let instance = HttpHelper.buildAxiosInstance();
        return new Promise((resolve, reject) => {
            instance.get("/api/system/user/" + hash + "/check").then(() => {
                resolve(true);
            })
            .catch(() => {
                reject(false);
            })
        });
    }

    public static createPageMetadata(pageMetadata: PageMetadata)
    {
        let instance = HttpHelper.buildAxiosInstance();

        return new Promise<boolean>((resolve, reject) => {
            instance.post("api/system/metadata/page", pageMetadata)
                .then((resp) => {
                    resolve(true);
                })
                .catch((err) => {
                    ToastService.showMessage(MessageType.Error, err.response.data.message);
                    reject(false);
                })
        });
    }

    public static formatEndpoint(endpoint: string, filter: string, referenceId: number) : string {
        if (referenceId != 1)
        {
            let formattedEndpoint = endpoint + filter.replace("%PARTNERID%", referenceId.toString());
            return formattedEndpoint;
        }
        return endpoint;
    }

    public static deletePageMetadata(pageMetadata: PageMetadata)
    {
        let instance = HttpHelper.buildAxiosInstance();

        return new Promise<boolean>((resolve, reject) => {
            instance.delete("api/system/metadata/page/" + pageMetadata.page.systemId)
                .then((resp) => {
                    resolve(true);
                })
                .catch((err) => {
                    ToastService.showMessage(MessageType.Error, err.response.data.message);
                    reject(false);
                })
        });
    }

    public static handleSmartLeadSave(lead: Lead) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();

        return new Promise<boolean>((resolve, reject) => {
            let loginObject = {
                username: 'sosmart.shop@so-smart.it',
                password: 'ShopSoSmart123456789!'
            };
    
            instance.post("api/system/login", loginObject)
                .then((resp) => {
                    if (resp.data.success)
                    {                    
                        instance.defaults.headers.common[HttpConstants.HEADER_AUTHORIZATION] = HttpConstants.BEARER_AUTH + " " + resp.data.value;
                        instance.post("odata/lead", lead)
                        .then((resp) => {
                            resolve(true);
                        })
                        .catch((err) => {
                            ToastService.showMessage(MessageType.Error, err.response.data.message);
                            reject(false);
                        });
                    }
                    else{
                        ToastService.showMessage(MessageType.Error, resp.data)
                        reject(false);
                    }
                })
                .catch((err) => {
                    ToastService.showMessage(MessageType.Error, err.response.data.message);
                    reject(false);
                })
        });
    }

    public static registerLead(lead: Lead) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();

        return new Promise<boolean>((resolve, reject) => {
            instance.post("api/crm/lead/register", lead)
            .then((resp) => {
                if (resp.data.success) {
                    resolve(true);
                }
                else {
                    ToastService.showMessage(MessageType.Error, resp.data.message);
                    reject(false);
                }
            })
            .catch((err) => {
                console.log(err);
                ToastService.showMessage(MessageType.Error, "Errore generico");
                reject(false);
            })
        });
    }

    public static getUserType(email: string) : Promise<LoginRequestResponse>
    {
        let instance = HttpHelper.buildAxiosInstance();

        return new Promise<LoginRequestResponse>((resolve, reject) => {
            instance.get("api/system/user/type/get/"+email)
            .then((resp) => {
                let t: LoginRequestResponse = resp.data;
                if (t.success) {
                    resolve(t);
                }
                else {
                    ToastService.showMessage(MessageType.Error, t.message);
                    reject("");
                }
            })
            .catch((err) => {
                console.log(err);
                ToastService.showMessage(MessageType.Error, "Errore in fase di autenticazione");
                reject(false);
            })
        });
    }

    public static handlePasswordReset(email: string) : Promise<boolean> {
        let instance = HttpHelper.buildAxiosInstance();
        return new Promise((resolve, reject) => {
            instance.post("/api/system/user/" + email + "/reset", null).then(() => {
                resolve(true);
            })
            .catch((resp) => {
                reject(resp.response.data);
            })
        });
    }

    public static processPasswordReset(hash: string, password: string) : Promise<string> {
        let instance = HttpHelper.buildAxiosInstance();
        let resetPwdEntry = new ResetPassword();
        resetPwdEntry.password = password;

        return new Promise((resolve, reject) => {
            instance.post("/api/system/user/" + hash + "/process", resetPwdEntry).then((resp) => {
                resolve(resp.data);
            })
            .catch((resp) => {
                reject(resp.response.data);
            })
        });
    }

    public static loadDataFromUrl(endpoint: string) : Promise<any> {
        let instance = HttpHelper.buildAxiosInstance();
        return new Promise((resolve, reject) => {
            instance.get(endpoint).then((resp) => {
                resolve(resp.data);
            })
            .catch((err) => {
                reject(err.response.data);
            })
        });
    }

    public static loadTableRelationDataFromUrl(endpoint: string) : Promise<any> {
        let instance = HttpHelper.buildAxiosInstance();
        return new Promise((resolve, reject) => {
            instance.get(endpoint).then((resp) => {
                resolve(resp.data);
            })
            .catch((err) => {
                resolve(undefined);
            })
        });
    }

    public static loadPageFromCurrentEndpoint(currentPath: string) : Promise<SystemPage> {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/odata/system/page?$filter=endpoint eq '" + currentPath + "'";

        return new Promise((resolve, reject) => {
            instance.get(url).then((resp) => {
                let pageResult: SystemPage = resp.data[0];
                resolve(pageResult);
            })
            .catch((resp) => {
                reject(resp.response.data);
            });
        });
    }

    public static loadPageEntity(page: SystemPage) : Promise<SystemEntity> {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/odata/system/entity?$filter=name eq '" + page.sourceEntityName + "'";

        return new Promise((resolve, reject) => {
            instance.get(url).then((resp) => {
                let result: SystemEntity = resp.data[0];
                resolve(result);
            })
            .catch((resp) => {
                reject(resp.response.data);
            });
        });
    }

    public static logEvent(description: string, referenceId: string) : Promise<boolean> {
        let instance = HttpHelper.buildAxiosInstance();
        
        let log = new SystemLog();
        log.description = description;
        log.referenceId = referenceId;

        return new Promise<boolean>((resolve, reject) => {
            instance.post("api/system/frontend/event/log", log)
                .then((resp) => {
                    resolve(true);
                })
                .catch((err) => {
                    resolve(false);
                });
        });
    }

    public static logSystemEvent(title: string, description: string, duration: number, userId: number, typeCode: string, dimension1:string, dimension2: string) : Promise<boolean> {
        let instance = HttpHelper.buildAxiosInstance();

        let systemEventLog = new SystemEventLog();
        systemEventLog.title = title;
        systemEventLog.description = description;
        systemEventLog.performedOn = new Date();
        systemEventLog.duration = duration;
        systemEventLog.userId = userId;
        systemEventLog.typeCode = typeCode;
        systemEventLog.dimension1 = dimension1;
        systemEventLog.dimension2 = dimension2;

        return new Promise<boolean>((resolve, reject) => {
            instance.post("odata/system/event/log", systemEventLog)
                .then((resp) => {
                    resolve(true);
                })
                .catch((err) => {
                    resolve(false);
                });
        });
    }

    public static callAction(endpoint: string, record: any) : Promise<boolean>
    {
        let client = HttpHelper.buildAxiosInstance();
        return new Promise<boolean>((resolve, reject) => {
            client.post(endpoint, record)
                .then((resp) => {
                    ToastService.showMessage(MessageType.Success, "Operazione completata con successo");
                    resolve(true);
                })
                .catch((err) => {
                    ToastService.showMessage(MessageType.Error, err.response.data.message);
                    resolve(false);
                });
            });
    }

    public getPartnerId(email: string): Promise<Partner>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "odata/user?$filter=privateEmail eq '" + email+"' or userPrincipleName eq '"+email+"'";

        return new Promise((resolve, reject) => {
            instance.get(url).then((userResp) => {
                instance.get("odata/partner?$filter=id eq "+userResp.data[0].partnerId).then((partnerResp) => {
                    let result = partnerResp.data[0];
                    resolve(result);
                })
                .catch((jqXHR) => {
                    reject(jqXHR.response.data);
                });
            })
            .catch((resp) => {
                reject(resp.response.data);
            });
        });
    }

    public sendReferralLinkToLead(leadId: number): Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "api/sales/lead/"+leadId+"/send/referral/link";

        return new Promise((resolve, reject) => {
            instance.post(url)
            .then((resp) => {
                resolve(true);
            })
            .catch((resp) => {
                reject(resp.response.data);
            });
        });
    }

    public sendPartnerReferralLink(partnerId: number, lead: Lead): Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "api/sales/partner/"+partnerId+"/send/referral/link";
        
        return new Promise((resolve, reject) => {
            instance.post(url, lead)
            .then((resp) => {
                resolve(true);
            })
            .catch((resp) => {
                reject(resp.response.data);
            });
        });
    }

    public static getEnabledLanguage() : Promise<SystemLanguage[]> {
        let instance = HttpHelper.buildAxiosInstance();

        return new Promise<SystemLanguage[]>((resolve, reject) => {
            instance.get("/odata/system/language?$filter=enabled eq true")
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    }

    public static loadLeads(lead?: Lead, partnerBusinessContractCode?: string) : Promise<Lead[]> {
        let instance = HttpHelper.buildAxiosInstance();

        let url = "/odata/lead?$filter=(statusCode eq \'80\' or statusCode eq \'60\' or statusCode eq \'50\' or statusCode eq \'20\' or statusCode eq \'02\' or statusCode eq \'00\')";
        
        if (partnerBusinessContractCode)
            url += " and partnerBusinessContractCode eq \'"+partnerBusinessContractCode+"\'";

        url += "&$orderby=createdOn desc";

        if(lead !== undefined)
            url = "/odata/lead/"+lead.systemId; 

        return new Promise<Lead[]>((resolve, reject) => {
            instance.get(url)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    }

    public static loadWorksheetLeads(lead?: Lead, leadFilter?: string) : Promise<Lead[]> {
        let instance = HttpHelper.buildAxiosInstance();

        let url = "/odata/lead";
        
        if (leadFilter)
            url += leadFilter;

        if(lead !== undefined)
            url = "/odata/lead/"+lead.systemId; 

        return new Promise<Lead[]>((resolve, reject) => {
            instance.get(url)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    }

    public static saveLead(leadToSave: Lead) : Promise<Lead> {
        let instance = HttpHelper.buildAxiosInstance();

        return new Promise<Lead>((resolve, reject) => {
            instance.put("/odata/lead/"+leadToSave.systemId, leadToSave)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    }

    public static getLeadTotalRiskLine(currentLead: Lead) : Promise<number> {
        let instance = HttpHelper.buildAxiosInstance();

        return new Promise<number>((resolve, reject) => {
            instance.get("/odata/crm/lossriskline?$filter=sourceTable eq 'Lead' and sourceId eq "+currentLead.id)
                .then((resp) => {
                    resolve(resp.data.length);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    }

    public static getLeadTotalQualifiedAnswer(currentLead: Lead) : Promise<number> {
        let instance = HttpHelper.buildAxiosInstance();

        return new Promise<number>((resolve, reject) => {
            instance.get("/odata/lead/qualified/answer?$filter=leadId eq "+currentLead.id+" and wizardCode eq null")
                .then((resp:any) => {
                    resolve(resp.data.length);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    }

    public static updateLeadQuestionnaireData(currentLead: Lead,modifiedData: LeadQuestionnaireDataModified[], questionnaireId: string) : Promise<boolean> {
        let instance = HttpHelper.buildAxiosInstance();

        return new Promise<boolean>((resolve, reject) => {
            instance.post("/api/CRM/"+ currentLead.id +"/questionnaire/"+ questionnaireId +"/update", modifiedData)
                .then((resp:any) => {
                    resolve(true);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    }

    public static getLeadQuestionnaireData(currentLead: Lead) : Promise<LeadQuestionnaireData[]> {
        let instance = HttpHelper.buildAxiosInstance();

        return new Promise<LeadQuestionnaireData[]>((resolve, reject) => {
            instance.get("/api/CRM/"+currentLead.id+"/questionnaire/get")
                .then((resp:any) => {
                    resolve(resp.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    }

    public static deleteShortCutParameters(navigate:NavigateFunction): void {
        
        let urlParams = new URLSearchParams(window.location.search);
        urlParams.delete('shortCutBook');
        urlParams.delete('shortCutBuy');
        urlParams.delete('shortCutTrial');
        urlParams.delete('shortCutHelp');

        navigate("?"+urlParams.toString(),{replace:true});
    }

    public static buildRedirectUrl = (path: string) : string => {
        if (window.location.pathname != "/app")
            return path += "?resource=" + btoa(window.location.pathname + window.location.search);
        return path;
    }

    public static disablePageScroll(): void {
        let TopScroll = window.pageYOffset || document.documentElement.scrollTop;
        let LeftScroll = window.pageXOffset || document.documentElement.scrollLeft;

        document.onwheel = () => {window.scrollTo(LeftScroll, TopScroll)};
        window.onscroll = () => {window.scrollTo(LeftScroll, TopScroll);};
    }

    public static enablePageScroll(): void {
        document.onwheel = () => {};
        window.onscroll = () => {};
    }

    public static validateVat(vat: string): Promise<AbletechValidateVATResponse>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "api/CustomerDigitalContract/ix/validate/vat";

        const jsonObject: AbletechValidateVAT = new AbletechValidateVAT();
        jsonObject.partitaIva = vat;

        return new Promise((resolve, reject) => {
            instance.post(url, jsonObject)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((resp) => {
                reject(resp.response.data);
            });
        });
    }

    public static getCrmSetup(): Promise<CrmSetup>
    {
        let instance = HttpHelper.buildAxiosInstance();
        
        return new Promise((resolve, reject) => {
            instance.get("/odata/CRMSetup").then((resp) => {
                resolve(resp.data[0]);
            })
            .catch((resp) => {
                reject(resp.response.data);
            });
        })
    }

    public static convertString(keyValue: string) : string
    {
        if(keyValue.includes('\'')){
            keyValue = String(keyValue).replace(/'/g, "''");
        }

        return keyValue;
    }
}