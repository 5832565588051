import { useRef } from "react";
import { SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminCrmIncidentTypeCard } from "../AdminCrmIncidentTypeCard/AdminCrmIncidentTypeCard";

export const AdminCrmIncidentTypeList : React.FC = () => {
    const cardRef = useRef<PageContainerBase>(null);
    
    return (
        <>
            <PageContainer endpoint="/odata/crm/incident/type" pageType={SystemPageType.List} title="Lista CRM Incident Type" cardRef={cardRef} backAllowed showAllowed updateAllowed>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Id" fieldName="entryNo" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Nome" fieldName="name" minWidth={450} maxWidth={500} />
                    <ListHeaderEntry name="Data Creazione" fieldName="createdOn" minWidth={300} maxWidth={350} />
                    <ListHeaderEntry name="Data Modifica" fieldName="modifiedOn" minWidth={300} maxWidth={350} />
                    <ListHeaderEntry name="Id CRM EOS" fieldName="crmReferenceId" minWidth={350} maxWidth={400} />
                    <ListHeaderEntry name="Visibile" fieldName="toShow" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Default" fieldName="default" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminCrmIncidentTypeCard ref={cardRef} />
        </>
    );
}