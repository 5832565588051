import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { SystemActionCategory, SystemOperation, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminSystemEventSetupCard } from "./AdminSystemEventSetupCard";

export const AdminSystemEventSetupList : React.FC = () => {
    
    const navigate = useNavigate();
    
    const cardRef = useRef<PageContainerBase>(null);
    
    const eventName = HttpHelper.getParameter('eventName')

    const buildEndpoint = () : string => {
    
        let endpoint: string = "/odata/system/event/setup";
        
        if (eventName !== undefined && eventName > "")
            endpoint += "?$filter=eventName eq '" + eventName + "'"
                
        return endpoint;
    }
    
    return(
        <>
            <PageContainer
                endpoint={buildEndpoint()}
                pageType={SystemPageType.List}
                title="Lista System Event Setup"
                backAllowed showAllowed insertAllowed updateAllowed deleteAllowed
                cardRef={cardRef}
                onNewRecord={() => {
                    cardRef.current?.openPage(SystemOperation.Insert);
                    cardRef.current?.setRecord({
                        eventName: eventName
                    });
                }}>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="ViewFilter" runOnRec isPromoted label="Filtri" iconName="Filter" onClick={(rec: any) => {
                            navigate("/app/admin/systemevent/filter?eventName=" + rec.eventName + "&setupCode=" + rec.code);
                        }} />
                        <ActionEntry name="ViewVariable" runOnRec isPromoted label="Variabili" iconName="Variable" onClick={(rec: any) => {
                            navigate("/app/admin/systemevent/variable?eventName=" + rec.eventName + "&setupCode=" + rec.code);
                        }} />
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Nome evento" fieldName="eventName" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry name="Codice" fieldName="code" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry name="Titolo" fieldName="title" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry name="Attivo" fieldName="active" minWidth={80} maxWidth={100} />
                    <ListHeaderEntry name="Codice tipo" fieldName="typeCode" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry name="Entità sorgente" fieldName="sourceEntity" minWidth={300} maxWidth={400} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminSystemEventSetupCard ref={cardRef} />
        </>
    );
}