import React, { useContext, useEffect, useRef, useState } from "react";
import { SystemActionCategory, SystemDataLoadingStatus,OperationType, SystemEntity, SystemPage, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";

export const PartnerSubscriptionLineList : React.FC = () => {
 
    const getParams=(param:string)=>{
        const query = new URLSearchParams(window.location.search);
        return (query.get(param))
    }

    return (
        <>
            <PageContainer endpoint={"/odata/subscription/line?$filter=idSubscriptionHeader eq "+getParams('headerId')} pageType={SystemPageType.List} title="Lista dettaglio sottoscrizione">                            
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="System Id" fieldName="systemId" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="System Creation User Id" fieldName="systemCreationUserId" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="System Creation Date" fieldName="systemCreationDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Data aggiornamento sistema" fieldName="systemUpdateDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id utente aggiornamento sistema " fieldName="systemUpdateUserId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Timestamp" fieldName="timestamp" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id" fieldName="id" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id Subscription Header" fieldName="idSubscriptionHeader" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Document No" fieldName="documentNo" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Document Line No" fieldName="documentLineNo" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id Partner" fieldName="partnerId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id Customer" fieldName="customerId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Item Ecommerce Code" fieldName="itemEcommerceCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Quantità" fieldName="quantity" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Prezzo" fieldName="price" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Line Amount" fieldName="lineAmount" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="VAT Percentage" fieldName="vaT_Percentage" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Percentuale di Sconto" fieldName="discount_Percentage" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Percentuale Commissione" fieldName="commission_Percentage" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Commissione" fieldName="commission_Amount" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Stato" fieldName="status" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id Item" fieldName="itemId" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
        </>
    );
}