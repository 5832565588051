import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";
import React from "react";

export interface IConfirmDialogProps {
    showDialog: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    title: string;
    subText: string;
    confirmButtonText: string;
    cancelButtonText: string;
}


export const ConfirmDialog : React.FC<IConfirmDialogProps> = (props) => {
    const dialogContentProps = {
        type: DialogType.normal,
        title: props.title,
        subText: props.subText
    };
    
    return (
        <Dialog
                dialogContentProps={dialogContentProps}
                hidden={! props.showDialog}
            >
            <DialogFooter>
                <PrimaryButton onClick={props.onConfirm} text={props.confirmButtonText} />
                <DefaultButton onClick={props.onCancel} text={props.cancelButtonText} />
            </DialogFooter>
        </Dialog>
    );
}