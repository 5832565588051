import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminSystemPermissionCategoryCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda System App"} endpoint="odata/system/permission/category" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="categoryCode" type={SystemFieldType.String} label="Codice categoria" />
                <CardField name="appId" type={SystemFieldType.Option} label="App Id" hasTableRelation tableRelationEndpoint="odata/system/app" tableRelationKey="id" tableRelationField="name" />
                <CardField name="roleCode" type={SystemFieldType.Option} label="Codice ruolo" hasTableRelation tableRelationEndpoint="odata/system/role" tableRelationKey="code" tableRelationField="description" tableRelationFilter="?$filter=appId eq '#appId#'" />
            </CardGroup>
        </PageContainer>
    );
});