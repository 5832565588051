import React, { forwardRef, useContext, useEffect, useState } from "react";
import { OperationType, SystemFieldType, SystemPageType, SystemUser } from "../../../../Model/SystemModels";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import { CardField } from "../../../Common/Template/Card/CardField";

export const AdminCustomerConfigCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    const getParams=(param:string)=>{
        const query = new URLSearchParams(window.location.search);
        return (query.get(param))
    }

    return (
        <PageContainer pageType={SystemPageType.Card} title={"Configurazione di " + getParams('name')} endpoint="odata/customer/setup/config" ref={ref}>
            <CardGroup name="config" label="Impostazioni di configurazione" isOpen>
                <CardField name="tenantId" type={SystemFieldType.String} label="Id tenant" readonly />
                <CardField name="hasTenant" type={SystemFieldType.Boolean} label="Ha un tenant id" />
                <CardField name="adminTenantUserId" type={SystemFieldType.String} label="Admin tenant id" />
                <CardField name="setupCompleted" type={SystemFieldType.Boolean} label="Setup completato" />
                <CardField name="partnerEmail" type={SystemFieldType.String} label="Email partner" />
                <CardField name="provisioningCompleted" type={SystemFieldType.Boolean} label="Provisioning completato" />
                <CardField name="partnerEmailSendAt" type={SystemFieldType.String} label="Data invio email partner" />
                <CardField name="enabled" type={SystemFieldType.Boolean} label="Abilitato" />
                <CardField name="customerId" type={SystemFieldType.Number} label="Id cliente" readonly />
                <CardField name="introCompleted" type={SystemFieldType.Boolean} label="Intro completata" />
                <CardField name="blockMultipleSession" type={SystemFieldType.Boolean} label="Blocco sessioni multiple" />
            </CardGroup> 
        </PageContainer>
    );
});