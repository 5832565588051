import { CSSProperties, FormEvent, useEffect, useState } from "react";
import { ComboBox, DatePicker, DayOfWeek, DefaultButton, Dialog, DialogFooter, Dropdown, IComboBoxOption, IDropdownOption, IModalProps, IStyle, PrimaryButton, Stack, TextField, mergeStyles } from "@fluentui/react";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { StripeCouponRequest } from "../../../../Model/AdminModel";
import { t } from "i18next";

export interface ICustomAdminStripeCouponCardProps {
    showCard: boolean;
    customStyle?: CSSProperties;
    onConfirmSave: () => void;
    onCancel: () => void;
}

export const CustomAdminStripeCouponCard: React.FC<ICustomAdminStripeCouponCardProps> = (props) =>  {
        
    const fullModalIStyle: IStyle = {
        width: '60% !important',
        maxWidth: 'calc(95%) !important',
        position: 'absolute',
        top: 50
    }
    
    const pageCardModalProps: Partial<IModalProps> = {
        isBlocking: true,
        dragOptions: undefined,
        styles: {
            main: fullModalIStyle
        },
        layerProps:{styles:{root:{zIndex: props.customStyle?.zIndex ? props.customStyle.zIndex : '50000 !important'}}},
    };

    const modalContentClassName = mergeStyles([{
        width: '100%',
        height: '100%',
        padding: 0
    }]);

    const durationOptions: IComboBoxOption[] = [
        { key: 10, text: '' },
        { key: 0, text: 'Forever' },
        { key: 1, text: 'Once' },
        { key: 2, text: 'Repeating' },
    ];
    
    const adminService = new AdminService();
    const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
    const [record, setRecord] = useState(new StripeCouponRequest());
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [stripeItemsOptions, setStripeItemsOptions] = useState<IDropdownOption[]>([]);
    const [errorName, setErrorName] = useState<string>("");
    const [errorAmount, setErrorAmount] = useState<string>("");
    const [errorPercent, setErrorPercent] = useState<string>("");
    const [errorDuration, setErrorDuration] = useState<string>("");
    const [errorMonthDuration, setErrorMonthDuration] = useState<string>("");

    useEffect(()=>{
        if(props.showCard) {
            adminService.getStripeActiveProduct()
            .then((resp)=>{
                console.log(resp)
                let items: IDropdownOption[] = [];

                for (let i = 0; i < resp.length; i++){
                    let item: IDropdownOption = {
                        key: resp[i].id,
                        text: resp[i].name
                    };
                    items.push(item);
                }

                setStripeItemsOptions(items);
            })
            .catch((err)=>{
                ToastService.showMessage(MessageType.Error, "Error: "+err.message);
            });

            let currentRecord = new StripeCouponRequest();
            currentRecord.duration = 10;
            setRecord(currentRecord);
        }
        else
        {
            setRecord(new StripeCouponRequest());
            setSelectedItems([]);
            cleanErrors();
        }
    }, [props.showCard])

    const cleanErrors = () => {
        setErrorName("");
        setErrorAmount("");
        setErrorPercent("");
        setErrorDuration("");
        setErrorMonthDuration("");
    }
    
    const validateRecord = (): boolean => {
        let result: boolean = true;

        cleanErrors();

        if (!record.name) {
            setErrorName(t('adminCenter:customAdminStripeCouponCard:validation:name')!);
            result = false;
        }

        if ((!record.amountOff && !record.percentOff) || (record.amountOff && record.percentOff)) {
            setErrorAmount(t('adminCenter:customAdminStripeCouponCard:validation:amountPercent')!);
            setErrorPercent(t('adminCenter:customAdminStripeCouponCard:validation:amountPercent')!);
            result = false;
        }

        if (record.duration === 10) {
            setErrorDuration(t('adminCenter:customAdminStripeCouponCard:validation:duration')!);
            result = false;
        }
        else if (record.duration === 2 && !record.durationInMonths) {
            setErrorMonthDuration(t('adminCenter:customAdminStripeCouponCard:validation:monthDuration')!);
            result = false;
        }

        return result;
    }

    const onSave = () => {
        if (!validateRecord())
            return;

        setSaveInProgress(true);
        ToastService.showPromiseMessage(
            adminService.createStripeCoupon(record)
            .then(() => {
                props.onConfirmSave();
                setSaveInProgress(false);
            })
            .catch(() => {
                ToastService.showMessage(MessageType.Error, t('adminCenter:customAdminStripeCouponCard:errorCreation')!);
                setSaveInProgress(false);
            }),
            t('adminCenter:customAdminStripeCouponCard:creating')!,
            "",
            ""
        );
    }
    
    return (
        <Dialog 
            hidden={!props.showCard}
            modalProps={pageCardModalProps}
            onDismiss={props.onCancel}
            dialogContentProps={{
                title: t('adminCenter:customAdminStripeCouponCard:dialogTitle')!
            }}>
                <Stack key="modalBodyContent" verticalFill verticalAlign="stretch" className={modalContentClassName}>
                    <Stack.Item>
                        <TextField
                            label={t('adminCenter:customAdminStripeCouponCard:fields:name')!}
                            name={t('adminCenter:customAdminStripeCouponCard:fields:name')!}
                            placeholder={t('adminCenter:customAdminStripeCouponCard:fields:name')!}
                            disabled={saveInProgress}
                            value={record.name}
                            errorMessage={errorName}
                            onChange={(e, v) => {
                                setRecord((prevRecord) => ({
                                    ...prevRecord,
                                    name: v!,
                                }));
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField
                            type="number"
                            label={t('adminCenter:customAdminStripeCouponCard:fields:amount')!}
                            name={t('adminCenter:customAdminStripeCouponCard:fields:amount')!}
                            placeholder={t('adminCenter:customAdminStripeCouponCard:fields:amount')!}
                            disabled={saveInProgress}
                            value={(record.amountOff) ? record.amountOff.toString() : undefined}
                            errorMessage={errorAmount}
                            onChange={(e, v) => {
                                setRecord((prevRecord) => ({
                                    ...prevRecord,
                                    amountOff: (v) ? Number(v!) : undefined,
                                }));
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField
                            type="number"
                            label={t('adminCenter:customAdminStripeCouponCard:fields:percent')!}
                            name={t('adminCenter:customAdminStripeCouponCard:fields:percent')!}
                            placeholder={t('adminCenter:customAdminStripeCouponCard:fields:percent')!}
                            disabled={saveInProgress}
                            value={(record.percentOff) ? record.percentOff.toString() : undefined}
                            errorMessage={errorPercent}
                            onChange={(e, v) => {
                                setRecord((prevRecord) => ({
                                    ...prevRecord,
                                    percentOff: (v) ? Number(v!) : undefined,
                                }));
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <ComboBox
                            label={t('adminCenter:customAdminStripeCouponCard:fields:duration')!}
                            placeholder={t('adminCenter:customAdminStripeCouponCard:fields:duration')!}
                            disabled={saveInProgress}
                            options={durationOptions} 
                            errorMessage={errorDuration}
                            styles={{
                                root: {
                                    height: 32
                                }
                            }}
                            onChange={(e, v) => {
                                setRecord((prevRecord) => ({
                                    ...prevRecord,
                                    duration: Number(v?.key!),
                                }));
                            }}
                            selectedKey={record.duration}
                            autoComplete="on"
                            defaultSelectedKey={10} />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField
                            type="number"
                            label={t('adminCenter:customAdminStripeCouponCard:fields:durationInMonths')!}
                            name={t('adminCenter:customAdminStripeCouponCard:fields:durationInMonths')!}
                            placeholder={t('adminCenter:customAdminStripeCouponCard:fields:durationInMonths')!}
                            disabled={saveInProgress}
                            value={(record.durationInMonths) ? record.durationInMonths.toString() : undefined}
                            errorMessage={errorMonthDuration}
                            onChange={(e, v) => {
                                setRecord((prevRecord) => ({
                                    ...prevRecord,
                                    durationInMonths: (v) ? Number(v!) : undefined,
                                }));
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Dropdown
                            placeholder={t('adminCenter:customAdminStripeCouponCard:fields:appliesTo')!}
                            label={t('adminCenter:customAdminStripeCouponCard:fields:appliesTo')!}
                            disabled={saveInProgress}
                            selectedKeys={selectedItems}
                            onChange={(event: FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) => {
                                if (option) {
                                    setSelectedItems(
                                        option.selected ? [...selectedItems, option.key as string] : selectedItems.filter(key => key !== option.key)
                                    )
                                }
                            }}
                            onBlur={() => {
                                setRecord((prevRecord) => ({
                                    ...prevRecord,
                                    appliesTo: selectedItems
                                }));
                            }}
                            multiSelect
                            options={stripeItemsOptions}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <DatePicker
                            firstDayOfWeek={DayOfWeek.Monday}
                            value={record.redeemBy}
                            label={t('adminCenter:customAdminStripeCouponCard:fields:redeemBy')!}
                            placeholder={t('adminCenter:customAdminStripeCouponCard:fields:redeemBy')!}
                            disabled={saveInProgress}
                            ariaLabel={t('adminCenter:customAdminStripeCouponCard:fields:redeemBy')!}
                            onSelectDate={(date) => {
                                let currentDate: Date | undefined;
                                if (date === null)
                                    currentDate = undefined;
                                else
                                    currentDate = date;
                                
                                setRecord((prevRecord) => ({
                                    ...prevRecord,
                                    redeemBy: currentDate
                                }));
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField
                            type="number"
                            label={t('adminCenter:customAdminStripeCouponCard:fields:maxRedemption')!}
                            name={t('adminCenter:customAdminStripeCouponCard:fields:maxRedemption')!}
                            placeholder={t('adminCenter:customAdminStripeCouponCard:fields:maxRedemption')!}
                            disabled={saveInProgress}
                            value={(record.maxRedemptions) ? record.maxRedemptions.toString() : undefined}
                            onChange={(e, v) => {
                                setRecord((prevRecord) => ({
                                    ...prevRecord,
                                    maxRedemptions: (v) ? Number(v!) : undefined,
                                }));
                            }}
                        />
                    </Stack.Item>
                </Stack>
                <Stack.Item key="modalFooterContent">
                    <DialogFooter>
                        <DefaultButton text={t('common:PageContainer:card:defaultButtonText')!} onClick={props.onCancel} disabled={saveInProgress} />
                        <PrimaryButton text={t('common:PageContainer:card:primaryButtonText')!} onClick={onSave} disabled={saveInProgress} />
                    </DialogFooter>
                </Stack.Item>
        </Dialog>
    );
}