import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminWizardHeaderCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda wizard"} endpoint="/odata/ui/wizard/header" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="code" type={SystemFieldType.String} label="Codice" />
                <CardField name="description" type={SystemFieldType.String} label="Descrizione" />
                <CardField name="active" type={SystemFieldType.Boolean} label="Attivo" />
            </CardGroup>
        </PageContainer>
    );
});