import React, {forwardRef} from "react";
import {SystemFieldType,SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export interface IAdminEmailTemplateProps {
    title: string;
}

export const AdminEmailSetupCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} endpoint="/odata/email/setup" title={"Setup email"} ref={ref}>
            <CardGroup name="localization" label="Localizzazione" isOpen={true}>
                <CardField name="legalEntityCode" label="Legal entity" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/crm/legal/entity" tableRelationKey="code" tableRelationField="name"/>
                <CardField name="distributorCode" label="Distributore" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/distributor" tableRelationKey="code" tableRelationField="name"/>
                <CardField name="partnerBusinessContractCode" label="Partner business contract" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/partner/business/contract" tableRelationKey="code" tableRelationField="description"/>
                <CardField name="languageCode" label="Lingua" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/system/language" tableRelationKey="code" tableRelationField="name"/>
            </CardGroup>
            <CardGroup name="engineSetup" label="Setup" isOpen={true}>
                <CardField name="defaultHost" label="Default Host" type={SystemFieldType.String} />
                <CardField name="defaultPort" label="Default port" type={SystemFieldType.Number} />
                <CardField name="defaultEmailAddress" label="Default email address" type={SystemFieldType.String} />
                <CardField name="defaultEmailPassword" label="Default email password" type={SystemFieldType.String} />
                <CardField name="defaultDisplayName" label="Default display name" type={SystemFieldType.String} />
                <CardField name="enableSsl" label="Enable SSL" type={SystemFieldType.Boolean} />
                <CardField name="tenantId" label="Tenant Id" type={SystemFieldType.String} />
                <CardField name="clientId" label="Client Id" type={SystemFieldType.String} />
                <CardField name="clientSecret" label="Client Secret" type={SystemFieldType.String} />
                <CardField name="scopes" label="Scopes" type={SystemFieldType.String} />
                <CardField name="userPasswordTemplateCode" label="User password template code" type={SystemFieldType.String} />
            </CardGroup>
        </PageContainer>
    );
});