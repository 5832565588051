import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminChatbotSetupCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda CSP setup"} endpoint="odata/chatbot/setup" ref={ref}>
            <CardGroup name="setup" label="Configurazione" isOpen={true}>
                <CardField name="primaryKey" type={SystemFieldType.String} label="Chiave primaria" />
                <CardField name="botName" type={SystemFieldType.String} label="Nome bot" />
                <CardField name="welcomeMessage" type={SystemFieldType.String} label="Messaggio di benvenuto" />
                <CardField name="languageEndpointKey" type={SystemFieldType.String} label="Chiave lingua endpoint" />
                <CardField name="languageEndpointHostName" type={SystemFieldType.String} label="Endpoint lingua" />
                <CardField name="scoreThreshold" type={SystemFieldType.Number} label="Soglia punteggio" />
                <CardField name="topAnswers" type={SystemFieldType.Number} label="Migliori risponte" />
                <CardField name="isTest" type={SystemFieldType.Boolean} label="Test" />
                <CardField name="enablePreciseAnswer" type={SystemFieldType.Boolean} label="Abilita risposte precise" />
                <CardField name="includeUnstructuredSources" type={SystemFieldType.Boolean} label="Includi sorgenti non strutturate" />
                <CardField name="activeLearningCardText" type={SystemFieldType.String} label="Testo card" />
                <CardField name="activeLearningCardTitle" type={SystemFieldType.String} label="Titolo card" />
                <CardField name="cardNoMatchText" type={SystemFieldType.String} label="Card senza corrispondenza" />
                
                <CardField name="noAnswerText" type={SystemFieldType.String} label="Testo senza risposta" />
                <CardField name="displayPreciseAnswerOnly" type={SystemFieldType.Boolean} label="Visualizza solo risposte precise" />
                <CardField name="qaResourceName" type={SystemFieldType.String} label="Risorsa qa" />
                <CardField name="qaBaseEndpoint" type={SystemFieldType.String} label="Endpoint qa" />
                <CardField name="qaApiKey" type={SystemFieldType.String} label="QA api key" />
                <CardField name="qaDefaultLanguage" type={SystemFieldType.String} label="Lingua qa default" />
                <CardField name="qaProjectName" type={SystemFieldType.String} label="Nome progetto qa" />
            </CardGroup>
        </PageContainer>
    );
});