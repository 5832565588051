import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminItemRequirementCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Item Requirement Card"} endpoint="odata/ItemRequirement" ref={ref}>
            <CardGroup name="general" label="General" isOpen={true}>
                <CardField label="Source Item Id" name="sourceId" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="/odata/item" tableRelationKey="itemEcommerceCode" tableRelationField="description" tableRelationFilter="?$filter=itemEcommerceCode ne null and itemEcommerceCode gt ''" />
                <CardField label="Destination Item Id" name="destinationId" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="/odata/item" tableRelationKey="itemEcommerceCode" tableRelationField="description" tableRelationFilter="?$filter=itemEcommerceCode ne null and itemEcommerceCode gt ''" />
                <CardField label="Ask Confirm" name="askConfirm" type={SystemFieldType.Boolean} />
            </CardGroup>
        </PageContainer>
    );
});