import { Dialog, DialogFooter, DialogType, IModalProps, IStackStyles, PrimaryButton, Stack } from "@fluentui/react";
import { t } from "i18next";
import React from "react";

export interface IBookingModalProps {
    onSave: () => void;
    onCancel: () => void;
    showModal: boolean;
    url: string;
}

const stackTokens = { childrenGap: 40 };
const stackStyles: Partial<IStackStyles> = { root: { width: '100%' } };
const dialogContentProps = {
    type: DialogType.largeHeader,
    title: t("adminCenter:leadList:fields:bookingModalTitle")
};
const modalProps: Partial<IModalProps> = {
    isBlocking: true,
    styles: {
        main: {
            width: '95% !important',
            maxWidth: 'calc(95%) !important'
        }
    }
};

export class CommonBookingModal extends React.Component<IBookingModalProps, {}> {

    constructor(props: IBookingModalProps)
    {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <>
              <Dialog
                hidden={!this.props.showModal}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
                minWidth={900}
                maxWidth={1000}
              >
                <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                    <iframe src={this.props.url} width='100%'  style={{ 
                        border: 0,
                        height: 'calc(100vh - 200px)'
                    }}
                    ></iframe>
                </Stack>
                <DialogFooter>
                  <PrimaryButton onClick={() => {this.props.onSave()}} text="Ok" />
                </DialogFooter>
              </Dialog>
            </>
          );
    }
}