import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminPartnerInvoiceSetupCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda partner invoice setup"} endpoint="odata/partner/invoice/setup" ref={ref}>
            <CardGroup name="general" label="General" isOpen={true}>
                <CardField name="partnerId" type={SystemFieldType.String} label="Partner Id" hasTableRelation tableRelationEndpoint="/odata/partner" tableRelationKey="id" tableRelationField="name"/>
                <CardField name="zoneCode" type={SystemFieldType.String} label="Zone code" />
                <CardField name="countryRegionCode" type={SystemFieldType.String} label="Country region code" />
            </CardGroup>
            <CardGroup name="settings" label="Settings" isOpen={true}>
                <CardField name="handleCompleteAddress" type={SystemFieldType.Boolean} label="Handle complete address" />
                <CardField name="handleSdiCode" type={SystemFieldType.Boolean} label="Handle SDI code" />
            </CardGroup>
            <CardGroup name="erp" label="Parametri ERP cliente" isOpen={true}>
                <CardField name="erpGenBusPostingGroup" type={SystemFieldType.String} label="General Business Posting Group" />
                <CardField name="erpVATBusPostingGroup" type={SystemFieldType.String} label="VAT Business Posting Group" />
                <CardField name="erpCustomerPostingGroup" type={SystemFieldType.String} label="Customer Posting Group" />
                <CardField name="erpLanguageCode" type={SystemFieldType.String} label="Language Code" />
                <CardField name="erpCurrencyCode" type={SystemFieldType.String} label="Currency Code" />
                <CardField name="erpPaymentTermsCode" type={SystemFieldType.String} label="Payment Terms Code" />
                <CardField name="erpPaymentMethodCode" type={SystemFieldType.String} label="Payment Method Code" />
                <CardField name="erpReminderTermsCode" type={SystemFieldType.String} label="Reminder Terms Code" />
                <CardField name="erpSalesPersonCode" type={SystemFieldType.String} label="Sales Person Code" />
                <CardField name="erpChannelDimension" type={SystemFieldType.String} label="Channel dimension" />
                <CardField name="erpEosOurBankAccount" type={SystemFieldType.String} label="Id Bank Account" />
            </CardGroup>
            <CardGroup name="erp" label="Parametri ERP fatture" isOpen={true}>
                <CardField name="erpPostingDescription" type={SystemFieldType.String} label="Posting description" />
                <CardField name="erpDiscountDescription" type={SystemFieldType.String} label="Discount description" />
                <CardField name="erpDiscountItemNo" type={SystemFieldType.String} label="Discount Item No" />
                <CardField name="erpAccrualTemplate" type={SystemFieldType.String} label="Accrual Template" />
            </CardGroup>
        </PageContainer>
    );
});
