import { Label, mergeStyles, Stack } from "@fluentui/react";
import { CommonFunctions } from "../../../ApplicationCode/Common/CommonFunctions";

export interface IDemoMicrosoftFeatureBlockProps {
    description: string;
    docLink: string;
    keyVal: string;
}

const stackStyles = mergeStyles([{
    width: '20%',
    height: 150,
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center',
    padding: 15,
    backgroundColor: '#29508f',
    margin: 20,
    textAlign: 'center',
    borderRadius: 10,
    cursor: 'pointer',
    selectors: {
        "&:hover": {
            backgroundColor: '#3160ad'
        }
    }
}]);

export const DemoMicrosoftFeatureBlock = (props: IDemoMicrosoftFeatureBlockProps) => {
    return (
        <Stack key={props.keyVal} 
               className={stackStyles}
               onClick={() => {
                if (props.docLink != "")
                    window.open(props.docLink)
                CommonFunctions.logEvent("Apertura link funzionalità Microsoft " + props.description, props.docLink);
               }}>
            <Label style={{
                fontSize: 19,
                color: 'white',
                cursor: 'pointer'
            }}>
                {props.description}
            </Label>
        </Stack>
    );
}