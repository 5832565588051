import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminCustomerInteractionTelemetryCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card}
                title={"Scheda Customer Interaction Telemetry"} 
                endpoint="odata/customer/interaction/telemetry"
                ref={ref}>
            <CardGroup name="general" label="General" isOpen={true}>
                <CardField name="entryNo" type={SystemFieldType.Number} label="Id" readonly />
                <CardField name="customerId" type={SystemFieldType.Number} label="Id Customer" readonly />
                <CardField name="tenantId" type={SystemFieldType.String} label="Id Tenant" readonly />
                <CardField name="sourceSystemId" type={SystemFieldType.String} label="System Id Sorgente" />
                <CardField name="sourceId" type={SystemFieldType.String} label="Id Sorgente" />
                <CardField name="session" type={SystemFieldType.String} label="Session" />
                <CardField name="mainPhase" type={SystemFieldType.Boolean} label="Main Phase" />
                <CardField name="phase" type={SystemFieldType.Number} label="Phase" />
                <CardField name="taskTitle" type={SystemFieldType.String} label="Titolo Task" />
                <CardField name="taskSubtitle" type={SystemFieldType.String} label="Sottotitolo Task" />
                <CardField name="dimension1" type={SystemFieldType.Boolean} label="Dimensione 1" />
                <CardField name="dimension2" type={SystemFieldType.Boolean} label="Dimensione 2" />
                <CardField name="message" type={SystemFieldType.String} label="Messaggio" />
                <CardField name="actionType" type={SystemFieldType.String} label="Tipo Azione" />
                <CardField name="userId" type={SystemFieldType.String} label="Id User" />
                <CardField name="ipAddress" type={SystemFieldType.String} label="Ip Address" />
                <CardField name="startDateTime" type={SystemFieldType.Date} label="Data/Ora Inizio" />
                <CardField name="endDateTime" type={SystemFieldType.Date} label="Data/Ora Fine" />
            </CardGroup> 
        </PageContainer>
    );
});