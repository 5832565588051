import { Checkbox, ComboBox, DatePicker, IComboBoxOption, Spinner, TextField } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { CommonFunctions } from "../../../../ApplicationCode/Common/CommonFunctions";
import { CommonTemplateBuilder, ITemplateBuilderOptions } from "../../../../ApplicationCode/Common/CommonTemplateBuilder";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { SystemFieldType, SystemTableRelationEntry } from "../../../../Model/SystemModels";
import { ICardFieldProps } from "../Card/CardField";

export interface ICommonControl {
    value: any;
    fieldProperty: ICardFieldProps;
    builderOptions: ITemplateBuilderOptions;
    editable: boolean;
    onValidate: (fieldName: string, fieldCaption: string, record: any, oldValue: any, newValue: any) => any;
}

export const CommonControl: React.FC<ICommonControl> = (props) => {
    const [value, setValue] = useState(props.value);
    const [fileName, setFileName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (props.value != value)
            setValue(props.value);

    }, [props.value])

    const formatDate = (dateStr?: string): Date | undefined => {
        if (dateStr != undefined)
        {
            let convert: Date = new Date(dateStr);
            if (convert.getFullYear() == 1)
                return undefined;
                
            return convert;
        }

        return undefined;
    }

    const processFieldDependencies = (tableRelationEntry: SystemTableRelationEntry) : SystemTableRelationEntry =>
    {
        if (tableRelationEntry.filter == undefined)
            return tableRelationEntry;
            
        tableRelationEntry.relatedFields = [];
        let currentRecord = props.builderOptions.getSelectedRecord();
        if (currentRecord == undefined)
            currentRecord = {};

        let keys = Object.keys(currentRecord);
        tableRelationEntry.filter = tableRelationEntry.notParsedFilter;
        for (let i = 0; i < keys.length; i++)
        {
            let fieldKeyword = "#" + keys[i] + "#";
            if (tableRelationEntry.filter.indexOf(fieldKeyword) >= 0)
            {
                let stringValue: string = CommonFunctions.convertString(String(Object.values(currentRecord)[i]));
                tableRelationEntry.filter = tableRelationEntry.filter.replace(fieldKeyword, stringValue);
                tableRelationEntry.relatedFields.push(keys[i]);
            }
        }

        return tableRelationEntry;
    }

    const processOptions = (tableRelationEntry: SystemTableRelationEntry) : IComboBoxOption[] =>
    {
        let options: IComboBoxOption[] = [];
        for (let i = 0; i < tableRelationEntry.data.length; i++)
        {
            let currentData = tableRelationEntry.data[i];

            // Getting key value
            let index = Object.keys(currentData).indexOf(tableRelationEntry.keyField);
            let keyValue: any = Object.values(currentData)[index];

            // Getting description value
            index = Object.keys(currentData).indexOf(tableRelationEntry.dropDownField);
            let descriptionValue: any = Object.values(currentData)[index];

            let option: IComboBoxOption = {} as IComboBoxOption;
            option.key = keyValue;
            option.text = descriptionValue;
            option.selected = (value == keyValue);
            options.push(option);
        }

        return options;
    }

    const processTableRelation = (tableRelationEntry: SystemTableRelationEntry) =>
    {
        tableRelationEntry = processFieldDependencies(tableRelationEntry);
        let client = HttpHelper.buildAxiosInstance();
        let url = tableRelationEntry.endpoint;
        if (tableRelationEntry.filter != undefined)
            url += tableRelationEntry.filter;

        client.get(url)
            .then((resp) => {
                tableRelationEntry.data = resp.data;
                tableRelationEntry.loaded = true;
                props.builderOptions.setTableRelationData!(tableRelationEntry);
            })
            .catch((err) => {
            })
    }

    if (props.fieldProperty.hasTableRelation)
    {
        let tableRelationEntry = props.builderOptions.getTableRelationData!(props.fieldProperty.name) as SystemTableRelationEntry | undefined;

        let options : IComboBoxOption[] = [];

        if (tableRelationEntry != undefined)
        {
            if (tableRelationEntry.loaded)
                options = processOptions(tableRelationEntry);
            else
            {
                let opt: IComboBoxOption = {
                    key: value,
                    text: tableRelationEntry.currentValue
                };

                if (value === "")
                    opt.text = "";

                options.push(opt);
            }
        }

        return (
            (props.fieldProperty === undefined || !props.fieldProperty.allowFreeForm) ?
            <ComboBox label={props.fieldProperty.label} useComboBoxAsMenuWidth onRenderLowerContent={() => {
                if (! tableRelationEntry!.loaded)
                {
                    processTableRelation(tableRelationEntry!);
                    return (
                        <Spinner label="Stiamo caricando i dati..." ariaLive="assertive" labelPosition="right" style={{
                            padding: 10,
                            width: '200px'
                        }} />
                    );
                }
                return <></>;
            }} 
            errorMessage={tableRelationEntry == undefined ? "" : tableRelationEntry!.errorMessage}
            options={options}
            selectedKey={value} 
            onChange={(e, opt, index) => {
                setValue(opt?.key);
                props.onValidate(props.fieldProperty.name, 
                    props.fieldProperty.label,
                    props.builderOptions.getSelectedRecord(), 
                    props.builderOptions.getRecordFieldValue!(props.fieldProperty.name), 
                    opt?.key);
            }} 
            disabled={! props.editable} /> 
            :
            <TextField
                label={props.fieldProperty.label}
                name={props.fieldProperty.name}
                onChange={(ev, value) => {
                    if (props.fieldProperty.maxLength! > 0)
                    {
                        if (value!.length > props.fieldProperty.maxLength!)
                            value = String(value).substring(0, props.fieldProperty.maxLength!);
                    }
                    setValue(value);
                }}
                onBlur={(e) => {
                    props.onValidate(props.fieldProperty.name,
                        props.fieldProperty.label,
                        props.builderOptions.getSelectedRecord(),
                        props.builderOptions.getRecordFieldValue!(props.fieldProperty.name),
                        value);
                }}
                value={value}
                multiline={props.fieldProperty.multiline}
                disabled={! props.editable} />
        )
    }


    switch(props.fieldProperty.type)
    {
        case SystemFieldType.String:
            let rows: number = 1;
            if (props.fieldProperty.multiline)
                rows = 6;
            
            return (
                    (props.fieldProperty.hidden) ? <></> :
                    <TextField
                        label={props.fieldProperty.label} 
                        name={props.fieldProperty.name} 
                        onChange={(ev, value) => {
                            if (props.fieldProperty.maxLength! > 0)
                            {
                                if (value!.length > props.fieldProperty.maxLength!)
                                    value = String(value).substring(0, props.fieldProperty.maxLength!);
                            }
                            setValue(value);
                        }}
                        onBlur={(e) => {
                            props.onValidate(props.fieldProperty.name, 
                                             props.fieldProperty.label,
                                             props.builderOptions.getSelectedRecord(), 
                                             props.builderOptions.getRecordFieldValue!(props.fieldProperty.name), 
                                             value);
                        }}
                        value={value}
                        multiline={props.fieldProperty.multiline} 
                        rows={rows} 
                        disabled={! props.editable}
                        placeholder={props.fieldProperty.placeholder} />
            );
        case SystemFieldType.HTML:
            const parse = require('html-react-parser');
            let html: string;

            try {
                html = atob(value);
            }
            catch {
                html = value;
            }

            return(
                <div style={{
                    maxHeight: '300px',
                    width: '85vw',
                    overflow: 'scroll'
                }}>{parse(''+html+'')}</div>
            );
        case SystemFieldType.Number:
            return(
                <TextField 
                    type="number" 
                    label={props.fieldProperty.label} 
                    name={props.fieldProperty.name} 
                    onBlur={(e) => {
                        props.onValidate(props.fieldProperty.name, 
                            props.fieldProperty.label,
                            props.builderOptions.getSelectedRecord(), 
                            props.builderOptions.getRecordFieldValue!(props.fieldProperty.name), 
                            value);
                    }}
                    onChange={(ev, value) => {
                        setValue(value)
                    }} 
                    value={value} 
                    disabled={! props.editable} />
            );
        case SystemFieldType.Boolean:
            return(
                <Checkbox 
                    label={props.fieldProperty.label} 
                    name={props.fieldProperty.name} 
                    onChange={(ev, value) => {
                        setValue(value);
                        props.onValidate(props.fieldProperty.name, 
                            props.fieldProperty.label,
                            props.builderOptions.getSelectedRecord(), 
                            props.builderOptions.getRecordFieldValue!(props.fieldProperty.name), 
                            value);
                    }} 
                    checked={value}
                    styles={{
                        root: {
                            height: 62,
                            display: 'flex',
                            alignItems: 'center'
                        }
                    }}  
                    disabled={! props.editable} />
            );
        case SystemFieldType.Option:
            return(
                <ComboBox 
                    label={props.fieldProperty.label} 
                    options={props.fieldProperty.cmbOptions ? props.fieldProperty.cmbOptions : []} 
                    onChange={(ev, value) => {
                        setValue(value?.key);
                        props.onValidate(props.fieldProperty.name, 
                            props.fieldProperty.label,
                            props.builderOptions.getSelectedRecord(), 
                            props.builderOptions.getRecordFieldValue!(props.fieldProperty.name), 
                            value?.key);
                    }} 
                    styles={{
                        root: {
                            height: 32
                        }
                    }}
                    selectedKey={value}  
                    disabled={! props.editable}
                    autoComplete="on"
                    defaultSelectedKey={props.fieldProperty.defaultSelectedKey} />
            );
        case SystemFieldType.Blob:
            return (
                <TextField 
                    label={props.fieldProperty.label} 
                    placeholder="Clicca per caricare un file" 
                    iconProps={{
                        iconName: "Upload",
                        styles: {
                            root: {
                                cursor: 'pointer'
                            }
                        }
                    }}
                    onClick={() => {
                        var builder=this;
                        let input = document.createElement('input');
                        input.type = 'file';
                        input.accept = props.fieldProperty.ext!;
                        input.onchange = _this => {
                            let files =   Array.from(input.files!);
                            var reader = new FileReader();
                            reader.readAsDataURL(files[0])

                            reader.onloadend = function () {
                                setValue(reader.result?.toString().substring(22,reader.result?.toString().length));
                                props.onValidate(props.fieldProperty.name, 
                                    props.fieldProperty.label,
                                    props.builderOptions.getSelectedRecord(), 
                                    props.builderOptions.getRecordFieldValue!(props.fieldProperty.name), 
                                    reader.result?.toString().substring(22,reader.result?.toString().length));
                            };

                            reader.onerror = function (error) {
                                console.log('Error: ', error);
                            };
                        };
                    input.click();
                }}
                value={value}  
                disabled={! props.editable} />
            );
        case SystemFieldType.Attachment:
            const getLabelText = () : string => {
                let labelText = props.fieldProperty.label;
                if (fileName !== undefined && fileName > '')
                    labelText += " (" + fileName + ")";
                    
                return labelText;
            }
            return (
                <>
                    <TextField
                        label={getLabelText()}
                        placeholder="Clicca per caricare un file"
                        iconProps={{
                            iconName: "Upload",
                            styles: {
                                root: {
                                    cursor: 'pointer'
                                }
                            }
                        }}
                        onClick={() => {
                            var input: HTMLInputElement = document.createElement('input');
                            input.type = 'file';
                            input.accept = props.fieldProperty.ext!;
                            input.onchange = _this => {
                                let files =   Array.from(input.files!);
                                
                                var reader = new FileReader();
                                
                                reader.readAsDataURL(files[0])                                
                                
                                setErrorMessage("");
                                const exts: string[] = props.fieldProperty.ext!.toLowerCase().replaceAll(".","").split(",");
                                const fileExt: string = files[0].name?.toLowerCase().toString().split(".").pop()!;
                                
                                if (!exts.includes(fileExt))
                                    setErrorMessage("Non puoi allegare file "+fileExt);                                    

                                reader.onloadend = function () {
                                    let currentValue: string | undefined = "";

                                    if (props.fieldProperty.valueWithoutBlobType) {
                                        currentValue = reader.result?.toString();
                                        let comma: number | undefined = currentValue?.indexOf(",");
                                        
                                        if (comma === undefined)
                                            comma = 0;
                                        else
                                            comma += 1;

                                        console.log(comma);

                                        currentValue = currentValue?.substring(comma, currentValue?.length);
                                        setValue(currentValue);
                                    }
                                    else {
                                        currentValue = reader.result?.toString();
                                        setValue(currentValue);
                                    }

                                    console.log(currentValue);

                                    setFileName(files[0].name?.toString());
                                    props.onValidate(props.fieldProperty.name,
                                        props.fieldProperty.label,
                                        props.builderOptions.getSelectedRecord(),
                                        props.builderOptions.getRecordFieldValue!(props.fieldProperty.name),
                                        currentValue,
                                    );
                                };
                                
                                reader.onerror = function (error) {
                                    console.log('Error: ', error);
                                };
                            };
                            input.click();
                        }}
                        value={value}
                        disabled={! props.editable}
                        errorMessage={errorMessage}
                    />
                </>
            );
        case SystemFieldType.TimeSpan:
            return(
                <TextField 
                    type="time" 
                    label={props.fieldProperty.label} 
                    title={props.fieldProperty.name} 
                    onChange={(ev, value) => {
                        setValue(value);
                    }}
                    onBlur={(e) => {
                        props.onValidate(props.fieldProperty.name, 
                            props.fieldProperty.label,
                            props.builderOptions.getSelectedRecord(), 
                            props.builderOptions.getRecordFieldValue!(props.fieldProperty.name), 
                            value);
                    }}
                    value={value} 
                    disabled={! props.editable} />
            );
        case SystemFieldType.Date:
            return(
                    <DatePicker
                        placeholder={props.fieldProperty.label}
                        label={props.fieldProperty.label}
                        value={formatDate(value)}
                        formatDate={(date) => {
                            let days, month, year;

                            if (date != undefined)
                            {
                                days = date.getDate();
                                month = date.getMonth() + 1;
                                year = date.getFullYear();
                                return days + "/" + month + "/" + year;
                            }
                            return "";
                        }}
                        onSelectDate={(date) => {
                            date!.setHours(date!.getHours() + 3);
                            setValue(date);
                            props.onValidate(props.fieldProperty.name, 
                                props.fieldProperty.label,
                                props.builderOptions.getSelectedRecord(), 
                                props.builderOptions.getRecordFieldValue!(props.fieldProperty.name), 
                                date);
                        }}
                        disabled={! props.editable}
                    />
            );
    }

    return <></>;
}