import { Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";
import { useEffect, useState } from "react";
import { ToastService } from "../../../../Core/Toast/ToastService";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";

export interface IAdminBusinessCentralAppUploaderProps {
    showDialog: boolean;
    onSuccess: () => void;
    onDismiss: () => void;
}

export const AdminBusinessCentralAppUploader : React.FC<IAdminBusinessCentralAppUploaderProps>  = (props) => {
    const [subText, setSubText] = useState<string>("Carica un file .app per aggiornare l'estensione");
    const [isError, setIsError] = useState<boolean>(false);
    const [formData, setFormData] = useState<FormData | undefined>(undefined);

    const adminService = new AdminService();

    const uploadFile = () => {
        let input = document.createElement('input');
        input.type = 'file';
        input.accept = ".app";

        input.onchange = () => {
            if(input.files![0] === null)
                return;

            const currformData:FormData = new FormData();
            currformData.append('file', input.files![0], input.files![0].name);

            setFormData(currformData);
            setSubText(input.files![0].name);
            setIsError(false);
        };

        input.click();
    }

    const sendRequestedFile = () => {
        if(formData === undefined){
            setIsError(true);
            setSubText("Nessun file selezionato");
            return;
        }

        ToastService.showPromiseMessage(
            adminService.uploadExtensionFile(formData).then(()=>{
                props.onSuccess();
            })
            .catch((err)=>{
                setIsError(true);
                setSubText(err.response.data.message);
            }),
            "Caricamento estensione in corso...",
            "Caricamento estensione completato con successo!",
            "Errore durante il caricamento dell'estensione"
        );
    }

    useEffect(() => {
        setIsError(false);
        setFormData(undefined);
        setSubText("Carica un file .app per aggiornare l'estensione");
    }, [props.showDialog])
    

    return(
        <Dialog
            hidden={!props.showDialog}
            onDismiss={props.onDismiss}
            dialogContentProps={{
                title: "Carica file",
                subText: subText,
                type: DialogType.largeHeader,
                styles:{
                    subText:{
                        color: isError ? "red" : "black",
                        fontStyle: isError ? "italic" : "normal"
                    }
                }
            }}
            modalProps={{
                isBlocking: true,
                dragOptions: undefined
            }}
        >
            <DialogFooter>
                <PrimaryButton
                    styles={{
                        root:{
                            width: "100%"
                        }
                    }}
                    text="Carica" 
                    onClick={()=>{
                        sendRequestedFile();
                    }} 
                />
                <PrimaryButton
                    styles={{
                        root:{
                            width: "100%"
                        }
                    }}
                    text="Scegli" 
                    onClick={()=>{
                        uploadFile();
                    }} 
                />
            </DialogFooter>
        </Dialog>
    );
}