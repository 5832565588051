import React, { useRef } from "react";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { SystemActionCategory,SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { AdminSalesInvoiceHeaderCard } from "../AdminSalesInvoiceHeaderCard/AdminSalesInvoiceHeaderCard";

export const AdminSalesInvoiceHeaderList : React.FC = () => {
    const navigate = useNavigate();
    const adminService = new AdminService();
    const cardRef = useRef<PageContainerBase>(null);

    const sendInvoiceToErp = (invoiceId:number) => {
        adminService.sendInvoiceToErpEos(invoiceId).then((rerp) => {
            ToastService.showMessage(MessageType.Success, "Invio effettuato con successo");
        })
        .catch((jqXHR)=>{
            ToastService.showMessage(MessageType.Error, "Sono stati rilevati alcuni problemi durante l'invio");
        });
    }
    
    return (
        <>
            <PageContainer endpoint={"/odata/sales/invoice?$orderby=systemCreationDate desc"} pageType={SystemPageType.List} title="Lista fatture" cardRef={cardRef} backAllowed showAllowed updateAllowed refreshAllowed>                            
            <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="OpenInvoiceLine" label="Dettaglio fattura" iconName="BulletedList2" runOnRec isPromoted onClick={(req:any)=>{
                                navigate('/app/admin/administrative/invoiceLineList?headerId='+req.id);
                            }}>
                        </ActionEntry>
                    </ActionArea>
                    <ActionArea category={SystemActionCategory.Process}>
                        <ActionEntry name="OpenInvoiceLine" label="Invia a ERP" iconName="Send" runOnRec onClick={(req:any)=>{
                                sendInvoiceToErp(req.id);
                            }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Numero Documento" fieldName="documentNo" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="ERP Sync Status" fieldName="erpSyncStatus" minWidth={150} maxWidth={250} onRender={(item: any, index, column) => {
                        if (item.erpSyncStatus === 0)
                            return "Waiting"
                        else if (item.erpSyncStatus === 10)
                            return "In Progress"
                        else if (item.erpSyncStatus === 20)
                            return "Sent"
                        else if (item.erpSyncStatus === 30)
                            return "Error"
                        else if (item.erpSyncStatus === 40)
                            return "Posponed"
                    }} />
                    <ListHeaderEntry name="Erp Sync Error" fieldName="erpSyncError" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id Partner" fieldName="partnerId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id Customer" fieldName="customerId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Posting Date" fieldName="postingDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Numero Ordine ERP" fieldName="erpOrderNo" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Numero ERP Cliente" fieldName="erpCustomerNo" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="ERP Sales Pearson Code" fieldName="erpSalesPearsonCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Inizio Periodo" fieldName="periodStart" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Fine Periodo" fieldName="PeriodEnd" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="System Id" fieldName="systemId" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="System Creation User Id" fieldName="systemCreationUserId" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="System Creation Date" fieldName="systemCreationDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Data aggiornamento sistema" fieldName="systemUpdateDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id utente aggiornamento sistema " fieldName="systemUpdateUserId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Timestamp" fieldName="timestamp" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id" fieldName="id" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminSalesInvoiceHeaderCard ref={cardRef} />
        </>
    );
}