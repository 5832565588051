import { HttpHelper } from "../../Core/Http/HttpHelper";
import { AdminDashboardCue, AdminHelpdeskDashboardCue, AssignedChatbotBusinessLine, BCNProcessTest, BusinessCentralCompany, StripeCouponRequest, StripeProduct } from "../../Model/AdminModel";
import { CustomerDigitalContract } from "../../Model/CustomerModels";
import { Customer, Lead } from "../../Model/PartnerModels";
import { Customer as CustomerModel } from "../../Model/CustomerModels";
import { PageMetadata, PageStructureMetadata, PostalCode, SystemApp, SystemEntity, SystemEntityProperty, SystemPage, SystemRole, SystemUser } from "../../Model/SystemModels";
import { IndustyType, LeadIndustry } from "../../Model/DemoModels";
import { CrmLostRiskLine, LeadConfigChecklistDetails } from "../../Components/Common/LeadDashboard/LeadDashboard";
import { OpenAPICompanyITAdvancedResponse } from "../../Model/OpenAPIModel";

export class AdminService {
    
    public loadEntities() : Promise<SystemEntity[]>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "odata/system/entity";

        return new Promise((resolve, reject) => {
            instance.get(url).then((resp) => {
                let resultArray: SystemEntity[] = resp.data;
                resolve(resultArray);
            })
            .catch((resp) => {
                reject(resp.response.data);
            });
        });
    }


    public loadEntityProperties(currentPage: SystemPage) : Promise<SystemEntityProperty[]> {
        return new Promise<SystemEntityProperty[]>((resolve, reject) => {
            let instance = HttpHelper.buildAxiosInstance();
            instance.get("odata/system/entity/property?$filter=entityName eq '" + currentPage.sourceEntityName + "'")
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch((err) => {
                    console.log(err);
                })
        });
    } 

    public loadCurrentPage(systemId: string) : Promise<PageMetadata> {
        return new Promise<PageMetadata>((resolve, reject) => {
            let instance = HttpHelper.buildAxiosInstance();
            instance.get("api/system/metadata/page/" + systemId)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch((err) => {
                    console.log(err);
                })
        });
    } 

    public loadCurrentPageStructure(systemPage: SystemPage) : Promise<PageStructureMetadata[]> {
        return new Promise<PageStructureMetadata[]>((resolve, reject) => {
            let instance = HttpHelper.buildAxiosInstance();
            instance.get("api/system/metadata/page/" + systemPage.systemId + "/structure")
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch((err) => {
                    console.log(err);
                })
        });
    }
    
    public getCustomerById(customerId:number) : Promise<Customer>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/odata/customer?$filter=id eq "+customerId;
        return new Promise((resolve,reject)=>{
            instance.get(url)
            .then((resp)=>{
                resolve(resp.data[0]);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }
    
    public getEntityNameFromParamter(eventName:string, parameterName:string) : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/odata/system/event/parameter?$filter=eventName eq '"+eventName+"' and parameterName eq '"+parameterName+"'";
        return new Promise((resolve,reject)=>{
            instance.get(url)
            .then((resp)=>{
                resolve(resp.data[0]["EntityName"]);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }
    
    public getAdminDashboardCue() : Promise<AdminDashboardCue>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/System/admin/dashboard/cue";
        return new Promise((resolve,reject)=>{
            instance.get(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }
    
    public getAdminHelpdeskDashboardCue() : Promise<AdminHelpdeskDashboardCue>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/System/admin/helpdesk/dashboard/cue";
        return new Promise((resolve,reject)=>{
            instance.get(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public uninstallApp(appId:string) : Promise<Boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/BusinessCentral/app/"+appId+"/uninstall";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }
    
    public createBaseContract(customerId:number) : Promise<Boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CustomerDigitalContract/createStandardContract/"+customerId;
        return new Promise((resolve,reject)=>{
            instance.post(url,{customerId})
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public sendEmailReadyEnvironments(customer:CustomerModel) : Promise<Boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/sales/sendreadyenvironmentsmailtocustomer";
        return new Promise((resolve,reject)=>{
            instance.post(url, customer)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }
    
    public createFESetupChecklist(customerId:number) : Promise<Boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/telemetry/customer/progress/checklist/fesetup/generate/"+customerId;
        return new Promise((resolve,reject)=>{
            instance.post(url,{customerId})
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }
    
    public createCustomerOnBCEos(customerId:number) : Promise<Boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/ecommerce/customer/"+customerId+"/createCustomerOnBCEos";
        return new Promise((resolve,reject)=>{
            instance.post(url,null)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }
    
    public syncCustomerOnCrmEos(customerId:number) : Promise<Boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CRM/account/syncronize/"+customerId
        return new Promise((resolve,reject)=>{
            instance.post(url,null)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }
    
    public reloadContractStatus(contractId:string) : Promise<Boolean>
    {
        if (contractId === undefined || contractId === null)
            contractId = "";
        
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CustomerDigitalContract/ix/contractstatus?ContractId="+contractId;
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }
        
    public updateExpirationDate(contractId:number) : Promise<Boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CustomerDigitalContract/namirial/envelope/update/status/"+contractId;
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }
        
    public restartExpirationDate(contractId:number) : Promise<Boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CustomerDigitalContract/namirial/envelope/restart/expiration/"+contractId;
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public reloadContractsStatus() : Promise<Boolean>
    {
        let instance = HttpHelper.buildAxiosInstance(900000);
        let url = "api/CustomerDigitalContract/ix/contractstatus/all";
        return new Promise((resolve, reject) => {
            instance.post(url)
            .then((resp) => {
                resolve(true);
            })
            .catch((resp) => {
                reject(resp.message.data);
            })
        })
    }

    public sendContractToArxivar(contractId:string) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CustomerDigitalContract/ix/contractmanualupload?contractId="+contractId;
        return new Promise((resolve,reject)=>{
            instance.get(url)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }

    public regenerateContract(contract:CustomerDigitalContract) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CustomerDigitalContract/regenerate";
        return new Promise((resolve,reject)=>{
            instance.post(url, contract)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public initializeFeSetup(contract:CustomerDigitalContract) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CustomerDigitalContract/initialize/fe/setup";
        return new Promise((resolve,reject)=>{
            instance.post(url, contract)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public manageContract(contract:CustomerDigitalContract) : Promise<Boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CustomerDigitalContract/manage/contract/"+contract.id;
        return new Promise((resolve,reject)=>{
            instance.post(url, contract)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public downloadMailTemplate(templateLanguageReferenceId:string) : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/System/emailtemplate/"+templateLanguageReferenceId+"/download";
        return new Promise((resolve,reject)=>{
            instance.get(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }

    public downloadMailStructure(structureCode: string) : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/System/emailstructure/"+structureCode+"/download";
        return new Promise((resolve,reject) => {
            instance.get(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }

    public downloadMailAttachment(entryNo:number) : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/system/email/attachment/"+entryNo+"/download"
        return new Promise((resolve,reject)=>{
            instance.post(url, null, {responseType: 'blob'})
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }

    public initEmailTemplateLanguage() : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/system/email/template/language/init"
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }

    public uploadMailTemplate(templateCode:string, templateLanguageReferenceId:string,file:File) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/System/emailtemplate/"+templateCode+"/" + templateLanguageReferenceId + "/upload";
        let fileUpload = new FormData();
        fileUpload.append('file',file)
        return new Promise((resolve,reject)=>{
            instance.post(url,fileUpload,{
                headers: {
                    'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
                }
            })
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(false);
            })
        })
    }

    public uploadMailStructure(structureCode: string, file:File) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/System/emailstructure/"+structureCode+"/upload";
        let fileUpload = new FormData();
        fileUpload.append('file', file);
        return new Promise((resolve,reject) => {
            instance.post(url, fileUpload, {
                headers: {
                    'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
                }
            })
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(false);
            })
        })
    }

    public uploadMailAttachment(templateCode: string, legalEntityCode: string, distributorCode: string, partnerBusinessContractCode: string, languageCode: string, file: File) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/System/email/"+templateCode+"/" + legalEntityCode + "/" + distributorCode + "/" + partnerBusinessContractCode + "/" + languageCode + "/attachment/upload";
        let fileUpload = new FormData();
        fileUpload.append('file', file);

        return new Promise((resolve,reject)=>{
            instance.post(url, fileUpload, {
                headers: {
                    'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
                }
            })
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(false);
            })
        })
    }

    public setUserPermission(role:{}) : Promise<{}>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/System/user/permission/app/set";
        return new Promise((resolve,reject)=>{
            instance.post(url,role)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public updateCustomersKPI() : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/setup/customer/updatekpi";
        return new Promise((resolve,reject)=>{
            instance.post(url, null)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }

    public customerChallenge(customerId:number) : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/BusinessCentral/customer/"+customerId+"/challenge";
        return new Promise((resolve,reject)=>{
            instance.get(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }

    public customerCreateSoSmartAdminUser(customerId:number) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CSP/customer/"+customerId+"/createSoSmartAdminUser";
        return new Promise((resolve,reject)=>{
            instance.post(url, null)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }

    public syncEnvironments(customerId:number) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/businesscentral/customer/"+customerId+"/environments/sync";
        return new Promise((resolve,reject)=>{
            instance.post(url, null)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }

    public copyProductionToEducational(customerId:number) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/businesscentral/customer/"+customerId+"/copyproduction";
        return new Promise((resolve,reject)=>{
            instance.post(url, null)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public removeAutorenew(customerId:number) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/csp/customer/"+customerId+"/removeautorenew";
        return new Promise((resolve,reject)=>{
            instance.post(url, null)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }

    public createTaskGroup(groupCode:string,customerId:string) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/Telemetry/customer/progress/checklist/copy/"+customerId+"/"+groupCode+"";
        return new Promise((resolve,reject)=>{
            instance.post(url, null)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }

    public createCustomerTaskGroup(template:any,customerId:string) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/Telemetry/customer/progress/checklist/create/"+customerId;
        return new Promise((resolve,reject)=>{
            instance.post(url, template)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }

    public syncronizeAzureAadGuest() : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CSP/syncronize/azure/aad/guest";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }

    public inviteUserInAzureAD(userId: number) : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CSP/user/"+userId+"/guest/invitation";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }

    public cleanAzureAADGuest(customerId: number) : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CSP/customer/"+customerId+"/aad/guest/clean";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }

    public resetPrivateUserPassword(user: SystemUser) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/system/user/private/password/reset";
        return new Promise((resolve,reject)=>{
            instance.post(url, user)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }

    public syncCrmAccount() : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CRM/syncronize/account";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }

    public syncCrmContact() : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CRM/syncronize/contact";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }

    public syncCrmEntitlements() : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CRM/entitlement/syncronize";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve("Sincronizzazione Entitlements completata con successo");
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public syncCrmIncidents() : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CRM/incident/syncronize";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve("Sincronizzazione Incidents completata con successo");
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public syncCrmModifiedIncidents() : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CRM/incident/modified/syncronize";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve("Sincronizzazione Incidents completata con successo");
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public syncCrmSystemUsers() : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CRM/systemuser/syncronize";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve("Sincronizzazione System Users completata con successo");
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public mapIncidents() : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CRM/incident/map";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve("Mappatura Incidents completata con successo");
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public syncPartners() : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CRM/partner/syncronize";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve("Sincronizzazione Partner completata con successo");
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public syncPartnersUsers() : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CRM/partner/user/syncronize";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve("Sincronizzazione Utenti Partner completata con successo");
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public syncPartnerUsers(partnerId: number) : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CRM/partner/"+partnerId+"/user/syncronize";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve("Sincronizzazione Utenti Partner completata con successo");
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public syncAllKnowledgeArticles(syncModified: boolean) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CRM/knowledge/article/sync/";
        
        if (syncModified)
            url += "modified";
        else
            url += "all";

        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then(()=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }
    
    public syncCrmIncidentTypes() : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CRM/incident/type/syncronize";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve("Sincronizzazione Incident Types completata con successo");
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public syncCrmInternalPriorities() : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CRM/internal/priority/syncronize";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve("Sincronizzazione Internal Priorities completata con successo");
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public sendInvoiceToErpEos(invoiceId:number) : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/ecommerce/erp_eos/sendinvoice/"+invoiceId;
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(resp.data[0]);
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }

    public changeCustomerDsStatus(dsCode:string,customerId:string) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/Setup/customer/"+customerId+"/DS/status/change/"+dsCode;
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }

    public getDsStatusValue(entityName:string) : Promise<any>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/odata/datasecurity/status?$filter=entityName eq '"+entityName+"'";
        return new Promise((resolve,reject)=>{
            instance.get(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }

    public getPartners() : Promise<any>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/odata/Partner";
        return new Promise((resolve,reject)=>{
            instance.get(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }

    public getUsers() : Promise<any>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/odata/user";
        return new Promise((resolve,reject)=>{
            instance.get(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }
    
    public syncBCNProcessTranslations() : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/businesscentral/translation/download";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }    

    public elaborateVideo() : string
    {
        try {
            let instance = HttpHelper.buildAxiosInstance();
            let url = "api/crm/knownledge/elaborate/video";
            instance.post(url);
            return "Elaborazione video lanciata con successo";
        }
        catch
        {
            return "Si è verificato un errore lanciando l'elaborazione video";
        }
    }
    
    public createTeamsGroupFromSetup(group:any) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/System/notification/group/create";
        return new Promise((resolve,reject)=>{
            instance.post(url,group)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }
    
    public syncTeamsChannels() : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/System/notification/group/teams/syncronize";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve("Sincronizzazione canali Teams completata con successo");
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }

    public sendNotification(group: string, notificationTemplate: any) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/System/notification/group/" + group + "/send";
        return new Promise((resolve,reject)=>{
            instance.post(url, notificationTemplate)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject();
            })
        })
    }
    
    public downloadCSPCustomersSubscriptions() : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CSP/customer/subscription/download";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve("Sincronizzazione Clienti e Sottoscrizioni CSP completata con successo");
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    } 
    
    public downloadCSPInvoices() : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CSP/invoices/download";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve("Sincronizzazione Fatture CSP completata con successo");
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    } 
    
    public downloadCSPProducts() : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CSP/products/download";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve("Sincronizzazione Prodotti CSP completata con successo");
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }
    
    public syncDSYFromBcCompany(customerId:number,environmentName:string,companyId:string) : Promise<Boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "api/businesscentral/customer/"+customerId+"/environment/"+environmentName+"/company/"+companyId+"/applydsy";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public updateCustomerEnvironments(customerId:number) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "api/businesscentral/customer/"+customerId+"/schedule/environment/update";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public scheduleCustomerEnvironments(statusCode: string) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "api/businesscentral/customer/status/"+statusCode+"/schedule/update/date";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public getVatOpenAPIData(vat: string, leadId: number, customerId: number) : Promise<OpenAPICompanyITAdvancedResponse>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/OpenAPI/company/"+vat+"/advanced/"+leadId+"/"+customerId;
        return new Promise((resolve,reject)=>{
            instance.get(url)
            .then((resp)=>{
                if (resp.data.success)
                    resolve(resp.data);
                else
                    reject(resp.data.message);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public reactivateLead(leadId: number) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CRM/lead/reactivate/"+leadId;
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then(()=>{
                resolve(true);
            })
            .catch((resp)=>{
                console.log(resp);
                console.log(resp.response.data.message);
                reject(resp.response.data.message);
            })
        })
    }

    public getLeadOpenAPIData(leadId: number) : Promise<OpenAPICompanyITAdvancedResponse>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/OpenAPI/lead/"+leadId+"/company/advanced";
        return new Promise((resolve,reject)=>{
            instance.get(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public getCustomerOpenAPIData(customerId: number) : Promise<OpenAPICompanyITAdvancedResponse>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/OpenAPI/customer/"+customerId+"/company/advanced";
        return new Promise((resolve,reject)=>{
            instance.get(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public getCustomersOpenAPIData() : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/OpenAPI/customer/company/advanced";
        return new Promise((resolve,reject)=>{
            instance.get(url)
            .then(()=>{
                resolve(true);
            })
            .catch(()=>{
                reject(false);
            })
        })
    }
    
    public updateCustomerEnvironment(customerId:number,environmentName:string) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "api/businesscentral/customer/"+customerId+"/schedule/environment/"+environmentName+"/update";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }
    
    public matchCustomersSubscriptions(customerId?: number) : Promise<Boolean>
    {
        let instance = HttpHelper.buildAxiosInstance(900000);
        let url: string = "";

        if (customerId === undefined || customerId === 0)
            url = "/api/csp/subscription/match";
        else
            url = "/api/csp/subscription/"+customerId+"/match";

        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }
    
    public createCustomerRelationshipRole(customerId?: number) : Promise<Boolean>
    {
        let instance = HttpHelper.buildAxiosInstance(900000);
        let url: string = "/api/csp/azure/gdap/customer/"+customerId+"/relationship/role/create";

        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }
    
    public createGDAPRelationship(customerId?: number) : Promise<Boolean>
    {
        let instance = HttpHelper.buildAxiosInstance(900000);
        let url: string = "/api/csp/azure/gdap/relationship/add/"+customerId;

        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }
    
    public finalizeGDAPRelationship(relationshipId: string) : Promise<Boolean>
    {
        let instance = HttpHelper.buildAxiosInstance(900000);
        let url: string = "/api/csp/azure/gdap/relationship/finalize/"+relationshipId;

        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }
    
    public syncronizeGDAPRelationships() : Promise<Boolean>
    {
        let instance = HttpHelper.buildAxiosInstance(900000);
        let url: string = "/api/csp/azure/gdap/relationship/syncronize";

        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }
    
    public updateGDAPRelationship(relationshipId: string) : Promise<Boolean>
    {
        let instance = HttpHelper.buildAxiosInstance(900000);
        let url: string = "/api/csp/azure/gdap/relationship/update/"+relationshipId;

        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }
    
    public addSecurityGroupToRelationship(relationshipId: string) : Promise<Boolean>
    {
        let instance = HttpHelper.buildAxiosInstance(900000);
        let url: string = "/api/csp/azure/gdap/relationship/add/group/"+relationshipId;

        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }
    
    public createCustomerSubscriptionFromTemplate(customerId: number, templateId: number) : Promise<Boolean>
    {
        let instance = HttpHelper.buildAxiosInstance(900000);
        let url: string = "/api/Sales/subscription/"+customerId+"/create/fromtemplate/"+templateId;

        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public getCustomers() : Promise<Customer[]>
    {
        let instance = HttpHelper.buildAxiosInstance(900000);
        let url = "/odata/customer";
        return new Promise((resolve,reject)=>{
            instance.get(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }
    
    public acceptDenySubscriptionMatch(entryNo: number, accepted: boolean) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/csp/subscription/match/"+entryNo+"/update/"+accepted;
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public processSubscriptionMatch() : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance(900000);
        let url = "/api/csp/subscription/match/process";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public deleteSubscriptionMatch() : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance(900000);
        let url = "/api/csp/subscription/match/delete";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }
    
    public initRmrLedgerEntry() : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/setup/rmr/ledger/entry/initialize";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve("Inizializzazione customer RMR ledger entry completata con successo");
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public initContractData() : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/Setup/contract/data/initialize";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data.message);
            })
        })
    }
    
    public updloadCrmIncidents(incidentId?: number) : Promise<Boolean>
    {
        let instance = HttpHelper.buildAxiosInstance(900000);
        let url: string = "";

        if (incidentId === undefined || incidentId === 0)
            url = "/api/CRM/incident/upload";
        else
            url = "/api/CRM/incident/upload/"+incidentId;

        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public suggestAnswer(rec: any, content: string) : Promise<Boolean>
    {
        let instance = HttpHelper.buildAxiosInstance(900000);
        let url: string = "";
        let requestData = {
            ticket: rec,
            content: content
        };

        url = "/api/eosChat/suggest/answer";

        return new Promise((resolve,reject)=>{
            instance.post(url, requestData)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(false);
            })
        })
    }
    
    public bookingChallenge(req: any) : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/csp/booking/challenge";
        return new Promise((resolve,reject)=>{
            instance.post(url, req)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }
    
    public cspChallenge(req: any) : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/csp/challenge";
        return new Promise((resolve,reject)=>{
            instance.post(url, req)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }
    
    public crmUserChallenge() : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/crm/challenge";
        return new Promise((resolve,reject)=>{
            instance.get(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }
    
    public gdapChallenge(req: any) : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/csp/gdap/challenge";
        return new Promise((resolve,reject)=>{
            instance.post(url, req)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public syncBookingStaffMembers() : Promise<Boolean>
    {
        let instance = HttpHelper.buildAxiosInstance(900000);
        let url = "api/csp/booking/staff/member/syncronize";
        return new Promise((resolve, reject) => {
            instance.post(url)
            .then((resp) => {
                resolve(true);
            })
            .catch((resp) => {
                reject(resp.message.data);
            })
        })
    }

    public syncBookingServices() : Promise<Boolean>
    {
        let instance = HttpHelper.buildAxiosInstance(900000);
        let url = "api/csp/booking/service/syncronize";
        return new Promise((resolve, reject) => {
            instance.post(url)
            .then((resp) => {
                resolve(true);
            })
            .catch((resp) => {
                reject(resp.message.data);
            })
        })
    }

    public syncBookingAppointments() : Promise<Boolean>
    {
        let instance = HttpHelper.buildAxiosInstance(900000);
        let url = "api/csp/booking/appointment/syncronize";
        return new Promise((resolve, reject) => {
            instance.post(url)
            .then((resp) => {
                resolve(true);
            })
            .catch((resp) => {
                reject(resp.message.data);
            })
        })
    }
    
    public getSystemApps() : Promise<SystemApp[]>
    {
        let instance = HttpHelper.buildAxiosInstance(900000);
        let url = "odata/system/app";
        return new Promise((resolve, reject) => {
            instance.get(url)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((resp) => {
                reject(resp.message.data);
            })
        })
    }

    public getSystemRoles() : Promise<SystemRole[]>
    {
        let instance = HttpHelper.buildAxiosInstance(900000);
        let url = "odata/system/role";
        return new Promise((resolve, reject) => {
            instance.get(url)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((resp) => {
                reject(resp.message.data);
            })
        })
    }

    public getnerateNewAppAccess(rec:any) : Promise<Boolean>
    {
        let instance = HttpHelper.buildAxiosInstance(900000);
        let url = "/api/System/app/authentication/grant/"+rec.userId+"/"+rec.appId;
        return new Promise((resolve, reject) => {
            instance.post(url)
            .then((resp) => {
                resolve(true);
            })
            .catch((resp) => {
                reject(resp.message.data);
            })
        })
    }

    public uploadVideoDocs(file:File) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/crm/knowledge/video/upload";
        let fileUpload = new FormData();
        fileUpload.append("file", file);
        return new Promise((resolve, reject) => {
            instance.post(url, fileUpload)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public getBCNProcessTestVideoEmbedHtml(processTest:BCNProcessTest) : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/BusinessCentral/bcn/process/test/"+processTest.processModule+"/"+processTest.processCode+"/"+processTest.lineNo+"/video/embed";
        return new Promise((resolve,reject)=>{
            instance.post(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }
    
    public calculateSuggestedLines() : Promise<Boolean>
    {
        let instance = HttpHelper.buildAxiosInstance(900000);
        let url: string = "/api/CRM/automation/get/prospect/line";

        return new Promise((resolve,reject)=>{
            instance.get(url)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public getLead(leadId: number) : Promise<Lead>
    {
        let instance = HttpHelper.buildAxiosInstance(900000);
        let url: string = "/odata/lead?$filter=id eq "+leadId;

        return new Promise((resolve, reject) => {
            instance.get(url)
            .then((resp) => {
                resolve(resp.data[0]);
            })
            .catch((err) => {
                reject(err.response.data);
            })
        })
    }

    public getIndustryTypes() : Promise<IndustyType[]>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url: string = "/odata/salesheadertemplate?filter=source eq 'Industry'";

        return new Promise((resolve, reject) => {
            instance.get(url)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((err) => {
                reject(err.response.data);
            })
        })
    }

    public getPostalCodes() : Promise<PostalCode[]>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url: string = "/odata/PostalCode";

        return new Promise((resolve, reject) => {
            instance.get(url)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((err) => {
                reject(err.response.data);
            })
        })
    }

    public getCrmLostRiskLine(lead: Lead) : Promise<CrmLostRiskLine[]>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url: string = "/odata/crm/lossriskline?$filter=sourceTable eq 'Lead' and sourceId eq " + lead.id + " and lossCauseCode eq 'BUSINESS_NEEDS' or lossCauseCode eq 'BUSINESS_NEEDS_NOTE' or lossCauseCode eq 'GAP' or lossCauseCode eq 'MIGRATION'";

        return new Promise((resolve, reject) => {
            instance.get(url)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((err) => {
                reject(err.response.data);
            })
        })
    }

    public getLeadIndustries(lead: Lead) : Promise<LeadIndustry[]>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url: string = "/odata/lead/industry?$filter=leadId eq " + lead.id;

        return new Promise((resolve, reject) => {
            instance.get(url)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((err) => {
                reject(err.response.data);
            })
        })
    }

    public getLeadConfigDetails(lead: Lead) : Promise<LeadConfigChecklistDetails>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url: string = "api/System/get/lead/" + lead.id + "/config/details";

        return new Promise((resolve, reject) => {
            instance.get(url)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((err) => {
                reject(err.response.data);
            })
        })
    }

    public getBusinessCentralCompanies(customerId: number) : Promise<BusinessCentralCompany[]>
    {
        let instance = HttpHelper.buildAxiosInstance(900000);
        let url: string = "/odata/businesscentral/company?$filter=customerId eq "+customerId;

        return new Promise((resolve, reject) => {
            instance.get(url)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((err) => {
                reject(err.response.data);
            })
        })        
    }    

    public getStripeActiveProduct(): Promise<StripeProduct[]>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url: string = "/api/ecommerce/stripe/product/active";
        
        return new Promise((resolve, reject) => {
            instance.get(url)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((err) => {
                reject(err.response.data);
            })
        })
    }

    public createStripeCoupon(stripeCouponRequest: StripeCouponRequest): Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url: string = "/api/ecommerce/stripe/coupon";

        return new Promise((resolve, reject) => {
            instance.post(url, stripeCouponRequest)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((err) => {
                reject(err.response.data);
            })
        })
    
    }

    public uploadExtensionFile(file: FormData) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance(900000);
        let url: string = "/api/BusinessCentral/bcn/extension/file/upload";

        return new Promise((resolve, reject) => {
            instance.post(url,file,{
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((resp) => {
                resolve(true);
            })
            .catch((err) => {
                reject(false);
            })
        })
    }

    public uploadPostalCodeFile(file: FormData) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url: string = "/api/System/postalCode/upload";

        return new Promise((resolve, reject) => {
            instance.post(url,file,{
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((resp) => {
                resolve(true);
            })
            .catch((err) => {
                reject(err.response.data);
            })
        })
    }

    public async getCurrentAssignedBusinessLines(profileCode: string) : Promise<AssignedChatbotBusinessLine[]>
    {
        let instance = HttpHelper.buildAxiosInstance(900000);
        let url: string = '/odata/business/line';

        let resultArray: AssignedChatbotBusinessLine[] = [];

        let response = await instance.get(url);
        if (response.status != 200)
            throw "Errore durante il caricamento dei dati";

        let businessLines: any[] = response.data;
        for (let index = 0; index < businessLines.length; index++)
        {
            let urlMapping: string = '/odata/chatbot/business/line?$filter=profileCode eq \'' + profileCode + '\' and businessLineCode eq \'' + businessLines[index].code + '\'';
            
            let assignedEntry: AssignedChatbotBusinessLine = new AssignedChatbotBusinessLine();

            try 
            {
                let responseMapping = await instance.get(urlMapping);
                if (responseMapping.data.length > 0)
                {
                    assignedEntry.enabled = true;
                    assignedEntry.systemId = responseMapping.data[0].systemId;
                }
            }
            catch
            {}

            assignedEntry.profileCode = profileCode;
            assignedEntry.businessLineCode = businessLines[index].code;
            assignedEntry.description = businessLines[index].code;
            resultArray.push(assignedEntry);
        }

        return resultArray;
    }

    public async modifyAssignedBusinessLine(modifiedBusinessLines: AssignedChatbotBusinessLine[]) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance(900000);
        for (let index = 0; index < modifiedBusinessLines.length; index++)
        {
            let currentEntry = modifiedBusinessLines[index];

            try 
            {
                if ((currentEntry.systemId) && (! currentEntry.enabled))
                {
                    let urlDelete: string = '/odata/chatbot/business/line/' + currentEntry.systemId;
                    await instance.delete(urlDelete);
                }
                else if ((! currentEntry.systemId) && (currentEntry.enabled))
                {
                    let dataRequest = 
                    {
                        profileCode: currentEntry.profileCode,
                        businessLineCode: currentEntry.businessLineCode
                    };
                    
                    let urlCreate: string = '/odata/chatbot/business/line';
                    await instance.post(urlCreate, dataRequest);
                }
            }
            catch
            {
                throw "Errore durante il salvataggio dei dati";
            }
        }

        return true;
    }

    public checkUnsubscribeEmail(userSystemId: string) : Promise<SystemUser>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url: string = "/api/System/email/unsubscribe/check";

        return new Promise((resolve, reject) => {
            instance.post(url, userSystemId, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((err) => {
                reject(err.response.data);
            })
        })
    }

    public unsubscribeEmail(user: SystemUser) : Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url: string = "/api/System/email/unsubscribe";

        return new Promise((resolve, reject) => {
            instance.post(url, user)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((err) => {
                reject(err.response.data);
            })
        })
    }
}
