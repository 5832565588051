import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminSecuritySetupCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda security setup"} endpoint="odata/Security/setup" ref={ref}>
            <CardGroup name="setup" label="Configurazione" isOpen={true}>
                <CardField name="primaryKey" type={SystemFieldType.String} label="Primary Key" />
                <CardField name="correlationIdMessage" type={SystemFieldType.String} label="Correlation Id message" />
                <CardField name="customerPortalAppId" type={SystemFieldType.String} label="Customer Portal App Id" />
                <CardField name="customerPurchaseRole" type={SystemFieldType.String} label="Customer purchase role" />
                <CardField name="useTestEmail" type={SystemFieldType.Boolean} label="Use test email" />
                <CardField name="resetPasswordMinutesDuration" type={SystemFieldType.Number} label="Reset password minutes duration" />
                <CardField name="resetPasswordLinkTemplateCode" type={SystemFieldType.String} label="Reset password link template code" />
                <CardField name="setPasswordConfirmationTemplateCode" type={SystemFieldType.String} label="Set password confirmation template code" />
                <CardField name="defaultCustomerAppId" type={SystemFieldType.Option} label="Default Customer App Id" hasTableRelation tableRelationEndpoint="odata/system/app" tableRelationKey="id" tableRelationField="name" />
                <CardField name="defaultLeadRoleCode" type={SystemFieldType.String} label="Default Lead Role Code" />
                <CardField name="defaultCustomerRoleCode" type={SystemFieldType.String} label="Default Customer Role Code" />
                <CardField name="systemApiAppId" type={SystemFieldType.Option} label="System API App Id" hasTableRelation tableRelationEndpoint="odata/system/app" tableRelationKey="id" tableRelationField="name" />
                <CardField name="itemFeId" type={SystemFieldType.Option} label="FE Item" hasTableRelation tableRelationEndpoint="odata/item" tableRelationKey="id" tableRelationField="description" />
                <CardField name="isProduction" type={SystemFieldType.Boolean} label="Production environment" />
            </CardGroup>
        </PageContainer>
    );
});