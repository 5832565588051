import React, {useEffect, useRef, useState} from "react";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { SystemActionCategory,SystemDataLoadingStatus,SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminCrmSourceCard } from "../AdminCrmSourceCard/AdminCrmSourceCard";


export const AdminContentList : React.FC = () => {

    return (
        <>
            <PageContainer endpoint="odata/bcn/process/faq?$filter=videoLink ne ''" pageType={SystemPageType.List} title={"Lista contenuti"} backAllowed>               
                <PageDefinitionContainer type={ContainerType.Action}>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Domanda" fieldName="questions" minWidth={100} maxWidth={200}  />
                    <ListHeaderEntry name="Link video" fieldName="videoLink" minWidth={100} maxWidth={200}   />
                    <ListHeaderEntry name="Visibile dai clienti" fieldName="customerVisible" minWidth={100} maxWidth={200}   />
                    <ListHeaderEntry name="Visibile prevendita" fieldName="presalesVisible" minWidth={100} maxWidth={200} />
                </PageDefinitionContainer>
            </PageContainer>
        </>
    );
}