import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminCrmProfilingSetupCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda CRM profiling setup"} endpoint="/odata/crm/profiling/setup" ref={ref}>
            <CardGroup name="setup" label="Setup" isOpen={false}>
                <CardField name="primaryKey" label="Chiave primaria" type={SystemFieldType.String} />
            </CardGroup>
            <CardGroup name="account" label="Account" isOpen={true}>
                <CardField name="crmAccountProfileGroupId" label="Account profile group Id" type={SystemFieldType.String} />
                <CardField name="crmAccountProfileTrialId" label="Account profile trial Id" type={SystemFieldType.String} />
                <CardField name="crmAccountProfileTrialName" label="Account profile trial name" type={SystemFieldType.String} />
                <CardField name="crmAccountProfileCustomerId" label="Account profile customer Id" type={SystemFieldType.String} />
                <CardField name="crmAccountProfileCustomerName" label="Account profile customer name" type={SystemFieldType.String} />
                <CardField name="crmAccountProfileOnBoardingId" label="Account profile on boarding Id" type={SystemFieldType.String} />
                <CardField name="crmAccountProfileOnBoardingName" label="Account profile on boarding name" type={SystemFieldType.String} />
                <CardField name="crmAccountProfileSupportId" label="Account profile support Id" type={SystemFieldType.String} />
                <CardField name="crmAccountProfileSupportName" label="Account profile support name" type={SystemFieldType.String} />
                <CardField name="crmAccountProfileYearlySubscriptionId" label="Account profile yearly subscription Id" type={SystemFieldType.String} />
                <CardField name="crmAccountProfileYearlySubscriptionName" label="Account profile yearly subscription name" type={SystemFieldType.String} />
                <CardField name="crmAccountProfileMonthlySubscriptionId" label="Account profile monthly subscription Id" type={SystemFieldType.String} />
                <CardField name="crmAccountProfileMonthlySubscriptionName" label="Account profile monthly subscription name" type={SystemFieldType.String} />
            </CardGroup>
            <CardGroup name="contact" label="Contact" isOpen={true}>
                <CardField name="crmContactProfileGroupId" label="Contact profile group Id" type={SystemFieldType.String} />
                <CardField name="crmContactProfileMainContactId" label="Contact profile main contact Id" type={SystemFieldType.String} />
                <CardField name="crmContactProfileMainContactName" label="Contact profile main contact name" type={SystemFieldType.String} />
            </CardGroup>
        </PageContainer>
    );
});