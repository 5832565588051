import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminSystemEventTypeCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda System Event Type"} endpoint="odata/system/event/type" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="code" type={SystemFieldType.String} label="Codice" />
                <CardField name="description" type={SystemFieldType.String} label="Descrizione" />
                <CardField name="unitOfMeasure" type={SystemFieldType.String} label="Unità di misura" />
                <CardField name="scorePerUnitOfMeasure" type={SystemFieldType.Number} label="Score per unità di misura" />
                <CardField name="dimension1" type={SystemFieldType.String} label="Dimensione 1" />
                <CardField name="dimension2" type={SystemFieldType.String} label="Dimensione 2" />
            </CardGroup> 
        </PageContainer>
    );
});