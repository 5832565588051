import React, { useEffect,useState } from "react";
import { Loader } from "../../../Common/Loader/Loader";
import { SystemActionCategory, SystemDataLoadingStatus,SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { Lead } from "../../../../Model/PartnerModels";
import { t } from "i18next";

export interface AdminFrontEndLogEntryProps {
    lead?: Lead;
    creationDate?: string;
    isModal?: boolean;
}

export const AdminFrontEndLogEntryList : React.FC<AdminFrontEndLogEntryProps> = (props) => {
    const [dataStatus,setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [endpoint, setEndpoint] = useState('');
    const parameter = HttpHelper.getParameter('Id');
    const leadName = HttpHelper.getParameter('leadName') ?? "";


    const buildEndpoint = () :string =>{
        if(props.lead !== undefined){
            let filterDate = new Date(props.creationDate!);
            filterDate.setDate(filterDate.getDate() + 1);

            return '/odata/frontend/log/entry?$filter=systemCreationDate gt '+props.creationDate+'T00:00:00Z and systemCreationDate lt '+filterDate.toISOString()+' and userId eq '+props.lead.linkUserId+'&$orderby=systemCreationDate desc';
        }
        else if(parameter!=null)
            return '/odata/frontend/log/entry?$filter=userId eq '+parameter+'&$orderby=systemCreationDate desc';
        else
            return '/odata/frontend/log/entry';
    }

    return (
        <PageContainer endpoint={buildEndpoint()} pageType={SystemPageType.List} title={t("adminCenter:frontEndLogEntryList:title") +  props.lead !== undefined ? props.lead?.companyName : leadName} backAllowed={!props.isModal!}>
            <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
            <PageDefinitionContainer type={ContainerType.PageStructure}>
                <ListHeaderEntry name={t("adminCenter:frontEndLogEntryList:fields:appId")} fieldName="appId" minWidth={150} maxWidth={250} />
                <ListHeaderEntry name={t("adminCenter:frontEndLogEntryList:fields:description")} fieldName="description" minWidth={150} maxWidth={250} />
                <ListHeaderEntry name={t("adminCenter:frontEndLogEntryList:fields:referenceId")} fieldName="referenceId" minWidth={150} maxWidth={250} />
                <ListHeaderEntry name={t("adminCenter:frontEndLogEntryList:fields:userId")} fieldName="userId" minWidth={150} maxWidth={250} />
                <ListHeaderEntry name={t("adminCenter:frontEndLogEntryList:fields:systemCreationDate")} fieldName="systemCreationDate" minWidth={150} maxWidth={250} />
            </PageDefinitionContainer>
        </PageContainer>
    );
}