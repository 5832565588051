import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminBookingServiceCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Servizio Booking"} endpoint="/odata/booking/service" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="calendarId" type={SystemFieldType.String} label="Id Calendario" readonly />
                <CardField name="id" type={SystemFieldType.String} label="Id" readonly />
                <CardField name="displayName" type={SystemFieldType.String} label="Nome" readonly />
                <CardField name="description" type={SystemFieldType.String} label="Descrizione" readonly />
                <CardField name="notes" type={SystemFieldType.String} label="Note" readonly />
                <CardField name="isHiddenFromCustomers" type={SystemFieldType.Boolean} label="Nascosto per i clienti" readonly />
                <CardField name="webUrl" type={SystemFieldType.String} label="Url" readonly />
                <CardField name="bookingVisibility" type={SystemFieldType.Option} label="Visibilità" cmbOptions={[
                    {key: 0, text: "None"},
                    {key: 1, text: "Customer"},
                    {key: 2, text: "Partner"},
                    {key: 3, text: "All"}
                ]} />
            </CardGroup>
        </PageContainer>
    );
});