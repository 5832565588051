import React, { forwardRef, useState } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CustomerEnvironment } from "../../../../Model/CustomerModels";

export const AdminCustomerEnvCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    const getParams=(param:string)=>{
        const query = new URLSearchParams(window.location.search);
        return (query.get(param))
    }
    
    const [fieldEnabled, setFieldEnabled] = useState<boolean>(false);
    
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Configurazione ambienti di " + getParams('name')} endpoint="/odata/customer/environment" ref={ref}>
            <CardGroup name="settingsEnv" label="Impostazioni Ambienti" isOpen>
                <CardField name="automaticUpdate" type={SystemFieldType.Boolean} label="Update automatico" />
                <CardField name="visibile" type={SystemFieldType.Boolean} label="Visibile" />
                <CardField name="enableUpgrade" type={SystemFieldType.Boolean} label="Upgrade abilitato"
                    onGetIsVisible={(record: CustomerEnvironment) => {
                        setFieldEnabled(!record.enableUpgrade);
                        return true;
                    }}
                    onValidate={(fieldName: string, record: any, oldValue: any, newValue: any) => {
                        setFieldEnabled(!newValue);
                    }}
                />
                <CardField name="startUpgradeDate" type={SystemFieldType.Date} label="Data inizio upgrade" readonly={fieldEnabled} />
                <CardField name="forceIgnoreUpdateWindow" type={SystemFieldType.Boolean} label="Forza ignora finestra update" readonly={fieldEnabled} />
            </CardGroup> 
        </PageContainer>
    );
});