import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../../Model/SystemModels";
import { CardField } from "../../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../../Common/Template/PageContainer";

export const AdminAzureGDAPCustomerRelationshipRoleCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} endpoint="/odata/azure/gdap/customer/relationship/role" title={'Card ruolo relazione cliente azure GDAP'} ref={ref}>
            <CardGroup name="general" label="Generale" isOpen>
                <CardField name="entryNo" label="Entry No" type={SystemFieldType.Number} readonly />
                <CardField name="customerId" label="Id cliente" type={SystemFieldType.Number} readonly />
                <CardField name="active" label="Attivo" type={SystemFieldType.Boolean} />
                <CardField name="relationshipId" label="Id relazione" type={SystemFieldType.String} readonly />
            </CardGroup>
        </PageContainer>    
    );
});