import React from "react";

export enum ContainerType {
    PageStructure,
    Action
}

export interface IPageDefinitionContainer {
    type: ContainerType;
}

export const PageDefinitionContainer : React.FC<IPageDefinitionContainer> = (props) => {
    return (
        <></>
    );
}