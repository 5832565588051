import { TooltipHost } from "@fluentui/react";
import { SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { CustomAdminStripeCouponCard } from "../AdminStripeCouponCard/CustomAdminStripeCouponCard";
import { useRef, useState } from "react";
import { t } from "i18next";

export const AdminStripeCouponList : React.FC = () => {
    
    const currentPage = useRef<PageContainerBase>(null);
    const [showCreateCard, setShowCreateCard] = useState(false);

    const onNewRecord = () => {
        setShowCreateCard(true);
    }

    const onConfirmSave = () => {
        setShowCreateCard(false);
        currentPage.current?.reloadData();
    }

    return (
        <>
            <PageContainer endpoint={"/api/ecommerce/stripe/coupon"} pageType={SystemPageType.List} title={"Stripe Coupon"} backAllowed insertAllowed onNewRecord={onNewRecord} ref={currentPage}>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Id" fieldName="id" minWidth={100} maxWidth={1000} />
                    <ListHeaderEntry name={t('adminCenter:customAdminStripeCouponCard:fields:name')!} fieldName="name" minWidth={500} maxWidth={1000} />
                    <ListHeaderEntry name={t('adminCenter:customAdminStripeCouponCard:fields:amount')!} fieldName="amount_off" minWidth={100} maxWidth={1000} onRender={(item: any, index, column) => {
                        return item.amount_off / 100
                     }} />
                    <ListHeaderEntry name={t('adminCenter:customAdminStripeCouponCard:fields:percent')!} fieldName="percent_off" minWidth={100} maxWidth={1000} onRender={(item: any, index, column) => {
                        if (item.percent_off) {
                            return item.percent_off + "%"
                        }
                     }} />
                    <ListHeaderEntry name={t('adminCenter:customAdminStripeCouponCard:fields:duration')!} fieldName="duration" minWidth={100} maxWidth={1000} />
                    <ListHeaderEntry name={t('adminCenter:customAdminStripeCouponCard:fields:durationInMonths')!} fieldName="duration_in_months" minWidth={130} maxWidth={1000} />
                    <ListHeaderEntry name={t('adminCenter:customAdminStripeCouponCard:fields:redeemBy')!} fieldName="redeem_by" minWidth={200} maxWidth={1000} onRender={(item: any, index, column) => {
                        if (item.redeem_by > 0) {
                            const date = new Date(item.redeem_by * 1000);
                            let ianaTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
                            const intlOptions: Intl.DateTimeFormatOptions = {
                                timeZone: ianaTimeZone,
                                timeZoneName: 'short',
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                                weekday: 'long',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: false,
                            };
                            let userLocale: string = navigator.language || navigator.languages[0];
                            return Intl.DateTimeFormat(userLocale, intlOptions).format(date);
                        }
                    }} />
                    <ListHeaderEntry name={t('adminCenter:customAdminStripeCouponCard:fields:maxRedemption')!} fieldName="max_redemptions" minWidth={120} maxWidth={1000} />
                    <ListHeaderEntry name={t('adminCenter:customAdminStripeCouponCard:fields:appliesTo')!} fieldName="applies_to" minWidth={250} maxWidth={1000} onRender={(item: any, index, column) => {
                        if (item.applies_to) {
                            const products: string[] = item.applies_to.products;
                            return <TooltipHost content={
                                <ul style={{ margin: 0, padding: 3 }}>
                                    {products.map((item, index, array) => (<li key={index} style={{ listStyleType: 'none' }}>{item}</li>))}
                                </ul>}>
                                <span>{products.join(', ')}</span>
                            </TooltipHost>
                        }
                    }} />
                    
                </PageDefinitionContainer>
            </PageContainer>
            <CustomAdminStripeCouponCard onCancel={() => {setShowCreateCard(false);}} onConfirmSave={onConfirmSave} showCard={showCreateCard} />
        </>
    );
}
