import { Checkbox, DefaultButton, Dialog, DialogFooter, IModalProps, IStackTokens, mergeStyles, MessageBar, PrimaryButton, Stack, TextField } from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from "react-accessible-accordion";
import { CustomerInformationContext } from "../../../ApplicationCode/Customer/CustomerInformationContext";
import { OperationType, SystemUser } from "../../../Model/SystemModels";
import '../../../../node_modules/react-accessible-accordion/dist/fancy-example.css';
import { t } from "i18next";

const modalProps: Partial<IModalProps> = {
    isBlocking: true,
    dragOptions: undefined,
    styles: {
        main: {
            width: '95% !important',
            maxWidth: 'calc(95%) !important',
            position: 'absolute',
            top: 50
        }
    }
  };

const contentBodyClassName = mergeStyles([{
    width: '100%',
    overflowY: "auto",
    overflowX: "hidden"
}]);

const modalContentClassName = mergeStyles([{
    width: '100%'
}]);

const formContentClassName = mergeStyles([{
    width: '100%'
}]);

const formContentEntryClassName = mergeStyles([{
    width: '100%'
}]);

const rowContentClassName = mergeStyles([{
    width: '100%',
    marginBottom: 7
}]);

const rowContentEntryClassName = mergeStyles([{
    width: '48%'
}]);

const textFieldClassName =  mergeStyles([{
    width: '100%',
    maxWidth: 600
}]);

const checkBoxClassName =  mergeStyles([{
    width: '100%',
    maxWidth: 600,
    paddingTop: 20
}]);

const rowContentStackToken: IStackTokens = {
    childrenGap: 50
};

const formContentStackToken: IStackTokens = {
    childrenGap: 20
};

export interface ICustomerUserCardProps {
    showDialog: boolean;
    title: string;
    operationType: OperationType;
    user: SystemUser;
    savingInProgress: boolean;
    onSuccessModal: (user: SystemUser) => void;
    onCancelModal: () => void;
}

export const CustomerUserCard : React.FC<ICustomerUserCardProps> = (props) => {
    const [user, setUser] = useState(props.user);
    const customerInfoContext = useContext(CustomerInformationContext);


    useEffect(() => {
        setUser(props.user);
    }, [props.user]);

    const handleObjectPropertyChange = (event: any, newValue?: any) => {
        let currentUser = new SystemUser();
        for (let i = 0; i < Object.keys(user).length; i++)
        {
            currentUser = Object.defineProperty(
                currentUser, 
                Object.keys(user)[i], 
                {
                  value: Object.values(user)[i],
                  writable: true,
                  enumerable: true
                }
              );
        }

        currentUser = Object.defineProperty(
            currentUser, 
            event.currentTarget.name, 
            {
              value: newValue!,
              writable: true,
              enumerable: true
            }
        );

        setUser(currentUser);
    }

    let messageStatus : JSX.Element = <></>;
    if (props.savingInProgress)
        messageStatus = <>
            <MessageBar>
                {t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserCard:MessageBar:messageContent1')}
            </MessageBar>
        </>

    return (
        <Dialog hidden={! props.showDialog}
                modalProps={modalProps}
                onDismiss={props.onCancelModal}
                dialogContentProps={{
                    title: props.title
                }}>
                <Stack key="modalContent" className={modalContentClassName}>
                    <Stack.Item key="modalBodyContent" className={contentBodyClassName}>
                        <Stack key="formContainer" className={formContentClassName}>
                            <Accordion allowMultipleExpanded preExpanded={["generalItem"]}>
                                <AccordionItem uuid={"generalItem"}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            {t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserCard:Dialog:CardGroup:label')}
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <Stack.Item className={formContentEntryClassName}>
                                            <Stack horizontal key="row1" className={rowContentClassName} tokens={rowContentStackToken}>
                                                <Stack.Item className={rowContentEntryClassName}>
                                                    <TextField name="firstName"
                                                        value={user.firstName} 
                                                        label={t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserCard:Dialog:CardGroup:CardField:firstName')!} 
                                                        className={textFieldClassName}
                                                        onChange={handleObjectPropertyChange} 
                                                        disabled={props.savingInProgress} />
                                                </Stack.Item>
                                                <Stack.Item className={rowContentEntryClassName}>
                                                    <TextField name="lastName"
                                                        value={user.lastName} 
                                                        label={t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserCard:Dialog:CardGroup:CardField:lastName')!}
                                                        className={textFieldClassName}
                                                        onChange={handleObjectPropertyChange} 
                                                        disabled={props.savingInProgress} />
                                                </Stack.Item>
                                            </Stack>
                                        </Stack.Item>
                                        <Stack.Item className={formContentEntryClassName}>
                                            <Stack horizontal key="row2" className={rowContentClassName} tokens={rowContentStackToken}>
                                                <Stack.Item className={rowContentEntryClassName}>
                                                    <TextField name="displayName"
                                                        value={user.displayName} 
                                                        label={t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserCard:Dialog:CardGroup:CardField:displayName')!}
                                                        className={textFieldClassName}
                                                        onChange={handleObjectPropertyChange} 
                                                        disabled={props.savingInProgress} />
                                                </Stack.Item>
                                                <Stack.Item className={rowContentEntryClassName}>
                                                    <TextField name="phone"
                                                        value={user.phone} 
                                                        label={t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserCard:Dialog:CardGroup:CardField:phone')!}
                                                        className={textFieldClassName}
                                                        onChange={handleObjectPropertyChange} 
                                                        disabled={props.savingInProgress} />
                                                </Stack.Item>
                                            </Stack>
                                        </Stack.Item>
                                        <Stack.Item className={formContentEntryClassName}>
                                            <Stack horizontal key="row3" className={rowContentClassName} tokens={rowContentStackToken}>
                                                {
                                                    props.operationType == OperationType.Insert ?
                                                    ( 
                                                        <Stack.Item className={rowContentEntryClassName}>
                                                            <TextField name="userPrincipleName"
                                                                value={user.userPrincipleName.split("@")[0]} 
                                                                label={t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserCard:Dialog:CardGroup:CardField:userPrincipleName')!}
                                                                suffix={"@" + customerInfoContext.defaultDomain.tenantDomain} 
                                                                className={textFieldClassName}
                                                                onChange={handleObjectPropertyChange}
                                                                disabled={props.savingInProgress} />
                                                        </Stack.Item>
                                                    )
                                                    : 
                                                    (
                                                        <Stack.Item className={rowContentEntryClassName}>
                                                            <TextField name="userPrincipleName"
                                                                value={user.userPrincipleName} 
                                                                label={t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserCard:Dialog:CardGroup:CardField:userPrincipleName')!} 
                                                                className={textFieldClassName}
                                                                onChange={handleObjectPropertyChange}
                                                                disabled />
                                                        </Stack.Item>
                                                    )
                                                }
                                                <Stack.Item className={rowContentEntryClassName}>
                                                    <Checkbox name="mainUser"
                                                        checked={user.mainUser}
                                                        label={t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserCard:Dialog:CardGroup:CardField:mainUser')!} 
                                                        className={checkBoxClassName}
                                                        onChange={(ev, checked)=>handleObjectPropertyChange(ev, checked)}
                                                        disabled={props.savingInProgress} />
                                                </Stack.Item>
                                            </Stack>
                                        </Stack.Item>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                            <Stack.Item className={formContentEntryClassName}>
                                {messageStatus}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item key="modalFooterContent">
                        <DialogFooter>
                            <DefaultButton text={t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserCard:Dialog:defaultButtonText')!} onClick={props.onCancelModal} />
                            <PrimaryButton text={t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerUserCard:Dialog:primaryButtonText')!} onClick={() => {
                                props.onSuccessModal(user)
                            }} />
                        </DialogFooter>
                    </Stack.Item>
                </Stack>
        </Dialog>
    );

}