import React, { useEffect, useState } from "react";
import { SystemDataLoadingStatus, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { Loader } from "../../../Common/Loader/Loader";
import { t } from "i18next";

export const AdminLeadQualifiedAnswerList : React.FC = () => {
    const [dataStatus,setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [endpoint, setEndpoint] = useState('');
    const leadId: number = HttpHelper.getParameter('leadId');
    const leadName: string = HttpHelper.getParameter('leadName') ?? "";

    useEffect(()=>{
        if(leadId !== undefined && leadId > 0)
            setEndpoint("/odata/lead/qualified/answer?$filter=leadId eq "+leadId+"&$orderby=entryNo");
        else
            setEndpoint("/odata/lead/qualified/answer");

        setDataStatus(SystemDataLoadingStatus.Loaded);
    },[dataStatus]);

    if (dataStatus!==SystemDataLoadingStatus.Loaded)
        return <Loader text={t("adminCenter:leadQualifiedAnswerList:loaderText")!}></Loader>;
    else
        return (
            <PageContainer endpoint={endpoint} pageType={SystemPageType.List} title={t("adminCenter:leadQualifiedAnswerList:title")!+leadName} backAllowed>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name={t("adminCenter:leadQualifiedAnswerList:fields:answerCode")!} fieldName="answerCode" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:leadQualifiedAnswerList:fields:answerType")!} fieldName="answerType" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:leadQualifiedAnswerList:fields:description")!} fieldName="description" minWidth={700} maxWidth={850} />
                    <ListHeaderEntry name={t("adminCenter:leadQualifiedAnswerList:fields:score")!} fieldName="score" minWidth={80} maxWidth={100} />
                    <ListHeaderEntry name={t("adminCenter:leadQualifiedAnswerList:fields:note")!} fieldName="note" minWidth={700} maxWidth={850} />
                    <ListHeaderEntry name={t("adminCenter:leadQualifiedAnswerList:fields:wizardCode")!} fieldName="wizardCode" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
        );
}