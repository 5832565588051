import React from "react";
import { TextField, DatePicker, Stack } from "@fluentui/react";
import { OrderHeader } from "../../../Model/PartnerModels";

export interface IPartnerCustomerDetailBoxProps {
    isCustomerDetailBoxVisible: boolean;
}

export interface IPartnerCustomerDetailBoxState {
    orderHeader: OrderHeader;
}

export class PartnerCustomerDetailBox extends React.Component<IPartnerCustomerDetailBoxProps, IPartnerCustomerDetailBoxState> {

    constructor(props: IPartnerCustomerDetailBoxProps){
        super(props);

        this.state = {
            orderHeader: new OrderHeader()
        }
    }

    setRecordValue = (fieldName: string, value?: any) => {
        Object.defineProperty(
            this.state.orderHeader, 
            fieldName, 
            {
              value: value!,
              writable: true,
              enumerable: true
            }
        )
        this.forceUpdate();
    }

    render(){
        if (this.props.isCustomerDetailBoxVisible) {
            return(
                <div>
                    <Stack>
                        <Stack.Item>
                            <TextField 
                                label="Ragione sociale"
                                value={this.state.orderHeader.customerName}
                                onChange={(e, value) => { this.setRecordValue("customerName", value)}}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <TextField 
                                label="Cod. Paese"
                                value={this.state.orderHeader.country}
                                onChange={(e, value) => { this.setRecordValue("country", value)}}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <DatePicker
                                label="Data attivazione"
                                value={this.state.orderHeader.activationDate}
                                onChange={(value) => {this.setRecordValue("activationDate", value)}}
                            />
                        </Stack.Item>
                    </Stack>
                </div>
            )
        }else{
            return <></>;
        }
    }
    
    
}