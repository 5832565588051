import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminApplicationTaskCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Application Task - Scheda entità"} endpoint="/odata/application/task" ref={ref}>
            <CardGroup name="Generale" label="general" isOpen={true}>
                <CardField name="code" type={SystemFieldType.String} label="Codice" />
                <CardField name="description" type={SystemFieldType.String} label="Descrizione"/>
                <CardField name="type" type={SystemFieldType.Option} label="Tipologia Task" cmbOptions={[
                    {key: 0, text: "Per tutti"},
                    {key: 1, text: "Uno per tutti"}
                ]} />
                <CardField name="forUserType" type={SystemFieldType.Option} label="Tipologia Utente" cmbOptions={[
                    {key: 0, text: "Utente Admin"},
                    {key: 1, text: "Utente Normale"},
                    {key: 2, text: "Entrambi i tipi"}
                ]} />
                <CardField name="actionType" type={SystemFieldType.Option} label="Tipologia Azione" cmbOptions={[
                    {key: 0, text: "Apertura Link"},
                    {key: 1, text: "Apertura Pagina"}
                ]} />
                <CardField name="priority" type={SystemFieldType.Number} label="Priorità"/>
                <CardField name="groupCode" type={SystemFieldType.String} label="Codice Gruppo"/>
                <CardField name="externalLink" type={SystemFieldType.String} label="Link Esterno"/>
                <CardField name="pageEndPoint" type={SystemFieldType.String} label="EndPoint Pagina"/>
            </CardGroup>
        </PageContainer>
    );
});