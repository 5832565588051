import { rejects } from "assert";
import { resolve } from "path";
import React, { createContext, useContext, useState } from "react";
import { ISessionStateContext, SessionStateContext } from "../../Core/State/SessionStateContext";
import { SystemCore } from "../../Core/System/SystemCore";
import { Customer, CustomerDomain, CustomerSetupConfig } from "../../Model/CustomerModels";
import { CustomerService } from "./CustomerService";

export interface ICustomerInformationContext {
    customer: Customer;
    setupConfig: CustomerSetupConfig;
    defaultDomain: CustomerDomain;
    setupData: Object;
    reloadSetupConfig: () => Promise<boolean>;
    reloadCustomer: (customerId: number) => Promise<Customer>;
    updateCustomer: (customer: Customer) => Promise<boolean>;
    loadDefaultDomain: () => void;
    setSetupData: (data: Object) => void;
}

export interface ICustomerInformationContextProviderProps {
    setupConfig: CustomerSetupConfig;
    defaultDomain: CustomerDomain;
    customer: Customer;
}

let defaultValue : ICustomerInformationContext = {} as ICustomerInformationContext;
defaultValue.setupConfig = new CustomerSetupConfig();

export const CustomerInformationContext = createContext<ICustomerInformationContext>(defaultValue);
export const CustomerInformationContextProvider : React.FC<ICustomerInformationContextProviderProps> = (props) => {
    const [setupConfig, setSetupConfig] = useState(props.setupConfig);
    const [setupData, setSetupData] = useState({} as Object);
    const [defaultDomain, setDefaultDomain] = useState(props.defaultDomain);
    const [customer, setCustomer] = useState<Customer>(props.customer);

    let sessionStateContext: ISessionStateContext = useContext(SessionStateContext);
    const customerService = new CustomerService();

    return (
        <CustomerInformationContext.Provider value={{
            setupConfig: setupConfig,
            setupData: setupData,
            defaultDomain: defaultDomain,
            customer: customer,
            reloadCustomer: (customerId: number) => {
                return new Promise<Customer>((resolve, reject) => {
                    customerService.getCustomer(customerId).then((custResp) => {
                        setCustomer(custResp);
                        resolve(custResp);
                    })
                    .catch((jqXHR) => {
                        reject(jqXHR.data.message);
                    });
                })
            },
            updateCustomer: (customer: Customer) => {
                return new Promise<boolean>((resolve, reject) => {
                    customerService.updateCustomer(customer).then((resp)=>{
                        resolve(true);
                    })
                    .catch((jqXHR)=>{
                        reject(false);
                    });
                })
            },
            reloadSetupConfig: () => {
                return new Promise<boolean>((resolve, reject) => {
                    customerService.getCustomerSetupConfiguration(sessionStateContext).then((resp) => {
                        setSetupConfig(resp);
                        resolve(true);
                    })
                    .catch((err) => {
                        resolve(false);;
                    });
                })
            },
            setSetupData: (data: Object) => {
                setSetupData(data);
            },
            loadDefaultDomain: () => {
                customerService.loadCustomerDefaultDomain(setupConfig.customerId).then((resp) => {
                    setDefaultDomain(resp);
                })
                .catch((err) => {
                    console.log(err);
                })
            }
        }}>
            {props.children}
        </CustomerInformationContext.Provider>
    );
}