import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminSystemEventSetupCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda System Event Setup"} endpoint="odata/system/event/setup" ref={ref}>
            <CardGroup name="general" label="General" isOpen={true}>
                <CardField name="eventName" type={SystemFieldType.String} label="Nome Evento" readonly />
                <CardField name="code" type={SystemFieldType.String} label="Codice" />
                <CardField name="title" type={SystemFieldType.String} label="Titolo" />
                <CardField name="description" type={SystemFieldType.String} label="Descrizione" />
                <CardField name="active" type={SystemFieldType.Boolean} label="Attivo" />
                <CardField name="typeCode" type={SystemFieldType.String} label="Codice tipo" hasTableRelation tableRelationEndpoint="odata/system/event/type" tableRelationKey="code" tableRelationField="description" />
                <CardField name="sourceEntity" type={SystemFieldType.String} label="Entità sorgente" hasTableRelation tableRelationEndpoint="odata/system/entity" tableRelationKey="name" tableRelationField="name" />
            </CardGroup> 
        </PageContainer>
    );
});