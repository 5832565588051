import React, { useEffect, useState } from "react";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import {SystemDataLoadingStatus, SystemPageType } from "../../../../Model/SystemModels";
import { Loader } from "../../../Common/Loader/Loader";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";

export const AdminCustomerDomainList : React.FC = () => {
    const [dataStatus,setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [endpoint, setEndpoint] = useState('');
    const [title, setTitle] = useState('');
    const [customerId,setCustomerId] = useState(HttpHelper.getParameter("customerId"));
    const [name,setName] = useState(HttpHelper.getParameter("name"));
 
 
    useEffect(()=>{
       if(customerId != null){
           setEndpoint('/odata/customer/domain?$filter=customerId eq '+customerId);
           setTitle('Lista domini per '+name);
       }
       else{
           setEndpoint('/odata/customer/domain');
           setTitle('Lista domini generale');
       }
       setDataStatus(SystemDataLoadingStatus.Loaded);
    },[dataStatus]);

    if (dataStatus!=SystemDataLoadingStatus.Loaded)
        return <Loader text="Stiamo caricando la lista richiesta..."></Loader>;
    else

    return (
        <>
            <PageContainer endpoint={endpoint} pageType={SystemPageType.List} title={title} backAllowed >
                <PageDefinitionContainer type={ContainerType.Action}>
                </PageDefinitionContainer>                            
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Id Tenant" fieldName="tenantId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Dominio Tenant" fieldName="tenantDomain" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Iniziale" fieldName="isInitial" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Default" fieldName="isDefault" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Abilitato" fieldName="enabled" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Utilizzato di default" fieldName="useAsDefault" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="S2S Abilitato" fieldName="s2SEnabled" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="System Id" fieldName="systemId" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="System Creation User Id" fieldName="systemCreationUserId" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="System Creation Date" fieldName="systemCreationDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Data aggiornamento sistema" fieldName="systemUpdateDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id utente aggiornamento sistema " fieldName="systemUpdateUserId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Timestamp" fieldName="timestamp" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
        </>
    );
}