import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminCRMLegalEntityCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda CRM Legal Entity"} endpoint="odata/crm/legal/entity" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>                
                <CardField label="Codice" name="code" type={SystemFieldType.String} />
                <CardField label="Nome" name="name" type={SystemFieldType.String} />
                <CardField label="Id CRM" name="crmReferenceId" type={SystemFieldType.String} />
                <CardField label="Cod. Distributore default" name="defaultDistributorCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/distributor" tableRelationKey="code" tableRelationField="name" />
                <CardField label="Id Partner default" name="defaultPartnerId" type={SystemFieldType.Number} hasTableRelation tableRelationEndpoint="/odata/partner" tableRelationKey= "id" tableRelationField="name" />
                <CardField label="Customer App Id default" name="defaultCustomerAppId" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/system/app" tableRelationKey= "id" tableRelationField="name" />
                <CardField label="Public Register Url" name="publicRegisterUrl" type={SystemFieldType.String} />
            </CardGroup>
            <CardGroup name="interactions" label="Interactions" isOpen={true}>
                <CardField label="Dalle ore (interazioni)" name="interactionFromTime" type={SystemFieldType.TimeSpan} />
                <CardField label="Alle ore (interazioni)" name="interactionToTime" type={SystemFieldType.TimeSpan} />
                <CardField label="Ore da UTC (TimeZone)" name="timeZoneHourDelay" type={SystemFieldType.Number} />
            </CardGroup>
        </PageContainer>
    );
});