import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminCrmIncidentTypeCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} endpoint="/odata/crm/incident/type" title="Scheda CRM Incident Type" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField label="Id" name="entryNo" type={SystemFieldType.Number} readonly/>
                <CardField label="Nome" name="name" type={SystemFieldType.String} readonly />
                <CardField label="Data Creazione" name="createdOn" type={SystemFieldType.String} readonly />
                <CardField label="Data Modifica" name="modifiedOn" type={SystemFieldType.String} readonly />
                <CardField label="Id CRM EOS" name="crmReferenceId" type={SystemFieldType.String} readonly />
                <CardField label="Visible" name="toShow" type={SystemFieldType.Boolean} />
                <CardField label="Default" name="default" type={SystemFieldType.Boolean} />
            </CardGroup>
        </PageContainer>
    );
});