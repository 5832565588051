import React, { useContext, useEffect, useState,useReducer, useRef } from "react";
import { Customer, CustomerDashboardCue, CustomerEnvironment, CustomerSetupStep } from "../../../../Model/CustomerModels";
import { SystemActionCategory, SystemDataLoadingStatus,OperationType, SystemEntity, SystemPage, SystemPageType } from "../../../../Model/SystemModels";
import { useForceUpdate } from "@fluentui/react-hooks";
import {Link, Routes, Route, useNavigate, useLocation} from 'react-router-dom';
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminCustomerConfigCard } from "../AdminCustomerConfigCard/AdminCustomerConfigCard";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { ILocation } from "../../../../Model/AdminModel";


export const AdminCustomerConfigList : React.FC = () => {    
    const navigate = useNavigate();
    const location = useLocation() as ILocation;
    const cardRef = useRef<PageContainerBase>(null);
    const [customerId,setCustomerId] = useState(HttpHelper.getParameter("customerId"));
    const [name,setName] = useState(HttpHelper.getParameter("name"));

    return (
        <>
            <PageContainer endpoint={"/odata/customer/setup/config?$filter=customerId eq "+customerId} pageType={SystemPageType.List} title={name ? "Configurazione per "+name : "Scheda configurazione"} cardRef={cardRef} backAllowed showAllowed updateAllowed>               
                <PageDefinitionContainer type={ContainerType.Action}>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Ha tenant id" fieldName="hasTenant" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Id tenant" fieldName="tenantId" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Admin tenant id" fieldName="adminTenantUserId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Setup completato" fieldName="setupCompleted" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Provisioning completato" fieldName="provisioningCompleted" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Abilitato" fieldName="enabled" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Email partner" fieldName="partnerEmail" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Data invio email partner" fieldName="partnerEmailSendAt" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Intro completed" fieldName="introCompleted" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Blocco multi sessione" fieldName="blockMultipleSession" minWidth={100} maxWidth={200} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminCustomerConfigCard ref={cardRef} />
        </>
    );
}