import { Outlet, Link } from "react-router-dom";
import styles from "./Layout.module.css";
import Azure from "../../../Assets/Images/favicon.png";
import { CommandBarButton, Dialog, Stack, TextField, ICommandBarStyles, IButtonStyles, DefaultButton, Icon, TeachingBubble  } from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import { CustomCommandBarButton } from "../../Common/Chatbot/Button/Button";
import { AppStateContext, AppStateProvider } from "../../../Core/State/ChatbotAppProvider";
import { CosmosDBStatus } from "../../../ApplicationCode/Common/Chatbot/api";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import i18next, { t } from "i18next";
import Chat from "../chat/Chat";
import { ChatbotHelper } from "../ChatHelper";

const shareButtonStyles: ICommandBarStyles & IButtonStyles = {
    root: {
      width: 86,
      height: 32,
      borderRadius: 4,
      background: 'radial-gradient(109.81% 107.82% at 100.1% 90.19%, #0F6CBD 33.63%, #2D87C3 70.31%, #8DDDD8 100%)',
    //   position: 'absolute',
    //   right: 20,
      padding: '5px 12px',
      marginRight: '20px'
    },
    icon: {
      color: '#FFFFFF',
    },
    rootHovered: {
      background: 'linear-gradient(135deg, #0F6CBD 0%, #2D87C3 51.04%, #8DDDD8 100%)',
    },
    label: {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '20px',
      color: '#FFFFFF',
    },
  };

const Layout = () => {
    const [isSharePanelOpen, setIsSharePanelOpen] = useState<boolean>(false);
    const [copyClicked, setCopyClicked] = useState<boolean>(false);
    const [copyText, setCopyText] = useState<string>(t("chatbot:layout:shareDialog:copyText")!);
    const appStateContext = useContext(AppStateContext);


    const handleShareClick = () => {
        setIsSharePanelOpen(true);
    };

    const handleSharePanelDismiss = () => {
        setIsSharePanelOpen(false);
        setCopyClicked(false);
        setCopyText(t("chatbot:layout:shareDialog:copyText")!);
    };

    const handleCopyClick = () => {
        navigator.clipboard.writeText(window.location.href);
        setCopyClicked(true);
    };

    const handleHistoryClick = () => {
        appStateContext?.dispatch({ type: 'TOGGLE_CHAT_HISTORY' })
    };

    const setLanguageFromBrowser = () => {
        let browserLanguage = navigator.language;
        if(browserLanguage.includes('-'))
            browserLanguage = browserLanguage.split('-')[0];
        
        i18next.changeLanguage(browserLanguage.toLowerCase());
    }

    const handleChatbotLanguage = (useBroserLanguage: boolean) => {
        let language: string | null = null;
        const embedded: boolean = (HttpHelper.getParameter("embedded") === "true");

        if(!embedded)
            return;
        if(!useBroserLanguage)
            language = HttpHelper.getParameter("lang");

        switch (language) {
            case 'it':
                i18next.changeLanguage("it");
                break;
            case 'en':
                i18next.changeLanguage("en");
                break;
            default:
                setLanguageFromBrowser();
                break;
        }
    };

    useEffect(() => {
        handleChatbotLanguage(false);

        return () => {
            handleChatbotLanguage(true);
        };
    },[])

    useEffect(() => {
        if (copyClicked) {
            setCopyText(t("chatbot:layout:shareDialog:copiedText")!);
        }
    }, [copyClicked]);

    useEffect(() => {}, [appStateContext?.state.isCosmosDBAvailable.status]); 

    return (
        <AppStateProvider>
            <div className={styles.layout}>
                <header className={styles.header} role={"banner"}>
                    <Stack horizontal verticalAlign="center" horizontalAlign="space-between"
                    // className={styles.headerContainer}
                    >
                        <Stack horizontal verticalAlign="center">
                            <img
                                src={ChatbotHelper.getIcon()}
                                className={styles.headerIcon}
                                aria-hidden="true"
                            />
                            <Link to="/" className={styles.headerTitleContainer}>
                                <h1 className={styles.headerTitle}>{ChatbotHelper.getTitle()}</h1>
                            </Link>
                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: 4 }}>
                            <CustomCommandBarButton
                                id="shareChatbot"
                                iconName="Share"
                                text={t("chatbot:layout:shareAction")!}
                                onClick={handleShareClick} 
                            />
                        </Stack>

                    </Stack>
                </header>
                <Outlet />
                <Dialog 
                    onDismiss={handleSharePanelDismiss}
                    hidden={!isSharePanelOpen}
                    styles={{
                        
                        main: [{
                            selectors: {
                            ['@media (min-width: 480px)']: {
                                maxWidth: '600px',
                                background: "#FFFFFF",
                                boxShadow: "0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)",
                                borderRadius: "8px",
                                maxHeight: '200px',
                                minHeight: '100px',
                            }
                            }
                        }]
                    }}
                    dialogContentProps={{
                        title: t("chatbot:layout:shareDialog:title")!,
                        showCloseButton: true
                    }}
                >
                    <Stack horizontal verticalAlign="center" style={{gap: "8px"}}>
                        <TextField className={styles.urlTextBox} defaultValue={window.location.href} readOnly/>
                        <div 
                            className={styles.copyButtonContainer} 
                            role="button" 
                            tabIndex={0} 
                            aria-label="Copy" 
                            onClick={handleCopyClick}
                            onKeyDown={e => e.key === "Enter" || e.key === " " ? handleCopyClick() : null}
                        >
                            <Icon iconName="Copy" className={styles.copyButton} />
                            <span className={styles.copyButtonText}>{copyText}</span>
                        </div>
                    </Stack>
                </Dialog>
            </div>
        </AppStateProvider>
    );
};

export const EmbeddedLayout = () => {
    const [isSharePanelOpen, setIsSharePanelOpen] = useState<boolean>(false);
    const [copyClicked, setCopyClicked] = useState<boolean>(false);
    const [teachingBubbleVisible, setTeachingBubbleVisible] = useState<boolean>(document.location.pathname.indexOf("demo") >= 0);
    const [copyText, setCopyText] = useState<string>(t("chatbot:layout:shareDialog:copyText")!);
    const appStateContext = useContext(AppStateContext);

    const handleShareClick = () => {
        setIsSharePanelOpen(true);
    };

    const handleSharePanelDismiss = () => {
        setIsSharePanelOpen(false);
        setCopyClicked(false);
        setCopyText(t("chatbot:layout:shareDialog:copyText")!);
    };

    const handleCopyClick = () => {
        navigator.clipboard.writeText(window.location.href);
        setCopyClicked(true);
    };

    const handleHistoryClick = () => {
        appStateContext?.dispatch({ type: 'TOGGLE_CHAT_HISTORY' })
    };

    const setLanguageFromBrowser = () => {
        let browserLanguage = navigator.language;
        if(browserLanguage.includes('-'))
            browserLanguage = browserLanguage.split('-')[0];
        
        i18next.changeLanguage(browserLanguage.toLowerCase());
    }

    const handleChatbotLanguage = (useBroserLanguage: boolean) => {
        let language: string | null = null;
        const embedded: boolean = (HttpHelper.getParameter("embedded") === "true");

        if(!embedded)
            return;
        if(!useBroserLanguage)
            language = HttpHelper.getParameter("lang");

        switch (language) {
            case 'it':
                i18next.changeLanguage("it");
                break;
            case 'en':
                i18next.changeLanguage("en");
                break;
            default:
                setLanguageFromBrowser();
                break;
        }
    };

    useEffect(() => {
        handleChatbotLanguage(false);

        return () => {
            handleChatbotLanguage(true);
        };
    },[])

    useEffect(() => {
        if (copyClicked) {
            setCopyText(t("chatbot:layout:shareDialog:copiedText")!);
        }
    }, [copyClicked]);

    useEffect(() => {}, [appStateContext?.state.isCosmosDBAvailable.status]); 

    return (
        <AppStateProvider>
            <div className={styles.layout} id="chatbotPanel">
                <Chat />
                <Dialog 
                    onDismiss={handleSharePanelDismiss}
                    hidden={!isSharePanelOpen}
                    styles={{
                        
                        main: [{
                            selectors: {
                            ['@media (min-width: 480px)']: {
                                maxWidth: '600px',
                                background: "#FFFFFF",
                                boxShadow: "0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)",
                                borderRadius: "8px",
                                maxHeight: '200px',
                                minHeight: '100px',
                            }
                            }
                        }]
                    }}
                    dialogContentProps={{
                        title: t("chatbot:layout:shareDialog:title")!,
                        showCloseButton: true
                    }}
                >
                    <Stack horizontal verticalAlign="center" style={{gap: "8px"}}>
                        <TextField className={styles.urlTextBox} defaultValue={window.location.href} readOnly/>
                        <div 
                            className={styles.copyButtonContainer} 
                            role="button" 
                            tabIndex={0} 
                            aria-label="Copy" 
                            onClick={handleCopyClick}
                            onKeyDown={e => e.key === "Enter" || e.key === " " ? handleCopyClick() : null}
                        >
                            <Icon iconName="Copy" className={styles.copyButton} />
                            <span className={styles.copyButtonText}>{copyText}</span>
                        </div>
                    </Stack>
                </Dialog>
            </div>
        </AppStateProvider>
    );
};


export default Layout;
