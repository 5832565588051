import React, { useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { SystemCore } from "../../../../Core/System/SystemCore";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { SystemActionCategory,SystemDataLoadingStatus,SystemPageType } from "../../../../Model/SystemModels";
import { Loader } from "../../../Common/Loader/Loader";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminCrmSetupCard } from "../AdminCrmSetupCard/AdminCrmSetupCard";


export const AdminCrmSetupList : React.FC = () => {
    const [dataStatus,setDataStatus] = useState(SystemDataLoadingStatus.Loaded);
    const [loaderMessage,setLoaderMessage] = useState("");
    const cardRef = useRef<PageContainerBase>(null);
    const navigate = useNavigate();
    const adminService = new AdminService();

    const syncCrmAccount = () => {
        adminService.syncCrmAccount().then((resp:string)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Success,resp);
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error,jqXHR.message);
        })
    }

    const syncCrmContact = () => {
        adminService.syncCrmContact().then((resp:string)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Success,resp);
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error,jqXHR.message);
        })
    }

    const syncCrmEntitlements = () => {
        adminService.syncCrmEntitlements().then((resp:string)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Success,resp);
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error,jqXHR.message);
        })
    }

    const syncCrmIncidents = () => {
        adminService.syncCrmIncidents().then((resp:string)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Success,resp);
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error,jqXHR.message);
        })
    }

    const syncCrmModifiedIncidents = () => {
        adminService.syncCrmModifiedIncidents().then((resp:string)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Success,resp);
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error,jqXHR.message);
        })
    }

    const syncCrmSystemUsers = () => {
        adminService.syncCrmSystemUsers().then((resp:string)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Success,resp);
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error,jqXHR.message);
        })
    }

    const mapIncidents = () => {
        adminService.mapIncidents().then((resp:string)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Success,resp);
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error,jqXHR.message);
        })
    }
    
    const syncCrmIncidentTypes = () => {
        adminService.syncCrmIncidentTypes().then((resp:string)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Success,resp);
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error,jqXHR.message);
        })
    }

    const syncCrmInternalPriorities = () => {
        adminService.syncCrmInternalPriorities().then((resp:string)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Success,resp);
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error,jqXHR.message);
        })
    }

    const syncPartners = () => {
        adminService.syncPartners().then((resp:string)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Success,resp);
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error,jqXHR.message);
        })
    }

    const syncPartnersUsers = () => {
        adminService.syncPartnersUsers().then((resp:string)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Success,resp);
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error,jqXHR.message);
        })
    }

    const syncKnowledgeArticles = (syncModified: boolean) => {
        adminService.syncAllKnowledgeArticles(syncModified).then(()=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Success,"Sincronizzazione knowledge articles completata con successo");
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error,jqXHR.message);
        })
    }
 
    if (!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={loaderMessage}></Loader>;
    else
        return (
            <>
                <PageContainer endpoint={'/odata/CRMSetup'} pageType={SystemPageType.List} title={"CRM Setup"} cardRef={cardRef} backAllowed showAllowed insertAllowed updateAllowed deleteAllowed>               
                    <PageDefinitionContainer type={ContainerType.Action}>
                        <ActionArea category={SystemActionCategory.Navigation}>
                            <ActionEntry name="CRMSourceList" label="Sorgenti" iconName="BacklogList" onClick={(req:any)=>{
                                    navigate('/app/admin/administrative/crmSourceList');
                                }}>
                            </ActionEntry>
                            <ActionEntry name="CRMCampaignList" label="Campagne" iconName="BacklogList" onClick={(req:any)=>{
                                    navigate('/app/admin/administrative/crmCampaignList');
                                }}>
                            </ActionEntry>
                            <ActionEntry name="CRMLossCauseList" label="Cause perdita" iconName="CRMReport" onClick={(req:any)=>{
                                    navigate('/app/admin/administrative/crmLossCauseList');
                                }}>
                            </ActionEntry>
                            <ActionEntry name="CRMStatusCode" label="Cause squalifica" iconName="Dislike" onClick={(req:any)=>{
                                navigate('/app/admin/administrative/crmStatusCode');
                            }}>                                
                            </ActionEntry>
                            <ActionEntry name="CRMSystemUserList" label="Utenti sistema" iconName="UserOptional" onClick={(req:any)=>{
                                    navigate('/app/admin/administrative/crmSystemUserList');
                                }}>
                            </ActionEntry>
                            <ActionEntry name="CRMIncidentTypeList" label="Tipi Incident" iconName="UserOptional" onClick={(req:any)=>{
                                    navigate('/app/admin/administrative/crmIncidentTypeList');
                                }}>
                            </ActionEntry>
                            <ActionEntry name="crmProfilingSetup" label="Setup profiling" iconName="PlayerSettings" onClick={(req:any)=>{
                                    navigate('/app/admin/administrative/crmProfilingSetup');
                                }}>
                            </ActionEntry>
                            <ActionEntry name="authenticationEntry" label="Authentication Entry" iconName="AuthenticatorApp" runOnRec onClick={(req:any)=>{
                                    navigate('/app/admin/administrative/authenticationEntry?tenantId='+req.tenantId+'&clientId='+req.clientId+'')
                                }}>
                            </ActionEntry>
                        </ActionArea>
                        <ActionArea category={SystemActionCategory.Process}>
                            <ActionEntry name="SyncCrmAccount" label="Sincronizza Account" iconName="UserSync" onClick={(req:any)=>{
                                    setDataStatus(SystemDataLoadingStatus.ToLoad)
                                    setLoaderMessage("Sincronizzazione account in corso, Attendere...");
                                    syncCrmAccount();
                                }}>
                            </ActionEntry>
                            <ActionEntry name="SyncCrmContact" label="Sincronizza Contatti" iconName="PublicContactCard" onClick={(req:any)=>{
                                    setDataStatus(SystemDataLoadingStatus.ToLoad)
                                    setLoaderMessage("Sincronizzazione contatti in corso, Attendere...");
                                    syncCrmContact();
                                }}>
                            </ActionEntry>
                            <ActionEntry name="SyncCrmSystemUsers" label="Sincronizza System Users" iconName="UserFollowed" onClick={(req:any)=>{
                                    setDataStatus(SystemDataLoadingStatus.ToLoad)
                                    setLoaderMessage("Sincronizzazione System Users in corso, Attendere...");
                                    syncCrmSystemUsers();
                                }}>
                            </ActionEntry>
                            <ActionEntry name="SyncCrmIncidentTypes" label="Sincronizza Incident Types" iconName="DatabaseSync" onClick={(req:any)=>{
                                    setDataStatus(SystemDataLoadingStatus.ToLoad)
                                    setLoaderMessage("Sincronizzazione Incident Types in corso, Attendere...");
                                    syncCrmIncidentTypes();
                                }}>
                            </ActionEntry>
                            <ActionEntry name="SyncCrmInternalPriorities" label="Sincronizza Internal Priorities" iconName="DatabaseSync" onClick={(req:any)=>{
                                    setDataStatus(SystemDataLoadingStatus.ToLoad)
                                    setLoaderMessage("Sincronizzazione Internal Priorities in corso, Attendere...");
                                    syncCrmInternalPriorities();
                                }}>
                            </ActionEntry>
                            <ActionEntry name="SyncCrmEntitlements" label="Sincronizza Entitlements" iconName="ChangeEntitlements" onClick={(req:any)=>{
                                    setDataStatus(SystemDataLoadingStatus.ToLoad)
                                    setLoaderMessage("Sincronizzazione Entitlements in corso, Attendere...");
                                    syncCrmEntitlements();
                                }}>
                            </ActionEntry>
                            <ActionEntry name="SyncCrmIncidents" label="Sincronizza Incidents" iconName="SyncFolder" onClick={(req:any)=>{
                                    setDataStatus(SystemDataLoadingStatus.ToLoad)
                                    setLoaderMessage("Sincronizzazione Incidents in corso, Attendere...");
                                    syncCrmIncidents();
                                }}>
                            </ActionEntry>
                            <ActionEntry name="SyncCrmModifiedIncidents" label="Sincronizza Incidents Modificati" iconName="SyncFolder" onClick={(req:any)=>{
                                    setDataStatus(SystemDataLoadingStatus.ToLoad)
                                    setLoaderMessage("Sincronizzazione Incidents in corso, Attendere...");
                                    syncCrmModifiedIncidents();
                                }}>
                            </ActionEntry>
                            <ActionEntry name="MapAllUnmappedIncidents" label="Mappatura Incidents" iconName="IssueTracking" onClick={(req:any)=>{
                                    setDataStatus(SystemDataLoadingStatus.ToLoad)
                                    setLoaderMessage("Mappatura Incidents in corso, Attendere...");
                                    mapIncidents();
                                }}>
                            </ActionEntry>
                            <ActionEntry name="SyncronizePartners" label="Sincronizza Partner" iconName="AccountManagement" onClick={(req:any)=>{
                                    setDataStatus(SystemDataLoadingStatus.ToLoad)
                                    setLoaderMessage("Sincronizzazione Partner in corso, Attendere...");
                                    syncPartners();
                                }}>
                            </ActionEntry>
                            <ActionEntry name="SyncronizePartnersUsers" label="Sincronizza Utenti Partner" iconName="UserSync" onClick={(req:any)=>{
                                    setDataStatus(SystemDataLoadingStatus.ToLoad)
                                    setLoaderMessage("Sincronizzazione Utenti Partner in corso, Attendere...");
                                    syncPartnersUsers();
                                }}>
                            </ActionEntry>
                            <ActionEntry name="SyncronizeAllKnowledgeArticles" label="Sincronizza knowledge articles" iconName="Sync" onClick={(req:any)=>{
                                    setDataStatus(SystemDataLoadingStatus.ToLoad)
                                    setLoaderMessage("Sincronizzazione knowledge articles in corso, Attendere...");
                                    syncKnowledgeArticles(false);
                                }}>
                            </ActionEntry>
                            <ActionEntry name="SyncronizeModifiedKnowledgeArticles" label="Sincronizza knowledge articles modificati" iconName="SyncOccurence" onClick={(req:any)=>{
                                    setDataStatus(SystemDataLoadingStatus.ToLoad)
                                    setLoaderMessage("Sincronizzazione knowledge articles modificati in corso, Attendere...");
                                    syncKnowledgeArticles(true);
                                }}>
                            </ActionEntry>
                        </ActionArea>
                    </PageDefinitionContainer>
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry fieldName="primaryKey" name="Chiave primaria" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="authType" name="Auth Type" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="resource" name="Risorsa" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="tenantId" name="Id Tenant" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="clientId" name="Client Id" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="clientSecret" name="Client Secret" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="redirectUrl" name="Redirect Url" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="aadInstance" name="Instance AAD" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="baseAddress" name="Base Address Lead" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="oDataVersion" name="Odata Version" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="oDataMaxVersion" name="Odata max version" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="defaultCustomerTypeCode" name="Default customer type code" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="defaultProspectTypeCode" name="Default prospect type code" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="defaultCountryId" name="Default country id" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="defaultLegalEntityId" name="Default legal entity Id" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="defaultServiceLevel" name="Default Service Level" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="serviceScopeEOSName" name="Service Scope eos name" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="serviceScopeQueueId" name="Service scope queue Id" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="serviceScopeId" name="Service scope id" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="crmSyncEnabled" name="Crm sync enabled" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="defaultCRMTeamId" name="Default CRM Team Id" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="defaultEntitlementName" name="Default Entitlement Name" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="incidentBaseAddress" name="Base Address Incident" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="crmSystemUserFilterSyncronization" name="Filtro Sincronizzazione System Users" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="notificationEmail" name="E-mail notifica" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="partnerAppId" name="Id App partner" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="enableNotification" name="Abilita notifica" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="incidentDayLimitForExpiration" name="Giorni Ritardo Ticket" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="leadNotificationSetupType" name="Tipo setup" minWidth={100} maxWidth={200} onRender={(item: any, index, column)=>{
                            if (item.type === 0)
                                return "Email"
                            else if (item.type === 1)
                                return "Chat"
                        }} />
                        <ListHeaderEntry fieldName="leadNotificationSetupCode" name="Codice setup" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="leadNotificationTemplateCode" name="Codice template" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="leadNotificationGroupCode" name="Codice gruppo" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="defaultLeadOwnerId" name="Proprietario lead default"  minWidth={100} maxWidth={200} />
                    </PageDefinitionContainer>
                </PageContainer>
                <AdminCrmSetupCard ref={cardRef} />
            </>
        );
}