import React, { forwardRef } from "react";
import { SystemFieldType,SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import PageContainer, {PageContainerBase } from "../../../Common/Template/PageContainer";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";

export interface IAdminUserPermissionPanel{
    Username?:string
}

export const AdminUserPermissionPanel = forwardRef<PageContainerBase, React.PropsWithChildren<IAdminUserPermissionPanel>>((props, ref) => {    
    return (
        <PageContainer pageType={SystemPageType.CardPanel} endpoint="/odata/user/role" title={props.Username ? "Assegnazione permessi per "+props.Username : "Assegnazione permessi"} ref={ref}>
            <CardGroup name="general" label="Generale" isOpen>
                <CardField name="appId" label="Applicazione" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/system/app" tableRelationKey="id" tableRelationField="name" />
                <CardField name="roleCode" label="Ruolo" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/system/role" tableRelationFilter="?$filter=appId eq '#appId#'" tableRelationKey="code" tableRelationField="code" ></CardField>
                <CardField name='userId' label="Id Utente" readonly type={SystemFieldType.Number} />
            </CardGroup>
        </PageContainer>    
    );
});