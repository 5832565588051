import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminItemCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda Item"} endpoint="odata/item" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="id" type={SystemFieldType.Number} label="Id" readonly />
                <CardField name="category" type={SystemFieldType.Option} label="Categoria" cmbOptions={[
                    {key: "SoSmart", text: "So Smart"},
                    {key: "EosApps", text: "EOS Apps"}
                ]} defaultSelectedKey={"SoSmart"} />
                <CardField name="description" type={SystemFieldType.String} label="Descrizione" />
                <CardField name="billingCycle" type={SystemFieldType.Option} label="Ciclo Fatturazione" cmbOptions={[
                    {key: 0, text: "Unknown"},
                    {key: 1, text: "Monthly"},
                    {key: 2, text: "Annual"},
                    {key: 3, text: "None"},
                    {key: 4, text: "OneTime"}
                ]} />
                <CardField name="itemType" type={SystemFieldType.Option} label="Tipologia" cmbOptions={[
                    {key: 0, text: "Subscription"},
                    {key: 1, text: "Company"},
                    {key: 2, text: "App"}
                ]} />
                <CardField name="moduleCode" type={SystemFieldType.String} label="Codice Modulo" />
                <CardField name="itemEcommerceCode" type={SystemFieldType.String} label="Ecommerce Codice Articolo" />
                <CardField name="termDuration" type={SystemFieldType.String} label="Termine Durata" />
                <CardField name="unitPrice" type={SystemFieldType.Number} label="Prezzo Unitario" />
                <CardField name="unitCost" type={SystemFieldType.Number} label="Costo Unitario" />
                <CardField name="priceType" type={SystemFieldType.Option} label="Tipo Prezzo" cmbOptions={[
                    {key: "one_time", text: "One Time"},
                    {key: "recurring", text: "Recurring"}
                ]} />
            </CardGroup>
            <CardGroup name="Core" label="Core" isOpen={true}>
                <CardField name="trialProductId" type={SystemFieldType.String} label="Trial Id Prodotto CSP" />
                <CardField name="trialProductSkuId" type={SystemFieldType.String} label="Trial Id SKU CSP" />
                <CardField name="aadGroupId" type={SystemFieldType.String} label="Id Gruppo AAD" />
                <CardField name="productId" type={SystemFieldType.String} label="Id Prodotto CSP" />
                <CardField name="productSkuId" type={SystemFieldType.String} label="Id SKU CSP" />
                <CardField name="productSkuAvailabilityId" type={SystemFieldType.String} label="Id Availability CSP" />
                <CardField name="trialProductQuantity" type={SystemFieldType.String} label="Trial Quantità Prodotto CSP" />
                <CardField name="cspProductName" type={SystemFieldType.String} label="Nome Prodotto CSP" />
            </CardGroup>
            <CardGroup name="erp" label="ERP" isOpen={true}>
                <CardField name="itemErpNo" type={SystemFieldType.String} label="No Codice ERP" />
                <CardField name="erpDescription" type={SystemFieldType.String} label="Descrizione ERP" />
            </CardGroup>
            <CardGroup name="visibility" label="Visibilità" isOpen={true}>
                <CardField name="hideFromChatbot" type={SystemFieldType.Boolean} label="Nascondi da chatbot" />
                <CardField name="showInLeadQuote" type={SystemFieldType.Boolean} label="Mostra su offerta lead" />
                <CardField name="priority" type={SystemFieldType.Number} label="Priorità" />
                <CardField name="mandatory" type={SystemFieldType.Boolean} label="Obbligatorio" />
            </CardGroup>
            <CardGroup name="localization" label="Localizzazione" isOpen={true}>
                <CardField label="Legal Entity" name="legalEntityCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/crm/legal/entity" tableRelationKey="code" tableRelationField="name" />
                <CardField label="Distributore" name="distributorCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/distributor" tableRelationKey="code" tableRelationField="name" />
                <CardField label="Partner Business Contract" name="partnerBusinessContractCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/partner/business/contract" tableRelationKey="code" tableRelationField="description" />
                <CardField label="Business line" name="businessLineCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/business/line" tableRelationKey="code" tableRelationField="description" />
                <CardField label="Lingua" name="languageCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/system/language" tableRelationKey="code" tableRelationField="name" />
            </CardGroup>
        </PageContainer>
    );
});