import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminCrmIncidentCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} endpoint="/odata/crm/user/incident" title="Scheda CRM Incident" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={false}>
                <CardField label="Id" name="entryNo" type={SystemFieldType.Number} readonly/>
                <CardField label="Id Utente" name="userId" type={SystemFieldType.Number} readonly />
                <CardField label="Nome Utente" name="userDisplayName" type={SystemFieldType.String} readonly />
                <CardField label="Id Cliente" name="customerId" type={SystemFieldType.Number} readonly />
                <CardField label="Nome Cliente" name="customerCompanyName" type={SystemFieldType.String} readonly />
                <CardField label="Entitlement" name="crmCustomerEntitlementName" type={SystemFieldType.String} readonly />
                <CardField label="Nome Partner" name="partnerName" type={SystemFieldType.String} readonly />
                <CardField label="Partner Business Contract" name="partnerBusinessContractCode" type={SystemFieldType.String} readonly />
                <CardField label="Titolo" name="title" type={SystemFieldType.String} readonly />
                <CardField label="Descrizione" name="description" type={SystemFieldType.String} readonly />
                <CardField label="Data Creazione" name="createdOn" type={SystemFieldType.String} readonly />
                <CardField label="Data Modifica" name="modifiedOn" type={SystemFieldType.String} readonly />
                <CardField label="Id CRM EOS" name="crmReferenceId" type={SystemFieldType.String} readonly />
                <CardField label="Utente Propretario" name="owningUserDisplayName" type={SystemFieldType.String} readonly />
                <CardField label="Utente Lavorazione" name="workingByUserDisplayName" type={SystemFieldType.String} readonly />
                <CardField label="Minuti Lavorazione" name="workingMinutes" type={SystemFieldType.Number} readonly />
                <CardField label="Stato" name="stateDescription" type={SystemFieldType.String} readonly />
                <CardField label="Avanzamento" name="crmStatusDescription" type={SystemFieldType.String} readonly />
                <CardField label="Titolo di Origine" name="sourceTitle" type={SystemFieldType.String} readonly />
                <CardField label="Link di Origine" name="sourceLink" type={SystemFieldType.String} readonly />
                <CardField label="Ultimo Errore" name="lastError" type={SystemFieldType.String} readonly />
                <CardField label="Applicazione di Origine" name="sourceApplicationOrigin" type={SystemFieldType.String} readonly />
                <CardField label="Numero Interazioni" name="internalInteractions" type={SystemFieldType.Number} readonly />
                <CardField label="Descrizione soluzione" name="resolutionDescription" type={SystemFieldType.String} readonly />
                <CardField label="Minuti soluzione" name="resolutionMinutes" type={SystemFieldType.Number} readonly />
                <CardField label="Origine CRM" name="caseOriginCode" type={SystemFieldType.Option} readonly cmbOptions={[
                    {key: 1, text: "Manual"},
                    {key: 2, text: "Email"},
                    {key: 3, text: "Web"},
                    {key: 2483, text: "Facebook"},
                    {key: 3986, text: "Twitter"},
                    {key: 700610000, text: "IoT"},
                ]} />
                <CardField label="Id supporto" name="supportId" type={SystemFieldType.String} readonly />
            </CardGroup>
            <CardGroup name="upload" label="Upload" isOpen={false}>
                <CardField label="Stato Upload CRM" name="uploadStatus" type={SystemFieldType.Option} cmbOptions={[
                    {key: 0, text: "In coda"},
                    {key: 1, text: "Caricato"},
                    {key: 3, text: "In errore"}
                ]} readonly />
            </CardGroup>
            <CardGroup name="troubleshooting" label="Risoluzione delle opportunità" isOpen={true}>
                <CardField label="Codice modulo" name="moduleCode" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="/odata/bcn/process/module" tableRelationKey="code" tableRelationField="description" />
                <CardField label="Codice processo" name="processCode" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="/odata/bcn/process" tableRelationFilter="?$filter=processModule eq '#moduleCode#'" tableRelationKey="processCode" tableRelationField="description" />
                <CardField label="Nr. riga test" name="processTestLineNo" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="/odata/bcn/process/test" tableRelationFilter="?$filter=processModule eq '#moduleCode#' and processCode eq '#processCode#'" tableRelationKey="lineNo" tableRelationField="description" />
                <CardField label="Nr. riga FAQ" name="processFAQLineNo" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="/odata/bcn/process/faq" tableRelationFilter="?$filter=processModule eq '#moduleCode#' and processCode eq '#processCode#' and processTestLineNo eq #processTestLineNo#" tableRelationKey="processFAQLineNo" tableRelationField="questions" />
                <CardField label="Errore" name="isError" type={SystemFieldType.Boolean} />
                <CardField label="Critico" name="isCritical" type={SystemFieldType.Boolean} />
                <CardField label="Tipo" name="crmIncidentTypeId" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="/odata/crm/incident/type" tableRelationKey="entryNo" tableRelationField="name" />
                <CardField label="Priorità Interna" name="crmInternalPriorityId" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="/odata/crm/internal/priority" tableRelationKey="entryNo" tableRelationField="name" />
                <CardField label="Documentazione gestita" name="documentationManaged" type={SystemFieldType.Boolean} />
                <CardField label="Proposta test documentazione mancante" name="missingDocsTestProposal" type={SystemFieldType.String} />
            </CardGroup>
            <CardGroup name="AI" label="AI" isOpen={true}>
                <CardField label="Risposta suggerita" name="aiAnswer" type={SystemFieldType.String} multiline readonly/>
            </CardGroup>
        </PageContainer>
    );
});