import React from "react";
import { SystemActionCategory } from "../../../../Model/SystemModels";

export interface IActionAreaProps {
    category: SystemActionCategory;    
}

export const ActionArea: React.FC<IActionAreaProps> = (props) => {
    return (
        <></>
    );
}