import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { t } from "i18next";

export const AdminCrmLeadInteractionCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={t("adminCenter:crmInteractionCard:title")!} endpoint="odata/crm/lead/interaction" ref={ref}>
            <CardGroup name="general" label="General" isOpen={true}>
                <CardField name="title" type={SystemFieldType.String} label={t("adminCenter:crmInteractionCard:fields:title")!} readonly />
                <CardField name="leadId" type={SystemFieldType.Number} label={t("adminCenter:crmInteractionCard:fields:leadId")!} readonly />
                <CardField name="type" type={SystemFieldType.Option} label={t("adminCenter:crmInteractionCard:fields:type")!}  cmbOptions={[
                    { key: 0, text: t("adminCenter:crmInteractionCard:fields:type0")! },
                    { key: 1, text: t("adminCenter:crmInteractionCard:fields:type1")! },
                    { key: 2, text: t("adminCenter:crmInteractionCard:fields:type2")! },
                    { key: 3, text: t("adminCenter:crmInteractionCard:fields:type3")! },
                    { key: 4, text: t("adminCenter:crmInteractionCard:fields:type4")! },
                    { key: 5, text: t("adminCenter:crmInteractionCard:fields:type5")! }
                ]} readonly />
                <CardField name="crmReferenceStartDate" type={SystemFieldType.String} label={t("adminCenter:crmInteractionCard:fields:crmReferenceStartDate")!} readonly />
                <CardField name="crmReferenceEndDate" type={SystemFieldType.String} label={t("adminCenter:crmInteractionCard:fields:crmReferenceEndDate")!} readonly />
                <CardField name="interactionSource" type={SystemFieldType.Option} label={t("adminCenter:crmInteractionCard:fields:interactionSource")!}  cmbOptions={[
                    { key: 0, text: t("adminCenter:crmInteractionCard:fields:interactionSource0")! },
                    { key: 1, text: t("adminCenter:crmInteractionCard:fields:interactionSource1")! }
                ]} readonly />
                <CardField name="createdOn" type={SystemFieldType.String} label={t("adminCenter:crmInteractionCard:fields:createdOn")!} readonly />
                <CardField name="modifiedOn" type={SystemFieldType.String} label={t("adminCenter:crmInteractionCard:fields:modifiedOn")!} readonly />
                <CardField name="percentComplete" type={SystemFieldType.Number} label={t("adminCenter:crmInteractionCard:fields:percentComplete")!} />
                <CardField name="durationMinutes" type={SystemFieldType.Number} label={t("adminCenter:crmInteractionCard:fields:durationMinutes")!} />
                <CardField name="phoneNumber" type={SystemFieldType.String} label={t("adminCenter:crmInteractionCard:fields:phoneNumber")!} />
                <CardField name="userDisplayName" type={SystemFieldType.String} label={t("adminCenter:crmInteractionCard:fields:userDisplayName")!} />
            </CardGroup>
            <CardGroup name="html" label="Interaction" isOpen={true}>
                <CardField name="description" type={SystemFieldType.HTML} label={t("adminCenter:crmInteractionCard:fields:description")!} readonly />
            </CardGroup>
        </PageContainer>
    );
});