import { AnimationClassNames, ICommandBarItemProps, Label, mergeStyles, Stack } from "@fluentui/react";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AdminService } from "../../../ApplicationCode/Admin/AdminService";
import { CommonFunctions } from "../../../ApplicationCode/Common/CommonFunctions";
import { SystemCore } from "../../../Core/System/SystemCore";
import { PageMetadata, PageStructureMetadata, SystemDataLoadingStatus, SystemEntityProperty, SystemOperation, SystemPage, SystemPageStructure, SystemPageType } from "../../../Model/SystemModels";
import { CommonActionBar } from "../../Common/CommonActionBar/CommonActionBar";
import { Loader } from "../../Common/Loader/Loader";
import { PageContainerBase } from "../../Common/Template/PageContainer";
import { AdminPageDesignerAddPanel } from "./AdminPageDesignerAddPanel/AdminPageDesignerAddPanel";
import { AdminPageDesignerStructure } from "./AdminPageDesignerStructure/AdminPageDesignerStructure";

const pageListContainerClassName = mergeStyles([{
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20
}]);

const pageListMainContainerClassName = mergeStyles([{
    width: '98%',
    backgroundColor: 'white',
    border: '1px solid #d9d9d9',
    boxShadow: '6px 7px 5px 0px rgba(230,230,230,1)',
    padding: 10,
    manHeight: 'calc(100vh - 100px)'
}]);

export const AdminPageDesigner: React.FC = () => {
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [currentPage, setCurrentPage] = useState(new PageMetadata());
    const [fields, setFields] = useState([] as SystemEntityProperty[]);
    const [structures, setStructures] = useState([] as PageStructureMetadata[]);
    const [structureShimmer, setStructureShimmer] = useState(true);
    const [showPropetySection, setShowPropetySection] = useState(false);

    const panelRef = useRef<PageContainerBase>(null);
    const adminService = new AdminService();
    let { pageId } = useParams();
    const navigate = useNavigate();
    
    const buildActions = () : ICommandBarItemProps[] => {
        let actions: ICommandBarItemProps[] = [];
        actions.push({
            key: 'add',
            text: 'Aggiungi',
            iconProps: {
                iconName: 'Add'
            },
            onClick: () => {
                panelRef.current?.setRecord(new PageStructureMetadata());
                panelRef.current?.openPage(SystemOperation.Insert);
            }
        });
        
        actions.push({
            key: 'properties',
            text: 'Proprietà',
            iconProps: {
                iconName: 'Info'
            },
            onClick: () => {
                setShowPropetySection(! showPropetySection);
            }
        });

        actions.push({
            key: 'viewActions',
            text: 'Azioni',
            iconProps: {
                iconName: 'SetAction'
            }
        });
        
        actions.push({
            key: 'save',
            text: 'Salva',
            iconProps: {
                iconName: 'Save'
            }
        });
        return actions;
    }



    useEffect(() => {
        if (dataStatus == SystemDataLoadingStatus.ToLoad)
        {
            if (pageId == undefined)
                navigate("/app/admin/system/pages");
            else
            {
                adminService.loadCurrentPage(pageId!)
                    .then((pageMetadata) => {
                        setCurrentPage(pageMetadata);
                        adminService.loadEntityProperties(pageMetadata.page)
                            .then((resp) => {
                                setFields(resp);
                                setDataStatus(SystemDataLoadingStatus.Loaded);

                                adminService.loadCurrentPageStructure(pageMetadata.page)
                                    .then((structures) => {
                                        setStructures(structures);
                                        setStructureShimmer(false);
                                    })
                            })
                        
                    });
            }
        }
    }, [dataStatus])
    
    let structureClassName = mergeStyles([{
        width: '70%',
        borderRight: '1px solid rgb(217, 217, 217)'
    }]);
    let propertyClassName = mergeStyles([{
        width: '30%'
    },
    AnimationClassNames.fadeIn200]);

    if (! showPropetySection)
    {
        structureClassName = mergeStyles([{
            width: '100%'
        }]);
        propertyClassName = mergeStyles([{
            width: '30%',
            display: 'none'
        },
        AnimationClassNames.fadeOut500]);
    }

    if (! SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text="Stiamo caricando il tool di progettazione..." />
    else 
    {
        return (
            <Stack key="container" className={pageListContainerClassName}>
                <Stack key="structureContainer" className={pageListMainContainerClassName} tokens={
                    {
                        childrenGap: 15
                    }
                }>
                    <Stack.Item style={{
                        padding: 5
                    }}>
                        <Label style={{
                            fontSize: 20
                        }}>Configurazione pagina {currentPage.userLanguageCaption}</Label>
                    </Stack.Item>
                    <Stack.Item>
                        <CommonActionBar items={buildActions()} />
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item className={structureClassName}>
                                <AdminPageDesignerStructure enableShimmer={structureShimmer} structures={structures} />
                            </Stack.Item>
                            <Stack.Item className={propertyClassName}>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
                <AdminPageDesignerAddPanel page={currentPage.page} ref={panelRef} />
            </Stack>
        );
    }
}