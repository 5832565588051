import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export interface ISystemEventVariableCard {
    eventName?: string;
}

export const AdminSystemEventVariableCard = forwardRef<PageContainerBase, React.PropsWithChildren<ISystemEventVariableCard>>((props, ref) => {
    
    const buildFilterParameter = (): string => {
        let filter = "";
        
        if (props.eventName !== undefined && props.eventName > "")
            filter = "?$filter=eventName eq '" + props.eventName + "'"
            
        return(filter);
    };
    
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda System Event Setup Variable"} endpoint="/odata/system/event/setup/variable" ref={ref}>
            <CardGroup name="general" label="General" isOpen={true}>
                <CardField name="variableName" type={SystemFieldType.String} label="Nome Variabile" />
                <CardField name="parameterName" type={SystemFieldType.String} label="Nome parametro" hasTableRelation tableRelationEndpoint="odata/system/event/parameter" tableRelationFilter={buildFilterParameter()} tableRelationKey="parameterName" tableRelationField="parameterName" />
                <CardField name="entityName" type={SystemFieldType.String} label="Nome Entità" hasTableRelation tableRelationEndpoint="odata/system/entity" tableRelationKey="name" tableRelationField="name" />
                <CardField name="fieldName" type={SystemFieldType.String} label="Nome Campo" hasTableRelation tableRelationEndpoint="odata/system/entity/property" tableRelationFilter="?$filter=entityName eq '#entityName#'" tableRelationKey="name" tableRelationField="name" />
                <CardField name="constantValue" type={SystemFieldType.String} label="Valore Costante" />
                <CardField name="appendBefore" type={SystemFieldType.Boolean} label="Prefisso" />
            </CardGroup> 
        </PageContainer>
    );
});