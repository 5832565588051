import React, { useEffect, useState} from "react";
import { ComboBox, DefaultButton, Dialog, DialogFooter, DialogType, IComboBoxOption, PrimaryButton, TextField } from "@fluentui/react";
import { SystemDataLoadingStatus, SystemFieldType } from "../../../Model/SystemModels";
import { CommonFunctions } from "../../../ApplicationCode/Common/CommonFunctions";

export interface IDialogProps {
    hidden:boolean;
    title:string;
    subText:string;
    txtLabel:string;
    errorMessage?:string;
    fieldType:SystemFieldType;
    cmbOptions?:IComboBoxOption[];
    onSuccess: (groupCode:string)=>void;
    onDismiss: ()=>void;
}

export const AdminCustomerDialog : React.FC<IDialogProps> = (props:IDialogProps) => {
    const [dataStatus,setDataStatus] = useState<SystemDataLoadingStatus>(SystemDataLoadingStatus.ToLoad);
    const [inputField, setInputField] = useState<JSX.Element>(<></>);
    const [value, setValue] = useState<any>("");
    const commonFunctions = new CommonFunctions();

    useEffect(()=>{
        if(dataStatus === SystemDataLoadingStatus.ToLoad){
            switch(props.fieldType){
                case SystemFieldType.Blob:
                    break;
                case SystemFieldType.Boolean:
                    break;
                case SystemFieldType.Date:
                    break;
                case SystemFieldType.Number:
                    break;
                case SystemFieldType.Option:
                    setInputField(
                        <ComboBox options={props.cmbOptions!} label={props.txtLabel} onChange={(ev,opt,index)=>{setValue(opt?.key!)}} required errorMessage={props.errorMessage} />
                    )
                    break;
                case SystemFieldType.String:
                    setInputField(<TextField label={props.txtLabel} onChange={(e,val)=>{setValue(val!)}} required errorMessage={props.errorMessage}/>)
                    break;
                case SystemFieldType.TimeSpan:
                    break;
                default:
                    setInputField(<TextField label={props.txtLabel} onChange={(e,val)=>{setValue(val!)}} required errorMessage={props.errorMessage}/>)
                    break;
            }
            setDataStatus(SystemDataLoadingStatus.Loaded);
        }
    },[dataStatus])

    return (
        <>
            <Dialog
                hidden={!props.hidden}
                onDismiss={props.onDismiss}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title:props.title,
                    subText:props.subText
                }}
            >
                {inputField}
                <DialogFooter>
                <PrimaryButton onClick={()=>props.onSuccess(value)} text="Crea" />
                <DefaultButton onClick={props.onDismiss} text="Cancella" />
                </DialogFooter>
            </Dialog>
        </>
    );
}