import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminSystemEventSubscriberCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda System Event Publisher"} endpoint="odata/system/event/publisher" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="eventName" type={SystemFieldType.String} label="Nome Evento" />
                <CardField name="subscribedByObject" type={SystemFieldType.String} label="Oggetto Sottoscrittore" />
                <CardField name="subscriberName" type={SystemFieldType.String} label="Nome Sottoscrittore" />
                <CardField name="eventType" type={SystemFieldType.Option} label="Tipo Evento" cmbOptions={[
                    {key: 0, text: "Tabella"},
                    {key: 1, text: "Integrazione"}
                ]} />
                <CardField name="active" type={SystemFieldType.String} label="Attivo" />
            </CardGroup> 
        </PageContainer>
    );
});