import { Announced, DefaultButton, Dialog, DialogFooter, DialogType, IModalProps, IStackStyles, Label, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { t } from "i18next";
import React, { useState } from "react";

export interface ILoginForgotPasswordModalProps {
    showModal: boolean;
    saveInProgress: boolean;
    onSave: (email: string) => void;
    onCancel: () => void; 
}

const stackTokens = { childrenGap: 5 };
const stackStyles: Partial<IStackStyles> = { root: { width: 500 } };
const dialogContentProps = {
    type: DialogType.largeHeader,
    title: t('common:LoginBox:LoginForgotPasswordModal:Dialog:title')!
};
const modalProps: Partial<IModalProps> = {
    isBlocking: true
};

export const LoginForgotPasswordModal: React.FC<ILoginForgotPasswordModalProps> = (props) => {
    const [email, setEmail] = useState("");
    
    return (
        <>
            <Dialog
                hidden={! props.showModal}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
                minWidth={500}
                maxWidth={1000}
            >
                <Stack tokens={stackTokens} styles={stackStyles}>
                    <Label>{t('common:LoginBox:LoginForgotPasswordModal:Dialog:label')!}</Label>
                    <TextField label="" value={email} onChange={(ev, newValue?) => {
                        setEmail(newValue!);
                    }}
                    disabled={props.saveInProgress} />
                </Stack>
                <DialogFooter>
                  <PrimaryButton onClick={() => {props.onSave(email)}} text={t('common:LoginBox:LoginForgotPasswordModal:Dialog:primaryButtonText')!} />
                  <DefaultButton onClick={props.onCancel} text={t('common:LoginBox:LoginForgotPasswordModal:Dialog:defaultButtonText')!} />
                </DialogFooter>
              </Dialog>
        </>
    );
}