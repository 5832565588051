import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminSystemJobQueueLogCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"System Job Queue - Scheda Log"} endpoint="odata/systemjobqueue/log" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="entryNo" type={SystemFieldType.Number} label="Id" />
                <CardField name="systemJobQueueCode" type={SystemFieldType.String} label="Codice Job" />
                <CardField name="description" type={SystemFieldType.String} label="Descrizione" />
                <CardField name="status" type={SystemFieldType.Option} label="Stato" cmbOptions={[
                    {key: 0, text: "In Esecuzione"},
                    {key: 1, text: "In Errore"},
                    {key: 2, text: "Completato"}
                ]} />
                <CardField name="startedAt" type={SystemFieldType.String} label="Data Inizio" />
                <CardField name="completedAt" type={SystemFieldType.String} label="Data Completamento" />
                <CardField name="errorMessage" type={SystemFieldType.String} label="Errore" />
            </CardGroup>
        </PageContainer>
    );
});