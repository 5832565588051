import { IColumn } from "@fluentui/react";
import React from "react";

export interface IListHeaderEntryProps {
    name: string;
    fieldName: string;
    minWidth: number;
    maxWidth: number;
    isPrimaryKey?: boolean;
    onRender?: (item?: any, index?: number | undefined, column?: IColumn | undefined) => any;
} 

export const ListHeaderEntry: React.FC<IListHeaderEntryProps> = (props) => {
    return (
        <></>
    );
};