import { DefaultButton, Label, PrimaryButton, Stack } from "@fluentui/react";
import React from "react";
import "./Stepper.css";

export const Stepper : React.FC = ({ children }) => {
    return (
        <Stack key="stepper" className="container-fluid stepper">
            {children}
        </Stack>
    );
}

export const StepperEntries : React.FC = ({ children }) => {
    return (
        <ul className="progress-indicator">
            {children}
        </ul>
    );
}

export interface IStepperEntryProps {
    id: number;
    completed: boolean;
    description: string;
}

export const StepperEntry : React.FC<IStepperEntryProps> = (props) => {

    let stepperEntryClassName: string = "";
    if (props.completed)
        stepperEntryClassName = "completed";

    let elementId: string = "step-" + props.id;
    return (
        <li className={stepperEntryClassName}>
            <span className="bubble"></span>
            <Label>{props.description}</Label>
        </li>
    );
}

export const StepperContent : React.FC = ({ children }) => {
    return (
        <Stack key="stepperContent" className="container-fluid stepper-content">
            {children}
        </Stack>
    );
}

export interface IStepperEntryContentProps {
    id: number;
    onSuccessClick: (stepId: number) => void;
    successButtonText: string;
    onCancelClick?: (stepId: number) => void;
    cancelButtonText?: string;
    visibile?: boolean;
}

export const StepperEntryContent : React.FC<IStepperEntryContentProps> = (props) => {
    let stepContentId = "step-content-" + props.id;
    
    let onCancelButton: JSX.Element = <></>;
    if (props.cancelButtonText)
        onCancelButton = <DefaultButton text={props.cancelButtonText} onClick={() => props.onCancelClick!(props.id)} style={{marginRight: 10}} />

    if (props.visibile)
        return (
            <Stack className="step-container" id={stepContentId} key={stepContentId}>
                <Stack.Item key={"step-content-body-" + props.id}>
                    {props.children}
                </Stack.Item>
                <Stack.Item key={"step-content-footer-" + props.id} className="btn-container">
                    {onCancelButton}
                    <PrimaryButton text={props.successButtonText}
                                    onClick={() => props.onSuccessClick(props.id)}/>
                </Stack.Item>
            </Stack>
        );
    else
        return <></>;
}