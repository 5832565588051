import { TeachingBubble,Selection, IStyle, IStyleFunctionOrObject, Link, PrimaryButton, ActionButton, TeachingBubbleContentBase } from "@fluentui/react";
import { BackgroundColorIcon } from "@fluentui/react-icons-mdl2";
import { t } from "i18next";
import { ITourEntry, SystemDataLoadingStatus } from "../../../Model/SystemModels";
import { TeachingGuide } from "../../Demo/TeachingGuide/TeachingGuide"
import { CSSProperties, Ref, useEffect, useState } from "react";
import { SystemCore } from "../../../Core/System/SystemCore";

export interface IGuideTourProps{
    id?: string;
    customStyle?: CSSProperties;
    isFooterAction?: boolean;
    actionKeyword?: string;
    primaryButtonLabel:string;
    secondaryButtonLabel:string;
    currentEntry: ITourEntry;
    currentIndex: number;
    totalSteps: number;
    onNext: () => void;
    onCancel: () => void;
    onDismiss: () => void;
    onFooterAction?: (keyWord:string) => void;
}

export const CommonGuideTour : React.FC<IGuideTourProps> = (props: IGuideTourProps) => {
    const [dataStatus,setDataStatus] = useState<SystemDataLoadingStatus>(SystemDataLoadingStatus.ToLoad);
    
    let footerContent:string | JSX.Element | undefined = props.currentIndex + t('customerCenter:CommonGuideTourDialog:tourStepText') + props.totalSteps;
    if(props.isFooterAction){
        let stringValue = t('customerCenter:CommonGuideTourDialog:tourFooterAction');

        footerContent =
            <ActionButton
                onClick={()=>props.onFooterAction!(props.actionKeyword!)}
                allowDisabledFocus
                styles={{
                    rootHovered:{
                        color:'white',
                        textDecoration:'underline',
                    },
                    rootPressed:{
                        color:'#F0F0F0',
                        textDecoration:'underline',
                    },
                    root:{
                        width:80,
                        color:'white',
                        textDecoration:'none',
                        backgroundColor:'transparent',
                        border:'none'
                    },
                    textContainer:{
                        width:80
                    }
                }}
                color="white"
            >
                {stringValue}
            </ActionButton>
    }

    return(
        <TeachingBubble

            target={`#${props.currentEntry.targetId}`}
            primaryButtonProps={{
                text: props.primaryButtonLabel,
                primary: false,
                onClick: props.onNext
            }}
            secondaryButtonProps={{
                text: props.secondaryButtonLabel,
                primary: false,
                onClick: props.onCancel
            }}
            headline={props.currentEntry.title}
            footerContent={footerContent}
            focusTrapZoneProps={{
                forceFocusInsideTrap:false
            }}
            calloutProps={{
                layerProps:{
                    styles:{
                        root:{
                            zIndex: props.customStyle?.zIndex ? props.customStyle.zIndex : '12000 !important'
                        }
                    }
                },
                directionalHint: props.currentEntry.direction,
                directionalHintFixed: true,
                styles:{
                    container:{
                        zIndex: '1 !important',
                    },
                    beakCurtain:{
                        color:'red',
                        backgroundColor:'red'
                    },
                    calloutMain:{
                        color:'red',
                        backgroundColor:'red'
                    },
                    beak:{
                        color:'red',
                        backgroundColor:'red'
                    }
                },
                preventDismissOnEvent:()=>true
            }}
            onDismiss={props.onDismiss}

            styles={{
                bodyContent:{
                    backgroundColor: props.customStyle?.backgroundColor ? props.customStyle.backgroundColor : 'rgb(0, 128, 137) !important',
                    border: "1px solid #ffff",
                },
                primaryButton:{
                    backgroundColor:'rgb(217, 240, 242) !important',
                    color:'black !important',
                    selectors:{
                        ':hover':{
                            backgroundColor:'white !important',
                        },
                        ':active':{
                            border:'1px solid white !important',
                        },
                        ':before':{
                            border:'1px solid white !important',
                        },
                        ':after':{
                            border:'1px solid white !important',
                        },
                        ':focus':{
                            border:'1px solid white !important',
                        }
                    }
                },
                secondaryButton:{
                    backgroundColor:'rgb(0, 128, 137) !important',
                    selectors:{
                        ':hover':{
                            color:'black !important',
                            backgroundColor:'rgb(0, 128, 167) !important',
                        },
                        ':active':{
                            border:'1px solid white !important',
                        },
                        ':before':{
                            border:'1px solid white !important',
                        },
                        ':after':{
                            border:'1px solid white !important',
                        },
                        ':focus':{
                            border:'1px solid white !important',
                        }
                    }
                },
                root:{
                    position: "absolute"
                }
            }}
        >
            <p id={props.id}>
                {props.currentEntry.description}
            </p>
        </TeachingBubble>
    )
}