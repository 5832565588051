import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminNotificationGroupMemberCard } from "../AdminNotificationGroupMemberCard/AdminNotificationGroupMemberCard";

export const AdminNotificationLogList : React.FC = () => {
    
    return (
        <>
            <PageContainer endpoint={"/odata/notification/log/entry?$orderby=systemCreationDate desc"} pageType={SystemPageType.List} title={"Log notifiche"} backAllowed>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Codice gruppo" fieldName="groupCode" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Codice template" fieldName="templateCode" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Id User" fieldName="userId" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Data creazione" fieldName="systemCreationDate" minWidth={100} maxWidth={200} />
                </PageDefinitionContainer>
            </PageContainer>
        </>
    );
}