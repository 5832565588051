import React, { useContext, useEffect, useRef, useState } from "react";
import { Loader } from "../../../Common/Loader/Loader";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { SystemActionCategory, SystemDataLoadingStatus,OperationType, SystemEntity, SystemPage, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { useNavigate } from "react-router-dom";

export const PartnerSalesInvoiceHeaderList : React.FC = () => {
    const navigate = useNavigate();
    const [dataStatus,setDataStatus]= useState(SystemDataLoadingStatus.ToLoad);
    const [endpoint,setEndpoint] = useState("");

    useEffect(()=>{
        setDataStatus(SystemDataLoadingStatus.Loading);
        if(window.location.href.includes('?'))
            setEndpoint("/odata/sales/invoice?"+window.location.href.split('?')[1]);
        else
            setEndpoint("/odata/sales/invoice");
            setDataStatus(SystemDataLoadingStatus.Loaded);

    },[dataStatus])


    if (dataStatus!=SystemDataLoadingStatus.Loaded)
        return <Loader text="Stiamo caricando le fatture richieste..."></Loader>;
    else
        return (
            <>
                <PageContainer endpoint={endpoint} pageType={SystemPageType.List} title="Lista Fatture">                            
                <PageDefinitionContainer type={ContainerType.Action}>
                        <ActionArea category={SystemActionCategory.Navigation}>
                            <ActionEntry name="OpenInvoiceLine" label="Apri dettaglio fattura" iconName="BulletedList2" runOnRec isPromoted onClick={(req:any)=>{
                                    navigate('/app/partner/administrative/invoiceLineList?headerId='+req.id);
                                }}>
                            </ActionEntry>
                        </ActionArea>
                    </PageDefinitionContainer>
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry name="System Id" fieldName="systemId" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name="System Creation User Id" fieldName="systemCreationUserId" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name="System Creation Date" fieldName="systemCreationDate" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Data aggiornamento sistema" fieldName="systemUpdateDate" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Id utente aggiornamento sistema " fieldName="systemUpdateUserId" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Timestamp" fieldName="timestamp" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Id" fieldName="id" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Numero Documento" fieldName="documentNo" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Id Partner" fieldName="partnerId" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Id Customer" fieldName="customerId" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Posting Date" fieldName="postingDate" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Numero ERP Cliente" fieldName="erpCustomerNo" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="ERP Sales Pearson Code" fieldName="erpSalesPearsonCode" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Inizio Periodo" fieldName="periodStart" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Fine Periodo" fieldName="PeriodEnd" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="ERP Sync Status" fieldName="erpSyncStatus" minWidth={150} maxWidth={250} />
                    </PageDefinitionContainer>
                </PageContainer>
            </>
        );
}