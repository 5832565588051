import { IColumn } from "@fluentui/react";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { SystemActionCategory, SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminCustomerDialog } from "../../AdminCustomerDialog/AdminCustomerGroupDialog";
import { AdminCustomerProgressChecklistTemplateCard } from "../AdminCustomerProgressChecklistTemplateCard/AdminCustomerProgressChecklistTemplateCard";

export const AdminCustomerProgressChecklistTemplateList : React.FC = () => {
    const [showCodeDialog, setShowCodeDialog] = useState(false);
    const [dialogError, setDialogError] = useState("");
    const [template,setTemplate]=useState({});
    const [functionType,setFunctionType]=useState(false);
    const [groupCode, setGroupCode] = useState("");
    const cardRef = useRef<PageContainerBase>(null);
    const navigate = useNavigate();

    const adminService=new AdminService();
 
    const createCustomerTaskGroup =(customerId:string)=>{
        if(functionType){
            if(customerId !== null){
                adminService.createCustomerTaskGroup(template,customerId)
                .then((srvData)=>{
                    setShowCodeDialog(false);
                    ToastService.showMessage(MessageType.Success,"Task creato con successo");
                })
                .catch((jqXHR)=>{
                    setShowCodeDialog(false);
                    ToastService.showMessage(MessageType.Error,"Errore nella creazione del gruppo task: "+jqXHR.message);
                })
                setDialogError("");
            }
            else
                setDialogError("Devi inserire il Customer Id");
        }
        else{
            if(customerId !== null){
                adminService.createTaskGroup(groupCode,customerId)
                    .then((srvData)=>{
                        setShowCodeDialog(false);
                        ToastService.showMessage(MessageType.Success,"Gruppo Task creato con successo");
                    })
                    .catch((jqXHR)=>{
                        setShowCodeDialog(false);
                        ToastService.showMessage(MessageType.Error,"Errore nella creazione del gruppo task: "+jqXHR.message);
                    })
                    setDialogError("");
                }
                else{
                    setDialogError("Devi inserire il Customer Id");
                }
            }
        }

    return (
        <>
            <PageContainer endpoint="/odata/customer/progress/checklist/template" pageType={SystemPageType.List} title="Lista Customer Progress Checklist Template" backAllowed insertAllowed deleteAllowed updateAllowed cardRef={cardRef}>               
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="SystemCaption" label="Traduzioni" iconName="Globe2" runOnRec onClick={(rec:any)=>{
                            navigate('/app/admin/related/systemCaption?sourceId='+rec.systemId+"&sourceEntity=CustomerProgressChecklistTemplate")
                        }}>
                        </ActionEntry>
                    </ActionArea>
                    <ActionArea category={SystemActionCategory.Process}>
                        <ActionEntry name="CreateTaskGroup" label="Crea task per cliente" iconName="TaskGroup" isPromoted runOnRec onClick={(req:any)=>{
                            setShowCodeDialog(true);
                            setTemplate(req);
                            setFunctionType(true);
                        }}>
                        </ActionEntry>
                        <ActionEntry name="CreateTaskGroup" label="Crea gruppo task cliente" iconName="TaskGroup" isPromoted runOnRec onClick={(req:any)=>{
                            setShowCodeDialog(true);
                            setGroupCode(req.groupCode);
                            setFunctionType(false);
                        }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>                               
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Codice" fieldName="code" minWidth={150} maxWidth={250}  />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={150} maxWidth={250}  />
                    <ListHeaderEntry name="Classificazione 1" fieldName="classification1" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Classificazione 2" fieldName="classification2" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Peso" fieldName="weight" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Tipologia Utente" fieldName="forUserType" minWidth={150} maxWidth={250} onRender={(item: any, index, column) => {
                        if (item.forUserType === 0)
                            return "Utente Admin"
                        else if (item.forUserType === 1)
                            return "Utente Normale"
                        else if (item.forUserType === 2)
                            return "Entrambi"
                    }} />
                    <ListHeaderEntry name="Tipologia Azione" fieldName="actionType" minWidth={150} maxWidth={250} onRender={(item: any, index, column) => {
                        if (item.actionType === 0)
                            return "Apertura Link"
                        else if (item.actionType === 1)
                            return "Apertura Pagina"
                    }} />
                    <ListHeaderEntry name="Priorità" fieldName="priority" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Codice Gruppo" fieldName="groupCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Link Esterno" fieldName="externalLink" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Endpoint Pagina" fieldName="pageEndpoint" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Descrizione Dettagliata" fieldName="detailedDescription" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Testo Bottone Principale" fieldName="primaryButtonText" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Mostra come Task" fieldName="showAsTask" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Mostra Bottone Task successivo" fieldName="showNextButton" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Testo Bottone Task successivo" fieldName="nextButtonText" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminCustomerProgressChecklistTemplateCard ref={cardRef} />
            <AdminCustomerDialog hidden={showCodeDialog}
                                 title="Inserisci il Customer Id"
                                 subText="Devi inserire il Customer Id per poter procedere"
                                 txtLabel="Customer Id"
                                 fieldType={SystemFieldType.String} 
                                 onSuccess={(CustomerId:string)=>{createCustomerTaskGroup(CustomerId)}} 
                                 onDismiss={()=>{setShowCodeDialog(false)}}
                                 errorMessage={dialogError} />
        </>
    );
}