import { IComboBoxOption } from "@fluentui/react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../../../ApplicationCode/Admin/AdminService";
import { SystemCore } from "../../../../../Core/System/SystemCore";
import { MessageType, ToastService } from "../../../../../Core/Toast/ToastService";
import { SystemActionCategory, SystemDataLoadingStatus, SystemFieldType, SystemPageType } from "../../../../../Model/SystemModels";
import { Loader } from "../../../../Common/Loader/Loader";
import { ActionArea } from "../../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../../Common/Template/PageDefinitionContainer";
import { AdminCustomerListDialog } from "../../../AdminCustomerListDialog/AdminCustomerListDialog";
import { AdminAzureGDAPCustomerRelationshipRoleCard } from "../AdminAzureGDAPCustomerRelationshipRoleCard/AdminAzureGDAPCustomerRelationshipRoleCard";

export const AdminAzureGDAPCustomerRelationshipRoleList : React.FC = () => {
    
    const cardRef = useRef<PageContainerBase>(null);
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [loaderMessage, setLoaderMessage] = useState("");
    const [customerCmbOptions, setCustomerCmbOptions] = useState<IComboBoxOption[]>([]);
    const [showCustomerDialog, setShowCustomerDialog] = useState(false);
    const adminService = new AdminService();
    const [dialogError, setDialogError] = useState("");
    const navigate = useNavigate();
    
    useEffect(() => {
        if(dataStatus === SystemDataLoadingStatus.ToLoad){
            buildCustomerCmbOptions();
        }
    }, [dataStatus])

    const buildCustomerCmbOptions = ()=>{
        setLoaderMessage("Stiamo caricando la lista dei clienti, attendere...");
        setDataStatus(SystemDataLoadingStatus.Loading);

        adminService.deleteSubscriptionMatch().then((resp) => {
            adminService.getCustomers().then((resp)=>{
                let options : IComboBoxOption[] = [];
                resp.forEach((item:any)=>{
                    options.push({key: item.id, text: item.companyName});
                })
                setCustomerCmbOptions(options);
                setLoaderMessage("");
                setDataStatus(SystemDataLoadingStatus.Loaded);
            })
            .catch(()=>{
                setLoaderMessage("");
                setDataStatus(SystemDataLoadingStatus.Loaded);
                ToastService.showMessage(MessageType.Error,"Errore nel recupero dei clienti");
            })
        })
        .catch((err) => {
            setLoaderMessage("");
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Error,"Errore nell'inizializzazione della tabella: "+err.message);
        })
    }
    
    const createCustomerRelationshipRole = (customerId: number, customerName: string) => {
        if (customerId !== undefined && customerId > 0){
            setLoaderMessage("Creazione ruoli relazione Cliente "+customerName+" in corso ...");
            setDataStatus(SystemDataLoadingStatus.Loading);

            adminService.createCustomerRelationshipRole(customerId)
                .then(() => {
                    setDataStatus(SystemDataLoadingStatus.Loaded);
                    setLoaderMessage("");
                    setShowCustomerDialog(false);

                    ToastService.showMessage(MessageType.Success, "Creazione ruoli relazioni Cliente "+customerName+" completato con successo");
                })
                .catch((err) => {
                    setDataStatus(SystemDataLoadingStatus.Loaded);
                    setLoaderMessage("");
                    setShowCustomerDialog(false);
                    ToastService.showMessage(MessageType.Error, "Errore durante la creazione ruoli relazioni Cliente "+customerName+": " + err);
                })
        }
        else
            setDialogError("Devi inserire un Cliente valido");
    }
    
    if (!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={loaderMessage}></Loader>;
    else    
        return (
            <>
                <PageContainer endpoint={'/odata/azure/gdap/customer/relationship/role'} pageType={SystemPageType.List} title={"Lista ruoli relazioni cliente azure GDAP"} cardRef={cardRef} backAllowed showAllowed updateAllowed>
                    <PageDefinitionContainer type={ContainerType.Action}>
                        <ActionArea category={SystemActionCategory.Navigation}>
                            <ActionEntry iconName="BulletedList" name="OpenAzureGDAPCustomerRelationshipRoleDetail" label="Dettaglio" runOnRec isPromoted onClick={(req:any)=>{
                                navigate("/app/admin/administrative/azureGDAPCustomerRelationshipRoleDetail?entryNo="+req.entryNo);
                            }}>
                            </ActionEntry>
                        </ActionArea>
                        <ActionArea category={SystemActionCategory.Process}>
                            <ActionEntry name="createCustomerRelationshipRole" label="Crea ruoli relazioni Cliente" iconName="AddLink" isPromoted onClick={() => {                                
                                setShowCustomerDialog(true);
                            }}></ActionEntry>
                        </ActionArea>
                    </PageDefinitionContainer>
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry fieldName="entryNo" name="Entry No" minWidth={100} maxWidth={900} />
                        <ListHeaderEntry fieldName="customerId" name="Id cliente" minWidth={150} maxWidth={900} />
                        <ListHeaderEntry fieldName="customerName" name="Cliente" minWidth={400} maxWidth={900} />
                        <ListHeaderEntry fieldName="active" name="Attivo" minWidth={100} maxWidth={900} />
                        <ListHeaderEntry fieldName="relationshipId" name="Id relazione" minWidth={450} maxWidth={900} />
                    </PageDefinitionContainer>
                </PageContainer>
                <AdminAzureGDAPCustomerRelationshipRoleCard ref={cardRef} />
                <AdminCustomerListDialog hidden={showCustomerDialog}
                    title="Scegli il Cliente"
                    subText="Devi inserire il Cliente per poter proseguire"
                    txtLabel="Cliente:"
                    cmbOptions={customerCmbOptions} 
                    fieldType={SystemFieldType.Option}
                    onSuccess={(customerId: number, customerName: string)=>{createCustomerRelationshipRole(customerId, customerName)}}
                    onDismiss={()=>{setShowCustomerDialog(false)}}
                    errorMessage={dialogError} />
            </>
        );
}