import { useRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import { IColumn, IComboBoxOption } from "@fluentui/react";
import { AdminCustomerQueuedStatusCard } from "../AdminCustomerQueuedStatusCard/AdminCustomerQueuedStatusCard";

export interface CustomerQueuedStatusStructure {
    displayName: string;
    fieldName: string;
    fieldType: SystemFieldType;
    readonly: boolean;
    cmbOptions?: IComboBoxOption[];
    render?: (item?: any, index?: number | undefined, column?: IColumn | undefined) => any;
    minWidth: number;
    maxWidth: number;
}

const structure: CustomerQueuedStatusStructure[] = [
    {
        displayName: "Nome società",
        fieldName: "companyName",
        fieldType: SystemFieldType.String,
        readonly: true,
        minWidth: 150,
        maxWidth: 250
    },
    {
        displayName: "Stato",
        fieldName: "status",
        fieldType: SystemFieldType.Option,
        readonly: false,
        cmbOptions: [
            {key: 0, text: "Waiting"},
            {key: 1, text: "In progress"}
        ],
        render: (item, index, column) => {
            switch(item.status){
                case 0:
                    return <span style={{color: "gray"}}>Waiting</span>;
                case 1:
                    return <span style={{color: "#008089"}}>In progress</span>;
            }
        },
        minWidth: 80,
        maxWidth: 100
    },
    {
        displayName: "ID Cliente",
        fieldName: "customerId",
        fieldType: SystemFieldType.String,
        readonly: true,
        minWidth: 250,
        maxWidth: 300
    }
]

export const AdminCustomerQueuedStatusList: React.FC = (props) => {
    const cardRef = useRef<PageContainerBase>(null); 

    function getStructure(): JSX.Element[] {
        let currentStructure: JSX.Element[] = [];

        structure.forEach((element: CustomerQueuedStatusStructure) => {
            currentStructure.push(
                <ListHeaderEntry 
                    key={element.fieldName} 
                    name={element.displayName} 
                    fieldName={element.fieldName} 
                    minWidth={element.minWidth} 
                    maxWidth={element.maxWidth}
                    onRender={element.render}
                />
            );
        });

        return currentStructure;
    }

    return (
        <>
            <PageContainer
                cardRef={cardRef}
                endpoint={"/odata/customer/queued/status"}
                pageType={SystemPageType.List}
                title={"Stato coda clienti"}
                backAllowed
                showAllowed
                updateAllowed
            >
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    {getStructure()}
                </PageDefinitionContainer>
            </PageContainer>
            <AdminCustomerQueuedStatusCard
                ref={cardRef}
                structure={structure}
            />
        </>
    );
};