import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminQualifiedAnswerCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda risposta qualifica"} endpoint="odata/qualified/answer" ref={ref}>
            <CardGroup name="general" label="General" isOpen={true}>
                <CardField name="code" type={SystemFieldType.String} label="Codice" />
                <CardField name="type" type={SystemFieldType.String} label="Tipologia" />
                <CardField name="description" type={SystemFieldType.String} label="Descrizione" />
                <CardField name="defaultValue" type={SystemFieldType.String} label="Valore default" />
                <CardField label="Codice wizard" name="wizardCode" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="odata/ui/wizard/header" tableRelationKey="code" tableRelationField="description" />
            </CardGroup>
        </PageContainer>
    );
});