import { Checkbox, ComboBox, DefaultButton, Dialog, DialogFooter, IComboBoxOption, IModalProps, IStackTokens, mergeStyles, MessageBar, PrimaryButton, Stack, TextField } from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from "react-accessible-accordion";
import { OperationType, SystemFieldType, SystemPageType, SystemUser } from "../../../Model/SystemModels";
import '../../../../node_modules/react-accessible-accordion/dist/fancy-example.css';
import { Product, Partner, SalesHeaderTemplate } from "../../../Model/PartnerModels";
import { PartnerInformationContext } from "../../../ApplicationCode/Partner/PartnerInformationContext";
import PageContainer from "../../Common/Template/PageContainer";
import { CardGroup } from "../../Common/Template/Card/CardGroup";
import { CardField } from "../../Common/Template/Card/CardField";
import { NONAME } from "dns";

const modalProps: Partial<IModalProps> = {
    isBlocking: true,
    dragOptions: undefined,
    styles: {
        main: {
            width: '95% !important',
            maxWidth: 'calc(95%) !important'
        }
    }
  };

const contentBodyClassName = mergeStyles([{
    width: '100%',
    overflowY: "auto",
    overflowX: "hidden"
}]);

const modalContentClassName = mergeStyles([{
    width: '100%'
}]);

const formContentClassName = mergeStyles([{
    width: '100%'
}]);

const formContentEntryClassName = mergeStyles([{
    width: '100%'
}]);

const rowContentClassName = mergeStyles([{
    width: '100%',
    marginBottom: 7
}]);

const rowContentEntryClassName = mergeStyles([{
    width: '48%'
}]);

const textFieldClassName =  mergeStyles([{
    width: '100%',
    maxWidth: 600
}]);

const rowContentStackToken: IStackTokens = {
    childrenGap: 50
};

const formContentStackToken: IStackTokens = {
    childrenGap: 20
};

export interface IPartnerProductCardProps {
    showDialog: boolean;
    product: Product;
    onSuccessModal: (Product: Product) => void;
    onCancelModal: () => void;
}

export const PartnerProductCard : React.FC<IPartnerProductCardProps> = (props) => {

    return (
        <PageContainer showModal={props.showDialog} pageType={SystemPageType.Card} title="Scheda Products" endpoint="">
            <CardGroup name="general" label="Generale" isOpen>
                <CardField name="description" type={SystemFieldType.String} label="Descrizione" />
                <CardField name="isBundle" type={SystemFieldType.Boolean} label="Prodotto bundle" />
                <CardField name="salePrice" type={SystemFieldType.Number} label="Prezzo vendita" />
                <CardField name="defaultInvoicingPeriod" type={SystemFieldType.String} label="Periodo fatturazione di default" />
                <CardField name="saleEnabled" type={SystemFieldType.Boolean} label="Attivo per la vendita" />
            </CardGroup>
            <CardGroup name="bundle" label="Bundle" isOpen hidden={props.product.isBundle}>
                <CardField name="bundleDescription" type={SystemFieldType.String} label="Descrizione bundle" />
                <CardField name="quantity" type={SystemFieldType.Number} label="Quantity" />
            </CardGroup>  
        </PageContainer>
    );
}