import { forwardRef, useState } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminWizardLineCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda step wizard"} endpoint="/odata/ui/wizard/line" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="title" type={SystemFieldType.String} label="Titolo" />
                <CardField name="subtitle" type={SystemFieldType.String} label="Sotto titolo" />
                <CardField name="sourceEntityName" type={SystemFieldType.String} label="Nome entità sorgente" />
                <CardField name="sourceId" type={SystemFieldType.String} label="ID sorgente" />
                <CardField name="type" type={SystemFieldType.Option} label="Tipo" cmbOptions={[{
                    key: 0,
                    text: "Scelta singola"
                },
                {
                    key: 1,
                    text: "Scelta multipla"
                },
                {
                    key: 2,
                    text: "Form"
                },
                {
                    key: 3,
                    text: "Valutazione"
                }]} />
                <CardField name="addOtherOption" type={SystemFieldType.Boolean} label="Aggiungi opzione Altro" onGetIsVisible={(record) => {
                    return record.type < 2;
                }} />
                <CardField name="canSkip" type={SystemFieldType.Boolean} label="Ignorabile" />
                <CardField name="duration" type={SystemFieldType.Number} label="Durata" />
                <CardField name="order" type={SystemFieldType.Number} label="Ordinamento" />
                <CardField name="isGroup" type={SystemFieldType.Boolean} label="Gruppo" />
                <CardField name="inputType" type={SystemFieldType.Option} label="Tipo input" cmbOptions={[
                    {
                        key: 0,
                        text: "Testo"
                    },
                    {
                        key: 1,
                        text: "Numero"
                    },
                    {
                        key: 2,
                        text: "Data"
                    },
                    {
                        key: 3,
                        text: "Booleano"
                    }
                ]}
                />
            </CardGroup>
            <CardGroup name="rating" label="Valutazione" isOpen={false}>
                <CardField name="selectedIcon" type={SystemFieldType.String} label="Icona selezionato" />
                <CardField name="unSelectedIcon" type={SystemFieldType.String} label="Icona non selezionato" />
                <CardField name="maxRate" type={SystemFieldType.Number} label="Voto massimo" />
            </CardGroup>
        </PageContainer>
    );
});