import React, {useEffect,useState } from "react";
import {SystemActionCategory, SystemDataLoadingStatus,SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { Loader } from "../../../Common/Loader/Loader";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { useNavigate } from "react-router-dom";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";

export const AdminCSPSubscriptionList : React.FC = () => {
    const navigate = useNavigate();
    const [dataStatus,setDataStatus]= useState(SystemDataLoadingStatus.ToLoad);
    const [endpoint, setEndpoint] = useState('');
    const parameter = HttpHelper.getParameter('customerId');

    useEffect(()=>{
        if(parameter!=null)
            setEndpoint("/odata/csp/customer/subscription?$filter=soSmartCustomerId eq "+parameter);
        else{
            navigate('/app');
            ToastService.showMessage(MessageType.Error,'Non è possibile aprire questa lista senza aver selezionato un cliente dalla pagina collegata')
        }
        setDataStatus(SystemDataLoadingStatus.Loaded);
    },[dataStatus]);

    if (dataStatus!==SystemDataLoadingStatus.Loaded)
        return <Loader text="Stiamo caricando i dati richiesti..."></Loader>;
    else
    return (
        <>
            <PageContainer endpoint={endpoint} pageType={SystemPageType.List} title="Lista sottoscrizioni clienti CSP" backAllowed>               
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Action}>
                    </ActionArea>
                </PageDefinitionContainer>                                 
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Id Tenant" fieldName="customerId" minWidth={220} maxWidth={220} />
                    <ListHeaderEntry name="Id Sottoscrizione" fieldName="subscriptionId" minWidth={220} maxWidth={220} />
                    <ListHeaderEntry name="Descrizione" fieldName="friendlyName" minWidth={400} maxWidth={400} />
                    <ListHeaderEntry name="Quantità" fieldName="quantity" minWidth={80} maxWidth={80} />
                    <ListHeaderEntry name="Tipo fatturazione" fieldName="billingType" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry name="Ciclo fatturazione" fieldName="billingCycle" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry name="Term duration" fieldName="termDuration" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry name="Rinnovo automatico" fieldName="autoRenewEnabled" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry name="Data inizio" fieldName="effectiveStartDate" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry name="Data fine" fieldName="commitmentEndDate" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry name="Cancellazione consentita fino alla data" fieldName="cancellationAllowedUntilDate" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry name="Trial" fieldName="isTrial" minWidth={50} maxWidth={50} />
                    <ListHeaderEntry name="Id offerta" fieldName="offerId" minWidth={220} maxWidth={220} />
                    <ListHeaderEntry name="Nome offerta" fieldName="offerName" minWidth={400} maxWidth={400} />
                    <ListHeaderEntry name="Id ordine" fieldName="orderId" minWidth={100} maxWidth={100} />
                    <ListHeaderEntry name="Stato" fieldName="status" minWidth={80} maxWidth={80} />
                    <ListHeaderEntry name="Id articolo" fieldName="itemId" minWidth={80} maxWidth={80} />
                    <ListHeaderEntry name="Descrizione articolo" fieldName="itemDescription" minWidth={400} maxWidth={400} />
                </PageDefinitionContainer>
            </PageContainer>
        </>
    );
}