import { AnimationClassNames, CompoundButton, Icon, mergeStyles, Stack } from '@fluentui/react';
import React, { useRef } from 'react';
import './ScrollPointer.css';

export interface ScrollPointerProps {
    reference: string;
    scrollId: string;
}

const ScrollPointer = (props: ScrollPointerProps) => {
    return (
        <Stack className="scroll-main" onClick={() => { let el = document.getElementById(props.reference); el?.scrollIntoView({behavior: 'smooth', block: 'start'}); }}>
            <Stack.Item className="scroll-container" id={props.scrollId}>
                <Icon iconName="ChevronDownSmall" style={{paddingTop: '5px'}} />
            </Stack.Item>
        </Stack>
    );
}

export default ScrollPointer;