import React, { useRef }  from "react";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { PartnerSalesBudgetCard } from "../PartnerSalesBudgetCard/PartnerSalesBudgetCard";

export const PartnerSalesBudgetList : React.FC = () => {
    const cardRef = useRef<PageContainerBase>(null);

    return (
        <>
            <PageContainer endpoint="/odata/sales/budget" pageType={SystemPageType.List} title="Lista budget" cardRef={cardRef} backAllowed insertAllowed updateAllowed deleteAllowed>               
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Action}>
                    </ActionArea>
                </PageDefinitionContainer>                                 
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Entry No" fieldName="entryNo" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Data" fieldName="date" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Origine" fieldName="origin" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Tipo Programma" fieldName="programType" minWidth={150} maxWidth={250} onRender={(item: any, index, column) => {
                        if (item.programType === 0)
                            return "Programma 1"
                        else if (item.programType === 1)
                            return "Programma 2"
                        else if (item.programType === 2)
                            return "Programma 3"
                    }} />
                    <ListHeaderEntry name="Id Partner" fieldName="partnerId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Numero Item" fieldName="itemNo" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Quantità" fieldName="quantity" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Prezzo unitario" fieldName="unitPrice" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Line Amount" fieldName="lineAmount" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <PartnerSalesBudgetCard ref={cardRef} />
        </>
    );
}