
import { mergeStyles,Stack,Text, Modal, ContextualMenu, IDragOptions, FontWeights, mergeStyleSets, IconButton, IButtonStyles, ResponsiveMode, Icon } from "@fluentui/react";
import React from "react";
import "plyr-react/plyr.css"
import { CommonFunctions } from "../../../ApplicationCode/Common/CommonFunctions";
import ReactPlayer from "react-player";
import { IContentManagement } from "../../../ApplicationCode/Common/ContentManagement";
import { DemoUIHelper } from "../../Demo/DemoUIHelper";
import { t } from "i18next";

export interface ICommonVideoModalProps {
    resource : IContentManagement;
    showModal : boolean;
    disableLight?: boolean
    onDismiss : (closeWithoutPlay:boolean) => void;
}

const dragOptions:IDragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
    keepInBounds:true,
    dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
}

const ContentStyles = mergeStyleSets({
    Container: {
        width:'calc(100vw - 288px)',
        zIndex:"20000 !important"
    },
    Header:{
        borderTop: `4px solid #008089`,
        color: "black",
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
    },
    Body: {
        padding: '0 10px 10px 10px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        }
    }
});

const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: "black",
        marginLeft: 'auto',
        marginRight: '2px',
    },
    rootHovered: {
        color: "gray",
    }
};

const iconClass = mergeStyles({
    fontSize: 150,
    marginBottom:40
});

export const CommonVideoModal : React.FC<ICommonVideoModalProps> = (props:ICommonVideoModalProps)=>{
    let displayDuration:number = 0;

    const buildModalContent = () : JSX.Element => {
        let element:JSX.Element = <></>;
        
        if(props.resource.contentVideo !== "")
            element = 
                <ReactPlayer
                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                    style={{marginTop:10}} 
                    width={"100%"}
                    height={"calc(100vh - 180px)"}
                    controls={true}
                    pip={true} 
                    playing={true}
                    light={props.disableLight ? false : DemoUIHelper.getVideoSource(props.resource.contentVideo!,props.resource.description!).poster} 
                    url={props.resource.contentVideo}
                    onProgress={(state) => {displayDuration = Math.round(state.playedSeconds)}}
                    onPlay={()=>{CommonFunctions.logEvent("Inizio video: "+props.resource.description,props.resource.contentVideo!)}}
                    onPause={()=>{CommonFunctions.logEvent("Fine video: "+props.resource.description+" - Secondi visti: "+displayDuration,props.resource.contentVideo!)}} 
                />
        else
            element = 
                <Stack horizontal horizontalAlign="center" styles={{root:{height:'calc(100vh - 180px)'}}}>
                    <Stack verticalFill verticalAlign="center">
                        <Stack horizontal horizontalAlign="center">
                            <Icon iconName="VideoOff" className={iconClass}></Icon>
                        </Stack>
                        <Stack horizontal horizontalAlign="center" styles={{root:{marginBottom:10}}}>
                            <Text variant="xLarge">{t('customerCenter:CustomerVideoModal:Err00001Text')!}</Text>
                        </Stack>
                        <Stack horizontal horizontalAlign="center">
                            <Text variant="medium">{t('customerCenter:CustomerVideoModal:Err00002Text')!}</Text>
                        </Stack>
                    </Stack>
                </Stack>

        return element;
    }

    const isVideoPlayed = () : void => {
        if(displayDuration > 0)
            props.onDismiss(false);
        else
            props.onDismiss(true);
    }

    if(!props.showModal)
        return <></>
    else
        return(
            <Modal
                titleAriaId={"ModalVideoContent"}
                isOpen={props.showModal}
                onDismiss={isVideoPlayed}
                isBlocking={true}
                containerClassName={ContentStyles.Container}
                dragOptions={true ? dragOptions : undefined}
                responsiveMode={ResponsiveMode.large}
            >
                <Stack className={ContentStyles.Header} horizontal horizontalAlign="space-between">
                    <Stack.Item>
                        <Text variant="xLarge">{props.resource.description}</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={{ iconName: 'Cancel' }}
                            ariaLabel="Close popup modal"
                            onClick={isVideoPlayed}
                        />
                    </Stack.Item>
                </Stack>
                <Stack className={ContentStyles.Body}>
                    {buildModalContent()}
                </Stack>
            </Modal>
        )
}