import { IColumn, IDragDropContext, IDragDropEvents, IGroup, mergeStyles, SelectionMode, ShimmeredDetailsList } from "@fluentui/react";
import React, { useEffect } from "react";
import { PageStructureMetadata, SystemPageStructure, SystemPageStructureType } from "../../../../Model/SystemModels";

export interface IAdminPageDesignerStructureProps {
    enableShimmer: boolean;
    structures: PageStructureMetadata[];
} 

const dragEnterClass = mergeStyles({
    backgroundColor: 'red',
    cursor: 'pointer !important'
  });

export const AdminPageDesignerStructure: React.FC<IAdminPageDesignerStructureProps> = (props) => {
    
    const getGroups = () : IGroup[] => {
        const groups: IGroup[] = [];
        return groups;
    };
    
    const buildColumns = () : IColumn[] => {
        let columns: IColumn[] = [];
        columns.push({
            key: "name",
            name: "Nome",
            fieldName: "name",
            minWidth: 100,
            maxWidth: 200,
            onRender: (item?: PageStructureMetadata, index?: number, column?: IColumn) => {
                return item!.pageStructure.name;
            }
        });
        columns.push({
            key: "type",
            name: "Tipo",
            fieldName: "type",
            minWidth: 100,
            maxWidth: 200,
            onRender: (item?: any, index?: number, column?: IColumn) => {
                if (item.type == 0)
                    return "Campo";
                else
                    return "Gruppo";
            }
        });

        return columns;
    }

    const _getDragDropEvents = (): IDragDropEvents => {
        return {
          canDrop: (dropContext?: IDragDropContext, dragContext?: IDragDropContext) => {
            return true;
          },
          canDrag: (item?: any) => {
            return true;
          },
          onDragEnter: (item?: any, event?: DragEvent) => {
            return dragEnterClass;
          },
          onDragLeave: (item?: any, event?: DragEvent) => {

          },
          onDrop: (item?: any, event?: DragEvent) => {

          },
          onDragStart: (item?: any, itemIndex?: number, selectedItems?: any[], event?: MouseEvent) => {

          },
          onDragEnd: (item?: any, event?: DragEvent) => {

          },
        };
      }
    
      let items: PageStructureMetadata[] = [];
      let item = new PageStructureMetadata();
      item.pageStructure.name = "StatusCode";
      item.pageStructure.type = SystemPageStructureType.Field;
      items.push(item);
      item = new PageStructureMetadata();
      item.pageStructure.name = "Description";
      item.pageStructure.type = SystemPageStructureType.Field;
      items.push(item);


    return (
        <ShimmeredDetailsList items={items}
                              setKey={"systemId"}
                              columns={buildColumns()}
                              selectionMode={SelectionMode.single}
                              enableShimmer={props.enableShimmer}
                              dragDropEvents={_getDragDropEvents()}
                              detailsListStyles={{
                                  contentWrapper: {
                                      maxHeight: 'calc(100vh - 320px)',
                                      minHeight: 'calc(100vh - 320px)',
                                      overflowY: 'auto',
                                      overflowX: 'hidden',
                                      width: '100%'
                                  },
                                  root: {
                                      overflowX: 'auto',
                                      overflowY: 'hidden'
                                  }
                              }} />
    );
}