import React, { useRef } from "react";
import { SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminSharepointDirectoryCard } from "../AdminSharepointDirectoryCard/AdminSharepointDirectoryCard";

export const AdminSharepointDirectoryList : React.FC = () => {

    const cardRef = useRef<PageContainerBase>(null);
    
    return (
        <>
            <PageContainer endpoint="/odata/sharepoint/directory" pageType={SystemPageType.List} title="Directory Sharepoint" cardRef={cardRef} backAllowed showAllowed insertAllowed updateAllowed deleteAllowed>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Id sito" fieldName="siteId" minWidth={200} maxWidth={200} />
                    <ListHeaderEntry name="Id lista" fieldName="listId" minWidth={200} maxWidth={200} />
                    <ListHeaderEntry name="Id directory" fieldName="directoryId" minWidth={80} maxWidth={80} />
                    <ListHeaderEntry name="Id referenza directory" fieldName="directoryRefId" minWidth={350} maxWidth={350} />
                    <ListHeaderEntry name="Nome" fieldName="name" minWidth={80} maxWidth={80} />
                    <ListHeaderEntry name="Tipo entità" fieldName="entityType" minWidth={350} maxWidth={350} onRender={(item: any, index, column) => {
                        if (item.entityType === 0)
                            return "Partner"
                        else if (item.entityType === 1)
                            return "Cliente"
                    }} />
                    <ListHeaderEntry name="Abilitata" fieldName="enabled" minWidth={250} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminSharepointDirectoryCard ref={cardRef} /> 
        </>
    );
}