import { CommandBar, ICommandBarItemProps, mergeStyles } from "@fluentui/react";
import React from "react";

export interface ICommonActionBarProps {
    items: ICommandBarItemProps[];
    farItems?: ICommandBarItemProps[];
}

const pageCommandBarClassName = mergeStyles([{
    borderBottom: '1px solid #d9d9d9'
}])

export const CommonActionBar : React.FC<ICommonActionBarProps> = (props) => {
    return (
        <CommandBar className={pageCommandBarClassName} items={props.items} farItems={props.farItems} />
    )
} 