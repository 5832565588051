import React, {useRef} from "react";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminAuthenticationSetupCard } from "../AdminAuthenticationSetupCard/AdminAuthenticationSetupCard";

export const AdminAuthenticationSetupList : React.FC = () => {
    
    const cardRef = useRef<PageContainerBase>(null);
    const adminService = new AdminService();
    
    return (
        <>
            <PageContainer endpoint={'/odata/authentication/setup'} pageType={SystemPageType.List} title={"Lista authentication setup"} cardRef={cardRef} backAllowed insertAllowed updateAllowed>
                <PageDefinitionContainer type={ContainerType.Action}>
                        <ActionArea category={SystemActionCategory.Process}>
                            <ActionEntry name="gdapChallenge" label="Challenge GDAP" iconName="AuthenticatorApp" runOnRec onClick={(req:any)=>{
                                adminService.gdapChallenge(req)
                                .then((resp) => {
                                    window.open(resp);
                                })
                                .catch((err) => {
                                    ToastService.showMessage(MessageType.Error, "Errore durante il challenge: " + err.message);
                                })
                            }}>
                            </ActionEntry>
                        </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry fieldName="code" name="Codice" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="instance" name="Istanza" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="tenantId" name="Tenant Id" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="clientId" name="Client Id" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="clientSecret" name="Client secret" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="scope" name="Scope" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="redirectUrl" name="Redirect Url" minWidth={100} maxWidth={200} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminAuthenticationSetupCard ref={cardRef} />
        </>
    );
}