import { ContextualMenu, DefaultButton, Dialog, DialogContent, DialogFooter, DialogType, FontWeights, getTheme, IButtonStyles, IconButton, mergeStyles, mergeStyleSets, Modal, PrimaryButton, ResponsiveMode, Stack, Text } from "@fluentui/react";
import { t } from "i18next";
import React from "react";
import { JsonToTable } from "react-json-to-table";
import { CommonJsonToTable } from "../CommonJsonToTable/CommonJsonToTable";

export interface IJsonToTableProps {
    onSuccess: () => void;
    onDismiss: () => void;
    title: string;
    subText? :string;
    showDialog: boolean;
    json: any;
}

export const CommonJsonToTableDialog: React.FC<IJsonToTableProps> = (props: IJsonToTableProps) => {
    const theme = getTheme();
    const contentStyles = mergeStyleSets({
      container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: '100%',
        height: 'calc(100vh - 100px)',
      },
      header: [
        theme.fonts.xLargePlus,
        {
          flex: '1 1 auto',
          borderTop: `4px solid ${theme.palette.themePrimary}`,
          color: theme.palette.neutralPrimary,
          display: 'flex',
          alignItems: 'center',
          fontWeight: FontWeights.semibold,
          padding: '12px 12px 14px 24px',
        },
      ],
      body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        height: "500px",
        selectors: {
          p: { margin: '14px 0' },
          'p:first-child': { marginTop: 0 },
          'p:last-child': { marginBottom: 0 },
          '&:hover': {
            cursor: 'pointer'
          }
        },
      },
    });

    const iconButtonStyles: Partial<IButtonStyles> = {
      root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
      },
      rootHovered: {
        color: theme.palette.neutralDark,
      },
    };

    const TableClassName = mergeStyles([{
        /*color: "black !important",
        'th, td': {
            paddingLeft: '20px !important',
            paddingRight: '20px !important',
            textAlign: 'left !important',
            //borderBottom: '1px solid #ddd !important'
            borderBottom: 'none !important'
        },
        'tr:nth-child(even)': {
            backgroundColor: '#0078d4 !important',
            color: 'white !important',
            fontWeight: 'bold !important',
        },
        'tr:nth-child(odd)': {
            backgroundColor: 'white !important',
            color: 'balack !important',
            fontWeight: 'bold !important',
        },
        'tr:nth-child(2)': {borderBottom: '1px solid #ddd !important'},
        'td':{
            borderTop: 'none !important',
            borderLeft: 'none !important',
            borderRight: 'none !important',
        },
        'tr:first-child': {
            borderBottom:'1px solid #ddd !important'
        }*/
    }])

    return (
        <Modal
            isOpen={props.showDialog}
            onDismiss={props.onDismiss}
            isBlocking={false}
            dragOptions={{
                moveMenuItemText: 'Move',
                closeMenuItemText: 'Close',
                menu: ContextualMenu,
                keepInBounds: true,
                dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child'
            }}
            containerClassName={contentStyles.container}
        >
            <Stack className={contentStyles.header}>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={{ iconName: 'Cancel' }}
                    ariaLabel="Chiudi"
                    onClick={() => {
                        props.onDismiss();
                    }}
                />
                <Text variant="xLargePlus" color="#ffff">{props.title}</Text>
            </Stack>
            <Stack 
                style={{
                    height: 'calc(100vh - 200px)',
                    width: '90%',
                    margin: '0 auto',
                    overflowY: 'auto',
                    overflowX: 'auto',
                }}
            >
              <CommonJsonToTable json={props.json} />
            </Stack>
        </Modal>
    );
}
