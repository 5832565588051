import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminSystemJobQueueEntryCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"System Job Queue - Scheda Entità"} endpoint="odata/systemjobqueue/entry" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="code" type={SystemFieldType.String} label="Codice" />
                <CardField name="description" type={SystemFieldType.String} label="Descrizione" />
                <CardField name="status" type={SystemFieldType.Option} label="Stato" cmbOptions={[
                    {key: 0, text: "Spento"},
                    {key: 1, text: "Pronto"},
                    {key: 2, text: "In esecuzione"},
                    {key: 3, text: "In errore"}
                ]} />
                <CardField name="categoryCode" type={SystemFieldType.String} label="Codice Categoria" />
                <CardField name="monday" type={SystemFieldType.Boolean} label="Lunedì" />
                <CardField name="tuesday" type={SystemFieldType.Boolean} label="Martedì" />
                <CardField name="wednesday" type={SystemFieldType.Boolean} label="Mercoledì" />
                <CardField name="thursday" type={SystemFieldType.Boolean} label="Giovedì" />
                <CardField name="friday" type={SystemFieldType.Boolean} label="Venerdì" />
                <CardField name="saturday" type={SystemFieldType.Boolean} label="Sabato" />
                <CardField name="sunday" type={SystemFieldType.Boolean} label="Domenica" />
                <CardField name="fromTime" type={SystemFieldType.TimeSpan} label="Dalle ore" />
                <CardField name="toTime" type={SystemFieldType.TimeSpan} label="Alle ore" />
                <CardField name="minutesDelay" type={SystemFieldType.Number} label="Intervallo (in minuti)" />
                <CardField name="functionCode" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="odata/systemjobqueue/function" tableRelationKey="code" tableRelationField="description" label="Codice Funzione" />
                <CardField name="nextStartDate" type={SystemFieldType.String} label="Data Prossima Esecuzione" />
                <CardField name="lastStartDate" type={SystemFieldType.String} label="Data Ultima Esecuzione" />
                <CardField name="runAsUserId" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="odata/user" tableRelationKey="id" tableRelationField="displayName" label="Utente Esecuzione" tableRelationFilter="?$filter=crmSystemUserId gt ''" />
                <CardField name="runAsAppId" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="odata/system/app" tableRelationKey="id" tableRelationField="name" label="App Esecuzione" />
            </CardGroup>
        </PageContainer>
    );
});