import { ComboBox, DefaultButton, Dialog, DialogFooter, DialogType, IComboBoxOption, IModalProps, IStackProps, IStackStyles, MessageBar, MessageBarType, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { t } from "i18next";
import React from "react";

export interface IBookingModalProps {
    onSave: () => void;
    showModal: boolean;
}

const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: '50%' } },
};
const stackTokens = { childrenGap: 40 };
const stackStyles: Partial<IStackStyles> = { root: { width: '100%' } };
const modalProps: Partial<IModalProps> = {
    isBlocking: true,
    styles: {
        main: {
            width: '95% !important',
            maxWidth: 'calc(95%) !important'
        }
    }
};

const options: IComboBoxOption[] = [];

export class BookingModal extends React.Component<IBookingModalProps, {}> {

    constructor(props: IBookingModalProps)
    {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <>
              <Dialog
                hidden={!this.props.showModal}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: t('demoCenter:BookingModal:title')!
                }}
                modalProps={modalProps}
                minWidth={900}
                maxWidth={1000}
              >
                <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                    <iframe src='https://outlook.office365.com/owa/calendar/EOSOnline@eos-solutions.it/bookings/' width='100%'  style={{ 
                        border: 0,
                        height: 'calc(100vh - 200px)'
                    }}
                    ></iframe>
                </Stack>
                <DialogFooter>
                  <PrimaryButton onClick={() => {this.props.onSave()}} text={t('demoCenter:BookingModal:primaryButtonText')!} />
                </DialogFooter>
              </Dialog>
            </>
          );
    }
}