import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { SystemActionCategory, SystemOperation, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminNotificationGroupMemberCard } from "../AdminNotificationGroupMemberCard/AdminNotificationGroupMemberCard";

export const AdminNotificationGroupMemberList : React.FC = () => {
    const SetupCode = HttpHelper.getParameter("setupCode");
    const SetupType = HttpHelper.getParameter("setupType");
    const GroupCode = HttpHelper.getParameter("groupCode");
    const navigate = useNavigate();
    const cardRef = useRef<PageContainerBase>(null);
    
    const buildListEndpoint = () :string => {
        let endpoint = "/odata/notification/group/member";

        if(((GroupCode !== null) && (GroupCode !== "")) && (SetupCode !== null) && (SetupCode !== ""))
            endpoint += "?$filter=groupCode eq '" + GroupCode+ "' and setupCode eq '" + SetupCode + "'";

        return endpoint
    }

    const setParentParameters = ()=>{
        cardRef.current?.setRecord({
            setupCode: SetupCode,
            setupType: SetupType,
            groupCode: GroupCode
        });
        
        cardRef.current?.openPage(SystemOperation.Insert);
    }
    
    return (
        <>
            <PageContainer onNewRecord={setParentParameters} endpoint={buildListEndpoint()} pageType={SystemPageType.List} title={"Membri gruppo"} cardRef={cardRef} backAllowed insertAllowed updateAllowed deleteAllowed>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Id User" fieldName="userId" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Reference Id" fieldName="referenceId" minWidth={100} maxWidth={200} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminNotificationGroupMemberCard ref={cardRef} />
        </>
    );
}