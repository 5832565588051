import { DirectionalHint } from "@fluentui/react";
import { CustomerTrialStatus } from "./CustomerModels";
import { Lead } from "./PartnerModels";
import { LeadToLiveChecklist } from "../Components/Common/LeadDashboard/LeadDashboard";

export class ShowcaseEntry {
    public id: string = "";
    public name: string = "";
    public description: string = "";
    public videoId: string = ""; 
    public iconName: string = "";
    public startAsDefault: boolean = false;
    public ranking: number = 0;
    public videoType: string = "";
}

export class GuideTourEntry {
    public id: string = "";
    public title: string = "";
    public description: string = "";
    public secondaryButtonText: string = "";
    public primaryButtonText: string = "";
    public elementId: string = "";
    public direction: DirectionalHint = DirectionalHint.bottomAutoEdge;
    public scrollToElement: boolean = false;
    public scrollToElementId: string = "";
    public visible: boolean = true;
}

export class ECommerceUser {
    public id: number = 0;
    public privateEmail : string = "";                  
	public firstName : string = "";              
	public lastName : string = "";               
	public displayName : string = "";            
	public soSmartEmail : string = "";           
	public phone : string = "";                  
	public active : boolean = false;                 
	public customerId : number = 0;             
	public isAdmin : boolean = false;                
	public role : string = "";                   
	public crmStatus : number = 0;              
	public iSpringStatus : number = 0;          
	public helpOnlineStatus : number = 0;       
	public idPartner : number = 0;              
	public leadId : number = 0;              
	public isPrivateAdmin : boolean = false;         
} 

export class ECommerceCustomer {
    public id : number = 0;
    public idPartner : number = 0;
    public name : string = "";
    public shopCustomerId : string = "";
    public partnerRefId : number = 0;
    public companyName : string = "";
    public streetAddress : string = "";
    public city : string = "";
    public zipCode : string = "";
    public county : string = "";
    public country : string = "";
    public vat : string = "";
    public sdi : string = "";
    public principalUserId : number  = 0;
    public enabled : boolean = true;
    public email : string = "";
    public pecEmail : string = "";
    public isTrial: CustomerTrialStatus = CustomerTrialStatus.Empty;
}

export class CustomerUser {
    public user: ECommerceUser = {} as ECommerceUser;
    public customer: ECommerceCustomer = {} as ECommerceCustomer;
    public password: string = "";
}

export class TrialData {
    public customerUser: CustomerUser = {} as CustomerUser;
    public confirmPassword: string = "";
    public currentLead: Lead = new Lead();
}

export class Module {
    public code: string = "";
    public description: string = "";
    public descriptionCaption: string = "";
    public enabled: boolean = false;
    public onBoardingPhaseNo: number = 0;
    public permissionPremium: string = "";
    public presaleVisible: boolean = false;
    public presaleIcon: string = "";
    public processCount: number = 0;
    public interviewQuestionCompleted: boolean = false;
    public moduleCompleted: boolean = false;
    public noTaskToBeCompleted: number = 0;
    public noTaskCompleted: number = 0;
    public dataMigrNoTaskToBe: number = 0;
    public dataMigrNoTaskComp: number = 0;
    public systemId: string = "";
    public systemCreationUserId: string = "";
    public systemCreationDate: string = "";
    public systemUpdateDate: string = "";
    public systemUpdateUserId: string = "";
    public timestamp: string = "";
    public statusCode: string = "";
}

export class Feature {
    public processModule: string = "";
    public processCode: string = "";
    public processTestLineNo: number = 0;
    public processFAQLineNo: number = 0;
    public questions: string = "";
    public questionsCaption: string = "";
    public processDescrption: string = "";
    public processDescrptionCaption: string = "";
    public testProjectStatus: string = "";
    public main: boolean = true;
    public publicVisible: boolean = false;
    public presalesVisible: boolean = true;
    public customerVisible: boolean = false;
    public processTestType: string = "";
    public onBoardingVisible: boolean = false;
    public onBoardingMandatory: boolean = false;
    public onBoardingCompleted: boolean = false;
    public onBoardingRanking: number = 0;
    public onBoardingMode: string = "";
    public onBoardingRefToModule: string = "";
    public onBoardingRefToProcessCode: string = "";
    public onBoardingReferToTestLine: number = 0;
    public onBoardingReferToFAQLine: number = 0;
    public objectType: string = "";
    public objectId: number = 0;
    public videoLink: string = "";
    public docs1Link: string = "";
    public presaleIcon: string = "";
    public presaleGroupDescription: string = "";
    public presaleGroupDescriptionCaption: string = "";
    public rankingAbsolute: number = 0;
    public likeCount: number = 0;
    public systemId: string = "";
    public systemCreationUserId: string = "";
    public systemCreationDate: string = "";
    public systemUpdateDate: string = "";
    public systemUpdateUserId: string = "";
    public timestamp: string = "";
    public statusCode: string = "";
}

export class DayEntry {
    public day: number = 0;
    public description: string = "";
    public sourceFeature: Feature = {} as Feature;
}

export class IndustyType {
    public id: number = 19;
    public createdAt: Date = new Date();
    public expirationDate: Date = new Date();
    public source: number = 1;
    public description: string = "";
    public translatedDescription: string = "";
    public systemId: string = "";
    public systemCreationUserId: string = "";
    public systemCreationDate: Date = new Date();
    public systemUpdateDate: Date = new Date();
}

export class LeadIndustry {
    public leadId: number = 0;
    public channelNo: number = 0;
    public salesChannel: string = "";
    public description: string = "";
    public typologyId: LeadIndustryTypology = LeadIndustryTypology.None;
    public revenue: number = 0;
}

export class ChecklistContent {
    public locale: string = "";
    public entry: ChecklistContent[] = [];
}

export class ChecklistItem {
    public id: string = "";
    public shortTitle: string = "";
    public title: string = "";
    public alternativeTitle: string = "";
    public description: string = "";
    public active: boolean = false;
    public completed: boolean = false;
    public mandatory: boolean = false;
    public visible: boolean = false;
    public duration: number = 0;
    public primaryButtonText: string = "";
    public defaultButtonText: string = "";
    public guideTourTitle?: string = "";
    public guideTourDescription?: string = "";
    public clickable?: boolean = false;
    public leadTask?: LeadToLiveChecklist = new LeadToLiveChecklist();
}

export class RoleCenterContent {
    public locale: string = "";
    public currency: string = "";
    public entry: AppGroup[] = [];
}

export class AppGroup {
    public groupTitle: string = "";
    public extendedTitle?: string = "";
    public subGroup: AppSubGroup[] = [];
    public uniqueId: string = "";
    public guideTourTitle?: string = "";
    public guideTourDescription?: string = "";
    public keyword?: string = "";
    public clickable?: boolean = false;
}

export class AppSubGroup {
    public subTitle: string = "";
    public cueEntry: AppEntry[] = [];
    public uniqueId: string = "";
    public guideTourTitle?: string = "";
    public guideTourDescription?: string = "";
    public chart?: AppChart[] = [];
    public image?: string = "";
    public clickable?: boolean = false;
    public keyword?: string = "";
}

export class AppEntry {
    public cueTitle: string = "";
    public cueExtendedTitle?: string = "";
    public redirectUrl: string = "";
    public value: number = 0;
    public indicatorType: AppIndicatorType = 0;
    public isIndicatorCue: Boolean = false;
    public actionType: AppActionType = 0;
    public indicatorSeverity?: AppIndicatorSeverity = 0;
    public videoId?: string = "";
    public uniqueId: string = "";
    public guideTourTitle?: string = "";
    public guideTourDescription?: string = "";
    public keyword?: string = "";
    public clickable?: boolean = false;
}

export class AppChart {
    public chartType: AppChartType = 0;
    public chartTitle: string = "";
    public labels: string[] = [];
    public datasets: AppChartDataset[] | [] = [];
    public uniqueId: string = "";
    public guideTourTitle?: string = "";
    public guideTourDescription?: string = "";
    public clickable?: boolean = false;
}

export class AppChartDataset {
    public label: string = "";
    public data: number[] = [];
    public backgroundColor: string | string[] = "";
    public borderColor?: string | string[] = "";
    public fill?: boolean = false;
    public borderWidth?: number = 0;
    public type?: any = "";
}

export enum AppIndicatorType {
    Percentage,
    Count,
    Cost
}

export enum AppIndicatorSeverity {
    Favorable,
    Unfavorable,
    Ambiguous,
    Subordinate
}

export enum AppActionType {
    None,
    ExternalRedirect,
    InternalRedirect,
    VideoModal,
    Custom
}

export enum AppChartType {
    VerticalBar,
    HorizontalBar,
    AreaChart,
    LineChart,
    DoughnatChart,
    Composed,
    Funnel
}

export enum LeadIndustryTypology {
    None,
    ProjectProduction,
    StandardProduction,
    Trade,
    Service
}

export class BCNProcess {
    public processModule: string = "";
    public processCode: string = "";
    public soSmartEnabled: boolean = false;
    public sequence: number = 0;
    public description: string = "";
    public extendedDescription: string = "";
    public descriptionCaption: string = "";
    public extendedDescriptionCaption: string = "";
}

export class BCNProcessTest {
    public processModule: string = "";
    public processCode: string = "";
    public lineNo: number = 0;
    public description: string = "";
    public descriptionCaption: string = "";
    public videoLink: string = "";
    public crmReferenceId: string = "";
    public enabled: boolean = false;
}

export class LeadInteraction {
    public frontEndLogEntry: FrontEndLogEntry[] = [];
    public systemLogEntry: SystemLogEntry[] = [];
    public crmInteraction: CRMInteraction[] = [];
}

export class FrontEndLogEntry {
    public systemCreationDate: Date = new Date();
    public id: string = "";
    public appId: string = "";
    public description: string = "";
    public referenceId: string = "";
    public userId:number = 0;
}

export class SystemLogEntry {
    public systemCreationDate: Date = new Date();
    public entryNo: number = 0;
    public title: string = "";
    public description: string = "";
    public performedOn: Date = new Date();
    public duration: number = 0;
    public data: string = "";
    public publisherEventName: string = "";
    public subscribedByObject: string = "";
    public subscribedByFunction: string = "";
    public sourceEntityName: string = "";
    public sourceId: string = "";
    public userId: number = 0;
    public typeCode: string = "";
    public unitOfMeasure: string = "";
    public scorePerUnitOfMeasure: number = 0;
    public dimension1: string = "";
    public dimension2: string = "";
}

export class CRMInteraction {
    public systemCreationDate: Date = new Date();
    public entryNo: number = 0;
    public leadId: number = 0;
    public type: number = 0;
    public description: string = "";
    public crmReferenceId: string = "";
    public crmReferenceStartDate: Date = new Date();
    public crmReferenceEndDate: Date = new Date();
    public interactionSource: number = 0;
    public createdOn: Date = new Date();
    public modifiedOn: Date = new Date();
    public title: string = "";
    public percentComplete: number = 0;
    public durationMinutes: number = 0;
    public owningUserId: number = 0;
    public phoneNumber: string = "";
}