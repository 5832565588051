import { CommandBar, FontIcon, Image, ICommandBarItemProps, Label, mergeStyles, Stack, Icon, List } from "@fluentui/react";
import React, { useContext } from "react";
import { ToastService } from "../../../Core/Toast/ToastService";
import { SharepointFile } from "../../../Model/PartnerModels";
import facebookLogo from '../../../Assets/Images/facebook.png';
import instagramLogo from '../../../Assets/Images/instagram.png';
import twitterLogo from '../../../Assets/Images/twitter.png';
import linkedinLogo from '../../../Assets/Images/linkedin.png';
import youtubeLogo from '../../../Assets/Images/youtube.png';
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { t } from "i18next";
import { PartnerInformationContext } from "../../../ApplicationCode/Partner/PartnerInformationContext";

//#region Dashboard Container

export const DashboardContainer : React.FC<{}> = (props) => {

    const dashboardContainerClassName = mergeStyles([{
        width: "98%",
        margin: '0 auto',
        marginTop: '10px',
        backgroundColor: 'rgb(255, 255, 255)',
        paddingLeft: '1em',
        paddingBottom: '1em',
        paddingRight: '1em',
        paddingTop: '1em',
        boxShadow: 'rgb(34 36 38 / 15%) 0px 1px 1px 1px',
        borderLeftWidth: '0px',
        borderBottomWidth: '0px',
        borderRightWidth: '0px',
        borderTopWidth: '2px',
        borderleftStyle: 'solid',
        borderbottomStyle: 'solid',
        borderRightStyle: 'solid',
        borderTopStyle: 'solid',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
        borderTopRightRadius: '0px',
        borderTopLeftRadius: '0px',
        borderLeftColor: 'transparent',
        borderBottomColor: 'transparent',
        borderRightColor: 'transparent',
        borderTopColor: 'transparent'
    }]);

    return(
        <Stack className={dashboardContainerClassName}>
            {props.children}
        </Stack>
    )
}

//#endregion

//#region ActionBar

export interface IActionbarProps {
    items: ICommandBarItemProps[];
}

export const ActionBar : React.FC<IActionbarProps> = (props: IActionbarProps) => {

    const commandBarStyle = {
        root: {
            marginBottom: 10,
            padding: 0,
            borderBottom: '1px solid rgb(224, 224, 224)',
        }
    };

    const dashboardItemActionStyle = {
        root: {
            fontSize: 16,
            color: 'rgb(0, 132, 137) !important',
            backgroundColor: 'rgb(255,255,255) !important'
        },
        rootHovered: {
            textDecoration:'underline',
        },
        icon:{
            color: 'rgb(0, 132, 137) !important'
        }
    };

    let itemList = props.items;
    for (let i = 0; i < itemList.length; i++)
        itemList[i].buttonStyles = dashboardItemActionStyle;

    return(
        <CommandBar
            items={itemList}
            overflowItems={[]}
            styles={commandBarStyle}
        />
    )
}

//#endregion

//#region CarouselItem

export interface ICarouselItemProps {
    title: string;
    description: string;
    iconName?: string;
    onClick?: () => void;
}

export const CarouselItem : React.FC<ICarouselItemProps> = (props: ICarouselItemProps) => {

    return(
        <Stack key={"message2"} style={{
            width: 'auto',
            display: 'flex',
            flexWrap:'wrap-reverse',
            flexDirection:'row',
            justifyContent: 'space-around',
            height: 'auto',
            paddingLeft: 60,
            paddingBottom:40,
            backgroundColor: 'rgb(217, 240, 242) !important',
            borderRadius:'10px',
            margin:'0 auto'
        }}>
            <Stack.Item>
                <Stack.Item style={{
                    fontSize: 32,
                    padding: 10,
                    display: 'flex',
                    alignItems: 'stretch',
                    margin:'0 auto',
                    textAlign: 'left'
                }}>
                    {props.title}
                </Stack.Item>
                <Stack.Item style={{
                    fontSize: 18,
                    padding: 10,
                    display: 'flex',
                    alignItems: 'stretch',
                    margin:'0 auto',
                    textAlign: 'left'
                }}>
                    <Stack verticalFill style={{
                        textAlign: 'center'
                    }}
                    onClick={props.onClick}>
                        {props.description}
                    </Stack>
                </Stack.Item>
            </Stack.Item>
            <Stack.Item style={{paddingTop:'35px',margin:'0 auto'}}>
                <FontIcon aria-label="icon" iconName={props.iconName} style={{fontSize:100,opacity:'30%'}} />
            </Stack.Item>
        </Stack>
    )
}

//#endregion

//#region SharepointDocumentList

const formatBytes = (bytes?: number, decimals = 2): string => {
    if (bytes) {        
        if (!+bytes) return '0 MB'
        
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }
    else
        return "0 MB";
}

export interface ISharepointDocumentListProps {
    partnerId: number;
    items: SharepointFile[];
}

export const SharepointDocumentList : React.FC<ISharepointDocumentListProps> = (props: ISharepointDocumentListProps) => {
    
    const handleOnClick = (item?: SharepointFile) => {
        let instance = HttpHelper.buildAxiosInstance();
        let endpoint = "/api/csp/sharepoint/file/"+props.partnerId+"/download";
        var promise = new Promise((resolve,reject)=>{
            instance.post(endpoint, item!, {responseType: 'blob'})
            .then((resp)=>{
                const url = window.URL.createObjectURL(new Blob([resp.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', item!.fileName);
                document.body.appendChild(link);
                link.click();
                resolve(true);
            })
            .catch((resp)=>{
                console.log(resp.response.data.message);
                reject(resp.response.data.message);
            })
        })

        ToastService.showPromiseMessage(promise, t('partnerCenter:partnerDashboard:components:downloadStart')!, t('partnerCenter:partnerDashboard:components:downloadSuccess')!, t('partnerCenter:partnerDashboard:components:downloadError')!);
    }

    const onRenderCell = (item?: SharepointFile, index?: any, isScrolling?: boolean): JSX.Element => {
        let iconName: string = "FileTemplate";
        if (item?.fileExtension === "pptx")
            iconName = "PowerPointLogo";

        return (
          <Stack key={item?.fileName + "Entry"}  data-is-focusable={true} horizontal styles={{
              root: {
                  width: '100%',
                  selectors: {
                    "&:hover": {
                        backgroundColor: '#f2f2f2',
                        cursor: 'pointer'
                    }
                  }
              }
          }} onClick={()=>{handleOnClick(item);}}>
            <Stack.Item style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 10,
                color: 'rgb(0, 128, 137)'
            }}>
                <Icon
                iconName={iconName}
                style={{
                    fontSize: 25
                }}
                />
            </Stack.Item>
            <Stack.Item style={{
                padding: 10
            }}>
                <div style={{
                    fontSize: 15,
                    fontWeight: 500
                }}>{item?.fileName} ({formatBytes(item?.fileSize, 2)})</div>
                <div style={{
                    fontSize: 10,
                    fontStyle: 'italic'
                }}>{t('partnerCenter:partnerDashboard:components:clickToDownload')!}</div>
            </Stack.Item>
          </Stack>
        );
      };
    
    if (props.items.length === 0)
      return(
        <Label>{t('partnerCenter:partnerDashboard:components:noDocs')!}</Label>
      );
    else
        return (
            <List items={props.items} onRenderCell={onRenderCell} />
        );
}

//#endregion

//#region Partner Social Slide

export const PartnerSocialSlide: React.FC = () => {
    const partnerInfoContext = useContext(PartnerInformationContext);
    
    const socialLogoImageClassName = mergeStyles([{
        cursor: 'pointer'
    }]);
    
    const buildSocialItem = (social: string): JSX.Element => {
        const legalEntityCode = partnerInfoContext.partner.legalEntityCode;
        switch (social)
        {
            case "facebook":
                if (legalEntityCode === 'ESS')
                    return <></>
                else
                    return <Stack.Item>
                        <Image src={facebookLogo} height={30} width={30} className={socialLogoImageClassName} onClick={() => window.open("https://www.facebook.com/so.smart.business")} />
                    </Stack.Item>
            case "instagram":
                if (legalEntityCode === 'ESS')
                    return <></>
                else
                    return <Stack.Item>
                        <Image src={instagramLogo} height={30} width={30} className={socialLogoImageClassName} onClick={() => window.open("https://www.instagram.com/so_smart_biz/")} />
                    </Stack.Item>
            case "youtube":
                if (legalEntityCode === 'ESS')
                    return <></>
                else
                    return <Stack.Item>
                        <Image src={youtubeLogo} height={30} width={30} className={socialLogoImageClassName} onClick={() => window.open("https://www.youtube.com/channel/UCb5qwkbMINv02sPk4YGWQ-g")} />
                    </Stack.Item>
            case "twitter":
                if (legalEntityCode === 'ESS')
                    return <></>
                else
                    return <Stack.Item>
                        <Image src={twitterLogo} height={30} width={30} className={socialLogoImageClassName} onClick={() => window.open("https://twitter.com/so_smart_biz")} />
                    </Stack.Item>
            case "linkedin":
                if (legalEntityCode === 'ESS')
                    return <Stack.Item>
                        <Image src={linkedinLogo} height={30} width={30} className={socialLogoImageClassName} onClick={() => window.open("https://www.linkedin.com/showcase/so-smart-us/")} />
                    </Stack.Item>
                else
                    return <Stack.Item>
                        <Image src={linkedinLogo} height={30} width={30} className={socialLogoImageClassName} onClick={() => window.open("https://www.linkedin.com/showcase/so-smart-business/")} />
                    </Stack.Item>
            default:
                return <></>;
            }
        }
    return (
        <Stack key={"socialSlideContainer"} style={{
            width: '100%',
            display: 'flex',
            minHeight: '320px',
            padding: 40,
            backgroundColor: 'rgb(217, 240, 242)'
        }}>
            <Stack.Item style={{
                fontSize: 32,
                padding: 10,
                display: 'flex',
                alignItems: 'stretch'
            }}>
                {t('customerCenter:DashboardContentContainer:CustomerSocialSlide:socialTitle')}
            </Stack.Item>
            <Stack.Item style={{
                fontSize: 18,
                padding: 10,
                display: 'flex',
                alignItems: 'stretch'
            }}>
                <div>
                    {t('customerCenter:DashboardContentContainer:CustomerSocialSlide:socialDescription')}<br />
                    <Stack key="socialLogoContainer" horizontal tokens={{
                        childrenGap: 5
                    }} style={{
                        paddingTop: 20
                    }}>
                        {buildSocialItem("facebook")}
                        {buildSocialItem("instagram")}
                        {buildSocialItem("youtube")}
                        {buildSocialItem("twitter")}
                        {buildSocialItem("linkedin")}
                    </Stack>
                </div>
            </Stack.Item>
        </Stack>
    );
}

//#endregion