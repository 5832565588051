import React, { useEffect, useState } from "react";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { SystemApp, SystemDataLoadingStatus, SystemPageType, SystemRole, SystemUser } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { CustomerService } from "../../../../ApplicationCode/Customer/CustomerService";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";

export const AdminUserPermissionList : React.FC = () => {
    const userId = HttpHelper.getParameter('userId');
    const customerService = new CustomerService();
    const adminService = new AdminService();
    const [user, setUser] = useState<SystemUser>(new SystemUser());
    const [systemApps, setSystemApps] = useState<SystemApp[]>([] as SystemApp[]);
    const [systemRoles, setSystemRoles] = useState<SystemRole[]>([] as SystemRole[]);
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
        
    const getUser = () => {
        customerService.getCurrentUser(userId)
        .then((resp)=>{
            setUser(resp);
        })
    }

    const getSystemApps = () => {
        adminService.getSystemApps()
        .then((resp)=>{
            console.log(resp);
            setSystemApps(resp);
        })
    }

    const getSystemRoles = () => {
        adminService.getSystemRoles()
        .then((resp)=>{
            console.log(resp);
            setSystemRoles(resp);
        })
    }

    useEffect(() => {
        if(dataStatus === SystemDataLoadingStatus.ToLoad) {
            getUser();
            getSystemApps();
            getSystemRoles();
            setDataStatus(SystemDataLoadingStatus.Loaded);
        }
    }, [dataStatus])

    return (
        <>
            <PageContainer endpoint={"/odata/user/role?$filter=userId eq "+userId} pageType={SystemPageType.List} title="Lista permessi utente" backAllowed>               
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>                                 
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Id utente" fieldName="userId" minWidth={80} maxWidth={150} />
                    <ListHeaderEntry name="Utente" fieldName="userDisplayName" minWidth={150} maxWidth={250} onRender={(item: any, index, column) => {
                        return user.displayName;
                    }} />
                    <ListHeaderEntry name="ID App" fieldName="appId" minWidth={250} maxWidth={300} />
                    <ListHeaderEntry name="App" fieldName="appId" minWidth={200} maxWidth={250} onRender={(item: any, index, column) => {
                        return systemApps.find(systemApp => systemApp.id === item.appId)?.name;
                    }} />
                    <ListHeaderEntry name="Codice Ruolo" fieldName="roleCode" minWidth={100} maxWidth={150} />
                    <ListHeaderEntry name="Ruolo" fieldName="roleCode" minWidth={200} maxWidth={250} onRender={(item: any, index, column) => {
                        return systemRoles.find(systemRole => systemRole.appId === item.appId && systemRole.code === item.roleCode)?.description;
                    }} />
                </PageDefinitionContainer>
            </PageContainer>
        </>
    );
}