import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { PartnerService } from "../../../../ApplicationCode/Partner/PartnerService";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { ISessionStateContext, SessionStateContext } from "../../../../Core/State/SessionStateContext";
import { SystemCore } from "../../../../Core/System/SystemCore";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { CrmSetup, Lead, Partner } from "../../../../Model/PartnerModels";
import { SystemActionCategory, SystemDataLoadingStatus, SystemPageType } from "../../../../Model/SystemModels";
import { CommonBookingModal } from "../../CommonBookingModal/CommonBookingModal";
import { Loader } from "../../Loader/Loader";
import { ActionArea } from "../../Template/Action/ActionArea";
import { ActionEntry } from "../../Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../Template/PageDefinitionContainer";
import { CommonFunctions } from "../../../../ApplicationCode/Common/CommonFunctions";
import { AdminLeadCard } from "../../../Admin/AdminLeadCard/AdminLeadCard";
import { LeadConfigChecklistDetails, LeadToLiveChecklist, SaveLeadConfigChecklist } from "../../LeadDashboard/LeadDashboard";
import { CommonLeadModal } from "../../CommonLeadModal/CommonLeadModal";
import { IndustyType } from "../../../../Model/DemoModels";
import { t } from "i18next";

export const CommonLeadList : React.FC = () => {
    const currentPage = useRef<PageContainerBase>(null);
    const cardRef = useRef<PageContainerBase>(null);
    const [showBookingModal, setShowBookingModal] = useState(false);
    const [dataStatus,setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [syncStatus,setSyncStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [partners,setPartners] = useState<Partner[]>([] as Partner[]);
    const [message,setMessage] = useState(t("adminCenter:leadList:loaderText"));
    const [endpoint,setEndpoint] = useState<string>("");
    const [listTitle,setListTitle] = useState<string>("");
    const [crmSetup,setCrmSetup]=useState(new CrmSetup());
    let sessionStateContext: ISessionStateContext = useContext(SessionStateContext);
    
    const [showLeadModal, setShowLeadModal] = useState<boolean>(false);
    const [selectedLead, setSelectedLead] = useState<Lead | undefined>(undefined);
    const [leadDataSaveInProgress, setLeadDataSaveInProgress] = useState<boolean>(false);
    const [leadConfigDetails, setLeadConfigDetails] = useState<LeadConfigChecklistDetails>(new LeadConfigChecklistDetails());
    const [industryTypes, setIndustryTypes] = useState<IndustyType[]>([]);
    const [validationErrors, setValidationErrors] = useState<Object[]>([]);
    const [legalEntityFilter, setLegalEntityFilter] = useState<string>("");

    const adminService = new AdminService();
    const partnerService = new PartnerService();
    const commonFunctions = new CommonFunctions();
    const navigate = useNavigate();

    const buildListEndpoint = () =>{
        setDataStatus(SystemDataLoadingStatus.Loading)
        const sourceCode = HttpHelper.getParameter('source');
        const workflowCode = HttpHelper.getParameter('code');
        const workflowCodeName = HttpHelper.getParameter('codeName');
        const leadFilter = HttpHelper.getParameter('leadFilter');
        const activeLeads = HttpHelper.getParameter('activeLeads');
        let buildedEndpoint = "/odata/lead";
        let currentTitle = t("adminCenter:leadList:title")!;
        commonFunctions.getPartnerId(sessionStateContext.currentUser.email).then((resp) => {
            if(resp.id === 1){
                if(workflowCode != null && workflowCode != ""){
                    currentTitle += " ("+workflowCodeName+")";
                    if (sourceCode)
                        buildedEndpoint += '?$filter=businessLineCode eq \''+sourceCode+'\' and statusCode eq \''+workflowCode+'\''+(legalEntityFilter ? 'and legalEntityCode eq \''+legalEntityFilter+'\'': '')+'&$orderby=createdOn desc';
                    else
                        buildedEndpoint += '?$filter=statusCode eq \''+workflowCode+'\''+(legalEntityFilter ? 'and legalEntityCode eq \''+legalEntityFilter+'\'': '')+'&$orderby=createdOn desc';
                }
                else if(activeLeads != null && activeLeads != ""){
                    currentTitle += t("adminCenter:leadList:title1");                    
                    if (sourceCode)
                        buildedEndpoint += '?$filter=businessLineCode eq \''+ sourceCode +'\' and (statusCode eq \'80\' or statusCode eq \'60\' or statusCode eq \'50\' or statusCode eq \'20\' or statusCode eq \'10\' or statusCode eq \'05\' or statusCode eq \'03\' or statusCode eq \'02\' or statusCode eq \'01\' or statusCode eq \'00\')'+(legalEntityFilter ? 'and legalEntityCode eq \''+legalEntityFilter+'\'': '')+'&$orderby=createdOn desc';
                    else
                        buildedEndpoint += '?$filter=(statusCode eq \'80\' or statusCode eq \'60\' or statusCode eq \'50\' or statusCode eq \'20\' or statusCode eq \'10\' or statusCode eq \'05\' or statusCode eq \'03\' or statusCode eq \'02\' or statusCode eq \'01\' or statusCode eq \'00\')'+(legalEntityFilter ? 'and legalEntityCode eq \''+legalEntityFilter+'\'': '')+'&$orderby=createdOn desc';
                }
                else if((leadFilter !== null) && (leadFilter !== "")){
                    switch(leadFilter){
                        case "leadWin":
                            currentTitle += " (Win)";
                            if (sourceCode)
                                buildedEndpoint += '?$filter=businessLineCode eq \''+sourceCode+'\' and (statusCode eq \'95\' or finalOutcome eq \'Win\')'+(legalEntityFilter ? 'and legalEntityCode eq \''+legalEntityFilter+'\'': '')+'&$orderby=systemCreationDate desc';
                            else
                                buildedEndpoint += '?$filter=statusCode eq \'95\' or finalOutcome eq \'Win\''+(legalEntityFilter ? 'and legalEntityCode eq \''+legalEntityFilter+'\'': '')+'&$orderby=systemCreationDate desc';
                        break;
                        case "leadLost":
                            currentTitle += " (Lost)";
                            if (sourceCode)
                                buildedEndpoint += '?$filter=businessLineCode eq \''+sourceCode+'\' and (statusCode eq \'96\' or finalOutcome eq \'Lost\')'+(legalEntityFilter ? 'and legalEntityCode eq \''+legalEntityFilter+'\'': '')+'&$orderby=systemCreationDate desc';
                            else
                                buildedEndpoint += '?$filter=statusCode eq \'96\' or finalOutcome eq \'Lost\''+(legalEntityFilter ? 'and legalEntityCode eq \''+legalEntityFilter+'\'': '')+'&$orderby=systemCreationDate desc';
                        break;
                        case "leadUnqualified":
                            currentTitle += " (Unqualified)";
                            if (sourceCode)
                                buildedEndpoint += '?$filter=businessLineCode eq \''+sourceCode+'\' and (statusCode eq \'97\' or finalOutcome eq \'Unqualified\')'+(legalEntityFilter ? 'and legalEntityCode eq \''+legalEntityFilter+'\'': '')+'&$orderby=systemCreationDate desc';
                            else
                                buildedEndpoint += '?$filter=statusCode eq \'97\' or finalOutcome eq \'Unqualified\''+(legalEntityFilter ? 'and legalEntityCode eq \''+legalEntityFilter+'\'': '')+'&$orderby=systemCreationDate desc';
                        break;
                        case "leadParked":
                            currentTitle += t("adminCenter:leadList:title2");
                            if (sourceCode)
                                buildedEndpoint += '?$filter=businessLineCode eq \''+sourceCode+'\' and (statusCode eq \'85\')'+(legalEntityFilter ? 'and legalEntityCode eq \''+legalEntityFilter+'\'': '')+'&$orderby=systemCreationDate desc';
                            else
                                buildedEndpoint += '?$filter=statusCode eq \'85\''+(legalEntityFilter ? 'and legalEntityCode eq \''+legalEntityFilter+'\'': '')+'&$orderby=systemCreationDate desc';
                        break;
                    }
                }
                else if (sourceCode){
                    currentTitle += ' '+sourceCode;
                    buildedEndpoint += '?$filter=businessLineCode eq \''+sourceCode+'\''+(legalEntityFilter ? 'and legalEntityCode eq \''+legalEntityFilter+'\'': '')+'&$orderby=systemCreationDate desc';
                }
                else{
                    currentTitle += t("adminCenter:leadList:title3");
                    buildedEndpoint += '?$orderby=systemCreationDate desc';
                }
            }
            else {
                if(workflowCode != null && workflowCode != ""){
                    currentTitle += " ("+workflowCodeName+")";
                    buildedEndpoint += '?$filter=statusCode eq \''+workflowCode+"\' and partnerId eq "+resp.id+(legalEntityFilter ? 'and legalEntityCode eq \''+legalEntityFilter+'\'': '')+"&$orderby=createdOn desc";
                }
                else if(activeLeads != null && activeLeads != ""){
                    currentTitle += t("adminCenter:leadList:title1");
                    buildedEndpoint += '?$filter=partnerId eq '+resp.id+' and (statusCode eq \'80\' or statusCode eq \'60\' or statusCode eq \'50\' or statusCode eq \'20\' or statusCode eq \'10\' or statusCode eq \'05\' or statusCode eq \'03\' or statusCode eq \'02\' or statusCode eq \'01\' or statusCode eq \'00\')'+(legalEntityFilter ? 'and legalEntityCode eq \''+legalEntityFilter+'\'': '')+'&$orderby=createdOn desc';
                }
                else{
                    currentTitle += t("adminCenter:leadList:title3");
                    buildedEndpoint += '?$filter=partnerId eq '+resp.id+(legalEntityFilter ? 'and legalEntityCode eq \''+legalEntityFilter+'\'': '')+'&$orderby=systemCreationDate desc';
                }
            }

            setEndpoint(buildedEndpoint);
            setListTitle(currentTitle);
            setDataStatus(SystemDataLoadingStatus.Loaded)
        })
        .catch((resp) => {
            setEndpoint("");
            setListTitle(t("adminCenter:leadList:listErrorText")!);
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error,t("adminCenter:leadList:text0001Err")!);
        });
    }
 
    useEffect(()=>{
        if(dataStatus === SystemDataLoadingStatus.ToLoad){
            adminService.getPartners()
            .then((resp) => {
                setPartners(resp);

                partnerService.getCrmLeadData()
                .then((resp) => {
                    setCrmSetup(resp);

                    buildListEndpoint();
                })
                .catch((jqXHR)=>{
                    ToastService.showMessage(MessageType.Error, t("adminCenter:leadList:text0002Err")!);
                })
            })
            .catch((jqXHR)=>{
                ToastService.showMessage(MessageType.Error, t("adminCenter:leadList:text0002Err")!);
            })
        }
    },[dataStatus]);

    const syncCalculatedFields = ()=>{
        setMessage(t("adminCenter:leadList:text0001Msg")!);
        setSyncStatus(SystemDataLoadingStatus.Loading);

        partnerService.syncCalculatedFields().then((resp)=>{
            ToastService.showMessage(MessageType.Success, t("adminCenter:leadList:text0002Msg")!);
            setSyncStatus(SystemDataLoadingStatus.Loaded);
            navigate(window.location.pathname+window.location.search);
        })
        .catch((jqXHR)=>{
            ToastService.showMessage(MessageType.Error, t("adminCenter:leadList:text0003Err")!+jqXHR.message);
            setSyncStatus(SystemDataLoadingStatus.Loaded);
        })
    };

    const syncLeadCalculatedFields = (leadId:number,leadName?:string)=>{
        setMessage(t("adminCenter:leadList:text0003Msg")!+leadName+' ...');
        setSyncStatus(SystemDataLoadingStatus.Loading);

        partnerService.syncLeadCalculatedFields(leadId).then((resp)=>{
            ToastService.showMessage(MessageType.Success, t("adminCenter:leadList:text0002Msg")!);
            setSyncStatus(SystemDataLoadingStatus.Loaded);
            navigate(window.location.pathname+window.location.search);
        })
        .catch((jqXHR)=>{
            ToastService.showMessage(MessageType.Error, t("adminCenter:leadList:text0003Err")!+jqXHR.message);
            setSyncStatus(SystemDataLoadingStatus.Loaded);
        })
    };

    const syncCRMLeadData = (leadId?:string,leadName?:string)=>{
        setSyncStatus(SystemDataLoadingStatus.Loading);

        if(leadId != undefined)
            setMessage(t("adminCenter:leadList:text0004Msg")!+leadName+' ...');
        else
            setMessage(t("adminCenter:leadList:text0005Msg")!);

        partnerService.syncCRMLeadData(leadId).then((resp)=>{
            if(leadId != undefined)
                ToastService.showMessage(MessageType.Success, t("adminCenter:leadList:text0002Msg")!);
            else
                ToastService.showMessage(MessageType.Success, t("adminCenter:leadList:text0002Msg")!);
            
            setSyncStatus(SystemDataLoadingStatus.Loaded);
            navigate(window.location.pathname+window.location.search);
        })
        .catch((jqXHR)=>{
            ToastService.showMessage(MessageType.Error, t("adminCenter:leadList:text0003Err")!);
            setSyncStatus(SystemDataLoadingStatus.Loaded);
        })
    };

    const reactivateLead = (leadId: number)=>{
        const promise = adminService.reactivateLead(leadId)
        .then(()=>{
            ToastService.showMessage(MessageType.Success, "OK");
        })
        .catch((err) =>{
            ToastService.showMessage(MessageType.Error, err);
        });
        ToastService.showPromiseMessage(promise, "Riattivazione in corso", "", "");
    }

    const syncCRMLeadInteraction = (leadId?:string,leadName?:string)=>{
        setSyncStatus(SystemDataLoadingStatus.Loading);

        if(leadId !== undefined)
            setMessage(t("adminCenter:leadList:text0006Msg")!);
        else
            setMessage(t("adminCenter:leadList:text0006Msg")!);

        partnerService.syncCRMLeadInteraction(leadId).then((resp)=>{
            if(leadId !== undefined)
                ToastService.showMessage(MessageType.Success, t("adminCenter:leadList:text0007Msg")!);
            else
                ToastService.showMessage(MessageType.Success, t("adminCenter:leadList:text0007Msg")!);
            
            setSyncStatus(SystemDataLoadingStatus.Loaded);
            navigate(window.location.pathname+window.location.search);
        })
        .catch((jqXHR)=>{
            ToastService.showMessage(MessageType.Error, t("adminCenter:leadList:text0004Err")!);
            setSyncStatus(SystemDataLoadingStatus.Loaded);
        })
    };

    const sendSetPasswordLink = (lead:Lead) =>{
        ToastService.showMessage(MessageType.Information, t("adminCenter:leadList:text0008Msg")!);
        let instance = HttpHelper.buildAxiosInstance();
        instance.post("api/crm/lead/send/password/link", lead)
            .then((resp) => {
                ToastService.showMessage(MessageType.Success, t("adminCenter:leadList:text0009Msg")!);
            })
            .catch((err) => {
                ToastService.showMessage(MessageType.Error, err.response.data.message);
            });
    }

    const saveActiveLeads = ()=>{
        setMessage(t("adminCenter:leadList:text0010Msg")!);
        setSyncStatus(SystemDataLoadingStatus.Loading);

        partnerService.saveActiveLeads().then((resp)=>{
            ToastService.showMessage(MessageType.Success, t("adminCenter:leadList:text0011Msg")!);
            setSyncStatus(SystemDataLoadingStatus.Loaded);
            navigate(window.location.pathname+window.location.search);
        })
        .catch((jqXHR)=>{
            ToastService.showMessage(MessageType.Error, t("adminCenter:leadList:text0005Err")!+jqXHR.message);
            setSyncStatus(SystemDataLoadingStatus.Loaded);
        })
    };

    const getLeadData = (lead: Lead): Promise<boolean> => {
        return new Promise((resolve, reject) =>{            
            adminService.getLeadConfigDetails(lead).then((details) => {
                setLeadConfigDetails(details);
        
                adminService.getIndustryTypes().then((respIndustries) => {
                    setIndustryTypes(respIndustries);
                    resolve(true);
                })
                .catch(err => {
                    ToastService.showMessage(MessageType.Error, err);
                    reject(false);
                })
            })
            .catch((err) => {
                ToastService.showMessage(MessageType.Error, err);
                reject(false);
            });
        })
    }
    
    const checkLeadModalData = (configuration: SaveLeadConfigChecklist): Object[] => {
        let validationErrors : object[] = [];

        if (! configuration.lead.firstname)
            validationErrors.push({
                reference: "firstname",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00001Text')!,
                applyToField: true
            });
        if (! configuration.lead.lastname)
            validationErrors.push({
                reference: "lastname",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00002Text')!,
                applyToField: true
            });
        if (! configuration.lead.companyName)
            validationErrors.push({
                reference: "companyName",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00003Text')!,
                applyToField: true
            });

        if (! configuration.lead.address)
            validationErrors.push({
                reference: "address",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00004Text')!,
                applyToField: true
            });
        if (! configuration.lead.zipCode)
            validationErrors.push({
                reference: "zipCode",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00004Text')!,
                applyToField: true
            });
        if (! configuration.lead.city)
            validationErrors.push({
                reference: "city",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00004Text')!,
                applyToField: true
            });
        if (! configuration.lead.county)
            validationErrors.push({
                reference: "county",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00004Text')!,
                applyToField: true
            });
        if (! configuration.lead.country)
            validationErrors.push({
                reference: "country",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00004Text')!,
                applyToField: true
            });

        if (! configuration.lead.email)
            validationErrors.push({
                reference: "email",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00004Text')!,
                applyToField: true
            });
        
        if (configuration.lead.full_Users_No === 0)
            validationErrors.push({
                reference: "fullUsersNo",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00004Text')!,
                applyToField: true
            });
        
        let riskIndex = configuration.details.businessNeedsRiskLines.findIndex((element) => element.description === "");
        if (riskIndex == 0){
            validationErrors.push({
                reference: "LostRisk1",
                error: t('leadDashboard:errors:text00001Err')!,
                applyToField: true
            });
        }

        if (configuration.lead.evaluationMode === 0)
            validationErrors.push({
                reference: "evaluationMode",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00007Text')!,
                applyToField: true,
            });

        setValidationErrors(validationErrors);
        return validationErrors;
    }
    
    const onSaveLeadModal = (configuration: SaveLeadConfigChecklist) => {
        if (checkLeadModalData(configuration).length > 0)
            return false;
   
        setLeadDataSaveInProgress(true);
        let client = HttpHelper.buildAxiosInstance();

        client.post('/api/system/lead/checklist/config/save', configuration).then(() => {
            setShowLeadModal(false);
            setLeadDataSaveInProgress(false);
            currentPage.current?.reloadData();
        })
        .catch(() => {
            ToastService.showMessage(MessageType.Error, t('leadDashboard:errors:text00002Err')!);
            setLeadDataSaveInProgress(false);
        });

        return true;
    }

    const openLeadOnboardingWorkSheet = (leadId: number) => {
        let client = HttpHelper.buildAxiosInstance();

        client.get('/api/businesscentral/lead/'+leadId+'/onboarding/worksheet').then((resp) => {            
            window.open(resp.data);
        })
        .catch((err) => {
            ToastService.showMessage(MessageType.Error, t('leadDashboard:errors:text00006Err')!);
        });
    }

    const getLeadOpenAPIData = (lead: Lead) => {        
        if (!lead.vat) {
            ToastService.showMessage(MessageType.Error, t('leadDashboard:errors:text00007Err')!);
            return;
        }

        const promise = adminService.getLeadOpenAPIData(lead.id).then((data)=>{
            Object.assign(document.createElement('a'),{ href: 'data:application/json;charset=UTF-8,' + encodeURIComponent(JSON.stringify(data.data[0], null, 2)), download: lead.companyName+ ' advanced data.json'}).click();
        });
        ToastService.showPromiseMessage(promise, "Download in progress", "Download completed", "Error during download", () => {currentPage.current?.reloadData()});
    }

    if (!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={message!}></Loader>;
    else
    return (
        <>
            <PageContainer endpoint={endpoint} pageType={SystemPageType.List} title={listTitle} ref={currentPage} cardRef={cardRef} backAllowed insertAllowed updateAllowed>               
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="CRMLead" label="Lead CRM" iconName="CRMServices" runOnRec isPromoted onClick={(req:any)=>{
                                window.open(crmSetup.baseAddress+req.crmLeadId);
                            }}>
                        </ActionEntry>
                        <ActionEntry name="LeadQualification" label={t("adminCenter:leadList:actions:leadQualification")} iconName="PartyLeader" isPromoted runOnRec onClick={(req:any)=>{
                            setSelectedLead(req);
                            getLeadData(req).then(()=>{setShowLeadModal(true)});
                        }}>
                        </ActionEntry>
                        <ActionEntry name="QualifierPage" label={t("adminCenter:leadList:actions:qualifierPage")} iconName="CheckList" isPromoted runOnRec onClick={(req:any)=>{
                                openLeadOnboardingWorkSheet(req.id);
                            }}>
                        </ActionEntry>
                        <ActionEntry name="OpenShopAddress" label={t("adminCenter:leadList:actions:openShopAddress")} iconName="ConfigurationSolid" runOnRec isPromoted onClick={(req:any)=>{
                                window.open(process.env.REACT_APP_SHOP_URL);
                            }}>
                        </ActionEntry>
                        <ActionEntry name="OpenQuoteOffer" label={t("adminCenter:leadList:actions:openQuoteOffer")} iconName="OpenEnrollment" runOnRec onClick={(req:any)=>{
                                navigate("/app/admin/administrative/salesHeader?saleHeaderCode="+req.salesHeaderTemplateCode);
                            }}>
                        </ActionEntry>
                        <ActionEntry name="OpenLeadIndustry" label={t("adminCenter:leadList:actions:openLeadIndustry")} iconName="OpenEnrollment" runOnRec onClick={(req:any)=>{
                                navigate("/app/admin/related/leadIndustry?companyName=" + req.companyName+"&leadId="+req.id);
                            }}>
                        </ActionEntry>
                        <ActionEntry name="OpenWorkflowList" label="Workflow Log" iconName="BacklogList" runOnRec onClick={(req:any)=>{
                                navigate("/app/partner/datasecurity/log?entityName=Lead&recordId="+req.systemId);
                            }}>
                        </ActionEntry>
                        <ActionEntry name="OpenFrontendLogEntryList" label="Frontend Log Entry" iconName="BacklogList" runOnRec onClick={(req:any)=>{
                                navigate("/app/admin/administrative/demolog?Id="+req.linkUserId+"&leadName="+req.companyName);
                            }}>
                        </ActionEntry>
                        <ActionEntry name="OpenEmailList" label={t("adminCenter:leadList:actions:openEmailList")} iconName="BacklogList" runOnRec onClick={(req: Lead)=>{
                                navigate('/app/admin/administrative/emailLog?email='+req.email +"&name="+req.companyName);
                            }}>
                        </ActionEntry>
                        <ActionEntry name="OpenLossRiskLine" label={t("adminCenter:leadList:actions:openLossRiskLine")} iconName="AlertSolid" runOnRec onClick={(req:any)=>{
                                 navigate("/app/admin/related/lossRiskLine?sourceTable=Lead&sourceId="+req.id+"");
                            }}>
                        </ActionEntry>
                        <ActionEntry name="OpenInteractionList" label={t("adminCenter:leadList:actions:openInteractionList")} iconName="MessageFill" runOnRec onClick={(req:any)=>{
                                 navigate("/app/admin/related/crmInteraction?leadId="+req.id+"");
                            }}>
                        </ActionEntry>
                        <ActionEntry name="OpenQualifiedAnswer" label={t("adminCenter:leadList:actions:openQualifiedAnswer")} iconName="SurveyQuestions" runOnRec onClick={(req:any)=>{
                                navigate("/app/admin/related/leadQualifiedAnswer?leadId="+req.id+"&leadName="+req.companyName);
                            }}>
                        </ActionEntry>
                        <ActionEntry name="OpenAutomatioProspect" label={t("adminCenter:leadList:actions:openAutomatioProspect")} iconName="TriggerAuto" onClick={(req:any)=>{
                                navigate("/app/admin/administrative/leadAutomationProspect");
                            }}>
                        </ActionEntry>
                    </ActionArea>
                    <ActionArea category={SystemActionCategory.Process}>
                        <ActionEntry name="renegerateLeadToken" label={t("adminCenter:leadList:actions:renegerateLeadToken")} iconName="UserSync" runOnRec onClick={(req:any)=>{
                                sendSetPasswordLink(req);
                            }}>
                        </ActionEntry>
                        <ActionEntry name="OpenBooking" label={t("adminCenter:leadList:actions:openBooking")} iconName="BookingsLogo" onClick={(req:any)=>{
                                setShowBookingModal(true);
                            }}>
                        </ActionEntry>
                        <ActionEntry name="GetOpenAPIData" label={t("adminCenter:leadList:actions:downloadOpenAPI")} iconName="CloudDownload" onClick={(req:any)=>{
                                getLeadOpenAPIData(req);
                            }}>
                        </ActionEntry>
                        <ActionEntry name="SyncCalculatedData" label={t("adminCenter:leadList:actions:syncCalculatedData")} iconName="Sync" onClick={(req:any)=>{
                                syncCalculatedFields();
                            }}>
                        </ActionEntry>
                        <ActionEntry name="syncSpecificCalculatedData" label={t("adminCenter:leadList:actions:syncSpecificCalculatedData")} iconName="Sync" runOnRec onClick={(req:any)=>{
                                syncLeadCalculatedFields(req.id, req.firstname+" "+req.lastname);
                            }}>
                        </ActionEntry>
                        <ActionEntry name="SyncCRMData" label={t("adminCenter:leadList:actions:syncCRMData")} iconName="Sync" onClick={(req:any)=>{
                                syncCRMLeadData();
                            }}>
                        </ActionEntry>
                        <ActionEntry name="syncSpecificCRMData" label={t("adminCenter:leadList:actions:syncSpecificCRMData")} iconName="Sync" runOnRec onClick={(req:any)=>{
                                syncCRMLeadData(req.id,req.firstname+" "+req.lastname);
                            }}>
                        </ActionEntry>
                        <ActionEntry name="SyncCRMInteractionAll" label={t("adminCenter:leadList:actions:syncCRMInteractionAll")} iconName="Sync" onClick={(req:any)=>{
                                syncCRMLeadInteraction();
                            }}>
                        </ActionEntry>
                        <ActionEntry name="SyncCRMInteractionLead" label={t("adminCenter:leadList:actions:syncCRMInteractionLead")} iconName="Sync" runOnRec onClick={(req:any)=>{
                                syncCRMLeadInteraction(req.id,req.firstname+" "+req.lastname);
                            }}>
                        </ActionEntry>
                        <ActionEntry name="SaveActiveLeads" label={t("adminCenter:leadList:actions:saveActiveLeads")} iconName="SaveAll" onClick={(req:any)=>{
                                saveActiveLeads();
                            }}>
                        </ActionEntry>
                        <ActionEntry name="ReactivateLead" label="Riattiva lead" iconName="RepeatAll" onClick={(req:any)=>{
                            reactivateLead(req.id);
                        }}></ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:statusCode")} fieldName="statusCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:firstname")} fieldName="firstname" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:lastname")} fieldName="lastname" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:companyName")} fieldName="companyName" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:email")} fieldName="email" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:phone")} fieldName="phone" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:partnerId")} fieldName="partnerId" minWidth={100} maxWidth={200} onRender={(item:any,index,column)=>{
                        return partners.find(partner => partner.id === item.partnerId)?.name;
                    }} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:liveMode")} fieldName="liveMode" minWidth={100} maxWidth={200} onRender={(item:any,index,column)=>{
                        if (item.liveMode === 0)
                            return t("adminCenter:leadList:fields:liveMode0")
                        else if (item.liveMode === 1)
                            return t("adminCenter:leadList:fields:liveMode1")
                        else if (item.liveMode === 2)
                            return t("adminCenter:leadList:fields:liveMode2")
                        else if (item.liveMode === 3)
                            return t("adminCenter:leadList:fields:liveMode3")
                        else if (item.liveMode === 4)
                            return t("adminCenter:leadList:fields:liveMode4")
                    }} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:vat")} fieldName="vat" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:partnerBusinessContractCode")} fieldName="partnerBusinessContractCode" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:businessLineCode")} fieldName="businessLineCode" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:origin")} fieldName="origin" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:campaignCode")} fieldName="campaignCode" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:score")} fieldName="score" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:budget")} fieldName="budget" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:turnover")} fieldName="turnover" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:logEntry")} fieldName="logEntry" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:createdOn")} fieldName="createdOn" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:marketingQualificationCarriedOut")} fieldName="marketingQualificationCarriedOut" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:lastInboundEmailDate")} fieldName="lastInboundEmailDate" minWidth={170} maxWidth={300} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:lastActivityDate")} fieldName="lastActivityDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:lastActivityType")} fieldName="lastActivityType" minWidth={90} maxWidth={90} onRender={(item: any, index, column) => {
                        if (item.lastActivityDate.substring(0,4) === "0001")
                            return ""

                        if (item.lastActivityType === 0)
                            return t("adminCenter:leadList:fields:lastActivityType0")
                        else if (item.lastActivityType === 1)
                            return t("adminCenter:leadList:fields:lastActivityType1")
                        else if (item.lastActivityType === 2)
                            return t("adminCenter:leadList:fields:lastActivityType2")
                        else if (item.lastActivityType === 3)
                            return t("adminCenter:leadList:fields:lastActivityType3")
                        else if (item.lastActivityType === 4)
                            return t("adminCenter:leadList:fields:lastActivityType4")
                        else if (item.lastActivityType === 5)
                            return t("adminCenter:leadList:fields:lastActivityType5")
                    }} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:lastWeekInteractions")} fieldName="lastWeekInteractions" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:lastMonthInteractions")} fieldName="lastMonthInteractions" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:demoBooked")} fieldName="demoBooked" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:demoDate")} fieldName="demoDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:demoCarriedOut")} fieldName="demoCarriedOut" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:demoCarriedOutBy")} fieldName="demoCarriedOutBy" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:demoFeedbackRequired")} fieldName="demoFeedbackRequired" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:demoFeedbackReceived")} fieldName="demoFeedbackReceived" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:trialRequestedDate")} fieldName="trialRequestedDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:trialStartDate")} fieldName="trialStartDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:riskLineExists")} fieldName="riskLineExists" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:riskLineHandled")} fieldName="riskLineHandled" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:offerLink")} fieldName="offerLink" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:salesHeaderTemplateCode")} fieldName="salesHeaderTemplateCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:leadList:fields:owningUserDisplayName")} fieldName="owningUserDisplayName" minWidth={200} maxWidth={300} />
                </PageDefinitionContainer>
            </PageContainer>
            <CommonBookingModal url="https://outlook.office365.com/owa/calendar/EOSOnline@eos-solutions.it/bookings/"
                                showModal={showBookingModal}
                                onSave={() => {
                                    setShowBookingModal(false);
                                }}
                                onCancel={() => {
                                    setShowBookingModal(false);
                                }} />
            <AdminLeadCard ref={cardRef} />
            <CommonLeadModal
                showModal={showLeadModal}
                savingData={leadDataSaveInProgress}
                canScroll={false}
                leadRef={selectedLead!}
                leadConfigDetail={leadConfigDetails}
                leadToLiveChecklist={new LeadToLiveChecklist()}
                industryTypes={industryTypes}
                validationErrors={validationErrors}
                onSave={onSaveLeadModal}
                onCancel={() =>{
                    setShowLeadModal(false);
                }}
                checkLeadData={(configuration: SaveLeadConfigChecklist) => {
                    checkLeadModalData(configuration);
                }}
            />
        </>
    );
}