import React, { createContext, useState } from "react";
import { Partner } from "../../Model/PartnerModels";

export interface IPartnerInformationContext {
    partner: Partner;
}

export interface IPartnerInformationContextProviderProps {
    partner: Partner;
}

let defaultValue : IPartnerInformationContext = {} as IPartnerInformationContext;
defaultValue.partner = new Partner();

export const PartnerInformationContext = createContext<IPartnerInformationContext>(defaultValue);
export const PartnerInformationContextProvider : React.FC<IPartnerInformationContextProviderProps> = (props) => {
    const [partner, setPartner] = useState(props.partner);

    return (
        <PartnerInformationContext.Provider value={{
            partner: partner
        }}>
            {props.children}
        </PartnerInformationContext.Provider>
    );
}