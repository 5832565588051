import { DefaultButton, Dialog, DialogFooter, FontWeights, IButtonStyles, IColumn, ICommandBarItemProps, IconButton, IDetailsList, IModalProps, ISelection, Label, MarqueeSelection, mergeStyles, Modal, Panel, PanelType, PrimaryButton, RefObject, SearchBox, Selection, SelectionMode, Separator, ShimmeredDetailsList, Stack, Text } from "@fluentui/react";
import { Guid } from "guid-typescript";
import React, { Children, forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Accordion } from "react-accessible-accordion";
import { useNavigate, useParams } from "react-router-dom";
import { Type } from "typescript";
import { CommonFunctions } from "../../../ApplicationCode/Common/CommonFunctions";
import { CommonTemplateBuilder, ITemplateBuilderOptions } from "../../../ApplicationCode/Common/CommonTemplateBuilder";
import { BookmarkManagement } from "../../../ApplicationCode/Common/BookmarkManagement";
import { CryptoHelper } from "../../../Core/Crypto/CryptoHelper";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { SystemCore } from "../../../Core/System/SystemCore";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { OperationType, SystemDataLoadingStatus, SystemOperation, SystemPage, SystemPageType, SystemTableRelationEntry } from "../../../Model/SystemModels";
import { CommonActionBar } from "../CommonActionBar/CommonActionBar";
import { CommonShareDialog, IShareDialogOptions } from "../CommonShareDialog/CommonShareDialog";
import { Loader } from "../Loader/Loader";
import { ConfirmDialog } from "./Dialog/ConfirmDialog";
import { ContentManagement } from "../../../ApplicationCode/Common/ContentManagement";
import { t } from "i18next";
import { Lead } from "../../../Model/PartnerModels";


const pageListContainerClassName = mergeStyles([{
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20
}]);

const pageListMainContainerClassName = mergeStyles([{
    width: '98%',
    backgroundColor: 'white',
    border: '1px solid #d9d9d9',
    boxShadow: '6px 7px 5px 0px rgba(230,230,230,1)',
    padding: 10,
    manHeight: 'calc(100vh - 100px)'
}]);

const pageListTitleContaunerClassName = mergeStyles([{
    marginBottom: 10,
    paddingTop: 20,
    paddingLeft: 20,
    width: '98%'
}]);

const pageListSeparatorStyles = {
    root: [{
      selectors: { // add selectors here
        '::before': {
          background: '#bbb',
        },
      },
    }]
};

const pageListTitleClassName = mergeStyles([{
    fontSize: 20,
    fontWeight: 500
}]);

const pageCardContentBodyClassName = mergeStyles([{
    width: '100%',
    overflowY: "auto",
    overflowX: "hidden"
}]);

const pageCardFormContentClassName = mergeStyles([{
    width: '100%'
}]);

const pageCardCustomFormContentClassName = mergeStyles([{
    width: '100%',
    height: '100%',
    overflowY: 'auto !important'
}]);

const pagePanelCardContentClassName = mergeStyles([{
    marginTop: 20
}]);


export interface IPageContainerProps extends React.PropsWithChildren<unknown> {
    pageType: SystemPageType;
    endpoint: string;
    title: string;
    cardRef?: React.RefObject<PageContainerBase>;
    showModal?: boolean;
    insertAllowed?: boolean;
    showAllowed?: boolean;
    updateAllowed?: boolean;
    deleteAllowed?: boolean;
    backAllowed?: boolean;
    refreshAllowed?: boolean;
    shareAllowed?: boolean;
    hideFooter?: boolean;
    customRecord?: any;
    blockBookmark?: boolean;
    customOperationType?: SystemOperation;
    onSuccess?: (operationType: SystemOperation, record: any, callback?: () => void) => void;
    onCancel?: () => void;
    onNewRecord?: () => void;
    onUpdateRecord?: (record: any) => void;
    onDeleteRecord?: (record: any) => void;
    OnOpenPage?: (record: any, operationType: SystemOperation) => any;
    onClosePage?: (record: any, operationType: SystemOperation) => any;
}

const defaultProps: IPageContainerProps = {
    pageType: SystemPageType.List,
    endpoint: "",
    title: "",
    backAllowed: true,
    refreshAllowed: true,
    shareAllowed: true,
}

export type PageContainerBase = {
    openPage: (operationType: SystemOperation, record?: any) => void;
    closePage: () => void;
    setRecord: (record: any) => void;
    addAfterSaveCallback: (callback: () => void) => void;
    handleError: () => void;
    reloadData: () => void;
}

const PageContainer = forwardRef((props: IPageContainerProps, ref?: Ref<PageContainerBase>) => { 
    const [record, setRecord] = useState({} as any);
    const [recordSet, setRecordSet] = useState([]);
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [structureDataStatus, setStructureDataStatus] = useState(SystemDataLoadingStatus.Loaded);
    const [selectedSet, setSelectedSet] = useState<any>([]);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [tableRelationEntries, setTableRelatinEntries] = useState([] as SystemTableRelationEntry[]);
    const [tableRelationEntriesWithFilters, setTableRelatinEntriesWithFilters] = useState([] as SystemTableRelationEntry[]);
    const [showCard, setShowCard] = useState(false);
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [operationType, setOperationType] = useState(SystemOperation.View);
    const [onAfterSaveCallBack, setOnAfterSaveCallBack] = useState(() => () => console.log("default ooops"));
    const [isCallbackSet, setIsCallbackSet] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [showList, setShowList] = useState(true);
    const updating = useRef<boolean>(false);
    const [selectionDataStatus,setSelectionDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const buttonStyles = { root: { marginRight: 8 } };
    const navigate = useNavigate();
    const bookmarkManagement = new BookmarkManagement(navigate);
    const [selection, setSelection] = useState<any>(new Selection({
        onSelectionChanged: () => {
            const bookmark = HttpHelper.getParameter('bookmark');
            const currentSelection = selection.getSelection();

            if ((currentSelection.length > 1 || currentSelection.length === 0)){
                if(!updating.current && ((props.blockBookmark === undefined) || !props.blockBookmark))
                    bookmarkManagement.setCurrentBookmark('bookmark',undefined);
                
                //handleOldBookmark();
            }
            else if(currentSelection.length !== 0){
                let systemId = selection.getSelection()[0].systemId;
                bookmarkManagement.setCurrentBookmark('bookmark',systemId);
            }
            
            setSelectedSet(selection.getSelection());
        },
        getKey: (item: any) : string | number => {
            return item.systemId;
        }
    }));

    useImperativeHandle(ref, () => ({
        openPage(operationType: SystemOperation, _record?: any) {
            setOperationType(operationType);
            if (props.OnOpenPage != undefined)
            {
                let recordToPass: any = {};
                if (_record != undefined)
                    recordToPass = _record;

                let result = props.OnOpenPage(recordToPass, operationType);
                if (result != undefined)
                    setRecord(result);
            } 

            setShowCard(true);
        },
        reloadData: () => {
            setDataStatus(SystemDataLoadingStatus.ToLoad);
        },
        handleError() {
            setSaveInProgress(false);
        },
        closePage() {
            setSaveInProgress(false);
            clearRegistryData();
            setShowCard(false);
        },
        setRecord(record: any) {
            setRecord(record);
        },
        OnOpenPage() {
        },
        addAfterSaveCallback(callback: () => void) {
            if (! isCallbackSet)
            {
                setIsCallbackSet(true);
                setOnAfterSaveCallBack(() => callback);
            }
        }
    }));

    const handlePageSave = () => {
        updating.current = true;
        // Handle required fields check
        let client = HttpHelper.buildAxiosInstance();
        switch(operationType)
        {
            case SystemOperation.Insert:
                client.post(props.endpoint, record)
                    .then((resp) => {
                        onAfterPageSuccess();
                    })
                    .catch((err) => {
                        setSaveInProgress(false);
                        updating.current = false;
                        if (err.response.data.message != undefined)
                            ToastService.showMessage(MessageType.Error, err.response.data.message);
                    });
                break;
            case SystemOperation.Update:
                client.put(props.endpoint + "/" + record.systemId, record)
                    .then((resp) => {
                        onAfterPageSuccess();
                    })
                    .catch((err) => {
                        setSaveInProgress(false);
                        updating.current = false;
                        if (err.response.data.message != undefined)
                            ToastService.showMessage(MessageType.Error, err.response.data.message);
                        else
                            ToastService.showMessage(MessageType.Error, err);
                    });
                break;
            case SystemOperation.Delete:
                client.delete(props.endpoint + "/" + record.systemId, undefined)
                    .then((resp) => {
                        onAfterPageSuccess();
                    })
                    .catch((err) => {
                        setSaveInProgress(false);
                        updating.current = false;
                        if (err.response.data.message != undefined)
                            ToastService.showMessage(MessageType.Error, err.response.data.message);
                        else
                            ToastService.showMessage(MessageType.Error, err);
                    });
                break;
            case SystemOperation.View:
                setShowCard(false);
                updating.current = false;
                break;
        }
    }

    const onAfterPageSuccess = () => {
        updating.current = true;
        clearRegistryData();
        setShowCard(false);
        setSaveInProgress(false);
        ToastService.showMessage(MessageType.Success, t('common:PageContainer:Text00001Lbl'));
        onAfterSaveCallBack!();
    }

    const handleOnSave = () => {
        updating.current = true;
        setSaveInProgress(true);
        if (props.onSuccess != undefined)
            props.onSuccess(operationType, record, onAfterPageSuccess);
        else
            handlePageSave();
    }

    const handleOnCancel = () => {
        if (props.onCancel != undefined)
            props.onCancel();
        else
        {
            clearRegistryData();
            setShowCard(false);
        }
    }

    const handleOnAfterSave = () => {
        updating.current = true;
        setDataStatus(SystemDataLoadingStatus.ToLoad);
    }

    const isRecordSelected = () : boolean => {
        return (selectedSet.length == 1);
    }

    const getRecordSelected = () : any => {
        if (props.pageType == SystemPageType.List)
            return selectedSet[0];
        else if ([SystemPageType.Card, SystemPageType.CardPanel,SystemPageType.CardCustom].indexOf(props.pageType) >= 0)
            return record;
    }

    const setNastedProperty = (record: any, fieldName: string, index: number, value: any) :  any => {
        let nestedPropertyNames = fieldName.split('.');
        if (index == nestedPropertyNames.length)
            return value;

        let nestedPropertyValue: any = {};
        let nestedKeyIndex = Object.keys(record).indexOf(nestedPropertyNames[index]);
        if (nestedKeyIndex >= 0)
            nestedPropertyValue = Object.values(record)[nestedKeyIndex];        
        
        return Object.defineProperty(
            record, 
            nestedPropertyNames[index],
            {
              value: setNastedProperty(nestedPropertyValue, fieldName, index + 1, value),
              writable: true,
              enumerable: true
            }
        );
    }

    const handleRecordChange = (record: any, fieldName: string, value: any) : any => {
        if (fieldName.indexOf('.') >= 0)
        record = setNastedProperty(record, fieldName, 0, value);
        else
        {
            record = Object.defineProperty(
                record, 
                fieldName, 
                {
                  value: value,
                  writable: true,
                  enumerable: true
                }
            );
        }

        return record;
    }
    
    const getRecordFieldValue = (fieldName: string) => {
        if (fieldName.indexOf('.') >= 0)
        {
            let nestedPropetyNames = fieldName.split('.');
            let nestedValue = record;
            
            for(let i = 0; i < nestedPropetyNames.length; i++)
            {
                let keyIndex = Object.keys(nestedValue).indexOf(nestedPropetyNames[i]);
                if (keyIndex < 0)
                    return;
                nestedValue = Object.values(nestedValue)[keyIndex];
            }

            return nestedValue;
        }
        else
        {
            let keyIndex = Object.keys(record).indexOf(fieldName);
            let valueIndex = Object.values(record)[keyIndex];
            return valueIndex;
        }
    }

    const setRecordData = (newRecord: any) => {
        let aus = JSON.parse(JSON.stringify(newRecord));
        setRecord(aus);
    }

    const setTableRelationData = (tableRelationEntry: SystemTableRelationEntry) => {
        let newArray : SystemTableRelationEntry[] = [];
        for (let i = 0; i < tableRelationEntries.length; i++)
        {
            if (tableRelationEntries[i].fieldName === tableRelationEntry.fieldName)
                newArray.push(tableRelationEntry);    
            else
                newArray.push(tableRelationEntries[i]);
        }
    
        setTableRelatinEntries(newArray);
    }

    const getTableRelationData = (fieldName?: string) : SystemTableRelationEntry | undefined | SystemTableRelationEntry[] => {
        if (fieldName == undefined)
            return tableRelationEntries;
        
        let result = tableRelationEntries.filter(e => e.fieldName == fieldName);
        if (result.length > 0)
            return result[0];

        return undefined;
    }

    const clearRegistryData = () => {
        setIsCallbackSet(false);
        setTableRelatinEntries([]);
    }

    const getPreviewsPage = () => {
        navigate(-1);
    }

    const shareCurrentContent = (shareCard: boolean) => {    
        let urlParams = new URLSearchParams(window.location.search);

        let urlToCopy = window.location.origin+window.location.pathname;
        if(urlParams.toString() !== "")
            urlToCopy += "?"+urlParams.toString();
        
        if(shareCard){
            if(urlToCopy.lastIndexOf('?') !== -1)
                urlToCopy += "&openCard=true&systemOperation="+operationType;
            else
                urlToCopy += "?openCard=true&systemOperation="+operationType;
        }
        
        navigator.clipboard.writeText(urlToCopy).then(()=>{
            ToastService.showMessage(MessageType.Success, "Link copiato negli appunti");
        })
        .catch(()=>{
            ToastService.showMessage(MessageType.Error, "impossibile copiare il link negli appunti");
        })
    }

    const handleBookmarkValue = () => {
        if (props.pageType !== SystemPageType.List)
            return;
        
        let bookmark = HttpHelper.getParameter("bookmark");
        let systemOperationType = HttpHelper.getParameter("systemOperation");
        let openCard = HttpHelper.getParameter("openCard");

        if(bookmark != null){
            let currentBookmark = bookmarkManagement.getCurrentBookmark(bookmark!)
            if(currentBookmark !== undefined){
                selection.toggleAllSelected();
                selection.setItems(recordSet);
                selection.setKeySelected(currentBookmark, true, true);
                //selection.setAllSelected(true);
            }
            else if (openCard)
                ToastService.showMessage(MessageType.Error, "Nessun record selezionato");
        }

        if(openCard === "true" && openCard != null){
            if(props.cardRef !== undefined) {
                let currentOperationType = handleOperationType(parseInt(systemOperationType));

                if(currentOperationType !== SystemOperation.None){
                    if(currentOperationType !== SystemOperation.Insert && bookmark != null){
                        props.cardRef?.current?.setRecord(selection.getSelection()[0]);
                        props.cardRef?.current?.openPage(currentOperationType);
                    }
                    else if(currentOperationType !== SystemOperation.Insert)
                        ToastService.showMessage(MessageType.Error, "Nessun record selezionato");  
                    else
                        props.cardRef?.current?.openPage(currentOperationType);
                }
            }
            else
                ToastService.showMessage(MessageType.Error, "La pagina è di sola visualizzazione");
        }
        
        deleteShareParameters();
        setShowList(true);
        updating.current = false;
        //setSelectionDataStatus(SystemDataLoadingStatus.Loaded);
    }

    const handleOperationType = (operationType:number) : SystemOperation => {
        let currOperation: SystemOperation = SystemOperation.None;

        switch(operationType){
            case 0:
                if(props.insertAllowed)
                    currOperation =  SystemOperation.Insert;
                break;
            case 1:
                if(props.updateAllowed)
                    currOperation = SystemOperation.Update;
                break;
            case 2:
                if(props.showAllowed)
                    currOperation = SystemOperation.View;
                break;
            default:
                if(props.showAllowed)
                    currOperation = SystemOperation.View;
                break;
        }

        return currOperation;
    }

    const deleteShareParameters = () => {
        let urlParams = new URLSearchParams(window.location.search);
        urlParams.delete('openCard');
        urlParams.delete('systemOperation');

        navigate("?"+urlParams.toString(),{replace:true});
    }

    if (props.cardRef != undefined)
        props.cardRef.current?.addAfterSaveCallback(handleOnAfterSave);

    const builderOptions: ITemplateBuilderOptions = {} as ITemplateBuilderOptions;
    builderOptions.getPreviewsPage = getPreviewsPage;
    builderOptions.getSelectedRecord = getRecordSelected;
    builderOptions.hasSelectedRecord = isRecordSelected;
    builderOptions.handleRecordChange = handleRecordChange;
    builderOptions.getRecordFieldValue = getRecordFieldValue;
    builderOptions.setTableRelationData = setTableRelationData;
    builderOptions.getTableRelationData = getTableRelationData;
    builderOptions.setRecord = setRecordData;
    builderOptions.getRecordSet = () => {
        return selectedSet;
    }
    builderOptions.setRecordSet = (records: any) => {
        setSelectedSet(records);
    }    
    builderOptions.reloadDataset = () => {
        updating.current = true;
        setDataStatus(SystemDataLoadingStatus.ToLoad);
    };
    builderOptions.shareCurrentContent = () => {
        shareCurrentContent(false);
    };
    builderOptions.pagerConfiguration = props;
    builderOptions.cardRef = props.cardRef;
    builderOptions.saveInProgress = saveInProgress;
    builderOptions.operationType = operationType;
    builderOptions.dataStatus = dataStatus;
    const templateBuilder = new CommonTemplateBuilder(builderOptions);



    useEffect(() => {
        switch(props.pageType)
        {
            case SystemPageType.List:
                if (dataStatus == SystemDataLoadingStatus.ToLoad)
                {
                    setDataStatus(SystemDataLoadingStatus.Loading);
                    CommonFunctions.loadDataFromUrl(props.endpoint)
                    .then((resp) => {
                        setRecordSet(resp);
                        setDataStatus(SystemDataLoadingStatus.Loaded);
                        handleBookmarkValue();
                    }) 
                    .catch((err) => {
                        ToastService.showMessage(MessageType.Error, err.message);
                    });
                }
                break;
            case SystemPageType.CardCustom:
                if (dataStatus == SystemDataLoadingStatus.ToLoad)
                {
                    setDataStatus(SystemDataLoadingStatus.Loading);
                    if(props.customRecord !== undefined && props.customRecord !== null){
                        setRecord(props.customRecord);
                        if(props.customOperationType !== undefined && props.customOperationType !== null){
                            setOperationType(props.customOperationType);
                            setDataStatus(SystemDataLoadingStatus.Loaded);
                        }
                        else
                            setDataStatus(SystemDataLoadingStatus.Error);
                    }
                    else
                        setDataStatus(SystemDataLoadingStatus.Error);
                }
                break;
            default:
                break;
        }
    }, [dataStatus]);

    useEffect(() => {
        if (showCard) {
            setDataStatus(SystemDataLoadingStatus.Loading);

            let pageChildren = Children.toArray(props.children);
            templateBuilder.getFieldsWithTableRelation(pageChildren)
                .then((entries) => {
                    setTableRelatinEntries(entries);
                    setDataStatus(SystemDataLoadingStatus.Loaded);
                })
                .catch((message) => {
                    ToastService.showMessage(MessageType.Error, message);
                    setShowCard(false);
                });
        }
    }, [showCard])

    useEffect(() => {
        if (props.pageType == SystemPageType.Card)
           setRecord(record);
    }, [record])

    useEffect(() => {
        if (props.pageType == SystemPageType.CardCustom) {
            setDataStatus(SystemDataLoadingStatus.Loading);

            let pageChildren = Children.toArray(props.children);
            templateBuilder.getFieldsWithTableRelation(pageChildren)
                .then((entries) => {
                    setTableRelatinEntries(entries);
                    setDataStatus(SystemDataLoadingStatus.Loaded);
                })
                .catch((message) => {
                    ToastService.showMessage(MessageType.Error, message);
                    setShowCard(false);
                });
        }
    }, [record])

    /*useEffect(() => {
        if (props.pageType == SystemPageType.List)
            if(dataStatus == SystemDataLoadingStatus.Loaded){
                const bookmark = HttpHelper.getParameter("bookmark");
                selection.setKeySelected(bookmarkManagement.getCurrentBookmark(bookmark!)!, true, true);
            }
    }, [selectionDataStatus])*/

    const arrayChildren = Children.toArray(props.children);
    if (props.pageType == SystemPageType.List)
    {
        const pageData = templateBuilder.buildListTemplate(arrayChildren);

        if (! SystemCore.isFinishedDataStatus(structureDataStatus))
            return <Loader text={t('common:PageContainer:Loader:messageContent1')!}></Loader>;
        else
            return (
                <Stack className={pageListContainerClassName}>
                    <Stack.Item className={pageListMainContainerClassName}>
                        <CommonActionBar items={pageData.actions} farItems={pageData.farActions} />
                        <Stack className={pageListTitleContaunerClassName}>
                            <Stack.Item>
                                <Stack horizontal>
                                    <Stack.Item style={{
                                        width: '50%'
                                    }}>
                                        <Text className={pageListTitleClassName}>{props.title}</Text>
                                    </Stack.Item>
                                    <Stack.Item align="center" style={{
                                        width: '50%',
                                        textAlign: 'right'
                                    }}>
                                       {templateBuilder.filterItems(recordSet, searchText).length} record
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <Separator styles={{
                                    root: {
                                        padding: 0,
                                        selectors: { // add selectors here
                                            '::before': {
                                              background: '#bbb',
                                            },
                                          }
                                    }
                                }}/>
                            </Stack.Item>
                            <Stack.Item>
                                <SearchBox onChange={(e, newValue) => {
                                    setSearchText(newValue!)
                                }} placeholder={t('common:PageContainer:SearchBox:placeholder')!} iconProps={{iconName: "Filter"}} styles={{root: {
                                    width: '100%'            
                                }}} 
                                value={searchText} />
                            </Stack.Item>
                        </Stack>
                        {
                            showList ? 
                                <MarqueeSelection selection={selection}>
                                    <ShimmeredDetailsList 
                                        items={templateBuilder.filterItems(recordSet, searchText)}
                                        setKey={"systemId"}
                                        columns={pageData.columns}
                                        selection={selection}
                                        selectionMode={SelectionMode.multiple}
                                        enableShimmer={! SystemCore.isFinishedDataStatus(dataStatus)}
                                        detailsListStyles={{
                                            root: {
                                                overflowX: 'scroll',
                                                selectors: {
                                                '& [role=grid]': {
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'start'
                                                }
                                                },
                                            },
                                            headerWrapper: {
                                                flex: '0 0 auto',
                                            },
                                            contentWrapper: {
                                                maxHeight: 'calc(100vh - 320px)',
                                                flex: '1 1 auto',
                                                overflowY: 'auto',
                                                overflowX: 'hidden',
                                            }
                                        }} 
                                    />
                                </MarqueeSelection>
                            :

                            <></>
                        }
                    </Stack.Item>
                </Stack>
            );
    }
    else
    {
        if(props.pageType == SystemPageType.CardCustom){
            return(
                <Stack verticalAlign="space-between" verticalFill key="formContainer1" >
                    <Stack.Item styles={{root:{width:'100% !important'}}}>
                        <PrimaryButton
                            disabled={props.customRecord !== undefined && props.customRecord !== null ? false : true}
                            styles={{
                                root:{
                                    marginBottom:10,
                                    width:'100%'
                                }
                            }} 
                            onClick={()=>props.onSuccess!(props.customOperationType!,record)}
                        >
                            Salva
                        </PrimaryButton>
                    </Stack.Item>
                    <Stack.Item className={pageCardCustomFormContentClassName}>
                        {templateBuilder.builCardTemplate(arrayChildren)}
                    </Stack.Item>
                </Stack>
            )
        }
        else if (operationType == SystemOperation.Delete)
        {
            return (
                <ConfirmDialog title="Cancellazione dati"
                               subText={t('common:PageContainer:ConfirmDialog:subText')!}
                               showDialog={showCard}
                               cancelButtonText={t('common:PageContainer:ConfirmDialog:cancelButtonText')!}
                               confirmButtonText={t('common:PageContainer:ConfirmDialog:confirmButtonText')!}
                               onCancel={handleOnCancel}
                               onConfirm={handleOnSave} />
            );
        }
        else
        {
            if (props.pageType == SystemPageType.Card)
            {
                const pageCardModalProps: Partial<IModalProps> = {
                    isBlocking: true,
                    dragOptions: undefined,
                    styles: {
                        main: {
                            width: '95% !important',
                            maxWidth: 'calc(95%) !important',
                            position: 'absolute',
                            top: 50
                        }
                    }
                };

                const pageCardModalHeaderClassName = mergeStyles([{
                    flex: '1 1 auto',
                    color: "#000",
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: FontWeights.semibold,
                    padding: '8px 8px 10px 14px',
                }]);

                const pageCardModalTitleClassName = mergeStyles([{
                    color: "#000",
                    fontWeight: FontWeights.semibold,
                    margin: '0',
                }]);
                
                const iconButtonStyles: Partial<IButtonStyles> = {
                    root: {
                        color: '#201F1E',
                        marginLeft: 'auto',
                        marginTop: '4px',
                        marginRight: '10px',
                    },
                    rootHovered: {
                        color: '#000',
                    }
                };

                let footer: JSX.Element = <></>;
                if (! props.hideFooter)
                {
                    footer = <Stack
                                verticalAlign="center"
                                verticalFill
                                styles={{
                                    root: {
                                        padding: 25,
                                    }
                                }}
                             >
                                <Stack
                                    key="modalFooterContent"
                                    horizontal
                                    horizontalAlign="end"
                                >
                                    <Stack horizontal horizontalAlign="space-between">
                                        <DefaultButton styles={{root:{marginRight:15}}} text={t('common:PageContainer:card:defaultButtonText')!} onClick={handleOnCancel} disabled={saveInProgress} />
                                        <PrimaryButton text={t('common:PageContainer:card:primaryButtonText')!} onClick={handleOnSave} disabled={saveInProgress} />
                                    </Stack>
                                </Stack>
                             </Stack>;
                }
                return (
                    <Modal 
                        isOpen={showCard}
                        onDismiss={handleOnCancel}
                        isBlocking={false}
                        dragOptions={undefined}
                        styles = {{
                            main: {
                                width: '95% !important',
                                maxWidth: '95% !important',
                                height: 'calc(100vh - 60px) !important',
                                overflowY: 'hidden !important',
                                position: 'absolute',
                                top: 50
                            }
                        }}
                    >
                        <Stack className={pageCardModalHeaderClassName} horizontal horizontalAlign="space-between">
                            <Stack.Item>
                                <Text variant="xLarge" className={pageCardModalTitleClassName}>{props.title}</Text>
                            </Stack.Item>
                            <Stack.Item>  
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={{ iconName: 'Share' }}
                                    ariaLabel="Share current content"
                                    onClick={()=>shareCurrentContent(true)}
                                />  
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={{ iconName: 'Cancel' }}
                                    ariaLabel="Close popup modal"
                                    onClick={handleOnCancel}
                                />
                            </Stack.Item>
                        </Stack>
                        <Stack
                            styles={{
                                root:{
                                    height: 'calc(100vh - 190px)',
                                    overflowY: 'auto',
                                    padding: 15
                                }
                            }}
                        >
                            {templateBuilder.builCardTemplate(arrayChildren)}
                        </Stack>
                        {footer}
                    </Modal>
                );
            }
            else if (props.pageType == SystemPageType.CardPanel)
            {
                const onRenderFooterContent = () => {
                    return (
                    <div>
                        <PrimaryButton onClick={handleOnSave} 
                                        styles={buttonStyles}>
                            Salva
                        </PrimaryButton>
                        <DefaultButton onClick={handleOnCancel}>
                            Annulla
                        </DefaultButton>
                    </div>
                    );
                }
                
                const handlePanelDismiss = () => {
                    setShowConfirmDialog(true);
                }

                return (
                    <>
                        <ConfirmDialog cancelButtonText="Annulla"
                                    confirmButtonText="Conferma"
                                    showDialog={showConfirmDialog}
                                    title="Modifiche non salvate"
                                    subText="Attenzione sono presenti modifiche non salvate. Vuoi continuare?"
                                    onConfirm={() => {
                                        setShowConfirmDialog(false);
                                        handleOnCancel();
                                    }}
                                    onCancel={() => {
                                        setShowConfirmDialog(false);
                                    }} />
                        <Panel
                            isLightDismiss={true}
                            isOpen={showCard}
                            headerText={props.title}
                            closeButtonAriaLabel="Close"
                            isFooterAtBottom={true}
                            onRenderFooterContent={onRenderFooterContent}
                            onDismiss={handlePanelDismiss}
                            hasCloseButton={false}
                            type={PanelType.medium}
                        >
                            <Stack className={pagePanelCardContentClassName}>
                                {templateBuilder.builCardTemplate(arrayChildren)}
                            </Stack>
                        </Panel>
                    </>
                )
            }
            else
                return <></>;
        }
    }
});

PageContainer.defaultProps = defaultProps;

export default PageContainer;