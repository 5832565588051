import { Checkbox, ComboBox, DefaultButton, Dialog, DialogFooter, IComboBoxOption, IModalProps, IStackTokens, mergeStyles, MessageBar, PrimaryButton, Stack, TextField } from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from "react-accessible-accordion";
import { CustomerInformationContext } from "../../../ApplicationCode/Customer/CustomerInformationContext";
import { OperationType, SystemFieldType, SystemPageType, SystemUser } from "../../../Model/SystemModels";
import '../../../../node_modules/react-accessible-accordion/dist/fancy-example.css';
import { Customer, Partner, SalesHeaderTemplate } from "../../../Model/PartnerModels";
import { PartnerInformationContext } from "../../../ApplicationCode/Partner/PartnerInformationContext";
import PageContainer from "../../Common/Template/PageContainer";
import { CardGroup } from "../../Common/Template/Card/CardGroup";
import { CardField } from "../../Common/Template/Card/CardField";

const modalProps: Partial<IModalProps> = {
    isBlocking: true,
    dragOptions: undefined,
    styles: {
        main: {
            width: '95% !important',
            maxWidth: 'calc(95%) !important'
        }
    }
  };

const contentBodyClassName = mergeStyles([{
    width: '100%',
    overflowY: "auto",
    overflowX: "hidden"
}]);

const modalContentClassName = mergeStyles([{
    width: '100%'
}]);

const formContentClassName = mergeStyles([{
    width: '100%'
}]);

const formContentEntryClassName = mergeStyles([{
    width: '100%'
}]);

const rowContentClassName = mergeStyles([{
    width: '100%',
    marginBottom: 7
}]);

const rowContentEntryClassName = mergeStyles([{
    width: '48%'
}]);

const textFieldClassName =  mergeStyles([{
    width: '100%',
    maxWidth: 600
}]);

const rowContentStackToken: IStackTokens = {
    childrenGap: 50
};

const formContentStackToken: IStackTokens = {
    childrenGap: 20
};

export interface IPartnerCustomerCardProps {
    showDialog: boolean;
    customer: Customer;
    onSuccessModal: (customer: Customer) => void;
    onCancelModal: () => void;
}

export const PartnerCustomerCard : React.FC<IPartnerCustomerCardProps> = (props) => {

    return (
        <PageContainer showModal={props.showDialog} pageType={SystemPageType.Card} title="Scheda Customer" endpoint="odata/Customer">
            <CardGroup name="general" label="Generale" isOpen>
                <CardField name="companyName" type={SystemFieldType.String} label="Ragione sociale" />
                <CardField name="mainContact" type={SystemFieldType.String} label="Contatto principale" />
                <CardField name="email" type={SystemFieldType.String} label="Email" />
                <CardField name="phone" type={SystemFieldType.String} label="Telefono" />
                <CardField name="customerInternalRefNo" type={SystemFieldType.String} label="Rif. Codice cliente interno" />
                <CardField name="domain" type={SystemFieldType.String} label="Nome tenant" />
                <CardField name="hasTenant" type={SystemFieldType.Boolean} label="Tenant esistente" /> 
            </CardGroup>
            <CardGroup name="address" label="Indirizzo" isOpen>
                <CardField name="address" type={SystemFieldType.String} label="Indirizzo" />
                <CardField name="zipCode" type={SystemFieldType.String} label="CAP" />
                <CardField name="city" type={SystemFieldType.String} label="Città" />
            </CardGroup>
        </PageContainer>
    );

}