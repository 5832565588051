import { useRef } from "react";
import { SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminApplicationTaskCard } from "../AdminApplicationTaskCard/AdminApplicationTaskCard";

export const AdminApplicationTaskList : React.FC = () => {
    
    const cardRef = useRef<PageContainerBase>(null);
    
    return (
        <>
            <PageContainer endpoint="/odata/application/task" pageType={SystemPageType.List} title="Entità Application Task" backAllowed insertAllowed deleteAllowed updateAllowed cardRef={cardRef}>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Codice" fieldName="code" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Codice Gruppo" fieldName="groupCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Priorità" fieldName="priority" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Tipologia Task" fieldName="type" minWidth={150} maxWidth={250} onRender={(item: any, index, column) => {
                        if (item.forUserType === 0)
                            return "Per tutti"
                        else if (item.forUserType === 1)
                            return "Uno per tutti"
                    }} />
                    <ListHeaderEntry name="Tipologia Utente" fieldName="forUserType" minWidth={150} maxWidth={250} onRender={(item: any, index, column) => {
                        if (item.forUserType === 0)
                            return "Utente Admin"
                        else if (item.forUserType === 1)
                            return "Utente Normale"
                        else if (item.forUserType === 2)
                            return "Entrambi i tipi"
                    }} />
                    <ListHeaderEntry name="Tipologia Azione" fieldName="actionType" minWidth={150} maxWidth={250} onRender={(item: any, index, column) => {
                        if (item.actionType === 0)
                        return "Apertura Link"
                        else if (item.actionType === 1)
                        return "Apertura Pagina"
                    }} />
                    <ListHeaderEntry name="Link Esterno" fieldName="externalLink" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="EndPoint Pagina" fieldName="pageEndPoint" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminApplicationTaskCard ref={cardRef} />
        </>
    );
}