import { DefaultButton, DialogFooter, FontIcon, IconButton, Label,Text, mergeStyles, Modal, PrimaryButton, Separator, Stack, TextField, ResponsiveMode, IButtonStyles, mergeStyleSets, FontWeights, IDragOptions, ContextualMenu } from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import { CustomerInformationContext } from "../../../ApplicationCode/Customer/CustomerInformationContext";
import { CustomerService } from "../../../ApplicationCode/Customer/CustomerService";
import { SystemDataLoadingStatus, SystemUser } from "../../../Model/SystemModels";
import { t } from "i18next";

const dragOptions:IDragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
    keepInBounds:true,
    dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
}

const ContentStyles = mergeStyleSets({
    Container: {
        width:'calc(688px)'
    },
    Header:{
        borderTop: `4px solid #0078d4`,
        color: "black",
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
    },
    Body: {
        padding: '0 10px 10px 10px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        }
    }
});

const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: "black",
        marginLeft: 'auto',
        marginRight: '2px',
    },
    rootHovered: {
        color: "gray",
    }
};

const iconClass = mergeStyles({
    fontSize: 25,
    padding:10
});

export interface ICustomerResetPasswordCardProps {
    showDialog: boolean;
    title: string;
    user: SystemUser;
    onSuccessModal: (user: SystemUser, email: String) => void;
    onCancelModal: () => void;
}

export const CustomerResetPasswordCard : React.FC<ICustomerResetPasswordCardProps> = (props) => {
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [mainPrivateUser, setMainPrivateUser] = useState("");

    const customerInfoContext = useContext(CustomerInformationContext);
    const customerService = new CustomerService();

    const getDefaultEmail = () => {
        customerService.getMainUserWithPrivateEmail(customerInfoContext.setupConfig.customerId)
        .then((resp) =>{
            setMainPrivateUser(resp.privateEmail);
            setDataStatus(SystemDataLoadingStatus.Loaded);
        })
        .catch((err) =>{
            setMainPrivateUser("");
            setDataStatus(SystemDataLoadingStatus.Loaded);
        });
    };

    useEffect(() => {
        if (props.showDialog)
        {
            if ((dataStatus === SystemDataLoadingStatus.ToLoad) || (dataStatus === SystemDataLoadingStatus.Loaded))
            {
                setDataStatus(SystemDataLoadingStatus.Loading);
                getDefaultEmail();
                setDataStatus(SystemDataLoadingStatus.Loaded);
            }
        }
        else
            setDataStatus(SystemDataLoadingStatus.ToLoad);
    }, [props.showDialog])

    return (
        <Modal
            titleAriaId={"AssignedLiscensesModal"}
            isOpen={props.showDialog}
            onDismiss={props.onCancelModal}
            isBlocking={true}
            containerClassName={ContentStyles.Container}
            dragOptions={true ? dragOptions : undefined}
            responsiveMode={ResponsiveMode.small}
        >
            <Stack className={ContentStyles.Header} horizontal horizontalAlign="space-between">
                <Stack.Item>
                    <FontIcon aria-label="Fingerprint" iconName="Fingerprint" className={iconClass} />
                    <Text variant="xLarge">{t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerResetPasswordCard:Dialog:title',{name:props.user.displayName})}</Text>
                    <Separator styles={{root:{width:'400px'}}}/>
                </Stack.Item>
                <Stack.Item>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close popup modal"
                        onClick={props.onCancelModal}
                    />
                </Stack.Item>
            </Stack>
                <Stack key="modalContent" className={ContentStyles.Body}>
                    <Stack.Item key="modalBodyContent" className={ContentStyles.Body}>
                        <Label>
                            {t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerResetPasswordCard:Dialog:labelAutomaticEmail',{email:props.user.userPrincipleName})!}
                        </Label>
                        <Label>
                            {t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerResetPasswordCard:Dialog:labelAdditionalAddress')!}
                        </Label>
                        <Separator></Separator>
                        <TextField label={t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerResetPasswordCard:Dialog:labelNotificationEmail')!} value={mainPrivateUser} onChange={((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) =>{
                            setMainPrivateUser(newValue!);
                        })} />
                    </Stack.Item>
                    <Stack.Item key="modalFooterContent" className={ContentStyles.Body}>
                        <DialogFooter>
                            <DefaultButton text={t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerResetPasswordCard:defaultButtonText')!} onClick={props.onCancelModal} />
                            <PrimaryButton text={t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerResetPasswordCard:primaryButtonText')!} onClick={() => props.onSuccessModal(props.user, mainPrivateUser)} />
                        </DialogFooter>
                    </Stack.Item>
                </Stack>
        </Modal>
    );

}