import { Guid } from "guid-typescript";
import { ActionType, SystemUser } from "./SystemModels";

export class CustomerSetupConfig {
    public customerId: number = 0;
    public hasTenant: boolean = false;
    public tenantId: string = "";
    public adminTenantUserId: string = "";
    public setupCompleted: boolean = false;
    public enabled: boolean = false;
    public partnerEmail: string = "";
    public introCompleted: boolean = false;
    public partnerEmailSendAt: Date = new Date();
    public systemId: string = "";
}

export class CustomerSetupStep {
    public customerId: number = 0;
    public id: number = 0;
    public description: string = "";
    public active: boolean = false;
    public completed: boolean = false;
    public systemId: string = "";
}

export class CustomerDomain {
    public customerId: number = 0;
    public tenantId: string = "";
    public tenantDomain: string = "";
    public isInitial: boolean = false;
    public isDefault: boolean = false;
    public enabled: boolean = false;
    public s2SEnabled: boolean = false;
    public useAsDefault: boolean = false;
    public systemId: string = "";
}

export class CustomerCompany {
    public id: number = 0;
    public customerId: number = 0;
    public name: string = "";
    public companyId: string = "";
    public enabled: boolean = true;
    public dataVersion: string = "";
    public systemId: string = "";
}

export class TenantInformationSetup {
    public hasTenant: boolean = false;
    public tenantId: string = "";
    public domain: string = "";
}

export class UserLicenseEntry {
    productSkuId: string = "";
    description: string = "";
    active: boolean = false;
    originalActive: boolean = false;
    availableQty: number = 0;
    originalAvailableQty: number = 0;
    toAssign: boolean = false;
    toRemove: boolean = false;
}

export class UserInformationSetup {
    public userInformation: SystemUser = new SystemUser();
    public licences: UserLicenseEntry[] = [];
}

export class CustomerDashboardCue {
    public activeUsers: number = 0;
    public activeCompanies: number = 0;
    public provisioningCompleted: boolean = false;
}

export class CustomerEnvironment {
    public customerId: number = 0;
    public environmentName: string = "";
    public countryCode: string = "";
    public production: boolean = false;
    public active: boolean = false;
    public companyDeployStatus: number = 0;
    public environmentDeployStatus: number = 0;
    public version: string = "";
    public visible: boolean = false;
    public systemId: string = Guid.EMPTY;
    public startUpgradeDate: Date = new Date();
    public forceIgnoreUpdateWindow: boolean = false;
    public enableUpgrade: boolean = false;
}

export class CustomerDigitalContract {
    public id: number = 0;
    public digitalContractCode: string = "";
    public customerId: number = 0;
    public signed: boolean = false;
    public signedBy: string = "";
    public signedAt: string = "";
    public mandatory: boolean = false;
    public adminRequired: boolean = false;
    public entityRelation: number = 0;
    public entityId: number = 0;
    public templateName: string = "";
    public envelopeId: string = "";
    public signLink: string = "";
    public practiceStatus: number = 0;
    public abletech_CustomerId: string = "";
    public abletech_ContractId: string = "";
    public abletech_MasterCompany: boolean = false;
    public exception_Description: string = "";
    public systemId: string = Guid.EMPTY;
}

export enum CustomerTrialStatus
{
    Empty,
    On,
    Off, 
    Converted
}

export enum CustomerVersion
{
    Empty = 0,
    V1 = 10,
    V2 = 20,
    V3 = 30
}

export class Customer {
    public id: number = 3;
    public firstName: string = "";
    public lastName: string = "";
    public purchasedCompany: number = 0;
    public shopCustomerId: string = "";
    public companyName: string = "";
    public streetAddress: string = "";
    public city: string = "";
    public zipCode: string = "";
    public county: string = "";
    public country: string = "";
    public phoneNo: string = "";
    public pecEmail: string = "";
    public vat: string = "";
    public sdi: string = "";
    public partnerId: number = 0;
    public erpCustomerNo: string = "";
    public erpSalesPersonCode: string = "";
    public mainGroupId: string = "";
    public enabled: boolean = false;
    public crmAccountId: string = "";
    public isTrial: CustomerTrialStatus = CustomerTrialStatus.Empty;
    public systemId: string = Guid.EMPTY;
    public foreignAddress: boolean = false;
    public email: string = "";
    public legalRepresentativeFirstName: string = "";
    public legalRepresentativeLastName: string = "";
    public linkToLeadId: string = "";
    public legalEntityCode: string = "";
    public customerVersion: CustomerVersion = CustomerVersion.Empty;
}

export enum RelationshipStatus {
    NotAccepted,
    Checking,
    Accepted
}

export enum GDAPRelationshipStatus {
    NotFound,
    NotAccepted,
    Checking,
    Accepted
}

export class CustomerProgressChecklist {
    public entryNo: number = 0;
    public customerId: number = 0;
    public code: string = "";
    public description: string = "";
    public classification1: string = "";
    public classification2: string = "";
    public status: number = 0;
    public tenantId: string = "";
    public weight: number = 100;
    public score: number = 0.00;
    public startDateTime: string = "";
    public endDateTime: string = "";
    public forUserType: number = 0;
    public actionType: ActionType = ActionType.OpenLink;
    public priority: number = 10;
    public groupCode: string = "";
    public externalLink: string = "";
    public pageEndpoint: string = "";
    public completed: boolean = false;
    public detailedDescription: string = "";
    public primaryButtonText: string = "";
    public showAsTask: boolean = false;
    public enabled: boolean = false;
    public showNextButton: boolean = false;
    public nextButtonText: string = "";
    public waitingTitle: string = "";
    public waitingMessage: string = "";
    public systemId: string = Guid.EMPTY.toString();
    public sourceId: string = "";
    public translatedDescription:string = "";
    public translatedDetDescription:string = "";
    public translatedPrimaryButtonText:string = "";
    public translatedNextButtonText:string = "";
    public translatedWaitingTitle:string = "";
    public translatedWaitingMessage:string = "";
}

export enum ApplicationForUserType { 
    AdminUser,
    NormalUser,
    Both
}

export class IncidentType {

    public entryNo: number = 0;
    public name: string = "";
}

export class StripeSubscription {
    public id: string = "";
    public currentPeriodStart: Date = new Date();
    public currentPeriodEnd: Date = new Date();
    public customer: string = "";
    public startDate: Date = new Date();
    public status: string = "";
    public recurringInterval: string = "";
    public cancellationLimitDate: Date = new Date();
}

export class CustomerGDAPRelationship {
    public id: string = "";
    public displayName: string = "";
    public duration: string = "";
    public customerId: number = 0;
    public tenantId: string = "";
    public customerDisplayName: string = "";
    public status: string = "";
    public createdOn: Date = new Date();
    public modifiedOn: Date = new Date();
    public activatedOn: Date = new Date();
    public endingDate: Date = new Date();
    public customerRelationshipRoleId: number = 0;
    public signLink: string = "";
}

export class DocsIndexStructure {
    public title: string = "";
    public isActive: boolean = false;
    public processes: DocsProcessStructure[] = [];
}

export class DocsSearchIndexStructure {
    public title: string = "";
    public key: string = "";
    public processModuleTitle: string = "";
    public processTitle: string = "";
}

export class DocsProcessStructure {
    public title: string = "";
    public isActive: boolean = false;
    public tests: DocsProcessTestStructure[] = [];
}

export class DocsProcessTestStructure {
    public title: string = "";
    public isActive: boolean = false;
    public fileName: string = "";
}

export class DocsResponse {
    public content: string = "";
    public contentType: string = "";
    public statusCode: number = 0;
}

export class CountryRegion {
    public countryCode: string = "";
    public country: string = "";
    public IsoCode: string = "";
    public abletechCode: string = "";
    public enabled: string = "";
}