import React, { useEffect, useState } from "react";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { Partner } from "../../../../Model/PartnerModels";
import {SystemActionCategory, SystemDataLoadingStatus, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";

export const AdminSubscriptionLineList : React.FC = () => {
    let headerId=HttpHelper.getParameter('headerId');

    return (
        <>
            <PageContainer endpoint={"/odata/subscription/line?$filter=idSubscriptionHeader eq "+headerId} pageType={SystemPageType.List} title="Lista dettaglio sottoscrizione" backAllowed>               
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Action}>
                    </ActionArea>
                </PageDefinitionContainer>                                 
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Id articolo" fieldName="itemId" minWidth={80} maxWidth={80} />
                    <ListHeaderEntry name="Codice articolo Ecommerce" fieldName="itemEcommerceCode" minWidth={200} maxWidth={200} />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={400} maxWidth={400} />
                    <ListHeaderEntry name="Stato" fieldName="status" minWidth={100} maxWidth={100} />
                    <ListHeaderEntry name="Ricorrenza" fieldName="recurringInterval" minWidth={100} maxWidth={100} />
                    <ListHeaderEntry name="Quantità" fieldName="quantity" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Prezzo" fieldName="price" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Importo riga" fieldName="lineAmount" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Percentuale IVA" fieldName="vaT_Percentage" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry name="Percentuale di sconto" fieldName="discount_Percentage" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry name="Percentuale commissione" fieldName="commission_Percentage" minWidth={180} maxWidth={180} />
                    <ListHeaderEntry name="Commissione" fieldName="commission_Amount" minWidth={120} maxWidth={120} />
                    <ListHeaderEntry name="Document No" fieldName="documentNo" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry name="Document Line No" fieldName="documentLineNo" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry name="Data inizio" fieldName="startDate" minWidth={200} maxWidth={200} />
                    <ListHeaderEntry name="Data fine" fieldName="endDate" minWidth={200} maxWidth={200} />
                    <ListHeaderEntry name="Prezzo mensile" fieldName="pricePerMonth" minWidth={120} maxWidth={120} />
                    <ListHeaderEntry name="Id Partner" fieldName="partnerId" minWidth={80} maxWidth={80} />
                    <ListHeaderEntry name="Id Cliente" fieldName="customerId" minWidth={80} maxWidth={80} />
                    <ListHeaderEntry name="Id" fieldName="id" minWidth={80} maxWidth={80} />
                </PageDefinitionContainer>
            </PageContainer>
        </>
    );
}