import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

export class CryptoHelper {

    constructor() {
    }

    static encryptWithAES(text: string, secretKey: string) : string {
        let val = AES.encrypt(text, secretKey).toString();
        return val;
    };
      
    static decryptWithAES(ciphertext: string, secretKey: string) {
        if (ciphertext != undefined)
        {
            const bytes = AES.decrypt(ciphertext, secretKey);
            const originalText = bytes.toString(Utf8);
            return originalText;
        }
        return undefined;
    };
}