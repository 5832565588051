import { Icon, Image, Label, mergeStyles, mergeStyleSets, PrimaryButton, Stack,Text } from "@fluentui/react";
import { List } from "@fluentui/react/lib/List";
import React, { useContext, useEffect, useState } from "react";
import { CustomerEnvironment, CustomerProgressChecklist } from "../../../Model/CustomerModels"
import facebookLogo from '../../../Assets/Images/facebook.png';
import instagramLogo from '../../../Assets/Images/instagram.png';
import twitterLogo from '../../../Assets/Images/twitter.png';
import linkedinLogo from '../../../Assets/Images/linkedin.png';
import youtubeLogo from '../../../Assets/Images/youtube.png';
import { CustomerInformationContext } from "../../../ApplicationCode/Customer/CustomerInformationContext";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { SystemDataLoadingStatus } from "../../../Model/SystemModels";
import { SystemCore } from "../../../Core/System/SystemCore";
import { t } from "i18next";

//#region Customer Environment List
export interface ICustomerEnvironmentListProps {
    items: CustomerEnvironment[];
}


export const CustomerEnvironmentList : React.FC<ICustomerEnvironmentListProps> = (props: ICustomerEnvironmentListProps) => {
    const customerInfoContext = useContext(CustomerInformationContext);

    const handleOnClick = (item:any,link:string) => {
        if (item.active)
            window.open(link);
        else
            ToastService.showMessage(MessageType.Information, t('customerCenter:CustomerEnvironmentList:Text00001Info'));
    }

    const onRenderCell = (item?: CustomerEnvironment, index?: number, isScrolling?: boolean): JSX.Element => {
        let envLink = "https://businesscentral.dynamics.com/" + customerInfoContext.setupConfig.tenantId + "/" + item!.environmentName;
        return (
          <Stack key={item!.environmentName + "Entry"} id="environmentsSection"  data-is-focusable={true} horizontal styles={{
              root: {
                  width: '100%',
                  selectors: {
                    "&:hover": {
                        backgroundColor: '#f2f2f2',
                        cursor: 'pointer'
                    }
                  }
              }
          }} onClick={()=>{handleOnClick(item,envLink);}}>
            <Stack.Item style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 10,
                color: 'rgb(0, 128, 137)'
            }}>
                <Icon
                iconName='FullCircleMask'
                style={{
                    fontSize: 25
                }}
                />
            </Stack.Item>
            <Stack.Item style={{
                padding: 10
            }}>
                <div style={{
                    fontSize: 15,
                    fontWeight: 500
                }}>{item?.environmentName}</div>
                <div style={{
                    fontSize: 10,
                    fontStyle: 'italic'
                }}>{t('customerCenter:CustomerEnvironmentList:envButtonDescription')}</div>
            </Stack.Item>
          </Stack>
        );
      };
    
    if (props.items.length == 0)
      return(
        <Label>{t('customerCenter:CustomerEnvironmentList:noEnvFoundLabel')}</Label>
      );
    else
        return (
            <List items={props.items} onRenderCell={onRenderCell} />
        );
}
//#endregion

//#region Customer App Box


//#endregion

//#region Customer Social Slide

const socialLogoImageClassName = mergeStyles([{
    cursor: 'pointer'
}]);

export const CustomerSocialSlide = () => {
    return (
        <Stack key={"socialSlideContainer"} style={{
            width: '100%',
            display: 'flex',
            minHeight: '320px',
            padding: 40,
            backgroundColor: 'rgb(217, 240, 242)'
        }}>
            <Stack.Item style={{
                fontSize: 32,
                padding: 10,
                display: 'flex',
                alignItems: 'stretch'
            }}>
                {t('customerCenter:DashboardContentContainer:CustomerSocialSlide:socialTitle')}
            </Stack.Item>
            <Stack.Item style={{
                fontSize: 18,
                padding: 10,
                display: 'flex',
                alignItems: 'stretch'
            }}>
                <div>
                {t('customerCenter:DashboardContentContainer:CustomerSocialSlide:socialDescription')}<br />
                <Stack key="socialLogoContainer" horizontal tokens={{
                    childrenGap: 5
                }} style={{
                    paddingTop: 20
                }}>
                    <Stack.Item>
                        <Image src={facebookLogo} height={30} width={30} className={socialLogoImageClassName} onClick={() => window.open("https://www.facebook.com/so.smart.business")} />
                    </Stack.Item>
                    <Stack.Item>
                        <Image src={instagramLogo} height={30} width={30} className={socialLogoImageClassName} onClick={() => window.open("https://www.instagram.com/so_smart_biz/")} />
                    </Stack.Item>
                    <Stack.Item>
                        <Image src={youtubeLogo} height={30} width={30} className={socialLogoImageClassName} onClick={() => window.open("https://www.youtube.com/channel/UCb5qwkbMINv02sPk4YGWQ-g")} />
                    </Stack.Item>
                    <Stack.Item>
                        <Image src={twitterLogo} height={30} width={30} className={socialLogoImageClassName} onClick={() => window.open("https://twitter.com/so_smart_biz")} />
                    </Stack.Item>
                    <Stack.Item>
                        <Image src={linkedinLogo} height={30} width={30} className={socialLogoImageClassName} onClick={() => window.open("https://www.linkedin.com/showcase/66618234/admin/")} />
                    </Stack.Item>
                </Stack>
                </div>
            </Stack.Item>
        </Stack>
    );
}
//#endregion

export interface IOnBoardingWizardProps {
    tasks: CustomerProgressChecklist[];
}

export const OnBoardingWizard: React.FC<IOnBoardingWizardProps> = (props) => {
    
    const buildSteps = () : JSX.Element[] => {
        let elements: JSX.Element[] = [];
        for (let i = 0; i < props.tasks.length; i++)
            elements.push(<OnBoardingWizardStep task={props.tasks[i]} />);
        
        return elements;
    }

    const getCurrentTask = () : CustomerProgressChecklist | undefined => {
        let candidates = props.tasks.filter(e => ! e.completed);
        if (candidates.length > 0)
            return candidates[0];
        return undefined;
    }

    return (
        <Stack key="onBoardingWizard">
            <OnBoardingWizardStepContainer>
                {buildSteps()}
            </OnBoardingWizardStepContainer>
            <OnBoardingWizardContentContainer task={getCurrentTask()}>
                {props.children}
            </OnBoardingWizardContentContainer>
        </Stack>
    );
}

export interface IOnBoardingWizardStepContainerProps {
}

export const OnBoardingWizardStepContainer: React.FC<IOnBoardingWizardStepContainerProps> = (props) => {
    return (
        <Stack.Item>
            <Stack key="onBoardingStepsContainer">
                {props.children}
            </Stack>
        </Stack.Item>
    );
}

export interface IOnBoardingWizardStepProps {
    task: CustomerProgressChecklist;
}

export const OnBoardingWizardStep: React.FC<IOnBoardingWizardStepProps> = (props) => {
    return (
        <Stack.Item key={"step" + props.task.systemId}>
            {props.task.description}
        </Stack.Item>
    );
}

export interface IOnBoardingWizardContentContainerProps {
    task?: CustomerProgressChecklist;
}

export const OnBoardingWizardContentContainer: React.FC<IOnBoardingWizardContentContainerProps> = (props) => {
    return (
        <Stack.Item key={"onBoardingStepContentContainer" + props.task!.systemId}>
            <Stack>
                {props.children}
            </Stack>
        </Stack.Item>
    );
}
