import {mergeStyles, Stack } from "@fluentui/react";
import React, {useEffect, useState } from "react";
import { AdminService } from "../../../ApplicationCode/Admin/AdminService";
import { SystemCore } from "../../../Core/System/SystemCore";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { AdminHelpdeskDashboardCue } from "../../../Model/AdminModel";
import { SystemDataLoadingStatus } from "../../../Model/SystemModels";
import { Banner, DashboardCarousel, DashboardContainer, DashboardContentColumn, DashboardContentContainer, DashboardContentRow, DataBox, UserProfileCard } from "../../Common/Dashboard/DashboardComponents";
import { Loader } from "../../Common/Loader/Loader";
import { CarouselItem } from "../../Partner/PartnerDashboard/PartnerDashboardComponents";


const dashboardCarouselClassName = mergeStyles([{
    width: "95%",
    height: "auto",
    position: "relative",
    margin: "auto"
}])

export const AdminHelpdeskDashboard : React.FC = () => {
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [helpdeskDashboardCue, setHelpdeskDashboardCue]= useState(new AdminHelpdeskDashboardCue())
    const adminService = new AdminService();
    
    useEffect(() => {
        if (dataStatus === SystemDataLoadingStatus.ToLoad)
        {
            setDataStatus(SystemDataLoadingStatus.Loading);

            adminService.getAdminHelpdeskDashboardCue().then((resp)=>{
                setHelpdeskDashboardCue(resp);
                setDataStatus(SystemDataLoadingStatus.Loaded);
            })
            .catch((jqXHR)=>{
                setDataStatus(SystemDataLoadingStatus.Loaded);
                ToastService.showMessage(MessageType.Error,jqXHR.message);
            })
        }
    }, [dataStatus]);

    if (! SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text="Stiamo caricando la tua dashboard..." />
    else
    {
        return (
            <DashboardContainer>
                    <Banner
                        leftContent={
                            <UserProfileCard/>
                        }
                        rightContent={
                            <DashboardCarousel containerClassName={dashboardCarouselClassName} activeSlide={1}>
                                <CarouselItem
                                    title="Nuove trial"
                                    description={"L'ultima settimana sono state create: "+helpdeskDashboardCue.trialNew+" nuove trial"}
                                    iconName="UserGauge"
                                />
                                <CarouselItem
                                    title="Nuovi clienti"
                                    description={"Negli ultimi 30 giorni sono stati acquisiti "+helpdeskDashboardCue.customerNew+" nuovi clienti"}
                                    iconName="UserFollowed"
                                />
                                <CarouselItem
                                    title={"Fatturato"}
                                    description={"Negli ultimi 30 giorni il fatturato è stato di "+helpdeskDashboardCue.newRevenue+" €"}
                                    iconName="Money"
                                />
                                <CarouselItem
                                    title={"Sottoscrizioni: "+helpdeskDashboardCue.subscriptionSum+" €"}
                                    description={"Negli ultimi 30 giorni sono state create nuove sottoscrizioni di "+helpdeskDashboardCue.newSubscriptionSum+" €"}
                                    iconName="Subscribe"
                                />
                            </DashboardCarousel>
                        }
                    />
                    <DashboardContentRow id={'1'}>
                        <Stack key={"dashboardVerticalWidgets"} styles={{root: {width: '50%'}}} tokens={{childrenGap: 20}}>
                            <DashboardContentColumn>
                                <DashboardContentContainer title="Clienti" name="customerWorkflow">
                                    <Stack horizontal wrap tokens={{childrenGap: 10}}>
                                        <DataBox
                                            name="customerNew"
                                            title="New"
                                            value={helpdeskDashboardCue.customerNew}
                                            link={"/app/admin/customer?dsStatus=50&dsCodeName=New"}
                                        />
                                        <DataBox
                                            name="customerProvisioning"
                                            title="Provisioning"
                                            value={helpdeskDashboardCue.customerProvisioning}
                                            link={"/app/admin/customer?dsStatus=60&dsCodeName=Provisioning"}
                                        />
                                        <DataBox
                                            name="customerOnboarding"
                                            title="OnBoarding"
                                            value={helpdeskDashboardCue.customerOnboarding}
                                            link={"/app/admin/customer?dsStatus=70&dsCodeName=OnBoarding"}
                                        />
                                        <DataBox
                                            name="customerLive"
                                            title="Support"
                                            value={helpdeskDashboardCue.customerLive}
                                            link={"/app/admin/customer?dsStatus=80&dsCodeName=Support"}
                                        />
                                    </Stack>
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                            <DashboardContentColumn>
                                <DashboardContentContainer title="Ticket del Team (So Smart)" name="teamIncident">
                                    <Stack horizontal wrap tokens={{childrenGap: 10}}>
                                        <DataBox
                                            name="teamIncidentOpen"
                                            title="Aperti"
                                            value={helpdeskDashboardCue.teamIncidentOpen}
                                            link={"/app/admin/administrative/crmIncidentList?source=IT-DIRETTA&stateCode=0&incidentWorkingBy=0"}
                                        />
                                        <DataBox
                                            name="teamIncidentOpenLate"
                                            title="Aperti In Ritardo"
                                            value={helpdeskDashboardCue.teamIncidentOpenLate}
                                            link={"/app/admin/administrative/crmIncidentList?source=IT-DIRETTA&stateCode=0&incidentWorkingBy=0&takeInChargeLate=true"}
                                        />
                                        <DataBox
                                            name="teamIncidentInProgress"
                                            title="In Corso"
                                            value={helpdeskDashboardCue.teamIncidentInProgress}
                                            link={"/app/admin/administrative/crmIncidentList?source=IT-DIRETTA&stateCode=0&incidentOwner=1&incidentWorkingBy=1"}
                                        />
                                        <DataBox
                                            name="teamIncidentInProgressLate"
                                            title="In Corso In Ritardo"
                                            value={helpdeskDashboardCue.teamIncidentInProgressLate}
                                            link={"/app/admin/administrative/crmIncidentList?source=IT-DIRETTA&stateCode=0&incidentOwner=1&incidentWorkingBy=1&interactionLate=true"}
                                        />
                                        <DataBox
                                            name="teamIncidentResolved"
                                            title="Risolti"
                                            value={helpdeskDashboardCue.teamIncidentResolved}
                                            link={"/app/admin/administrative/crmIncidentList?source=IT-DIRETTA&stateCode=1"}
                                        />
                                    </Stack>
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                            <DashboardContentColumn>
                                <DashboardContentContainer title="I miei Ticket (So Smart)" name="userIncident">
                                    <Stack horizontal wrap tokens={{childrenGap: 10}}>
                                        <DataBox
                                            name="userIncidentInProgress"
                                            title="In Corso"
                                            value={helpdeskDashboardCue.userIncidentInProgress}
                                            link={"/app/admin/administrative/crmIncidentList?source=IT-DIRETTA&stateCode=0&incidentOwner=0&incidentWorkingBy=2"}
                                        />
                                        <DataBox
                                            name="userIncidentLate"
                                            title="In Ritardo"
                                            value={helpdeskDashboardCue.userIncidentLate}
                                            link={"/app/admin/administrative/crmIncidentList?source=IT-DIRETTA&stateCode=0&incidentOwner=0&incidentWorkingBy=2&interactionLate=true"}
                                        />
                                    </Stack>
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                            <DashboardContentColumn>
                                <DashboardContentContainer title="Ticket in anomalia (So Smart)" name="incidentInAnomaly">
                                    <Stack horizontal wrap tokens={{childrenGap: 10}}>
                                        <DataBox
                                            name="teamIncidentNotAssocied"
                                            title="Non Associati"
                                            value={helpdeskDashboardCue.teamIncidentNotAssocied}
                                            link={"/app/admin/administrative/crmIncidentList?source=IT-DIRETTA&stateCode=0&notAssocied=true"}
                                        />
                                        <DataBox
                                            name="teamIncidentNotMapped"
                                            title="Non Mappati"
                                            value={helpdeskDashboardCue.teamIncidentNotMapped}
                                            link={"/app/admin/administrative/crmIncidentList?source=IT-DIRETTA&notMapped=true"}
                                        />
                                        <DataBox
                                            name="teamIncidentNotMappedLastMonth"
                                            title="Non Mappati Ultimo Mese"
                                            value={helpdeskDashboardCue.teamIncidentNotMappedLastMonth}
                                            link={"/app/admin/administrative/crmIncidentList?source=IT-DIRETTA&notMapped=true&lastMonth=true"}
                                        />
                                        <DataBox
                                            name="teamIncidentFAQMissing"
                                            title="FAQ Mancanti"
                                            value={helpdeskDashboardCue.teamIncidentFAQMissing}
                                            link={"/app/admin/administrative/crmIncidentList?source=IT-DIRETTA&faqMissing=true"}
                                        />
                                        <DataBox
                                            name="teamIncidentFAQMissingLastMonth"
                                            title="FAQ Mancanti Ultimo Mese"
                                            value={helpdeskDashboardCue.teamIncidentFAQMissingLastMonth}
                                            link={"/app/admin/administrative/crmIncidentList?source=IT-DIRETTA&faqMissing=true&lastMonth=true"}
                                        />
                                    </Stack>
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                        </Stack>
                        <Stack key={"dashboardSharepointDocs"} styles={{root: {width: '50%'}}} tokens={{childrenGap: 20}}>
                            <DashboardContentColumn>
                                <DashboardContentContainer title="Trial" name="trialWorkflow">
                                    <Stack horizontal wrap tokens={{childrenGap: 10}}>
                                        <DataBox
                                            name="trialNew"
                                            title="New"
                                            value={helpdeskDashboardCue.trialNew}
                                            link={"/app/admin/customer?dsStatus=10&dsCodeName=New"}
                                        />
                                        <DataBox
                                            name="trialProvisioning"
                                            title="Provisioning"
                                            value={helpdeskDashboardCue.trialProvisioning}
                                            link={"/app/admin/customer?dsStatus=20&dsCodeName=Provisioning"}
                                        />
                                        <DataBox
                                            name="trialOnboarding"
                                            title="OnBoarding"
                                            value={helpdeskDashboardCue.trialOnboarding}
                                            link={"/app/admin/customer?dsStatus=30&dsCodeName=OnBoarding"}
                                        />
                                        <DataBox
                                            name="trialSwitch"
                                            title="Switch"
                                            value={helpdeskDashboardCue.trialSwitch}
                                            link={"/app/admin/customer?dsStatus=40&dsCodeName=Switch"}
                                        />
                                    </Stack>
                                </DashboardContentContainer>
                            </DashboardContentColumn><DashboardContentColumn>
                                <DashboardContentContainer title="Ticket del Team (Eos Apps)" name="eosAppTeamIncident">
                                    <Stack horizontal wrap tokens={{childrenGap: 10}}>
                                        <DataBox
                                            name="eosAppTeamIncidentOpen"
                                            title="Aperti"
                                            value={helpdeskDashboardCue.eosAppTeamIncidentOpen}
                                            link={"/app/admin/administrative/crmIncidentList?source=EOS-APPS&stateCode=0&incidentWorkingBy=0"}
                                        />
                                        <DataBox
                                            name="eosAppTeamIncidentOpenLate"
                                            title="Aperti In Ritardo"
                                            value={helpdeskDashboardCue.eosAppTeamIncidentOpenLate}
                                            link={"/app/admin/administrative/crmIncidentList?source=EOS-APPS&stateCode=0&incidentWorkingBy=0&takeInChargeLate=true"}
                                        />
                                        <DataBox
                                            name="eosAppTeamIncidentInProgress"
                                            title="In Corso"
                                            value={helpdeskDashboardCue.eosAppTeamIncidentInProgress}
                                            link={"/app/admin/administrative/crmIncidentList?source=EOS-APPS&stateCode=0&incidentOwner=1&incidentWorkingBy=1"}
                                        />
                                        <DataBox
                                            name="eosAppTeamIncidentInProgressLate"
                                            title="In Corso In Ritardo"
                                            value={helpdeskDashboardCue.eosAppTeamIncidentInProgressLate}
                                            link={"/app/admin/administrative/crmIncidentList?source=EOS-APPS&stateCode=0&incidentOwner=1&incidentWorkingBy=1&interactionLate=true"}
                                        />
                                        <DataBox
                                            name="eosAppTeamIncidentResolved"
                                            title="Risolti"
                                            value={helpdeskDashboardCue.eosAppTeamIncidentResolved}
                                            link={"/app/admin/administrative/crmIncidentList?source=EOS-APPS&stateCode=1"}
                                        />
                                    </Stack>
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                            <DashboardContentColumn>
                                <DashboardContentContainer title="I miei Ticket (Eos App)" name="eosAppUserIncident">
                                    <Stack horizontal wrap tokens={{childrenGap: 10}}>
                                        <DataBox
                                            name="eosAppUserIncidentInProgress"
                                            title="In Corso"
                                            value={helpdeskDashboardCue.eosAppUserIncidentInProgress}
                                            link={"/app/admin/administrative/crmIncidentList?source=EOS-APPS&stateCode=0&incidentOwner=0&incidentWorkingBy=2"}
                                        />
                                        <DataBox
                                            name="eosAppUserIncidentLate"
                                            title="In Ritardo"
                                            value={helpdeskDashboardCue.eosAppUserIncidentLate}
                                            link={"/app/admin/administrative/crmIncidentList?source=EOS-APPS&stateCode=0&incidentOwner=0&incidentWorkingBy=2&interactionLate=true"}
                                        />
                                    </Stack>
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                            <DashboardContentColumn>
                                <DashboardContentContainer title="Ticket in anomalia (Eos App)" name="eosAppIncidentInAnomaly">
                                    <Stack horizontal wrap tokens={{childrenGap: 10}}>
                                        <DataBox
                                            name="eosAppTeamIncidentNotAssocied"
                                            title="Non Associati"
                                            value={helpdeskDashboardCue.eosAppTeamIncidentNotAssocied}
                                            link={"/app/admin/administrative/crmIncidentList?source=EOS-APPS&stateCode=0&notAssocied=true"}
                                        />
                                        <DataBox
                                            name="eosAppTeamIncidentNotMapped"
                                            title="Non Mappati"
                                            value={helpdeskDashboardCue.eosAppTeamIncidentNotMapped}
                                            link={"/app/admin/administrative/crmIncidentList?source=EOS-APPS&notMapped=true"}
                                        />
                                        <DataBox
                                            name="eosAppTeamIncidentNotMappedLastMonth"
                                            title="Non Mappati Ultimo Mese"
                                            value={helpdeskDashboardCue.eosAppTeamIncidentNotMappedLastMonth}
                                            link={"/app/admin/administrative/crmIncidentList?source=EOS-APPS&notMapped=true&lastMonth=true"}
                                        />
                                        <DataBox
                                            name="eosAppTeamIncidentFAQMissing"
                                            title="FAQ Mancanti"
                                            value={helpdeskDashboardCue.eosAppTeamIncidentFAQMissing}
                                            link={"/app/admin/administrative/crmIncidentList?source=EOS-APPS&faqMissing=true"}
                                        />
                                        <DataBox
                                            name="eosAppTeamIncidentFAQMissingLastMonth"
                                            title="FAQ Mancanti Ultimo Mese"
                                            value={helpdeskDashboardCue.eosAppTeamIncidentFAQMissingLastMonth}
                                            link={"/app/admin/administrative/crmIncidentList?source=EOS-APPS&faqMissing=true&lastMonth=true"}
                                        />
                                    </Stack>
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                        </Stack>
                    </DashboardContentRow>
                </DashboardContainer>
        );
    }
}
