import { useRef, useState, useEffect, useContext, useLayoutEffect } from "react";
import { IconButton, Dialog, DialogType, Stack, Icon, IChoiceGroupOption } from "@fluentui/react";

import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import uuid from 'react-uuid';

import styles from "./Chat.module.css";

import {
    ChatMessage,
    ConversationRequest,
    conversationApi,
    Citation,
    ToolMessageContent,
    ChatResponse,
    getUserInfo,
    Conversation,
    historyGenerate,
    historyUpdate,
    historyClear,
    ChatHistoryLoadingState,
    CosmosDBStatus,
    ErrorMessage,
    ChatMessageType,
    ChatCompletionType,
    ToolContentEntry,
    getSupportTypes,
    getItems,
    SupportEntry,
    getSupportEntry,
    BCNProcessTest,
    getBCNProcessTest,
    ISupportFlow,
    SupportUserInput,
    getSoSmartToken,
    odataBaseUrl,
    getQuestions,
    buildBaseEndpoint
} from "../../../ApplicationCode/Common/Chatbot/api";
import { Answer, WelcomeMessage } from "../../Common/Chatbot/Answer";
import { QuestionInput } from "../../Common/Chatbot/QuestionInput";
import { ChatHistoryPanel } from "../../Common/Chatbot/ChatHistory/ChatHistoryPanel";
import { AppStateContext } from "../../../Core/State/ChatbotAppProvider";
import { useBoolean } from "@fluentui/react-hooks";
import { AppVersionForm, CustomerIdForm, EosAppForm, EosAppWelcomeMessage, EosSupportFlow, InstallationTypeForm, SubscriptionTypeForm, SuccessForm, SupportRequestTextForm, SupportTypeForm } from "../../Common/Chatbot/Answer/EosAppSupport";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import i18next, { t } from "i18next";
import { SoSmartSimpleSupportFlow } from "../../Common/Chatbot/Answer/SoSmartSimpleSupport";
import { SoSmartAdvancedSupportFlow } from "../../Common/Chatbot/Answer/SoSmartAdvancedSupport";
import { Item } from "../../../Model/PartnerModels";
import { Loader } from "../../Common/Loader/Loader";
import { parseAnswer } from "../../Common/Chatbot/Answer/AnswerParser";
import { SystemCore } from "../../../Core/System/SystemCore";
import { ChatbotContext, ChatbotHelper } from "../ChatHelper";

const enum messageStatus {
    NotRunning = "Not Running",
    Processing = "Processing",
    Done = "Done"
}

function useInterval(callback: () => void, delay: number) {
    const savedCallback = useRef<any>();
  
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
  
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }, [delay]);
  }

const Chat = () => {
    const appStateContext = useContext(AppStateContext)
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const chatMessageContainer = useRef<HTMLDivElement | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
    const [isSupportInProgress, setIsSupportInProgress] = useState<boolean>(false);
    const [supportStep, setSupportStep] = useState<number>(0);
    const [activeCitation, setActiveCitation] = useState<[content: string, id: string, title: string, filepath: string, url: string, metadata: string]>();
    const [isCitationPanelOpen, setIsCitationPanelOpen] = useState<boolean>(false);
    const abortFuncs = useRef([] as AbortController[]);
    const [showAuthMessage, setShowAuthMessage] = useState<boolean>(true);
    const [messages, setMessages] = useState<ChatMessage[]>([])
    const [processMessages, setProcessMessages] = useState<messageStatus>(messageStatus.NotRunning);
    const [clearingChat, setClearingChat] = useState<boolean>(false);
    const [hideErrorDialog, { toggle: toggleErrorDialog }] = useBoolean(true);
    const [errorMsg, setErrorMsg] = useState<ErrorMessage | null>();
    const [supportFlowHandler, setSupportFlowHandler] = useState<ISupportFlow | undefined>();
    const [showFastActions, setShowFastActions] = useState<boolean>(false);
    const [selectedApp, setSelectedApp] = useState<Item>();
    const [items, setItems] = useState<Item[]>([]);
    const [firstLoading, setFirstLoading] = useState<boolean>(false);
    const [generetingText, setGeneretingText] = useState<string>("");
    const [displayText, setDisplayText] = useState<string>("");
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(undefined);
    const [welcomeMessage, setWelcomeMessage] = useState<string>("");

    const errorDialogContentProps = {
        type: DialogType.close,
        title: errorMsg?.title,
        closeButtonAriaLabel: 'Close',
        subText: errorMsg?.subtitle,
    };

    const modalProps = {
        titleAriaId: 'labelId',
        subtitleAriaId: 'subTextId',
        isBlocking: true,
        styles: { main: { maxWidth: 450 } },
    }

    useEffect(() => {
        if(appStateContext?.state.isCosmosDBAvailable?.status === CosmosDBStatus.NotWorking && appStateContext.state.chatHistoryLoadingState === ChatHistoryLoadingState.Fail && hideErrorDialog){
            let subtitle = `${appStateContext.state.isCosmosDBAvailable.status}. Please contact the site administrator.`
            setErrorMsg({
                title: "Chat history is not enabled",
                subtitle: subtitle
            })
            toggleErrorDialog();
        }
    }, [appStateContext?.state.isCosmosDBAvailable]);


    useEffect(() => {
        if (generetingText.length > 0)
        {
            let i = displayText.length > 0 ? displayText.length - 1 : 0;
            setIntervalId(setInterval(() => {
                if (i < generetingText.length)
                {
                    setDisplayText(generetingText.slice(0, i));
                    i++;
                }
            }, 35));
            
            return () => clearInterval(intervalId!);
        }
      }, [generetingText]);

    const handleErrorDialogClose = () => {
        toggleErrorDialog()
        setTimeout(() => {
            setErrorMsg(null)
        }, 500);
    }
    
    const createSupportFlowHandler = (advancedMode: boolean) : ISupportFlow => {
        let context = ChatbotHelper.getContext();
        switch (context)
        {
            case ChatbotContext.Eos:
                return new EosSupportFlow();
            case ChatbotContext.SoSmart:
                if (advancedMode)
                    return new SoSmartAdvancedSupportFlow();
                else
                    return new SoSmartSimpleSupportFlow();
        }
        return new EosSupportFlow();
    }

    const startSupportRequestFlow = (conversation: Conversation | null | undefined) => {
        setProcessMessages(messageStatus.Processing);
        if (isSupportInProgress)
        {
            setProcessMessages(messageStatus.Done);
            return;   
        }

        setShowLoadingMessage(true);

        if (! conversation)
            conversation = appStateContext?.state?.currentChat;

        let supportId = HttpHelper.getParameter("supportId");
        if (supportId)
            window.history.replaceState(null, '', window.location.pathname);
        
        let currentHandler = createSupportFlowHandler((supportId));
        currentHandler.loadRequiredData(supportId).then(() => {
            let currentSupportStep = currentHandler.initializeStartStep();
            setSupportFlowHandler(currentHandler);
            currentHandler.handleStep(selectedApp!, currentSupportStep, undefined, appStateContext!, conversation!, setMessages, makeApiRequestWithoutCosmosDB)
            .then((increment: number) => {
                setSupportStep(currentSupportStep + increment);
                setProcessMessages(messageStatus.Done);
                setIsSupportInProgress(true);
                setShowLoadingMessage(false);
            });
        });
    }
    
    const getUserInfoList = async () => {
        const userInfoList = await getUserInfo();
        if (userInfoList.length === 0 && window.location.hostname !== "localhost") {
            setShowAuthMessage(true);
        }
        else {
            setShowAuthMessage(false);
        }
    }

    const handleUserInput = async (question: SupportUserInput, conversationId?: string) => {
        if (isSupportInProgress)
        {
            setProcessMessages(messageStatus.Processing);
            let conversation: Conversation | null | undefined;
            conversation = appStateContext?.state?.currentChat;
            if (! conversation)
                return;
            
            if (supportFlowHandler?.showUserMessage(supportStep))
            {
                const userMessage: ChatMessage = {
                    id: uuid(),
                    role: "user",
                    content: question.value,
                    date: new Date().toISOString(),
                    type: ChatMessageType.Conversation
                };

                conversation.messages.push(userMessage);
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
                setMessages(conversation.messages);
            }

            try 
            {
                let newStep = supportStep;
                console.log(selectedApp);
                let stepToAdd: number = await supportFlowHandler!.handleStep(selectedApp!, supportStep, question, appStateContext!, conversation, setMessages, makeApiRequestWithoutCosmosDB);

                newStep += stepToAdd;
                setSupportFlowHandler(supportFlowHandler);
                setSupportStep(newStep);

                let isFlowCompleted: boolean = supportFlowHandler!.isFlowCompleted(newStep);
                setIsSupportInProgress(! isFlowCompleted);
            }
            catch(err)
            {
                conversation.messages.push({
                    id: uuid(),
                    role: "error",
                    content: String(err),
                    date: new Date().toISOString(),
                    type: ChatMessageType.Conversation
                });

                let isErrorHandled = supportFlowHandler?.isErrorHandled(supportStep);
                if (! isErrorHandled)
                    setIsSupportInProgress(false);

                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
                setMessages(conversation.messages);

            }
            setProcessMessages(messageStatus.Done);
        }
        else {
            if (! conversationId)
            {
                let conversation: Conversation | null | undefined;
                conversation = appStateContext?.state?.currentChat;
                if (conversation)
                    conversationId = conversation.id;
            }
            
            makeApiRequestWithoutCosmosDB(question.value, conversationId);

        }
    }

    const getItems = async (): Promise<Item[]> => {
        return await performODataHttpRequest("/item?filter=category eq 'EosApps' and itemErpNo ne null and itemErpNo gt '' and hideFromChatbot eq false&orderby=description asc");
    }

    const performODataHttpRequest = async (path: string): Promise<any> => {
        let token = await getSoSmartToken();
        const response = await fetch(odataBaseUrl + path, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token
            }
        });

        if (response.status == 200)
            return response.json();
        
        throw new Error("Cannot read data from server API " + odataBaseUrl + path);
    }

    const getWelcomeMessage = async (): Promise<string> => {
        let languageCode = i18next.resolvedLanguage.toUpperCase();

        const response = await fetch(buildBaseEndpoint() + "/api/eosChat/message/" + languageCode + "/welcome", {
            method: "GET"
        });

        if (response.status == 200)
            return await response.text();
        
        throw new Error("Cannot read data from server API");
    }

    const makeApiRequestWithoutCosmosDB = async (question: string, conversationId?: string, skipMessageCreation?: boolean) => {
        setIsLoading(true);
        setShowLoadingMessage(true);
        setGeneretingText("");
        setDisplayText("");
        
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);


        let userMessage: ChatMessage = {
            id: uuid(),
            role: "user",
            content: question,
            date: new Date().toISOString(),
            type: ChatMessageType.Conversation
        };

        let conversation: Conversation | null | undefined;
        if(!conversationId){
            conversation = {
                id: conversationId ?? uuid(),
                title: question,
                messages: [userMessage],
                date: new Date().toISOString(),
                languageCode: i18next.resolvedLanguage.toUpperCase()
            }
        }else{
            conversation = appStateContext?.state?.currentChat
            if(!conversation){
                console.error("Conversation not found.");
                setIsLoading(false);
                setShowLoadingMessage(false);
                abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                return;
            }
            else if (! skipMessageCreation)
                conversation.messages.push(userMessage);
        }

        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
        setMessages(conversation.messages);

        //handle Support Request
        const userRequiredSupport: boolean = question.toLowerCase() === "support";
        if(userRequiredSupport){
            setIsLoading(false);
            setShowLoadingMessage(false);
            setProcessMessages(messageStatus.Done);

            startSupportRequestFlow(conversation);

            return abortController.abort();
        }
        
        const request: ConversationRequest = {
            messages: [...conversation.messages.filter((answer) => answer.role !== "error")],
            languageCode: i18next.resolvedLanguage.toUpperCase()
            // messages: [...conversation.messages.filter((answer) => answer.role === "error")]
        };

        let supportRequired: boolean = false;
        let result = {} as ChatResponse;
        try {
            const response = await conversationApi(request, abortController.signal);
            if (response?.body) {
                const reader = response.body.getReader();
                let runningText = "";
                let currentText = "";

                while (true) {
                    setProcessMessages(messageStatus.Processing);
                    let skip = 0;
                    const {done, value} = await reader.read();
                    if (done) break;

                    var text = new TextDecoder("utf-8").decode(value);
                    const objects = text.split("\n");
                    objects.forEach((obj) => {
                        try {
                            runningText += obj;
                            result = JSON.parse(runningText);
                            result.choices[0].messages.forEach((obj) => {
                                obj.id = uuid();
                                obj.date = new Date().toISOString();
                                obj.type = ChatMessageType.Conversation
                            })
                            

                            for (let i = 0; i < result.choices[0].messages.length; i++)
                            {
                                let startPos = currentText.length > 0 ? currentText.length : 0;
                                currentText += result.choices[0].messages[0].content.slice(startPos, result.choices[0].messages[i].content.length - 1);
                            }
                        
                            runningText = "";
                        }
                        catch { }
                    });

                    setGeneretingText(currentText);
                }

            
                let parsedAnswer = parseAnswer({
                    answer: result.choices[0].messages[0].content.toString(),
                    citations: []
                })

                if (parsedAnswer.citations.length > 0)
                {
                    const questionRequest: ConversationRequest = {
                        messages: [...messages.filter((answer) => answer.role !== "error"), ...result.choices[0].messages],
                        languageCode: i18next.resolvedLanguage.toUpperCase()
                        // messages: [...conversation.messages.filter((answer) => answer.role === "error")]
                    };
                    try 
                    {
                        let followupQuestionsResult = await getQuestions(questionRequest);
                        result.choices[0].messages[0].followupQuestions = followupQuestionsResult;
                    }
                    catch
                    {
                        result.choices[0].messages[0].followupQuestions = [];
                    }
                }
                conversation.messages.push(...result.choices[0].messages);
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
                setDisplayText("");
                setGeneretingText("");
                setMessages([...messages, ...result.choices[0].messages]);
            }
        } catch ( e )  {
            if (!abortController.signal.aborted) {
                let errorMessage = t("chatbot:chat:errorMessage");
                if (result.error?.message) {
                    errorMessage = result.error.message;
                }
                else if (typeof result.error === "string") {
                    errorMessage = result.error;
                }
                let errorChatMsg: ChatMessage = {
                    id: uuid(),
                    role: "error",
                    content: errorMessage,
                    date: new Date().toISOString(),
                    type: ChatMessageType.Conversation
                }
                conversation.messages.push(errorChatMsg);
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
                setMessages([...messages, errorChatMsg]);
            } else {
                setMessages([...messages, userMessage])
            }
        } finally {
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            setProcessMessages(messageStatus.Done);
            setGeneretingText("");
            setDisplayText("");
        }

        return abortController.abort();
    };

    const substituteDefaultOAIMessage = (chatResponse: ChatResponse) : ChatResponse => {
        try {
            let index =chatResponse.choices[0].messages.findIndex(e => e.role === "assistant");
            if (index < 0)
                return chatResponse;

            if ((chatResponse.choices[0].messages[index].content == "The requested information is not available in the retrieved data. Please try another query or topic.")
            || (chatResponse.choices[0].messages[index].content == "The requested information is not found in the retrieved data. Please try another query or topic."))
            {
                chatResponse.choices[0].messages[index].content = t("chatbot:chat:notFoundResponse")!;
                return chatResponse;
            }
            return chatResponse;
        }
        catch
        {
            return chatResponse;
        }
    }


    const newChat = () => {
        setProcessMessages(messageStatus.Processing)
        appStateContext?.dispatch({ type: 'CLEAR_CURRENT_CHAT' });
        setWelcomeMessage("");
        setDisplayText("");
        setGeneretingText("");
        try 
        {
            clearInterval(intervalId!);
        }
        catch
        {}
        setMessages([]);
        setIsSupportInProgress(false);
        setSupportStep(0);
        setSelectedApp(new Item());
        setIsCitationPanelOpen(false);
        setActiveCitation(undefined);
        setProcessMessages(messageStatus.Done);
        setFirstLoading(false);

        loadInitialData().finally(() => {
        });
    };

    const stopGenerating = () => {
        abortFuncs.current.forEach(a => a.abort());
        setDisplayText("");
        setGeneretingText("");
        try 
        {
            clearInterval(intervalId!);
        }
        catch
        {}

        let text = generetingText;
        if (text)
        {
            const conversation = appStateContext?.state?.currentChat
            let chatMsg: ChatMessage = {
                id: uuid(),
                role: "assistant",
                content: text,
                date: new Date().toISOString(),
                type: ChatMessageType.Conversation
            }
            conversation!.messages.push(chatMsg);
            appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation! });
            setMessages([...messages, chatMsg]);
        }
        setShowLoadingMessage(false);
        setIsLoading(false);
    }

    const enableDisableClearButton = () => {
        const clearButton = document.getElementById('clearCurrentChat');
        const clearButtonIcon = document.getElementById('ICON-clearCurrentChat');

        if(clearButton !== null){
            if(isClearButtonDisabled()){
                clearButton.setAttribute("disabled","");

                clearButton.style.background = "#BDBDBD";
                clearButton.style.cursor = "";
            }
            else{
                clearButton.removeAttribute("disabled");
                
                clearButton.style.removeProperty("background");
                clearButton.style.removeProperty("cursor");
                clearButton.onclick = () => newChat();
            }

            if(clearButtonIcon !== null){
                if(isClearButtonDisabled()){
                    clearButtonIcon.style.color = "#FFFF";
                    clearButtonIcon.style.cursor = "";
                }
                else{
                    clearButtonIcon.style.removeProperty("color");
                    clearButtonIcon.style.removeProperty("cursor");
                }
            }
        }
    }

    const enableDisableSupportButton = () => {
        const supportButton = document.getElementById('btnSupportRequest');
        const supportButtonIcon = document.getElementById('ICON-btnSupportRequest');

        if(supportButton !== null){
            if(isSupportButtonDisabled()){
                supportButton.setAttribute("disabled","");

                supportButton.style.background = "#BDBDBD";
                supportButton.style.cursor = "";
            }
            else{
                supportButton.removeAttribute("disabled");
                
                supportButton.style.removeProperty("background");
                supportButton.style.removeProperty("cursor");
            }

            if(supportButtonIcon !== null){
                if(isSupportButtonDisabled()){
                    supportButtonIcon.style.color = "#FFFF";
                    supportButtonIcon.style.cursor = "";
                }
                else{
                    supportButtonIcon.style.removeProperty("color");
                    supportButtonIcon.style.removeProperty("cursor");
                }
            }
        }
    }

    const sendWelcomeMessage = (): string => {
        setProcessMessages(messageStatus.Processing);

        let conversation: Conversation | null | undefined;
        let currentSupportId = HttpHelper.getParameter("supportId");
        if (currentSupportId)
        {
            if((appStateContext?.state?.currentChat !== null) && (appStateContext?.state?.currentChat !== undefined)){
                conversation = appStateContext?.state?.currentChat;
            }
            else{
                conversation = {
                    id: currentSupportId,
                    title: "Advanced support",
                    languageCode: i18next.resolvedLanguage.toUpperCase(),
                    messages: [
                    ],
                    date: new Date().toISOString(),
                }
            };

            setMessages(conversation.messages);
            appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
            setProcessMessages(messageStatus.Done);

            startSupportRequestFlow(conversation);
        }
        else
        {
            let questionMessage: ChatMessage;

            var context = ChatbotHelper.getContext();
            if (context == ChatbotContext.Eos)
            {
                questionMessage = {
                    id: uuid(),
                    role: "assistant",
                    content: t("chatbot:chat:welcomeMessage")!,
                    date: new Date().toISOString(),
                    type: ChatMessageType.BotRequest,
                    subType: "eosWelcomeMessage"
                };
            }
            else
            {
                questionMessage = {
                    id: uuid(),
                    role: "assistant",
                    content: welcomeMessage,
                    isLogged: true,
                    date: new Date().toISOString(),
                    type: ChatMessageType.Conversation,
                    subType: ""
                };
            }

            if((appStateContext?.state?.currentChat !== null) && (appStateContext?.state?.currentChat !== undefined)){
                conversation = appStateContext?.state?.currentChat;
            }
            else
            {                
                conversation = {
                    id: uuid(),
                    languageCode: i18next.resolvedLanguage.toUpperCase(),
                    title: t("chatbot:chat:welcomeMessage")!,
                    messages: [questionMessage],
                    date: new Date().toISOString(),
                }
            }

            setMessages(conversation.messages);
            appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
            setProcessMessages(messageStatus.Done);
        }
        return conversation.id;
    }

    const sendOpenSupportMessage = () => {
        let supportUserInput: SupportUserInput = {
            key: 'support',
            value: 'support'
        };

        handleUserInput(supportUserInput);
    }

    useEffect(() => {
        enableDisableClearButton();
        enableDisableSupportButton();
        
        if (appStateContext?.state.currentChat) {
            setMessages(appStateContext.state.currentChat.messages);
            
            dispatchSupportEvent();
        }else{
            setMessages([])
        }

    }, [appStateContext?.state.currentChat]);
    
    useLayoutEffect(() => {
        const saveToDB = async (messages: ChatMessage[], id: string) => {
            const response = await historyUpdate(messages, id)
            return response
        }

        if (appStateContext && appStateContext.state.currentChat && processMessages === messageStatus.Done) {
                if(appStateContext.state.isCosmosDBAvailable.cosmosDB){
                    if(!appStateContext?.state.currentChat?.messages){
                        console.error("Failure fetching current chat state.")
                        return 
                    }
                    saveToDB(appStateContext.state.currentChat.messages, appStateContext.state.currentChat.id)
                    .then((res) => {
                        if(!res.ok){
                            let errorMessage = "An error occurred. Answers can't be saved at this time. If the problem persists, please contact the site administrator.";
                            let errorChatMsg: ChatMessage = {
                                id: uuid(),
                                role: "error",
                                content: errorMessage,
                                date: new Date().toISOString(),
                                type: ChatMessageType.Conversation
                            }
                            if(!appStateContext?.state.currentChat?.messages){
                                let err: Error = {
                                    ...new Error,
                                    message: "Failure fetching current chat state."
                                }
                                throw err
                            }
                            setMessages([...appStateContext?.state.currentChat?.messages, errorChatMsg])
                        }
                        return res as Response
                    })
                    .catch((err) => {
                        console.error("Error: ", err)
                        let errRes: Response = {
                            ...new Response,
                            ok: false,
                            status: 500,
                        }
                        return errRes;
                    })
                }else{
                }
                appStateContext?.dispatch({ type: 'UPDATE_CHAT_HISTORY', payload: appStateContext.state.currentChat });
                setMessages(appStateContext.state.currentChat.messages);
            setProcessMessages(messageStatus.NotRunning)
        }
    }, [processMessages]);

    useEffect(() => {
        getUserInfoList();
        dispatchClearEvent();

        ChatbotHelper.handleContext();
        loadInitialData();
    }, []);

    useEffect(() => {
        if (welcomeMessage)
        {
            sendWelcomeMessage();
            setFirstLoading(true);
        }
    }, [welcomeMessage])

    const loadInitialData = async () : Promise<boolean> => {
        try 
        {
            let message = await getWelcomeMessage();
            if (ChatbotHelper.getContext() == ChatbotContext.Eos)
            {
                let itemsData = await getItems();
                setItems(itemsData);
            }
            else
                setItems([]);
            setWelcomeMessage(message);
            return true;
        }
        catch
        {
            return false;
        }
    }

    useEffect(() => {
        chatMessageContainer.current?.scrollTo({
            top: chatMessageStreamEnd.current?.offsetTop!,
            left: 0,
            behavior: "smooth",
        }); 
        
        enableDisableClearButton();
        enableDisableSupportButton();
    }, [showLoadingMessage, processMessages]);

    const onShowCitation = (citation: Citation) => {
        setActiveCitation([citation.content, citation.id, citation.title ?? "", citation.filepath ?? "", "", ""]);
        setIsCitationPanelOpen(true);
    };

    const checkIfAnswerNeedSupport = (chatResponse: ChatResponse) : boolean => {
        try {
            let toolMessageIndex =chatResponse.choices[0].messages.findIndex(e => e.role === "tool");
            if (toolMessageIndex < 0)
                return false;

            let toolMessage = chatResponse.choices[0].messages[toolMessageIndex];
            let toolMessageContent : ToolContentEntry = JSON.parse(toolMessage.content);
            let intentArray: string[] = JSON.parse(toolMessageContent.intent);

            if ((toolMessageContent.citations.length == 0) && (intentArray.length > 0))
                return true;

            return false;
        }
        catch
        {
            return false;
        }
    }

    const parseCitationFromMessage = (message: ChatMessage) => {
        if (message?.role && message?.role === "tool") {
            try {
                const toolMessage = JSON.parse(message.content.toString()) as ToolMessageContent;
                return toolMessage.citations;
            }
            catch {
                return [];
            }
        }
        return [];
    }

    const isClearButtonDisabled = () => {
        return isLoading || (messages && messages.length === 0) || clearingChat || appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Loading
    }

    const isSupportButtonDisabled = () => {
        return isLoading || clearingChat || isSupportInProgress
    }

    const handleSupportInput = (key: any, value: any) => {
        let userInput: SupportUserInput = {
            key: key,
            value: value,
            systemGenerated: true
        };
        
        handleUserInput(userInput);
    }

    const handleElement = (msg: ChatMessage) : JSX.Element => {
        switch (msg.subType)
        {
            case "welcomeMessage":
                return <WelcomeMessage isSupportInProgress={isSupportInProgress} onLinkClick={sendOpenSupportMessage} />
            case "eosWelcomeMessage":
                return <EosAppWelcomeMessage items={items} OnSelectApp={(app) => {
                    setSelectedApp(app);

                    const appMessage: ChatMessage = {
                        id: uuid(),
                        role: "assistant",
                        content: t("chatbot:eosAppWelcome:selection")!.replace("#APPDESCRIPTION#", app.description),
                        date: new Date().toISOString(),
                        type: ChatMessageType.Conversation
                    };
    
                    let conversation = appStateContext?.state?.currentChat;
                    conversation?.messages.push(appMessage);
                    appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation! });
                    setMessages(conversation!.messages);
                }} />
            default:
                return supportFlowHandler!.handleElement(msg, handleSupportInput);
        }
        return <></>;
    }

    const onQuestionInput = (question: string, conversationId?: string) => {
        if ((selectedApp?.itemErpNo) && (!isSupportInProgress))
        {
            let aus = question;
            question = t("chatbot:eosAppWelcome:prefix")!.replace("#APP#", selectedApp!.description).replace("#QUESTION#", aus);
        }

        let questionUserInput: SupportUserInput = {
            key: 'question',
            value: question
        };

        handleUserInput(questionUserInput, conversationId);
    }

    const dispatchClearEvent = () => {
        const clearButton = document.getElementById('clearCurrentChat');
        clearButton?.addEventListener('click', function (this: HTMLElement, ev: any) {
            newChat();
        });
    }

    const dispatchSupportEvent = () => {
        const supportButton = document.getElementById('btnSupportRequest');
        supportButton?.addEventListener('click', function (this: HTMLElement, ev: any) {
            sendOpenSupportMessage();
        });
    }

    const handleFastActionsElements = (): JSX.Element[] => {
        let fastActions: JSX.Element[] = [];       
        if ((isSupportInProgress) && (supportFlowHandler) && (!isLoading))
        {
            let supportActions = supportFlowHandler.handleFastActions(supportStep, handleSupportInput);
            if (supportActions)
                fastActions.push(supportActions);
        }

        
        let show = fastActions.length > 0;
        if (show != showFastActions)    
            setShowFastActions(show);

        return fastActions;
    }
    
    let chatInputStyle = styles.chatInput;
    if ((showFastActions) && (HttpHelper.getParameter("embedded") != "true"))
        chatInputStyle = styles.chatInputWithFastActions;


    if (firstLoading)
    {
    return (
        <div className={styles.container} role="main">
                <Stack horizontal className={styles.chatRoot}>
                    <div className={styles.chatContainer}>
                        {!messages || messages.length < 1 ? (
                            <Stack className={styles.chatEmptyState}>
                                <img
                                    src={ChatbotHelper.getLogo()}
                                    className={styles.chatIcon}
                                    aria-hidden="true"
                                />
                                <h1 className={styles.chatEmptyStateTitle}>{t("chatbot:chat:title")}</h1>
                                <h2 className={styles.chatEmptyStateSubtitle}>{t("chatbot:chat:subTitle")}</h2>
                            </Stack>
                        ) : (
                            <div className={styles.chatMessageStream} ref={chatMessageContainer} role="log">
                                {messages.map((answer, index) => {
                                    return (
                                    <>
                                        {
                                            answer.type === ChatMessageType.Conversation ?   
                                            (answer.role === "user" ? (
                                                <div className={styles.chatMessageUser} tabIndex={0}>
                                                    <div className={styles.chatMessageUserMessage}>{answer.content}</div>
                                                </div>
                                            ) : (
                                                answer.role === "assistant" ? <div className={styles.chatMessageGpt}>
                                                    <Answer
                                                        answer={{
                                                            answer: answer.content.toString(),
                                                            citations: parseCitationFromMessage(messages[index - 1]),
                                                        }}
                                                        followupQuestions={answer.followupQuestions}
                                                        onCitationClicked={c => onShowCitation(c)}
                                                        onFollowUpQuestionClicked={(question) => {
                                                            setGeneretingText("");
                                                            setDisplayText("");
                                                            onQuestionInput(question, appStateContext?.state.currentChat?.id);
                                                        }}
                                                        onOpenSupportRequestClick={() => {
                                                            setGeneretingText("");
                                                            setDisplayText("");
                                                            startSupportRequestFlow(undefined);
                                                        }}
                                                        isLastIteraction={(index + 1) == messages.length}
                                                        showFeedbackIcons
                                                        feedbackMessage={answer}
                                                    />
                                                </div> : answer.role === "error" ? <div className={styles.chatMessageError}>
                                                    <Stack horizontal className={styles.chatMessageErrorContent}>
                                                        <Icon iconName="Error" className={styles.errorIcon} style={{color: "rgba(182, 52, 67, 1)"}} />
                                                        <span>{t("chatbot:chat:error")}</span>
                                                    </Stack>
                                                    <span className={styles.chatMessageErrorContent}>{answer.content}</span>
                                                </div> : <></>
                                            ))
                                            :
                                            ( answer.subType ?
                                            <div className={styles.chatMessageGpt}>
                                                {handleElement(answer)}
                                            </div> : <></>)
                                        }

                                    </>
                                )})}
                                {showLoadingMessage && (
                                    <>
                                        <div className={styles.chatMessageGpt}>
                                            <Answer
                                                answer={{
                                                    answer: displayText.length == 0 ? t("chatbot:chat:generateText")! : displayText,
                                                    citations: []
                                                }}
                                                onCitationClicked={() => null}
                                                isLastIteraction={false}
                                                onOpenSupportRequestClick={() => {}}
                                            />
                                        </div>
                                    </>
                                )}
                                <div ref={chatMessageStreamEnd} />
                            </div>
                        )}

                        <Stack horizontal className={chatInputStyle}>
                            {
                                (HttpHelper.getParameter("embedded") === "true") ?
                                <></>
                                :
                                <>
                                    <Stack horizontal className={styles.supportFastActionsContainer} tokens={{
                                        childrenGap: 15
                                    }}>
                                        {handleFastActionsElements()}
                                    </Stack>
                            </>
                            }
                            <Stack>
                                <Dialog
                                    hidden={hideErrorDialog}
                                    onDismiss={handleErrorDialogClose}
                                    dialogContentProps={errorDialogContentProps}
                                    modalProps={modalProps}
                                >
                                </Dialog>
                            </Stack>
                            <QuestionInput
                                clearOnSend
                                placeholder={t("chatbot:chat:questionInput")!}
                                disabled={isLoading}
                                showSupportIcon={! ((HttpHelper.getParameter("embedded") === "true") && (ChatbotHelper.getContext() != ChatbotContext.Eos))}
                                onSend={(question, id) => {
                                    onQuestionInput(question, id);
                                }}
                                onStopGenerating={stopGenerating}
                                conversationId={appStateContext?.state.currentChat?.id ? appStateContext?.state.currentChat?.id : undefined}
                            />
                        </Stack>
                    </div>
                    {messages && messages.length > 0 && isCitationPanelOpen && activeCitation && (
                    <Stack.Item className={styles.citationPanel} tabIndex={0} role="tabpanel" aria-label="Citations Panel">
                        <Stack aria-label="Citations Panel Header Container" horizontal className={styles.citationPanelHeaderContainer} horizontalAlign="space-between" verticalAlign="center">
                            <span aria-label="Citations" className={styles.citationPanelHeader}>{t("chatbot:chat:citationDialog")!}</span>
                            <IconButton iconProps={{ iconName: 'Cancel'}} aria-label="Close citations panel" onClick={() => setIsCitationPanelOpen(false)}/>
                        </Stack>
                        <h5 className={styles.citationPanelTitle} tabIndex={0}>{activeCitation[2]}</h5>
                        <div tabIndex={0}> 
                        <ReactMarkdown 
                            linkTarget="_blank"
                            className={styles.citationPanelContent}
                            children={activeCitation[0]} 
                            remarkPlugins={[remarkGfm]}
                        />
                        </div>
                        
                    </Stack.Item>
                )}
                {(appStateContext?.state.isChatHistoryOpen && appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured) && <ChatHistoryPanel/>}
                </Stack>
        </div>
    );
    }
    else
        return (
            <Loader text="" />
        )
};

export default Chat;
