import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import './RichTextEditor.css'

export interface IRichTextEditorProps {
    data?: any;
    onChange: (data: any) => void;
}

export const RichTextEditor : React.FC<IRichTextEditorProps> = (props) =>  {
    return (
        <CKEditor
            editor={ Editor }
            data={props.data}
            onChange={ (event:any, editor:any) => {
                props.onChange(editor.getData());
            }}
        />
    );
}