import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminNotificationTemplateVariableCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda notification template variable"} endpoint="/odata/notification/template/variable" ref={ref}>
            <CardGroup name="general" label="General" isOpen={true}>
                <CardField name="variableName" type={SystemFieldType.String} label="Nome variabile" />
                <CardField name="constantValue" type={SystemFieldType.String} label="Valore constante" />
                <CardField name="appendConstant" type={SystemFieldType.Boolean} label="Appendi costante" />
                <CardField name="appendBefore" type={SystemFieldType.Boolean} label="Appendi prima" />
            </CardGroup>
        </PageContainer>
    );
});