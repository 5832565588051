import React, { forwardRef} from "react";
import {SystemFieldType, SystemPageType} from "../../../../Model/SystemModels";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import { CardField } from "../../../Common/Template/Card/CardField";

export const AdminCrmLossCauseCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda causa perdita  CRM "} endpoint="odata/crm/losscause" ref={ref}>
            <CardGroup name="lossCause" label="Causa Perdita" isOpen={false}>
                <CardField name="code" type={SystemFieldType.String} label="Codice"/>
                <CardField name="description" type={SystemFieldType.String} label="Descrizione"/>
            </CardGroup> 
        </PageContainer>
    );
});