import React, {forwardRef} from "react";
import {SystemFieldType,SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, {PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminOpenAISetupCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} endpoint="/odata/openai/setup" title={'Setup OpenAI'} ref={ref}>
            <CardGroup name="general" label="Generale" isOpen>
                <CardField name="primaryKey" label="Chiave primaria" type={SystemFieldType.String} />
                <CardField name="azureKeyCredential" label="Azure key credential" type={SystemFieldType.String} />
                <CardField name="azureServiceEndpoint" label="Azure service endpoint" type={SystemFieldType.String} />
                <CardField name="azureEmbeddingDeploymentName" label="Azure embedding deployment name" type={SystemFieldType.String} />
                <CardField name="azureSearchDeploymentName" label="Azure search deployment name" type={SystemFieldType.String} />
                <CardField name="scoreLimit" label="Score limit" type={SystemFieldType.Number} />
            </CardGroup>
        </PageContainer>    
    );
});