import React, { useEffect,useState } from "react";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { Loader } from "../../../Common/Loader/Loader";
import { SystemActionCategory, SystemDataLoadingStatus,SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { useNavigate } from "react-router-dom";
import { EndPointSolidIcon } from "@fluentui/react-icons-mdl2";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";

export const AdminSubscriptionHeaderList : React.FC = () => {
    const navigate = useNavigate();
    let listTitle = "Lista sottoscrizioni";
     
    const buildEndpointList = () : string =>{
        const subscriptionFilter = HttpHelper.getParameter("subscriptionFilter");
        const companyName = HttpHelper.getParameter("name");
        const customerId = HttpHelper.getParameter("customerId");
        let endpoint="/odata/subscription";
        
        switch(subscriptionFilter){
            case "active":
                listTitle += " attive";
                endpoint += "?$filter=source ne \'trial\' and (status eq \'active\' or status eq \'pass_due\')&$orderby=systemCreationDate desc";
                break;
            case "customer":
                listTitle += " di "+companyName;
                endpoint += "?$filter=customerId eq "+customerId+" and source ne \'trial\' and (status eq \'active\' or status eq \'pass_due\')&$orderby=systemCreationDate desc";
                break;
        }
        return endpoint;
    }

    return (
        <>
            <PageContainer endpoint={buildEndpointList()} pageType={SystemPageType.List} title={listTitle} backAllowed>                            
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="OpenSubscriptionLine" label="Dettaglio sottoscrizione" iconName="BulletedList2" runOnRec isPromoted onClick={(req:any)=>{
                                navigate('/app/admin/administrative/subscriptionLine?headerId='+req.id);
                            }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Numero Documento" fieldName="documentNo" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry name="Stato" fieldName="status" minWidth={100} maxWidth={100} />
                    <ListHeaderEntry name="Origine" fieldName="source" minWidth={100} maxWidth={100} />
                    <ListHeaderEntry name="Posting Date" fieldName="postingDate" minWidth={200} maxWidth={200} />
                    <ListHeaderEntry name="Ricorrenza" fieldName="recurringInterval" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Data disdetta" fieldName="cancellationDate" minWidth={200} maxWidth={200} />
                    <ListHeaderEntry name="Processato" fieldName="processed" minWidth={100} maxWidth={100} />
                    <ListHeaderEntry name="Id Partner" fieldName="partnerId" minWidth={80} maxWidth={80} />
                    <ListHeaderEntry name="Id Cliente" fieldName="customerId" minWidth={80} maxWidth={80} />
                    <ListHeaderEntry name="Id" fieldName="id" minWidth={80} maxWidth={80} />
                </PageDefinitionContainer>
            </PageContainer>
        </>
    );
}