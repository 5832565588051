import { IComboBoxOption } from "@fluentui/react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../../../ApplicationCode/Admin/AdminService";
import { SystemCore } from "../../../../../Core/System/SystemCore";
import { MessageType, ToastService } from "../../../../../Core/Toast/ToastService";
import { SystemActionCategory, SystemDataLoadingStatus, SystemFieldType, SystemPageType } from "../../../../../Model/SystemModels";
import { Loader } from "../../../../Common/Loader/Loader";
import { ActionArea } from "../../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../../Common/Template/PageDefinitionContainer";
import { AdminCustomerListDialog } from "../../../AdminCustomerListDialog/AdminCustomerListDialog";
import { AdminAzureGDAPRelationshipCard } from "../AdminAzureGDAPRelationshipCard/AdminAzureGDAPRelationshipCard";

export const AdminAzureGDAPRelationshipList : React.FC = () => {
    
    const cardRef = useRef<PageContainerBase>(null);
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [loaderMessage, setLoaderMessage] = useState("");
    const [showCustomerDialog, setShowCustomerDialog] = useState(false);
    const [customerCmbOptions, setCustomerCmbOptions] = useState<IComboBoxOption[]>([]);
    const [dialogError, setDialogError] = useState("");
    const adminService = new AdminService();
    const navigate = useNavigate();

    useEffect(() => {
        if(dataStatus === SystemDataLoadingStatus.ToLoad){
            buildCustomerCmbOptions();
        }
    }, [dataStatus])

    const buildCustomerCmbOptions = ()=>{
        setLoaderMessage("Stiamo caricando la lista dei clienti, attendere...");
        setDataStatus(SystemDataLoadingStatus.Loading);

        adminService.deleteSubscriptionMatch().then((resp) => {
            adminService.getCustomers().then((resp)=>{
                let options : IComboBoxOption[] = [];
                resp.forEach((item:any)=>{
                    options.push({key: item.id, text: item.companyName});
                })
                setCustomerCmbOptions(options);
                setLoaderMessage("");
                setDataStatus(SystemDataLoadingStatus.Loaded);
            })
            .catch(()=>{
                setLoaderMessage("");
                setDataStatus(SystemDataLoadingStatus.Loaded);
                ToastService.showMessage(MessageType.Error,"Errore nel recupero dei clienti");
            })
        })
        .catch((err) => {
            setLoaderMessage("");
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Error,"Errore nell'inizializzazione della tabella: "+err.message);
        })
    }
    
    const createGDAPRelationship = (customerId: number, customerName: string) => {
        if (customerId !== undefined && customerId > 0){
            setLoaderMessage("Creazione relazione GDAP Cliente "+customerName+" in corso ...");
            setDataStatus(SystemDataLoadingStatus.Loading);

            adminService.createGDAPRelationship(customerId)
                .then(() => {
                    setDataStatus(SystemDataLoadingStatus.Loaded);
                    setLoaderMessage("");
                    setShowCustomerDialog(false);

                    ToastService.showMessage(MessageType.Success, "Creazione relazione GDAP Cliente "+customerName+" completata con successo");
                })
                .catch((err) => {
                    setDataStatus(SystemDataLoadingStatus.Loaded);
                    setLoaderMessage("");
                    setShowCustomerDialog(false);
                    ToastService.showMessage(MessageType.Error, "Errore durante la creazione relazione GDAP Cliente "+customerName+": " + err.message);
                })
        }
        else
            setDialogError("Devi inserire un Cliente valido");
    }

    const finalizeGDAPRelationship = (relationshipId: string) => {
        setLoaderMessage("Finalizzazione relazione GDAP Id "+relationshipId+" in corso ...");
        setDataStatus(SystemDataLoadingStatus.Loading);

        adminService.finalizeGDAPRelationship(relationshipId)
            .then(() => {
                setDataStatus(SystemDataLoadingStatus.Loaded);
                setLoaderMessage("");
                ToastService.showMessage(MessageType.Success, "Finalizzazione relazione GDAP "+relationshipId+" completata con successo");
            })            
            .catch((err) => {
                setDataStatus(SystemDataLoadingStatus.Loaded);
                setLoaderMessage("");
                ToastService.showMessage(MessageType.Error, "Errore durante la finalizzazione relazione GDAP "+relationshipId+": " + err.message);
            })
    }

    const syncronizeGDAPRelationships = () => {
        setLoaderMessage("Sincronizzazione relazioni GDAP in corso ...");
        setDataStatus(SystemDataLoadingStatus.Loading);

        adminService.syncronizeGDAPRelationships()
            .then(() => {
                setDataStatus(SystemDataLoadingStatus.Loaded);
                setLoaderMessage("");
                ToastService.showMessage(MessageType.Success, "Sincronizzazione relazioni GDAP completata con successo");
            })            
            .catch((err) => {
                setDataStatus(SystemDataLoadingStatus.Loaded);
                setLoaderMessage("");
                ToastService.showMessage(MessageType.Error, "Errore durante la sincronizzazione relazioni GDAP : " + err.message);
            })
    }

    const updateGDAPRelationship = (relationshipId: string) => {
        setLoaderMessage("Aggiornamento relazione GDAP Id "+relationshipId+" in corso ...");
        setDataStatus(SystemDataLoadingStatus.Loading);

        adminService.updateGDAPRelationship(relationshipId)
            .then(() => {
                setDataStatus(SystemDataLoadingStatus.Loaded);
                setLoaderMessage("");
                ToastService.showMessage(MessageType.Success, "Aggiornamento relazione GDAP "+relationshipId+" completata con successo");
            })            
            .catch((err) => {
                setDataStatus(SystemDataLoadingStatus.Loaded);
                setLoaderMessage("");
                ToastService.showMessage(MessageType.Error, "Errore durante l'aggiornamento relazione GDAP "+relationshipId+": " + err.message);
            })
    }

    const addSecurityGroupToRelationship = (relationshipId: string) => {
        setLoaderMessage("Aggiunta gruppi sicurezza relazione GDAP Id "+relationshipId+" in corso ...");
        setDataStatus(SystemDataLoadingStatus.Loading);

        adminService.addSecurityGroupToRelationship(relationshipId)
            .then(() => {
                setDataStatus(SystemDataLoadingStatus.Loaded);
                setLoaderMessage("");
                ToastService.showMessage(MessageType.Success, "Aggiunta gruppi sicurezza relazione GDAP "+relationshipId+" completata con successo");
            })            
            .catch((err) => {
                setDataStatus(SystemDataLoadingStatus.Loaded);
                setLoaderMessage("");
                ToastService.showMessage(MessageType.Error, "Errore durante l'aggiunta gruppi sicurezza relazione GDAP "+relationshipId+": " + err.message);
            })
    }
    
    if (!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={loaderMessage}></Loader>;
    else
        return (
            <>
                <PageContainer endpoint={'/odata/azure/gdap/relationship'} pageType={SystemPageType.List} title={"Lista relazioni azure GDAP"} cardRef={cardRef} backAllowed showAllowed>
                    <PageDefinitionContainer type={ContainerType.Action}>
                        <ActionArea category={SystemActionCategory.Navigation}>
                            <ActionEntry iconName="HomeGroup" name="OpenAzureGDAPRole" label="Ruoli GDAP" onClick={() => {
                                navigate("/app/admin/administrative/azureGDAPRole");
                            }}>
                            </ActionEntry>
                            <ActionEntry iconName="SecurityGroup" name="OpenAzureGDAPSecurityGroup" label="Gruppi sicurezza GDAP" onClick={() => {
                                navigate("/app/admin/administrative/azureGDAPSecurityGroup");
                            }}>
                            </ActionEntry>
                            <ActionEntry iconName="AddLink" name="OpenAzureGDAPSecurityGroupRole" label="Gruppi sicurezza / ruoli GDAP" onClick={() => {
                                navigate("/app/admin/administrative/azureGDAPSecurityGroupRole");
                            }}>
                            </ActionEntry>
                            <ActionEntry iconName="PartyLeader" name="OpenAzureGDAPCustomerRelationshipRole" label="Ruoli relazioni clienti GDAP" onClick={() => {
                                navigate("/app/admin/administrative/azureGDAPCustomerRelationshipRole");
                            }}>
                            </ActionEntry>
                        </ActionArea>
                        <ActionArea category={SystemActionCategory.Process}>
                            <ActionEntry name="syncronizeGDAPRelationships" label="Sincronizza relazioni GDAP" iconName="Sync" onClick={() => {
                                syncronizeGDAPRelationships();
                            }}>                                
                            </ActionEntry>
                            <ActionEntry name="createGDAPRelationship" label="Crea relazione GDAP Cliente" iconName="AddLink" isPromoted onClick={() => {
                                setShowCustomerDialog(true);
                            }}>                                
                            </ActionEntry>
                            <ActionEntry name="finalizeGDAPRelationship" label="Finalizza relazione GDAP" iconName="DocumentApproval" runOnRec isPromoted onClick={(req: any) => {
                                finalizeGDAPRelationship(req.id);
                            }}>
                            </ActionEntry>
                            <ActionEntry name="updateGDAPRelationship" label="Aggiorna relazione GDAP" iconName="Refresh" runOnRec isPromoted onClick={(req: any) => {
                                updateGDAPRelationship(req.id);
                            }}>
                            </ActionEntry>
                            <ActionEntry name="addSecurityGroupToRelationship" label="Aggiungi gruppi sicurezza alla relazione" iconName="AddNotes" runOnRec isPromoted onClick={(req:any) => {
                                addSecurityGroupToRelationship(req.id);
                            }}>
                            </ActionEntry>
                        </ActionArea>
                    </PageDefinitionContainer>
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry fieldName="id" name="Id" minWidth={450} maxWidth={900} />
                        <ListHeaderEntry fieldName="displayName" name="Display Name" minWidth={300} maxWidth={900} />
                        <ListHeaderEntry fieldName="duration" name="Duration" minWidth={90} maxWidth={900} />
                        <ListHeaderEntry fieldName="customerId" name="Customer Id" minWidth={90} maxWidth={900} />
                        <ListHeaderEntry fieldName="tenantId" name="Tenant Id" minWidth={250} maxWidth={900} />                    
                        <ListHeaderEntry fieldName="customerDisplayName" name="Customer Display Name" minWidth={300} maxWidth={900} />
                        <ListHeaderEntry fieldName="status" name="Status" minWidth={80} maxWidth={900} />
                        <ListHeaderEntry fieldName="createdOn" name="Created On" minWidth={200} maxWidth={900} />
                        <ListHeaderEntry fieldName="modifiedOn" name="Modified On" minWidth={200} maxWidth={900} />
                        <ListHeaderEntry fieldName="activatedOn" name="Activated On" minWidth={200} maxWidth={900} />
                        <ListHeaderEntry fieldName="endingDate" name="Ending Date" minWidth={200} maxWidth={900} />
                        <ListHeaderEntry fieldName="customerRelationshipRoleId" name="Customer Relationship Role Id" minWidth={250} maxWidth={900} />
                        <ListHeaderEntry fieldName="signLink" name="Sign Link" minWidth={250} maxWidth={900} />
                    </PageDefinitionContainer>
                </PageContainer>
                <AdminAzureGDAPRelationshipCard ref={cardRef} />
                <AdminCustomerListDialog hidden={showCustomerDialog}
                    title="Scegli il Cliente"
                    subText="Devi inserire il Cliente per poter proseguire"
                    txtLabel="Cliente:"
                    cmbOptions={customerCmbOptions} 
                    fieldType={SystemFieldType.Option}
                    onSuccess={(customerId: number, customerName: string)=>{createGDAPRelationship(customerId, customerName)}}
                    onDismiss={()=>{setShowCustomerDialog(false)}}
                    errorMessage={dialogError} />
            </>
        );
}