import React, { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../Model/SystemModels";
import '../../../../node_modules/react-accessible-accordion/dist/fancy-example.css';
import PageContainer, { PageContainerBase } from "../../Common/Template/PageContainer";
import { CardField } from "../../Common/Template/Card/CardField";
import { CardGroup } from "../../Common/Template/Card/CardGroup";
import { t } from "i18next";

export const AdminLeadCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} endpoint="/odata/lead" title={t("adminCenter:leadCard:title")} ref={ref}>
        <CardGroup name="general" label={t("adminCenter:leadCard:general")} isOpen={true}>
            <CardField name="companyName" type={SystemFieldType.String} label={t("adminCenter:leadCard:companyName")}/>
            <CardField name="email" type={SystemFieldType.String} label={t("adminCenter:leadCard:email")}/>
            <CardField name="firstname" type={SystemFieldType.String} label={t("adminCenter:leadCard:firstname")} />
            <CardField name="lastname" type={SystemFieldType.String} label={t("adminCenter:leadCard:lastname")} />
            <CardField name="phone" type={SystemFieldType.String} label={t("adminCenter:leadCard:phone")} />
            <CardField name="vat" type={SystemFieldType.String} label={t("adminCenter:leadCard:vat")} />
            <CardField name="tokenForDemo" type={SystemFieldType.String} label={t("adminCenter:leadCard:tokenForDemo")} readonly />
            <CardField name="score" type={SystemFieldType.String} label={t("adminCenter:leadCard:score")} readonly />
            <CardField name="subject" type={SystemFieldType.String} label={t("adminCenter:leadCard:subject")} readonly />
            <CardField name="crmTrackingCode" type={SystemFieldType.String} label={t("adminCenter:leadCard:crmTrackingCode")} readonly />
            <CardField name="owningUserId" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/user" tableRelationKey="id" tableRelationField="displayName" tableRelationFilter="?$filter=crmSystemUserId ne null" label={t("adminCenter:leadCard:owningUserId")} />
        </CardGroup>
        <CardGroup name="localization" label={t("adminCenter:leadCard:localization")} isOpen={true}>
            <CardField label={t("adminCenter:leadCard:legalEntityCode")} name="legalEntityCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/crm/legal/entity" tableRelationKey="code" tableRelationField="name" />
            <CardField label={t("adminCenter:leadCard:distributorCode")} name="distributorCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/distributor" tableRelationKey="code" tableRelationField="name" />
            <CardField label={t("adminCenter:leadCard:partnerId")} name="partnerId" type={SystemFieldType.Number} hasTableRelation tableRelationEndpoint="/odata/partner" tableRelationKey="id" tableRelationField="name" />
            <CardField label={t("adminCenter:leadCard:partnerBusinessContractCode")} name="partnerBusinessContractCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/partner/business/contract" tableRelationKey="code" tableRelationField="description" />
            <CardField label={t("adminCenter:leadCard:businessLineCode")} name="businessLineCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/business/line" tableRelationKey="code" tableRelationField="description" />
            <CardField label={t("adminCenter:leadCard:languageCode")} name="languageCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/system/language" tableRelationKey="code" tableRelationField="name" />
        </CardGroup>
        <CardGroup name="status" label={t("adminCenter:leadCard:statusCode")} isOpen>
            <CardField label={t("adminCenter:leadCard:statusCode")} name="statusCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/datasecurity/status" tableRelationFilter={"?$filter=entityName eq 'Lead'"} tableRelationKey="code" tableRelationField="description" readonly />
            <CardField label={t("adminCenter:leadCard:interestConfirmed")} name="interestConfirmed" type={SystemFieldType.Boolean} />
            <CardField label={t("adminCenter:leadCard:marketingQualificationCarriedOut")} name="marketingQualificationCarriedOut" type={SystemFieldType.Boolean} />
            <CardField label={t("adminCenter:leadCard:firstLoginCompleted")} name="firstLoginCompleted" type={SystemFieldType.Boolean} readonly />
            <CardField label={t("adminCenter:leadCard:setupCompanyCompleted")} name="setupCompanyCompleted" type={SystemFieldType.Boolean} readonly />
            <CardField label={t("adminCenter:leadCard:demoBooked")} name="demoBooked" type={SystemFieldType.Boolean} />
            <CardField label={t("adminCenter:leadCard:demoDate")} name="demoDate" type={SystemFieldType.Date} />
            <CardField label={t("adminCenter:leadCard:demoCarriedOut")} name="demoCarriedOut" type={SystemFieldType.Boolean} />
            <CardField label={t("adminCenter:leadCard:demoCarriedOutBy")} name="demoCarriedOutBy" type={SystemFieldType.Number} hasTableRelation tableRelationEndpoint="odata/user" tableRelationFilter="?$filter=crmSystemUserId ne null" tableRelationKey="id" tableRelationField="displayName" />
            <CardField label={t("adminCenter:leadCard:trialStartDate")}  name="trialStartDate" type={SystemFieldType.Date} readonly />
            <CardField label={t("adminCenter:leadCard:shopOpened")}  name="shopOpened" type={SystemFieldType.Boolean} readonly />
            <CardField label={t("adminCenter:leadCard:parked")}  name="parked" type={SystemFieldType.Boolean} />
        </CardGroup>
        <CardGroup name="address" label={t("adminCenter:leadCard:address")} isOpen={false}>
            <CardField name="address" type={SystemFieldType.String} label={t("adminCenter:leadCard:address")} />
            <CardField name="zipCode" type={SystemFieldType.String} label={t("adminCenter:leadCard:zipCode")} />
            <CardField name="city" type={SystemFieldType.String} label={t("adminCenter:leadCard:city")} />
            <CardField name="county" type={SystemFieldType.String} label={t("adminCenter:leadCard:county")} />
            <CardField name="country" type={SystemFieldType.String} label={t("adminCenter:leadCard:country")} />
        </CardGroup> 
        <CardGroup name="marketingQualification" label={t("adminCenter:leadCard:marketingQualification")} isOpen={false}>
            {/* <CardField name="salesHeaderTemplateId" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="odata/salesheadertemplate" tableRelationFilter={"?$filter=Source eq 'Industry'"} tableRelationKey="id" tableRelationField="description" label={t("adminCenter:leadCard:salesHeaderTemplateId")} /> */}
            <CardField name="origin" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="odata/crm/source" tableRelationKey="code" tableRelationField="description" label={t("adminCenter:leadCard:origin")} />
            <CardField name="campaignCode" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="odata/crm/campaign" tableRelationKey="code" tableRelationField="description" label={t("adminCenter:leadCard:campaignCode")} />
            <CardField name="originName" type={SystemFieldType.String} label={t("adminCenter:leadCard:originName")} />
            <CardField name="noOfCompany" type={SystemFieldType.Number} label={t("adminCenter:leadCard:noOfCompany")}/>
            <CardField name="full_Users_No" type={SystemFieldType.Number} label={t("adminCenter:leadCard:full_Users_No")} />
            <CardField name="limited_User_No" type={SystemFieldType.Number} label={t("adminCenter:leadCard:limited_User_No")} />
            <CardField name="turnover" type={SystemFieldType.Number} label={t("adminCenter:leadCard:turnover")} />
            <CardField name="note" type={SystemFieldType.String} label={t("adminCenter:leadCard:note")} />
            <CardField name="monthlyBudget" type={SystemFieldType.Number} label={t("adminCenter:leadCard:monthlyBudget")} />
            <CardField name="noOfEmployees" type={SystemFieldType.Number} label={t("adminCenter:leadCard:noOfEmployees")} />
            <CardField name="atecoCode" type={SystemFieldType.String} label={t("adminCenter:leadCard:atecoCode")} readonly />
            <CardField name="atecoDescription" type={SystemFieldType.String} label={t("adminCenter:leadCard:atecoDescription")} readonly />
            <CardField name="companyStartDate" type={SystemFieldType.Date} label={t("adminCenter:leadCard:companyStartDate")} readonly />
            <CardField name="companyRegistrationDate" type={SystemFieldType.Date} label={t("adminCenter:leadCard:companyRegistrationDate")} readonly />
            <CardField name="companyEndDate" type={SystemFieldType.Date} label={t("adminCenter:leadCard:companyEndDate")} readonly />
            <CardField name="balanceYear" type={SystemFieldType.Number} label={t("adminCenter:leadCard:balanceYear")} readonly />
            <CardField name="netWorth" type={SystemFieldType.Number} label={t("adminCenter:leadCard:netWorth")} readonly />
            <CardField name="shareCapital" type={SystemFieldType.Number} label={t("adminCenter:leadCard:shareCapital")} readonly />
            <CardField name="totalStaffCost" type={SystemFieldType.Number} label={t("adminCenter:leadCard:totalStaffCost")} readonly />
            <CardField name="totalAssets" type={SystemFieldType.Number} label={t("adminCenter:leadCard:totalAssets")} readonly />
            <CardField name="avgGrossSalary" type={SystemFieldType.Number} label={t("adminCenter:leadCard:avgGrossSalary")} readonly />
            <CardField name="digitalLevel" type={SystemFieldType.Number} label={t("adminCenter:leadCard:digitalLevel")} />
            <CardField name="currentERPName" type={SystemFieldType.String} label={t("adminCenter:leadCard:currentERPName")} />
            <CardField name="otherConnectedSystems" type={SystemFieldType.String} label={t("adminCenter:leadCard:otherConnectedSystems")} />
            <CardField name="decisionDate" type={SystemFieldType.Date} label={t("adminCenter:leadCard:decisionDate")} />
            <CardField name="expectedLiveDate" type={SystemFieldType.Date} label={t("adminCenter:leadCard:expectedLiveDate")} />
            <CardField name="ourAgentRole" type={SystemFieldType.String} label={t("adminCenter:leadCard:ourAgentRole")} />
            <CardField name="demoPartecipants" type={SystemFieldType.String} label={t("adminCenter:leadCard:demoPartecipants")} />
            <CardField name="decisionMakerPath" type={SystemFieldType.String} label={t("adminCenter:leadCard:decisionMakerPath")} />
            <CardField name="freeTrialInterest" type={SystemFieldType.Boolean} label={t("adminCenter:leadCard:freeTrialInterest")} />
            <CardField name="isAlreadyCustomer" type={SystemFieldType.Boolean} label={t("adminCenter:leadCard:isAlreadyCustomer")} />
            <CardField name="activeMicrosoftServices" type={SystemFieldType.String} label={t("adminCenter:leadCard:activeMicrosoftServices")} />
            <CardField name="registerRequest" type={SystemFieldType.String} label={t("adminCenter:leadCard:registerRequest")} readonly />
        </CardGroup> 
        <CardGroup name="interactionsDetail" label={t("adminCenter:leadCard:interactionsDetail")} isOpen={false}>
            <CardField name="internalMeetingBooked" type={SystemFieldType.Boolean} label={t("adminCenter:leadCard:internalMeetingBooked")} />
            <CardField name="internalMeetingDate" type={SystemFieldType.Date} label={t("adminCenter:leadCard:internalMeetingDate")} />
            <CardField name="notManagedGAPEmerged" type={SystemFieldType.Boolean} label={t("adminCenter:leadCard:notManagedGAPEmerged")} />
            <CardField name="negotiationState" type={SystemFieldType.String} label={t("adminCenter:leadCard:negotiationState")} />
            <CardField name="rating" type={SystemFieldType.Option} label={t("adminCenter:leadCard:rating")} cmbOptions={[
                    {key: 0, text: t("adminCenter:leadCard:rating0")},
                    {key: 1, text: t("adminCenter:leadCard:rating1")},
                    {key: 2, text: t("adminCenter:leadCard:rating2")}
                ]} />
            <CardField name="evaluationMode" type={SystemFieldType.Option} label={t("adminCenter:leadCard:evaluationMode")} cmbOptions={[
                {key: 0, text: t("adminCenter:leadCard:evaluationMode0")},
                {key: 1, text: t("adminCenter:leadCard:evaluationMode1")},
                {key: 2, text: t("adminCenter:leadCard:evaluationMode2")}
                ]} />
            <CardField name="liveMode" type={SystemFieldType.Option} label={t("adminCenter:leadCard:liveMode")} cmbOptions={[
                {key: 0, text: t("adminCenter:leadCard:liveMode0")},
                {key: 1, text: t("adminCenter:leadCard:liveMode1")},
                {key: 2, text: t("adminCenter:leadCard:liveMode2")},
                {key: 3, text: t("adminCenter:leadCard:liveMode3")},
                {key: 4, text: t("adminCenter:leadCard:liveMode4")},
                ]} />
        </CardGroup>
        <CardGroup name="leadAutomation" label="Lead automation" isOpen={false}>
            <CardField name="lastActivityDate" type={SystemFieldType.Date} label={t("adminCenter:leadCard:general")} readonly />
            <CardField name="lastActivityType" type={SystemFieldType.Option} label={t("adminCenter:leadCard:lastActivityType")}  cmbOptions={[
                { key: 0, text: t("adminCenter:leadCard:lastActivityType0") },
                { key: 1, text: t("adminCenter:leadCard:lastActivityType1") },
                { key: 2, text: t("adminCenter:leadCard:lastActivityType2") },
                { key: 3, text: t("adminCenter:leadCard:lastActivityType3") },
                { key: 4, text: t("adminCenter:leadCard:lastActivityType4") },
                { key: 5, text: t("adminCenter:leadCard:lastActivityType5") }
            ]} readonly />
            <CardField name="lastInboundEmailDate" type={SystemFieldType.Date} label={t("adminCenter:leadCard:lastInboundEmailDate")} readonly />
            <CardField name="lastWeekInteractions" type={SystemFieldType.Number} label={t("adminCenter:leadCard:lastWeekInteractions")} readonly />
            <CardField name="lastMonthInteractions" type={SystemFieldType.Number} label={t("adminCenter:leadCard:lastMonthInteractions")} readonly />
            <CardField name="demoFeedbackRequired" type={SystemFieldType.Boolean} label={t("adminCenter:leadCard:demoFeedbackRequired")} />
            <CardField name="demoFeedbackReceived" type={SystemFieldType.Boolean} label={t("adminCenter:leadCard:demoFeedbackReceived")} />
            <CardField name="demoFeedbackRating" type={SystemFieldType.Number} label={t("adminCenter:leadCard:demoFeedbackRating")} />
            <CardField name="riskLineExists" type={SystemFieldType.Boolean} label={t("adminCenter:leadCard:riskLineExists")} readonly />
            <CardField name="riskLineHandled" type={SystemFieldType.Boolean} label={t("adminCenter:leadCard:riskLineHandled")} />
        </CardGroup> 
        <CardGroup name="offer" label={t("adminCenter:leadCard:offer")} isOpen={false}>
            <CardField name="offerLink" type={SystemFieldType.String} label={t("adminCenter:leadCard:offerLink")} />
            <CardField name="offerSent" type={SystemFieldType.Boolean} label={t("adminCenter:leadCard:offerSent")} />
            <CardField name="promoCode" type={SystemFieldType.String} label={t("adminCenter:leadCard:promoCode")} />
            <CardField name="salesHeaderTemplateCode" type={SystemFieldType.String} label={t("adminCenter:leadCard:salesHeaderTemplateCode")} readonly />
        </CardGroup>
        <CardGroup name="customer" label={t("adminCenter:leadCard:customer")} isOpen={false}>
            <CardField name="isTrial" type={SystemFieldType.Option} label={t("adminCenter:leadCard:isTrial")} cmbOptions={[
                {key: 0, text: "Empty"},
                {key: 1, text: "On"},
                {key: 2, text: "Off"},
                {key: 3, text: "Converted"},
            ]} readonly />
            <CardField name="trialRequestedDate" type={SystemFieldType.Date} label={t("adminCenter:leadCard:trialRequestedDate")} readonly />
            <CardField name="createdOn" type={SystemFieldType.String} label={t("adminCenter:leadCard:createdOn")} readonly />
        </CardGroup>
        <CardGroup name="finalOutcome" label={t("adminCenter:leadCard:finalOutcome")} isOpen={false}>
            <CardField name="finalOutcome" type={SystemFieldType.Option} label={t("adminCenter:leadCard:finalOutcome")} cmbOptions={[
                    {key: 0, text: ""},
                    {key: 1, text: t("adminCenter:leadCard:finalOutcome1")},
                    {key: 2, text: t("adminCenter:leadCard:finalOutcome2")},
                    {key: 3, text: t("adminCenter:leadCard:finalOutcome3")}
                ]} />
            <CardField name="lossCauseCode" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="odata/crm/losscause" tableRelationKey="code" tableRelationField="description" label={t("adminCenter:leadCard:lossCauseCode")} />
            <CardField name="crmStatusCode" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="odata/crm/status/code" tableRelationKey="code" tableRelationField="description" tableRelationFilter="?$filter=entityName eq 'Lead'" label={t("adminCenter:leadCard:crmStatusCode")} />
        </CardGroup>
        </PageContainer>    
    );
});