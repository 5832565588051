import React, { useRef } from "react";
import { SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminSystemEventTypeCard } from "./AdminSystemEventTypeCard";

export const AdminSystemEventTypeList : React.FC = () => {
    
    const cardRef = useRef<PageContainerBase>(null);
    
    return(
        <>
            <PageContainer endpoint="/odata/system/event/type" pageType={SystemPageType.List} title="Lista System Event Type" backAllowed showAllowed insertAllowed updateAllowed deleteAllowed cardRef={cardRef}>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Codice" fieldName="code" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry name="Unità di misura" fieldName="unitOfMeasure" minWidth={150} maxWidth={200} />
                    <ListHeaderEntry name="Score per unità di misura" fieldName="scorePerUnitOfMeasure" minWidth={200} maxWidth={300} />
                    <ListHeaderEntry name="Dimensione 1" fieldName="dimension1" minWidth={400} maxWidth={500} />
                    <ListHeaderEntry name="Dimensione 2" fieldName="dimension2" minWidth={400} maxWidth={500} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminSystemEventTypeCard ref={cardRef} />
        </>
    );
}