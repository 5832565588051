import React, {forwardRef} from "react";
import {SystemFieldType,SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, {PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminAzureSetupCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} endpoint="/odata/azure/setup" title={'Creazione nuovo utente'} ref={ref}>
            <CardGroup name="AzureSetup" label="Setup" isOpen>
                <CardField name="primaryKey" label="Chiave Primaria" type={SystemFieldType.String} />
                <CardField name="aadClientId" label="AAD Client Id" type={SystemFieldType.String} />
                <CardField name="aadClientSecret" label="AAD Client Secret" type={SystemFieldType.String} />
                <CardField name="aadTenantId" label="AAD Id tenant" type={SystemFieldType.String} />
                <CardField name="aadScope" label="AAD Scope" type={SystemFieldType.String} />
                <CardField name="aadCustoperGroupPrefix" label="AAD Prefisso Gruppo" type={SystemFieldType.String} />
                <CardField name="aadAzureapiEndPoint" label="API Endpoint" type={SystemFieldType.String} />
                <CardField name="videoBlobConnectionString" label="Video blob connection string" type={SystemFieldType.String} />
                <CardField name="videoBlobContainerName" label="Blob Container Name" type={SystemFieldType.String} />
                <CardField name="videoLinkMinutesDuration" label="Video link duration" type={SystemFieldType.Number} />
                <CardField name="aadPageFilter" label="Filtro ricerca pagine" type={SystemFieldType.Number} />
                <CardField name="sendInvitationMessage" label="Invia mail invito guest user AAD" type={SystemFieldType.Boolean} />
            </CardGroup>
        </PageContainer>    
    );
});