import React from "react";

export interface IActionEntryProps {
    name: string;
    label: string;
    iconName: string;
    runOnRec?: boolean;
    isPromoted?: boolean;
    onClick?: (record: any) => void;
    endpoint?: string;
    openInModal?: boolean;
}

export const ActionEntry: React.FC<IActionEntryProps> = (props) => {
    return (
        <></>
    );
}