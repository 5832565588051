import { useRef, useState } from "react";
import { SystemCore } from "../../../../Core/System/SystemCore";
import { SystemDataLoadingStatus, SystemPageType } from "../../../../Model/SystemModels";
import { Loader } from "../../../Common/Loader/Loader";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminCrmProfilingSetupCard } from "../AdminCrmProfilingSetupCard/AdminCrmProfilingSetupCard";

export const AdminCrmProfilingSetupList : React.FC = () => {
    const [dataStatus,setDataStatus] = useState(SystemDataLoadingStatus.Loaded);
    const [loaderMessage,setLoaderMessage] = useState("");
    const cardRef = useRef<PageContainerBase>(null);
    
    if (!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={loaderMessage}></Loader>;
    else
        return (
            <>
                <PageContainer endpoint={'/odata/crm/profiling/setup'} pageType={SystemPageType.List} title={"CRM profiling setup"} cardRef={cardRef} backAllowed showAllowed insertAllowed updateAllowed deleteAllowed>
                    <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry fieldName="primaryKey" name="Chiave primaria" minWidth={50} maxWidth={100} />
                        <ListHeaderEntry fieldName="crmAccountProfileGroupId" name="Account profile group Id" minWidth={200} maxWidth={300} />
                        <ListHeaderEntry fieldName="crmAccountProfileTrialId" name="Account profile trial Id" minWidth={200} maxWidth={300} />
                        <ListHeaderEntry fieldName="crmAccountProfileTrialName" name="Account profile trial name" minWidth={200} maxWidth={300} />
                        <ListHeaderEntry fieldName="crmAccountProfileCustomerId" name="Account profile customer Id" minWidth={200} maxWidth={300} />
                        <ListHeaderEntry fieldName="crmAccountProfileCustomerName" name="Account profile customer name" minWidth={200} maxWidth={300} />
                        <ListHeaderEntry fieldName="crmAccountProfileOnBoardingId" name="Account profile on boarding Id" minWidth={200} maxWidth={300} />
                        <ListHeaderEntry fieldName="crmAccountProfileOnBoardingName" name="Account profile on boarding name" minWidth={200} maxWidth={300} />
                        <ListHeaderEntry fieldName="crmAccountProfileSupportId" name="Account profile support Id" minWidth={200} maxWidth={300} />
                        <ListHeaderEntry fieldName="crmAccountProfileSupportName" name="Account profile support name" minWidth={200} maxWidth={300} />
                        <ListHeaderEntry fieldName="crmAccountProfileYearlySubscriptionId" name="Account profile yearly subscription Id" minWidth={200} maxWidth={300} />
                        <ListHeaderEntry fieldName="crmAccountProfileYearlySubscriptionName" name="Account profile yearly subscription name" minWidth={200} maxWidth={300} />
                        <ListHeaderEntry fieldName="crmAccountProfileMonthlySubscriptionId" name="Account profile monthly subscription Id" minWidth={200} maxWidth={300} />
                        <ListHeaderEntry fieldName="crmAccountProfileMonthlySubscriptionName" name="Account profile monthly subscription name" minWidth={200} maxWidth={300} />
                        <ListHeaderEntry fieldName="crmContactProfileGroupId" name="Contact profile group Id" minWidth={200} maxWidth={300} />
                        <ListHeaderEntry fieldName="crmContactProfileMainContactId" name="Contact profile main contact Id" minWidth={200} maxWidth={300} />
                        <ListHeaderEntry fieldName="crmContactProfileMainContactName" name="Contact profile main contact name" minWidth={200} maxWidth={300} />
                    </PageDefinitionContainer>
                </PageContainer>
                <AdminCrmProfilingSetupCard ref={cardRef} />
            </>
        );
}