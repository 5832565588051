import { useRef } from "react";
import { SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminCrmSystemUserCard } from "../AdminCrmSystemUserCard/AdminCrmSystemUserCard";

export const AdminCrmSystemUserList : React.FC = () => {
    
    const cardRef = useRef<PageContainerBase>(null);

        return (
            <>
                <PageContainer endpoint={'/odata/crm/system/user'} pageType={SystemPageType.List} title={"Lista CRM System Users"} cardRef={cardRef} backAllowed showAllowed>
                    <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry name="Id" fieldName="entryNo" minWidth={50} maxWidth={50} />
                        <ListHeaderEntry name="Nome" fieldName="firstName" minWidth={150} maxWidth={200} />
                        <ListHeaderEntry name="Cognome" fieldName="lastName" minWidth={150} maxWidth={200} />
                        <ListHeaderEntry name="Nome Completo" fieldName="fullName" minWidth={300} maxWidth={350} />
                        <ListHeaderEntry name="Indirizzo Email" fieldName="internalEmailAddress" minWidth={300} maxWidth={350} />
                        <ListHeaderEntry name="Data Creazione" fieldName="createdOn" minWidth={150} maxWidth={200} />
                        <ListHeaderEntry name="Data Modifica" fieldName="modifiedOn" minWidth={150} maxWidth={200} />
                        <ListHeaderEntry name="Id CRM" fieldName="crmReferenceId" minWidth={250} maxWidth={300} />
                    </PageDefinitionContainer>
                </PageContainer>
                <AdminCrmSystemUserCard ref={cardRef} />
            </>
        );
}