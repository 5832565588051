import React from "react";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { useNavigate } from "react-router-dom";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";

export const AdminProvisioningLineDetailsList : React.FC = () => {
    let lineNo=HttpHelper.getParameter('lineNo');
    
    return (
        <>
            <PageContainer endpoint={"/odata/provisioning/line/detail?$filter=documentLineNo eq "+lineNo} pageType={SystemPageType.List} title="Lista dettaglio avanzamenti" backAllowed>               
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Action}>
                    </ActionArea>
                </PageDefinitionContainer>     
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Numero Documento" fieldName="documentNo" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Numero Linea Documento" fieldName="documentLineNo" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Numero Linea" fieldName="lineNo" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Codice funzione" fieldName="functionCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Sincrono" fieldName="syncronous" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Ordine" fieldName="order" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Processato" fieldName="processed" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="In errore" fieldName="hasError" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Messaggio di errore" fieldName="errorMessage" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Iniziato il" fieldName="startedAt" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Completato il" fieldName="completedAt" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="System Id" fieldName="systemId" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="System Creation User Id" fieldName="systemCreationUserId" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="System Creation Date" fieldName="systemCreationDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Data aggiornamento sistema" fieldName="systemUpdateDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id utente aggiornamento sistema " fieldName="systemUpdateUserId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Timestamp" fieldName="timestamp" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
        </>
    );
}