import React, {forwardRef} from "react";
import {SystemFieldType,SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, {PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminAuthenticationSetupCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} endpoint="/odata/authentication/setup" title={'Card authentication setup'} ref={ref}>
            <CardGroup name="general" label="Generale" isOpen>
                <CardField name="code" label="Codice" type={SystemFieldType.String} />
                <CardField name="instance" label="Istanza" type={SystemFieldType.String} />
                <CardField name="tenantId" label="Tenant Id" type={SystemFieldType.String} />
                <CardField name="clientId" label="Client Id" type={SystemFieldType.String} />
                <CardField name="clientSecret" label="Client secret" type={SystemFieldType.String} />
                <CardField name="scope" label="Scope" type={SystemFieldType.String} />
                <CardField name="redirectUrl" label="Redirect Url" type={SystemFieldType.String} />
            </CardGroup>
        </PageContainer>    
    );
});