import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminItemProductSkuIdCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda notification template variable"} endpoint="/odata/item/product/sku/id" ref={ref}>
            <CardGroup name="general" label="General" isOpen={true}>
                <CardField name="itemId" type={SystemFieldType.Number} label="Id Articolo" readonly />
                <CardField name="productSkuId" type={SystemFieldType.String} label="Product Sku Id" />
            </CardGroup>
        </PageContainer>
    );
});