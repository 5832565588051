import { IColumn, ICommandBarItemProps } from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import { AdminService } from "../../../ApplicationCode/Admin/AdminService";
import { PartnerInformationContext } from "../../../ApplicationCode/Partner/PartnerInformationContext";
import { PartnerService } from "../../../ApplicationCode/Partner/PartnerService";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { ISessionStateContext, SessionStateContext } from "../../../Core/State/SessionStateContext";
import { SystemCore } from "../../../Core/System/SystemCore";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { Customer, Lead, OrderHeader, Partner, SalesHeaderTemplate } from "../../../Model/PartnerModels";
import { SystemActionCategory, SystemDataLoadingStatus, SystemEntity, SystemPage, SystemPageType } from "../../../Model/SystemModels";
import { CommonBookingModal } from "../../Common/CommonBookingModal/CommonBookingModal";
import { CommonPageList } from "../../Common/CommonPageList/CommonPageList";
import { Loader } from "../../Common/Loader/Loader";
import { ActionArea } from "../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../Common/Template/List/ListHeaderEntry";
import PageContainer from "../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../Common/Template/PageDefinitionContainer";
import { BookingModal } from "../../Demo/BookingModal/BookingModal";
import { PartnerPurchaseOrderCard } from "../PartnerPurchaseOrderCard/PartnerPurchaseOrderCard";

export const PartnerPurchaseOrderList : React.FC = () => {
    const [showDialog, setShowDialog] = useState(false);

    const partnerInfoContext = useContext(PartnerInformationContext);
    const partnerService = new PartnerService(); 
    

    return (
        <>
            <PageContainer pageType={SystemPageType.List} title="Lista ordini" endpoint="" insertAllowed updateAllowed deleteAllowed>
                <PageDefinitionContainer type={ContainerType.Action}>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Id" fieldName="id" minWidth={80} maxWidth={100} />
                </PageDefinitionContainer>
            </PageContainer>
            <PartnerPurchaseOrderCard showDialog={true} onSuccessModal={() => {}} onCancelModal={() => {}} orderHeader={new OrderHeader()} />
        </>
    );
}