import React, {useRef} from "react";
import { SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminCrmStatusCodeCard } from "../AdminCrmStatusCodeCard/AdminCrmStatusCodeCard";

export const AdminCrmStatusCodeList : React.FC = () => {
    const cardRef = useRef<PageContainerBase>(null);
    
    return (
        <>
            <PageContainer endpoint={'/odata/crm/status/code'} pageType={SystemPageType.List} title={"Lista causali squalifica"} cardRef={cardRef} backAllowed insertAllowed updateAllowed deleteAllowed>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry fieldName="entityName" name="Entità" minWidth={150} maxWidth={200} />
                    <ListHeaderEntry fieldName="code" name="Codice" minWidth={150} maxWidth={200} />
                    <ListHeaderEntry fieldName="description" name="Descrizione" minWidth={300} maxWidth={350}/>
                    <ListHeaderEntry fieldName="crmId" name="Id CRM" minWidth={80} maxWidth={150} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminCrmStatusCodeCard ref={cardRef} />
        </>
    );
}
