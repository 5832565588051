import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../../Model/SystemModels";
import { CardField } from "../../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../../Common/Template/PageContainer";

export const AdminAzureGDAPSecurityGroupRoleCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} endpoint="/odata/azure/gdap/security/group/role" title={'Card gruppo sicurezza / ruolo azure GDAP'} ref={ref}>
            <CardGroup name="localization" label="Localizzazione" isOpen={true}>
                <CardField name="legalEntityCode" label="Legal entity" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/crm/legal/entity" tableRelationKey="code" tableRelationField="name"/>
                <CardField name="distributorCode" label="Distributore" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/distributor" tableRelationKey="code" tableRelationField="name"/>
                <CardField name="partnerBusinessContractCode" label="Partner business contract" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/partner/business/contract" tableRelationKey="code" tableRelationField="description"/>
                <CardField name="languageCode" label="Lingua" type={SystemFieldType.String} />
            </CardGroup>
            <CardGroup name="general" label="Generale" isOpen>
                <CardField name="securityGroupCode" label="Codice gruppo sicurezza" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/azure/gdap/security/group" tableRelationKey="code" tableRelationField="description" />
                <CardField name="roleCode" label="Codice ruolo" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/azure/gdap/role" tableRelationKey="code" tableRelationField="name" />
                <CardField name="active" label="Attivo" type={SystemFieldType.Boolean} />
            </CardGroup>
        </PageContainer>    
    );
});