import { AnimationClassNames, IStackTokens, Label, mergeStyles, SearchBox, Separator, Stack, Toggle } from "@fluentui/react";
import { AxiosInstance } from "axios";
import { t } from "i18next";
import { relative } from "path";
import React from "react";
import { CommonFunctions } from "../../../ApplicationCode/Common/CommonFunctions";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { SessionStateContext } from "../../../Core/State/SessionStateContext";
import { Feature, Module } from "../../../Model/DemoModels";
import { DemoContainer } from "../DemoContainter/DemoContainer";
import { DemoUIHelper } from "../DemoUIHelper";
import { DemoVideoModal } from "../DemoVideoModal/DemoVideoModal";
import { FeatureList } from "../Feature/FeatureList";
import ModuleBox from "../Feature/ModuleBox";

export interface FeatureOverviewContainerProps {
    modules: Module[];
    features: Feature[];
    linkToId: string;
    refreshData: () => void;
}

export interface FeatureOverviewContainerState {
    modules: Module[];
    features: Feature[];
    selectedModule: Module;
    showAllFeatures: boolean;
    searchKeyWorld: string;
    selectedFeature: Feature;
    featureVideoUrl: string;
}


const mainStackStyle = mergeStyles([{
    width: '100%',
    minHeight: '420px',
    backgroundColor: 'white',
    display: 'flex'
},
AnimationClassNames.fadeIn500]);
const moduleStackStyle = mergeStyles([{
    width: '60%',
    height: '100%',
    borderRight: '1px solid rgb(0 0 0 / 20%)',
    padding: '5px',
    display: 'flex',
}]);
const featureStackStyle = mergeStyles([{
    width: '40%',
    height: '100%'
}]);
const stackTokens: Partial<IStackTokens> = { childrenGap: 20 };

const checkboxStyle = {
    root: {
      margin: '0 30px 20px 0',
      maxWidth: '300px',
    },
};


export class FeatureOverviewContainer extends React.Component<FeatureOverviewContainerProps, FeatureOverviewContainerState> {

    static contextType = SessionStateContext;

    constructor(props: FeatureOverviewContainerProps)
    {
        super(props);
        this.state = {
            modules: props.modules,
            features: props.features,
            selectedModule: {} as Module,
            showAllFeatures: true,
            searchKeyWorld: "",
            selectedFeature: {} as Feature,
            featureVideoUrl: ""
        };
    }

    handleModuleClick = (module: Module) => {
        if (this.state.selectedModule == module)
            this.setState({selectedModule: {} as Module, showAllFeatures: false});
        else
            this.setState({selectedModule: module, showAllFeatures: true});
    };

    toggleFirstTenFeatures = (event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => {
        if (checked!)
            this.setState({showAllFeatures: checked!});
        else
            this.setState({showAllFeatures: checked!, selectedModule: {} as Module});

    };

    handleSearchChange = (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        this.setState({searchKeyWorld: newValue!});
    };


    filterFunction = (f: Feature) => {
        if (this.state.selectedModule.code != null)
        {
            if (f.processModule != this.state.selectedModule.code)
                return false;
        }

        return (f.processDescrption.toLocaleLowerCase().indexOf(this.state.searchKeyWorld.toLocaleLowerCase()) >= 0);
    };

    onFeatureClick = async (feature: Feature) => {
        if (feature.videoLink != "")
        {
            this.setState({
                selectedFeature: feature
            });

            if (feature.videoLink.indexOf("https://") < 0)
            {
                let client: AxiosInstance = HttpHelper.buildAxiosInstance();
                let response = await client.get("api/csp/video/" + feature.videoLink + "/generate");
                if (response.status == 200)
                {
                    this.setState({
                        featureVideoUrl: response.data
                    });
                }
            }
            else
            {
                this.setState({
                    featureVideoUrl: feature.videoLink
                });
            }

            CommonFunctions.logEvent("Apertura video dialog: " + feature.processDescrption, feature.videoLink);
        }
        else if (feature.docs1Link != "")
        {
            CommonFunctions.logEvent("Apertura documentazione: " + feature.processDescrption, feature.docs1Link)
                .then(() => {
                    window.open(feature.docs1Link);
                });
        }
    }

    render() {
        let modulesContent: JSX.Element[] = [];
        this.state.modules.forEach(module => {
            modulesContent.push(<ModuleBox selected={this.state.selectedModule == module} sourceModule={module} onSelect={this.handleModuleClick}></ModuleBox>);
        });

        let currentFeatures: Feature[];
        if (! this.state.showAllFeatures)
        {
            currentFeatures = this.state.features.sort(DemoUIHelper.sortFeatureEntryArray).slice(0, 10);
        }
        else
            currentFeatures = this.state.features;   
        
        currentFeatures = currentFeatures.filter(this.filterFunction).sort(DemoUIHelper.sortFeatureEntryArray); 

        return(
            <DemoContainer linkToId={this.props.linkToId} id="featuresOverview" title={t('demoCenter:DemoContainer:featuresOverviewTitle')!} scrollId="scroll0">
                <Stack horizontal className={mainStackStyle} key="featureDataContainer">
                    <Stack.Item align="start" className={moduleStackStyle} verticalFill={true} id="moduleOverview" key="moduleOverview">
                        <Stack key="containerGroup">
                            <Stack wrap style={{width: '100%', display: 'flex', padding: '10px', height: 'auto'}}>
                            </Stack>
                            <Stack horizontal wrap style={{width: '100%', display: 'flex'}} key="modulesContainer">
                                {modulesContent}
                            </Stack>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item grow align="start" className={featureStackStyle} id="searchBox" key="searchBox">
                        <Stack.Item grow >
                            <Stack key="searcBoxMainContainer">
                                <Stack key="titleConainter" wrap style={{width: '100%', display: 'flex', paddingLeft: '10px', paddingTop: '15px'}}>
                                    <Label style={{fontSize: '20px'}}>
                                        {t('demoCenter:DemoContainer:Stack:featureOverviewContent')!}
                                        <Separator className="separator"></Separator>
                                    </Label>
                                </Stack>
                                <Stack tokens={stackTokens} key="searchBoxConainter" id="searchBoxTxt" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                                </Stack>
                            </Stack>
                        </Stack.Item>
                        <Stack.Item style={{
                            maxHeight: '250px',
                            overflowY: 'auto'
                        }}>
                            <FeatureList onItemInvoked={this.onFeatureClick} features={currentFeatures}></FeatureList>
                        </Stack.Item>
                    </Stack.Item>
                </Stack>
                <DemoVideoModal videoUrl={this.state.featureVideoUrl} 
                                videoName={this.state.selectedFeature.processDescrptionCaption} 
                                hideModal={(this.state.selectedFeature.videoLink != "" && this.state.selectedFeature.videoLink != undefined)} 
                                onCloseModal={() => { CommonFunctions.logEvent("Chiusura video dilog:"+this.state.selectedFeature.processDescrptionCaption,this.state.selectedFeature.videoLink); this.setState({ selectedFeature: {} as Feature, featureVideoUrl: "" }); }} ></DemoVideoModal>
            </DemoContainer>
        );
    }

};