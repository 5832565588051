import { Checkbox, ComboBox, DefaultButton, Dialog, DialogFooter, IComboBoxOption, IModalProps, IStackTokens, mergeStyles, MessageBar, PrimaryButton, Stack, TextField } from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from "react-accessible-accordion";
import { OperationType, SystemFieldType, SystemPageType, SystemUser } from "../../../Model/SystemModels";
import '../../../../node_modules/react-accessible-accordion/dist/fancy-example.css';
import { OrderHeader, Partner, SalesHeaderTemplate } from "../../../Model/PartnerModels";
import { PartnerInformationContext } from "../../../ApplicationCode/Partner/PartnerInformationContext";
import PageContainer from "../../Common/Template/PageContainer";
import { CardGroup } from "../../Common/Template/Card/CardGroup";
import { CardField } from "../../Common/Template/Card/CardField";
import { NONAME } from "dns";
import { Stepper, StepperContent, StepperEntries, StepperEntry, StepperEntryContent } from "../../Common/Stepper/Stepper";
import { PartnerCustomerDetailBox } from "../PartnerCustomerDetailBox/PartnerCustomerDetailBox";
import { CustomerCompany, CustomerSetupStep, TenantInformationSetup } from "../../../Model/CustomerModels";

const modalProps: Partial<IModalProps> = {
    isBlocking: true,
    dragOptions: undefined,
    styles: {
        main: {
            width: '95% !important',
            maxWidth: 'calc(95%) !important'
        }
    }
  };

const contentBodyClassName = mergeStyles([{
    width: '100%',
    overflowY: "auto",
    overflowX: "hidden"
}]);

const modalContentClassName = mergeStyles([{
    width: '100%'
}]);

const formContentClassName = mergeStyles([{
    width: '100%'
}]);

const formContentEntryClassName = mergeStyles([{
    width: '100%'
}]);

const rowContentClassName = mergeStyles([{
    width: '100%',
    marginBottom: 7
}]);

const rowContentEntryClassName = mergeStyles([{
    width: '48%'
}]);

const textFieldClassName =  mergeStyles([{
    width: '100%',
    maxWidth: 600
}]);

const rowContentStackToken: IStackTokens = {
    childrenGap: 50
};

const formContentStackToken: IStackTokens = {
    childrenGap: 20
};

export interface IPartnerPurchaseOrderCardProps {
    showDialog: boolean;
    orderHeader: OrderHeader;
    onSuccessModal: (OrderHeader: OrderHeader) => void;
    onCancelModal: () => void;
}

export const PartnerPurchaseOrderCard : React.FC<IPartnerPurchaseOrderCardProps> = (props) => {

    return (
        
        <PageContainer showModal={props.showDialog} pageType={SystemPageType.Card} title="Scheda orders" endpoint="" hideFooter>
            <Stepper>
                <StepperEntries>
                    <StepperEntry id={1} description="Dati cliente" completed />
                    <StepperEntry id={2} description="Prodotti" completed={false} />
                    <StepperEntry id={3} description="Riepilogo ordine" completed={false} />
                </StepperEntries>
               <StepperContent>
                   <StepperEntryContent id={1} successButtonText="Prosegui" onSuccessClick={() => console.log('success on id 1')} visibile={true}>
                        <PartnerCustomerDetailBox isCustomerDetailBoxVisible={true} />
                   </StepperEntryContent>
                   <StepperEntryContent id={2} successButtonText="Prosegui" onSuccessClick={() => console.log('success on id 2')} visibile={false}>
                        <PartnerCustomerDetailBox isCustomerDetailBoxVisible={true} />
                   </StepperEntryContent>
               </StepperContent>
            </Stepper>
        </PageContainer>

    );
}
