import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { SystemActionCategory, SystemOperation, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminNotificationTemplateCard } from "../AdminNotificationTemplateCard/AdminNotificationTemplateCard";

export const AdminNotificationTemplateList : React.FC = () => {
    const SetupCode = HttpHelper.getParameter("setupCode");
    const SetupType = HttpHelper.getParameter("setupType");
    const navigate = useNavigate();
    const cardRef = useRef<PageContainerBase>(null);

    const setParentParameters = ()=>{
        cardRef.current?.setRecord({
            setupCode: SetupCode,
            setupType: SetupType
        });
        
        cardRef.current?.openPage(SystemOperation.Insert);
    }
    
    return (
        <>
            <PageContainer onNewRecord={setParentParameters} endpoint={'/odata/notification/template'} pageType={SystemPageType.List} title={"Template notifiche"} cardRef={cardRef} backAllowed insertAllowed updateAllowed>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="OpenNotificationTemplateVariable" iconName="VariableGroup" label="Variabili template" runOnRec onClick={(req:any)=>{
                            navigate("/app/admin/related/notificationTemplateVariable?setupCode="+req.setupCode+"&setupType="+req.setupType+"&templateCode="+req.code);
                        }}>
                        </ActionEntry>
                    </ActionArea>
                    <ActionArea category={SystemActionCategory.Process}>
                        <ActionEntry name="SendNotification" iconName="Send" label="Invia notifica" runOnRec onClick={(rec: any)=>{
                            let adminService = new AdminService();
                            adminService.sendNotification("6-OPERATION", rec)
                                .then((resp) => {
                                    ToastService.showMessage(MessageType.Success, "Notifica inviata");
                                })
                                .catch(() => {
                                    ToastService.showMessage(MessageType.Error, "Errore nell'invio della notifica");
                                })
                        }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Codice" fieldName="code" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Template" fieldName="template" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Attivo" fieldName="active" minWidth={100} maxWidth={200} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminNotificationTemplateCard ref={cardRef} />
        </>
    );
}