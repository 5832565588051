import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";
import { t } from "i18next";
import React from "react";

export interface IGuideTourDialogProps {
    onSuccess: () => void;
    onDismiss: () => void;
    subText? :string;
    showDialog: boolean;
}

export const CommonGuideTourDialog: React.FC<IGuideTourDialogProps> = (props: IGuideTourDialogProps) => {

    const modalProps = {
        isBlocking: false,
        styles: { 
            main: { 
                maxWidth: 450,
                backgroundColor:'rgb(217, 240, 242) !important'
            }
        },
    };
    const dialogContentProps = {
      type: DialogType.normal,
      title: t('customerCenter:CommonGuideTourDialog:title'),
      subText: props.subText,
    };

    return (
        <Dialog
            hidden={!props.showDialog}
            onDismiss={props.onDismiss}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
        >
            <DialogFooter>
                <PrimaryButton 
                    onClick={props.onSuccess} 
                    styles={{
                        root: {
                            backgroundColor: '#008089',
                            borderColor: '#008089'
                        },
                        rootHovered: {
                            backgroundColor: 'rgb(1, 93, 99)',
                            borderColor: 'rgb(1, 93, 99)'
                        },
                        rootPressed: {
                            backgroundColor: 'rgb(1, 93, 99)',
                            borderColor: 'rgb(1, 93, 99)'
                        }
                    }}
                    text={t('customerCenter:CommonGuideTourDialog:primaryButtonText')!}
                />
                <DefaultButton onClick={props.onDismiss} text={t('customerCenter:CommonGuideTourDialog:defaultButtonText')!} />
            </DialogFooter>
        </Dialog>
    );
}
