import { DirectionalHint } from "@fluentui/react";
import { Guid } from "guid-typescript";
import { CustomAttachment } from "../Components/Customer/CustomerIncidentCard/CustomCustomerIncidentCard";

export enum SystemDataLoadingStatus {
    ToLoad,
    Loading,
    Loaded,
    Error
}

export class User {
    public firstName: string = "";
    public lastName: string = "";
    public displayName: string = "";
    public email: string = "";
    public referenceId: number = 0;
    public mainUser: boolean = false;
    public userId: number = 0;
    public leadId: number = 0;
}

export class SystemApp {
    public id: string = "";
    public name: string = "";
    public title: string = "";
    public role: string = "";
    public homepageUrl: string = "";
    public showChatBot: boolean = false;
    public appLogo: string = "";
    public appIcon: string = "";
    public helpPageEnable: boolean = false;
}

export class SystemRole {
    public appId: string = "";
    public code: string = "";
    public description: string = "";
    public homepageUrl: string = "";
    public default: boolean = false;
}

export class UserSettings {
    public userId: number = 0;
    public hideCustomerCommonGuideTour: boolean = false;
    public userLanguageCode: string = "";
    public systemId: string = Guid.EMPTY.toString();
}

export class UserSessionInformation {
    public user: User = new User();
    public app: SystemApp = new SystemApp();
    public userSettings: UserSettings = new UserSettings();
}

export class SystemUser {
    public id: number = 0;
    public firstName: string = "";
    public lastName: string = "";
    public displayName: string = "";
    public privateEmail: string = "";
    public userPrincipleName: string = "";
    public usageLocation: string = "";
    public azureId: string = "";
    public guestAzureId: string = "";
    public phone: string = "";
    public active: boolean = false;
    public mainUser: boolean = false;
    public customerId: number = 0;
    public partnerId: number = 0;
    public systemId: string = Guid.EMPTY.toString();
    public contactEmail: string = "";
    public crmContactId: string = "";
    public crmPartnerContactId: string = "";
}

export enum OperationType {
    View,
    Insert,
    Update,
    Delete
}

export enum SystemPageType {
    Custom,
    List,
    Card,
    CardPanel,
    CardCustom
}

export enum SystemPageCategory {
    None,
    Administrator,
    List,
    History
}

export class MenuEntry {
    public name: string = "";
    public type: SystemPageType = SystemPageType.Custom;
    public caption: string = "";
    public endpoint: string = "";
    public category: SystemPageCategory = SystemPageCategory.None;
}

export class SystemPage {
    public appId: string = "";
    public name: string = "";
    public type: SystemPageType = SystemPageType.Custom;
    public category: SystemPageCategory = SystemPageCategory.None;
    public sourceEntityName: string = "";
    public sourceEntityFilter: string = "";
    public insertAllowed: boolean = true;
    public updateAllowed: boolean = true;
    public deleteAllowed: boolean = true;
    public endpoint: string = "";
    public enabled: boolean = false;
    public systemId: string = Guid.EMPTY;
}

export class SystemEntity {
    public name: string = "";
    public endpoint: string = "";
    public enabled: boolean = false;
    public systemId: string = "";
}

export enum SystemActionCategory {
    Action,
    Process,
    Navigation
}

export enum SystemFieldType {
    String,
    Number,
    Boolean,
    Date,
    Option,
    Blob,
    TimeSpan,
    HTML,
    Attachment,
    Radio
}

export class ValidationError {
    public fieldName: string = "";
    public text: string = "";
}

export class SystemLog {
    public id: string = Guid.EMPTY;
    public appId: string = Guid.EMPTY;
    public description: string = "";
    public referenceId: string = "";
    public userId: number = 0;
}

export class SystemEventLog {
    public title: string = "";
    public description: string = "";
    public performedOn: Date = new Date();
    public duration: number = 0;
    public userId: number = 0;
    public typeCode: string = "";
    public dimension1: string = "";
    public dimension2: string = "";
}

export class SystemTableRelationEntry {
    public fieldName: string = "";
    public endpoint: string = "";
    public filter: string = "";
    public notParsedFilter: string = "";
    public data: any = undefined;
    public keyField: string = "";
    public dropDownField: string = "";
    public relatedFields: string[] = [];
    public currentValue: any = undefined;
    public loaded: boolean = false;
    public hasError: boolean = false;
    public errorMessage: string = "";
}

export class ResetPassword {
    public password: string = "";
}

export enum SystemOperation {
    Insert,
    Update,
    View,
    Delete,
    None
}

export class SystemCaption {
    public captionType: SystemCaptionType = SystemCaptionType.Entity;
    public sourceId: string = "";
    public sourceSubId: string = "";
    public languageCode: string = "";
    public originalValue: string = "";
    public translatedValue: string = "";
}

export class PageMetadata {

    public page: SystemPage = new SystemPage();
    public userLanguageCaption: string = "";
    public captions: SystemCaption[] = [];

}

export enum SystemCaptionType
{
    Entity,
    EntityProperty,
    EntityPropertyOption,
    Page,
    PageStructure,
    PageStructureAction,
    Message,
    Field,
    System
}

export class SystemEntityProperty {
    public entityName: string = "";
    public name: string = "";
    public dataType: SystemFieldType = SystemFieldType.String;
    public primaryKey: boolean = true;
    public required: boolean = false;
    public enabled: boolean = false;
    public maxLength: number = 0;
    public hasTableRelation: boolean = false;
    public tableRelationName: string = "";
    public tableRelationPropertyName: string = "";
    public tableRelationFilter: string = "";
    public optionValues: string = "";
}

export enum SystemPageStructureType {
    Field,
    Group
}

export class SystemPageStructure {
    public entryId: string = Guid.EMPTY;
	public pageId: string = "";
	public type: SystemPageStructureType = 0;
	public name: string = "";
	public firstLevelOrder: number = 0;
	public secondLevelOrder: string = "";
	public parentId: string = "";
	public disabled: boolean = false;
	public hasTableRelation: boolean = false;
	public tableRelationEntity: string = "";
	public tableRelationEntityFilter: string = "";
	public tableRelationKey: string = "";
	public tableRelationField: string = "";
	public systemId: string = Guid.EMPTY;
}

export class PageStructureMetadata {
    public pageStructure: SystemPageStructure = new SystemPageStructure();
    public userLanguageCaption: string = "";
    public captions: SystemCaption[] = [];
}

export enum ActionType {
    OpenLink,
    OpenPage
}

export class UserApplicationTask {
    public description: string = "";
    public type: ActionType = ActionType.OpenLink;
    public externalLink: string = "";
    public pageEndPoint: string = "";
    public completed: boolean = false;
}

export class ITourEntry {
    public title: string = "";
    public description: string = "";
    public targetId: string = "";
    public canScroll?: boolean = false;
    public direction?: DirectionalHint = DirectionalHint.bottomCenter;
    public adminRequired: boolean = false;
    public visible?: boolean = true;
    public clickable?: boolean = true;
    public actionKeyword?: string = "";
}

export class PostalCode{
    public zipCode: string = "";
    public city: string = "";
    public countryCode: string = "";
    public county: string = "";
    public areaCode: string = "";
    public istatCode: string = "";
    public principalCity: boolean = true;
}

export class TicketInfo {
    public title: string = "";
    public description: string = "";
    public contactemail: string = "";
    public ticketType: number = 0;
    public sourceTitle: string = "";
    public sourceAddress: string = "";
    public lastError: string = "";
    public sourceApplicationOrigin: string = "";
    public attachments: CustomAttachment[] = [];
    public itemId: number = 0;
}

export class Ticket {
    public tenantId: string = "";
    public userId: number = 0;
    public partnerId: number = 0;
    public isItemRequired: boolean = false;
    public ticketInfo: TicketInfo = new TicketInfo();
}

export class SystemBookmark {
    public systemId:string = "";
    public openCard: boolean = false;
    public operationType: SystemOperation = SystemOperation.View;
}

export class SystemLanguage {
    public code:string = "";
    public name:string = "";
    public culture:string = "";
    public enabled:boolean = false;
    public default:boolean = false;
    public cmrReferenceId:boolean = false;
    public businessCentralCode:boolean = false;
}

export class AbletechValidateVATResponse {
    public ragioneSociale: string = "";
    public risultato: string = "";
    public errore: string = "";
}

export class AbletechValidateVAT
{
    public partitaIva: string = "";
}