import { DetailsList, Dialog, DialogFooter, DialogType, IComboBoxStyles, IModalProps, mergeStyles, MessageBar, PrimaryButton, SelectionMode, Separator, ShimmeredDetailsList, Stack, Text, TextField } from "@fluentui/react";
import { useForceUpdate } from "@fluentui/react-hooks";
import React, { useContext, useEffect, useState } from "react";
import { CustomerInformationContext } from "../../../ApplicationCode/Customer/CustomerInformationContext";
import { CustomerService } from "../../../ApplicationCode/Customer/CustomerService";
import { SystemCore } from "../../../Core/System/SystemCore";
import { CustomerCompany } from "../../../Model/CustomerModels";
import { SystemDataLoadingStatus } from "../../../Model/SystemModels";
import { Loader } from "../../Common/Loader/Loader";
import { t } from "i18next";

const tenantDetailBoxClassName = mergeStyles([{
}]);

const titleClassName = mergeStyles([{
    fontSize: 20,
    fontWeight: 500
}]);

const textClassName = mergeStyles([{
    fontSize: 15
}]);

const separatorStyles = {
    root: [{
      selectors: { // add selectors here
        '::before': {
          background: '#bbb',
        },
      }
    }]
};


const pageCardContentBodyClassName = mergeStyles([{
    width: '100%',
    overflowY: "auto",
    overflowX: "hidden"
}]);

const pageCardModalContentClassName = mergeStyles([{
    width: '100%',
    marginTop: 20
}]);

const pageCardFormContentClassName = mergeStyles([{
    width: '100%'
}]);

const contentContainerClassName = mergeStyles([{
}]);

export interface ICustomerSetupCompanyListProps {
    showModal: boolean;
    saveInProgress: boolean;
    onDismiss: (companies: CustomerCompany[]) => void;
    onCancel: () => void;
}

export const CustomerSetupCompanyList : React.FC<ICustomerSetupCompanyListProps> = (props) => {
    const [companies, setCompanies] = useState([] as CustomerCompany[]);
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const customerService = new CustomerService();
    
    const customerInfoContext = useContext(CustomerInformationContext);

    const pageCardModalProps: Partial<IModalProps> = {
        isBlocking: false,
        dragOptions: undefined,
        styles: {
            main: {
                minWidth: '500px !important',
                position: 'absolute',
                top: 50
            }
        }
    };

    const handleCompanyNameChange = (currentCompany: CustomerCompany, newValue: string) => {
        let newCompanies: CustomerCompany[] = JSON.parse(JSON.stringify(companies));
        let index = newCompanies.findIndex(e => e.id == currentCompany.id);
        newCompanies[index].name = newValue;
        setCompanies(newCompanies);
    }

    useEffect(() => {
        if (dataStatus == SystemDataLoadingStatus.ToLoad)
        {
            customerService.getCustomerCompanies(customerInfoContext.setupConfig.customerId)
                .then((resp) => {
                    setCompanies(resp);
                    setDataStatus(SystemDataLoadingStatus.Loaded);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [dataStatus])


    let messageBar = <></>;
    if (props.saveInProgress)
        messageBar = <MessageBar>{t('customerCenter:CustomerSetupCompanyList:MessageBar:messageContent1')}</MessageBar>

    return (
        <Dialog hidden={! props.showModal}
            modalProps={pageCardModalProps}
            onDismiss={props.onCancel}
            dialogContentProps={{
                title: t('customerCenter:CustomerSetupCompanyList:Dialog:title')!,
                type: DialogType.largeHeader
            }}>
            <Stack key="modalContent" className={pageCardModalContentClassName} tokens={{
                childrenGap: 15
            }}>
                <Stack.Item key="modalBodyContent" className={pageCardContentBodyClassName}>
                <Stack key="tenantDetailBox" className={tenantDetailBoxClassName}>
                    <Stack.Item className={contentContainerClassName}>
                        <ShimmeredDetailsList columns={[{
                            key: "name",
                            name: t('customerCenter:CustomerSetupCompanyList:Dialog:DetailList:name')!,
                            minWidth: 350,
                            maxWidth: 800,
                            onRender: (item? : CustomerCompany | undefined, index?, column?) => {
                                return (
                                    <TextField label="" 
                                                tabIndex={index! + 1}
                                                value={item!.name}
                                                onChange={(e, newValue) => {
                                                    handleCompanyNameChange(item!, newValue!);
                                                }} 
                                                borderless 
                                                placeholder={t('customerCenter:CustomerSetupCompanyList:Dialog:DetailList:TextField:placeholder')!}
                                                maxLength={30} 
                                                disabled={props.saveInProgress} />
                                );
                            }
                        }]}
                        items={companies}
                        enableShimmer={! SystemCore.isFinishedDataStatus(dataStatus)}
                        selectionMode={SelectionMode.none}>

                        </ShimmeredDetailsList>
                    </Stack.Item>
                    {messageBar}
                    <Stack.Item key="modalFooterContent">
                        <DialogFooter>
                            <PrimaryButton text={t('leadDashboard:buttonText')!} onClick={() => {
                                props.onDismiss(companies);
                            }}
                            disabled={props.saveInProgress}/>
                        </DialogFooter>
                    </Stack.Item>
                </Stack>
                </Stack.Item>
            </Stack>
        </Dialog>
    );
};