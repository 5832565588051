import React, { forwardRef} from "react";
import {SystemFieldType, SystemPageType} from "../../../../Model/SystemModels";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import { CardField } from "../../../Common/Template/Card/CardField";

export const AdminCrmSystemUserCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda CRM System User"} endpoint="odata/crm/system/user" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={false}>
                <CardField name="entryNo" type={SystemFieldType.Number} label="Id" readonly />
                <CardField name="firstName" type={SystemFieldType.String} label="Nome" readonly />
                <CardField name="lastName" type={SystemFieldType.String} label="Cognome" readonly />
                <CardField name="fullName" type={SystemFieldType.String} label="Nome Completo" readonly />
                <CardField name="internalEmailAddress" type={SystemFieldType.String} label="Indirizzo Email" readonly />
                <CardField name="createdOn" type={SystemFieldType.String} label="Data Creazione" readonly />
                <CardField name="modifiedOn" type={SystemFieldType.String} label="Data Modifica" readonly />
                <CardField name="crmReferenceId" type={SystemFieldType.String} label="Id CRM" readonly />
            </CardGroup>
        </PageContainer>
    );
});