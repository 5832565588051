import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminNotificationGroupMemberCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda notification group member"} endpoint="/odata/notification/group/member" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="userId" type={SystemFieldType.String} label="Id User" />
                <CardField name="referenceId" type={SystemFieldType.String} label="ReferenceId" />
            </CardGroup>
        </PageContainer>
    );
});