import { useEffect, useState } from "react";
import { CommandBarButton, Icon, Stack, TextField, mergeStyles, TooltipHost } from "@fluentui/react";
import styles from './QuestionInput.module.css';
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { t } from "i18next";
import { ChatbotHelper } from "../../../Chatbot/ChatHelper";

interface Props {
    onSend: (question: string, id?: string) => void;
    onStopGenerating: () => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    conversationId?: string;
    showSupportIcon?: boolean;
}

export const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend, conversationId, showSupportIcon, onStopGenerating }: Props) => {
    const [question, setQuestion] = useState<string>("");
    

    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }

        if(conversationId){
            onSend(question, conversationId);
        }else{
            onSend(question);
        }

        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setQuestion(newValue || "");
    };

    const sendQuestionDisabled = disabled || !question.trim();

    useEffect(() => {
        console.log(document.body.style.getPropertyValue('--question-default-color'))
    }, []);

    return (
        <Stack horizontal className={styles.inputContainer}>
            <TextField
                className={styles.questionInputTextArea}
                placeholder={placeholder}
                multiline
                resizable={false}
                borderless
                value={question}
                onChange={onQuestionChange}
                onKeyDown={onEnterPress}
            />
                <div className={styles.questionInputSendButtonContainer} 
                    role="button" 
                    tabIndex={0}
                    aria-label="Ask question button"
                    onClick={sendQuestion}
                    onKeyDown={e => e.key === "Enter" || e.key === " " ? sendQuestion() : null}
                >
                    { 
                        sendQuestionDisabled ? 
                        disabled ?
                        <TooltipHost content={t("chatbot:chat:stopGenerating")!}>
                            <Icon iconName="CircleStopSolid" className={styles.stopGeneratingIcon}
                            onClick={onStopGenerating}
                            />
                        </TooltipHost>
                        :
                            <Icon iconName="Send" className={styles.questionInputSendButtonDisabled}/>
                        :
                        <img src={ChatbotHelper.getSendIcon()} alt="sendImage" className={styles.questionInputSendButton}/>
                        
                    }
                </div>
                <div 
                    className={styles.questionInputClearButtonContainer} 
                    role="button" 
                    tabIndex={0}
                    aria-label="Clear chat button"
                    onKeyDown={e => e.key === "Enter" || e.key === " " ? sendQuestion() : null}
                >
                    <TooltipHost content={t("chatbot:chat:clearChatTxt")!}>
                        <CommandBarButton
                            role="button"
                            styles={{ 
                                icon: { 
                                    color: '#FFFFFF',
                                },
                                iconHovered:{
                                    color: '#FFFFFF',
                                },
                                iconPressed: {
                                    color: '#FFFFFF',
                                },
                                root: {
                                    color: '#FFFFFF',
                                    background: ChatbotHelper.getButtonBackground(),
                                    cursor: "pointer",
                                    width: 30,
                                    height: 30,
                                    borderRadius: 4,
                                },
                                rootHovered:{
                                    background: ChatbotHelper.getButtonHoveredBackground()
                                },
                                rootPressed: {
                                    color: '#FFFFFF',
                                }
                            }}
                            id="clearCurrentChat"
                            iconProps={{ iconName: 'Broom', id: "ICON-clearCurrentChat"}}
                            onClick={()=>{}}
                            disabled={false}
                            aria-label="clear chat button"
                        />
                    </TooltipHost>
                    {
                        showSupportIcon ?
                            <TooltipHost content={t("chatbot:chat:supportTxt")!} styles={{root:{marginLeft:25}}}>
                                <CommandBarButton
                                    role="button"
                                    styles={{ 
                                        icon: { 
                                            color: '#FFFFFF',
                                        },
                                        iconHovered:{
                                            color: '#FFFFFF',
                                        },
                                        iconPressed: {
                                            color: '#FFFFFF',
                                        },
                                        root: {
                                            color: '#FFFFFF',
                                            background: ChatbotHelper.getButtonBackground(),
                                            cursor: "pointer",
                                            width: 30,
                                            height: 30,
                                            borderRadius: 4
                                        },
                                        rootHovered:{
                                            background: ChatbotHelper.getButtonHoveredBackground()
                                        },
                                        rootPressed: {
                                            color: '#FFFFFF',
                                        }
                                    }}
                                    id="btnSupportRequest"
                                    iconProps={{ iconName: 'Help', id: "ICON-btnSupportRequest"}}
                                    onClick={()=>{}}
                                    disabled={false}
                                    aria-label="clear chat button"
                                />
                            </TooltipHost>
                        :
                        <></>
                    }
                </div>
            <div className={styles.questionInputBottomBorder} />
        </Stack>
    );
    return (
        <></>
    );
};