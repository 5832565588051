import { Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";
import React from "react";

export interface IErrorDialogProps {
    title: string;
    text: string;
    showDialog: boolean;
    onUpdateClick: () => void;
} 

export const ErrorDialog : React.FC<IErrorDialogProps> = (props: IErrorDialogProps) => {
    const dialogStyles = { main: { 
            minWidth: "50px !important",
            maxWidth: "600px !important" 
        } 
    };
    const modalProps = React.useMemo(
        () => ({
          titleAriaId: "titleLabelId",
          subtitleAriaId: "subTextId",
          isBlocking: true,
          styles: dialogStyles,
          dragOptions: undefined,
        }),
        [],
      );
    
    const dialogContentProps = {
        type: DialogType.normal,
        title: props.title,
        closeButtonAriaLabel: 'Close',
        subText: props.text,
    };

    return (
        <Dialog
            hidden={! props.showDialog}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
        >
            <DialogFooter>
                <PrimaryButton onClick={props.onUpdateClick} text="Aggiorna" />
            </DialogFooter>
        </Dialog>
    );
}