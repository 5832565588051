import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminWizardMappingCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda mapping wizard"} endpoint="/odata/ui/wizard/mapping" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="endpoint" type={SystemFieldType.String} label="Endpoint" />
                <CardField name="completeAction" type={SystemFieldType.Option} label="Azione completamento" cmbOptions={[{
                    key: 0,
                    text: "Chiudi"
                },
                {
                    key: 1,
                    text: "Reindirizza"
                }]} />
                <CardField name="active" type={SystemFieldType.Boolean} label="Attivo" />
            </CardGroup>
        </PageContainer>
    );
});