import React from "react";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";


export const AdminSalesInvoiceLineList : React.FC = () => {
    let headerId=HttpHelper.getParameter('headerId')

    return (
        <>
            <PageContainer endpoint={"/odata/sales/invoice/line?$filter=idSalesOrderHeader eq "+headerId} pageType={SystemPageType.List} title="Lista dettaglio fatture" backAllowed>               
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Action}>
                    </ActionArea>
                </PageDefinitionContainer>                                 
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Id" fieldName="id" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id Sales Order Header" fieldName="idSalesOrderHeader" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Document No" fieldName="documentNo" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Subscription No" fieldName="subscriptionNo" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id Partner" fieldName="partnerId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id Customer" fieldName="customerId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Item Ecommerce Code" fieldName="itemEcommerceCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Item ERP No" fieldName="itemErpNo" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="ID Item" fieldName="itemId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Quantità" fieldName="quantity" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Prezzo" fieldName="price" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Line Amount" fieldName="lineAmount" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="VAT Amount" fieldName="vatAmount" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Vat Percentage" fieldName="vatPercentage" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Discount Percentage" fieldName="discount_Percentage" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Commission Percentage" fieldName="commission_Percentage" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Commission Amount" fieldName="commission_Amount" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Starting Date" fieldName="startingDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Ending Date" fieldName="endingDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="System Id" fieldName="systemId" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="System Creation User Id" fieldName="systemCreationUserId" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="System Creation Date" fieldName="systemCreationDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Data aggiornamento sistema" fieldName="systemUpdateDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id utente aggiornamento sistema " fieldName="systemUpdateUserId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Timestamp" fieldName="timestamp" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
        </>
    );
}