import React, { forwardRef} from "react";
import {SystemFieldType, SystemPageType} from "../../../Model/SystemModels";
import PageContainer, { PageContainerBase } from "../../Common/Template/PageContainer";
import { CardGroup } from "../../Common/Template/Card/CardGroup";
import { CardField } from "../../Common/Template/Card/CardField";

export const AdminCustomerDigitalContractCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {

    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda contratto"} endpoint="odata/customer/digitalcontract" ref={ref}>
            <CardGroup name="settingsContracts" label="Impostazioni Contratto" isOpen >
                <CardField name="id" type={SystemFieldType.Number} label="Id" readonly />
                <CardField name="digitalContractCode" type={SystemFieldType.String} label="Codice contratto" readonly />
                <CardField name="customerId" type={SystemFieldType.Number} label="Id cliente" readonly />
                <CardField name="signed" type={SystemFieldType.Boolean} label="Firmato" />
                <CardField name="signedBy" type={SystemFieldType.String} label="Firmato da" />
                <CardField name="signedAt" type={SystemFieldType.String} label="Firmato il" />
                <CardField name="mandatory" type={SystemFieldType.Boolean} label="Obbligatorio" />
                <CardField name="adminRequired" type={SystemFieldType.Boolean} label="Admin richiesto" />
                <CardField 
                    name="entityRelation" 
                    type={SystemFieldType.Option} 
                    label="Relazione entità" 
                    cmbOptions={[
                        { key: 10, text: "Cliente" },
                        { key: 1, text: "Tenant" },
                        { key: 2, text: "Company" },
                        { key: 3, text: "User" },
                    ]}
                    readonly
                />
                <CardField name="entityId" type={SystemFieldType.Number} label="Id entità" readonly />
                <CardField name="templateName" type={SystemFieldType.String} label="Nome template" />
                <CardField name="envelopeId" type={SystemFieldType.String} label="Id envelope" />
                <CardField name="signLink" type={SystemFieldType.String} label="Link firma" />
                <CardField 
                    name="practiceStatus"
                    type={SystemFieldType.Option} 
                    label="Stato pratica"
                    cmbOptions={[
                        { key: 10, text: "Nuovo" },
                        { key: 11, text: "Attesa firma namirial" },
                        { key: 12, text: "Firmato" },
                        { key: 13, text: "In attesa approvazione" },
                        { key: 14, text: "Approvato" },
                        { key: 15, text: "In errore" },
                        { key: 16, text: "Attesa riscontro Abletech" },
                        { key: 17, text: "Riaperto"}
                    ]} 
                />
                <CardField name="expirationDate" type={SystemFieldType.Date} label="Data scadenza" />
                <CardField name="abletech_CustomerId" type={SystemFieldType.String} label="Id cliente abletech" />
                <CardField name="abletech_ContractId" type={SystemFieldType.String} label="Id contratto abletech" />
                <CardField name="abletech_MasterCompany" type={SystemFieldType.Boolean} label="Abletech azienda principale" />
                <CardField name="exception_Description" type={SystemFieldType.String} label="Descrizione errore" />
            </CardGroup> 
        </PageContainer>
    );
});