import { useRef } from "react";
import { SystemPageType } from "../../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../../Common/Template/PageDefinitionContainer";
import { AdminAzureGDAPRoleCard } from "../AdminAzureGDAPRoleCard/AdminAzureGDAPRoleCard";

export const AdminAzureGDAPRoleList : React.FC = () => {
    
    const cardRef = useRef<PageContainerBase>(null);
    
    return (
        <>
            <PageContainer endpoint={'/odata/azure/gdap/role'} pageType={SystemPageType.List} title={"Lista ruoli azure GDAP"} cardRef={cardRef} backAllowed showAllowed insertAllowed updateAllowed>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry fieldName="code" name="Codice" minWidth={200} maxWidth={900} />
                    <ListHeaderEntry fieldName="name" name="Nome" minWidth={300} maxWidth={900} />
                    <ListHeaderEntry fieldName="description" name="Descrizione" minWidth={900} maxWidth={900} />
                    <ListHeaderEntry fieldName="active" name="Attivo" minWidth={100} maxWidth={900} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminAzureGDAPRoleCard ref={cardRef} />
        </>
    );
}