import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminAuthenticationEntryCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Authentication entry"} endpoint="/odata/authentication/entry" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="tenantId" label="Tenant Id" type={SystemFieldType.String} />
                <CardField name="clientId" label="Client Id" type={SystemFieldType.String} />
                <CardField name="expiredOn" label="Scade il" type={SystemFieldType.Date} />
                <CardField name="clientId" label="Client Id" type={SystemFieldType.String} />
                <CardField name="accessToken" label="Access Token" type={SystemFieldType.String} />
            </CardGroup>
        </PageContainer>
    );
});