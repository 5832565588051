import { useRef } from "react";
import { SystemPageType } from "../../../../Model/SystemModels";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import { AdminDigitalContractCard } from "../AdminDigitalContractCard/AdminDigitalContractCard";

export const AdminDigitalContractList : React.FC = () => {

    const cardRef = useRef<PageContainerBase>(null);
    
    return (
        <>
            <PageContainer endpoint="/odata/digitalcontract" pageType={SystemPageType.List} title="Lista template contratti" cardRef={cardRef} backAllowed showAllowed insertAllowed updateAllowed deleteAllowed>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry fieldName="id" name="Id" minWidth={90} maxWidth={90} />
                    <ListHeaderEntry fieldName="code" name="Codice" minWidth={120} maxWidth={120} />
                    <ListHeaderEntry fieldName="description" name="Descrizione" minWidth={400} maxWidth={400} />
                    <ListHeaderEntry fieldName="mandatory" name="Obbligatorio" minWidth={100} maxWidth={100} />
                    <ListHeaderEntry fieldName="adminRequired" name="Admin richiesto" minWidth={100} maxWidth={100} />
                    <ListHeaderEntry fieldName="functionCode" name="Codice funzione" minWidth={120} maxWidth={120} />
                    <ListHeaderEntry fieldName="entityRelation" name="Relazione entità" minWidth={120} maxWidth={120} onRender={(item: any, index, column) => {
                        if (item.entityRelation === 10)
                            return "Cliente"
                        else if (item.entityRelation === 11)
                            return "Tenant"
                        else if (item.entityRelation === 12)
                            return "Company"
                        else if (item.entityRelation === 13)
                            return "User"
                    }} />
                    <ListHeaderEntry fieldName="templateName" name="Nome template" minWidth={350} maxWidth={350} />
                    <ListHeaderEntry fieldName="templateBlob" name="Blob template" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry fieldName="namirialTemplateId" name="Id template Namirial" minWidth={220} maxWidth={220} />
                    <ListHeaderEntry fieldName="expirationDelayDays" name="Giorni scadenza" minWidth={110} maxWidth={110} />
                    <ListHeaderEntry fieldName="canDelete" name="Cancellazione permessa" minWidth={160} maxWidth={160} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminDigitalContractCard ref={cardRef} />
        </>
    );
}