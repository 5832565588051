import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export interface ISystemEventFilterCard {
    eventName?: string;
}

export const AdminSystemEventFilterCard = forwardRef<PageContainerBase, React.PropsWithChildren<ISystemEventFilterCard>>((props, ref) => {
        
    const buildFilterParameter = (): string => {
        let filter = "";
        
        if (props.eventName !== undefined && props.eventName > "")
            filter = "?$filter=eventName eq '" + props.eventName + "'"
            
        return(filter);
    };
        
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda System Event Setup Filter"} endpoint="odata/system/event/setup/filter" ref={ref}>
            <CardGroup name="general" label="General" isOpen={true}>
                <CardField name="eventName" type={SystemFieldType.String} label="Nome Evento" readonly />
                <CardField name="setupCode" type={SystemFieldType.String} label="Codice Setup" readonly />
                <CardField name="parameterName" type={SystemFieldType.String} label="Nome parametro" hasTableRelation tableRelationEndpoint="/odata/system/event/parameter" tableRelationFilter={buildFilterParameter()} tableRelationKey="parameterName" tableRelationField="parameterName" />
                <CardField name="isEntity" type={SystemFieldType.Boolean} label="Entità" />
                <CardField name="entityName" type={SystemFieldType.String} label="Nome Entità" hasTableRelation tableRelationEndpoint="odata/system/entity" tableRelationKey="name" tableRelationField="name" />
                <CardField name="fieldName" type={SystemFieldType.String} label="Nome Campo" hasTableRelation tableRelationEndpoint="odata/system/entity/property" tableRelationFilter="?$filter=entityName eq '#entityName#'" tableRelationKey="name" tableRelationField="name" />
                <CardField name="filterValue" type={SystemFieldType.String} label="Valore Filtro" />
            </CardGroup> 
        </PageContainer>
    );
});