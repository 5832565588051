import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../../Model/SystemModels";
import { CardField } from "../../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../../Common/Template/PageContainer";

export const AdminAzureGDAPRoleCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} endpoint="/odata/azure/gdap/role" title={'Card ruolo azure GDAP'} ref={ref}>
            <CardGroup name="general" label="Generale" isOpen>
                <CardField name="code" label="Codice" type={SystemFieldType.String} />
                <CardField name="name" label="Nome" type={SystemFieldType.String} />
                <CardField name="description" label="Descrizione" type={SystemFieldType.String} />
                <CardField name="active" label="Attivo" type={SystemFieldType.Boolean} />
            </CardGroup>
        </PageContainer>    
    );
});