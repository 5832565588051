import React, { forwardRef, useContext, useEffect, useState } from "react";
import { OperationType, SystemFieldType, SystemOperation, SystemPageType, SystemUser } from "../../../../Model/SystemModels";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import { CardField } from "../../../Common/Template/Card/CardField";
import { Customer } from "../../../../Model/CustomerModels";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";

export interface IDsyDataRequestCardProps {
    onSuccess: (operationType: SystemOperation, record: any, callback?: (() => void)) => void;
}

export const AdminDsyDataRequestCard = forwardRef<PageContainerBase, React.PropsWithChildren<IDsyDataRequestCardProps>>((props, ref) => {

    return (
        <PageContainer 
            pageType={SystemPageType.Card} 
            title={"Scheda Richiesta Dati (DSY)"} 
            endpoint="" 
            ref={ref}
            onSuccess={props.onSuccess}
        >
            <CardGroup name="generale" label="Generale" isOpen>
                <CardField name="tableNo" type={SystemFieldType.Number} label="Nr. Tabella" />
                <CardField name="fieldNo" type={SystemFieldType.Number} label="Nr. Campo" />
                <CardField name="tableFilters" type={SystemFieldType.String} label="Filtri Tabella" />
                <CardField name="operationType" type={SystemFieldType.Option} label="Tipo Operazione" cmbOptions={[
                    {key: 'Read', text: "Lettura"},
                    {key: 'Count', text: "Conteggio"},
                    {key: 'MinValue', text: "Valore minimo"},
                    {key: 'MaxValue', text: "Valore massimo"},
                ]} />
            </CardGroup> 
        </PageContainer>
    );
});