import { CommandBarButton, DefaultButton, IButtonProps, IButtonStyles, ICommandBarStyles } from "@fluentui/react";
import { use } from "i18next";
import { useEffect } from "react";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { ChatbotContext, ChatbotHelper } from "../../../Chatbot/ChatHelper";

interface CustomCommandBarButtonProps extends IButtonProps {
    text: string;
    iconName: string;
    id: string;
    onClick: () => void;
  }

export const CustomCommandBarButton: React.FC<CustomCommandBarButtonProps> = (props) => {
    let rootBackground = "radial-gradient(109.81% 107.82% at 100.1% 90.19%, #0F6CBD 33.63%, #2D87C3 70.31%, #8DDDD8 100%)";
    let rootHoveredBackground = "linear-gradient(135deg, #0F6CBD 0%, #2D87C3 51.04%, #8DDDD8 100%)";
    let fontFamily = "Nunito, sans-serif";
    if (ChatbotHelper.getContext() == ChatbotContext.Eos)
    {
      rootBackground = "radial-gradient(109.81% 107.82% at 100.1% 90.19%, #ff6d00 33.63%, #e34e00 70.31%, #ff9761 100%)";
      rootHoveredBackground = "linear-gradient(135deg, #ff6d00 0%, #e34e00 51.04%, #ff9761 100%)";
      fontFamily = "Open Sans, sans-serif";
    }
  

    const shareButtonStyles: ICommandBarStyles & IButtonStyles = {
        root: {
          width: 86,
          height: 32,
          borderRadius: 4,
          background: rootBackground,
        //   position: 'absolute',
        //   right: 20,
          padding: 5,
          marginRight: '20px'
        },
        icon: {
          color: '#FFFFFF',
        },
        iconHovered:
        {
          color: '#FFFFFF',
        },
        iconPressed: {
          color: '#FFFFFF',
        },
        rootHovered: {
          background: rootHoveredBackground,
          color: "#FFFF"
        },
        label: {
          fontWeight: 600,
          fontSize: 12,
          lineHeight: '20px',
          color: '#FFFFFF',
          fontFamily: fontFamily
        }
      };

      return (
        <CommandBarButton
                id={props.id}
                styles={shareButtonStyles}
                iconProps={{ iconName: props.iconName, id: "ICON-"+props.id }}
                onClick={props.onClick}
                text={props.text}
        />
      )
}

interface HistoryButtonProps extends IButtonProps {
    onClick: () => void;
    text: string;
  }

export const HistoryButton: React.FC<HistoryButtonProps> = ({onClick, text}) => {
    const historyButtonStyles: ICommandBarStyles & IButtonStyles = {
        root: {
            width: '180px',
            border: `1px solid #D1D1D1`,
          },
          rootHovered: {
            border: `1px solid #D1D1D1`,
          },
          rootPressed: {
            border: `1px solid #D1D1D1`,
          },
          label:{
            fontFamily:'Nunito'
          }
      };

      return (
        <DefaultButton
            text={text}
            iconProps={{ iconName: 'History' }}
            onClick={onClick}
            styles={historyButtonStyles}
        />
      )
}