import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminBusinessCentralDefaultAppCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda default app"} endpoint="odata/businesscentral/default/app" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="appId" type={SystemFieldType.String} label="ID App"/>
                <CardField name="name" type={SystemFieldType.String} label="Nome" />
                <CardField name="version" type={SystemFieldType.String} label="Versione" />
                <CardField name="priority" type={SystemFieldType.String} label="Priorità" />
                <CardField name="publisher" type={SystemFieldType.String} label="Pubblicatore" />
            </CardGroup>
        </PageContainer>
    );
});