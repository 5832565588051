import { CryptoHelper } from '../Crypto/CryptoHelper';
import Cookies from 'js-cookie';


export class CookieService {

    constructor() {}

    static getCookieValue(entryName: string, decryptValue: boolean = true): string | undefined
    {
        let value = Cookies.get(entryName);
        if ((decryptValue) && (value != undefined))
            return CryptoHelper.decryptWithAES(value, process.env.REACT_APP_SYSTEM_SECRET_KEY!)!;
        else
            return value;
    }

    static setCookieValue(entryName: string, value: string, expiration: Date, encryptValue: boolean = true)
    {
        let data = "";
        if (encryptValue)
            data = CryptoHelper.encryptWithAES(value, process.env.REACT_APP_SYSTEM_SECRET_KEY!);
        else    
            data = value;

        Cookies.set(entryName, data, {
            expires: expiration,
            sameSite: 'Strict'
        });
    }

    static removeCookie(entryName: string)
    {
        Cookies.remove(entryName);
    }

    public static setCustomCookie(cookieName: string, value: string) : void 
    {
        let data = btoa(value);

        Cookies.set(cookieName, data, {
            expires: new Date("01-01-9999")
        });
    }

    public static getCustomCookie(cookieName: string) : string | undefined
    {
        let value = Cookies.get(cookieName);
        if (value != undefined)
            return atob(value);
        else
            return undefined;
    }

}