import React from "react";
import {SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";

export const AdminApplicationLogList : React.FC = () => {
    return (
        <>
            <PageContainer endpoint="/odata/application/log/entry?$orderby= systemCreationDate desc" pageType={SystemPageType.List} title="Lista LOG" backAllowed>               
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Action}>
                    </ActionArea>
                </PageDefinitionContainer>               
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="ID" fieldName="id" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id Utente" fieldName="userId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Tipo" fieldName="type" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Sorgente" fieldName="source" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Messaggio" fieldName="message" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Stack Trace" fieldName="stackTrace" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="System Id" fieldName="systemId" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="System Creation User Id" fieldName="systemCreationUserId" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="System Creation Date" fieldName="systemCreationDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Data aggiornamento sistema" fieldName="systemUpdateDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id utente aggiornamento sistema " fieldName="systemUpdateUserId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Timestamp" fieldName="timestamp" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
        </>
    );
}