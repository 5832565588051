import { HttpHelper } from "../../Core/Http/HttpHelper";

export interface IContentManagement {
    contentVideo: string;
    description: string;
}

export class ContentManagement {

    constructor(){}

    public static async generateResource(_videoContent: string, _description: string) : Promise<IContentManagement> {
        let currentContentVideo:string = "";
        let currentDescription:string = "";

        if(_videoContent !== "" && _videoContent !== null){
            _videoContent = decodeURIComponent(atob(_videoContent));
            if(_videoContent.includes("storage:")){
                let instance = HttpHelper.buildAxiosInstance();
                let resp =  await instance.get("api/csp/video/" + _videoContent + "/generate");

                if(resp.status === 200)
                    currentContentVideo = resp.data;
            }
            else
                currentContentVideo = _videoContent;
        }

        if(_description !== "" && _description !== null)
            currentDescription = _description;
            
        return {
            contentVideo: currentContentVideo,
            description: currentDescription
        }
    }
}