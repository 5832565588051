import React, { useRef } from "react";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import {SystemActionCategory, SystemOperation, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminDataSecurityStatusChangePanel } from "./AdminDataSecurityStatusChangePanel";

export const AdminDataSecurityStatusChangeList : React.FC = () => {
    const cardRef = useRef<PageContainerBase>(null);
    const entityName = HttpHelper.getParameter('entityName')
    const fromCode = HttpHelper.getParameter('fromCode')

    return (
        <>
            <PageContainer endpoint={"/odata/datasecurity/status/change?$filter=entityName eq '" + entityName + "' and fromCode eq '" + fromCode + "'"} pageType={SystemPageType.List} title="Stati Data Security" backAllowed insertAllowed deleteAllowed updateAllowed cardRef={cardRef}>               
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Action}>
                    </ActionArea>
                </PageDefinitionContainer>                             
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Da stato" fieldName="fromCode" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="A stato" fieldName="toCode" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminDataSecurityStatusChangePanel ref={cardRef} entityName={entityName} fromCode={fromCode} />
        </>
    );
}