import React, {useRef, useState} from "react";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { CommonUploadFileDialog } from "../../../Common/CommonUploadFileDialog/CommonUploadFileDialog";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { AdminEmailAttachmentCard } from "../AdminEmailAttachmentCard/AdminEmailAttachmentCard";
import { useNavigate } from "react-router-dom";

export const AdminEmailAttachmentList : React.FC = () => {
    const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
    const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
    const ref = useRef<PageContainerBase>(null);
    const pageRef = useRef<PageContainerBase>(null);
    const adminService = new AdminService();
    const navigate = useNavigate();
    
    const templateCodeParam: string = HttpHelper.getParameter("templateCode");
    const legalEntityCode: string = HttpHelper.getParameter("legalEntityCode");
    const distributorCode: string = HttpHelper.getParameter("distributorCode");
    const partnerBusinessContractCode: string = HttpHelper.getParameter("partnerBusinessContractCode");
    const languageCode: string = HttpHelper.getParameter("languageCode");

    const buildListEndpoint = () : string => {
        let endpoint = "/odata/email/attachment";
        
        if (templateCodeParam && legalEntityCode && distributorCode && partnerBusinessContractCode && languageCode) {
            endpoint += "?$filter=emailTemplateCode eq '" + templateCodeParam + "' and legalEntityCode eq '" + legalEntityCode + "' and distributorCode eq '" + distributorCode + "' and partnerBusinessContractCode eq '" + partnerBusinessContractCode + "' and languageCode eq '" + languageCode + "'";
        }
        else {
            navigate("/app/admin/administrative/emailTemplate");
        }
        
        return endpoint;
    }

    const downloadMailAttachment = (entryNo: number, fileName: string)=>{
        adminService.downloadMailAttachment(entryNo).then((srvData)=>{
            const url = window.URL.createObjectURL(new Blob([srvData]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

            ToastService.showMessage(MessageType.Information,"Download completato con successo")
        })
        .catch((jqXHR)=>{
            ToastService.showMessage(MessageType.Error,"Errore durante il download del file: "+jqXHR.message);
        })
    };

    const uploadMailAttachment = (file:File) => {
        setSaveInProgress(true)

        adminService.uploadMailAttachment(templateCodeParam, legalEntityCode, distributorCode, partnerBusinessContractCode, languageCode, file).then((resp)=>{
            setShowUploadDialog(false);
            setSaveInProgress(false)

            ToastService.showMessage(MessageType.Success,"Upload completato.");
        })
        .catch((jqXHR)=>{
            setSaveInProgress(false)
            ToastService.showMessage(MessageType.Error, "Errore: Impossibile caricare l'allegato.");
        })
        .finally(() =>{
            pageRef.current?.reloadData();
        })
    }

    return (
        <>
            <PageContainer ref={pageRef} endpoint={buildListEndpoint()} pageType={SystemPageType.List} title="Allegati Email" backAllowed deleteAllowed cardRef={ref}>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Process}>
                        <ActionEntry name="uploadFile" label="Carica allegato" iconName="Upload" isPromoted onClick={(req:any)=>{
                                setShowUploadDialog(true);
                            }}>
                        </ActionEntry>
                        <ActionEntry name="downloadAttachment" label="Scarica allegato" iconName="Download" isPromoted runOnRec onClick={(req:any)=>{
                                downloadMailAttachment(req.entryNo, req.name);
                            }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>               
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Id" fieldName="entryNo" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Codice template" fieldName="emailTemplateCode" minWidth={250} maxWidth={350} />
                    <ListHeaderEntry name="Nome" fieldName="name" minWidth={600} maxWidth={700} />
                    <ListHeaderEntry name="Estensione" fieldName="extension" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Content type" fieldName="contentType" minWidth={500} maxWidth={600} />
                    <ListHeaderEntry name="Legal entity" fieldName="legalEntityCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Distributore" fieldName="distributorCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Partner business contract" fieldName="partnerBusinessContractCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Lingua" fieldName="languageCode" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminEmailAttachmentCard ref={ref} />
            <CommonUploadFileDialog
                title="Carica Template"
                subText="Seleziona il file da caricare"
                hidden={showUploadDialog}
                txtLabel="File"
                ext=".pdf,.xls,.xlsx,.ppt,.pptx,.doc,.docx"
                saveInProgress={saveInProgress}
                onSuccess={uploadMailAttachment}
                onDismiss={()=>{setShowUploadDialog(false)}}
            />
        </>
    );
}