import { IModalProps, IComboBoxOption, IComboBox, Stack, MessageBar, MessageBarType, MessageBarButton, Label, TextField, Dialog, DialogType, ComboBox, DialogFooter, PrimaryButton, mergeStyles, IComboBoxStyles, IStackTokens } from "@fluentui/react";
import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import { CustomerInformationContext } from "../../../ApplicationCode/Customer/CustomerInformationContext";
import { CustomerService } from "../../../ApplicationCode/Customer/CustomerService";
import { SystemCore } from "../../../Core/System/SystemCore";
import { ToastService, MessageType } from "../../../Core/Toast/ToastService";
import { RelationshipStatus, CustomerDomain, TenantInformationSetup } from "../../../Model/CustomerModels";
import { OperationType, SystemDataLoadingStatus, SystemUser } from "../../../Model/SystemModels";
import { Loader } from "../../Common/Loader/Loader";

export interface ICustomerMainUserSetupCardProps {
    showModal: boolean;
    saveInProgress: boolean;
    onDismiss: (user: SystemUser) => void;
    onCancel: () => void;
}

const tenantDetailBoxClassName = mergeStyles([{
}]);

const titleClassName = mergeStyles([{
    fontSize: 20,
    fontWeight: 500
}]);

const textClassName = mergeStyles([{
    fontSize: 15
}]);

const separatorStyles = {
    root: [{
      selectors: { // add selectors here
        '::before': {
          background: '#bbb',
        },
      }
    }]
};

const comboBoxStyles: Partial<IComboBoxStyles> = { root: { maxWidth: '500px' } };

const contentContainerClassName = mergeStyles([{
}]);

const relationshipContainerClassName = mergeStyles([{
    paddingTop: 15
}]);

const statusContainerClassName = mergeStyles([{
    paddingTop: 15
}]);

const partnerContainerClassName = mergeStyles([{
    paddingTop: 15
}]);

const pageCardContentBodyClassName = mergeStyles([{
    width: '100%',
    overflowY: "auto",
    overflowX: "hidden"
}]);

const pageCardModalContentClassName = mergeStyles([{
    width: '100%',
    marginTop: 20
}]);

const pageCardFormContentClassName = mergeStyles([{
    width: '100%'
}]);

const formContentClassName = mergeStyles([{
    width: '100%'
}]);

const formContentEntryClassName = mergeStyles([{
    width: '100%'
}]);

const rowContentClassName = mergeStyles([{
    width: '100%',
    marginBottom: 7
}]);

const rowContentEntryClassName = mergeStyles([{
    width: '48%'
}]);

const textFieldClassName =  mergeStyles([{
    width: '100%',
    maxWidth: 600
}]);

const rowContentStackToken: IStackTokens = {
    childrenGap: 50
};

export const CustomerMainUserSetupCard : React.FC<ICustomerMainUserSetupCardProps> = (props) => {
    const [currentUser, setCurrentUser] = useState(new SystemUser());
    const [aadUsers, setAADUsers] = useState([] as SystemUser[]);
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [syncingUsers, setSyncingUsers] = useState(false);
    const [syncUserMessage, setsyncUserMessage] = useState("");
    const [messageBarType, setMessageBarType] = useState(MessageBarType.info);
    const customerInfoContext = useContext(CustomerInformationContext);
    const customerService = new CustomerService();

    const pageCardModalProps: Partial<IModalProps> = {
        isBlocking: false,
        dragOptions: undefined,
        styles: {
            main: {
                width: '95% !important',
                maxWidth: 'calc(95%) !important',
                position: 'absolute',
                top: 50
            }
        }
    };

    useEffect(() => {
        if ((dataStatus == SystemDataLoadingStatus.ToLoad) && (props.showModal))
        {
            setDataStatus(SystemDataLoadingStatus.Loading);
            if (customerInfoContext.setupConfig.hasTenant)
            {
                syncUsers();
                setDataStatus(SystemDataLoadingStatus.Loaded);
            }
            else
                setDataStatus(SystemDataLoadingStatus.Loaded);
        }
    }, [props.showModal]);

    const syncUsers = () => {
        setSyncingUsers(true);
        setsyncUserMessage(t('customerCenter:CustomerMainUserSetupCard:Loader:textSyncUser')!);
        setMessageBarType(MessageBarType.info);
        customerService.syncUsers(customerInfoContext.setupConfig.customerId)
        .then((resp) => {
            customerService.getCustomerUsers(customerInfoContext.setupConfig.customerId)
                .then((resp) => {
                    let users = resp.filter(e => (e.userPrincipleName !== "" && e.userPrincipleName !== undefined));
                    setAADUsers(users);
                    setSyncingUsers(false);
                    setsyncUserMessage("");
                })                
                .catch((err) => {
                    ToastService.showMessage(MessageType.Error, err);
                });;                    
        })
        .catch((err) => {
            setMessageBarType(MessageBarType.warning);
            setsyncUserMessage(t('customerCenter:CustomerMainUserSetupCard:Err00006Text')!);
        });
    }

    const buildUserOptions = () : IComboBoxOption[] => {
        let options: IComboBoxOption[] = [];
        for (let i = 0; i < aadUsers.length; i++)
        {
            let opt: IComboBoxOption = {
                key: aadUsers[i].id,
                text: aadUsers[i].userPrincipleName
            };

            options.push(opt);
        }

        return options;
    };

    const handleObjectPropertyChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        let newCurrentUser = new SystemUser();
        for (let i = 0; i < Object.keys(currentUser).length; i++)
        {
            newCurrentUser = Object.defineProperty(
                newCurrentUser, 
                Object.keys(currentUser)[i], 
                {
                  value: Object.values(currentUser)[i],
                  writable: true,
                  enumerable: true
                }
              );
        }

        newCurrentUser = Object.defineProperty(
            newCurrentUser, 
            event.currentTarget.name, 
            {
              value: newValue!,
              writable: true,
              enumerable: true
            }
        );

        setCurrentUser(newCurrentUser);
    }

    let messageBar = <></>;
    if (props.saveInProgress)
        messageBar = <Stack.Item>
                        <MessageBar>
                            {t('customerCenter:CustomerMainUserSetupCard:MessageBar:messageContent1')}
                        </MessageBar>
                    </Stack.Item>;

    let syncUserStatusBar = <Stack.Item hidden={!syncingUsers}>
        <MessageBar
            isMultiline={false}
            dismissButtonAriaLabel="Close"
            messageBarType={messageBarType}
            actions={
                <MessageBarButton hidden={messageBarType === MessageBarType.info} onClick={() => syncUsers()}>{t('customerCenter:CustomerTenantDetailBox:MessageBar:messageBarButtonText')}</MessageBarButton>
            }>
            {syncUserMessage}
        </MessageBar>
    </Stack.Item>

    if (customerInfoContext.setupConfig.hasTenant)
    {
        if (! props.showModal)
            return <></>;
        else if (! SystemCore.isFinishedDataStatus(dataStatus))
            return <Loader text={t('customerCenter:CustomerMainUserSetupCard:Loader:text')!}></Loader>;
        else
        {
            return (
                <Dialog hidden={! props.showModal}
                        modalProps={pageCardModalProps}
                        onDismiss={props.onCancel}
                        dialogContentProps={{
                            title: t('customerCenter:CustomerMainUserSetupCard:Dialog:title')!,
                            type: DialogType.largeHeader
                        }}>
                            <Stack key="modalContent" className={pageCardModalContentClassName} tokens={{
                                childrenGap: 15
                            }}>
                                <Stack.Item key="modalBodyContent" className={pageCardContentBodyClassName}>
                                    <Stack key="tenantDetailBox" className={tenantDetailBoxClassName}>
                                        <Stack.Item className={contentContainerClassName}>
                                            <ComboBox
                                                label={t('customerCenter:CustomerMainUserSetupCard:Dialog:ComboBox:label')!}
                                                options={buildUserOptions()}
                                                styles={comboBoxStyles}
                                                disabled={props.saveInProgress}
                                                onChange={(e, opt, idx, val) => {
                                                    let selectedUser = aadUsers.filter(e => e.id == opt?.key);
                                                    if (selectedUser.length > 0)
                                                        setCurrentUser(selectedUser[0]);
                                                }}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                                {syncUserStatusBar}
                                {messageBar}
                                <Stack.Item key="modalFooterContent">
                                    <DialogFooter>
                                        <PrimaryButton text={t('leadDashboard:buttonText')!} onClick={() => {
                                            if (currentUser.id == 0)
                                                ToastService.showMessage(MessageType.Error, t('customerCenter:CustomerMainUserSetupCard:Err00001Text'));
                                            else
                                                props.onDismiss(currentUser);
                                        }}
                                        disabled={props.saveInProgress} />
                                    </DialogFooter>
                                </Stack.Item>
                            </Stack>
                </Dialog>
            );
        }
    }
    else
    {
        if (! SystemCore.isFinishedDataStatus(dataStatus))
            return <></>;
        else
        {
            return (
                <Dialog hidden={! props.showModal}
                        modalProps={pageCardModalProps}
                        onDismiss={props.onCancel}
                        dialogContentProps={{
                            title: t('customerCenter:CustomerMainUserSetupCard:Dialog:title')!,
                            type: DialogType.largeHeader
                        }}>
                            <Stack key="modalContent" className={pageCardModalContentClassName} tokens={{
                                childrenGap: 15
                            }}>
                                <Stack.Item key="modalBodyContent" className={pageCardContentBodyClassName}>
                                    <Stack key="formContainer" className={formContentClassName}>
                                        <Accordion allowMultipleExpanded preExpanded={["generalItem"]}>
                                            <AccordionItem uuid={"generalItem"}>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        {t('customerCenter:CustomerMainUserSetupCard:CardGroup:label')}
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <Stack.Item className={formContentEntryClassName}>
                                                        <Stack horizontal key="row1" className={rowContentClassName} tokens={rowContentStackToken}>
                                                            <Stack.Item className={rowContentEntryClassName}>
                                                                <TextField name="firstName"
                                                                    value={currentUser.firstName} 
                                                                    label={t('customerCenter:CustomerMainUserSetupCard:CardGroup:CardField:firstName')!} 
                                                                    className={textFieldClassName}
                                                                    onChange={handleObjectPropertyChange} 
                                                                    disabled={props.saveInProgress} />
                                                            </Stack.Item>
                                                            <Stack.Item className={rowContentEntryClassName}>
                                                                <TextField name="lastName"
                                                                    value={currentUser.lastName} 
                                                                    label={t('customerCenter:CustomerMainUserSetupCard:CardGroup:CardField:lastName')!}
                                                                    className={textFieldClassName}
                                                                    onChange={handleObjectPropertyChange} 
                                                                    disabled={props.saveInProgress} />
                                                            </Stack.Item>
                                                        </Stack>
                                                    </Stack.Item>
                                                    <Stack.Item className={formContentEntryClassName}>
                                                        <Stack horizontal key="row2" className={rowContentClassName} tokens={rowContentStackToken}>
                                                            <Stack.Item className={rowContentEntryClassName}>
                                                                <TextField name="displayName"
                                                                    value={currentUser.displayName} 
                                                                    label={t('customerCenter:CustomerMainUserSetupCard:CardGroup:CardField:displayName')!} 
                                                                    className={textFieldClassName}
                                                                    onChange={handleObjectPropertyChange} 
                                                                    disabled={props.saveInProgress} />
                                                            </Stack.Item>
                                                            <Stack.Item className={rowContentEntryClassName}>
                                                                <TextField name="phone"
                                                                    value={currentUser.phone} 
                                                                    label={t('customerCenter:CustomerMainUserSetupCard:CardGroup:CardField:phone')!}
                                                                    className={textFieldClassName}
                                                                    onChange={handleObjectPropertyChange} 
                                                                    disabled={props.saveInProgress} />
                                                            </Stack.Item>
                                                        </Stack>
                                                    </Stack.Item>
                                                    <Stack.Item className={formContentEntryClassName}>
                                                        <Stack horizontal key="row3" className={rowContentClassName} tokens={rowContentStackToken}>
                                                            <Stack.Item className={rowContentEntryClassName}>
                                                                <TextField name="userPrincipleName"
                                                                    value={currentUser.userPrincipleName.split("@")[0]} 
                                                                    label={t('customerCenter:CustomerMainUserSetupCard:CardGroup:CardField:userPrincipleName')!}
                                                                    suffix={"@" + customerInfoContext.defaultDomain.tenantDomain} 
                                                                    className={textFieldClassName}
                                                                    onChange={handleObjectPropertyChange}
                                                                    disabled={props.saveInProgress} />
                                                            </Stack.Item>
                                                        </Stack>
                                                    </Stack.Item>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        </Accordion>
                                    </Stack>
                                </Stack.Item>
                                {messageBar}
                                <Stack.Item key="modalFooterContent">
                                    <DialogFooter>
                                        <PrimaryButton text={t('leadDashboard:buttonText')!} onClick={() => {
                                            if (! currentUser.firstName)
                                                ToastService.showMessage(MessageType.Error, t('customerCenter:CustomerMainUserSetupCard:Err00002Text'));
                                            else if (! currentUser.lastName)
                                                ToastService.showMessage(MessageType.Error, t('customerCenter:CustomerMainUserSetupCard:Err00003Text'));
                                            else if (! currentUser.displayName)
                                                ToastService.showMessage(MessageType.Error, t('customerCenter:CustomerMainUserSetupCard:Err00004Text'));
                                            else if (! currentUser.userPrincipleName)
                                                ToastService.showMessage(MessageType.Error, t('customerCenter:CustomerMainUserSetupCard:Err00005Text'));
                                            else
                                                props.onDismiss(currentUser);
                                        }} disabled={props.saveInProgress} />
                                    </DialogFooter>
                                </Stack.Item>
                        </Stack>
                </Dialog>
            );
        }
    }
}