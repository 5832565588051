import { AnimationClassNames, Label, mergeStyles, Stack } from "@fluentui/react";
import React from "react";
import ScrollPointer from "../ScrollPointer/ScrollPointer";
import './DemoContainer.css';

const mainStackStyles = mergeStyles([{
    width: '98%',
    height: 'auto',
    backgroundColor: 'white',
    margin: '0 auto',
    marginTop: '80px',
    boxShadow: '0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%)',
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'stretch',
    selectors: {
        '&:first-child': { marginTop: '30px' }
    }
}]);

const titleContainerStyles = mergeStyles([{
    width: '100%',
    height: 'auto',
    backgroundColor: '#007bff',
    display: 'flex',
    alignItems: 'stretch',
    padding: '5px',
    paddingLeft: '15px',
    boxShadow: '0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%)'
},
AnimationClassNames.fadeIn500]);

const labelTitleStyle = mergeStyles([{
    color: 'black', 
    fontSize: '16px'
}]);

const mainContentStackStyles = mergeStyles([{
    width: '100%',
    height: 'auto',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'stretch'
},
AnimationClassNames.fadeIn500]);

export interface IDemoContainerProps {
    title: string;
    id: string;
    linkToId: string | undefined;
    scrollId: string;
    customHeight?:string;
    customWidth?:string;
};

export const DemoContainer: React.FC<IDemoContainerProps> = (props) => {
    let titleContainer: JSX.Element = <></>;
    if (props.title != "")
        titleContainer = <>
            <Stack.Item className="title-container">
                <Label className={labelTitleStyle}>{props.title}</Label>
            </Stack.Item>
        </>;
    
    let scrollPointerContainer: JSX.Element = <></>;
    if (props.linkToId != "")
        scrollPointerContainer = <>
            <Stack.Item>
                <ScrollPointer reference={props.linkToId!} scrollId={props.scrollId}></ScrollPointer>
            </Stack.Item>
        </>;
    
    return (
        <Stack>
            <Stack.Item>
                <Stack className={mainStackStyles} style={{height:props.customHeight,width:props.customWidth,maxWidth:'98vw',minWidth:props.customWidth}} id={props.id}>
                    {titleContainer}
                    <Stack.Item className={mainContentStackStyles}>
                        {props.children}
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            {scrollPointerContainer}
        </Stack>
    );
};