import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminNotificationGroupMemberCard } from "../AdminNotificationGroupMemberCard/AdminNotificationGroupMemberCard";
import { AdminWizardHeaderCard } from "./AdminWizardHeaderCard";

export const AdminWizardHeaderList : React.FC = () => {
    const cardRef = useRef<PageContainerBase>(null);
    const navigate = useNavigate();

    return (
        <>
            <PageContainer endpoint={"/odata/ui/wizard/header"} pageType={SystemPageType.List} title={"Wizard"} cardRef={cardRef} backAllowed insertAllowed updateAllowed deleteAllowed>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry label="Steps" iconName="Step" name="ViewSteps" runOnRec isPromoted onClick={(record) => {
                            navigate("/app/admin/ui/wizard/lines?wizardCode=" + record.code);
                        }} />
                        <ActionEntry label="Mapping" iconName="HighlightMappedShapes" name="ViewMapping" runOnRec isPromoted onClick={(record) => {
                            navigate("/app/admin/ui/wizard/mapping?wizardCode=" + record.code);
                        }} />
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Codice" fieldName="code" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Attivo" fieldName="active" minWidth={100} maxWidth={200} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminWizardHeaderCard ref={cardRef} />
        </>
    );
}