import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminDashboardLinkCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {

    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda link dashboard"} endpoint="odata/dashboard/link" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="code" type={SystemFieldType.String} label="Codice" />
                <CardField name="description" type={SystemFieldType.String} label="Descrizione" />
                <CardField name="toolTipText" type={SystemFieldType.String} label="Tooltip" />
                <CardField name="order" type={SystemFieldType.Number} label="Ordinamento" />
                <CardField name="active" type={SystemFieldType.Boolean} label="Attivo" />
                <CardField name="forUserType" type={SystemFieldType.Option} label="Tipologia utente" cmbOptions={[
                    {key: 0, text: "Utente Admin"},
                    {key: 1, text: "Utente Normale"},
                    {key: 2, text: "Entrambi i tipi"}
                ]} />
                <CardField name="visibleFor" type={SystemFieldType.Option} label="Visibile per" cmbOptions={[
                    {key: 0, text: "Lead"},
                    {key: 1, text: "Customer"},
                    {key: 2, text: "Lead e Customer"}
                ]} />
                <CardField name="groupCode" type={SystemFieldType.String} label="Codice gruppo" />
                <CardField name="groupDescription" type={SystemFieldType.String} label="Descrizione gruppo" />
                <CardField name="iconName" type={SystemFieldType.String} label="Nome icona" />
                <CardField name="linkType" type={SystemFieldType.Option} label="Tipologia link" cmbOptions={[
                    {key: 0, text: "Link esterno"},
                    {key: 1, text: "Link interno"},
                    {key: 2, text: "Apertura pagina"},
                    {key: 3, text: "Mail to"}
                ]} />
                <CardField name="linkUrl" type={SystemFieldType.String} label="Url link" />
            </CardGroup> 
        </PageContainer>
    );
});
