import React, { useRef }  from "react";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { SystemPageType } from "../../../../Model/SystemModels";
import { AdminProspectLeadAutomationSetupCard } from "../AdminProspectLeadAutomationSetupCard/AdminProspectLeadAutomationSetupCard";
import { t } from "i18next";

export const AdminProspectLeadAutomationSetupList : React.FC = () => {
    
    const cardRef = useRef<PageContainerBase>(null);

    return (
        <>
            <PageContainer endpoint="/odata/prospect/lead/automation/setup" pageType={SystemPageType.List} title="Lista prospect lead automation" cardRef={cardRef} backAllowed showAllowed insertAllowed updateAllowed deleteAllowed>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Codice" fieldName="code" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Abilitato" fieldName="enabled" minWidth={80} maxWidth={100} />
                    <ListHeaderEntry name="Tipo proprietario suggerito" fieldName="suggestedOwnerType" minWidth={100} maxWidth={200} onRender={(item: any, index, column) => {
                        if (item.suggestedOwnerType === 0)
                            return "Utente"
                        else if (item.suggestedOwnerType === 1)
                            return "Entità"
                    }} />
                    <ListHeaderEntry name="Id proprietario suggerito" fieldName="suggestedOwnerId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Nome entità correlata" fieldName="linkedEntityName" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Tipo azione suggerita" fieldName="suggestedActionType" minWidth={100} maxWidth={200} onRender={(item: any, index, column) => {
                        if (item.suggestedActionType === 0)
                            return "Task"
                        else if (item.suggestedActionType === 1)
                            return "Email"
                        else if (item.suggestedActionType === 2)
                            return "Email CRM"
                        else if (item.suggestedActionType === 3)
                            return "Aggiunta contatto a segmento"
                        else if (item.suggestedActionType === 4)
                            return "Cambio stato DS"
                        else if (item.suggestedActionType === 5)
                            return "Conversioni lead"
                    }} />
                    <ListHeaderEntry name="Codice template email" fieldName="emailTemplateCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Oggetto email da lead" fieldName="subjectFromLead" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Codice template task" fieldName="taskTemplateMessage" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Codice stato destinazione" fieldName="destinationRecordStatusCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:leadCard:lossCauseCode")} fieldName="lossCauseCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:leadCard:crmStatusCode")} fieldName="crmStatusCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id segmento CRM" fieldName="crmSegmentId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Accettazione automatica" fieldName="autoAccept" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="One Shot" fieldName="oneShot" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Partner Business Contract" fieldName="partnerBusinessContractCode" minWidth={150} maxWidth={200} />
                    <ListHeaderEntry name="Giorni ritardo azione" fieldName="suggestedDelayDays" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminProspectLeadAutomationSetupCard ref={cardRef} />
        </>
    );
}