import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminCSPSetupCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda CSP setup"} endpoint="odata/CSP/setup" ref={ref}>
            <CardGroup name="localization" label="Localizzazione" isOpen={true}>
                <CardField name="legalEntityCode" label="Legal entity" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/crm/legal/entity" tableRelationKey="code" tableRelationField="name"/>
                <CardField name="distributorCode" label="Distributore" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/distributor" tableRelationKey="code" tableRelationField="name"/>
                <CardField name="partnerBusinessContractCode" label="Partner business contract" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/partner/business/contract" tableRelationKey="code" tableRelationField="description"/>
                <CardField name="languageCode" label="Lingua" type={SystemFieldType.String} />
            </CardGroup>
            <CardGroup name="setup" label="Configurazione" isOpen={true}>
                <CardField name="clientId" type={SystemFieldType.String} label="Id client" />
                <CardField name="clientSecret" type={SystemFieldType.String} label="Secret" />
                <CardField name="domain" type={SystemFieldType.String} label="Dominio" />
                <CardField name="tenantId" type={SystemFieldType.String} label="Id tenant" />
                <CardField name="redirectUrl" type={SystemFieldType.String} label="Redirect url" />
                <CardField name="audience" type={SystemFieldType.String} label="Audience" />
                <CardField name="aadInstance" type={SystemFieldType.String} label="AAD Instance" />
                <CardField name="authType" type={SystemFieldType.Option} cmbOptions={[
                    {key:0,text:"No auth"},
                    {key:1,text:"Credentials"},
                    {key:2,text:"Custom"},
                    {key:3,text:"Authorization code"}
                ]} label="Auth type" />
                <CardField name="batchQueryChunks" type={SystemFieldType.Number} label="Batch Query Chunks" />
                <CardField name="defaultCountryCode" type={SystemFieldType.String} label="Default Country Code" />
                <CardField name="defaultProductTargetView" type={SystemFieldType.String} label="Default Product Target View" />
                <CardField name="defaultCulture" type={SystemFieldType.String} label="Default Culture" />
                <CardField name="defaultLanguage" type={SystemFieldType.String} label="Default Language" />
                <CardField name="defaultAgreementType" type={SystemFieldType.String} label="Default Agreement Type" />
                <CardField name="generateMissingSubscriptionItem" type={SystemFieldType.Boolean} label="Generate Missing Subscription Item" />
                <CardField name="soSmartAdminUserName" type={SystemFieldType.String} label="So Smart Admin Username" />
                <CardField name="soSmartAdminPassword" type={SystemFieldType.String} label="So Smart Admin Password" />
                <CardField name="waitingSeconds" type={SystemFieldType.Number} label="Secondi di attesa" />
                <CardField name="cspAdminRole" type={SystemFieldType.String} label="Ruolo Admin CSP" />
                <CardField name="gdapBaseUrl" type={SystemFieldType.String} label="GDAP base URL" />
                <CardField name="manageUserLicense" type={SystemFieldType.Boolean} label="Gestisci licenze utenti" />
                <CardField name="gdapAuthenticationSetupCode" type={SystemFieldType.String} label="Authentication Setup Code" />
            </CardGroup>
        </PageContainer>
    );
});