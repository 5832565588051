import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import {SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminDataSecurityStatusCard } from "./AdminDataSecurityStatusCard";

export const AdminDataSecurityStatusList : React.FC = () => {
    const cardRef = useRef<PageContainerBase>(null);
    const entityName = HttpHelper.getParameter('entityName')
    const navigate = useNavigate();

    return (
        <>
            <PageContainer endpoint={"/odata/datasecurity/status?$filter=entityName eq '" + entityName + "'"} pageType={SystemPageType.List} title="Stati Data Security" backAllowed insertAllowed deleteAllowed updateAllowed cardRef={cardRef}>               
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="ViewChangeStatus" label="Cambi stato" iconName="VisioDiagramSync" isPromoted onClick={(record: any) => {
                            navigate("/app/admin/datasecurity/statusChange?entityName=" + record.entityName + "&fromCode=" + record.code);
                        }} />
                    </ActionArea>
                </PageDefinitionContainer>                             
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Codice" fieldName="code" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Stato iniziale" fieldName="first" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Stato finale" fieldName="last" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminDataSecurityStatusCard ref={cardRef} />
        </>
    );
}