import React, { forwardRef} from "react";
import {SystemFieldType, SystemPageType} from "../../../../Model/SystemModels";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import { CardField } from "../../../Common/Template/Card/CardField";

export const AdminCrmSourceCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} 
                       title={"Scheda sorgenti CRM "} 
                       endpoint="odata/crm/source"
                       ref={ref}>
            <CardGroup name="Sorgenti" label="Sorgenti" isOpen={false}>
                <CardField name="code" type={SystemFieldType.String} label="Codice" readonly/>
                <CardField name="description" type={SystemFieldType.String} label="Descrizione"/>
                <CardField name="crmReferenceId" type={SystemFieldType.String} label="Id Referenza CRM"/>
            </CardGroup> 
        </PageContainer>
    );
});