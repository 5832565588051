import { DetailsList, DetailsListLayoutMode, IColumn, Label, mergeStyles, SelectionMode, Stack } from "@fluentui/react";
import React from "react";
import { Feature } from "../../../Model/DemoModels";

const entryContainerStyle = mergeStyles([{
  width: '100%',
  padding: '5px',
  cursor: 'pointer',
  borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
  selectors: {
    '&:hover': {
      backgroundColor: '#f2f2f2'
    }
  }
}]);

export interface FeatureListProp {
    features: Feature[];
    onItemInvoked: (item: Feature) => void;
}

export class FeatureList extends React.Component<FeatureListProp, {}> {
    private _columns: IColumn[];
  
    constructor(props: FeatureListProp) {
      super(props);

      this._columns = [
        { key: '1', name: 'Descrizione', fieldName: 'process_Descrption', minWidth: 50, isResizable: true }
      ];
    }
  
    public render(): JSX.Element {  
      const entries: JSX.Element[] = [];
      this.props.features.forEach(element => {
        entries.push(
          <Stack onClick={() => this.props.onItemInvoked(element)} className={entryContainerStyle} 
                key={element.processModule + element.processCode + element.processTestLineNo.toString() + element.processFAQLineNo.toString()}>
            <Label style={{
              color: '#696969',
              cursor: 'pointer',
              fontSize: 12
            }}>{element.processDescrptionCaption}</Label>
          </Stack>
        );
      });
      
      
      return (
        <Stack verticalFill style={{
          width: '100%',
          height: '100%',
          cursor: 'pointer'
        }}>
          {entries}
        </Stack>
      );
    }
  }