import { ContextualMenu, DefaultButton, Dialog, DialogFooter, DialogType, hiddenContentStyle, mergeStyles, PrimaryButton } from "@fluentui/react";
import { t } from "i18next";
import React from "react";

export interface IDemoTeachingQuestionProps {
    onSuccess: () => void;
    OnDismiss: () => void;
    showDialog: boolean;
}

  const dialogStyles = { main: { maxWidth: 450 } };
  const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
    keepInBounds: true,
  };
  const screenReaderOnly = mergeStyles(hiddenContentStyle);

export const DemoTeachingQuestion = (props: IDemoTeachingQuestionProps) => {

  const dialogContentProps = {
      type: DialogType.normal,
      title: t('demoCenter:DemoTeachingQuestion:title')!,
      closeButtonAriaLabel: 'Close',
      subText: t('demoCenter:DemoTeachingQuestion:subText')!,
    };

    const modalProps = React.useMemo(
        () => ({
          titleAriaId: 'lbl',
          subtitleAriaId: 'txt',
          isBlocking: false,
          styles: dialogStyles,
          dragOptions: undefined,
        }),
        [false, "lbl", "txt"],
      );

    return (
        <Dialog
            hidden={!props.showDialog}
            onDismiss={props.OnDismiss}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
        >
            <DialogFooter>
                <PrimaryButton onClick={props.onSuccess} text={t('demoCenter:DemoTeachingQuestion:primaryButtonText')!} />
                <DefaultButton onClick={props.OnDismiss} text={t('demoCenter:DemoTeachingQuestion:defaultButtonText')!} />
            </DialogFooter>
        </Dialog>
    );
}
