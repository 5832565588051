import { useRef, useState } from "react";
import { SystemCore } from "../../../../Core/System/SystemCore";
import { SystemDataLoadingStatus, SystemPageType } from "../../../../Model/SystemModels";
import { Loader } from "../../../Common/Loader/Loader";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminBookingServiceCard } from "../AdminBookingServiceCard/AdminBookingServiceCard";

export const AdminBookingServiceList : React.FC = () => {
    
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.Loaded);
    const [loaderMessage, setLoaderMessage] = useState("");
    const cardRef = useRef<PageContainerBase>(null);
    
    if (!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={loaderMessage}></Loader>;
    else
        return (
            <>
                <PageContainer endpoint={'/odata/booking/service'} pageType={SystemPageType.List} title={"Servizi Booking"} cardRef={cardRef} backAllowed showAllowed updateAllowed>
                    <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry fieldName="calendarId" name="Id Calendario" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="id" name="Id" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="displayName" name="Nome" minWidth={300} maxWidth={400} />
                        <ListHeaderEntry fieldName="description" name="Descrizione" minWidth={300} maxWidth={400} />
                        <ListHeaderEntry fieldName="notes" name="Note" minWidth={300} maxWidth={400} />
                        <ListHeaderEntry fieldName="isHiddenFromCustomers" name="Nascosto per i clienti" minWidth={150} maxWidth={200} />
                        <ListHeaderEntry fieldName="webUrl" name="Url" minWidth={200} maxWidth={300} />
                        <ListHeaderEntry fieldName="bookingVisibility" name="Visibilità" minWidth={100} maxWidth={200} onRender={(item: any, index, column)=>{
                            if (item.bookingVisibility === 0)
                                return "None"
                            else if (item.bookingVisibility === 1)
                                return "Customer"
                            else if (item.bookingVisibility === 2)
                                return "Partner"
                            else if (item.bookingVisibility === 3)
                                return "All"
                        }} />
                    </PageDefinitionContainer>
                </PageContainer>
                <AdminBookingServiceCard ref={cardRef} />
            </>
        );
}