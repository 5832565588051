import React, {forwardRef} from "react";
import {SystemFieldType,SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminEmailAttachmentCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} endpoint="/odata/email/attachment" title="Allegato Email" ref={ref}>
            <CardGroup name="general" label="General" isOpen={true}>                
                <CardField label="Id" name="entryNo" type={SystemFieldType.Number} readonly />
                <CardField label="Codice template" name="emailTemplateCode" type={SystemFieldType.String} readonly />
                <CardField label="Nome" name="name" type={SystemFieldType.String} readonly />
                <CardField label="Estensione" name="extension" type={SystemFieldType.String} readonly />
                <CardField label="Content type" name="contentType" type={SystemFieldType.String} readonly />
            </CardGroup>
            <CardGroup name="localization" label="Localizzazione" isOpen={true}>
                <CardField name="legalEntityCode" label="Legal entity" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/crm/legal/entity" tableRelationKey="code" tableRelationField="name"/>
                <CardField name="distributorCode" label="Distributore" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/distributor" tableRelationKey="code" tableRelationField="name"/>
                <CardField name="partnerBusinessContractCode" label="Partner business contract" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/partner/business/contract" tableRelationKey="code" tableRelationField="description"/>
                <CardField name="languageCode" label="Lingua" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/system/language" tableRelationKey="code" tableRelationField="name"/>
            </CardGroup>
        </PageContainer>
    );
});