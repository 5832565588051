import { Label, mergeStyles, Stack } from "@fluentui/react";
import React from "react";
import { DayEntry } from "../../../Model/DemoModels";

export interface IDayBlockProps {
    entry: DayEntry;
    onClick: (entry: DayEntry) => void;
};

const stackStyle = mergeStyles([{
    paddingLeft: '15px',
    cursor: 'pointer'
}]);

const dayStyle = mergeStyles([{
    color: 'white',
    fontSize: 20,
    cursor: 'pointer'
}]);

const descriptionStyle = mergeStyles([{
    color: 'white',
    fontSize: 13,
    cursor: 'pointer'
}]);

export const DayBlock: React.FC<IDayBlockProps> = (props: IDayBlockProps) => {
    return (
        <div id="pointer" onClick={() => props.onClick(props.entry)}>
            <Stack className={stackStyle} key={props.entry.day}>
                <Stack.Item> 
                    <Label className={dayStyle}>
                        {props.entry.description}
                    </Label>
                </Stack.Item>
            </Stack>
        </div>
    );
};