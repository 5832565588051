import React, { useRef } from "react";
import { SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminItemRequirementCard } from "../AdminItemRequirementCard/AdminItemRequirementCard";

export const AdminItemRequirementList : React.FC = () => {
    const cardRef = useRef<PageContainerBase>(null);
    
    return (
        <>
            <PageContainer endpoint="/odata/ItemRequirement" pageType={SystemPageType.List} title="Item Requirement List" backAllowed insertAllowed updateAllowed deleteAllowed showAllowed cardRef={cardRef}>               
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>                    
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Source Item Id" fieldName="sourceId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Source Item Description" fieldName="sourceDescription" minWidth={400} maxWidth={500} />
                    <ListHeaderEntry name="Destination Item Id" fieldName="destinationId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Destination Item Description" fieldName="destinationDescription" minWidth={400} maxWidth={500} />
                    <ListHeaderEntry name="Ask Confirm" fieldName="askConfirm" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminItemRequirementCard ref={cardRef} />
        </>
    );
}