import { DefaultButton, PrimaryButton, Stack,mergeStyles,AnimationClassNames,Text, TextField,Toggle,Label,TooltipHost,DirectionalHint } from "@fluentui/react";
import React, { useState } from "react";

export interface ICustomerTicketDialogProps {
    onSuccess: (obj:any) => void;
    onDismiss:()=>void
}

const mainContentStackStyles = mergeStyles([{
    width: '500px',
    height: 'calc(100vh - 150px)',
    backgroundColor: 'white',
    margin: '0 auto',
    marginRight:15,
    marginTop: '80px',
    boxShadow: '0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%)',
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'stretch',
    selectors: {
        '&:first-child': { marginTop: '30px' }
    }
},
AnimationClassNames.fadeIn500]);

const buttonStyles = {
  root: {
    background: 'transparent',
    border: 'none',
    minWidth: '16px',
    padding: 0,
  },
};

const labelTitleStyle = mergeStyles([{
    color: 'black', 
    fontSize: '16px'
}]);

const titleContainerClassName = mergeStyles([{
    width: '100%',
    height: 'auto',
    display: 'flex',
    alignItems: 'stretch',
    padding: '5px',
    boxShadow: '0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%)',
    cursor: 'pointer',
    transition: 'all ease-in-out 0.2s',
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
}])

export const CustomerSendTicket: React.FC<ICustomerTicketDialogProps> = (props) => {
    const [showTooltip, setShowTooltip] = useState(false);

    let ticketData = {
        Mail:"",
        Title:"",
        Description:"",
        rememberMe:false
    };
  
    const iconTooltip = (
      <>
        <TooltipHost content={showTooltip ? 'Abilitando questa opzione permetterai a SoSmart di impostare questa mail come predefinita per esigenze future' : undefined}>
          <DefaultButton
            onClick={() => setShowTooltip(!showTooltip)}
            styles={buttonStyles}
            iconProps={{ iconName: 'Info' }}
          />
        </TooltipHost>
      </>
    );

    const handleRequestChange = (event:any)=>{
        switch(event.nativeEvent.target.id){
            case 'txtMail':
                ticketData.Mail=event.target.value;
            break;
            case 'txtTitle':
                ticketData.Title=event.target.value;
            break;
            case 'txtDescr':
                ticketData.Description=event.target.value;
            break;
        }
    }

    const handleToggleChange = (event:any,value:any)=>{
        if(value)
            ticketData.rememberMe=true;
        else
            ticketData.rememberMe=false;
    }
    
    return (
        <Stack>
            <Stack.Item>
                <Stack className={mainContentStackStyles}>
                    <Stack.Item className={titleContainerClassName}>
                        <Label className={labelTitleStyle}>SoSmart - Apertura Ticket</Label>
                    </Stack.Item>
                    <Stack horizontal style={{margin:20,overflowY:'auto',padding:5}}>
                        <Stack.Item style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin:'0 auto'
                        }}>
                            <Stack verticalFill>
                                <Stack.Item>
                                    <Text style={{fontSize: 15}}>Inserisci la tua e-mail:</Text>
                                    <TextField onChange={(value)=>{handleRequestChange(value)}} 
                                            id='txtMail' >
                                    </TextField>
                                    <Stack horizontal style={{marginTop:10}}>
                                        <Toggle label={"Impostare questa mail come predefinita?"} 
                                                inlineLabel 
                                                onText="Si" 
                                                offText="No" 
                                                onChange={(e,value)=>{handleToggleChange(e,value)}} />
                                        <TooltipHost directionalHint={DirectionalHint.bottomCenter} content={showTooltip ? 'Abilitando questa opzione permetterai a SoSmart di impostare questa mail come predefinita per esigenze future' : undefined}>
                                            <DefaultButton
                                                onClick={() => setShowTooltip(!showTooltip)}
                                                styles={buttonStyles}
                                                iconProps={{ iconName: 'Info' }} />
                                        </TooltipHost>
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item style={{paddingTop:10}}>
                                    <Text style={{fontSize: 15}}>Titolo della richiesta:</Text>
                                    <TextField onChange={(value)=>{handleRequestChange(value)}} 
                                            id='txtTitle' >        
                                    </TextField>
                                </Stack.Item>
                                <Stack.Item style={{paddingTop:10}}>
                                    <Text onChange={(value)=>{handleRequestChange(value)}} 
                                        style={{fontSize: 15}}>Descrizione della richiesta:</Text>
                                    <TextField onChange={(value)=>{handleRequestChange(value)}} 
                                            id='txtDescr' 
                                            multiline 
                                            resizable={false} 
                                            style={{width:'auto',resize:'none',height:'200px'}}>
                                    </TextField>
                                </Stack.Item>
                                <Stack.Item style={{
                                    marginTop: 20
                                }}>
                                    <PrimaryButton text="Invia" onClick={(data)=>{props.onSuccess(ticketData)}} />
                                    <PrimaryButton style={{backgroundColor:'grey',marginLeft:5,border:'1px solid grey'}} text="Annulla" onClick={props.onDismiss} />
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                    </Stack> 
                </Stack>
            </Stack.Item>
        </Stack>
    )
} 