import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminSystemAppCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda System App"} endpoint="odata/system/app" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="id" type={SystemFieldType.String} label="Id" />
                <CardField name="name" type={SystemFieldType.String} label="Nome" />
                <CardField name="title" type={SystemFieldType.String} label="Titolo" />
                <CardField name="baseAddress" type={SystemFieldType.String} label="URL Base" />
                <CardField name="active" type={SystemFieldType.Boolean} label="Attiva" />
                <CardField name="appType" type={SystemFieldType.Option} label="Tipologia" cmbOptions={[
                    {key: 0, text: "Cutomer"},
                    {key: 1, text: "Admin"},
                    {key: 2, text: "Partner"},
                    {key: 3, text: "Demo"}
                ]} />
                <CardField name="appLogo" type={SystemFieldType.Blob} ext=".png" label="Logo Applicazione" />
                <CardField name="appIcon" type={SystemFieldType.Blob} ext=".png" label="Icona Applicazione" />
                <CardField name="tokenMinutesDuration" type={SystemFieldType.Number} label="Durata Token (Minuti)" />
                <CardField name="allowRefreshToken" type={SystemFieldType.Boolean} label="Refresh Token Abilitato" />
                <CardField name="helpPageEnable" type={SystemFieldType.Boolean} label="Pagina Di Aiuto Abilitata" />
                <CardField name="showChatBot" type={SystemFieldType.Boolean} label="Mostra ChatBot" />
                <CardField name="powerAutomateKey" type={SystemFieldType.String} label="Power Automate Key" />
            </CardGroup>
            <CardGroup name="localization" label="Localizzazione" isOpen={true}>
                <CardField label="Legal Entity (Default)" name="defaultLegalEntityCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/crm/legal/entity" tableRelationKey="code" tableRelationField="name" />
                <CardField label="Lingua (Default)" name="languageCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/system/language" tableRelationKey="code" tableRelationField="name" />
            </CardGroup>
        </PageContainer>
    );
});