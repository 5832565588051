import { Checkbox, ComboBox, IColumn, IComboBoxOption, ICommandBarItemProps, IContextualMenuItem, IContextualMenuProps, IStackTokens, mergeStyles, SelectableOptionMenuItemType, Shimmer, Spinner, Stack, Text, TextField } from "@fluentui/react";
import { rejects } from "assert";
import { Console } from "console";
import { read } from "fs";
import { t } from "i18next";
import { resolve } from "path";
import React, { Children } from "react";
import { Accordion } from "react-accessible-accordion";
import { Loader } from "../../Components/Common/Loader/Loader";
import { IActionAreaProps } from "../../Components/Common/Template/Action/ActionArea";
import { IActionEntryProps } from "../../Components/Common/Template/Action/ActionEntry";
import { ICardFieldProps } from "../../Components/Common/Template/Card/CardField";
import { CardGroup, ICardGroupProps } from "../../Components/Common/Template/Card/CardGroup";
import { CommonControl } from "../../Components/Common/Template/Controls/CommonControls";
import { IListHeaderEntryProps } from "../../Components/Common/Template/List/ListHeaderEntry";
import { IPageContainerProps, PageContainerBase } from "../../Components/Common/Template/PageContainer";
import { ContainerType } from "../../Components/Common/Template/PageDefinitionContainer";
import { HttpHelper } from "../../Core/Http/HttpHelper";
import { SystemCore } from "../../Core/System/SystemCore";
import { OperationType, SystemActionCategory, SystemDataLoadingStatus, SystemFieldType, SystemOperation, SystemPageType, SystemTableRelationEntry } from "../../Model/SystemModels";
import { CommonFunctions } from "./CommonFunctions";

export interface ICommonListData {
    columns: IColumn[];
    actions: ICommandBarItemProps[];
    farActions: ICommandBarItemProps[];
}

export interface ITemplateBuilderOptions {
    getPreviewsPage : () => void;
    getSelectedRecord: () => any;
    hasSelectedRecord: () => boolean;
    handleRecordChange?: (record: any, fieldName: string, value: any) => any;
    getRecordFieldValue?: (fieldName: string) => any;
    getRecordSet?: () => any;
    setRecordSet?: (records: any[]) => void;
    setRecord: (newRecord: any) => void;
    setTableRelationData?: (tableRelationEntry: SystemTableRelationEntry) => void;
    getTableRelationData?: (fieldName?: string) => SystemTableRelationEntry | undefined | SystemTableRelationEntry[];
    reloadDataset?: () => void;
    shareCurrentContent?: ()=> void;
    pagerConfiguration: IPageContainerProps;
    cardRef?: React.RefObject<PageContainerBase>;
    saveInProgress: boolean;
    dataStatus: SystemDataLoadingStatus;
    operationType: SystemOperation;
}

const rowContentStackToken: IStackTokens = {
    childrenGap: 50
};


const formContentEntryClassName = mergeStyles([{
    width: '100%'
}]);

const rowContentClassName = mergeStyles([{
    width: '100%',
    marginBottom: 7
}]);

const rowContentEntryClassName = mergeStyles([{
    width: '48%'
}]);

const cardShimmerLabelClassName = mergeStyles([{
    fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
    WebkitFontSmoothing: 'antialiased',
    fontSize: 14,
    fontWeight: 600,
    color: 'rgb(161, 159, 157)',
    boxSizing: 'border-box',
    boxShadow: 'none',
    margin: 0,
    display: 'block',
    padding: '5px 0px',
    overflowWrap: 'break-word'
}]);

export class CommonTemplateBuilder {

    private builderOptions: ITemplateBuilderOptions;

    constructor(options: ITemplateBuilderOptions)
    {
        this.builderOptions = options;
    }

    public builCardTemplate(arrayChildren: (React.ReactChild | React.ReactFragment | React.ReactPortal)[]) : JSX.Element
    {
        let structures: JSX.Element[] = [];
        let openGroups: string[] = [];

        const accordionClassName = mergeStyles([{
            overflowY: 'auto !important',
        }]);

        for(let i = 0; i < arrayChildren.length; i++)
        {
            let entry = this.processCardEntry(arrayChildren[i], openGroups);
            if (entry != undefined){
                structures.push(entry);
            }
        }
        
        if (structures.length > 0)
        {
            if (this.builderOptions.pagerConfiguration.pageType == SystemPageType.Card)
            {
                return (
                    <Accordion allowMultipleExpanded preExpanded={openGroups}>
                        {structures}
                    </Accordion>
                );
            }
            else if (this.builderOptions.pagerConfiguration.pageType == SystemPageType.CardCustom)
            {
                return (
                    <Accordion className={accordionClassName} allowMultipleExpanded preExpanded={openGroups}>
                        {structures}
                    </Accordion>
                );
            }
            else if (this.builderOptions.pagerConfiguration.pageType == SystemPageType.CardPanel)
            {
                return (
                    <Accordion  allowMultipleExpanded preExpanded={openGroups}>
                        {structures}
                    </Accordion>
                );
            }
        }
        return (
            <>
                {arrayChildren}
            </>
        );
    }

    public buildListTemplate(arrayChildren: (React.ReactChild | React.ReactFragment | React.ReactPortal)[]) : ICommonListData
    {
        let data: ICommonListData = {} as ICommonListData;
        data.actions = [];
        data.farActions = [];

        for (let i = 0; i < arrayChildren.length; i++)
        {
            let entry : any = arrayChildren[i];
            let containerType: ContainerType = entry.props.type;

            switch(containerType)
            {
                case ContainerType.PageStructure:
                    data.columns = this.processListHeaderChildren(entry);
                    break;
                case ContainerType.Action:
                    const crudActions = this.getCRUDActions();
                    const farActions = this.getFarActions();
                    const structureActions = this.processListActionEntry(entry, []);
                    
                    let index = 0;
                    for(index = 0; index < crudActions.length; index++)
                        data.actions.push(crudActions[index]);
                    for (index = 0; index < structureActions.length; index++)
                        data.actions.push(structureActions[index]);
                    for (index = 0; index < farActions.length; index++)
                        data.farActions.push(farActions[index]);
                    break;
            }
        }

        return data;
    }

    private processFieldDependencies(tableRelationEntry: SystemTableRelationEntry) : SystemTableRelationEntry
    {
        if (tableRelationEntry.filter == undefined)
            return tableRelationEntry;
            
        tableRelationEntry.relatedFields = [];
        let currentRecord = this.builderOptions.getSelectedRecord();
        if (currentRecord == undefined)
            currentRecord = {};

        tableRelationEntry.filter = tableRelationEntry.notParsedFilter;
        let keys = Object.keys(currentRecord);
        for (let i = 0; i < keys.length; i++)
        {
            let fieldKeyword = "#" + keys[i] + "#";
            if (tableRelationEntry.filter.indexOf(fieldKeyword) >= 0)
            {   
                let stringValue: string = CommonFunctions.convertString(String(Object.values(currentRecord)[i]));
                tableRelationEntry.filter = tableRelationEntry.filter.replace(fieldKeyword, stringValue);
                tableRelationEntry.relatedFields.push(keys[i]);
            }
        }
        return tableRelationEntry;
    }

    public getFieldsWithTableRelation(arrayChildren: (React.ReactChild | React.ReactFragment | React.ReactPortal)[]) : Promise<SystemTableRelationEntry[]> 
    {
        return new Promise<SystemTableRelationEntry[]>(async (resolve, rejects) => {
            let tableRelationEntries: SystemTableRelationEntry[] = [];

            for(let i = 0; i < arrayChildren.length; i++)
            {
                let entry: any = arrayChildren[i];
                let elementName = this.getElementName(arrayChildren[i]);
                if (! elementName)
                    return [];
    
                let anyProps: any = entry.props;
                let isGroup = (anyProps.type == undefined);
                if (isGroup)
                {
                    let groupChildren = Children.toArray(entry.props.children);
                    for(let j = 0; j < groupChildren.length; j++)
                    {
                        let entryChild: any = groupChildren[j];
                        let fieldProperty: ICardFieldProps = entryChild.props;
                        if (fieldProperty.hasTableRelation)
                        {
                            let tableRelationEntry = new SystemTableRelationEntry()
                            tableRelationEntry.fieldName = fieldProperty.name;
                            tableRelationEntry.endpoint = fieldProperty.tableRelationEndpoint!;
                            tableRelationEntry.notParsedFilter = fieldProperty.tableRelationFilter!;
                            tableRelationEntry.filter = fieldProperty.tableRelationFilter!;
                            tableRelationEntry.keyField = fieldProperty.tableRelationKey!;
                            tableRelationEntry.dropDownField = fieldProperty.tableRelationField!;
                            tableRelationEntry.loaded = false;
                            tableRelationEntry = this.processFieldDependencies(tableRelationEntry);

                            let endpoint = tableRelationEntry.endpoint;
                            let query = tableRelationEntry.keyField + ' eq ';
                            let keyValue = this.builderOptions.getRecordFieldValue!(fieldProperty.name);
                            if ((keyValue != undefined) && (keyValue != null))
                            {
                                if ((typeof(keyValue) == "number") || (typeof(keyValue) == "boolean"))
                                    query += keyValue; 
                                else{
                                    keyValue = CommonFunctions.convertString(keyValue);
                                    query += "'" + keyValue + "'";
                                }

                                if (tableRelationEntry.filter != undefined && tableRelationEntry.filter > "")
                                    endpoint += tableRelationEntry.filter + ' and ';
                                else
                                    endpoint += "?$filter=";
                                endpoint += query;

                                let result = await CommonFunctions.loadTableRelationDataFromUrl(endpoint);
                                if (result == undefined)
                                    rejects("Impossibile collegare in modo univoco il valore " + keyValue + " del campo " + fieldProperty.name);
                                else
                                {
                                    if (result.length == 1)
                                    {
                                        let keyIndex = Object.keys(result[0]).indexOf(fieldProperty.tableRelationField!);
                                        let fieldValue = Object.values(result[0])[keyIndex];
                                        tableRelationEntry.currentValue = fieldValue;
                                    }
                                }
                            }
    
                            tableRelationEntries.push(tableRelationEntry);
                        }
                    }
                }
            }
    
            resolve(tableRelationEntries);
        });
        
    }

    public filterItems(items: Object[], newValue: string): Object[]
    {
        return items.filter((e) => {
            let values = Object.values(e);
            for (let i = 0; i < values.length; i++)
            {
                let value: string = String(values[i]);
                if (value != undefined)
                {
                    if (value.toLocaleLowerCase().indexOf(newValue.toLocaleLowerCase()) >= 0)
                        return true;
                }
            }

            return false;
        });
    }

    private getCRUDActions() : ICommandBarItemProps[]
    {
        let crudActions: ICommandBarItemProps[] = [];
        if (this.builderOptions.pagerConfiguration.backAllowed)
            crudActions.push({
                key: "Back",
                text: t('common:CommonTemplateBuilder:CrudAction:back')!,
                iconProps: {
                    iconName: "Back"
                },
                onClick: () => {
                    this.builderOptions.getPreviewsPage();
                }
            });
        if (this.builderOptions.pagerConfiguration.showAllowed)
            crudActions.push({
                key: "Show",
                text: t('common:CommonTemplateBuilder:CrudAction:view')!,
                iconProps: {
                    iconName: "View"
                },
                onClick: () => {
                    if (this.builderOptions.pagerConfiguration.cardRef != undefined)
                    {
                        this.builderOptions.cardRef?.current?.setRecord(this.builderOptions.getSelectedRecord());
                        this.builderOptions.cardRef?.current?.openPage(SystemOperation.View);
                    }
                },
                disabled: ! this.builderOptions.hasSelectedRecord()
            });
        if (this.builderOptions.pagerConfiguration.insertAllowed)
            crudActions.push({
                key: "New",
                text: t('common:CommonTemplateBuilder:CrudAction:new')!,
                iconProps: {
                    iconName: "Add"
                },
                onClick: () => {
                    if (this.builderOptions.pagerConfiguration.onNewRecord != undefined)
                        this.builderOptions.pagerConfiguration.onNewRecord();
                    else
                    {
                        this.builderOptions.cardRef?.current?.setRecord({});
                        this.builderOptions.cardRef?.current?.openPage(SystemOperation.Insert);
                    }
                }
            });
        if (this.builderOptions.pagerConfiguration.updateAllowed)
            crudActions.push({
                key: "Edit",
                text: t('common:CommonTemplateBuilder:CrudAction:edit')!,
                iconProps: {
                    iconName: "Edit"
                },
                onClick: () => {
                    if (this.builderOptions.pagerConfiguration.onUpdateRecord != undefined)
                        this.builderOptions.pagerConfiguration.onUpdateRecord(this.builderOptions.getSelectedRecord());
                    else
                    {
                        this.builderOptions.cardRef?.current?.setRecord(this.builderOptions.getSelectedRecord());
                        this.builderOptions.cardRef?.current?.openPage(SystemOperation.Update);
                    }
                },
                disabled: ! this.builderOptions.hasSelectedRecord()
            });
        if (this.builderOptions.pagerConfiguration.deleteAllowed)
            crudActions.push({
                key: "Delete",
                text: t('common:CommonTemplateBuilder:CrudAction:delete')!,
                iconProps: {
                    iconName: "Delete"
                },
                onClick: () => {
                    if (this.builderOptions.pagerConfiguration.onDeleteRecord != undefined)
                        this.builderOptions.pagerConfiguration.onDeleteRecord(this.builderOptions.getSelectedRecord());
                    else
                    {
                        this.builderOptions.cardRef?.current?.setRecord(this.builderOptions.getSelectedRecord());
                        this.builderOptions.cardRef?.current?.openPage(SystemOperation.Delete, this.builderOptions.getSelectedRecord());
                    }
                },
                disabled: ! this.builderOptions.hasSelectedRecord()
            });
        if (this.builderOptions.pagerConfiguration.refreshAllowed)
            crudActions.push({
                key: "Refresh",
                text: t('common:CommonTemplateBuilder:CrudAction:refresh')!,
                iconProps: {
                    iconName: "Sync"
                },
                onClick: () => {
                    this.builderOptions.reloadDataset!();
                }
            });

        return crudActions;
    }

    private getFarActions(): ICommandBarItemProps[] {
        let farActions:ICommandBarItemProps[] = [];
        
        if (this.builderOptions.pagerConfiguration.shareAllowed)
            farActions.push({
                key: "Share",
                text: t('common:CommonTemplateBuilder:CrudAction:share')!,
                iconProps: {
                    iconName: "Share"
                },
                onClick: () => {
                    this.builderOptions.shareCurrentContent!();
                }
            });

        return farActions;
    }

    private processCardEntry(entry: any, openGroups: string[]) : any
    {
        let elementName = this.getElementName(entry);
        if (! elementName)
            return [];

        let anyProps: any = entry.props;
        let isGroup = (anyProps.type == undefined);


        if (isGroup)
        {
                let fields: JSX.Element[] = [];

                let groupProperty: ICardGroupProps = entry.props;
                let children = Children.toArray(entry.props.children);
                let rowFields: JSX.Element[] = [];
                if (groupProperty.isOpen)
                    openGroups.push(groupProperty.name);

                for (let i = 0; i < children.length; i++)
                {
                    let fieldEntry = this.processCardEntry(children[i], openGroups);
                    if (fieldEntry != undefined) 
                    {
                        rowFields.push(
                            <Stack.Item className={rowContentEntryClassName}>
                                {fieldEntry}
                            </Stack.Item>
                        );

                        if (rowFields.length == 2)
                        {
                            fields.push(
                                <Stack.Item className={formContentEntryClassName}>
                                    <Stack horizontal key="row1" className={rowContentClassName} tokens={rowContentStackToken}>
                                        {rowFields}
                                    </Stack>
                                </Stack.Item>
                            );

                            rowFields = [];
                        }
                    }
                }

                if (rowFields.length > 0)
                    fields.push(
                        <Stack.Item className={formContentEntryClassName}>
                            <Stack horizontal key="row1" className={rowContentClassName} tokens={rowContentStackToken}>
                                {rowFields}
                            </Stack>
                        </Stack.Item>
                    );

                return (
                    <CardGroup isOpen={groupProperty.isOpen} label={groupProperty.label} name={groupProperty.name}>
                        {fields}
                    </CardGroup>
                );
        }
        else 
        {
            let fieldProperty: ICardFieldProps = entry.props;
            if ((fieldProperty.onGetIsVisible != undefined) && (! fieldProperty.onGetIsVisible(this.builderOptions.getSelectedRecord())))
                return undefined;

            return this.processField(fieldProperty);
        }
    }

    private processField(fieldProperty: ICardFieldProps) : any 
    {
        if (! SystemCore.isFinishedDataStatus(this.builderOptions.dataStatus))
        {
            return (
                <Stack>
                    <Stack.Item>
                        <Text className={cardShimmerLabelClassName}>{fieldProperty.label}</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Shimmer width={"100%"} styles={{
                            shimmerWrapper: {
                                height: 32
                            }
                        }}></Shimmer>
                    </Stack.Item>
                </Stack>
            )
        }
        else
        {
            return (
                <CommonControl 
                    value={this.builderOptions.getRecordFieldValue!(fieldProperty.name)}
                    fieldProperty={fieldProperty}
                    builderOptions={this.builderOptions}
                    editable={this.isEditable() && !fieldProperty.readonly && !this.builderOptions.saveInProgress}
                    onValidate={(fieldName, fieldCaption, record, oldValue, newValue) => {
                        if (oldValue != newValue)
                        {
                            record = this.builderOptions.handleRecordChange!(record, fieldName, newValue);
                            if (fieldProperty.onValidate != undefined)
                                record = fieldProperty.onValidate(fieldName, record, oldValue, newValue);
                            
                            let currentRecord = this.builderOptions.getRecordSet!();
                            if (currentRecord != record)
                            {
                                this.builderOptions.setRecord(record);

                                let currentEntry = this.builderOptions.getTableRelationData!(fieldName) as SystemTableRelationEntry;
                                if (currentEntry != undefined)
                                {
                                    let tableRelation = currentEntry as SystemTableRelationEntry;
                                    if (tableRelation.hasError)
                                    {
                                        tableRelation.hasError = false;
                                        tableRelation.errorMessage = "";
                                        this.builderOptions.setTableRelationData!(tableRelation);
                                    }
                                }

                                let relatedEntries = this.getRelatedTableRelations(fieldName);
                                if (relatedEntries != undefined)
                                {
                                    for (let i = 0; i < relatedEntries.length; i++)
                                    {
                                        relatedEntries[i].loaded = false;
                                        relatedEntries[i].data = [];
                                        relatedEntries[i].hasError = true;
                                        relatedEntries[i].errorMessage = "Il valore del campo relazionato " + fieldCaption + " è stato cambiato. Aggiorna il campo";
                                        relatedEntries[i] = this.processFieldDependencies(relatedEntries[i]);
                                        this.builderOptions.setTableRelationData!(relatedEntries[i]);
                                    }
                                }
                            }

                            
                        }
                    }}
                    ></CommonControl>
            )
        }
    }

    private getRelatedTableRelations(fieldName: string) : SystemTableRelationEntry[] | undefined
    {
        let entries = this.builderOptions.getTableRelationData!() as SystemTableRelationEntry[];
        if (entries == undefined)
            return undefined;

        let relatedEntries: SystemTableRelationEntry[] = [];
        for (let i = 0; i < entries.length; i++)
        {
            entries[i] = this.processFieldDependencies(entries[i]);
            if (entries[i].relatedFields.indexOf(fieldName) >= 0)
                relatedEntries.push(entries[i]);
        }

        return relatedEntries;
        
    }

    private isEditable()
    {
        if ((this.builderOptions.operationType == SystemOperation.View) || 
            (this.builderOptions.operationType == SystemOperation.Delete))
            return false;

        if(this.builderOptions.operationType == SystemOperation.Insert)
            return true;

        return ! this.builderOptions.saveInProgress;
    }

    private processListHeaderChildren(entry: any) : any
    {
        const columns: IColumn[] = [];
        if (this.isPageStructureContainer(entry))
        {
            let children = Children.toArray(entry.props.children);
            let i = 0;
            for (i = 0; i < children.length; i++)
                columns.push(this.processListHeaderChildren(children[i]));
            
            return columns;
        }
        else
        {
            let property: IListHeaderEntryProps = entry.props;
            
            let column: IColumn = {} as IColumn;
            column.key = property.name;
            column.name = property.name;
            column.fieldName = property.fieldName;
            column.minWidth = property.minWidth;
            //column.maxWidth = property.maxWidth;
            column.isResizable = true;
            column.flexGrow = 1;
            column.onRender = property.onRender;
            return column;
        }
    }

    private processListActionEntry(entry: any, promotedActions: ICommandBarItemProps[]): any
    {

        if (this.isActionContainer(entry))
        {
            let children = Children.toArray(entry.props.children);
            let i = 0;
            const actions: ICommandBarItemProps[] = [];
            const subMenuActions: ICommandBarItemProps[] = [];

            for (i = 0; i < children.length; i++)
                subMenuActions.push(this.processListActionEntry(children[i], promotedActions));

            for (i = 0; i < promotedActions.length; i++)
                actions.push(promotedActions[i]);
            for (i = 0; i < subMenuActions.length; i++)
                actions.push(subMenuActions[i]);

            return actions;
        }
        if (this.isActionArea(entry))
        {
            let property: IActionAreaProps = entry.props;
            let item: ICommandBarItemProps = {} as ICommandBarItemProps;

            switch(property.category)
            {
                case SystemActionCategory.Process:
                    item.key = "Action";
                    item.text = t("common:ApllicationNavBar:Text000008Action")!;
                    break;
                case SystemActionCategory.Navigation:
                    item.key = "Navigation";
                    item.text = t("common:ApllicationNavBar:Text000007Action")!;
                    break;
            }

            let actionChildren = Children.toArray(entry.props.children);
            item.subMenuProps = {} as IContextualMenuProps;
            item.subMenuProps.items = [];
            item.subMenuProps.key = item.key + "subMenu";

            for (let i = 0; i < actionChildren.length; i++)
                item.subMenuProps?.items.push(this.processListActionEntry(actionChildren[i], promotedActions));
            return item;
        }
        else
        {
            let actionProperty: IActionEntryProps = entry.props;
            let subMenuItem: IContextualMenuItem = {} as IContextualMenuItem;

            subMenuItem.key = actionProperty.name;
            subMenuItem.text = actionProperty.label;
            if (actionProperty.runOnRec)
                subMenuItem.disabled = ! this.builderOptions.hasSelectedRecord();
            subMenuItem.iconProps = {
                iconName: actionProperty.iconName
            };

            if (actionProperty.onClick != undefined)
            {
                subMenuItem.onClick = () => {
                    actionProperty.onClick!(this.builderOptions.getSelectedRecord());
                }
            }
            else
            {
                subMenuItem.onClick = () => {
                    CommonFunctions.callAction(actionProperty.endpoint!, this.builderOptions.getSelectedRecord());
                }
            }

            if (actionProperty.isPromoted)
            {
                let actionItem: ICommandBarItemProps = {} as ICommandBarItemProps;
                actionItem.key = actionProperty.name + "Promoted";
                actionItem.text = actionProperty.label;
                if (actionProperty.runOnRec)
                    actionItem.disabled = ! this.builderOptions.hasSelectedRecord();
                actionItem.iconProps = {
                    iconName: actionProperty.iconName
                };

                if (actionProperty.onClick != undefined)
                {
                    actionItem.onClick = () => {
                        actionProperty.onClick!(this.builderOptions.getSelectedRecord());
                    };
                }
                else
                {
                    actionItem.onClick = () => {
                        CommonFunctions.callAction(actionProperty.endpoint!, this.builderOptions.getSelectedRecord());
                    };
                }

                promotedActions.push(actionItem);
            }

            return subMenuItem;
        }
    }

    private isPageStructureContainer(entry: any) : boolean {
        return "type" in entry.props;
    }

    private isActionContainer(entry: any) : boolean {
        return "type" in entry.props;
    }

    private isActionArea(entry: any) : boolean {
        return "category" in entry.props;
    }

    private getElementName(entry: any): string | undefined
    {
        if (React.isValidElement(entry) && typeof entry.type !== "string") 
            return entry.type.name;

        return undefined;
    }

}