import React, {useEffect, useRef, useState} from "react";
import { SystemCore } from "../../../../Core/System/SystemCore";
import { SystemActionCategory, SystemDataLoadingStatus, SystemPageType } from "../../../../Model/SystemModels";
import { Loader } from "../../../Common/Loader/Loader";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { SystemUser } from "../../../../Model/SystemModels";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { AdminAuthenticationEntryCard } from "../AdminAuthenticationEntryCard/AdminAuthenticationEntryCard";

export const AdminAuthenticationEntryList : React.FC = () => {
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [loaderMessage, setLoaderMessage] = useState("Caricamento pagina in corso ...");
    const [users, setUsers] = useState<SystemUser[]>([] as SystemUser[]);
    const clientId: String = HttpHelper.getParameter("clientId");
    const tenantId: String = HttpHelper.getParameter("tenantId");
    const adminService = new AdminService();
    const cardRef = useRef<PageContainerBase>(null);
    
    useEffect(() => {
        if(dataStatus === SystemDataLoadingStatus.ToLoad) {                        
            adminService.getUsers()
            .then((resp)=>{
                setUsers(resp);
                setDataStatus(SystemDataLoadingStatus.Loaded);
                setLoaderMessage("");
            })
            .catch((err) => {
                setDataStatus(SystemDataLoadingStatus.Loaded);
                setLoaderMessage("");
                ToastService.showMessage(MessageType.Error, "Errore nel caricamento degli utenti: "+ err);
            })
        }
    }, [dataStatus])
    
    const buildListEndpoint = () : string =>{
        let endpoint = "/odata/authentication/entry?$filter=clientId eq '"+clientId+"' and tenantId eq '"+tenantId+"' and userId gt 0";
        return endpoint;
    }
 
    if (!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={loaderMessage}></Loader>;
    else
        return (
            <>
                <PageContainer endpoint={buildListEndpoint()} pageType={SystemPageType.List} title={"Authentication entries"} cardRef={cardRef} backAllowed showAllowed deleteAllowed>
                    <PageDefinitionContainer type={ContainerType.Action}>
                        <ActionArea category={SystemActionCategory.Process}>
                            <ActionEntry name="crmChallenge" label="Challenge CRM" iconName="AuthenticatorApp" onClick={()=>{
                                adminService.crmUserChallenge()
                                .then((resp) => {
                                    console.log(resp);
                                    window.open(resp);
                                })
                                .catch((err) => {
                                    ToastService.showMessage(MessageType.Error, "Errore durante il challenge: " + err.message);
                                })
                            }}>
                            </ActionEntry>                            
                        </ActionArea>
                    </PageDefinitionContainer>
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry fieldName="tenantId" name="Tenant Id" minWidth={300} maxWidth={900} />
                        <ListHeaderEntry fieldName="clientId" name="Client Id" minWidth={300} maxWidth={900} />
                        <ListHeaderEntry fieldName="expiredOn" name="Scade il" minWidth={300} maxWidth={900} />
                        <ListHeaderEntry fieldName="userId" name="Id Utente" minWidth={100} maxWidth={900} />
                        <ListHeaderEntry fieldName="userId" name="Nome Utente" minWidth={300} maxWidth={900} onRender={(item: any, index, column)=>{
                            return users.find(user => user.id === item.userId)?.displayName;
                        }}/>
                        <ListHeaderEntry fieldName="userId" name="Email Utente" minWidth={300} maxWidth={900} onRender={(item: any, index, column)=>{
                            return users.find(user => user.id === item.userId)?.userPrincipleName;
                        }}/>
                    </PageDefinitionContainer>
                </PageContainer>
                <AdminAuthenticationEntryCard ref={cardRef} />
            </>
        );
}