import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminContractSetupCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title="Scheda Setup Contratti" endpoint="/odata/contract/setup" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="primaryKey" label="Chiave primaria" type={SystemFieldType.String} />
            </CardGroup>
            <CardGroup name="abletech" label="Abletech" isOpen={false}>
                <CardField name="ixFeClientId" label="Ix Fe Client Id" type={SystemFieldType.String} />
                <CardField name="ixFeClientSecret" label="Ix Fe Client Secret" type={SystemFieldType.String} />
                <CardField name="ixFeUserName" label="Ix Fe Username" type={SystemFieldType.String} />
                <CardField name="ixFePassword" label="Ix Fe Password" type={SystemFieldType.String} />
                <CardField name="ixFeUrlWebApiOAuth" label="Ix Fe Url Web Api OAuth" type={SystemFieldType.String} />
                <CardField name="ixFeUrlWebApiRetailer" label="Ix Fe Url Web Api Retailer" type={SystemFieldType.String} />
                <CardField name="ixFeItemDocumentNumber" label="Ix Fe Item Document Number" type={SystemFieldType.String} />
                <CardField name="ixFeItemCompanyNumber" label="Ix Fe Item Company Number" type={SystemFieldType.String} />
                <CardField name="abletechResellerIdentifier"  label="Abletech Reseller Identifier" type={SystemFieldType.String} />
                <CardField name="abletechDistributorIdentifier" label="Abletech Distributor Identifier"  type={SystemFieldType.String} />
            </CardGroup>
            <CardGroup name="namirial" label="Namirial" isOpen={false}>
                <CardField name="namirialUrl" label="Namirial Url" type={SystemFieldType.String} />
                <CardField name="namirialUserAccount" label="Namirial User Account" type={SystemFieldType.String} />
                <CardField name="namirialUserPassword" label="Namirial User Password" type={SystemFieldType.String} />
                <CardField name="namirialOrganizationKey" label="Namirial Organization Key" type={SystemFieldType.String} />
                <CardField name="namirialApiKey" label="Namirial API Key" type={SystemFieldType.String} />
            </CardGroup>
            <CardGroup name="notification" label="Notifiche" isOpen={false}>
                <CardField name="contractNotificationSetupType" type={SystemFieldType.Option} label="Tipo setup" cmbOptions={[
                    {key: 0, text: "Email"},
                    {key: 1, text: "Chat"}
                ]} />
                <CardField name="contractNotificationSetupCode" type={SystemFieldType.String} label="Codice setup"></CardField>
                <CardField name="contractNotificationTemplateCode" type={SystemFieldType.String} label="Codice template"></CardField>
                <CardField name="contractNotificationGroupCode" type={SystemFieldType.String} label="Codice gruppo"></CardField>
                <CardField name="adminAppId" label="App Admin" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="odata/system/app" tableRelationKey="id" tableRelationField="name" />
            </CardGroup>
        </PageContainer>
    );
});