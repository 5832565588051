import React, {useEffect, useRef, useState} from "react";
import { AdminService } from "../../../ApplicationCode/Admin/AdminService";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { SystemCore } from "../../../Core/System/SystemCore";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { CustomerDigitalContract } from "../../../Model/CustomerModels";
import { SystemActionCategory,SystemDataLoadingStatus,SystemPageType } from "../../../Model/SystemModels";
import { Loader } from "../../Common/Loader/Loader";
import { ActionArea } from "../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../Common/Template/PageDefinitionContainer";
import { AdminCustomerDigitalContractCard } from "../AdminCustomerDigitalContractCard/AdminCustomerDigitalContractCard";
import { BusinessCentralCompany } from "../../../Model/AdminModel";

export const AdminCustomerDigitalContractList : React.FC = () => {
    const [dataStatus,setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [loadMessage, setLoadMessage]= useState("Caricamento dati in corso...");
    const cardRef = useRef<PageContainerBase>(null);
    const adminService = new AdminService();
    const [bcCompanies, setBcCompanies] = useState<BusinessCentralCompany[]>([]);
    let listTitle:string = "";

    useEffect(() => {
        if (dataStatus === SystemDataLoadingStatus.ToLoad) {
            let customerId = HttpHelper.getParameter("customerId");
            
            if (customerId) {
                adminService.getBusinessCentralCompanies(Number(customerId))
            .then((resp) => {
                setBcCompanies(resp)
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setDataStatus(SystemDataLoadingStatus.Loaded)
            })
        }
        else
            setDataStatus(SystemDataLoadingStatus.Loaded)
        }
            
    }, [dataStatus])

    const buildListEndpoint = () : string => {
        let endpoint= "/odata/customer/digitalcontract";
        let contractFilter = HttpHelper.getParameter("contractFilter");
        let customerId = HttpHelper.getParameter("customerId");
        let companyName = HttpHelper.getParameter("name");

        switch(contractFilter){
            case "baseUnsigned":
                listTitle='Lista contratti base da firmare';
                endpoint += "?$filter=digitalContractCode eq 'SOSMART_DIRECT' and signed eq false";
                break;
            case "sendToNamirial":
                listTitle='Lista contratti FE da inviare a Namirial'
                endpoint += "?$filter=signLink eq \'\'";
                break;
            case "feUnsigned":
                listTitle='Lista contratti FE da firmare'
                endpoint += "?$filter=digitalContractCode eq 'IX-ADD' and signed eq false and signLink ne \'\'";
                break;
            case 'signed':
                listTitle='Lista contratti firmati'
                endpoint += "?$filter=signed eq true";
                break;
            default:
                listTitle = 'Lista contratti';

                if((customerId !== null) && (customerId !== "")){
                    listTitle = "Lista contratti per cliente: " + companyName;
                    endpoint += "?$filter=customerId eq "+customerId;
                }
                break;
        }

        return endpoint;
    }

    const reloadContractStatus = (contractId:string)=>{
        adminService.reloadContractStatus(contractId)
        .then((resp)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Success,"Contratto aggiornato con successo");
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Error,"Errore nell'aggiornamento del contratto: "+jqXHR.Message);
        })
    }

    const restartExpirationDate = (contractId:number)=>{
        adminService.restartExpirationDate(contractId)
        .then((resp)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Success,"Data scadenza estesa con successo");
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Error,"Errore nell'estensione della data scadenza: "+jqXHR.message);
        })
    }

    const downloadSigned = (contractId:number)=>{
        let instance = HttpHelper.buildAxiosInstance();
        let endpoint = "/api/customerDigitalContract/namirial/envelope/download/contract/"+contractId;
        var promise = new Promise((resolve,reject)=>{
            instance.get(endpoint, {responseType: 'blob'})
            .then((resp)=>{
                let extension: string = "";
                if (resp.data.type === "application/zip")
                    extension = ".zip";
                else if (resp.data.type === "application/pdf")
                    extension = ".pdf";

                const url = window.URL.createObjectURL(new Blob([resp.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', contractId.toString()+extension);
                document.body.appendChild(link);
                link.click();
                resolve(true);
            })
            .catch((resp)=>{
                console.log(resp.response.data.message);
                reject(resp.response.data.message);
            })
        })

        ToastService.showPromiseMessage(promise, "Avvio download contratto in corso", "Download contratto completato con successo", "Errore durante il download del contratto");
    }
    
    const updateExpirationDate = (contractId:number)=>{
        adminService.updateExpirationDate(contractId)
        .then((resp)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Success,"Data scadenza estesa con successo");
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Error,"Errore nell'estensione della data scadenza: "+jqXHR.message);
        })
    }

    const sendContractToArxivar = (contractId:string)=>{
        adminService.sendContractToArxivar(contractId).then((resp)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Success,"Controfirmato inviato correttamente a Arxivar.");
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Error,"Errore: "+jqXHR.message);
        })
    }

    const regenerateContract = (contract:CustomerDigitalContract) =>{
        adminService.regenerateContract(contract).then((resp)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Success,"Contratto rigenerato correttamente");
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Error,"Errore: "+jqXHR);
        })
    }

    const initializeFeSetup = (contract:CustomerDigitalContract) =>{
        adminService.initializeFeSetup(contract).then((resp)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Success,"Inizializzazione FE Setup completata correttamente");
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Error,"Errore: "+jqXHR.message);
        })
    }

    const manageContract = (contract:CustomerDigitalContract) => {
        adminService.manageContract(contract).then((resp) => {
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Success,"Elaborazione contratto completata correttamente");
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Error,"Errore: "+jqXHR.message);
        })
    }
    
    if (!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={loadMessage}></Loader>;
    else
        return (
            <>
                <PageContainer endpoint={buildListEndpoint()} pageType={SystemPageType.List} title={listTitle} cardRef={cardRef} backAllowed showAllowed>               
                    <PageDefinitionContainer type={ContainerType.Action}>
                        <ActionArea category={SystemActionCategory.Process}>
                            <ActionEntry name="regenerateContract" label="Rigenera Contratto" iconName="Documentation" runOnRec onClick={(req:any)=>{
                                setLoadMessage("Rigenerazione contratto in corso...");
                                setDataStatus(SystemDataLoadingStatus.Loading);
                                regenerateContract(req);
                            }}>
                            </ActionEntry>
                            <ActionEntry name="initializeFeSetup" label="Inizializza FE Setup" iconName="ReturnToSession" runOnRec onClick={(req:any)=>{
                                setLoadMessage("Inizializzazione FE Setup in corso...");
                                setDataStatus(SystemDataLoadingStatus.Loading);
                                initializeFeSetup(req);
                            }}>
                            </ActionEntry>
                            <ActionEntry name="manageContract" label="Elabora contratto" iconName="Send" runOnRec onClick={(req:any)=>{
                                setLoadMessage("Elaborazione contratto in corso...");
                                setDataStatus(SystemDataLoadingStatus.Loading);
                                manageContract(req);
                            }}>
                            </ActionEntry>
                            <ActionEntry name="reloadContractStatus" label="Aggiorna stato contratto" iconName="SyncOccurence" runOnRec isPromoted onClick={(req:any)=>{
                                    setLoadMessage("Aggiornamento stato contratto in corso...");
                                    setDataStatus(SystemDataLoadingStatus.Loading);
                                    reloadContractStatus(req.abletech_ContractId);
                                }}>
                            </ActionEntry>
                            <ActionEntry name="UploadArxivarContract" label="Carica Controfirmato" iconName="Upload" runOnRec onClick={(req:any)=>{
                                    setLoadMessage("Invio controfirmato in corso...");
                                    setDataStatus(SystemDataLoadingStatus.Loading);
                                    sendContractToArxivar(req.abletech_ContractId);
                            }}>
                            </ActionEntry>
                            <ActionEntry name="downloadSigned" label="Scarica contratto firmato" iconName="Download" runOnRec onClick={(req:any)=>{
                                    downloadSigned(req.id);
                                }}>
                            </ActionEntry>
                            <ActionEntry name="updateExpirationDate" label="Aggiorna data scadenza" iconName="DateTime2" runOnRec onClick={(req:any)=>{
                                    setLoadMessage("Aggiornamento data scadenza in corso...");
                                    setDataStatus(SystemDataLoadingStatus.Loading);
                                    updateExpirationDate(req.id);
                                }}>
                            </ActionEntry>
                            <ActionEntry name="restartExpirationDate" label="Estendi data scadenza" iconName="Refresh" runOnRec onClick={(req:any)=>{
                                    setLoadMessage("Estensione data scadenza in corso...");
                                    setDataStatus(SystemDataLoadingStatus.Loading);
                                    restartExpirationDate(req.id);
                                }}>
                            </ActionEntry>
                        </ActionArea>
                    </PageDefinitionContainer>   
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry name="Id Cliente" fieldName="customerId" minWidth={70} maxWidth={70} />
                        <ListHeaderEntry name="Società" fieldName="companyName" minWidth={120} maxWidth={150} onRender={(item: BusinessCentralCompany, index, column) => {
                            if (item.businessCentralCompanyId)
                                return bcCompanies.find(company => company.id === item.businessCentralCompanyId.toLowerCase())?.displayName;
                            else
                                return "";
                        }} />
                        <ListHeaderEntry name="Id Contratto digitale" fieldName="digitalContractCode" minWidth={130} maxWidth={130} />
                        <ListHeaderEntry name="Firmato" fieldName="signed" minWidth={50} maxWidth={50} />
                        <ListHeaderEntry name="Firmato da" fieldName="signedBy" minWidth={100} maxWidth={150} />
                        <ListHeaderEntry name="Firmato il" fieldName="signedAt" minWidth={90} maxWidth={150} />
                        <ListHeaderEntry name="Obbligatorio" fieldName="mandatory" minWidth={90} maxWidth={90} />
                        <ListHeaderEntry name="Admin richiesto" fieldName="adminRequired" minWidth={100} maxWidth={100} />
                        <ListHeaderEntry name="Link firma" fieldName="signLink" minWidth={70} maxWidth={70} />
                        <ListHeaderEntry name="Stato pratica" fieldName="practiceStatus" minWidth={90} maxWidth={90} onRender={(item: any, index, column) => {
                            if (item.practiceStatus === 10)
                                return "Nuovo"
                            else if (item.practiceStatus === 11)
                                return "Attesa firma namirial"
                            else if (item.practiceStatus === 12)
                                return "Firmato"
                            else if (item.practiceStatus === 13)
                                return "In attesa approvazione"
                            else if (item.practiceStatus === 14)
                                return "Approvato"
                            else if (item.practiceStatus === 15)
                                return "In Errore"
                            else if (item.practiceStatus === 16)
                                return "Attesa riscontro Abletech"
                            else if (item.practiceStatus === 17)
                                return "Riaperto"
                        }} />
                        <ListHeaderEntry name="Data scadenza" fieldName="expirationDate" minWidth={90} maxWidth={150} />
                        <ListHeaderEntry name="Errore" fieldName="exception_Description" minWidth={500} maxWidth={600} />
                        <ListHeaderEntry name="Id" fieldName="id" minWidth={30} maxWidth={30} />
                    </PageDefinitionContainer>
                </PageContainer>
                <AdminCustomerDigitalContractCard ref={cardRef} />
            </>
        );
}