import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminPostalCodeCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Codice postale"} endpoint="odata/PostalCode" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>                
                <CardField label="Codice" name="zipCode" type={SystemFieldType.String} />
                <CardField label="Città" name="city" type={SystemFieldType.String} />
                <CardField label="Provincia" name="county" type={SystemFieldType.String} />
                <CardField label="Cod. paese" name="countryCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="odata/CountryRegion" tableRelationKey="countryCode" tableRelationField="country" />
                <CardField label="Cod. territorio" name="territoryCode" type={SystemFieldType.String} />
                <CardField label="Cod. area" name="areaCode" type={SystemFieldType.String} />
                <CardField label="Cod. istat" name="istatCode" type={SystemFieldType.String} />
                <CardField label="Città principale" name="principalCity" type={SystemFieldType.Boolean} />
            </CardGroup>
        </PageContainer>
    );
});