import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminBusinessLineTagCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title="Scheda business line tag" endpoint="odata/business/line/tag" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="businessLineCode" label="Codice business line" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="odata/business/line" tableRelationKey="code" tableRelationField="description" readonly />
                <CardField name="tagName" label="Nome tag" type={SystemFieldType.String} />
                <CardField name="tagValue" label="Valore tag" type={SystemFieldType.String} />
            </CardGroup>
        </PageContainer>
    );
});