import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { SystemActionCategory, SystemOperation, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminNotificationGroupCard } from "../AdminNotificationGroupCard/AdminNotificationGroupCard";

export const AdminNotificationGroupList : React.FC = () => {
    const SetupCode = HttpHelper.getParameter("setupCode");
    const SetupType = HttpHelper.getParameter("setupType");
    const adminService = new AdminService();
    const navigate = useNavigate();
    const cardRef = useRef<PageContainerBase>(null);
    
    const createTeamsGroupFromSetup = (group:any) : void => {
        adminService.createTeamsGroupFromSetup(group).then((resp) => {
            ToastService.showMessage(MessageType.Success,"Gruppo Teams creato con successo");
        })
        .catch((jqXHR)=>{
            ToastService.showMessage(MessageType.Error,"Errore durante la creazione del gruppo teams: "+jqXHR.message);
        })
    }

    const setParentParameters = ()=>{
        cardRef.current?.setRecord({
            setupCode: SetupCode,
            setupType: SetupType
        });
        
        cardRef.current?.openPage(SystemOperation.Insert);
    }

    return (
        <>
            <PageContainer onNewRecord={setParentParameters} endpoint={'/odata/notification/group'} pageType={SystemPageType.List} title={"Gruppi notifiche"} cardRef={cardRef} backAllowed insertAllowed updateAllowed>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="OpenNotificationGroupMember" iconName="SecurityGroup" label="Membri" runOnRec onClick={(req:any)=>{
                            navigate("/app/admin/related/notificationGroupMember?setupType="+req.setupType+"&setupCode="+req.setupCode+"&groupCode="+req.code);
                        }}>
                        </ActionEntry>
                    </ActionArea>
                    <ActionArea category={SystemActionCategory.Process}>
                        <ActionEntry name="CreateGroup" iconName="AddGroup" label="Crea gruppo Teams" runOnRec isPromoted onClick={(req:any)=>{
                            createTeamsGroupFromSetup(req);
                        }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Codice" fieldName="code" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Nome" fieldName="name" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Reference Id" fieldName="referenceId" minWidth={100} maxWidth={200} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminNotificationGroupCard ref={cardRef} />
        </>
    );
}