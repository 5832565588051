import React, { useEffect } from "react";
import { Loader } from "../../Common/Loader/Loader"
import { SupportEntry } from "../../../ApplicationCode/Common/Chatbot/api";
import { useNavigate } from "react-router-dom";
import { HttpHelper } from "../../../Core/Http/HttpHelper";

export class CustomerSupportHelperModel {
    public pageName: string = "";
}

export const CustomerSupportHelper : React.FC = () => {
    
    const navigate = useNavigate();

    const createSupportEntry = (): Promise<SupportEntry> => {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/CRM/support/entry/create";
        let body = new CustomerSupportHelperModel();
        body.pageName = "";
        return new Promise((resolve, reject) => {
            instance.post(url, body)
            .then((resp) => {
                let supportEntry: SupportEntry = resp.data;
                resolve(supportEntry);
            })
            .catch((err) => {
                reject(err.response.data);
            })            
        });
    }
    
    useEffect(() => {
        createSupportEntry()
        .then((supportEntry) => {
            navigate("/chatbot?supportId=" + supportEntry.id);
        })
        .catch((err) => {
            navigate("/app/customer/home");
        })
    }, []);

    return <Loader text="Stiamo creando la richiesta di assistenza, attendere prego ..." />
}