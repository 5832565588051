import { IImageStyleProps, IImageStyles, IStackTokens, IStyleFunctionOrObject, mergeStyles, Stack, TooltipHost, Image, Spinner, SpinnerSize, Icon, IButtonStyles, DefaultButton, Shimmer } from "@fluentui/react";
import ReactHtmlParser from 'html-react-parser';
import { t } from "i18next";
import { useContext, useEffect, useRef, useState } from "react";
import { SystemCore } from "../../../Core/System/SystemCore";
import { AppActionType, AppIndicatorSeverity, ChecklistItem, CustomerUser, ECommerceCustomer, ECommerceUser, IndustyType, LeadIndustry, TrialData } from "../../../Model/DemoModels";
import { ActionType, PostalCode, SystemDataLoadingStatus, SystemOperation, SystemUser } from "../../../Model/SystemModels";
import { CancelButton, ContentDataBox, CueGroup, RoleCenterBookingModal, SubCueGroup, SuccessButton } from "../../Demo/DemoRoleCenter/DemoRoleCenter";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { ISessionStateContext, SessionStateContext } from "../../../Core/State/SessionStateContext";
import { Lead, LeadLiveMode } from "../../../Model/PartnerModels";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { CommonVideoModal } from "../CommonVideoModal/CommonVideoModal";
import { ContentManagement, IContentManagement } from "../../../ApplicationCode/Common/ContentManagement";
import { CommonFunctions } from "../../../ApplicationCode/Common/CommonFunctions";
import bcLogo from '../../../Assets/Images/BusinessCentral_scalable.svg';
import m365Logo from '../../../Assets/Images/Microsoft_365_(2022).svg';
import powerBILogo from '../../../Assets/Images/PowerBI_scalable.svg';
import powerAutomateLogo from '../../../Assets/Images/PowerAutomate_scalable.svg';
import { AdminService } from "../../../ApplicationCode/Admin/AdminService";
import { Loader } from "../Loader/Loader";
import { LeadModal } from "../../Demo/LeadModal/LeadModal";
import { CustomerTenantSetupBox } from "../../Customer/CustomerTenantSetupBox/CustomerTenantSetupBox";
import { CustomerTenantDetailBox } from "../../Customer/CustomerTenantDetailBox/CustomerTenantDetailBox";
import { CustomerSetupCompanyList } from "../../Customer/CustomerSetupCompanyList/CustomerSetupCompanyList";
import { CustomerUserSetupList } from "../../Customer/CustomerUserSetupList/CustomerUserSetupList";
import { CustomerMainUserSetupCard } from "../../Customer/CustomerMainUserSetupCard/CustomerMainUserSetupCard";
import { ApplicationForUserType, Customer, CustomerCompany, CustomerProgressChecklist, CustomerTrialStatus, TenantInformationSetup, UserInformationSetup } from "../../../Model/CustomerModels";
import { CustomerInformationContext } from "../../../ApplicationCode/Customer/CustomerInformationContext";
import { CustomerService } from "../../../ApplicationCode/Customer/CustomerService";
import { useNavigate } from "react-router-dom";
import { PageContainerBase } from "../Template/PageContainer";
import { CustomerBillingInfoCard } from "../../Customer/CustomerBillingInfoCard/CustomerBillingInfoCard";
import { AxiosInstance } from "axios";
import { googleAnalyticsManagement } from "../../../ApplicationCode/Common/GoogleAnalyticsManagement";
import { MigrationLostRiskLineDetail as MigrationLostRiskLineDetail, LeadQuoteDetails } from "../CommonLeadModal/CommonLeadModal";

//#region Component: PurchaseButton

export interface IPurchaseButtonProps {
    hidden: boolean;
    onClick: () => void;
}

export const PurchaseButton: React.FC<IPurchaseButtonProps> = (props) => {
    
    const wrapperClassName = mergeStyles({
        marginBottom: '10px',
        backgroundColor: 'rgb(255, 255, 255)',
        paddingBottom: '0.5em',
        paddingRight: '0.5em',
        paddingTop: '0.5em'
    });
    
    const buyButtonStyle: IButtonStyles = {
        root: {
            fontSize: 16,
            color: 'white !important',
            backgroundColor: '#E65E6D',
            borderRadius: 5,
            padding: 20,
            boxShadow: 'rgb(34 36 38 / 15%) 0px 1px 1px 1px',
            selectors: {
                "&:hover": { }
            },
        },
        rootHovered: {
            backgroundColor: '#E97768'
        }
    };
    
    const [buttonInterval, setButtonInterval] = useState<NodeJS.Timeout | undefined>(undefined);
    const [buttonClassName, setButtonClassName] = useState("");
    
    const createInterval = () => {
        return setTimeout(() => {
            if (buttonClassName === "")
                setButtonClassName("purchase-blink");
            else
                setButtonClassName("");
            
            setButtonInterval(undefined);
        }, 8000);
    }
    
    if (buttonInterval === undefined)
        setButtonInterval(createInterval());
    
    return (
        <Stack horizontal horizontalAlign="end" wrap className={wrapperClassName} key="headerMainContainer" id="headerContent" tokens={{childrenGap : 15}} hidden={props.hidden}>
            <DefaultButton styles={buyButtonStyle} text={t('customerCenter:DashboardCommandBar:purchase')!} className={buttonClassName} onClick={props.onClick} />
        </Stack>
    );
}

//#endregion

//#region Component: Banner

export interface IBannerProps {
    text: string;
    hasSubtext?: boolean;
    subText?: string;
}

export const Banner: React.FC<IBannerProps> = (props) => {

    const mainContainer = mergeStyles([{
        width: '100%',
        minHeight: '40vh',
        maxHeight: '40vh',
        backgroundColor: '#f2f2f2'
    }]);

    const leftContainer = mergeStyles([{
        width: '40%',
        maxHeight: '40vh'
    }]);

    const leftContentContainer = mergeStyles([{
        paddingTop: 28,
        width: '100%',
        height: '100%'      
    }]);

    const leftContentContainerTokens: IStackTokens = {
        childrenGap: 30
    }

    const leftMainText = mergeStyles([{
        fontSize: '1.7vw',
        paddingLeft: 45,
        fontWeight: 500,
        fontFamily: 'Nunito',
        color: 'black',
        width: '85%'  
    }]);

    const leftSubText = mergeStyles([{
        fontSize: '1vw',
        paddingLeft: 45,
        fontWeight: 300,
        fontFamily: 'Nunito',
        color: '#424241',
        width: '85%'  
    }]);

    const leftImage: IStyleFunctionOrObject<IImageStyleProps, IImageStyles> = {
        image: {
            width: '3vw',
            height: 'auto'
        }
    };

    const rightContainer = mergeStyles([{
        width: '60%',
        padding: 25
    }]);

    const rightContentContainer = mergeStyles([{
        backgroundColor: 'white',
        width: '100%',
        height: '100%',
        maxHeight: '40vh'
    }]);
    
    return (
        <Stack horizontal className={mainContainer} key="bannerMainContainer" id="bannerContent">
            <Stack.Item className={leftContainer}>
                <Stack className={leftContentContainer} tokens={leftContentContainerTokens} key={"leftContentContainer"}>
                    <Stack.Item className={leftMainText}>
                        {props.text}
                    </Stack.Item>
                    {
                        props.hasSubtext ?
                        <Stack.Item className={leftSubText} id="gtBannerContent">
                            {props.subText}
                        </Stack.Item>
                        :
                        <></>
                    }
                    <Stack verticalAlign="center" verticalFill styles={{root:{position:'relative'}}}>
                        <Stack horizontal horizontalAlign="space-around" styles={{root:{position:'absolute',bottom:'20px',left:'0px',width:'100%'}}}>
                            <TooltipHost
                                content={"Dynamics 365 Business Central"}
                                id={"tootlipBC"}
                                calloutProps={{ gapSpace: 0 }}
                                styles={{root:{display:'inline-block !important'}}}
                            >
                                <Image src={bcLogo} styles={leftImage} />
                            </TooltipHost>
                            <TooltipHost
                                content={"Microsoft 365"}
                                id={"tootlipM365"}
                                calloutProps={{ gapSpace: 0 }}
                                styles={{root:{display:'inline-block !important'}}}
                            >
                                <Image src={m365Logo} styles={leftImage} />
                            </TooltipHost>
                            <TooltipHost
                                content={"Microsoft Power BI"}
                                id={"tootlipPowerBI"}
                                calloutProps={{ gapSpace: 0 }}
                                styles={{root:{display:'inline-block !important'}}}
                            >
                                <Image src={powerBILogo} styles={leftImage} />
                            </TooltipHost>
                            <TooltipHost
                                content={"Microsoft Power Automate"}
                                id={"tootlipMPA"}
                                calloutProps={{ gapSpace: 0 }}
                                styles={{root:{display:'inline-block !important'}}}
                            >
                                <Image src={powerAutomateLogo} styles={leftImage} />
                            </TooltipHost>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack.Item>
            <Stack.Item className={rightContainer}>
                <Stack horizontal className={rightContentContainer} key={"rightContentContainer"}>
                    {props.children}
                </Stack>
            </Stack.Item>
        </Stack>
    );
}

//#endregion

//#region Component: Check List Item

export interface IChecklistProps {
    items: ChecklistItem[];
    currentItem: ChecklistItem;
    completed: boolean;
    onStart: (item: ChecklistItem) => void;
    onCancel: (item: ChecklistItem) => void;
}

export const Checklist: React.FC<IChecklistProps> = (props) => {

    const containerStyle = mergeStyles([{
        width: '100%',
        height: '100%',
        padding: 20
    }]);

    useEffect(() => {
        const itemElement = document.getElementById(props.currentItem.id);
        if(itemElement !== null)
            itemElement.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
    }, [props.currentItem])

    return (
        <Stack className={containerStyle}>
            <Stack key={"checklist"} className={containerStyle} horizontal id="inventoryApp">
                {
                    props.completed ?
                        <ChecklistCompleted />
                    :
                        <>
                            <CheckListContainer>
                                {
                                    props.items.map((item, index, array) => {
                                        return (
                                            <>
                                                <CheckListItem
                                                    id={item.id}
                                                    key={item.id}
                                                    title={item.shortTitle}
                                                    active={item.active}
                                                    completed={item.completed}
                                                    visible={item.visible}
                                                />
                                                {
                                                    index + 1 !== array.length &&
                                                    <CheckListSeparator
                                                        visible={item.visible}
                                                        active={item.active && item.completed} 
                                                    />
                                                }
                                            </>
                                        )
                                    })
                                }
                            </CheckListContainer>
                            <ChecklistContentContainer>
                                <ChecklistContentEntry item={props.currentItem} onStart={props.onStart} onCancel={props.onCancel}  />
                            </ChecklistContentContainer>
                        </>
                }
            </Stack>
        </Stack>
    );
}

//#endregion

//#region Component: Check List Container

export const ChecklistContentContainer: React.FC<{}> = (props) => {
    const containerStyle = mergeStyles([{
        paddingLeft: 40,
        width: '100%'
    }]);
    
    return (
        <Stack key={"checklistContentContainer"} className={containerStyle}>
            {props.children}
        </Stack>
    );
}

//#endregion

//#region Component: Check List Content Entry

export interface IChecklistContentEntryProps {
    item: ChecklistItem;
    onStart: (item: ChecklistItem) => void;
    onCancel: (item: ChecklistItem) => void;
}

export const ChecklistContentEntry: React.FC<IChecklistContentEntryProps> = (props) => {
    const titleStyle = mergeStyles([{
        color: 'black',
        fontFamily: 'Nunito',
        fontWeight: 600,
        fontSize: '1.1vw'
    }]);

    const descriptionStyle = mergeStyles([{
        color: '#424241',
        fontFamily: 'Nunito',
        fontSize: '0.9vw',
        fontWeight: 300
    }]);

    const tokens: IStackTokens = {
        childrenGap: 20
    }

    const containerStyle = mergeStyles([{
        overflowY: 'auto'
    }])
    
    return (
        <Stack className={containerStyle} verticalFill verticalAlign="space-between">
            <Stack hidden={!props.item.visible} tokens={tokens}>
                <Stack.Item className={titleStyle} hidden={!props.item.visible}>
                    {props.item.title}
                </Stack.Item>
                <Stack.Item className={descriptionStyle} hidden={!props.item.visible}>
                    {ReactHtmlParser(props.item.description)}
                </Stack.Item>
            </Stack>
            <Stack.Item hidden={!props.item.visible}>
                <Stack key={"buttonContentContainer"} horizontal>
                    <Stack.Item>
                        <SuccessButton hidden={!props.item.clickable} text={props.item.primaryButtonText} onClick={() => props.onStart(props.item)}/>
                    </Stack.Item>
                    <Stack.Item>
                        {props.item.mandatory ? <></> : <CancelButton text={props.item.defaultButtonText} onClick={() => props.onCancel(props.item)} />}
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    );
}

//#endregion

//#region Component: Check List Container

export const CheckListContainer : React.FC<{}> = (props) => {

    const containerStyle = mergeStyles([{
        paddingRight: '2vw',
        maxHeight: '40vh',
        overflowY: 'auto',
        width: 'auto',
        height: '100%',
        display: 'flex',
        justifyContent: 'start',
        selectors: {
            "::-webkit-scrollbar": {
                width: 7
            },
            "::-webkit-scrollbar-track": {
                backgroundColor: '#f1f1f1'
            },
            "::-webkit-scrollbar-thumb": {
                backgroundColor: "#888"
            },
            "::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#555"
            }
        }
    }]);

    return (
        <Stack.Item>
            <Stack key={"checkListContainer"}
                className={containerStyle}>
                {props.children}
            </Stack>
        </Stack.Item>
    );
}

//#endregion

//#region Component: Check List Item

export interface ICheckListItemProps {
    id: string;
    key: string;
    title: string;
    visible: boolean;
    active?: boolean;
    completed?: boolean;
}

export const CheckListItem : React.FC<ICheckListItemProps> = (props) => {    
    const circleStyle = mergeStyles([{
        backgroundColor: props.active ? (props.active? '#008089' : 'inherit') : 'inherit',
        border: props.active ? (props.completed? 'none' : '3px solid #008089') : '3px solid #AFAFAF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        borderRadius: 100,
        height: 20,
        minHeight: 20,
        width: 20,
        selectors: {
            "&:hover": {
                cursor: 'pointer'
            }
        }
    }]);

    const titleStyle = mergeStyles([{
        color: props.active ? '#015d63' : '#5E5F5F',
        fontFamily: 'Nunito',
        fontSize: '1.7vh',
        display: 'flex',
        fontWeight: props.active ? 500 : 300,
        alignItems: 'center',
        justifyContent: 'start',
        paddingLeft: 15
    }]);

    const completeStepTiitleStyle = mergeStyles([{
        color: '#015d63',
        fontFamily: 'Nunito',
        fontSize: '1.7vh',
        display: 'flex',
        fontWeight: 500,
        alignItems: 'center',
        justifyContent: 'start',
        paddingLeft: 15
    }]);
    
    return (
        <Stack.Item hidden={!props.visible}>
            <Stack id={props.id} key={"checkListItem" + props.key} horizontal>
                <Stack.Item>
                    <div className={circleStyle}>
                        {props.completed ? <Icon iconName="CheckMark" /> : <></>}
                    </div>
                </Stack.Item>
                <Stack.Item className={props.completed ? completeStepTiitleStyle : titleStyle}>
                    {props.title}
                </Stack.Item>
            </Stack>
        </Stack.Item>
    );
}

//#endregion

//#region Component: Check List Separator

export interface ICheckListSeparatorProps {
    active?: boolean;
    visible: boolean;
}

export const CheckListSeparator : React.FC<ICheckListSeparatorProps> = (props) => {

    const circleStyle = mergeStyles([{
        backgroundColor: props.active ? '#008089' : '#AFAFAF',
        height: 15,
        minHeight: 15,
        width: 4,
        marginTop: 2.5,
        marginBottom: 2.5
    }]);

    const containerStyle = mergeStyles([{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 20
    }]);
    
    return (
        <Stack.Item className={containerStyle} hidden={!props.visible}>
            <div className={circleStyle} />
        </Stack.Item>
    );
}

//#endregion

//#region Component: Check List Loader

export const ChecklistLoader: React.FC<{}> = (props) => {

    return (
        <div className="loader" style={
            {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                backgroundColor: '#f2f2f2'
            }
        }>
            <div>
                <Spinner 
                    label={"Loading..."}
                    size={SpinnerSize.large} 
                    styles={{
                        label: {
                            fontSize: 13
                        },
                        circle: {
                            width: 70,
                            height: 70,
                            borderWidth: 2.5,
                            marginBottom: 10
                        }
                    }}
                />
            </div>
        </div>
    );
}

//#endregion

//#region Component: Check List Completed

export const ChecklistCompleted: React.FC = () => {
    const titleStyle = mergeStyles([{
        color: 'black',
        fontFamily: 'Nunito',
        fontWeight: 600,
        fontSize: '1.1vw'
    }]);

    const descriptionStyle = mergeStyles([{
        color: '#424241',
        fontFamily: 'Nunito',
        fontSize: '1vw',
        fontWeight: 300
    }]);

    const containerStyle = mergeStyles([{
        width: '100%',
        borderLeft: '3px solid #008089',
        paddingLeft: 20
    }]);

    return(
        <Stack className={containerStyle}>
            <Stack.Item className={titleStyle}>
                {t('leadDashboard:checklist:contentEntryTitle')!}
            </Stack.Item>
            <Stack.Item className={descriptionStyle}>
                {t('leadDashboard:checklist:contentEntryText')!}
            </Stack.Item>
        </Stack>
    )
}

//#endregion

//#region Component Lead Dashboard Link

export enum DashboardLinkType {
    ExternalLink,
    InternalLink,
    OpenPage,
    MailTo
}


export class DashboardLink {
    public code: string = "";
    public description: string = "";
    public translatedDescription: string = "";
    public toolTipText: string = "";
    public translatedToolTipText: string = "";
    public order: number = 0;
    public active: boolean = false;
    public forUserType: ApplicationForUserType = ApplicationForUserType.AdminUser;
    public groupCode: string = "";
    public groupDescription: string = "";
    public translatedGroupDescription: string = "";
    public iconName: string = "";
    public linkType: DashboardLinkType = DashboardLinkType.ExternalLink;
    public linkUrl: string = "";
    public forEntity: string = "";
}

export interface ILeadDashboardLinkProps {
    links: DashboardLink[],
    onClick: (link: DashboardLink) =>  void
}

export const LeadDashboardLink: React.FC<ILeadDashboardLinkProps> = (props) => {
    const [group, setGroup] = useState<JSX.Element[]>([]);
    const [linkDataStatus, setLinkDataStatus] = useState<SystemDataLoadingStatus>(SystemDataLoadingStatus.ToLoad);
    
    const wrapperClassName = mergeStyles({
        width: '100%',
        height: 'auto',
        marginTop: '10px',
        paddingBottom: '0.5em',
        paddingTop: '1em',
    });

    useEffect(() => {
        setLinkDataStatus(SystemDataLoadingStatus.Loading);

        let groupElements: JSX.Element[] = [];
        let subGroupElements: JSX.Element[] = [];
        let linkElements: JSX.Element[] = [];
        let groupDescription: string = "";
        let distinctGroupCode: string[] = props.links.map(link => link.groupCode).filter((link: string, index: number, array:string[]) => array.indexOf(link) === index);

        distinctGroupCode.forEach((groupCode: string, index: number, array: string[]) => {
            linkElements = [];

            let linkByGroup: DashboardLink[] = props.links.filter(link => link.groupCode == groupCode);
            linkByGroup.forEach((link: DashboardLink, index: number, array: DashboardLink[]) => {
                groupDescription = link.translatedGroupDescription;
                linkElements.push(
                    <ContentDataBox 
                        name={link.code} 
                        title={link.translatedDescription}
                        iconName={link.iconName}
                        value={""}
                        link={link.linkUrl}
                        id={link.code}
                        extendedTitle={link.translatedToolTipText}
                        isIconBox
                        actionType={AppActionType.Custom}
                        severity={AppIndicatorSeverity.Ambiguous}
                        onClick={()=>{
                            props.onClick(link);
                        }}
                    />
                )
            });

            subGroupElements.push(
                <SubCueGroup
                    id={groupCode}
                    title={groupDescription}
                    key={groupCode}
                    onClick={() => {}}
                >
                    {linkElements}
                </SubCueGroup>
            )
        });

        groupElements.push(
            <CueGroup
                id="DASHBOARDLINKGROUP"
                name="Dashboard Link"
                title={t("leadDashboard:dashboardLink:title")!}
                extendedTitle={t("leadDashboard:dashboardLink:extendedTitle")!}
                key={"DASHBOARDLINKGROUP"}
                onClick={() => {}}
            >
                {subGroupElements}
            </CueGroup>
        );

        setGroup(groupElements);
        setLinkDataStatus(SystemDataLoadingStatus.Loaded);
    },[props.links])

    if(!SystemCore.isFinishedDataStatus(linkDataStatus))
        return(
            <>
                <Shimmer width="100%" />
                <Shimmer width="100%" />
                <Shimmer width="100%" />
                <Shimmer width="100%" />
                <Shimmer width="100%" />
                <Shimmer width="100%" />
                <Shimmer width="100%" />
                <Shimmer width="100%" />
                <Shimmer width="100%" />
                <Shimmer width="100%" />
                <Shimmer width="100%" />
            </>
        )

    return(
        <Stack horizontal horizontalAlign="space-between" wrap className={wrapperClassName} key="headerMainContainer" id="headerContent" tokens={{childrenGap : 15}}>
            <Stack.Item>
                {group}
            </Stack.Item>
        </Stack>
    );
}

//#endregion

const wrapperClassName = mergeStyles({
    width: '98vw',
    height: '100vh',
    margin: '0 auto',
    marginTop: '10px',
    backgroundColor: 'rgb(255, 255, 255)',
    paddingLeft: '1em',
    paddingBottom: '1em',
    paddingRight: '1em',
    paddingTop: '0.5em',
    boxShadow: 'rgb(34 36 38 / 15%) 0px 1px 1px 1px',
});

export class CrmLostRiskLine {
    public entryNo: number = 0;
    public sourceTable: string = "";
    public sourceId: number = 0;
    public sourceExternalId: number = 0;
    public lossCauseCode: string = "";
    public lossCauseDescription: string = "";
    public processModule: string = "";
    public processCode: string = "";
    public description: string = "";
    public notes: string = "";
    public lineScore: number = 0;
}

export class LeadToLive {
    public lead: Lead = new Lead();
    public leadToLiveChecklists: LeadToLiveChecklist[] = [];
}

export class LeadToLiveChecklist {
    public wizardCode: string = "";
    public wizardLine: number = 0;
    public answerCode: string = "";
    public title: string = "";
    public subtitle: string = "";
    public alternativeTitle: string = "";
    public visible: boolean = false;
    public completed: boolean = false;
    public clickable: boolean = false;
    public order: number = 0;
    public answerEntryNo: number = 0;
    public answerDescription: string = "";
    public customerProgressChecklist: CustomerProgressChecklist[] = [];
}

export class LeadChecklistItem {
    public lead: Lead = new Lead();
    public checklist: ChecklistItem[] = [];
}

export class SaveLeadConfigChecklist {
    public leadToLiveChecklist: LeadToLiveChecklist = new LeadToLiveChecklist();
    public lead: Lead = new Lead();
    public details: LeadConfigChecklistDetails = new LeadConfigChecklistDetails();
}

export class LeadConfigChecklistDetails {
    public businessNeedsRiskLines: CrmLostRiskLine[] = [];
    public businessNeedsNotesRiskLines: CrmLostRiskLine[] = [];
    public gapRiskLines: CrmLostRiskLine[] = [];
    public migrationRiskLines: MigrationLostRiskLineDetail[] = [];
    public leadIndustries: LeadIndustry[] = [];
    public leadQuoteDetails: LeadQuoteDetails[] = [];
}

export enum LeadToLiveConfigurationMode {
    None,
    ManageLead,
    CreateCustomer
}

export const LeadDashboard: React.FC = () => {
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [checklistItems, setChecklistItems] = useState<ChecklistItem[]>([]);
    const [dashboardLinks, setDashboardLinks] = useState<DashboardLink[]>([]);
    const [currentChecklistItem, setCurrentChecklistItem] = useState<ChecklistItem>(new ChecklistItem());
    const [trialData,setTrialData] = useState<TrialData>({} as TrialData);
    const [taskUpdateStatus, setTaskUpdateStatus] = useState<SystemDataLoadingStatus>(SystemDataLoadingStatus.ToLoad);
    const sessionInfoContext = useContext<ISessionStateContext>(SessionStateContext);
    const [videoResource, setVideoResource] = useState<IContentManagement>();
    const [disableVideoLight, setDisableVideoLight] = useState<boolean>(false);
    const [showVideoModal, setShowVideoModal] = useState<boolean>(false);
    
    const [showLeadModal, setShowLeadModal] = useState<boolean>(false);
    const [showTenantInfo, setShowTenantInfo] = useState(false);
    const [showTenantDetailInfo, setShowTenantDetailInfo] = useState(false);
    const [showCompanySetup, setShowCompanySetup] = useState(false);
    const [showUserSetup, setShowUserSetup] = useState(false);
    const [showMainUserSetup, setShowMainUserSetup] = useState(false);
    const [tenantInformation, setTenantInformation] = useState(new TenantInformationSetup());
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [industryTypes, setIndustryTypes] = useState<IndustyType[]>([]);
    const [leadConfigDetails, setLeadConfigDetails] = useState<LeadConfigChecklistDetails>(new LeadConfigChecklistDetails());
    const [lead, setLead] = useState<Lead>(new Lead());
    const [leadDataSaveInProgress, setLeadDataSaveInProgress] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState<Object[]>([]);
    const [showBookingModal, setShowBookingModal] = useState<boolean>(false);
    const [configurationMode, setConfigurationMode] = useState<LeadToLiveConfigurationMode>(LeadToLiveConfigurationMode.None);
    const [checklistComplete, setChecklistComplete] = useState<boolean>(false);
    const [leadModalCanScroll, setLeadModalCanScroll] = useState<boolean>(false);
    const [shopLink, setShopLink] = useState<string>("");
    const [purchaseClicked, setPurchasedClicked] = useState<boolean>(false);
    const customerInfoContext = useContext(CustomerInformationContext);
    const billingCardRef = useRef<PageContainerBase>(null);
    const customerService = new CustomerService();
    const adminService = new AdminService();
    const navigate = useNavigate();
    const analytics = new googleAnalyticsManagement();

    const getDashboardData = (): Promise<ChecklistItem[]> =>{
        return new Promise((resolve, rejects) => {
            getLeadToLiveChecklist().then((leadChecklistItem) => {
                setLead(leadChecklistItem.lead);
                setChecklistItems(leadChecklistItem.checklist);
                getDashboardLink().then((dashboardLinks: DashboardLink[]) => {
                    setDashboardLinks(dashboardLinks);

                    adminService.getLeadConfigDetails(leadChecklistItem.lead).then((resp) => {
                        setLeadConfigDetails(resp);
                    
                        adminService.getIndustryTypes().then((resp) => {
                            setIndustryTypes(resp);
                            resolve(leadChecklistItem.checklist);
                        })
                        .catch(err => {
                            rejects(err);
                        });
                    })
                    .catch((err) => {
                        rejects(err);
                    });
                });
            })
            .catch((err) => {
                rejects(err.data.message);
            })
        });
    }

    //#region Common

    const getDashboardLink = () : Promise<DashboardLink[]> => {
        return new Promise((resolve, reject) => {
            let client: AxiosInstance = HttpHelper.buildAxiosInstance();
            client.get("/api/system/dashboard/link").then((resp) => {
                resolve(resp.data);
            })
            .catch((err) => {
                reject(err.data.message);
            });
        })
    }

    const getSubscriptionKPIText = () : boolean => {
        if (customerInfoContext.customer == undefined)
            return false;

        if (customerInfoContext.customer.isTrial === CustomerTrialStatus.On)
            return true;
        return false;
    }

    const handleDashboardLinkClick = (link: DashboardLink) => {
        
        CommonFunctions.logSystemEvent("Dashboard link", "User click dashboard link item "+link.code+" - "+link.translatedDescription, 0, sessionInfoContext.currentUser.userId, "ACCESS", "CLICK", link.code);

        switch(link.linkType)
        {
            case DashboardLinkType.ExternalLink:
                if (link.code === 'SHOP')
                    handleRedirectToShop();
                else
                    window.open(link.linkUrl, '_blank');
                break;
            case DashboardLinkType.InternalLink:
                navigate(link.linkUrl);
                break;
            case DashboardLinkType.OpenPage:
                switch(link.linkUrl)
                {
                    case 'bookingAvailability':
                        setShowBookingModal(true);
                        break;
                    case 'invoicingData':
                        customerInfoContext.reloadCustomer(customerInfoContext.customer.id).then((customer) => {
                            billingCardRef.current!.setRecord(customer);
                            billingCardRef.current!.openPage(SystemOperation.Update);
                        });
                        break;
                }
                break;
            case DashboardLinkType.MailTo:
                window.open(link.linkUrl);
        }
    }

    const isPurchaseButtonHidden = (): boolean => {
        return (sessionInfoContext.currentUser.mainUser && getSubscriptionKPIText());
    }

    const handleShortcut = () => {
        const bookShortCut = HttpHelper.getParameter('shortCutBook');
        const buyShortCut = HttpHelper.getParameter('shortCutBuy');
        const trialShortCut = HttpHelper.getParameter('shortCutTrial');
        
        const trialExist: boolean = (trialShortCut !== null) && (trialShortCut === "true");
        const buyExist: boolean = (buyShortCut !== null) && (buyShortCut === "true");
        const bookExists: boolean = (bookShortCut !== null) && (bookShortCut === "true");
        if(!buyExist && !trialExist && !bookExists)
            return;
        
        if(trialExist){
            //TODO: Gestito per ora solo il redirec capire come integrare la vecchia gesrtione.
        }

        if(buyExist){
            const isLead: boolean = customerInfoContext.customer === undefined;
            if (isLead){
                if(sessionInfoContext.currentUser.mainUser)
                    window.open(lead.offerLink,'_self');
                else
                    ToastService.showMessage(MessageType.Error,t('customerCenter:Err00002Text'));  
            }
            else{
                if (sessionInfoContext.currentUser.mainUser)
                    handleRedirectToShop();
                else
                    ToastService.showMessage(MessageType.Error,t('customerCenter:Err00002Text'));   
            }
        }

        if (bookExists) {
            setShowBookingModal(true);   
        }
        
        CommonFunctions.deleteShortCutParameters(navigate);
    }

    //#endregion

    //#region Task management

    const getCurrentChecklistItem = (items: ChecklistItem[]): ChecklistItem => {
        let currentEntry: ChecklistItem | undefined = undefined;
        items.forEach((item, index, array) => {
            if(!item.completed){
                if(item.visible)
                    item.active= false;
                else{
                    item.completed = true;
                    item.active = false;
                }
            }
            else{
                item.active = true;
            }
        })

        let currentElementIndex = items.findIndex((item) => !item.completed && item.visible);
        if (currentElementIndex == -1){
            setChecklistComplete(true);

            return new ChecklistItem();
        }

        currentEntry = items[currentElementIndex];
        if(currentEntry !== undefined){
            currentEntry.active = true;
            setCurrentChecklistItem(currentEntry);

            return currentEntry;
        }
        else{
            items[0].active = true;
            setCurrentChecklistItem(items[0]);

            return items[0];
        }

    }

    const onCompleteTask = (): Promise<ChecklistItem[]> => {

        return new Promise((resolve, rejects) => {
            let client = HttpHelper.buildAxiosInstance();

            client.post("/api/system/lead/checklist/update", currentChecklistItem.leadTask).then(()=> {
                getDashboardData().then((checklist)=>{
                    resolve(checklist);
                })
                .catch((err) => {
                    rejects(err.data.message);
                })
            })
            .catch((err) => {
                rejects(err.data.message);
            })
        });
    };

    const startTask = (task: ChecklistItem) => {

        CommonFunctions.logSystemEvent("Lead checklist", "User click checklist item "+task.id+" - "+task.title, 0, sessionInfoContext.currentUser.userId, "ACCESS", "CLICK", task.id);
        
        switch(task.id){
            case 'SMRT_INTRO':
                if (lead.legalEntityCode === 'ESS') {
                    openVideoModal(
                        "https://sosmartcontainer.blob.core.windows.net/sosmart-docs/en-US/video/How%20it%20works%20-%20intro%20video%20CP.mp4",
                        t('demoRoleCenter:defaultVideoDescription'),
                        true
                    );
                } else {
                    openVideoModal(
                        "https://sosmartcontainer.blob.core.windows.net/sosmart-docs/it-IT/video/Come%20funziona%20-%20Welcome.mp4",
                        t('demoRoleCenter:defaultVideoDescription'),
                        true
                    );
                }
                task.leadTask!.answerDescription = "Video viewed";
                break;
            case 'SMRT_CUST':
                setConfigurationMode(LeadToLiveConfigurationMode.CreateCustomer);
                generateTrialData();
                setShowLeadModal(true);
                task.leadTask!.answerDescription = "Lead configuration modal completed";
                break;
            case 'SMRT_BOOK':
                setShowBookingModal(true);
                break;
            // case 'SMRT_GOALS':
            //     setLeadModalCanScroll(true);
            //     setConfigurationMode(LeadToLiveConfigurationMode.CreateCustomer);
            //     generateTrialData();
            //     tryOpenTrialModal();
            //     task.leadTask!.answerDescription = "Lead goals modal completed";
            //     break;
            case 'SMRT_TRIAL':
                if(customerInfoContext.setupConfig === undefined)
                    customerInfoContext.reloadSetupConfig();
                if(customerInfoContext.defaultDomain === undefined)
                    customerInfoContext.loadDefaultDomain();

                let currentChecklistStep: CustomerProgressChecklist = getCurrentTask(checklistItems);
                handleNextClick(currentChecklistStep);
                task.leadTask!.answerDescription = "Lead goals modal completed";
                break;
            case 'SMRT_BUY':
                handleRedirectToShop();
                task.leadTask!.answerDescription = "shop link generated";
                break;
            case 'SMRT_CONTRACT':
            case 'SMRT_FE_SETUP':
            case 'SMRT_FE_SIGN':
                handleContractPageEvent(false);
                let smrtContractStep: CustomerProgressChecklist = getCurrentTask(checklistItems);
                window.open(smrtContractStep.externalLink, '_blank');
                task.leadTask!.answerDescription = "Contract step completed";
                break;
            case 'SMRT_STRATEGY':
            case 'SMRT_MIGRATION':
            case 'SMRT_TRAINING':
            case 'SMRT_CHECK_LIVE':
                task.leadTask!.answerDescription = "Task completed";
                completeTask();
                break;
        }
        
    }

    const completeTask = () => {
        setTaskUpdateStatus(SystemDataLoadingStatus.Loading);
        onCompleteTask().then((resp) => {
            setTaskUpdateStatus(SystemDataLoadingStatus.Loaded);
        })
        .catch((err) => {
            setTaskUpdateStatus(SystemDataLoadingStatus.Error);
        })
    }

    const getLeadToLiveChecklist = (): Promise<LeadChecklistItem> => {
        return new Promise((resolve, reject) => {
            let client = HttpHelper.buildAxiosInstance();
    
            client.get("/api/system/user/checklist/status").then(resp => {
                let leadToLive: LeadToLive = resp.data;
                let taskList: ChecklistItem[] = [];
                leadToLive.leadToLiveChecklists.forEach(item => {
                    let task: ChecklistItem = new ChecklistItem();
                    task.clickable = item.clickable;
                    task.mandatory = true;
                    task.description = item.subtitle;
                    task.title = item.alternativeTitle === null || item.alternativeTitle === "" ? item.title : item.alternativeTitle;
                    task.duration = 10;
                    task.id = item.answerCode;
                    task.shortTitle = item.title;
                    task.defaultButtonText = t('leadDashboard:checklist:nextButtonText')!;
                    task.primaryButtonText = t('leadDashboard:checklist:nextButtonText')!;
                    task.completed = item.completed;
                    task.visible = item.visible;
                    task.leadTask = item;
                    taskList.push(task);
                })
                
                getCurrentChecklistItem(taskList);
                let leadChecklistItem: LeadChecklistItem = {
                    lead: leadToLive.lead,
                    checklist: taskList
                }

                resolve(leadChecklistItem);
            })
            .catch(err => {;
                reject(err.data.message);
            });

        })
    }

    //#endregion
    
    //#region Intro step

    const openVideoModal = (content:string,description:string,disableLight:boolean) => {
        setDisableVideoLight(false);
        
        ContentManagement.generateResource(btoa(content),description).then((resp: IContentManagement)=>{

            if(disableLight)
                setDisableVideoLight(true);

            setVideoResource(resp);
            
            setShowVideoModal(true);
        })
    }

    const autoStartIntroStep = () => {
        if(currentChecklistItem.id == 'SMRT_INTRO'){
            startTask(currentChecklistItem);
        }
    }

    //#endregion

    //#region Lead modal step

    const checkLeadModalData = (configuration: SaveLeadConfigChecklist): Object[] => {
        let validationErrors : object[] = [];

        if (! configuration.lead.firstname)
            validationErrors.push({
                reference: "firstname",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00001Text')!,
                applyToField: true
            });
        if (! configuration.lead.lastname)
            validationErrors.push({
                reference: "lastname",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00002Text')!,
                applyToField: true
            });
        if (! configuration.lead.companyName)
            validationErrors.push({
                reference: "companyName",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00003Text')!,
                applyToField: true
            });

        if (! configuration.lead.address)
            validationErrors.push({
                reference: "address",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00004Text')!,
                applyToField: true
            });
        if (! configuration.lead.zipCode)
            validationErrors.push({
                reference: "zipCode",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00004Text')!,
                applyToField: true
            });
        if (! configuration.lead.city)
            validationErrors.push({
                reference: "city",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00004Text')!,
                applyToField: true
            });
        if (! configuration.lead.county)
            validationErrors.push({
                reference: "county",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00004Text')!,
                applyToField: true
            });
        if (! configuration.lead.country)
            validationErrors.push({
                reference: "country",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00004Text')!,
                applyToField: true
            });

        if (! configuration.lead.email)
            validationErrors.push({
                reference: "email",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00004Text')!,
                applyToField: true
            });
        
        if (configuration.lead.full_Users_No == 0)
            validationErrors.push({
                reference: "fullUsersNo",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00004Text')!,
                applyToField: true
            });

        if (configuration.details.businessNeedsRiskLines.findIndex((element) => element.description === "") >= 0)
            validationErrors.push({
                reference: "LostRisk1",
                error: t('leadDashboard:errors:text00001Err')!,
                applyToField: true
            });

        if (configuration.details.leadQuoteDetails.findIndex((element) => element.selected === true) < 0)
            validationErrors.push({
                reference: "quoteList",
                error: t('common:qualification:Text0063Lbl'),
                applyToField: true
            });

        if (configuration.lead.liveMode == LeadLiveMode.None)
            validationErrors.push({
                reference: "leadLiveMode",
                error: t('common:qualification:Text0064Lbl'),
                applyToField: true
            });

        if (configuration.lead.evaluationMode === 0)
            validationErrors.push({
                reference: "evaluationMode",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00007Text')!,
                applyToField: true,
            });
        
        if (configuration.lead.legalEntityCode === 'EOL' && !configuration.lead.vat)
            validationErrors.push({
                reference: "vat",
                error: t('demoCenter:TrialModal:Dialog:FieldError:Err00008Text')!,
                applyToField: true,
            });

        setValidationErrors(validationErrors);
        return validationErrors;
    }

    const generateTrialData = () => {
        let ecommerceCustomer: ECommerceCustomer = new ECommerceCustomer();
        let ecommerceUser: ECommerceUser = new ECommerceUser();
        let customerUser: CustomerUser = new CustomerUser();

        customerUser.customer = ecommerceCustomer;
        customerUser.user = ecommerceUser;
        customerUser.user.firstName = sessionInfoContext.currentUser.firstName;
        customerUser.user.lastName = sessionInfoContext.currentUser.lastName;
        customerUser.user.privateEmail = sessionInfoContext.currentUser.email;
        customerUser.user.leadId = sessionInfoContext.currentUser.referenceId;

        setTrialData({
            confirmPassword: "",
            currentLead: lead,
            customerUser: customerUser  
        });
    }

    const onSaveLeadModal = (configuration: SaveLeadConfigChecklist): boolean => {
        if (checkLeadModalData(configuration).length > 0)
            return false;
   
        setLeadDataSaveInProgress(true);
        let client = HttpHelper.buildAxiosInstance();

        switch(configurationMode){
            case LeadToLiveConfigurationMode.ManageLead:
                client.post('/api/system/lead/checklist/config/save', configuration).then(() => {
                    completeTask();
                    setShowLeadModal(false);
                    setLeadDataSaveInProgress(false);
                })
                .catch(() => {
                    ToastService.showMessage(MessageType.Error, t('leadDashboard:errors:text00002Err')!);
                    setLeadDataSaveInProgress(false);
                });
                break;
            case LeadToLiveConfigurationMode.CreateCustomer:
                client.post('/api/system/lead/checklist/config/save', configuration).then(() => {
                    client.post('api/ecommerce/customerinfo', trialData.customerUser).then(() => {
                        setTaskUpdateStatus(SystemDataLoadingStatus.Loading);
                        onCompleteTask().then((resp) => {
                            analytics.sendEvent('attiva_trial', {});
                            window.location.reload();
                        })
                        .catch((err) => {
                            setTaskUpdateStatus(SystemDataLoadingStatus.Error);
                            setShowLeadModal(false);
                            setLeadDataSaveInProgress(false);
                        })
                    })
                    .catch(() => {
                        ToastService.showMessage(MessageType.Error, t('leadDashboard:errors:text00002Err')!);
                        setLeadDataSaveInProgress(false);
                    });
                })
                .catch(() => {
                    ToastService.showMessage(MessageType.Error, t('leadDashboard:errors:text00002Err')!);
                    setLeadDataSaveInProgress(false);
                });
                break;
            default:
                console.error(t('leadDashboard:errors:text00003Err')!);
        }

        return true;
    }

    //#endregion

    //#region Customer onboarding stepper

    const getCurrentTask = (items: ChecklistItem[]) : CustomerProgressChecklist => {
        let notCompletedTask: CustomerProgressChecklist = getCurrentChecklistItem(items).leadTask?.customerProgressChecklist.find(task => ! task.completed)!;

        return notCompletedTask;
    }

    const handleNextClick = (currentTask: CustomerProgressChecklist)=>{

        switch(currentTask.code)
        {
            case "TENANT_INFO":
                setShowTenantInfo(true);
                break;
            case "TENANT_AUTH":
                setShowTenantDetailInfo(true);
                break;
            case "COMPANY_SETUP":
                setShowCompanySetup(true);
                break;
            case "MAIN_USER_SETUP":
                setShowMainUserSetup(true);
                break;
            case "USER_SETUP":
                setShowUserSetup(true);
                break;
            default:
                if(!currentTask.enabled)
                    return;

                switch(currentTask.actionType)
                {
                    case ActionType.OpenPage:
                        navigate(currentTask.pageEndpoint);
                        break;
                    case ActionType.OpenLink:
                        window.open(currentTask.externalLink,'_self');
                        break;
                }

                break;
        }
    }

    const handleTenantStepSave = () => {
        setSaveInProgress(true);
        customerService.completeSetupStep(customerInfoContext.setupConfig.customerId, 1).then((resp) => {
            setTaskUpdateStatus(SystemDataLoadingStatus.Loading);
            onCompleteTask().then((newChecklist) => {
                setShowTenantInfo(false);
                setSaveInProgress(false);
                setTaskUpdateStatus(SystemDataLoadingStatus.Loaded);

                handleNextClick(getCurrentTask(newChecklist));
            })
            .catch((err) => {
                setSaveInProgress(false);
                setTaskUpdateStatus(SystemDataLoadingStatus.Error);
            })
        })
        .catch((err) => {
            ToastService.showMessage(MessageType.Error, err);
            setSaveInProgress(false);
        })
    };

    const handleTenantDetailStepSave = (tenantInfo: TenantInformationSetup) => {    
        setSaveInProgress(true);
        let currentData: TenantInformationSetup = JSON.parse(JSON.stringify(tenantInfo));
        currentData.tenantId = customerInfoContext.setupConfig.tenantId;

        if (! customerInfoContext.setupConfig.hasTenant)
            currentData.domain += ".onmicrosoft.com";

        customerService.saveSetupStepData(customerInfoContext.setupConfig.customerId, 2, currentData).then((resp) => {
            customerService.completeSetupStep(customerInfoContext.setupConfig.customerId, 2).then((resp) => {
                customerInfoContext.loadDefaultDomain();
                setTaskUpdateStatus(SystemDataLoadingStatus.Loading);
                onCompleteTask().then((newChecklist) => {
                    setShowTenantDetailInfo(false);
                    setSaveInProgress(false);
                    setTaskUpdateStatus(SystemDataLoadingStatus.Loaded);

                    handleNextClick(getCurrentTask(newChecklist));
                })
                .catch((err) => {
                    setSaveInProgress(false);
                    setTaskUpdateStatus(SystemDataLoadingStatus.Error);
                })
            })
            .catch((err) => {
                ToastService.showMessage(MessageType.Error, err.message);
                setSaveInProgress(false);
            })
        })
        .catch((err) => {
            ToastService.showMessage(MessageType.Error, err.errorMessage);
            setSaveInProgress(false);
        });
    };

    const handleCompanySetupStepSave = (companies: CustomerCompany[]) => {        
        setSaveInProgress(true);

        customerService.saveSetupStepData(customerInfoContext.setupConfig.customerId, 3, companies).then((resp) => {
            customerService.completeSetupStep(customerInfoContext.setupConfig.customerId, 3).then((resp) => {
                setTaskUpdateStatus(SystemDataLoadingStatus.Loading);
                onCompleteTask().then((newChecklist) => {
                    setShowCompanySetup(false);
                    setSaveInProgress(false);
                    setTaskUpdateStatus(SystemDataLoadingStatus.Loaded);

                    handleNextClick(getCurrentTask(newChecklist));
                })
                .catch((err) => {
                    setSaveInProgress(false);
                    setTaskUpdateStatus(SystemDataLoadingStatus.Error);
                })
            })
            .catch((err) => {
                ToastService.showMessage(MessageType.Error, err.message);
                setSaveInProgress(false);
            })
        })
        .catch((err) => {
            ToastService.showMessage(MessageType.Error, err.errorMessage);
            setSaveInProgress(false);
        });
    };

    const handleMainUserStep = (user: SystemUser) => {
        setSaveInProgress(true);
        let userInfo = new UserInformationSetup();
        userInfo.userInformation = user;
        userInfo.userInformation.mainUser = true;
        if (! customerInfoContext.setupConfig.hasTenant)
            userInfo.userInformation.userPrincipleName = userInfo.userInformation.userPrincipleName.split('@')[0] +  "@" + customerInfoContext.defaultDomain.tenantDomain;
        userInfo.licences = [];
        userInfo.userInformation.customerId = customerInfoContext.setupConfig.customerId;

        customerService.updateMainUserInformations(customerInfoContext.setupConfig.customerId, userInfo).then((resp) => {
            customerService.completeTask(getCurrentTask(checklistItems)!)
                .then((resp) => {
                    setTaskUpdateStatus(SystemDataLoadingStatus.Loading);
                    onCompleteTask().then((newChecklist) => {
                        customerInfoContext.reloadSetupConfig();
                        setShowMainUserSetup(false);
                        setSaveInProgress(false);
                        setTaskUpdateStatus(SystemDataLoadingStatus.Loaded);

                        handleNextClick(getCurrentTask(newChecklist));
                    })
                    .catch((err) => {
                        setSaveInProgress(false);
                        setTaskUpdateStatus(SystemDataLoadingStatus.Error);
                    })
                })
                .catch((err) => {
                    ToastService.showMessage(MessageType.Error, err);
                    setSaveInProgress(false);
                })
        })
        .catch((err) => {
            ToastService.showMessage(MessageType.Error, err);
            setSaveInProgress(false);
        });
    }

    const handleUserStep = () => {
        setSaveInProgress(true);
        customerService.completeSetupStep(customerInfoContext.setupConfig.customerId, 4).then((resp) => {
            setTaskUpdateStatus(SystemDataLoadingStatus.Loading);
            onCompleteTask().then((newChecklist) => {
                customerInfoContext.reloadSetupConfig();
                setShowUserSetup(false);
                setSaveInProgress(false);
                setTaskUpdateStatus(SystemDataLoadingStatus.Loaded);

                handleNextClick(getCurrentTask(newChecklist));
            })
            .catch((err) => {
                setSaveInProgress(false);
                setTaskUpdateStatus(SystemDataLoadingStatus.Error);
            })
        })
        .catch((err) => {
            ToastService.showMessage(MessageType.Error, err.message);
            setSaveInProgress(false);
        });
    }

    //#endregion

    //#region Customer buy step
    
    const checkCustomerBillingData = (current: Customer): boolean => {
        let statusValue = true;
        let customerData = {
            companyName: current.companyName,
            firstName: current.firstName,
            lastName: current.lastName,
            phoneNo: current.phoneNo,
            streetAddress: current.streetAddress,
            zipCode: current.zipCode,
            city: current.city,
            county: current.county,
            country: current.country,
            vat: current.vat,
            sdi: current.sdi,
            pecEmail: current.pecEmail,
            email: current.email,
            legalRepresentativeFirstName: current.legalRepresentativeFirstName,
            legalRepresentativeLastName: current.legalRepresentativeLastName,
        };
        
        Object.keys(customerData).forEach((value: string, index: number, array: string[]) => {
            const currentValue = Object.values(customerData)[index];
            
            if (currentValue === undefined || currentValue === null || currentValue === "") {
                statusValue = false;
                
                if (current.legalEntityCode === 'ESS') {
                    if (value === "sdi" || value === "vat" || value === "pecEmail") {
                        statusValue = true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }
        });
        
        return statusValue;
    };

    const handleRedirectToShop = () => {
        ToastService.showMessage(MessageType.Pending, t("leadDashboard:messages:text00002msg")!, 'handleRedirectToShop');
        customerInfoContext.reloadCustomer(customerInfoContext.customer.id).then((customer) => {
            customerService.generateShopLink().then((link: string) => {
                setShopLink(link);
                if(checkCustomerBillingData(customer)){
                    ToastService.update("handleRedirectToShop", { isLoading: false, render: () => t('customerCenter:Text00001Lbl')!, type: "info"});
                    window.open(link,'_self');
                }
                else {
                    setPurchasedClicked(true);
                    billingCardRef.current!.setRecord(customer);
                    billingCardRef.current!.openPage(SystemOperation.Update);
                    ToastService.update('handleRedirectToShop', { isLoading: false, render: () => t('customerCenter:Err00003Text')!, type: "warning"});
                }
            })
            .catch(() => {
                ToastService.update('handleRedirectToShop', { isLoading: false, render: () => t("leadDashboard:errors:text00005Err")!, type: "error"});
            })
        })
        .catch(() => {
            ToastService.update('handleRedirectToShop', { isLoading: false, render: () => t("leadDashboard:errors:text00005Err")!, type: "error"});
        })
    }

    const reloadCardData = (operationType: SystemOperation, rec: Customer)=>{
        if (!checkCustomerBillingData(rec)){
            customerInfoContext.reloadCustomer(rec.id).then(() => {
                ToastService.showMessage(MessageType.Error, t('customerCenter:Err00004Text'));
                billingCardRef.current?.handleError();
            })
            .catch((jqXHR) => {
                ToastService.showMessage(MessageType.Error, jqXHR.message);
                billingCardRef.current?.handleError();
            });
        }
        else
        {
            if (!(rec.legalEntityCode === 'ESS')) 
            {
                CommonFunctions.validateVat(rec.vat)
                .then((resp) => {
                    if (resp.risultato === "NON_VALIDA" || resp.risultato === "ERROR")
                    {
                        customerInfoContext.reloadCustomer(rec.id).then(() => {
                            ToastService.showMessage(MessageType.Warning, t('customerCenter:Err00005Text')+": "+rec.vat);
                            billingCardRef.current?.handleError();
                        })
                        .catch((jqXHR) => {
                            ToastService.showMessage(MessageType.Error, jqXHR.message);
                            billingCardRef.current?.handleError();
                        });
                    }
                    else
                        updateCustomer(rec);
                })
                .catch((err) => {
                    customerInfoContext.reloadCustomer(rec.id).then(() => {
                        ToastService.showMessage(MessageType.Error, err.message);
                        billingCardRef.current?.handleError();
                    })
                    .catch((jqXHR) => {
                        ToastService.showMessage(MessageType.Error, jqXHR.message);
                        billingCardRef.current?.handleError();
                    });
                })
            }
            else
                updateCustomer(rec);
        }
    }
    
    const updateCustomer = (rec: Customer) => {
        customerInfoContext.updateCustomer(rec).then(() => {
            customerInfoContext.reloadCustomer(rec.id).then(() => {
                billingCardRef.current?.closePage();
                ToastService.showMessage(MessageType.Success, t('customerCenter:Text00002Lbl'));
                
                if (purchaseClicked)
                {
                    window.open(shopLink,'_blank');
                    setPurchasedClicked(false);
                }
            })
            .catch((jqXHR) => {
                ToastService.showMessage(MessageType.Error, jqXHR.message);
                billingCardRef.current?.handleError();
            });
        })
        .catch((jqXHR) => {
            customerInfoContext.reloadCustomer(rec.id).then(() => {
                ToastService.showMessage(MessageType.Error, jqXHR.message);
                billingCardRef.current?.handleError();
            });
        });
    }

    //#endregion

    //#region Contract step

    const completeContractTask = () => {
        if(!document.hidden){
            setTaskUpdateStatus(SystemDataLoadingStatus.Loading);
            onCompleteTask().then((resp) => {
                handleContractPageEvent(true);
                setTaskUpdateStatus(SystemDataLoadingStatus.Loaded);
            })
            .catch((err) => {
                handleContractPageEvent(true);
                setTaskUpdateStatus(SystemDataLoadingStatus.Error);
            })
        }
    }

    const handleContractPageEvent = (removeEvent: boolean) => {
        if(removeEvent)
            document.removeEventListener('visibilitychange', completeContractTask);
        else
        {
            document.addEventListener(
                "visibilitychange",
                completeContractTask
            );
        }
    }

    //#endregion

    useEffect(()=>{
        if (dataStatus === SystemDataLoadingStatus.ToLoad)
        {
            getDashboardData().then(() => {
                setTaskUpdateStatus(SystemDataLoadingStatus.Loaded);
                setDataStatus(SystemDataLoadingStatus.Loaded);
            })
            .catch((err) => {
                setTaskUpdateStatus(SystemDataLoadingStatus.Error);
                setDataStatus(SystemDataLoadingStatus.Error);
            })
        }
    }, [dataStatus]);

    useEffect(() => {
        if(dataStatus === SystemDataLoadingStatus.Loaded)
        {
            handleShortcut();
            autoStartIntroStep();
        }
    }, [dataStatus])

    if (! SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={t('leadDashboard:messages:text00001msg')!} />

    return(
        <>
            <Stack verticalAlign="space-between" verticalFill className={wrapperClassName}>
                <Stack.Item>
                    <PurchaseButton
                        hidden={!isPurchaseButtonHidden()}
                        onClick={handleRedirectToShop}
                    />
                    <Banner
                        text={t("leadDashboard:banner:title")} 
                        hasSubtext 
                        subText={t("leadDashboard:banner:subTitle")!}
                    >
                        {
                            SystemCore.isFinishedDataStatus(taskUpdateStatus) ?
                                <Checklist 
                                    items={checklistItems}
                                    currentItem={currentChecklistItem}
                                    completed={checklistComplete}
                                    onCancel={()=>{}} 
                                    onStart={(item:ChecklistItem)=>{
                                        startTask(item)
                                    }}
                                />
                            :
                                <ChecklistLoader />
                        }
                    </Banner>
                </Stack.Item>
                <LeadDashboardLink 
                    links={dashboardLinks}
                    onClick={handleDashboardLinkClick}
                    key={"LeadDashboardLink"}
                />
            </Stack>
            <CommonVideoModal
                showModal={showVideoModal}
                disableLight={disableVideoLight} 
                key={"DemoVideoModal-" + videoResource?.description} 
                onDismiss={(isVideoPlayed:boolean)=>{
                    if(isVideoPlayed)
                        CommonFunctions.logEvent("Chiusura video dialog: "+videoResource?.description, "");
                    else
                        CommonFunctions.logEvent("Chiusura video dialog: "+videoResource?.description+", senza visione del contenuto", "");
                    
                    setShowVideoModal(false);

                    completeTask();
                }} 
                resource={videoResource!} 
            />         
            <LeadModal
                showModal={showLeadModal}
                savingData={leadDataSaveInProgress}
                canScroll={leadModalCanScroll}
                leadRef={lead}
                leadConfigDetail={leadConfigDetails}
                leadToLiveChecklist={currentChecklistItem.leadTask!}
                industryTypes={industryTypes}
                validationErrors={validationErrors}
                onSave={onSaveLeadModal}
                onCancel={() =>{
                    setShowLeadModal(false);
                    CommonFunctions.logEvent("Chiusura form trial senza conferma", "");
                }}
                checkLeadData={(configuration: SaveLeadConfigChecklist) => {
                    checkLeadModalData(configuration);
                }}
            />
            <RoleCenterBookingModal
                showModal={showBookingModal}
                onDismiss={()=>{
                    CommonFunctions.logEvent("Chiusura form booking per prenotazione consulente", "");
                    setShowBookingModal(false);
                    completeTask();
                }}
            />
            {
                customerInfoContext.setupConfig !== undefined ?
                    <>
                        <CustomerBillingInfoCard onSuccess={reloadCardData} ref={billingCardRef} record={customerInfoContext.customer} />
                        <CustomerTenantSetupBox showModal={showTenantInfo} saveInProgress={saveInProgress} onDismiss={handleTenantStepSave} onCancel={() => setShowTenantInfo(false)} />
                        <CustomerTenantDetailBox tenantInfo={tenantInformation} showModal={showTenantDetailInfo} saveInProgress={saveInProgress} onDismiss={handleTenantDetailStepSave} onCancel={() => setShowTenantDetailInfo(false)} />
                        <CustomerSetupCompanyList onDismiss={handleCompanySetupStepSave} showModal={showCompanySetup} saveInProgress={saveInProgress} onCancel={() => setShowCompanySetup(false)} />
                        <CustomerUserSetupList showModal={showUserSetup} onDismiss={handleUserStep} saveInProgress={saveInProgress} onCancel={() => setShowUserSetup(false)} />
                        <CustomerMainUserSetupCard showModal={showMainUserSetup} saveInProgress={saveInProgress} onDismiss={handleMainUserStep} onCancel={() => setShowMainUserSetup(false)} />
                    </>
                :
                    <></>
            }
        </>
    );
}
