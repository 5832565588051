import { CommandBar, DetailsList, Dialog, DialogFooter, DialogType, IComboBoxStyles, IModalProps, mergeStyles, MessageBar, PrimaryButton, SelectionMode, Separator, Stack, Text, TextField } from "@fluentui/react";
import { useForceUpdate } from "@fluentui/react-hooks";
import React, { useContext, useEffect, useState } from "react";
import { CustomerInformationContext } from "../../../ApplicationCode/Customer/CustomerInformationContext";
import { CustomerService } from "../../../ApplicationCode/Customer/CustomerService";
import { SystemCore } from "../../../Core/System/SystemCore";
import { ToastService, MessageType } from "../../../Core/Toast/ToastService";
import { CustomerCompany } from "../../../Model/CustomerModels";
import { SystemDataLoadingStatus, SystemUser } from "../../../Model/SystemModels";
import { Loader } from "../../Common/Loader/Loader";
import { CustomerUserList } from "../CustomerUserList/CustomerUserList";
import { t } from "i18next";

const tenantDetailBoxClassName = mergeStyles([{
}]);

const titleClassName = mergeStyles([{
    fontSize: 20,
    fontWeight: 500
}]);

const textClassName = mergeStyles([{
    fontSize: 15
}]);

const separatorStyles = {
    root: [{
      selectors: { // add selectors here
        '::before': {
          background: '#bbb',
        },
      }
    }]
};

const contentContainerClassName = mergeStyles([{
}]);

const pageCardContentBodyClassName = mergeStyles([{
    width: '100%',
    overflowY: "auto",
    overflowX: "hidden"
}]);

const pageCardModalContentClassName = mergeStyles([{
    width: '100%',
    marginTop: 20
}]);

const pageCardFormContentClassName = mergeStyles([{
    width: '100%'
}]);

export interface ICustomerUserSetupListProps {
    showModal: boolean;
    saveInProgress: boolean;
    onDismiss: () => void;
    onCancel: () => void;
}

export const CustomerUserSetupList : React.FC<ICustomerUserSetupListProps> = (props) => {
    const pageCardModalProps: Partial<IModalProps> = {
        isBlocking: false,
        dragOptions: undefined,
        styles: {
            main: {
                minWidth: '500px !important',
                position: 'absolute',
                top: 50
            }
        }
    };
    let messageBar = <></>;
    if (props.saveInProgress)
        messageBar = <MessageBar>{t('customerCenter:CustomerUserSetupList:MessageBar:messageContent1')}</MessageBar>
    
    return (
        <Dialog hidden={! props.showModal}
        modalProps={pageCardModalProps}
        onDismiss={props.onCancel}
        dialogContentProps={{
            title: t('customerCenter:CustomerUserSetupList:Dialog:title')!,
            type: DialogType.largeHeader
        }}>
            <Stack key="modalContent" className={pageCardModalContentClassName} tokens={{
                childrenGap: 15
            }}>
                <Stack.Item key="modalBodyContent" className={pageCardContentBodyClassName}>
                    <Stack key="userSetupList" className={tenantDetailBoxClassName}>
                        <Stack.Item className={contentContainerClassName}>
                            <CustomerUserList />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                {messageBar}
                <Stack.Item key="modalFooterContent">
                    <DialogFooter>
                        <PrimaryButton text={t('leadDashboard:buttonText')!} onClick={() => {
                            props.onDismiss();
                        }}
                        disabled={props.saveInProgress} />
                    </DialogFooter>
                </Stack.Item>
            </Stack>
        </Dialog>
    );
};