import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminChatbotProfileCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title="Scheda profilo chatbot" endpoint="odata/chatbot/profile" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="code" label="Codice" type={SystemFieldType.String} />
                <CardField name="description" label="Descrizione" type={SystemFieldType.String} />
                <CardField name="origin" label="Origine" type={SystemFieldType.String} />
                <CardField name="defaultContext" label="Default context" type={SystemFieldType.String} />
            </CardGroup>
            <CardGroup name="OpenAi" label="Open AI" isOpen={false}>
                <CardField name="oaiApiEndpoint" label="Endpoint" type={SystemFieldType.String} />
                <CardField name="oaiApiKey" label="Chiave api" type={SystemFieldType.String} />
                <CardField name="oaiApiDeploymentName" label="Nome distribuzione api" type={SystemFieldType.String} />
                <CardField name="embeddingDeploymentName" label="Nome distribuzione embedding" type={SystemFieldType.String} />
            </CardGroup>
            <CardGroup name="advanced" label="Avanzato" isOpen={true}>
                <CardField name="roleInformation" label="Indentità" type={SystemFieldType.String} multiline />
                <CardField name="promptTemplate" label="Prompt risposta" type={SystemFieldType.String} multiline />
                <CardField name="followUpQuestionsTemplate" label="Prompt domande suggerite" type={SystemFieldType.String} multiline />
                <CardField name="welcomeMessage" label="Messaggio di benvenuto" type={SystemFieldType.String} multiline />
            </CardGroup>
        </PageContainer>
    );
});