import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { CookieService } from "../Cookie/CookieHelper";
import { HttpConstants } from "./HttpConstants";

export class HttpHelper {

    public static buildAxiosInstance(timeout?: number) : AxiosInstance
    {
        let accessToken = CookieService.getCookieValue(HttpConstants.ACCESSTOKEN_COOKIE_NAME, true);

        if (timeout === undefined || timeout <= 0)
            timeout = Number(process.env.AXIOS_TIMEOUT);
        
        let properties: AxiosRequestConfig<any> = {
            baseURL: this.buildBaseEndpoint(),
            timeout: timeout,
            withCredentials: true
        };

        let instance: AxiosInstance = axios.create(properties);
        if (accessToken != undefined)
            instance.defaults.headers.common[HttpConstants.HEADER_AUTHORIZATION] = HttpConstants.BEARER_AUTH + " " + accessToken;

        instance.interceptors.response.use(function(response) {
            return response;
        }, 
        function(error){
           if (error.response.staus == 401)
             window.location.pathname = "sessionexpired";
           return Promise.reject(error); 
        });
        return instance;
    }

    private static buildBaseEndpoint() : string
    {
        let backendHost: string;
        
        if (process.env.REACT_APP_SYSTEM_BACKEND_HOST!.indexOf('localhost') >= 0) {
            backendHost = process.env.REACT_APP_SYSTEM_BACKEND_HOST!;
        }
        else {
            let hostname: string = window.location.hostname;
            if(hostname.indexOf('localhost') >= 0){
                backendHost = process.env.REACT_APP_SYSTEM_BACKEND_HOST! + process.env.REACT_APP_SYSTEM_BACKEND_DEFAULT_HOST_EXTENSION!;
            }
            else {
                const parts = hostname.split('.');
                const domainExtension = parts[parts.length - 1];
                backendHost = process.env.REACT_APP_SYSTEM_BACKEND_HOST! + '.' + domainExtension;
            }
        }
        
        let serverBaseEndpoint: string = 'https';
        serverBaseEndpoint += "://" + backendHost;
        
        let port = Number(process.env.REACT_APP_SYSTEM_BACKEND_PORT!);
        if (port !== 0)
            serverBaseEndpoint += ":" + port;
        
        return serverBaseEndpoint;
    }

    public static getParameter(param:string):any
    {
        const query = new URLSearchParams(window.location.search);
        return (query.get(param))
    }

}