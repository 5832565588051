import { useEffect, useState } from "react";
import { SystemActionCategory, SystemDataLoadingStatus, SystemPageType } from "../../../Model/SystemModels";
import { ActionArea } from "../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../Common/Template/Action/ActionEntry";
import PageContainer from "../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../Common/Template/PageDefinitionContainer";
import { ListHeaderEntry } from "../../Common/Template/List/ListHeaderEntry";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { Loader } from "../../Common/Loader/Loader";
import { BookingAvailabilityItem, BookingSource } from "../../../Model/PartnerModels";
import { PartnerService } from "../../../ApplicationCode/Partner/PartnerService";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { useNavigate } from "react-router-dom";
import React from "react";
import { SessionStateContext } from "../../../Core/State/SessionStateContext";
import { t } from "i18next";

export interface IPartnerBookinAvailabilityListProps {
    leadId?: number;
    leadName?: string;
    mode?: string;
    isModal?: boolean;
    source?: BookingSource;
}

export const PartnerBookinAvailabilityList : React.FC<IPartnerBookinAvailabilityListProps> = (props) => {
    const [message, setMessage] = useState(t("common:bookingList:loaderText")!);
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const partnerService = new PartnerService();
    const navigate = useNavigate();
    const sessionStateContext = React.useContext(SessionStateContext);

    let leadId: number = HttpHelper.getParameter('leadId');
    let leadName: string = HttpHelper.getParameter('leadName');
    let mode: string = HttpHelper.getParameter('mode');
    let source: BookingSource = HttpHelper.getParameter('source');
    let ianaTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const encodedIanaTimeZone: string = encodeURIComponent(ianaTimeZone);
    let userLocale: string = navigator.language || navigator.languages[0];
    const intlOptions: Intl.DateTimeFormatOptions = {
        timeZone: ianaTimeZone,
        timeZoneName: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
    };

    if(leadId === null)
        if((props.leadId !== undefined) && (props.leadId !== 0))
            leadId = props.leadId;
        else
            leadId = 0;

    if(leadName === null)
        if((props.leadName !== undefined) && (props.leadName !== ""))
            leadName = props.leadName;

    if(mode === null)
        if((props.mode !== undefined) && (props.mode !== ""))
            mode = props.mode;

    if(source === null)
        if((props.source !== undefined))
            source = props.source;

    useEffect(()=>{
        if(dataStatus === SystemDataLoadingStatus.ToLoad) {
            if (mode === undefined || mode === "" || (mode !== "lead" && mode !== "internal")){
                navigate(sessionStateContext.currentApp.homepageUrl);
            }
            
            if (leadId === undefined || leadId === 0)
                navigate(sessionStateContext.currentApp.homepageUrl);

            if (source === undefined)
                navigate(sessionStateContext.currentApp.homepageUrl);

            setDataStatus(SystemDataLoadingStatus.Loaded);
        }
    },[dataStatus]);

    const bookLeadAppointment = (availabilityItem: BookingAvailabilityItem, leadId: number) => {
        setMessage(t("common:bookingList:loaderText1")!)
        setDataStatus(SystemDataLoadingStatus.Loading);

        partnerService.bookLeadAppointment(availabilityItem, leadId, (mode === "internal"), ianaTimeZone)
            .then((resp)=>{
                setDataStatus(SystemDataLoadingStatus.Loaded);
                ToastService.showMessage(MessageType.Success, t("common:bookingList:loaderText2")!);
                
                if(props.isModal !== undefined && !props.isModal)
                    navigate("/app/partner/leads");
            })
            .catch((jqXHR)=>{
                ToastService.showMessage(MessageType.Error, t("common:bookingList:loaderText3")!+jqXHR.message);
                setDataStatus(SystemDataLoadingStatus.Loaded);
            })
    }
    
    const buildEndpoint = () : string => {
    
        let endpoint: string = "/api/csp/booking/availability/"+source+"/"+leadId+"?ianaTimeZone="+encodedIanaTimeZone;
                
        return endpoint;
    }

    const buildTitle = () : string => {
        let title: string = t("common:bookingList:title0");

        if (mode === "internal")
            title += t("common:bookingList:title1")
        else if (mode === "lead")
            title += t("common:bookingList:title2")

        if(props.isModal !== undefined && !props.isModal)
            title += t("common:bookingList:title3") + leadName;

        return title;
    }

    if (dataStatus!==SystemDataLoadingStatus.Loaded)
        return <Loader text={message}></Loader>;
    else
        return (
            <>
                <PageContainer pageType={SystemPageType.List} title={buildTitle()} endpoint={buildEndpoint()} backAllowed={!props.isModal!} shareAllowed={!props.isModal!}>
                    <PageDefinitionContainer type={ContainerType.Action}>
                        <ActionArea category={SystemActionCategory.Process}>
                            <ActionEntry name="Book" label={t("common:bookingList:actions:text0001lbl")!} iconName="Accept" runOnRec isPromoted onClick={(req:BookingAvailabilityItem) => {
                                bookLeadAppointment(req, leadId);
                            }} />
                        </ActionArea>
                    </PageDefinitionContainer>
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry name={t("common:bookingList:field:serviceName")!} fieldName="serviceName" minWidth={150} maxWidth={200} />
                        <ListHeaderEntry name={t("common:bookingList:field:status")!} fieldName="status" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name={t("common:bookingList:field:startDateTime")!} fieldName="startDateTime" minWidth={300} maxWidth={350}
                            onRender={(item: any, index, column) => {
                                let date = new Date(item.startDateTime);
                                const milliseconds = Date.UTC(
                                    date.getFullYear(),
                                    date.getMonth(),
                                    date.getDate(),
                                    date.getHours(),
                                    date.getMinutes(),
                                    date.getSeconds(),
                                );
                                const localDate = new Date(milliseconds);
                                return Intl.DateTimeFormat(userLocale, intlOptions).format(localDate);
                            }}
                        />
                        <ListHeaderEntry name={t("common:bookingList:field:serviceDuration")!} fieldName="serviceDuration" minWidth={150} maxWidth={200} />
                    </PageDefinitionContainer>
                </PageContainer>
            </>
        );
}