import { Checkbox, DefaultButton, Dialog, DialogFooter, IColumn, IModalProps, mergeStyles, PrimaryButton, SelectionMode, ShimmeredDetailsList, Stack, TextField } from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import { CustomerInformationContext } from "../../../ApplicationCode/Customer/CustomerInformationContext";
import { CustomerService } from "../../../ApplicationCode/Customer/CustomerService";
import { SystemCore } from "../../../Core/System/SystemCore";
import { UserLicenseEntry } from "../../../Model/CustomerModels";
import { SystemDataLoadingStatus, SystemUser } from "../../../Model/SystemModels";
import { t } from "i18next";

const modalProps: Partial<IModalProps> = {
    isBlocking: true,
    dragOptions: undefined,
    styles: {
        main: {
            width: '95% !important',
            maxWidth: 'calc(95%) !important',
            position: 'absolute',
            top: 50
        }
    }
  };

const contentBodyClassName = mergeStyles([{
    width: '100%',
    maxHeight: 'calc(100vh - 200px)',
    overflowY: "auto",
    overflowX: "hidden"
}]);

const modalContentClassName = mergeStyles([{
    width: '100%'
}]);


export interface ICustomerLicenseCardProps {
    showDialog: boolean;
    title: string;
    user: SystemUser;
    onSuccessModal: (licenses: UserLicenseEntry[]) => void;
    onCancelModal: () => void;
}

export const CustomerLicenseCard : React.FC<ICustomerLicenseCardProps> = (props) => {
    const [licences, setLicenses] = useState([] as UserLicenseEntry[]);
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);

    const customerInfoContext = useContext(CustomerInformationContext);
    const customerService = new CustomerService();

    const buildListColumns = (): IColumn[] => {
        return [{
            key: "product",
            name: t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerLicenseCard:Dialog:DetailList:columns:product')!,
            minWidth: 100,
            maxWidth: 500,
            onRender: (item: UserLicenseEntry | undefined, index?, column?) => {
                let disableControl = false;
                if ((item!.availableQty === 0) && (! item!.active))
                    disableControl = true;
                
                return (
                    <Checkbox label={item!.description} 
                              checked={item!.active} 
                              disabled={disableControl}
                              onChange={(ev, checked) => {
                                handleLicenseActiveChange(item!, checked!);
                              }} />
                );
            }
        },
        {
            key: "availableLicences",
            fieldName: "availableQty",
            name: t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerLicenseCard:Dialog:DetailList:columns:availableLicences')!,
            minWidth: 100,
            maxWidth: 200
        }];
    };

    const handleLicenseActiveChange = (licenseEntry: UserLicenseEntry, value: boolean) => {
        let entries: UserLicenseEntry[] = [];
        for (let i = 0; i < licences.length; i++)
            entries.push(licences[i]);
        
        let index = entries.indexOf(licenseEntry);

        entries[index].toRemove = false;
        entries[index].toAssign = false;
        entries[index].availableQty = licenseEntry.originalAvailableQty;

        if (entries[index].originalActive !== value && (entries[index].active && !value)) {
            entries[index].availableQty = licenseEntry.availableQty + 1;
            entries[index].toRemove = true;
            entries[index].toAssign = false;
        }
        if (entries[index].originalActive !== value && (!entries[index].active && value)) {
            entries[index].availableQty = licenseEntry.availableQty - 1;
            entries[index].toRemove = false;
            entries[index].toAssign = true;
        }

        entries[index].active = value;
        setLicenses(entries);
    }

    useEffect(() => {
        if (props.showDialog)
        {
            if ((dataStatus === SystemDataLoadingStatus.ToLoad) || (dataStatus === SystemDataLoadingStatus.Loaded))
            {
                setDataStatus(SystemDataLoadingStatus.Loading);
                customerService.loadCustomerUserAvailableLicenses(customerInfoContext.setupConfig.customerId, props.user.id)
                    .then((resp) => {
                        setLicenses(resp);
                        setDataStatus(SystemDataLoadingStatus.Loaded);
                    })
                    .catch((err) => {
                        console.log(err)
                    });
            }
        }
        else
            setDataStatus(SystemDataLoadingStatus.ToLoad);
    }, [props.showDialog])

    return (
        <Dialog hidden={! props.showDialog}
                modalProps={modalProps}
                onDismiss={props.onCancelModal}
                dialogContentProps={{
                    title: props.title
                }}>
                <Stack key="modalContent" className={modalContentClassName}>
                    <Stack.Item key="modalBodyContent" className={contentBodyClassName}>
                        <ShimmeredDetailsList items={licences}
                                              columns={buildListColumns()}
                                              selectionMode={SelectionMode.none}
                                              enableShimmer={! SystemCore.isFinishedDataStatus(dataStatus)}
                        />
                    </Stack.Item>
                    <Stack.Item key="modalFooterContent">
                        <DialogFooter>
                            <DefaultButton text={t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerLicenseCard:Dialog:defaultButtonText')!} onClick={props.onCancelModal} />
                            <PrimaryButton text={t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerLicenseCard:Dialog:primaryButtonText')!} onClick={() => props.onSuccessModal(licences) } />
                        </DialogFooter>
                    </Stack.Item>
                </Stack>
        </Dialog>
    );

}