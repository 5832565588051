import { Checkbox, ComboBox, DefaultButton, Dialog, DialogFooter, IComboBoxOption, IModalProps, IStackTokens, mergeStyles, MessageBar, PrimaryButton, Stack, TextField } from "@fluentui/react";
import React, { forwardRef, useContext, useEffect, useState } from "react";
import { CustomerInformationContext } from "../../../../ApplicationCode/Customer/CustomerInformationContext";
import { OperationType, SystemFieldType, SystemPageType, SystemUser } from "../../../../Model/SystemModels";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";

export const PartnerSalesBudgetCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} endpoint="/odata/sales/budget" title={'Scheda Lead'} ref={ref}>
            <CardGroup name="Budget" label="Budget" isOpen={true}>
                <CardField name="entryNo" type={SystemFieldType.Number} label="Entry No" readonly />
                <CardField name="date" type={SystemFieldType.String} label="Data"/>
                <CardField name="origin" type={SystemFieldType.String} label="Origine"/>
                <CardField name="programType" type={SystemFieldType.Option} cmbOptions={[
                    {key: 0, text: "Prog 1"},
                    {key: 1, text: "Prog 2"},
                    {key: 2, text: "Prog 3"}
                ]} label="Tipo Programma" />
                <CardField name="partnerId" type={SystemFieldType.Number} label="ID Partner" hasTableRelation tableRelationEndpoint="odata/partner" tableRelationKey="id" tableRelationField="name" />
                <CardField name="itemNo" type={SystemFieldType.Number} label="Numero Item" />
                <CardField name="quantity" type={SystemFieldType.Number} label="Quantità"/>
                <CardField name="unitPrice" type={SystemFieldType.Number} label="Prezzo unitario" />
                <CardField name="lineAmount" type={SystemFieldType.Number} label="Line Amount" />
            </CardGroup>
        </PageContainer>    
    );
});