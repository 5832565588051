import React, {useRef} from "react";
import { useNavigate } from "react-router-dom";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminAzureSetupCard } from "../AdminAzureSetupCard/AdminAzureSetupCard";

export const AdminAzureSetupList : React.FC = () => {
    
    const cardRef = useRef<PageContainerBase>(null);
    const navigate = useNavigate();
    
    return (
        <>
            <PageContainer endpoint={'/odata/azure/setup'} pageType={SystemPageType.List} title={"Azure Setup"} cardRef={cardRef} backAllowed insertAllowed updateAllowed>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry label="Booking" iconName="BookingsLogo" name="openBookingSetup" onClick={(req:any)=>{
                            navigate("/app/admin/administrative/bookingSetup");
                        }}>
                        </ActionEntry>
                        <ActionEntry label="OpenAI" iconName="AutoEnhanceOn" name="openOpenAISetup" onClick={(req:any)=>{
                            navigate("/app/admin/administrative/openaiSetup");
                        }}>
                        </ActionEntry>
                        <ActionEntry label="Sharepoint directory" iconName="SharepointLogo" name="openSharepointDirectory" onClick={(req:any)=>{
                            navigate("/app/admin/administrative/sharepointDirectory");
                        }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry fieldName="primaryKey" name="Chiave primaria" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="aadClientId" name="AAD Client Id" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="aadClientSecret" name="AAD Client Secret" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="aadTenantId" name="AAD Id Tenant" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="aadScope" name="AAD Scope" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="aadCustoperGroupPrefix" name="AAD Prefisso Gruppo" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="aadAzureapiEndPoint" name="Api endpoint" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="videoBlobConnectionString" name="Video blob connection string" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="videoBlobContainerName" name="Blob Container Name" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="videoLinkMinutesDuration" name="video link duration" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="aadPageFilter" name="Filtro ricerca pagine" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="sendInvitationMessage" name="Invia mail invito guest user AAD" minWidth={100} maxWidth={200} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminAzureSetupCard ref={cardRef} />
        </>
    );
}