import React, { useRef } from "react";
import { SystemOperation, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { AdminLossRiskLineCard } from "../AdminLossRiskLineCard/AdminLossRiskLineCard";
import { t } from "i18next";

export const AdminLossRiskLineList : React.FC = () => {
    
    const cardRef = useRef<PageContainerBase>(null);

    const sourceTable: String = HttpHelper.getParameter("sourceTable");
    const sourceId: Number = HttpHelper.getParameter("sourceId");

    const buildListEndpoint = () : string =>{
        
        let endpoint = "/odata/crm/lossriskline";
        
        if (sourceTable && sourceId){
            endpoint += "?$filter=sourceTable eq '"+sourceTable+"' and sourceId eq "+sourceId;
        }
        
        return endpoint;
    }
    
    return (
        <>
            <PageContainer endpoint={buildListEndpoint()} pageType={SystemPageType.List} title={t("adminCenter:lossRiskLineList:title")!} cardRef={cardRef} backAllowed insertAllowed updateAllowed deleteAllowed onNewRecord={() => {
                cardRef.current?.openPage(SystemOperation.Insert);
                cardRef.current?.setRecord({
                    sourceTable: sourceTable,
                    sourceId: sourceId
                });
            }}>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name={t("adminCenter:lossRiskLineList:fields:lossCauseDescription")!} fieldName="lossCauseDescription" minWidth={300} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:lossRiskLineList:fields:description")!} fieldName="description" minWidth={400} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:lossRiskLineList:fields:notes")!} fieldName="notes" minWidth={400} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:lossRiskLineList:fields:processModule")!} fieldName="processModule" minWidth={200} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:lossRiskLineList:fields:processCode")!} fieldName="processCode" minWidth={200} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:lossRiskLineList:fields:lineScore")!} fieldName="lineScore" minWidth={150} maxWidth={200} />
                    <ListHeaderEntry name={t("adminCenter:lossRiskLineList:fields:handled")!} fieldName="handled" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name={t("adminCenter:lossRiskLineList:fields:handleDate")!} fieldName="handleDate" minWidth={150} maxWidth={200} />
                    <ListHeaderEntry name={t("adminCenter:lossRiskLineList:fields:sourceTable")!} fieldName="sourceTable" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:lossRiskLineList:fields:sourceId")!} fieldName="sourceId" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:lossRiskLineList:fields:sourceExternalId")!} fieldName="sourceExternalId" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:lossRiskLineList:fields:entryNo")!} fieldName="entryNo" minWidth={100} maxWidth={200} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminLossRiskLineCard ref={cardRef} />
        </>
    );
}