import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminChatbotTicketInputCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda ticket chatbot"} endpoint="odata/chatbot/ticket/input" ref={ref}>
            <CardGroup name="setup" label="Configurazione" isOpen={true}>
                <CardField name="entryNo" label="Id" type={SystemFieldType.Number} />
                <CardField name="uploadState" label="Upload State" type={SystemFieldType.Option} cmbOptions={[
                    {key: 0, text:"In coda"},
                    {key: 1, text:"Caricato"},
                    {key: 2, text:"In errore"}
                ]} />
                <CardField name="origin" label="Origin" type={SystemFieldType.String} />
                <CardField name="email" label="Email" type={SystemFieldType.String} />
                <CardField name="leadId" label="Lead Id" type={SystemFieldType.String} />
                <CardField name="itemId" label="Item Id" type={SystemFieldType.Number} />
                <CardField name="incidentType" label="Incident Type" type={SystemFieldType.Number} />
                <CardField name="customerId" label="Customer Id" type={SystemFieldType.String} />
                <CardField name="customerType" label="Customer Type" type={SystemFieldType.Option} cmbOptions={[
                    {key: 0, text: "OnPrem"},
                    {key: 1, text: "Saas"}
                ]} />
                <CardField name="subscriptionType" label="Subscription Type" type={SystemFieldType.Option} cmbOptions={[
                    {key: 0, text: "Free"},
                    {key: 1, text: "Full"}
                ]} />
                <CardField name="appVersion" label="App Version" type={SystemFieldType.String} />
                <CardField name="title" label="Title" type={SystemFieldType.String} />
                <CardField name="description" label="Description" type={SystemFieldType.String} />
                <CardField name="crmReferenceId" label="CRM Reference Id" type={SystemFieldType.String} />
                <CardField name="errorDescription" label="Error Description" type={SystemFieldType.String} />
                <CardField name="totalSeconds" label="Total Seconds" type={SystemFieldType.Number} />
                <CardField name="contactSeconds" label="Contact Seconds" type={SystemFieldType.Number} />
            </CardGroup>
        </PageContainer>
    );
});