import React, { useRef } from "react";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminSystemEventSubscriberCard } from "./AdminSystemEventSubscriberCard";

export const AdminSystemEventSubscriberList : React.FC = () => {
    
    const cardRef = useRef<PageContainerBase>(null);
    
    const eventName = HttpHelper.getParameter('eventName')

    const buildEndpoint = () : string => {
    
        let endpoint: string = "/odata/system/event/subscriber";
        
        if (eventName !== undefined && eventName > "")
            endpoint += "?$filter=eventName eq '" + eventName + "'"
                
        return endpoint;
    }

    return(
        <>
            <PageContainer endpoint={buildEndpoint()} pageType={SystemPageType.List} title="Lista System Event Subscriber" cardRef={cardRef} backAllowed showAllowed>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Nome Evento" fieldName="eventName" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry name="Oggetto Sottoscrittore" fieldName="subscribedByObject" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry name="Nome Sottoscrittore" fieldName="subscriberName" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry name="Tipo Evento" fieldName="eventType" minWidth={250} maxWidth={300} onRender={(item: any, index, column) => {
                        if (item.eventType === 0)
                            return "Tabella"
                        else if (item.eventType === 1)
                            return "Integrazione"
                    }} />
                    <ListHeaderEntry name="Attivo" fieldName="active" minWidth={80} maxWidth={100} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminSystemEventSubscriberCard ref={cardRef} />
        </>
    );
}