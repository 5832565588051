import React, { useRef } from "react";
import { SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { AdminLeadIndustryLineCard } from "../AdminLeadIndustryCard/AdminLeadIndustryCard";
import { t } from "i18next";

export const AdminLeadIndustryList : React.FC = () => {
    const cardRef = useRef<PageContainerBase>(null);
    const companyName: string | null = HttpHelper.getParameter("companyName");

    const buildEndpoint = (): string => {
        let baseEndpoint = "odata/lead/industry";
        const leadId: string | null = HttpHelper.getParameter("leadId");
        if((leadId !== "") && (leadId !== null))
            baseEndpoint += "?$filter=leadId eq "+leadId;

        return baseEndpoint;
    }
    
    return (
        <>
            <PageContainer endpoint={buildEndpoint()} pageType={SystemPageType.List} title={t("adminCenter:leadIndustryList:title")+ companyName} cardRef={cardRef} backAllowed showAllowed updateAllowed>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name={t("adminCenter:leadIndustryList:fields:salesChannel")} fieldName="salesChannel" minWidth={400} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:leadIndustryList:fields:description")} fieldName="description" minWidth={400} maxWidth={250} />
                    <ListHeaderEntry name={t("adminCenter:leadIndustryList:fields:typologyId")} fieldName="typologyId" minWidth={200} maxWidth={250} onRender={(item, index, column) => {
                        switch(item.typologyId){
                            case 0:
                                return t("adminCenter:leadIndustryList:fields:typologyId0")
                            case 1:
                                return t("adminCenter:leadIndustryList:fields:typologyId1")
                            case 2:
                                return t("adminCenter:leadIndustryList:fields:typologyId2")
                            case 3:
                                return t("adminCenter:leadIndustryList:fields:typologyId3")
                            case 4:
                                return t("adminCenter:leadIndustryList:fields:typologyId4")
                        }
                    }} />
                    <ListHeaderEntry name={t("adminCenter:leadIndustryList:fields:revenue")} fieldName="revenue" minWidth={200} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminLeadIndustryLineCard ref={cardRef} />
        </>
    );
}