import React, { useEffect, useState } from "react";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import {SystemActionCategory, SystemDataLoadingStatus, SystemPageType } from "../../../../Model/SystemModels";
import { Loader } from "../../../Common/Loader/Loader";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { t } from "i18next";

export const AdminEmailLogList : React.FC = () => {
    const [dataStatus,setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [endpoint,setEndpoint] = useState("");
    const [title,setTitle] = useState("");

    useEffect(()=>{
        if (dataStatus == SystemDataLoadingStatus.ToLoad)
        {
            setDataStatus(SystemDataLoadingStatus.Loading);
            let email=HttpHelper.getParameter('email');
            let name = HttpHelper.getParameter('name');
            if(email != null){
                setTitle(t("adminCenter:emailLogList:title")!+name);
                setEndpoint("/odata/email/log?$filter=toAddress eq '"+ email + "'");
                setDataStatus(SystemDataLoadingStatus.Loaded);
            }
            else{
                setEndpoint('/odata/email/log');   
                setTitle('Email log ');
                setDataStatus(SystemDataLoadingStatus.Loaded);
            }
        }
    },[dataStatus])
 
    if (dataStatus!=SystemDataLoadingStatus.Loaded)
        return <Loader text={t("adminCenter:emailLogList:loaderText")!}></Loader>;
    else
        return (
            <>
                <PageContainer endpoint={endpoint} pageType={SystemPageType.List} title={title} backAllowed>               
                    <PageDefinitionContainer type={ContainerType.Action}>
                        <ActionArea category={SystemActionCategory.Action}>
                        </ActionArea>
                    </PageDefinitionContainer>                                 
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry name={t("adminCenter:emailLogList:fields:templateCode")!} fieldName="templateCode" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name={t("adminCenter:emailLogList:fields:legalEntityCode")!} fieldName="legalEntityCode" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name={t("adminCenter:emailLogList:fields:distributorCode")!} fieldName="distributorCode" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name={t("adminCenter:emailLogList:fields:partnerBusinessContractCode")!} fieldName="partnerBusinessContractCode" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name={t("adminCenter:emailLogList:fields:languageCode")!} fieldName="languageCode" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name={t("adminCenter:emailLogList:fields:toAddress")!} fieldName="toAddress" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name={t("adminCenter:emailLogList:fields:subject")!} fieldName="subject" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name={t("adminCenter:emailLogList:fields:body")!} fieldName="body" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name={t("adminCenter:emailLogList:fields:userId")!} fieldName="userId" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name={t("adminCenter:emailLogList:fields:id")!} fieldName="id" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="System Creation Date" fieldName="systemCreationDate" minWidth={150} maxWidth={250} />
                    </PageDefinitionContainer>
                </PageContainer>
            </>
        );
}