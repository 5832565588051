import React, { useContext, useEffect, useState } from "react";
import { Customer, CustomerDashboardCue, CustomerEnvironment } from "../../../../Model/CustomerModels";
import { SystemActionCategory, SystemDataLoadingStatus,OperationType, SystemEntity, SystemPage, SystemPageType } from "../../../../Model/SystemModels";

import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import {Link, Routes, Route, useNavigate, useLocation} from 'react-router-dom';
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { ILocation } from "../../../../Model/AdminModel";


export const AdminCustomerDataList : React.FC = () => {
    const [customerId,setCustomerId] = useState(HttpHelper.getParameter("customerId"));
    const [name,setName] = useState(HttpHelper.getParameter("name"));

    return (
        <>
            <PageContainer endpoint={"/odata/customer/company?$filter=customerId eq "+customerId} pageType={SystemPageType.List} title={"Lista versione dati di "+name} backAllowed>               
                <PageDefinitionContainer type={ContainerType.Action}>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Id" fieldName="id" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Nome" fieldName="name" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Versione Dati" fieldName="dataVersion" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
        </>
    );
}