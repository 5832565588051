import React, { useRef } from "react";
import { SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { AdminCrmLeadInteractionCard } from "../AdminCrmLeadInteractionCard/AdminCrmLeadInteractionCard";
import { Lead } from "../../../../Model/PartnerModels";
import { t } from "i18next";

export interface IAdminCrmLeadInteractionListProps {
    lead?: Lead,
    creationDate?: string,
    isModal?: boolean
}

export const AdminCrmLeadInteractionList : React.FC<IAdminCrmLeadInteractionListProps> = (props) => {
    
    const cardRef = useRef<PageContainerBase>(null);

    const leadId: number = HttpHelper.getParameter("leadId");

    const buildListEndpoint = () : string =>{
        let endpoint = "/odata/crm/lead/interaction";

        if(props.lead !== undefined){
            let filterDate = new Date(props.creationDate!);
            filterDate.setDate(filterDate.getDate() + 1);

            return '/odata/crm/lead/interaction?$filter=systemCreationDate gt '+props.creationDate+'T00:00:00Z and systemCreationDate lt '+filterDate.toISOString()+' and leadId eq '+props.lead.id+'&$orderby=systemCreationDate desc'
        } 
        else {
            endpoint = "/odata/crm/lead/interaction";
            
            if (leadId !== undefined && leadId > 0){
                endpoint += "?$filter=leadId eq "+leadId+"&$orderby=createdon desc";
            }

            return endpoint;
        }
    }
    
    return (
        <>
            <PageContainer endpoint={buildListEndpoint()} pageType={SystemPageType.List} title={t("adminCenter:crmInteractionList:title")!} cardRef={cardRef} backAllowed={!props.isModal!} showAllowed={!props.isModal!}>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name={t("adminCenter:crmInteractionList:fields:leadId")!} fieldName="leadId" minWidth={50} maxWidth={50} />
                    <ListHeaderEntry name={t("adminCenter:crmInteractionList:fields:type")!} fieldName="type" minWidth={90} maxWidth={90} onRender={(item: any, index, column) => {
                        if (item.type === 0)
                            return t("adminCenter:crmInteractionList:fields:type0")!
                        else if (item.type === 1)
                            return t("adminCenter:crmInteractionList:fields:type1")!
                        else if (item.type === 2)
                            return t("adminCenter:crmInteractionList:fields:type2")!
                        else if (item.type === 3)
                            return t("adminCenter:crmInteractionList:fields:type3")!
                        else if (item.type === 4)
                            return t("adminCenter:crmInteractionList:fields:type4")!
                        else if (item.type === 5)
                            return t("adminCenter:crmInteractionList:fields:type5")!
                    }} />
                    <ListHeaderEntry name={t("adminCenter:crmInteractionList:fields:title")!} fieldName="title" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry name={t("adminCenter:crmInteractionList:fields:description")!} fieldName="description" minWidth={700} maxWidth={700} onRender={(item: any, index, column) => {
                        let html: string = item.description || "";
                        if (html === undefined)
                            html = "";
                            
                        return html.replace(/(<([^>]+)>)/ig, '').substring(0,200)+"...";
                    }} />
                    <ListHeaderEntry name={t("adminCenter:crmInteractionList:fields:crmReferenceStartDate")!} fieldName="crmReferenceStartDate" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry name={t("adminCenter:crmInteractionList:fields:crmReferenceEndDate")!} fieldName="crmReferenceEndDate" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry name={t("adminCenter:crmInteractionList:fields:interactionSource")!} fieldName="interactionSource" minWidth={90} maxWidth={90} onRender={(item: any, index, column) => {
                        if (item.interactionSource === 0)
                            return t("adminCenter:crmInteractionList:fields:interactionSource0")!
                        else if (item.interactionSource === 1)
                            return t("adminCenter:crmInteractionList:fields:interactionSource1")!
                    }} />
                    <ListHeaderEntry name={t("adminCenter:crmInteractionList:fields:createdOn")!} fieldName="createdOn" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry name={t("adminCenter:crmInteractionList:fields:percentComplete")!} fieldName="percentComplete" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry name={t("adminCenter:crmInteractionList:fields:durationMinutes")!} fieldName="durationMinutes" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry name={t("adminCenter:crmInteractionList:fields:phoneNumber")!} fieldName="phoneNumber" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry name={t("adminCenter:crmInteractionList:fields:userDisplayName")!} fieldName="userDisplayName" minWidth={150} maxWidth={150} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminCrmLeadInteractionCard ref={cardRef} />
        </>
    );
}