import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminSalesInvoiceHeaderCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda fattura"} endpoint="odata/sales/invoice" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="id" type={SystemFieldType.Number} label="Id" readonly />
                <CardField name="documentNo" type={SystemFieldType.String} label="Numero Documento" readonly />
                <CardField name="erpSyncStatus" type={SystemFieldType.Option} label="ERP Sync Status" cmbOptions={[
                    {key: 0, text: "Waiting"},
                    {key: 10, text: "In Progress"},
                    {key: 20, text: "Sent"},
                    {key: 30, text: "Error"},
                    {key: 40, text: "Posponed"},
                ]} />
                <CardField name="erpSyncError" type={SystemFieldType.String} label="Erp Sync Error" readonly />
            </CardGroup>
        </PageContainer>
    );
});