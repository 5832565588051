import { useRef } from "react";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { SystemOperation, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminNotificationTemplateVariableCard } from "../AdminNotificationTemplateVariableCard/AdminNotificationTemplateVariableCard";

export const AdminNotificationTemplateVariableList : React.FC = () => {
    const SetupCode = HttpHelper.getParameter("setupCode");
    const SetupType = HttpHelper.getParameter("setupType");
    const TemplateCode = HttpHelper.getParameter("templateCode");
    const cardRef = useRef<PageContainerBase>(null);
    
    const buildListEndpoint = () :string => {
        let endpoint = "/odata/notification/template/variable";

        if(((TemplateCode !== null) && (TemplateCode !== "")) && (SetupCode !== null) && (SetupCode !== ""))
            endpoint += "?$filter=templateCode eq '" + TemplateCode+ "' and setupCode eq '" + SetupCode + "'";

        //setto i parametri parent

        return endpoint
    }

    const setParentParameters = ()=>{
        cardRef.current?.setRecord({
            setupCode: SetupCode,
            setupType: SetupType,
            templateCode: TemplateCode
        });
        
        cardRef.current?.openPage(SystemOperation.Insert);
    }

    return (
        <>
            <PageContainer onNewRecord={setParentParameters} endpoint={buildListEndpoint()} pageType={SystemPageType.List} title={"Variabili template notifiche"} cardRef={cardRef} backAllowed insertAllowed updateAllowed deleteAllowed>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Nome variabile" fieldName="variableName" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Valore costante" fieldName="constantValue" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Appendi costante" fieldName="appendConstant" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Appendi prima" fieldName="appendBefore" minWidth={100} maxWidth={200} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminNotificationTemplateVariableCard ref={cardRef} />
        </>
    );
}