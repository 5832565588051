import { DetailsList, IColumn, MarqueeSelection, mergeStyles, SelectionMode, Stack } from "@fluentui/react"
import { Doughnut, Line } from "react-chartjs-2"
import { ActionBar, ChildrenWrapper, ContentWrapper, LeftSideWrapper,QualifiedAnswerComponent,RightSideWrapper, UpdateLeadComponent } from "./LeadSurveyComponent"
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale, CategoryScale, PointElement, LineElement, Title } from 'chart.js';
import { CommonActionBar } from "../../CommonActionBar/CommonActionBar";

const RightSideWrapperClassName = mergeStyles([{
  width: "80%"
}])

const ChildrenRightContentClassName = mergeStyles([{
  width: "calc(100% - 51%)",
  height:"calc(100vh - 480px)",
  paddingLeft: '1em',
  paddingBottom: '1em',
  paddingRight: '1em',
  overflow:'auto',
  borderRadius: '5px',
  backgroundColor: '#f7f7f7',
  border: '1px solid #ebebeb',
  boxShadow: '0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%)'
}])

const ChildrenLeftContentClassName = mergeStyles([{
  width: "calc(100% - 50%)",
  marginRight: '1%',
  paddingLeft: '1em',
  paddingBottom: '1em',
  paddingRight: '1em',
  paddingTop: '1em',
  borderRadius: '5px',
  backgroundColor: '#f7f7f7',
  border: '1px solid #ebebeb',
  boxShadow: '0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%)'
}])

const ChildrenBottomContentClassName = mergeStyles([{
  width: "calc(100%)",
  paddingLeft: '1em',
  paddingBottom: '1em',
  paddingRight: '1em',
  paddingTop: '1em'
}])

const ChildrenTopContainerClassName = mergeStyles([{
  height:"auto",
}])

export const LeadSurveyList : React.FC = () => {
    //#region Dati di prova
    ChartJS.register(ArcElement, Tooltip, Legend);
    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend
    );
    const columns:IColumn[] = [
        { key: 'column1', name: 'Name', fieldName: 'name', minWidth: 100, maxWidth: 200, isResizable: true }
    ]   

    const Items = [{
        key: 1,
        name: 'Item ' + 1,
        value: "1",
      }
    ] 


    const data = {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
          {
            label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
          },
        ],
      };
      
      
    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    const lineData = {
        labels,
        datasets: [
          {
            label: 'Dataset 1',
            data: [12, 19, 3, 5, 2, 3],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
          {
            label: 'Dataset 2',
            data: [15, 19, 13, 35, 52, 13],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      }

    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          },
          title: {
            display: true,
            text: 'Chart.js Line Chart',
          },
        },
      };
    //#endregion
    


  
    return (
        <ContentWrapper>
            <CommonActionBar items={[
              {
                key: 'Save',
                text: 'Salva',
                iconProps: {
                  iconName: 'Save'
                },
                onClick: () => {
                  alert('s1fds54f15');
                }
              }
            ]} />
            <ChildrenWrapper>
                <LeftSideWrapper>
                    <DetailsList
                        styles={
                          {
                            root: {
                              width: "100%",
                              overflow:'auto'
                            }
                          }}
                        items={Items}
                        columns={columns}
                        isHeaderVisible={false}
                        selectionMode={SelectionMode.single}
                    />
                </LeftSideWrapper>
                <RightSideWrapper>
                  <Stack className={RightSideWrapperClassName}>
                    <Stack horizontal wrap horizontalAlign="space-around" className={ChildrenTopContainerClassName}>
                      <Stack.Item className={ChildrenLeftContentClassName}>
                        <UpdateLeadComponent />
                      </Stack.Item>
                      <Stack.Item className={ChildrenRightContentClassName}>
                      <QualifiedAnswerComponent title="TESTO DI PROVA" answers={[<p>Risposta 1</p>,<p>Risposta 2</p>,<p>Risposta 3</p>]} onNewAnswer={()=>{}} />,<QualifiedAnswerComponent title="TESTO DI PROVA" answers={[<p>Risposta 1</p>,<p>Risposta 2</p>,<p>Risposta 3</p>]} onNewAnswer={()=>{}} />
                      </Stack.Item>
                    </Stack>
                    <Stack horizontal wrap horizontalAlign="space-around">
                      <Stack.Item className={ChildrenBottomContentClassName}> 
                        <Stack style={{marginTop:10}} horizontal horizontalAlign="space-evenly" wrap>
                            <Stack.Item>
                                <Line style={{height:240}} options={options} data={lineData} />
                            </Stack.Item>
                            <Stack.Item>
                                <Doughnut style={{height:140}} data={data} />
                            </Stack.Item>
                        </Stack>
                      </Stack.Item>
                    </Stack>
                  </Stack>
                </RightSideWrapper>
            </ChildrenWrapper>
        </ContentWrapper>
    )
}