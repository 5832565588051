import React, { useRef } from "react";
import { useNavigate } from "react-router";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminDashboardLinkCard } from "../AdminDashboardLinkCard/AdminDashboardLinkCard";

export const AdminDashboardLinkList : React.FC = () => {
    const cardRef = useRef<PageContainerBase>(null);
    const navigate = useNavigate();

    return (
        <>
            <PageContainer endpoint="/odata/dashboard/link?$orderby=order" pageType={SystemPageType.List} title="Lista link dashboard" backAllowed insertAllowed deleteAllowed updateAllowed cardRef={cardRef}>               
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="SystemCaption" label="Traduzioni" iconName="Globe2" runOnRec onClick={(rec:any)=>{
                            navigate('/app/admin/related/systemCaption?sourceId='+rec.systemId+"&sourceEntity=DashboardLink")
                        }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>                               
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Codice" fieldName="code" minWidth={150} maxWidth={250}  />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={150} maxWidth={250}  />
                    <ListHeaderEntry name="Tooltip" fieldName="toolTipText" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Ordinamento" fieldName="order" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Attivo" fieldName="active" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Tipologia utente" fieldName="forUserType" minWidth={150} maxWidth={250} onRender={(item: any, index, column) => {
                        if (item.forUserType === 0)
                            return "Utente Admin"
                        else if (item.forUserType === 1)
                            return "Utente Normale"
                        else if (item.forUserType === 2)
                            return "Entrambi"
                    }} />
                    <ListHeaderEntry name="Visibile per" fieldName="visibleFor" minWidth={150} maxWidth={250} onRender={(item: any, index, column) => {
                        if (item.visibleFor === 0)
                            return "Lead"
                        else if (item.visibleFor === 1)
                            return "Customer"
                        else if (item.visibleFor === 2)
                            return "Lead e Customer"
                    }} />
                    <ListHeaderEntry name="Codice gruppo" fieldName="groupCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Descrizione gruppo" fieldName="groupDescription" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Nome icona" fieldName="iconName" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Tipologia link" fieldName="linkType" minWidth={150} maxWidth={250} onRender={(item: any, index, column) => {
                        if (item.linkType === 0)
                            return "Link esterno"
                        else if (item.linkType === 1)
                            return "Link interno"
                        else if (item.linkType === 2)
                            return "Apertura pagina"
                        else if (item.linkType === 3)
                            return "Mail to"
                    }} />
                    <ListHeaderEntry name="Url link" fieldName="linkUrl" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminDashboardLinkCard ref={cardRef} />
        </>
    );
}