import { Checkbox, IStyle, Label, mergeStyles, Pivot, PivotItem, PrimaryButton, Stack, TextField } from "@fluentui/react"
import { FileASPXIcon } from "@fluentui/react-icons-mdl2"
import React from "react"
import { CSSProperties } from "styled-components"
import { SystemFieldType } from "../../../../Model/SystemModels"
import { CardField } from "../../Template/Card/CardField"

//#region ContentWrapper

const ContentWrapperClassName = mergeStyles([{
    width: "98%",
    height:"auto",
    margin: '0 auto',
    marginTop: '10px',
    backgroundColor: 'rgb(255, 255, 255)',
    paddingLeft: '1em',
    paddingBottom: '1em',
    paddingRight: '1em',
    paddingTop: '1em',
    boxShadow: 'rgb(34 36 38 / 15%) 0px 1px 1px 1px',
    border:'none'
}])

export const ContentWrapper : React.FC<{}> = (props) => {
    
    return (
        <Stack verticalFill verticalAlign="space-around" className={ContentWrapperClassName}>
            {props.children}
        </Stack>
    )
}

//#endregion

//#region ChildrenWrapper

const ChildrenWrapperClassName = mergeStyles([{
    width: "100%",
    height: "100%",
    marginTop: '10px !important',
    border:'none'
}])

export const ChildrenWrapper : React.FC<{}> = (props) => {
    
    return (
        <Stack horizontal wrap horizontalAlign="space-between" className={ChildrenWrapperClassName}>
            {props.children}
        </Stack>
    )
}

//#endregion

//#region LeftSideWrapper

const LeftSideWrapperClassName = mergeStyles([{
    width: "18%",
    backgroundColor: 'rgb(255, 255, 255)',
    paddingLeft: '1em',
    paddingBottom: '1em',
    paddingRight: '1em',
    paddingTop: '1em',
    boxShadow: '0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%)',
    overflow:'auto',
    border: '1px solid #ebebeb',
}])

export const LeftSideWrapper : React.FC<{}> = (props) => {
    
    return (
        <Stack className={LeftSideWrapperClassName}>
            {props.children}
        </Stack>
    )
}

//#endregion

//#region RightSideWrapper

export const RightSideWrapper : React.FC<{}> = (props) => {
    
    return (
        <>
            {props.children}
        </>    
    );
}

//#endregion

//#region ActionBar

const ActionBarClassName = mergeStyles([{
    width: "100%",
    paddingBottom: '1em',
    paddingTop: '1em'
}])

export interface ICustActionBarProps {
    onClick: () => void,
}

export const ActionBar : React.FC<ICustActionBarProps> = (props:ICustActionBarProps) => {
    
    return (
        <Stack className={ActionBarClassName}>
                <PrimaryButton 
                    styles={{
                        root:{
                            backgroundColor:"#008089 !important",
                            borderColor:"#008089 !important"
                        },
                        rootHovered:{
                            backgroundColor: 'rgb(1, 93, 99) !important',
                            borderColor: 'rgb(1, 93, 99) !important'
                        }
                    }}  
                    text="Salva"
                    onClick={props.onClick} 
                />
        </Stack>
    )
}

//#endregion

//#region UpdateLeadComponent

export const UpdateLeadComponent : React.FC<{}> = (props) => {
    
    return (
        <Pivot 
            aria-label="leadPivotExample" 
            overflowBehavior={'menu'}
            overflowAriaLabel="more items"
            styles={{
                itemContainer:{
                    minWidth: 350
                }
            }}
        >
            <PivotItem headerText="Generale">
                <Stack style={{
                    minHeight:"80px",
                    height: 'calc(23vh)',
                    marginTop: 20,
                    maxHeight:"calc(23vh)",
                    width: '100%',
                    overflow: 'auto'
                }}>
                    <Row>
                        <Column styles={{
                            width: 'calc(48% - 10px)',
                            margin: 5,
                            minWidth: 300
                        }}>
                            <TextField label="Azienda" type={"text"} onChange={()=>{}} name="companyName" />
                        </Column>
                        <Column styles={{
                            width: 'calc(48% - 10px)',
                            margin: 5,
                            minWidth: 300
                        }}>
                            <TextField label="E-Mail" type={"text"} onChange={()=>{}} name="email" />
                        </Column>
                    </Row>
                    <Row>
                        <Column styles={{
                            width: 'calc(48% - 10px)',
                            margin: 5
                        }}>
                            <TextField label="Azienda" type={"text"} onChange={()=>{}} name="companyName" />
                        </Column>
                        <Column styles={{
                            width: 'calc(48% - 10px)',
                            margin: 5
                        }}>
                            <TextField label="E-Mail" type={"text"} onChange={()=>{}} name="email" />
                        </Column>
                    </Row>
                    <Row>
                        <Column styles={{
                            width: 'calc(48% - 10px)',
                            margin: 5
                        }}>
                            <TextField label="Azienda" type={"text"} onChange={()=>{}} name="companyName" />
                        </Column>
                        <Column styles={{
                            width: 'calc(48% - 10px)',
                            margin: 5
                        }}>
                            <TextField label="E-Mail" type={"text"} onChange={()=>{}} name="email" />
                        </Column>
                    </Row>
                    <Row>
                        <Column styles={{
                            width: 'calc(48% - 10px)',
                            margin: 5
                        }}>
                            <TextField label="Azienda" type={"text"} onChange={()=>{}} name="companyName" />
                        </Column>
                        <Column styles={{
                            width: 'calc(48% - 10px)',
                            margin: 5
                        }}>
                            <TextField label="E-Mail" type={"text"} onChange={()=>{}} name="email" />
                        </Column>
                    </Row>
                </Stack>
            </PivotItem>
            <PivotItem headerText="Indirizzo">
                <Stack horizontal wrap disableShrink horizontalAlign="space-around" tokens={{
                    childrenGap: 10,
                    padding: 5
                }}>
                    <TextField label="Indirizzo" type={"text"} onChange={()=>{}} name="address" />
                    <TextField label="C.A.P." type={"text"} onChange={()=>{}} name="zipCode" />
                    <TextField label="Città" type={"text"} onChange={()=>{}} name="city" />
                    <TextField label="Paese" type={"text"} onChange={()=>{}} name="country" />
                    <TextField label="Provincia" type={"text"} onChange={()=>{}} name="county" />
                </Stack>
            </PivotItem>
            <PivotItem headerText="Qualifica">
                <Stack horizontal wrap disableShrink horizontalAlign="space-around" tokens={{
                    childrenGap: 10,
                    padding: 5
                }}>
                    {/* <TextField label="Sales Header Template" type={"text"} onChange={()=>{}} name="salesHeaderTemplateId" /> */}
                    <TextField label="Origine" type={"text"} onChange={()=>{}} name="origin" />
                    <TextField label="Codice campagna" type={"text"} onChange={()=>{}} name="campaignCode" />
                    <TextField label="Nome Origine" type={"text"} onChange={()=>{}} name="originName" />
                    <TextField label="Numero utenti full" type={"text"} onChange={()=>{}} name="full_Users_No" />
                    <TextField label="Numero utenti limited" type={"text"} onChange={()=>{}} name="limited_Users_No" />
                    <TextField label="Numero società" type={"text"} onChange={()=>{}} name="noOfCompany" />
                    <TextField label="Note" type={"text"} onChange={()=>{}} name="note" />
                    <Checkbox styles={{root:{paddingTop:35}}} label="Qualifica effettuata" onChange={()=>{}} name="marketingQualificationCarriedOut" />
                </Stack>
            </PivotItem>
            <PivotItem headerText="Interazione">
                <Stack horizontal wrap disableShrink horizontalAlign="space-around" tokens={{
                    childrenGap: 10,
                    padding: 5
                }}>
                    <Checkbox styles={{root:{paddingTop:35}}} label="Demo prenotata" onChange={()=>{}} name="demoBooked" />
                    <Checkbox styles={{root:{paddingTop:35}}} label="Data demo" onChange={()=>{}} name="demoDate" />
                    <Checkbox styles={{root:{paddingTop:35}}} label="Demo effettuata" onChange={()=>{}} name="demoCarriedOut" />
                    <TextField label="Demo effettuata da" type={"text"} onChange={()=>{}} name="demoCarriedOutBy" />
                    <TextField label="Gap non gestiti" type={"text"} onChange={()=>{}} name="notManagedGAPEmerged" />
                    <TextField label="Stato negoziazione" type={"text"} onChange={()=>{}} name="negotiationState" />
                    <TextField label="Rating" type={"text"} onChange={()=>{}} name="rating" />
                </Stack>
            </PivotItem>
            <PivotItem headerText="Offerta">
                <Stack horizontal wrap disableShrink horizontalAlign="space-around" tokens={{
                    childrenGap: 10,
                    padding: 5
                }}>
                    <TextField label="Link offerta" type={"text"} onChange={()=>{}} name="offerLink" />
                    <Checkbox styles={{root:{paddingTop:35}}} label="Offerta inviata" onChange={()=>{}} name="offerSent" />
                    <TextField label="Codice promo" type={"text"} onChange={()=>{}} name="promoCode" />
                    <TextField label="Codice mail template" type={"text"} onChange={()=>{}} name="mailTemplateCode" />
                </Stack>
            </PivotItem>
            <PivotItem headerText="Esito finale">
                <Stack horizontal wrap disableShrink horizontalAlign="space-around" tokens={{
                    childrenGap: 10,
                    padding: 5
                }}>
                    <TextField label="Esito finale" type={"text"} onChange={()=>{}} name="finalOutcome" />
                    <TextField label="Causa perdita" type={"text"} onChange={()=>{}} name="lossCauseCode" />
                    <TextField label="Causa squalifica" type={"text"} onChange={()=>{}} name="crmStatusCode" />
                </Stack>
            </PivotItem>
        </Pivot>
    )
}

export interface IPivotItemProps {
    Label: string,
    Name: string,
    Type:SystemFieldType,
    onChange: (fieldName:any,newValue: any) => void
}

const PivotFieldClassName = mergeStyles([{
    marginRight:10
}])

export const PivotField : React.FC<IPivotItemProps> = (props:IPivotItemProps) => {
    let field:JSX.Element = <></>;
    switch (props.Type) {
        case SystemFieldType.String:
            field = <TextField className={PivotFieldClassName} type="text" label={props.Label} name={props.Name} onChange={props.onChange}  />
            break;
        case SystemFieldType.Number:
            break;
        case SystemFieldType.Boolean:
            break;
        case SystemFieldType.Option:
            break;
    }

    return field
}

//#endregion

//#region Row
export const Row: React.FC<{}> = (props) => {
    return (
        <Stack.Item style={{
            width: '100%',
        }}>
            <Stack horizontal key="dsfsd" style={{
                width: '100%',
            }} wrap>
                {props.children}
            </Stack>
        </Stack.Item>
    );
}
//#endregion

//#region Column
export interface IColumnProps {
    styles: CSSProperties;
}

export const Column: React.FC<IColumnProps> = (props) => {
    return (
        <Stack.Item style={props.styles}>
            {props.children}
        </Stack.Item>
    );
}
//#endregion

//#region QualifiedAnswerComponent

const QualifiedAnswerClassName = mergeStyles([{
    width: "100%",
    height: "auto   ",
    backgroundColor: 'rgb(255, 255, 255)',
    paddingLeft: '1em',
    paddingRight: '1em',
    paddingTop: '1em',
    marginTop: '1em',
    border:'1px solid rgb(34, 36, 38)',
    overflow:'auto'
}])

const QualifiedAnswerTitleClassName = mergeStyles([{
    fontSize: '1.2em',
    fontWeight: '700',
    color:"#008089"
}])

const QualifiedAnswerTitleContainerClassName = mergeStyles([{
    borderBottom: '1px solid rgb(34, 36, 38)',
    marginBottom: '1em'
}])

const QualifiedAnswerButtonClassName = mergeStyles([{
    backgroundColor:"transparent !important",
    border:"none !important",
    color:"black !important",
    fontWeight:"bold !important",
}])

export interface IQualifiedAnswerProps {
    title: string,
    answers:JSX.Element[],
    onNewAnswer: () => void
}

export const QualifiedAnswerComponent : React.FC<IQualifiedAnswerProps> = (props: IQualifiedAnswerProps) => {
    
    return (
        <Stack verticalAlign="start" verticalFill className={QualifiedAnswerClassName}>
            <Stack horizontal horizontalAlign="space-between" verticalAlign="center" className={QualifiedAnswerTitleContainerClassName}>
                <Stack.Item className={QualifiedAnswerTitleClassName}>
                    {props.title}
                </Stack.Item>
                <Stack.Item>
                    <PrimaryButton className={QualifiedAnswerButtonClassName} iconProps={{iconName:"Add"}} />
                </Stack.Item>
            </Stack>
            {props.answers}
        </Stack>
    )
}

//#endregion

