import { useContext, useEffect, useMemo, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks"
import { FontIcon, Icon, Label, MessageBar, MessageBarButton, MessageBarType, Stack, Text, TooltipHost } from "@fluentui/react";

import styles from "./Answer.module.css";

import { AskResponse, ChatMessage, Citation, Conversation } from "../../../../ApplicationCode/Common/Chatbot/api";
import { parseAnswer } from "./AnswerParser";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from 'remark-supersub';
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { StyleSheetManager } from "styled-components";
import { t } from "i18next";
import { AppStateContext } from "../../../../Core/State/ChatbotAppProvider";

interface Props {
    answer: AskResponse;
    onCitationClicked: (citedDocument: Citation) => void;
    onFollowUpQuestionClicked?: (question: string) => void;
    onOpenSupportRequestClick: () => void;
    isLastIteraction: boolean;
    showFeedbackIcons?: boolean;
    feedbackMessage?: ChatMessage;
    followupQuestions?: String[];
}


export const Answer = ({
    answer,
    onCitationClicked,
    onOpenSupportRequestClick,
    onFollowUpQuestionClicked,
    isLastIteraction,
    showFeedbackIcons,
    feedbackMessage,
    followupQuestions
}: Props) => {
    const appStateContext = useContext(AppStateContext);
    const [isRefAccordionOpen, { toggle: toggleIsRefAccordionOpen }] = useBoolean(false);
    const [isRefFQAccordionOpen, { toggle: toggleIsRefFQAccordionOpen }] = useBoolean(true);
    const [isLikeOver, setIsLikeOver] = useState<boolean>(false);
    const [isDisLikeOver, setIsDisLikeOver] = useState<boolean>(false);
    const [isPositiveFeedbackClicked, setIsPositiveFeedbackClicked] = useState<boolean>(false);
    const [isNegativeFeedbackClicked, setIsNegativeFeedbackClicked] = useState<boolean>(false);
    const [showSupportPanel, setShowSupportPanel] = useState<boolean>(false);

    const embedded: boolean = (HttpHelper.getParameter("embedded") === "true");
    
    const filePathTruncationLimit = 50;

    const parsedAnswer = useMemo(() => parseAnswer(answer), [answer]);
    const [chevronIsExpanded, setChevronIsExpanded] = useState(isRefAccordionOpen);
    const [chevronFQIsExpanded, setChevronFQIsExpanded] = useState(isRefFQAccordionOpen);

    const getMarker = () => {
        var test = " ";
        test += "```callout";
        test += "        _Cursor_";
        test += "```";
        return test;
    }

    const handleChevronClick = () => {
        setChevronIsExpanded(!chevronIsExpanded);
        toggleIsRefAccordionOpen();
      };
      const handleFQChevronClick = () => {
        setChevronFQIsExpanded(!chevronFQIsExpanded);
        toggleIsRefFQAccordionOpen();
      };

    useEffect(() => {
        setChevronIsExpanded(isRefAccordionOpen);
    }, [isRefAccordionOpen]);

    const createCitationFilepath = (citation: Citation, index: number, truncate: boolean = false) => {
        let citationFilename = "";

        /*if (citation.filepath && citation.chunk_id) {
            if (truncate && citation.filepath.length > filePathTruncationLimit) {
                const citationLength = citation.filepath.length;
                citationFilename = `${citation.filepath.substring(0, 20)}...${citation.filepath.substring(citationLength -20)} - ${t("chatbot:answer:citationPart")} ${parseInt(citation.chunk_id) + 1}`;
            }
            else {
                citationFilename = `${citation.filepath} - ${t("chatbot:answer:citationPart")} ${parseInt(citation.chunk_id) + 1}`;
            }
        }
        else {
            citationFilename = `${t("chatbot:answer:citationCaption")} ${index}`;
        }
        return citationFilename;*/
        return citation.title!;
    }

    const handleFeedbackClick = (isPositive: boolean) => {
        let conversation: Conversation | null | undefined;
        if((appStateContext?.state?.currentChat !== null) && (appStateContext?.state?.currentChat !== undefined))
            conversation = appStateContext?.state?.currentChat;
        
        let currentMessage = feedbackMessage;
        currentMessage!.positiveFeedback = isPositive;
        currentMessage!.negativeFeedback! = !isPositive;
                                        
        appStateContext?.dispatch({ type: 'UPDATE_FEEDBACK', convId: conversation?.id!, message: currentMessage!});

        if(isPositive){
            setIsPositiveFeedbackClicked(true);
            setIsNegativeFeedbackClicked(false);
        }
        else{
            setIsPositiveFeedbackClicked(false);
            setIsNegativeFeedbackClicked(true);
        }
    }



    return (
        <>
            <Stack className={styles.answerContainer} tabIndex={0}>
                <Stack.Item>
                    <ReactMarkdown
                        linkTarget="_blank" 
                        remarkPlugins={[remarkGfm, supersub]}
                        children={parsedAnswer.markdownFormatText}
                        className={styles.answerText}
                    />
                </Stack.Item>
                <Stack verticalFill verticalAlign="space-between" wrap className={styles.answerFooter} tokens={{
                    childrenGap: 5
                }}>
                    {
                        followupQuestions != undefined && followupQuestions.length > 0 ?
                        (
                            <Stack.Item
                                onKeyDown={e => e.key === "Enter" || e.key === " " ? toggleIsRefFQAccordionOpen() : null}
                            >
                                <Stack style={{width: "100%"}} >
                                    <Stack horizontal horizontalAlign='start' verticalAlign='center'>
                                        <Text
                                            className={styles.accordionTitle}
                                            onClick={handleFQChevronClick}
                                            aria-label="Open references"
                                            tabIndex={0}
                                            role="button"
                                        >
                                        <span>{followupQuestions.length + t("chatbot:answer:followupQuestions")}</span>
                                        </Text>
                                        <FontIcon className={styles.accordionIcon}
                                        onClick={handleFQChevronClick} iconName={chevronFQIsExpanded ? 'ChevronDown' : 'ChevronRight'}
                                        />
                                    </Stack>
                                    
                                </Stack>
                            </Stack.Item>
                        )
                        :
                        <>
                        </>
                    }
                    {chevronFQIsExpanded && followupQuestions != undefined &&
                        <div style={{ marginTop: 8, paddingBottom: 10, display: "flex", flexFlow: "row wrap", maxHeight: "150px", overflow:"auto", gap: "4px" }}>
                            {followupQuestions.map((followupQuestion, idx) => {
                                    return (
                                        <span 
                                            tabIndex={0} 
                                            role="link" 
                                            key={idx} 
                                            onClick={() => {
                                                onFollowUpQuestionClicked!(followupQuestion.toString());
                                            }} 
                                            style={{
                                                marginLeft: 10,
                                                fontStyle: "normal",
                                                fontWeight: 600,
                                                fontSize: 12,
                                                color: "#115EA3",
                                                cursor: "pointer"    ,
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                gap: 4
                                            }}
                                        >
                                            {followupQuestion}
                                        </span>);
                            })}
                        </div>
                    }
                    {(!embedded) && !!parsedAnswer.citations.filter(e => e.title).length && (
                        <Stack.Item
                            onKeyDown={e => e.key === "Enter" || e.key === " " ? toggleIsRefAccordionOpen() : null}
                        >
                            <Stack style={{width: "100%"}} >
                                <Stack horizontal horizontalAlign='start' verticalAlign='center'>
                                    <Text
                                        className={styles.accordionTitle}
                                        onClick={toggleIsRefAccordionOpen}
                                        aria-label="Open references"
                                        tabIndex={0}
                                        role="button"
                                    >
                                    <span>{parsedAnswer.citations.filter(e => e.title).length > 1 ? parsedAnswer.citations.filter(e => e.title).length + t("chatbot:answer:citationCount") : t("chatbot:answer:citationOne")}</span>
                                    </Text>
                                    <FontIcon className={styles.accordionIcon}
                                    onClick={handleChevronClick} iconName={chevronIsExpanded ? 'ChevronDown' : 'ChevronRight'}
                                    />
                                </Stack>
                                
                            </Stack>
                        </Stack.Item>
                    )}
                    {chevronIsExpanded && 
                        <div style={{ marginTop: 8, paddingBottom: 10, display: "flex", flexFlow: "row wrap", maxHeight: "150px", overflow:"auto", gap: "4px" }}>
                            {parsedAnswer.citations.map((citation, idx) => {
                                if (citation.title)
                                    return (
                                        <span 
                                            title={createCitationFilepath(citation, ++idx)} 
                                            tabIndex={0} 
                                            role="link" 
                                            key={idx} 
                                            onClick={() => {
                                                if (citation.url)
                                                    window.open(citation.url)
                                            }} 
                                            onKeyDown={e => e.key === "Enter" || e.key === " " ? onCitationClicked(citation) : null}
                                            className={styles.citationContainer}
                                            aria-label={createCitationFilepath(citation, idx)}
                                        >
                                            {citation.title!}
                                        </span>);
                                    return <></>;
                            })}
                        </div>
                    }
                    <Stack.Item className={styles.answerDisclaimerContainer}>
                        <div style={{
                            display: 'flex',
                            flexFlow: 'wrap'
                        }}>
                            <span className={styles.answerDisclaimer}>
                                {t("chatbot:answer:aiDisclamer")!}
                            </span>
                            {
                                showFeedbackIcons ?
                                <span className={styles.answerFeedback}>
                                    {
                                        isLikeOver || isPositiveFeedbackClicked ?
                                        <TooltipHost content={t("chatbot:answer:like")!}>
                                            <Icon iconName="LikeSolid" onMouseLeave={() => setIsLikeOver(false)} className={styles.answerFeedbackIcon} onClick={() => {
                                                handleFeedbackClick(true);
                                            }} />    
                                        </TooltipHost>
                                        :
                                        <TooltipHost content={t("chatbot:answer:like")!}>
                                            <Icon iconName="Like" onMouseEnter={() => setIsLikeOver(true)} className={styles.answerFeedbackIcon} />
                                        </TooltipHost>
                                    }
                                    {
                                        isDisLikeOver || isNegativeFeedbackClicked ?
                                        <TooltipHost content={t("chatbot:answer:dislike")!}>
                                            <Icon iconName="DislikeSolid" onMouseLeave={() => setIsDisLikeOver(false)} className={styles.answerFeedbackIcon} onClick={() => {
                                                handleFeedbackClick(false);
                                            }} />    
                                        </TooltipHost>
                                        :
                                        <TooltipHost content={t("chatbot:answer:dislike")!}>
                                            <Icon iconName="Dislike" onMouseEnter={() => setIsDisLikeOver(true)} className={styles.answerFeedbackIcon} />
                                        </TooltipHost>
                                    }
                                </span>
                                :
                                <></>
                            }
                        </div>
                    </Stack.Item>
                </Stack>
            </Stack>
        </>
    );
};

interface WelcomeMessageProps {
    isSupportInProgress: boolean;
    onLinkClick: () => void;
}

export const WelcomeMessage: React.FC<WelcomeMessageProps> = (prop) => {
    const getContext = () : string => {
        let context = HttpHelper.getParameter("context");
        if((context === "") || (context === null))
            context = "SoSmart";
        
        
        if (window.location.origin === "https://eosapps.so-smart.it")
            return "Eos";

        return context;
    }

    return (
        <>
            <Stack className={styles.answerContainer} tabIndex={0}>
                <Stack.Item>
                    <ReactMarkdown
                        components={{
                            a: ({node, ...props}) => <a {...props} href="javascript:void(0);" style={{color:"#000",pointerEvents:prop.isSupportInProgress ? "none" : "auto"}} onMouseOver={(element)=>{element.currentTarget.style.color="gray"}} onMouseLeave={(element)=>{element.currentTarget.style.color="#000"}} onClick={()=>{prop.onLinkClick()}}/>
                        }}
                        remarkPlugins={[remarkGfm, supersub]}
                        children={t("chatbot:chat:welcomeMessage")!}
                        className={styles.answerText}
                    />
                </Stack.Item>
            </Stack>
        </>
    );
};
