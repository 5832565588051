import React, { Children } from "react";
import { AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from "react-accessible-accordion";

export interface ICardGroupProps {
    name: string;
    label: string;
    isOpen: boolean;
    hidden?: boolean;
}

export const CardGroup : React.FC<ICardGroupProps> = (props) => {
    return (
        <AccordionItem uuid={props.name}>
            <AccordionItemHeading>
                <AccordionItemButton>
                    {props.label}
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                {props.children}
            </AccordionItemPanel>
        </AccordionItem>
    )
}