import { IComboBoxOption } from "@fluentui/react";
import { useEffect, useState } from "react";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { SystemCore } from "../../../../Core/System/SystemCore";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { SystemActionCategory, SystemDataLoadingStatus, SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { Loader } from "../../../Common/Loader/Loader";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminCustomerListDialog } from "../../AdminCustomerListDialog/AdminCustomerListDialog";

export const AdminCustomerSubscriptionMatchList : React.FC = () => {
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [loaderMessage, setLoaderMessage] = useState("");
    const [showCustomerDialog, setShowCustomerDialog] = useState(false);
    const [customerCmbOptions, setCustomerCmbOptions] = useState<IComboBoxOption[]>([]);
    const [dialogError, setDialogError] = useState("");
    let listTitle = "";
    
    const adminService = new AdminService();

    useEffect(() => {
        if(dataStatus === SystemDataLoadingStatus.ToLoad){
            buildCustomerCmbOptions();
        }
    }, [dataStatus])

    const buildCustomerCmbOptions = ()=>{
        setLoaderMessage("Stiamo caricando la lista dei clienti, attendere...");
        setDataStatus(SystemDataLoadingStatus.Loading);

        adminService.deleteSubscriptionMatch().then((resp) => {
            adminService.getCustomers().then((resp)=>{
                let options : IComboBoxOption[] = [];
                resp.forEach((item:any)=>{
                    options.push({key: item.id, text: item.companyName});
                })
                setCustomerCmbOptions(options);
                setLoaderMessage("");
                setDataStatus(SystemDataLoadingStatus.Loaded);
            })
            .catch(()=>{
                setLoaderMessage("");
                setDataStatus(SystemDataLoadingStatus.Loaded);
                ToastService.showMessage(MessageType.Error,"Errore nel recupero dei clienti");
            })
        })
        .catch((err) => {
            setLoaderMessage("");
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Error,"Errore nell'inizializzazione della tabella: "+err.message);
        })
    }
    
    const matchCustomerSubscriptions = (customerId: number, customerName: string) => {
        if (customerId !== undefined && customerId > 0){
            setLoaderMessage("Match Sottoscrizioni Cliente "+customerName+" in corso ...");
            setDataStatus(SystemDataLoadingStatus.Loading);

            adminService.matchCustomersSubscriptions(customerId)
                .then(() => {
                    setDataStatus(SystemDataLoadingStatus.Loaded);
                    setLoaderMessage("");
                    setShowCustomerDialog(false);

                    ToastService.showMessage(MessageType.Success, "Match Sottoscrizioni Cliente "+customerName+" completato con successo");
                })
                .catch((err) => {
                    setDataStatus(SystemDataLoadingStatus.Loaded);
                    setLoaderMessage("");
                    setShowCustomerDialog(false);
                    ToastService.showMessage(MessageType.Error, "Errore durante il Match Sottoscrizioni Cliente "+customerName+": " + err.message);
                })
        }
        else
            setDialogError("Devi inserire un Cliente valido");
    }

    const acceptDenySubscriptionMatch = (entryNo: number, accepted: boolean) => {
        setLoaderMessage("Aggiornamento Match in corso ...");
        setDataStatus(SystemDataLoadingStatus.Loading);
        adminService.acceptDenySubscriptionMatch(entryNo, accepted)
            .then(()=>{
                setLoaderMessage("");
                setDataStatus(SystemDataLoadingStatus.Loaded);
                ToastService.showMessage(MessageType.Success,"Aggiornamento Match completato con successo.");
            })
            .catch((jqXHR)=>{
                setLoaderMessage("");
                setDataStatus(SystemDataLoadingStatus.Loaded);
                ToastService.showMessage(MessageType.Error,"Errore durante l'aggiornamento del Match: "+jqXHR.message);
            })
    }

    const processSubscriptionMatch = () => {
        setLoaderMessage("Elaborazione Match in corso ...");
        setDataStatus(SystemDataLoadingStatus.Loading);
        adminService.processSubscriptionMatch()
            .then(()=>{
                setLoaderMessage("");
                setDataStatus(SystemDataLoadingStatus.Loaded);
                ToastService.showMessage(MessageType.Success,"Elaborazione Match completato con successo.");
            })
            .catch((jqXHR)=>{
                setLoaderMessage("");
                setDataStatus(SystemDataLoadingStatus.Loaded);
                ToastService.showMessage(MessageType.Error,"Errore durante l'elaborazione del Match: "+jqXHR.message);
            })
    }

    const deleteSubscriptionMatch = () => {
        setLoaderMessage("Reset Match in corso ...");
        setDataStatus(SystemDataLoadingStatus.Loading);
        adminService.deleteSubscriptionMatch()
            .then(()=>{
                setLoaderMessage("");
                setDataStatus(SystemDataLoadingStatus.Loaded);
                ToastService.showMessage(MessageType.Success,"Reset Match completato con successo.");
            })
            .catch((jqXHR)=>{
                setLoaderMessage("");
                setDataStatus(SystemDataLoadingStatus.Loaded);
                ToastService.showMessage(MessageType.Error,"Errore durante il reset del Match: "+jqXHR.message);
            })
    }
    
    const buildListEndpoint = () : string =>{
        let endpoint = "/odata/subscription/match";
        listTitle = "Lista Match Sottoscrizioni"
   
        return endpoint
    }
    
    if (!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={loaderMessage}></Loader>;
    else
        return (
            <>
                <PageContainer endpoint={buildListEndpoint()} pageType={SystemPageType.List} title={listTitle} backAllowed>
                    <PageDefinitionContainer type={ContainerType.Action}>
                        <ActionArea category={SystemActionCategory.Process}>
                            <ActionEntry name="matchCustomersSubscriptions" label="Effettua Match Sottoscrizioni" iconName="Sync" isPromoted onClick={() => {
                                setLoaderMessage("Match Sottoscrizioni in corso ...");
                                setDataStatus(SystemDataLoadingStatus.Loading);
                                adminService.matchCustomersSubscriptions()
                                    .then(() => {
                                        setDataStatus(SystemDataLoadingStatus.Loaded);
                                        ToastService.showMessage(MessageType.Success, "Match Sottoscrizioni completato con successo");
                                    })
                                    .catch((err) => {
                                        setDataStatus(SystemDataLoadingStatus.Loaded);
                                        ToastService.showMessage(MessageType.Error, "Errore durante il Match Sottoscrizioni: " + err);
                                    })
                            }}>                                
                            </ActionEntry>
                            <ActionEntry name="matchCustomerSubscriptions" label="Effettua Match Sottoscrizioni Cliente" iconName="SchoolDataSyncLogo" isPromoted onClick={() => {                                
                                setShowCustomerDialog(true);
                            }}>                                
                            </ActionEntry>
                            <ActionEntry name="acceptSubscriptionMatch" label="Accetta Match" iconName="Accept" runOnRec isPromoted onClick={(req:any) => {
                                acceptDenySubscriptionMatch(req.entryNo, true);
                            }}>                                
                            </ActionEntry>
                            <ActionEntry name="denySubscriptionMatch" label="Rifiuta Match" iconName="Clear" runOnRec isPromoted onClick={(req:any) => {
                                acceptDenySubscriptionMatch(req.entryNo, false);
                            }}>                                
                            </ActionEntry>
                            <ActionEntry name="processSubscriptionMatch" label="Elabora Match" iconName="Processing" isPromoted onClick={(req:any) => {
                                processSubscriptionMatch();
                            }}>                                
                            </ActionEntry>
                            <ActionEntry name="resetSubscriptionMatch" label="Resetta Match" iconName="Refresh" onClick={(req:any) => {
                                deleteSubscriptionMatch();
                            }}>                                
                            </ActionEntry>
                        </ActionArea>
                    </PageDefinitionContainer>
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry name="Id" fieldName="entryNo" minWidth={30} maxWidth={30} />
                        <ListHeaderEntry name="Id Cliente" fieldName="customerId" minWidth={80} maxWidth={80} />
                        <ListHeaderEntry name="Nome Cliente" fieldName="customerName" minWidth={200} maxWidth={200} />
                        <ListHeaderEntry name="Id Articolo" fieldName="itemId" minWidth={90} maxWidth={90} />
                        <ListHeaderEntry name="Descrizione Articolo" fieldName="itemDescription" minWidth={250} maxWidth={250} />
                        <ListHeaderEntry name="Quantità CSP" fieldName="cspQuantity" minWidth={90} maxWidth={90} />
                        <ListHeaderEntry name="Quantità Sottoscrizione" fieldName="subscriptionQuantity" minWidth={150} maxWidth={150} />
                        <ListHeaderEntry name="Differenza" fieldName="difference" minWidth={90} maxWidth={90} />
                        <ListHeaderEntry name="Tipo Operazione" fieldName="operationType" minWidth={110} maxWidth={110} onRender={(item: any, index, column) => {
                            if (item.operationType === 0)
                            return "Aggiunta"
                            else if (item.operationType === 1)
                            return "Rimozione"
                        }} />
                        <ListHeaderEntry name="Operazione Accettata" fieldName="accepted" minWidth={150} maxWidth={150} />
                        <ListHeaderEntry name="Operazione Processata" fieldName="processed" minWidth={150} maxWidth={150} />
                        <ListHeaderEntry name="Errore" fieldName="error" minWidth={150} maxWidth={150} />
                        <ListHeaderEntry name="Id Sottoscrizione CSP" fieldName="cspSubscriptionId" minWidth={150} maxWidth={150} />
                        <ListHeaderEntry name="Id Sottoscrizione" fieldName="subscriptionId" minWidth={150} maxWidth={150} />
                        <ListHeaderEntry name="Trial" fieldName="isTrial" minWidth={100} maxWidth={100} />
                    </PageDefinitionContainer>
                </PageContainer>
                <AdminCustomerListDialog hidden={showCustomerDialog}
                    title="Scegli il Cliente"
                    subText="Devi inserire il Cliente per poter proseguire"
                    txtLabel="Cliente:"
                    cmbOptions={customerCmbOptions} 
                    fieldType={SystemFieldType.Option}
                    onSuccess={(customerId: number, customerName: string)=>{matchCustomerSubscriptions(customerId, customerName)}}
                    onDismiss={()=>{setShowCustomerDialog(false)}}
                    errorMessage={dialogError} />
            </>
        );
}