import { useRef } from "react";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminSystemJobQueueLogCard } from "./AdminSystemJobQueueLogCard";

export const AdminSystemJobQueueLogList : React.FC = () => {
    const cardRef = useRef<PageContainerBase>(null);
    let listTitle = "Log System Job Queue";

    const buildListEndpoint = () : string => {
        const jobCode = HttpHelper.getParameter('jobCode');
        let endpoint = "/odata/systemjobqueue/log";

        if((jobCode != null) && (jobCode != '')){
            listTitle+=" ("+jobCode+")";
            endpoint+="?$filter=systemJobQueueCode eq \'"+jobCode+"\'&$orderby=startedAt desc";
        }
        else
            endpoint+="?$orderby=startedAt desc"

        return endpoint;
    }
    return (
        <>
            <PageContainer endpoint={buildListEndpoint()} pageType={SystemPageType.List} title={listTitle} backAllowed showAllowed cardRef={cardRef}>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Stato" fieldName="status" minWidth={150} maxWidth={250} onRender={(item: any, index, column) => {
                        if (item.status === 0)
                            return "In Esecuzione"
                        else if (item.status === 1)
                            return "In Errore"
                        else if (item.status === 2)
                            return "Completato"
                    }} />
                    <ListHeaderEntry name="Data Inizio" fieldName="startedAt" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Data Completamento" fieldName="completedAt" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Errore" fieldName="errorMessage" minWidth={100} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminSystemJobQueueLogCard ref={cardRef} />
        </>
    );
}