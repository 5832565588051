import React, {useRef} from "react";
import { SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminAuthorizedApiKeyCard } from "../AdminAuthorizedApiKeyCard/AdminAuthorizedApiKeyCard";

export const AdminAuthorizedApiKeyList : React.FC = () => {
    
    const cardRef = useRef<PageContainerBase>(null);
    
    return (
        <>
            <PageContainer endpoint={'/odata/authorized/api/key'} pageType={SystemPageType.List} title={"Authorized Api Key List"} cardRef={cardRef} backAllowed insertAllowed updateAllowed deleteAllowed>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry fieldName="entryNo" name="Entry No" minWidth={80} maxWidth={100} />
                    <ListHeaderEntry fieldName="description" name="Description" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry fieldName="category" name="Category" minWidth={200} maxWidth={300} onRender={(item: any, index, column)=>{
                        if (item.category === 0)
                            return "Business Central"
                    }} />
                    <ListHeaderEntry fieldName="apiKey" name="Api Key" minWidth={500} maxWidth={600} />
                    <ListHeaderEntry fieldName="revoked" name="Revoked" minWidth={100} maxWidth={200} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminAuthorizedApiKeyCard ref={cardRef} />
        </>
    );
}