import { useRef } from "react";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminPostalCodeCard } from "../AdminPostalCodeCard/AdminPostalCodeCard";

export const AdminPostalCodeList : React.FC = () => {
    const cardRef = useRef<PageContainerBase>(null);

    const buildEndpoint = (): string => {
        const countryCode: string | null = HttpHelper.getParameter('countryCode');

        let endpoint: string = "/odata/PostalCode";
        if((countryCode !== "") && (countryCode !== null)){
            endpoint += `?$filter=countryCode eq '${countryCode}'`;
        }

        return endpoint;
    };
    
    return (
        <>
            <PageContainer endpoint={buildEndpoint()} pageType={SystemPageType.List} title="Codici postali" backAllowed insertAllowed updateAllowed deleteAllowed cardRef={cardRef}>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Codice" fieldName="zipCode" minWidth={250} maxWidth={250} />
                    <ListHeaderEntry name="Città" fieldName="city" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Provincia" fieldName="county" minWidth={250} maxWidth={250} />
                    <ListHeaderEntry name="Cod. paese" fieldName="countryCode" minWidth={250} maxWidth={250} />
                    <ListHeaderEntry name="Cod. territorio" fieldName="territoryCode" minWidth={250} maxWidth={250} />
                    <ListHeaderEntry name="Cod. area" fieldName="areaCode" minWidth={250} maxWidth={250} />
                    <ListHeaderEntry name="Cod. istat" fieldName="istatCode" minWidth={250} maxWidth={250} />
                    <ListHeaderEntry name="Città principale" fieldName="principalCity" minWidth={250} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminPostalCodeCard ref={cardRef} />
        </>
    );
}