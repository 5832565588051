import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { SystemActionCategory, SystemOperation, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";

export const AdminEnvironmentPackageList : React.FC = () => {
    const environmentName = HttpHelper.getParameter("environmentName");
    const customerId = HttpHelper.getParameter("customerId");
    const companyId = HttpHelper.getParameter("companyId");
    const companyName = HttpHelper.getParameter("companyName");

    const adminService = new AdminService();
    const navigate = useNavigate();
    const cardRef = useRef<PageContainerBase>(null);

    const buildEndpoint = () => {
        let endpoint = '/odata/bc/customer/package?$filter=customerId eq ' + customerId + ' and environmentName eq \'' + environmentName + '\'';
        
    }


    return (
        <>
            <PageContainer endpoint={'/odata/bc/customer/package?$filter=customerId eq ' + customerId + ' and environmentName eq \'' + environmentName + '\''} pageType={SystemPageType.List} title={"Pacchetti Rapid Start (" + environmentName + ")"} cardRef={cardRef} backAllowed showAllowed>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Process}>
                    <ActionEntry name="Synchronize" iconName="SyncOccurence" label="Sincronizza" onClick={(req:any)=>{
                            let instance = HttpHelper.buildAxiosInstance();
                            let url = "api/BusinessCentral/customer/" + customerId + "/environment/" + environmentName + "/packages/synchronize";
                            
                            var promise = new Promise((resolve, reject) => {
                                instance.post(url).then((resp) => {
                                    resolve(true);
                                })
                                .catch((resp) => {
                                    reject(false);
                                });
                            });

                            ToastService.showPromiseMessage(promise, "Sincronizzazione in corso", "Sincronizzazione completata", "Sincronizzazione non riuscita");
                        }}>
                        </ActionEntry>
                        <ActionEntry name="CreateAndImport" iconName="BulkUpload" label="Crea e importa (TEMPLATE)" onClick={(req:any)=>{
                            let instance = HttpHelper.buildAxiosInstance();
                            let url = "api/BusinessCentral/customer/" + customerId + "/environment/" + environmentName + "/packages/TEMPLATE_SMRT/create";
                            
                            var promise = new Promise((resolve, reject) => {
                                instance.post(url).then((resp) => {
                                    resolve(true);
                                })
                                .catch((resp) => {
                                    reject(false);
                                });
                            });

                            ToastService.showPromiseMessage(promise, "Creazione pacchetto in corso", "Pacchetto creato e importato", "Errore nell'esecuzione dell'operazione");
                        }}>
                        </ActionEntry>
                        <ActionEntry name="Apply" iconName="Play" label="Applica" runOnRec onClick={(req:any)=>{
                            let instance = HttpHelper.buildAxiosInstance();
                            let url = "api/BusinessCentral/customer/" + customerId + "/environment/" + environmentName + "/packages/" + req.packageId + "/apply";
                            
                            var promise = new Promise((resolve, reject) => {
                                instance.post(url).then((resp) => {
                                    resolve(true);
                                })
                                .catch((resp) => {
                                    reject(false);
                                });
                            });

                            ToastService.showPromiseMessage(promise, "Avvio applicazione pacchetto in corso", "Applicazione pacchetto avviata", "Applicazione pacchetto non riuscita");
                        }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Codice" fieldName="code" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Nome pacchetto" fieldName="packageName" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Versione" fieldName="productVersion" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Versione" fieldName="productVersion" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Stato import" fieldName="importStatus" minWidth={100} maxWidth={200} onRender={(item, index, column) => {
                        if (item.importStatus == 0)
                            return "In attesa";
                        else if (item.importStatus == 1)
                            return "Schedulato";
                        else if (item.importStatus == 2)
                            return "In corso";
                        else if (item.importStatus == 3)
                            return "Completato";
                        else if (item.importStatus == 4)
                            return "In errore";
                    }} />
                    <ListHeaderEntry name="Stato applicazione" fieldName="applyStatus" minWidth={100} maxWidth={200} onRender={(item, index, column) => {
                        if (item.applyStatus == 0)
                            return "In attesa";
                        else if (item.applyStatus == 1)
                            return "Schedulato";
                        else if (item.applyStatus == 2)
                            return "In corso";
                        else if (item.applyStatus == 3)
                            return "Completato";
                        else if (item.applyStatus == 4)
                            return "In errore";
                    }} />
                    <ListHeaderEntry name="Nr. errori" fieldName="numberOfErrors" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Nr. record" fieldName="numberOfRecords" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Nr. tabelle" fieldName="numberOfTables" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Errore import" fieldName="importError" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Errore applic." fieldName="applyError" minWidth={100} maxWidth={200} />
                </PageDefinitionContainer>
            </PageContainer>
        </>
    );
}