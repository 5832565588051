import React, { forwardRef, useRef, useState } from "react";
import { SystemActionCategory, SystemDataLoadingStatus, SystemFieldType, SystemOperation, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { Loader } from "../../../Common/Loader/Loader";
import { AdminBusinessCentralCompanyCard } from "../AdminBusinessCentralCompanyCard/AdminBusinessCentralCompanyCard";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";

export const AdminChatbotTranslactionList : React.FC = () => {
    const [loaderMessage,setLoaderMessage] = useState<string>("Caricamento delle traduzioni");
    const [sourceId,setSourceId] = useState<string>(HttpHelper.getParameter("sourceId"));
    const [dataStatus,setDataStatus] = useState(SystemDataLoadingStatus.Loaded);
    const cardRef = useRef<PageContainerBase>(null);
    const adminService = new AdminService();
    let listTitle = "Lista traduzioni";

    const buildListEndpoint = () : string =>{
        let endpoint = "/odata/system/caption";
        
        if (sourceId)
            endpoint += "?$filter=sourceId eq '"+sourceId+"'";
        
        return endpoint;
    }
    
    if(dataStatus === SystemDataLoadingStatus.ToLoad)
        return <Loader text={loaderMessage}/>

    return (
        <>
            <PageContainer endpoint={buildListEndpoint()} onNewRecord={() => {
                cardRef.current?.setRecord({
                    sourceEntity: "ChatbotProfile",
                    sourceId: sourceId,
                    captionType: 7
                });

                cardRef.current?.openPage(SystemOperation.Insert);
            }} pageType={SystemPageType.List} title={listTitle} cardRef={cardRef} backAllowed showAllowed insertAllowed deleteAllowed updateAllowed>
                <PageDefinitionContainer type={ContainerType.Action}>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Nome campo" fieldName="sourceSubId" minWidth={90} maxWidth={90} />
                    <ListHeaderEntry name="Lingua" fieldName="languageCode" minWidth={50} maxWidth={50} />
                    <ListHeaderEntry name="Traduzione" fieldName="translatedValue" minWidth={90} maxWidth={90} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminChatbotTranslactionCard ref={cardRef} />
        </>
    );
}

export const AdminChatbotTranslactionCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    const getParams=(param:string)=>{
        const query = new URLSearchParams(window.location.search);
        return (query.get(param))
    }

    return (
        <PageContainer pageType={SystemPageType.Card} title={"Traduzioni"} endpoint="odata/system/caption" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen>
                <CardField name="languageCode" type={SystemFieldType.String} label="Cod. lingua" hasTableRelation tableRelationEndpoint="/odata/system/language" tableRelationFilter="?$filter=default eq false" tableRelationField="name" tableRelationKey="code"/>
                <CardField name="sourceSubId" type={SystemFieldType.String} label="Nome proprietà" />
                <CardField name="translatedValue" type={SystemFieldType.String} label="Traduzione" multiline />
            </CardGroup> 
        </PageContainer>
    );
});