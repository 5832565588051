import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminSystemEventPublisherCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda System Event Publisher"} endpoint="odata/system/event/publisher" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="name" type={SystemFieldType.String} label="Nome" />
                <CardField name="description" type={SystemFieldType.String} label="Descrizione" />
                <CardField name="type" type={SystemFieldType.Option} label="Tipo" cmbOptions={[
                    {key: 0, text: "Tabella"},
                    {key: 1, text: "Integrazione"}
                ]} />
            </CardGroup> 
        </PageContainer>
    );
});