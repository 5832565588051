import React, { useRef, useState } from "react";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import {SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminBusinessCentralDefaultAppCard } from "../AdminBusinessCentralDefaultAppCard/AdminBusinessCentralDefaultAppCard";
import { AdminBusinessCentralAppUploader } from "./AdminBusinessCentralAppUploader";
import { ToastService } from "../../../../Core/Toast/ToastService";
import { ConfirmDialog } from "../../../Common/Template/Dialog/ConfirmDialog";

export const AdminBusinessCentralDefaultAppList : React.FC = () => {
    const [showDialog, setShowDialog] = useState(false);
    const cardRef = useRef<PageContainerBase>(null);
    const pageRef = useRef<PageContainerBase>(null);
    const adminService = new AdminService();
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
    const appId = useRef<string>('');
    const appName = useRef<string>('');
    
    const handleUninstallApp = () => {
        var promise = adminService.uninstallApp(appId.current);
        ToastService.showPromiseMessage(promise, "Schedulazione disinstallazione "+appName.current+" in corso", "Schedulazione disinstallazione "+appName.current+" completata con successo", "Errore durante la schedulazione della disinstallazione di "+appName.current);
    }
    
    return (
        <>
            <PageContainer endpoint="/odata/businesscentral/default/app?$orderby=priority asc" pageType={SystemPageType.List} title="BusinessCentral default app" backAllowed insertAllowed updateAllowed showAllowed ref={pageRef} cardRef={cardRef}>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Process}>
                        <ActionEntry
                            name="UploadAppFile" 
                            label="Carica estensione" 
                            iconName="Upload" 
                            onClick={(req:any)=>{
                                setShowDialog(true);
                            }}
                        >
                        </ActionEntry>
                        <ActionEntry name="UninstallApp" label="Disinstalla app" iconName="DependencyRemove" runOnRec onClick={(req: any) => {
                            appId.current = req.appId;
                            appName.current = req.name;
                            setShowConfirmDialog(true);
                        }}>                            
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>                    
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry fieldName="name" name="Nome" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="type" name="Tipo" minWidth={150} maxWidth={250} onRender={(item: any, index, column) => {
                        if (item.type === 0)
                            return "PerTenant"
                        else if (item.type === 1)
                            return "ISV"
                    }} />
                    <ListHeaderEntry fieldName="version" name="Versione" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry fieldName="publisher" name="Publisher" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminBusinessCentralDefaultAppCard ref={cardRef} />
            <AdminBusinessCentralAppUploader 
                showDialog={showDialog}
                onSuccess={()=>{
                    setShowDialog(false);
                    pageRef.current?.reloadData();
                }}
                onDismiss={()=>{
                    setShowDialog(false);
                }}
            />
            <ConfirmDialog 
                cancelButtonText="Annulla"
                confirmButtonText="Conferma"
                showDialog={showConfirmDialog}
                title="Disinstallazione App"
                subText={"Attenzione, verrà disinstallata l'app "+appName.current+". Vuoi continuare?"}
                onConfirm={() => {
                    handleUninstallApp();
                    appId.current = '';
                    appName.current = '';
                    setShowConfirmDialog(false);
                }}
                onCancel={() => {
                    setShowConfirmDialog(false);
                }} 
            />
        </>
    );
}