import { IColumn, ICommandBarItemProps } from "@fluentui/react";
import React, { createRef, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../ApplicationCode/Admin/AdminService";
import { CommonFunctions } from "../../../ApplicationCode/Common/CommonFunctions";
import { ISessionStateContext, SessionStateContext } from "../../../Core/State/SessionStateContext";
import { SystemCore } from "../../../Core/System/SystemCore";
import { PageMetadata, SystemActionCategory, SystemDataLoadingStatus, SystemEntity, SystemOperation, SystemPage, SystemPageType } from "../../../Model/SystemModels";
import { CommonPageList } from "../../Common/CommonPageList/CommonPageList";
import { Loader } from "../../Common/Loader/Loader";
import { ActionArea } from "../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../Common/Template/PageDefinitionContainer";
import { AdminPagePanel } from "../AdminPagePanel/AdminPagePanel";

export const AdminPageList : React.FC = () => {
    const [showPanel, SetShowPanel] = useState(false);
    const panelRef = useRef<PageContainerBase>(null);
    const navigate = useNavigate();

    const getNestedPropety = (record: any, fieldName: string) => {
        let nestedPropetyNames = fieldName.split('.');
        let nestedValue = record;
        
        for(let i = 0; i < nestedPropetyNames.length; i++)
        {
            let keyIndex = Object.keys(nestedValue).indexOf(nestedPropetyNames[i]);
            nestedValue = Object.values(nestedValue)[keyIndex];
        }

        return nestedValue;
    }

    return (
        <>
            <PageContainer pageType={SystemPageType.List} title="Pagine" endpoint="/api/system/metadata/page" insertAllowed deleteAllowed cardRef={panelRef}>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Process}>
                        <ActionEntry iconName="Design" name="DesignPage" label="Progetta" runOnRec isPromoted onClick={(record: any) => {
                            navigate("/app/admin/system/design/" + record.page.systemId);
                        }} />
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Nome" fieldName="page.name" minWidth={180} maxWidth={200} onRender={(item, index, colum) => {
                        return getNestedPropety(item!, colum!.fieldName!);
                    }} />
                    <ListHeaderEntry name="Caption" fieldName="userLanguageCaption" minWidth={180} maxWidth={200} />
                    <ListHeaderEntry name="Entità sorgente" fieldName="page.sourceEntityName" minWidth={80} maxWidth={100} onRender={(item, index, colum) => {
                        return getNestedPropety(item!, colum!.fieldName!);
                    }}  />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminPagePanel title="Nuova pagina" ref={panelRef} />
        </>
    );
}