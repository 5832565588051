import React, { useEffect, useState } from "react";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import {SystemActionCategory, SystemDataLoadingStatus, SystemPageType } from "../../../../Model/SystemModels";
import { Loader } from "../../../Common/Loader/Loader";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";

export const PartnerEmailLogList : React.FC = () => {
    const [dataStatus,setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [endpoint,setEndpoint] = useState("");
    const [title,setTitle] = useState("");

    useEffect(()=>{
        if (dataStatus == SystemDataLoadingStatus.ToLoad)
        {
            setDataStatus(SystemDataLoadingStatus.Loading);
            let email=HttpHelper.getParameter('email');
            let name = HttpHelper.getParameter('name');
            if(email != null){
                setTitle('Lista email log - lead: '+name);
                setEndpoint("/odata/email/log?$filter=toAddress eq '"+ email + "'");
                setDataStatus(SystemDataLoadingStatus.Loaded);
            }
            else{
                setEndpoint('/odata/email/log');   
                setTitle('Lista email log ');
                setDataStatus(SystemDataLoadingStatus.Loaded);
            }
        }
    },[dataStatus])
 
    if (dataStatus!=SystemDataLoadingStatus.Loaded)
        return <Loader text="Stiamo caricando la lista richiesta..."></Loader>;
    else
        return (
            <>
                <PageContainer endpoint={endpoint} pageType={SystemPageType.List} title={title} backAllowed>               
                    <PageDefinitionContainer type={ContainerType.Action}>
                        <ActionArea category={SystemActionCategory.Action}>
                        </ActionArea>
                    </PageDefinitionContainer>                                 
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry name="Id" fieldName="id" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Inviato a" fieldName="toAddress" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Soggetto" fieldName="subject" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Corpo" fieldName="body" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Html" fieldName="isHtml" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Codice Template" fieldName="templateCode" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Id Utente" fieldName="userId" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="System Id" fieldName="systemId" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name="System Creation User Id" fieldName="systemCreationUserId" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name="System Creation Date" fieldName="systemCreationDate" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Data aggiornamento sistema" fieldName="systemUpdateDate" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Id utente aggiornamento sistema " fieldName="systemUpdateUserId" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Timestamp" fieldName="timestamp" minWidth={150} maxWidth={250} />
                    </PageDefinitionContainer>
                </PageContainer>
            </>
        );
}