import { DefaultButton, Dialog,DialogFooter,Text, IModalProps, IStackTokens, mergeStyles,PrimaryButton, Stack, TextField } from "@fluentui/react";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Accordion, AccordionItem, AccordionItemPanel } from "react-accessible-accordion";
import '../../../../node_modules/react-accessible-accordion/dist/fancy-example.css';
import { Lead } from "../../../Model/PartnerModels";

const modalProps: Partial<IModalProps> = {
    isBlocking: true,
    dragOptions: undefined,
    styles: {
        main: {
            width: '40% !important',
            maxWidth: 'calc(40%) !important'
        }
    }
  };

const contentBodyClassName = mergeStyles([{
    width: '100%',
    overflowY: "auto",
    overflowX: "hidden"
}]);

const modalContentClassName = mergeStyles([{
    width: '100%'
}]);

const formContentClassName = mergeStyles([{
    width: '100%'
}]);

const formContentEntryClassName = mergeStyles([{
    width: '100%'
}]);

const rowContentClassName = mergeStyles([{
    width: '100%',
    marginBottom: 7
}]);

const rowContentEntryClassName = mergeStyles([{
    width: '100%',
    display:'flex',
    justifyContent:'center'
}]);

const textFieldClassName =  mergeStyles([{
    width: '100%',
    maxWidth: 600
}]);

const noteClassName = mergeStyles([{
    fontSize:'9pt',
    fontFamily:'serif',
    fontStyle:'italic'
}])


const rowContentStackToken: IStackTokens = {
    childrenGap: 50
};

export interface IPartnerLeadCardProps {
    showDialog: boolean;
    title: string;
    note?:string;
    saveInProgress: boolean;
    onSuccessModal: (lead: Lead) => void;
    onCancelModal: () => void;
}

export const CustomerCreateLeadCard : React.FC<IPartnerLeadCardProps> = (props) => {
    const [lead,setLead]= useState({} as Lead);

    const handleObjectPropertyChange = (fieldName: string, newValue: any) => {
        let currentLead = JSON.parse(JSON.stringify(lead));

        currentLead = Object.defineProperty(
            currentLead, 
            fieldName, 
            {
              value: newValue!,
              writable: true,
              enumerable: true
            }
        );
        setLead(currentLead);
    }

    let noteContent:any;
    if(props.note !=="")
        noteContent=<Text className={noteClassName}>
                        <Text style={{fontSize:'11pt',fontWeight:'bold',fontStyle:'italic',fontFamily:'serif'}}>Note: </Text>
                        {props.note}
                    </Text>

    return (
        <Dialog
                hidden={! props.showDialog}
                modalProps={modalProps}
                onDismiss={props.onCancelModal}
                dialogContentProps={{
                    title: props.title
                }}>
                <Stack key="modalContent" className={modalContentClassName}>
                    <Stack.Item key="modalBodyContent" className={contentBodyClassName}>
                        <Stack key="formContainer" className={formContentClassName}>
                            <Accordion preExpanded={["generalItem"]}>
                                <AccordionItem uuid={"generalItem"}>
                                    <AccordionItemPanel>
                                        <Stack.Item className={formContentEntryClassName}>
                                            <Stack horizontal key="row1" className={rowContentClassName} tokens={rowContentStackToken}>
                                                <Stack.Item className={rowContentEntryClassName}>
                                                    <TextField name="companyName"
                                                        value={lead.companyName} 
                                                        label={t('customerCenter:CustomerCreateLeadCard:companyName')!}
                                                        className={textFieldClassName}
                                                        onChange={(ev, newValue) => {
                                                            handleObjectPropertyChange(ev.currentTarget.name, newValue);
                                                        }}
                                                        disabled={props.saveInProgress} />
                                                </Stack.Item>
                                            </Stack>
                                        </Stack.Item>
                                        <Stack.Item className={formContentEntryClassName}>
                                            <Stack horizontal key="row2" className={rowContentClassName} tokens={rowContentStackToken}>
                                                <Stack.Item className={rowContentEntryClassName}>
                                                    <TextField name="email"
                                                        value={lead.email} 
                                                        label={t('customerCenter:CustomerCreateLeadCard:email')!} 
                                                        className={textFieldClassName}
                                                        onChange={(ev, newValue) => {
                                                            handleObjectPropertyChange(ev.currentTarget.name, newValue);
                                                        }}
                                                        disabled={props.saveInProgress} />
                                                </Stack.Item>
                                            </Stack>
                                        </Stack.Item>
                                        <Stack.Item className={formContentEntryClassName}>
                                            <Stack horizontal key="row3" className={rowContentClassName} tokens={rowContentStackToken}>
                                                <Stack.Item className={rowContentEntryClassName}>
                                                    <TextField name="phone"
                                                        value={lead.phone} 
                                                        label={t('customerCenter:CustomerCreateLeadCard:phone')!} 
                                                        className={textFieldClassName}
                                                        onChange={(ev, newValue) => {
                                                            handleObjectPropertyChange(ev.currentTarget.name, newValue);
                                                        }}
                                                        disabled={props.saveInProgress} />
                                                </Stack.Item>
                                            </Stack>
                                        </Stack.Item>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item key="modalFooterContent">
                        {noteContent}
                        <DialogFooter>
                            <DefaultButton text={t('customerCenter:CustomerCreateLeadCard:defaultButtonText')!} onClick={props.onCancelModal} />
                            <PrimaryButton text={t('customerCenter:CustomerCreateLeadCard:primaryButtonText')!} onClick={() => {
                                props.onSuccessModal(lead)
                            }} />
                        </DialogFooter>
                    </Stack.Item>
                </Stack>
        </Dialog>
    );

}