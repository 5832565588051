import { IColumn } from "@fluentui/react";
import { Guid } from "guid-typescript";
import React, { useRef, useState } from "react";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { SystemCaptionType, SystemEntity, SystemOperation, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminSystemCaptionCard } from "../AdminSystemCaptionCard/AdminSystemCaptionCard";

export const AdminSystemCaptionList : React.FC = () => {
    const cardRef = useRef<PageContainerBase>(null);
    const sourceId = HttpHelper.getParameter('sourceId');
    const sourceEntity = HttpHelper.getParameter('sourceEntity');
    const pageTitle = HttpHelper.getParameter('title');
    const captionType = HttpHelper.getParameter('captionType');

    const buildListEndpoint = ():string =>{
        let endpoint:string = "/odata/system/caption";

        let filterCaptionType: string = "Field";
        if((captionType !== null) && (captionType !== "")){
            filterCaptionType = captionType;
        }

        endpoint += "?$filter=sourceId eq '"+sourceId+"'";
        endpoint += " and captionType eq '"+filterCaptionType+"'";

        return endpoint
    }

    const getPageTitle = (): string => {
        let title: string = "Lista system caption";
        if((pageTitle !== null) && (pageTitle !== "")){
            title = pageTitle;
        }

        return title;
    }

    return (
        <>
            <PageContainer endpoint={buildListEndpoint()} pageType={SystemPageType.List} title={getPageTitle()} onNewRecord={()=>{
                    cardRef.current?.setRecord({
                        sourceId : sourceId,
                        captionType : (captionType !== null) && (captionType !== "") ? captionType : SystemCaptionType.Field,
                        sourceEntity: sourceEntity
                    })
                    cardRef.current?.openPage(SystemOperation.Insert);
                }} 
                backAllowed 
                insertAllowed 
                deleteAllowed 
                updateAllowed 
                cardRef={cardRef} 
            >               
                <PageDefinitionContainer type={ContainerType.Action}>
                </PageDefinitionContainer>                               
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Codice lingua" fieldName="languageCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Source sub id" fieldName="sourceSubId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Valore tradotto" fieldName="translatedValue" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Tipo" fieldName="captionType" minWidth={150} maxWidth={250}
                        onRender={(item?: any, index?: number | undefined, column?: IColumn | undefined)=>{
                            switch(item.captionType){
                                case SystemCaptionType.Entity:
                                    return 'Entity';
                                case SystemCaptionType.EntityProperty:
                                    return 'Entity property';
                                case SystemCaptionType.EntityPropertyOption:
                                    return 'Entity property option';
                                case SystemCaptionType.Message:
                                    return 'Message';
                                case SystemCaptionType.Page:
                                    return 'Page';
                                case SystemCaptionType.PageStructure:
                                    return 'Page structure';
                                case SystemCaptionType.PageStructureAction:
                                    return 'Page structure action';
                                case SystemCaptionType.Field:
                                    return 'Field';
                                case SystemCaptionType.System:
                                    return 'System';
                            }
                    }} />
                    <ListHeaderEntry name="Source Id" fieldName="sourceId" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminSystemCaptionCard ref={cardRef}  />
        </>
    );
}