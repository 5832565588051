import { AnimationClassNames, Label, mergeStyles, PrimaryButton, Stack } from "@fluentui/react"
import { t } from "i18next";

const stackMainStyle = mergeStyles([{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: "fixed",
    bottom: 15,
    right: 7
}]);

export interface IDemoSalePopupProps {
    onClick: () => void;
}

export const DemoSalePopup = (props: IDemoSalePopupProps) => {

    return (
        <Stack className={stackMainStyle} id="demoSalesPopup"
                onClick={props.onClick}>
            <PrimaryButton 
                onClick={props.onClick}
                text={t('demoCenter:DemoSalePopup:primaryButtonText')!}
                styles={{
                    root: {
                        backgroundColor: '#007bff',
                        borderColor: '#007bff',
                        width: "120px",
                        height: "120px",
                        borderRadius:"100%",
                        fontSize: 17,
                        boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
                    },
                    rootHovered: {
                        backgroundColor: '#043e7d',
                        borderColor: '#043e7d'
                    }
                }}
            />
        </Stack>
    );

}