import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminSystemEventLogCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {

    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda System Event Log"} endpoint="odata/system/event/log" ref={ref}>
            <CardGroup name="general" label="General" isOpen={true}>
                <CardField name="entryNo" type={SystemFieldType.Number} label="Nome Evento" readonly />
                <CardField name="title" type={SystemFieldType.String} label="Titolo" readonly />
                <CardField name="description" type={SystemFieldType.String} label="Description" readonly />
                <CardField name="performedOn" type={SystemFieldType.String} label="Data Evento" readonly />
                <CardField name="duration" type={SystemFieldType.Number} label="Durata Evento" readonly />
                <CardField name="data" type={SystemFieldType.String} label="Dati" readonly />
                <CardField name="publisherEventName" type={SystemFieldType.String} label="Nome Evento Pubblicatore" readonly />
                <CardField name="subscribedByObject" type={SystemFieldType.String} label="Oggetto Sottoscrittore" readonly />
                <CardField name="subscribedByFunction" type={SystemFieldType.String} label="Funzione Sottoscrittrice" readonly />
                <CardField name="subscriberName" type={SystemFieldType.String} label="Nome Sottoscrittore" readonly />
                <CardField name="sourceEntityName" type={SystemFieldType.String} label="Nome Entità Origine" readonly />
                <CardField name="sourceId" type={SystemFieldType.String} label="Id Origine" readonly />
                <CardField name="userId" type={SystemFieldType.Number} label="Id Utente" readonly />
                <CardField name="typeCode" type={SystemFieldType.String} label="Codice tipo" readonly />
                <CardField name="unitOfMeasure" type={SystemFieldType.String} label="Unità di misura" readonly />
                <CardField name="scorePerUnitOfMeasure" type={SystemFieldType.Number} label="Score per unità di misura" readonly />
                <CardField name="dimension1" type={SystemFieldType.String} label="Dimensione 1" readonly />
                <CardField name="dimension2" type={SystemFieldType.String} label="Dimensione 2" readonly />
            </CardGroup> 
        </PageContainer>
    );
});