import React from "react";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";

export const AdminPartnerBudgetList : React.FC = () => {

    return (
        <>
            <PageContainer endpoint="/odata/partner/budget" pageType={SystemPageType.List} title="Partner budget" backAllowed>               
                <PageDefinitionContainer type={ContainerType.Action}>
                </PageDefinitionContainer>                                 
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Id" fieldName="id" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id Partner" fieldName="idPartner" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Anno" fieldName="year" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Mese" fieldName="month" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Budget New Seats" fieldName="budgetNewSeats" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Budget New Customer" fieldName="budgetNewCustomer" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Budget Amount" fieldName="budgetAmount" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="New Lead Count" fieldName="newLeadCount" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="New Customer Count" fieldName="newCustomerCount" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="New Subscription Amount" fieldName="newSubscriptionAmount" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="New Invoice Amount" fieldName="newInvoiceAmount" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="System Id" fieldName="systemId" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="System Creation User Id" fieldName="systemCreationUserId" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="System Creation Date" fieldName="systemCreationDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Data aggiornamento sistema" fieldName="systemUpdateDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id utente aggiornamento sistema" fieldName="systemUpdateUserId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Timestamp" fieldName="timestamp" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
        </>
    );
}