import { Checkbox, DatePicker, DefaultButton, ICheckboxStyleProps, ICheckboxStyles, IDatePickerStyleProps, IDatePickerStyles, IIconStyleProps, IIconStyles, IImageStyleProps, IImageStyles, IProgressIndicatorStyleProps, IProgressIndicatorStyles, IRatingStyleProps, IRatingStyles, IStackProps, IStackTokens, IStyleFunctionOrObject, ITextFieldProps, ITextFieldStyleProps, ITextFieldStyles, Icon, Image, ImageFit, Label, PrimaryButton, ProgressIndicator, Rating, RatingSize, Spinner, SpinnerSize, Stack, StackItem, TextField, keyframes, mergeStyles } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import logo from '../../../Assets/Images/logo-colori.png';
import loaderImage from '../../../Assets/Images/logo.png';
import { SystemFieldType } from "../../../Model/SystemModels";
import './Wizard.css';
import { resolve } from "path";
import { rejects } from "assert";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import parse from 'html-react-parser'
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { Console } from "console";


enum WizardStepType {
    SingleChoice,
    MultipleChoice,
    Form,
    Rating
}

class WizardStepStructure {
    public name: string = "";
    public label: string = "";
    public type: SystemFieldType = SystemFieldType.String;
    public value: any = "";
}

class WizardStepData {
    public title: string = "";
    public subtitle: string = "";
    public type: WizardStepType = WizardStepType.SingleChoice;
    public addOtherOption: boolean = false;
    public canSkip: boolean = false;
    public duration: number = 0;
    public maxRate: number = 0;
    public selectedIcon: string = "";
    public unSelectedIcon: string = "";
    public allowZeroRate: boolean = false;
}

class WizardStep {
    public data: WizardStepData = new WizardStepData();
    public structures: WizardStepStructure[] = [];
}

class WizardInformation {
    public name: string = "";
    public duration: number = 0;
    public workedDuration: number = 0;
    public step: WizardStep = new WizardStep();
    public completed: boolean = false;
    public redirectUrl: string = "";
}

class WizardError {
    public isHtml: boolean = false;
    public message: string = ""; 
}

class WizardRequest {
    public pathName: string = "";
    public queryString: string = "";
}

class WizardManagement {
    private setAnswersCallback: (answers: WizardAnswer[]) => void;
    private operationInProgress: boolean;

    constructor(_setAnswersCallback: (answers: WizardAnswer[]) => void, _operationInProgress: boolean)
    {
        this.setAnswersCallback = _setAnswersCallback;
        this.operationInProgress = _operationInProgress;
    }

    public onChangeControl(name: string, value: any, answers: WizardAnswer[], info: WizardInformation, extendedValue?: string)
    {
        let index = answers.findIndex(e => e.name == name);
        if (index >= 0)
        {
            answers[index].value = value;
            answers[index].extendedValue = extendedValue;
        }
        else
        {
            let answer = new WizardAnswer();
            answer.name = name;
            answer.value = value;
            answer.extendedValue = extendedValue;
            answers.push(answer);
        }

        if (info.step.data.type == WizardStepType.SingleChoice)
        {
            for (let j = 0; j < answers.length; j++)
                answers[j].value = (answers[j].name == name);
        }

        let copy = JSON.parse(JSON.stringify(answers));
        this.setAnswersCallback(copy);
    } 

    public onCompleteStepTrigger(info: WizardInformation, answers: WizardAnswer[],wizardCookie:string) : Promise<any>
    {
        return new Promise((resolve, rejects) => {
            let client = HttpHelper.buildAxiosInstance();
            client.post("/api/ui/wizard/step/complete?wizardCode="+ wizardCookie, JSON.stringify(answers))
                .then(resp => {
                    if (resp.data.success)
                    {
                        console.log(resp.data.message);
                        let newInfo: WizardInformation = JSON.parse(resp.data.message);

                        if (newInfo.completed)
                        {
                            client.post("/api/ui/wizard/complete?wizardCode="+ wizardCookie)
                                .then(e => {
                                    if (e.data.success)
                                    {
                                        this.setAnswersCallback([]);
                                        
                                        if(window.location.pathname === "/register"){
                                            newInfo.workedDuration = newInfo.duration;
                                            newInfo.redirectUrl = e.data.message;
                                            newInfo.step.data.title = "Complimenti hai completato tutti gli step!"
                                            newInfo.step.data.subtitle = "Tra pochi secondi sarai reindirizzato al portale demo";
                                            resolve(newInfo);
                                        }
                                        else if(window.location.pathname === "/app/demo/roleCenter"){
                                            newInfo.workedDuration = newInfo.duration;
                                            newInfo.redirectUrl = e.data.message;
                                            newInfo.step.data.title = "Complimenti hai completato tutti gli step!"
                                            newInfo.step.data.subtitle = "Ora puoi iniziare a utilizzare il portale demo, segui il tour guidato per una maggiore esperienza";
                                            resolve(newInfo);
                                        }
                                    }
                                    else
                                        rejects(e.data.message);
                                })
                                .catch(err => {
                                    rejects("");
                                });
                        }
                        else
                        {
                            resolve(newInfo);
                        }
                    }
                    else
                    {
                        ToastService.showMessage(MessageType.Error, resp.data.message);
                        rejects();
                    }
                });
        });
    } 

    public onOpenWizardTrigger(wizardCode: string) : Promise<any> {
        return new Promise((resolve, rejects) => {
            let client = HttpHelper.buildAxiosInstance();
            
            let wizardRequest: WizardRequest = new WizardRequest();

            let currentPath = window.location.pathname;
            wizardRequest.pathName = currentPath;
            
            let currentSearch = window.location.search;
            wizardRequest.queryString = currentSearch;

            client.post("/api/ui/wizard/open?wizardCode=" + wizardCode, wizardRequest)
                .then(resp => {
                    let wizardResponse = resp.data;
                    if (wizardResponse.success)
                    {
                        let info: WizardInformation = JSON.parse(wizardResponse.message);
                        console.log(info);
                        resolve(info);
                    }
                    else
                    {
                        let errorEntry = new WizardError();
                        errorEntry.isHtml = true;
                        errorEntry.message = wizardResponse.message;
                        rejects(errorEntry);
                    }
                })
                .catch(err => {
                    let errorEntry = new WizardError();
                    errorEntry.isHtml = true;
                    errorEntry.message = "<div style='padding: 20px;'><span style='font-size: 20px; font-weight: 800; color: #2A7DE1; padding-bottom: 20px'>Ci scusiamo, i nostri servizi sono attualmente non disponibili!</span><br /><span style='font-size: 15px; font-weight: 600'>Riprova più tardi o&nbsp;<a href='mailto:sales@so-smart.it'>contattaci qui</a></span></div>";
                    rejects(errorEntry);
                });
        });
    }

    public buildStepLayout(info: WizardInformation, onChangeCallback: (name: string, value: any) => void, answers: WizardAnswer[]) : any {
        if (info.completed)
            return <></>;

        switch(info.step.data.type)
        {
            case WizardStepType.Rating:
                return this.buildRatingStepLayout(info, onChangeCallback, answers);
            case WizardStepType.MultipleChoice:
                return this.buildChoiceLayout(info, onChangeCallback, false, answers);
            case WizardStepType.SingleChoice:
                return this.buildChoiceLayout(info, onChangeCallback, true, answers);
            case WizardStepType.Form:
                return this.buildFormLayout(info, onChangeCallback, answers);
                
        }

        return <></>;
    }

    private buildRatingStepLayout(info: WizardInformation, onChangeCallback: (name: string, value: any) => void, answers: WizardAnswer[]) : JSX.Element
    {
        let createDefaultAnswers = (answers.length == 0) && (info.step.structures.length > 0) && (! this.operationInProgress);
        if (createDefaultAnswers)
        {
            answers.push({
                name: info.step.structures[0].name,
                value: info.step.data.allowZeroRate ? 0 : 1,
                extendedValue: ""
            });
        }

        console.log(answers);
        return <WizardContentRow id={1} childrenGap={20}>
                <WizardContentColumn width={90}>
                    <WizardRating name={info.step.structures[0].name} 
                                allowZero={info.step.data.allowZeroRate} 
                                max={info.step.data.maxRate}
                                label={info.step.structures[0].label}
                                value={5}
                                onChange={onChangeCallback} />
                </WizardContentColumn>
            </WizardContentRow>
    }

    private buildChoiceLayout(info: WizardInformation, onChangeCallback: (name: string, value: any) => void, single: boolean, answers: WizardAnswer[]) : JSX.Element[]
    {
        let elements: JSX.Element[] = [];
        let row : JSX.Element[] = [];
        let i = 0;
        let createDefaultAnswers = (answers.length == 0) && (info.step.structures.length > 0) && (! this.operationInProgress);

        let entries: WizardStepStructure[] = JSON.parse(JSON.stringify(info.step.structures));
        if (info.step.data.addOtherOption)
        {
            entries.push({
                name: "other",
                label: "Altro...",
                type: SystemFieldType.String,
                value: false
            });
        }

        for (i = 0; i < entries.length; i++)
        {
            let isActive = entries[i].value;
            let index = answers.findIndex(e => e.name == entries[i].name);
            if (index >= 0)
                isActive = answers[index].value;

            let entry = <WizardContentColumn width={45}>
                <WizardChoice 
                    singleChoice={single} 
                    name={entries[i].name}
                    text={entries[i].label}
                    isOther={entries[i].name == "other"}
                    active={isActive}
                    onSelect={onChangeCallback} />
            </WizardContentColumn>
            
            row.push(entry);

            if ((i + 1) % 2 == 0)
            {
                elements.push(<WizardContentRow id={i + 1} childrenGap={20}>{row}</WizardContentRow>);
                row = [];
            }

            if (createDefaultAnswers)
                answers.push({
                    name: entries[i].name,
                    value: isActive,
                    extendedValue: ""
                });
        }

        if ((i + 1) % 2 == 0)
            elements.push(<WizardContentRow id={i + 1} childrenGap={20}>{row}</WizardContentRow>);

        console.log(answers);
        if (createDefaultAnswers)
        {
            let copy = JSON.parse(JSON.stringify(answers));
            this.setAnswersCallback(copy);
        }

        return elements;
    }

    private buildFormLayout(info: WizardInformation, onChangeCallback: (name: string, value: any) => void, answers: WizardAnswer[]) : JSX.Element[]
    {
        let elements: JSX.Element[] = [];
        let row : JSX.Element[] = [];
        let i = 0;
        let createDefaultAnswers = (answers.length == 0) && (info.step.structures.length > 0) && (! this.operationInProgress);

        let entries: WizardStepStructure[] = JSON.parse(JSON.stringify(info.step.structures));
        for (i = 0; i < entries.length; i++)
        {
            let currentValue: any = entries[i].value;
            let index = answers.findIndex(e => e.name == entries[i].name);
            if (index >= 0)
                currentValue = answers[index].value;

            let entry = <WizardContentColumn width={45}>
                <WizardFormControl 
                    name={entries[i].name}
                    label={entries[i].label}
                    type={entries[i].type}
                    onChange={onChangeCallback}
                    value={currentValue} />
            </WizardContentColumn>
            
            row.push(entry);

            if ((i + 1) % 2 == 0)
            {
                elements.push(<WizardContentRow id={i + 1} childrenGap={20}>{row}</WizardContentRow>);
                row = [];
            }

            if (createDefaultAnswers)
                answers.push({
                    name: entries[i].name,
                    value: currentValue,
                    extendedValue: ""
                });
        }

        if ((i + 1) % 2 == 0)
            elements.push(<WizardContentRow id={i + 1} childrenGap={20}>{row}</WizardContentRow>);

        if (createDefaultAnswers)
        {
            let copy = JSON.parse(JSON.stringify(answers));
            this.setAnswersCallback(copy);
        }
        
        console.log(answers);
        return elements;
    }
}

class WizardAnswer {
    public name: string = "";
    public value: any = "";
    public extendedValue: any = "";
}

const wizardContainerClassName = mergeStyles([{
    backgroundImage: 'url(\'./wizardBackground.png\')',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}]);

export interface IWizardProps {
    wizardCookieName: string;
    containerClassName?: string;
    onCompleteWizard?: () => void;
}

export const Wizard : React.FC<IWizardProps> = (props) => {
    const [operationInProgress, setOperationInProgress] = useState(false);
    const [answers, setAnswers] = useState([] as WizardAnswer[]);
    const [wizardInfo, setWizardInfo] = useState<WizardInformation | undefined>(undefined);
    const [blockingError, setBlockingError] = useState<WizardError | undefined>(undefined);

    let wizardMgmt = new WizardManagement(setAnswers, operationInProgress);

    useEffect(() => {
        if ((wizardInfo == undefined) && (! operationInProgress))
        {
            setOperationInProgress(true);
            wizardMgmt.onOpenWizardTrigger(props.wizardCookieName)
                .then((resp) => {
                    setWizardInfo(resp);
                    setOperationInProgress(false);
                })
                .catch((err) => {
                    setBlockingError(err);
                });
        }
    })

    const onChange = (name: string, value: any, extendedValue?: string) => {
        console.log(name + " - " + value);
        wizardMgmt.onChangeControl(name, value, answers, wizardInfo!, extendedValue);
    }

    if (wizardInfo == undefined)
    {
        return (
            <Stack key={"wizard"} className={props.containerClassName ? props.containerClassName : wizardContainerClassName}>
                <Stack.Item styles={{
                    root: {
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }
                }}>
                    <Stack key={"wizardContainer-"} style={{
                        backgroundColor: 'white',
                        borderRadius: 20,
                        boxShadow: '10px 10px 17px 0px rgba(131,131,130,0.75)',
                        width: '45%'
                    }}>
                        {
                            blockingError == undefined ?
                                <WizardHeader name="" percentComplete={0} loading />
                                :
                                <>
                                    <WizardHeader name="" percentComplete={0} hideProgress />
                                    <StackItem style={{
                                        width: '100&',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontFamily: 'Nunito',
                                        fontSize: 20,
                                        textAlign: 'center'
                                    }}>
                                        {
                                            blockingError.isHtml ?
                                                parse(blockingError.message)
                                            :
                                                blockingError.message
                                        }
                                    </StackItem>
                                </>
                        }
                    </Stack>
            </Stack.Item>
            </Stack>
        );
    }

    return (
        <Stack key={"wizard" + wizardInfo.name} className={props.containerClassName ? props.containerClassName : wizardContainerClassName}>
            <Stack.Item styles={{
                root: {
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }
            }}>
                <Stack key={"wizardContainer-" + wizardInfo.name} style={{
                    backgroundColor: 'white',
                    borderRadius: 20,
                    boxShadow: '10px 10px 17px 0px rgba(131,131,130,0.75)',
                    width: '45%'
                }}>
                    <WizardHeader percentComplete={(wizardInfo!.workedDuration * 100) / wizardInfo!.duration} name={wizardInfo.name} />
                    <WizardBody name={wizardInfo.name} title={wizardInfo.step.data.title} subTitle={wizardInfo.step.data.subtitle} operationInProgress={operationInProgress}>
                        {wizardMgmt.buildStepLayout(wizardInfo, onChange, answers)}
                    </WizardBody>
                    <WizardFooter name={wizardInfo.name} duration={wizardInfo!.duration - wizardInfo!.workedDuration} showSkip={wizardInfo!.step.data.canSkip} operationInProgress={operationInProgress} onComplete={() => {
                        setOperationInProgress(true);

                        wizardMgmt.onCompleteStepTrigger(wizardInfo, answers,props.wizardCookieName)
                            .then(e => {
                                let data: WizardInformation = e as WizardInformation;
                                setAnswers([] as WizardAnswer[]);

                                setTimeout(() => {
                                    if(data.completed){
                                        let button = document.getElementById("WZPrimaryButtonComplete");
                                        if(button != null)
                                            button.style.display = "none";
                                    }

                                    setWizardInfo(data);
                                    setOperationInProgress(false);
                                }, 500);
                                
                                if (data.completed)
                                {
                                    setTimeout(() => {
                                        if(window.location.pathname === "/register"){
                                            const win: Window = window;
                                            win.location = data.redirectUrl;
                                        }
                                        else if(window.location.pathname === "/app/demo/roleCenter")
                                            props.onCompleteWizard!();
                                    }, 5000);
                                }

                            })
                            .catch(err => {
                                setOperationInProgress(false);
                            });
                    }} />
                </Stack>
            </Stack.Item>
        </Stack>
    );

}

export interface IWizardHeaderProps {
    percentComplete: number;
    name: string;
    loading?: boolean;
    hideProgress?: boolean;
}

export const WizardHeader: React.FC<IWizardHeaderProps> = (props) => {
    const headerContentContainer = mergeStyles([{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingTop: 20
    }]);

    const headerContentContainerTokens: IStackTokens = {
        childrenGap: 15
    }

    const imageContainer = mergeStyles([{
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    }]);

    const progressContainer = mergeStyles([{
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    }]);

    const imageStyle: IStyleFunctionOrObject<IImageStyleProps, IImageStyles> = {
        image: {
            width: '12vw'
        }
    };

    let progressStyles: IStyleFunctionOrObject<IProgressIndicatorStyleProps, IProgressIndicatorStyles> = {
        root: {
            width: '85%'
        },
        progressBar: {
            height: 10,
            backgroundColor: '#2A7DE1'
        },
        progressTrack: {
            height: 10
        }
    }

    if (props.loading)
    {
        progressStyles = {
            root: {
                width: '85%'
            },
            progressBar: {
                height: 10,
                backgroundColor: '#2A7DE1'
            },
            progressTrack: {
                height: 10,
                backgroundColor: 'inherit'
            }
        }
    }
    
    return (
        <StackItem>
            <Stack key={"wizardHeader-" + props.name} className={headerContentContainer} tokens={headerContentContainerTokens}>
                <StackItem className={imageContainer}>
                    <Image src={logo} styles={imageStyle} draggable={false} />
                </StackItem>
                {
                    props.hideProgress ?
                        <>
                        </>
                    :
                        props.loading ?
                            <StackItem className={progressContainer} style={{
                                marginBottom: 20
                            }}>
                                <ProgressIndicator styles={progressStyles} />
                            </StackItem>
                        :
                            <StackItem className={progressContainer}>
                                <ProgressIndicator percentComplete={props.percentComplete > 0 ? props.percentComplete / 100 : 0.01} styles={progressStyles} />
                            </StackItem>
                }
            </Stack>
        </StackItem>
    );
}

export interface IWizardBodyProps {
    name: string;
    title: string;
    subTitle: string;
    operationInProgress: boolean;
}

export const WizardBody: React.FC<IWizardBodyProps> = (props) => {
    const [showProgress, setShowProgress] = useState(false);
    const [contentClassName, setContentClassName] = useState("first");

    const wizardTextContentContainer = mergeStyles([{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }]);

    const wizardBodyContainer = mergeStyles([{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }]);

    const wizardBodyContentContainer = mergeStyles([{
        width: '90%',
        display: 'flex',
        justifyContent: 'left',
        maxHeight: '20vh',
        overflowY: 'auto',
        selectors: {
            "::-webkit-scrollbar": {
                width: 6
            },
            "::-webkit-scrollbar-track": {
                backgroundColor: '#f2f2f2'
            },
            "::-webkit-scrollbar-thumb": {
                backgroundColor: '#2A7DE1',
                borderRadius: 20
            },
            "::-webkit-scrollbar-thumb:hover": {
                backgroundColor: '#1b4880'
            }
        },
        paddingRight: 5
    }]);

    const wizardContentTokens : IStackTokens = {
        childrenGap: 30
    }

    const wizardTextContentContainerTokens : IStackTokens = {
        childrenGap: 10
    }

    const wizardTitleContentContainer = mergeStyles([{
        width: '90%',
        display: 'flex',
        justifyContent: 'left',
        marginTop: 40,
        fontSize: 22,
        fontFamily: 'Nunito',
        fontWeight: 700,
        color: '#424241'
    }]);

    const wizardSubTitleContentContainer = mergeStyles([{
        width: '90%',
        display: 'flex',
        justifyContent: 'left',
        fontSize: 15,
        fontFamily: 'Nunito',
        fontWeight: 500,
        color: '#424241'
    }]);


    if ((contentClassName == "first") && (! props.operationInProgress))
        setContentClassName("");
    else if ((props.operationInProgress) && ((contentClassName != "hide") && (contentClassName != "first")))
    {
        setTimeout(() => {
            setShowProgress(true);
        }, 1100);
        console.log("Hi");
        console.log(contentClassName);
        setContentClassName("hide");  
    }
    else if ((showProgress) && (! props.operationInProgress))
    {
        setShowProgress(false);
        setTimeout(() => {
            setContentClassName("");
        }, 500);  
    }

    return (
        <>
        <StackItem className={"wizard-body" + " " + contentClassName}>
            <Stack key={"wizardContent-" + props.name} tokens={wizardContentTokens}>
                <StackItem>
                    <Stack key={"wizardTextContent-" + props.name} className={wizardTextContentContainer} tokens={wizardTextContentContainerTokens}>
                        <StackItem className={wizardTitleContentContainer}>
                            {parse(props.title == null ? "" : props.title)}
                        </StackItem>
                        <StackItem className={wizardSubTitleContentContainer}>
                            {parse(props.subTitle == null ? "" : props.subTitle)}
                        </StackItem>
                    </Stack>
                </StackItem>
                <StackItem className={wizardBodyContainer}>
                    <Stack key={"wizardBodyContent-" + props.name} className={wizardBodyContentContainer} tokens={{
                        childrenGap: 20
                    }}>
                        {props.children}
                    </Stack>
                </StackItem>
            </Stack>
        </StackItem>
        <WizardLoader showLoader={showProgress} />
        </>
    );
}

export interface IWizardFooterProps {
    duration: number;
    name: string;
    showSkip: boolean;
    operationInProgress: boolean;
    onComplete: () => void;
    onSkip?: () => void;
}

export const WizardFooter: React.FC<IWizardFooterProps> = (props) => {
    const footerMainContentContainer = mergeStyles([{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 10,
        paddingBottom: 20
    }]);
    
    const footerContentContainer = mergeStyles([{
        width: '90%',
        paddingTop: 20
    }]);

    const durationContentContainer = mergeStyles([{
        width: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        fontSize: 14,
        fontFamily: 'Nunito',
        fontWeight: 400,
        color: '#424241'
    }]);

    const buttonContentContainer = mergeStyles([{
        width: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end'
    }]);

    const defaultButton = mergeStyles([{
        marginRight: 15,
        borderRadius: 15,
        padding: 10
    }]);

    const primaryButton = mergeStyles([{
        borderRadius: 15,
        padding: 10
    }]);

    return (
        <StackItem className={footerMainContentContainer}>
            <Stack key={"wizardFooter-" + props.name} className={footerContentContainer} horizontal>
                <StackItem className={durationContentContainer}>
                    {
                        props.duration > 1 ? 
                        "Durata: " + props.duration + " minuti"
                        :
                        props.duration == 1 
                        ?
                        "Durata: " + props.duration + " minuto"
                        :
                        "Durata: meno di un minuto"
                    }
                </StackItem>
                <StackItem className={buttonContentContainer}>
                    {
                        props.showSkip ? 
                        <DefaultButton text="Salta" disabled={props.operationInProgress} className={defaultButton} onClick={props.onSkip} />
                        :
                        <></>
                    }
                    <PrimaryButton text="Avanti" disabled={props.operationInProgress} className={primaryButton} id="WZPrimaryButtonComplete" onClick={props.onComplete} />
                </StackItem>
            </Stack>
        </StackItem>
    );
}

export const TestPage: React.FC = () => {
    return (
        <Wizard wizardCookieName="testpage"/>
    )
}

export interface IWizardChoiceProps {
    name: string;
    text: string;
    singleChoice?: boolean;
    active?: boolean;
    isOther?: boolean;
    onSelect: (name: string, value: any, extendedValue?: string) => void;
}

export const WizardChoice : React.FC<IWizardChoiceProps> = (props) => {
    const [hover, setHover] = useState(false);
    
    const textContainer = mergeStyles([{
        display: 'flex',
        alignItems: 'center',
        width: '90%'
    }]);

    const iconContainer = mergeStyles([{
        display: 'flex',
        alignItems: 'center',
        width: '10%'
    }]);

    const choiceContainerTokens: IStackTokens = {
        childrenGap: 10
    }

    const iconStyles: IStyleFunctionOrObject<IIconStyleProps, IIconStyles> = {
        root: {
            fontSize: 20,
            minHeight: 20,
            minWidth: 20
        }
    }

    let choiceContainerClassName = mergeStyles([{
        width: '100%',
        border: '1px solid #CECECE',
        display: 'flex',
        color: '#424241',
        alignItems: 'center',
        fontFamily: 'Nunito',
        height: '100%',
        fontSize: '1vh',
        padding: '2vh',
        transition: 'color, border, 0.3s ease',
        "selectors": {
            "&:hover": {
                border: '1px solid #2A7DE1',
                color: '#2A7DE1',
                fontWeight: 500,
                cursor: 'pointer'
            }
        }
    }]);

    if (props.active)
        choiceContainerClassName = mergeStyles([{
            width: '100%',
            border: '1px solid #2A7DE1',
            backgroundColor: '#2A7DE1',
            color: 'white',
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            fontFamily: 'Nunito',
            fontSize: '2vh',
            padding: '2vh',
            transition: 'background-color 0.3s ease',
            "selectors": {
                "&:hover": {
                    backgroundColor: '#1b4880',
                    cursor: 'pointer',
                    border: '1px solid #1b4880',
                }
            }
        }]);
    

    const handleOnClick = () => {
        props.onSelect(props.name, !props.active);
    }

    const handleOnMouseEnter = () => {
        setHover(true);
    }

    const handleOnMouseLeave = () => {
        setHover(false);
    }

    return (
        <Stack key={"choice-" + props.name} 
               horizontal 
               className={choiceContainerClassName} 
               tokens={choiceContainerTokens}
               onClick={(e) => {
                
                e.preventDefault();
                let el: any = e.target;
                if (el.id != "txtOther")
                    handleOnClick()
               }}
               onMouseEnter={handleOnMouseEnter}
               onMouseLeave={handleOnMouseLeave}
               verticalAlign="stretch">
            <Stack.Item className={textContainer} align="stretch">
                {
                    props.isOther ?
                        props.active ?
                            <TextField borderless id="txtOther" placeholder="Clicca qui per scrivere" onChange={(e, newVal) => {
                                props.onSelect(props.name, props.active, newVal);
                            }}
                            styles={{
                                fieldGroup: {
                                    backgroundColor: 'inherit',
                                    color: 'white !important'
                                },
                                field: {
                                    color: 'white !important',
                                    fontFamily: 'Nunito',
                                    selectors: {
                                        "&::placeholder": {
                                            color: '#f2f2f2',
                                            fontStyle: 'italic',
                                        }
                                    }
                                }
                            }}/>
                        :
                        props.text
                    :
                    props.text
                }
            </Stack.Item>
            <Stack.Item className={iconContainer} align="stretch">
                <Icon iconName={props.active ? "SkypeCircleCheck" : hover ? "CircleRing" : ""} styles={iconStyles} />
            </Stack.Item>
        </Stack>
    );
}

export interface IWizardContentRowProps {
    id: number;
    childrenGap: number;
}

export const WizardContentRow : React.FC<IWizardContentRowProps> = (props) => {
    return (
        <StackItem>
            <Stack horizontal tokens={{
                childrenGap: props.childrenGap
            }}>
                {props.children}
            </Stack>
        </StackItem>
    );
}

export interface IWizardContentColumnProps {
    width: number
}

export const WizardContentColumn : React.FC<IWizardContentColumnProps> = (props) => {
    const columnContainer = mergeStyles([{
        width: props.width + "%"
    }]);
    
    return (
        <StackItem className={columnContainer}>
            {props.children}
        </StackItem>
    );
}

export interface IWizardFormControlProps {
    type: SystemFieldType;
    name: string;
    label: string;
    value: any;
    onChange: (name: string, value: any) => void;
}

export const WizardFormControl: React.FC<IWizardFormControlProps> = (props) => {
    
    const textFieldStyles: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles> = {
        field: {
            fontFamily: 'Nunito'
        },
        fieldGroup: {
            height: 40,
            borderColor: '#bebebe'
        }
    }

    const datePickerTextFieldProps: ITextFieldProps = {
        styles: {
            field: {
                fontFamily: 'Nunito',
                height: 40
            },
            fieldGroup: {
                height: 40,
                borderColor: '#bebebe'
            }
        }
    }

    const datePickerStyles: IStyleFunctionOrObject<IDatePickerStyleProps, IDatePickerStyles> = {
        textField: {
            fontFamily: 'Nunito'
        },
        readOnlyTextField: {
            height: 40,
            display: 'flex',
            alignItems: 'center'
        },
        icon: {
            height: 40,
            display: 'flex',
            alignItems: 'center'
        }
    }

    const checkboxStyles: IStyleFunctionOrObject<ICheckboxStyleProps, ICheckboxStyles> = {
        root: {
            height: 69,
            display: 'flex',
            alignItems: 'end',
            paddingBottom: 10
        }
    }
    
    const onFormatDate = (date?: Date): string => {
        if (! date)
            return '';

        let dateStr = "";
        if (date.getDate() <= 9)
            dateStr += '0';
        dateStr += date.getDate() + "/";

        if ((date.getMonth() + 1) <= 9) 
            dateStr += '0';
        
        dateStr += (date.getMonth() + 1);

        return dateStr + '/' + (date.getFullYear() % 100);
      };
    
    switch(props.type)
    {
        case SystemFieldType.String:
            return (
                <TextField value={props.value} 
                           name={props.name} 
                           label={props.label} 
                           onChange={(e, newValue) => props.onChange(props.name, newValue)} 
                           styles={textFieldStyles} />
            );
        case SystemFieldType.Number:
            return (
                <TextField type="number"
                           value={props.value} 
                           name={props.name} 
                           label={props.label} 
                           onChange={(e, newValue) => props.onChange(props.name, newValue)} 
                           styles={textFieldStyles} />
            );
        case SystemFieldType.Date:
            return (
                <DatePicker
                    value={props.value}
                    label={props.label}
                    formatDate={onFormatDate}
                    onSelect={(e) => props.onChange(props.name, null)}
                    styles={datePickerStyles}
                    textField={datePickerTextFieldProps}
                />
            );
        case SystemFieldType.Boolean:
            return (
                <Checkbox
                    label={props.label}
                    checked={props.value}
                    onChange={(e, newValue) => props.onChange(props.name, newValue)}
                    styles={checkboxStyles}
                    onRenderLabel={(p, d) => {
                        return <Label style={{
                            marginLeft: 5,
                        }}>{parse(props.label)}</Label>
                    }}
                />
            );
        default:
            return <></>;
    }
}

export interface IWizardRatingProps {
    name: string;
    label: string;
    selectedIcon?: string;
    unselectedIcon?: string;
    allowZero: boolean;
    max: number;
    value: number;
    onChange: (name: string, value: any) => void;
}

export const WizardRating : React.FC<IWizardRatingProps> = (props) => {
    const ratingControlStyles: IStyleFunctionOrObject<IRatingStyleProps, IRatingStyles> = {
        ratingStarFront: {
            color: '#2A7DE1'
        },
        ratingStarIsLarge: {
            fontSize: 25
        },
        ratingButton: {
            color: '#2A7DE1'
        }
    }

    const labelClassName = mergeStyles([{
        fontFamily: 'Nunito',
        fontSize: 15,
        fontWeight: 700
    }]);
    
    return (
        <>
            <Label className={labelClassName}>{props.label}</Label>
            <Rating
                allowZeroStars={props.allowZero}
                max={props.max}
                defaultRating={1}
                icon={props.selectedIcon}
                unselectedIcon={props.unselectedIcon}
                onChange={(e, newValue) => { props.onChange(props.name, newValue)}}
                size={RatingSize.Large}
                styles={ratingControlStyles}
            />
        </>
    );
}

export interface IWizardLoaderProps {
    showLoader: boolean;
}

export const WizardLoader: React.FC<IWizardLoaderProps> = (props) => {
    const [firstRender, setFirstRender] = useState(true); 
    
    const spinnerAnimation = keyframes({
        'from': {
            transform: 'rotate(0deg)'
        },
        'to': {
            transform: 'rotate(359deg)'
        }
    });

    const spinnerFadeIn = keyframes({
        'from': {
            opacity: 0,
            height: 0,
        },
        'to': {
            opacity: 1,
            height: 100
        }
    });

    const spinnerFadeOut = keyframes({
        'from': {
            opacity: 1,
            height: 100,
        },
        'to': {
            opacity: 0,
            height: 0
        }
    });

    useEffect(() => {
        if (props.showLoader)
            setFirstRender(false);
    }, [props.showLoader]);

    let containerStyle = mergeStyles([{
        opacity: 0,
        height: 0,
    }]);

   if (! firstRender)
    {
        containerStyle = mergeStyles([{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '3vh !important',
            animation: spinnerFadeOut + ' 0.5s normal',
            height: 0,
            opacity: 0,
            display: 'flex'
        }]);
    }

    if (props.showLoader)
        containerStyle = mergeStyles([{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '3vh !important',
            animation: spinnerFadeIn + ' 0.5s normal',
            height: 100,
            display: 'flex'
        }]);

    const spinnerImageStyles: IStyleFunctionOrObject<IImageStyleProps, IImageStyles> = {
        root: {
            height: 60,
            width: 60
        },
        image: {
            height: 60,
            padding: 5,
            width: 60,
            animation: spinnerAnimation + ' 2s ease-out infinite'
        }
    }
    
    return (
        <StackItem className={containerStyle}>
            <Image src={loaderImage} styles={spinnerImageStyles}  />
        </StackItem>
    );
}