import { useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminSystemJobQueueEntryCard } from "./AdminSystemJobQueueEntryCard";

export const AdminSystemJobQueueEntryList : React.FC = () => {
    const navigate = useNavigate();
    const cardRef = useRef<PageContainerBase>(null);
    
    return (
        <>
            <PageContainer endpoint="/odata/systemjobqueue/entry" pageType={SystemPageType.List} title="Entità System Job Queue" backAllowed insertAllowed deleteAllowed updateAllowed cardRef={cardRef}>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="OpenJobQueueLog" iconName="BacklogList" label="Job queue log" runOnRec onClick={(req:any)=>{
                            navigate("/app/admin/systemjobqueue/log?jobCode="+req.code);
                        }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Codice" fieldName="code" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Stato" fieldName="status" minWidth={150} maxWidth={250} onRender={(item: any, index, column) => {
                        if (item.status === 0)
                            return "Spento"
                        else if (item.status === 1)
                            return "Pronto"
                        else if (item.status === 2)
                            return "In esecuzione"
                        else if (item.status === 3)
                            return "In errore"
                    }} />
                    <ListHeaderEntry name="Codice Categoria" fieldName="categoryCode" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Lunedì" fieldName="monday" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Martedì" fieldName="tuesday" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Mercoledì" fieldName="wednesday" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Giovedì" fieldName="thursday" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Venerdì" fieldName="friday" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Sabato" fieldName="saturday" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Domenica" fieldName="sunday" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Dalle ore" fieldName="fromTime" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Alle ore" fieldName="toTime" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Intervallo (in minuti)" fieldName="minutesDelay" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Codice Funzione" fieldName="functionCode" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Data Prossima Esecuzione" fieldName="nextStartDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Data Ultima Esecuzione" fieldName="lastStartDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Utente Esecuzione" fieldName="runAsUserId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="App Esecuzione" fieldName="runAsAppId" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminSystemJobQueueEntryCard ref={cardRef} />
        </>
    );
}