import { IImageProps, Image, ImageFit } from "@fluentui/react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminSystemAppCard } from "../AdminSystemAppCard/AdminSystemAppCard";

export const AdminSystemAppList : React.FC = () => {

    const navigate = useNavigate();
    const cardRef = useRef<PageContainerBase>(null);
    
    return (
        <>
            <PageContainer endpoint="/odata/system/app" pageType={SystemPageType.List} title="Lista System App" backAllowed insertAllowed updateAllowed cardRef={cardRef}>
                <PageDefinitionContainer type={ContainerType.Action}> 
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="OpenApp" label="Vail all'app" iconName="NavigateForward" runOnRec isPromoted onClick={(req:any)=>{
                                window.open(req.baseAddress,'_blank');
                            }}>
                        </ActionEntry>
                        <ActionEntry iconName="Permissions" label="Permission category" name="openAdminSystemPermissionCategory" onClick={(req:any)=>{
                            navigate("/app/admin/administrative/permissionCategory")
                        }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Id" fieldName="id" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Nome" fieldName="name" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Titolo" fieldName="title" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="URL Base" fieldName="baseAddress" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Attiva" fieldName="active" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Tipologia" fieldName="appType" minWidth={150} maxWidth={250} onRender={(item: any, index, column) => {
                        if (item.appType === 0)
                            return "Customer"
                        else if (item.appType === 1)
                            return "Admin"
                        else if (item.appType === 2)
                            return "Partner"
                        else if (item.appType === 3)
                            return "Demo"
                    }} />
                    <ListHeaderEntry name="Legal Entity (Default)" fieldName="defaultLegalEntityCode" minWidth={80} maxWidth={150} />
                    <ListHeaderEntry name="Lingua (Default)" fieldName="languageCode" minWidth={80} maxWidth={150} />
                    <ListHeaderEntry name="Pagina di aiuto abilitata" fieldName="helpPageEnable" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Logo Applicazione" fieldName="appLogo" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Icona Applicazione" fieldName="appIcon" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Durata Ticket (Minuti)" fieldName="tokenMinutesDuration" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Refresh token abilitato" fieldName="allowRefreshToken" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Mostra ChatBot" fieldName="showChatBot" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Power Automate Key" fieldName="powerAutomateKey" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminSystemAppCard ref={cardRef} />
        </>
    );
}