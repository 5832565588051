import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../../Model/SystemModels";
import { CardField } from "../../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../../Common/Template/PageContainer";

export const AdminAzureGDAPRelationshipCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} endpoint="/odata/azure/gdap/relationship" title={'Card relazione azure GDAP'} ref={ref}>
            <CardGroup name="general" label="Generale" isOpen>                
                <CardField name="id" label="Id" type={SystemFieldType.String} />
                <CardField name="displayName" label="Display Name" type={SystemFieldType.String} />
                <CardField name="duration" label="Duration" type={SystemFieldType.String} />
                <CardField name="customerId" label="Customer Id" type={SystemFieldType.Number} />
                <CardField name="tenantId" label="Tenant Id" type={SystemFieldType.String} />                    
                <CardField name="customerDisplayName" label="Customer Display Name" type={SystemFieldType.String} />
                <CardField name="status" label="Status" type={SystemFieldType.String} />
                <CardField name="createdOn" label="Created On" type={SystemFieldType.Date} />
                <CardField name="modifiedOn" label="Modified On" type={SystemFieldType.Date} />
                <CardField name="activatedOn" label="Activated On" type={SystemFieldType.Date} />
                <CardField name="endingDate" label="Ending Date" type={SystemFieldType.Date} />
                <CardField name="customerRelationshipRoleId" label="Customer Relationship Role Id" type={SystemFieldType.Number} />
                <CardField name="signLink" label="Sign Link" type={SystemFieldType.String} readonly />
            </CardGroup>
        </PageContainer>    
    );
});