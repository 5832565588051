import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { SystemCore } from "../../../../Core/System/SystemCore";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { SystemActionCategory, SystemDataLoadingStatus, SystemPageType } from "../../../../Model/SystemModels";
import { Loader } from "../../../Common/Loader/Loader";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminCrmCampaignCard } from "../AdminCrmCampaignCard/AdminCrmCampaignCard";
import { AdminNotificationSetupCard } from "../AdminNotificationSetupCard/AdminNotificationSetupCard";

export const AdminNotificationSetupList : React.FC = () => {

    const [dataStatus,setDataStatus] = useState(SystemDataLoadingStatus.Loaded);
    const [loaderMessage,setLoaderMessage] = useState("");
    const adminService = new AdminService();
    const navigate = useNavigate();
    const cardRef = useRef<PageContainerBase>(null);
    
    const syncTeamsChannels = () => {
        adminService.syncTeamsChannels().then((resp:string)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Success,resp);
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error,jqXHR);
        })
    }
    
    if (!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={loaderMessage}></Loader>;
    else
        return (
            <>
                <PageContainer endpoint={'/odata/notification/setup'} pageType={SystemPageType.List} title={"Notifiche"} cardRef={cardRef} backAllowed insertAllowed updateAllowed>
                    <PageDefinitionContainer type={ContainerType.Action}>
                        <ActionArea category={SystemActionCategory.Navigation}>
                            <ActionEntry name="OpenNotificationTemplate" iconName="FileTemplate" label="Template" runOnRec onClick={(req:any)=>{
                                navigate("/app/admin/related/notificationTemplate?setupType="+req.type+"&setupCode="+req.code);
                            }}>
                            </ActionEntry>
                            <ActionEntry name="OpenNotificationGroup" iconName="Group" label="Gruppi" runOnRec onClick={(req:any)=>{
                                navigate("/app/admin/related/notificationGroup?setupType="+req.type+"&setupCode="+req.code);
                            }}>
                            </ActionEntry>
                            <ActionEntry name="OpenNotificationLogEntry" iconName="BackLog" label="Log notifiche" onClick={(req:any)=>{
                                navigate("/app/admin/related/notificationLog");
                            }}>
                            </ActionEntry>
                        </ActionArea>
                        <ActionArea category={SystemActionCategory.Process}>
                            <ActionEntry name="SyncTeamsGroup" label="Sincronizza Canali Teams" iconName="TeamsLogo" onClick={(req:any)=>{
                                setDataStatus(SystemDataLoadingStatus.ToLoad)
                                setLoaderMessage("Sincronizzazione canali Teams in corso, Attendere...");
                                syncTeamsChannels();
                            }}>
                            </ActionEntry>                        
                        </ActionArea>
                    </PageDefinitionContainer>
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry name="Tipo" fieldName="type" minWidth={100} maxWidth={200} onRender={(item: any, index, column)=>{
                            if (item.type === 0)
                                return "Email"
                            else if (item.type === 1)
                                return "Chat"
                        }} />
                        <ListHeaderEntry name="Codice" fieldName="code" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name="Istanza" fieldName="instance" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name="Id Client" fieldName="clientId" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name="Secret" fieldName="clientSecret" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name="Tenant Id" fieldName="tenantId" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name="Redirect URL" fieldName="redirectUrl" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name="Attivo" fieldName="active" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name="Id Team" fieldName="teamId" minWidth={100} maxWidth={200} />
                    </PageDefinitionContainer>
                </PageContainer>
                <AdminNotificationSetupCard ref={cardRef} />
            </>
        );
}