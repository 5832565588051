import React, {useRef, useState} from "react";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { CommonUploadFileDialog } from "../../../Common/CommonUploadFileDialog/CommonUploadFileDialog";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ConfirmDialog } from "../../../Common/Template/Dialog/ConfirmDialog";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";

export const AdminAssetsList : React.FC = () => {
    const [fileDialogHidden, setFileDialogHidden] = useState<boolean>(true);
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
    const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
    const [assetFileName, setAssetFileName] = useState<string>("");
    const currentPage = useRef<PageContainerBase>(null);

    const downloadAsset = (record: any) => {
        const client = HttpHelper.buildAxiosInstance();
        ToastService.showPromiseMessage(
            client.get(`/api/system/assets/${btoa(record.name!)}`).then((resp) => {
                Object.assign(document.createElement('a'),{ href: 'data:' + record.contentType + ';base64,'+encodeURIComponent(resp.data), download:record.name}).click();
                ToastService.showMessage(MessageType.Success, "Download completato");
            })
            .catch(() => {
                ToastService.showMessage(MessageType.Error, "Impossibile scaricare il file");
            }),
            "Download in corso...",
            "",
            ""
        )
    }

    const uploadAssets = (file: File, name?: string) => {
        let currformData:FormData = new FormData();
        currformData.append('file', file, name);

        const client = HttpHelper.buildAxiosInstance();
        ToastService.showPromiseMessage(
            client.post(`/api/system/assets/${encodeURIComponent(btoa(name!))}/upload`, currformData,{
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then((resp) => {
                ToastService.showMessage(MessageType.Success, "Caricamento completato");
                setSaveInProgress(false);
                setFileDialogHidden(true);
                currentPage.current?.reloadData();
            })
            .catch(() => {
                ToastService.showMessage(MessageType.Error, "Impossibile caricare il file");
                setSaveInProgress(false);
            }),
            "Caricamento file in corso...",
            "",
            ""
        );
    }

    const deleteAsset = () => {
        const client = HttpHelper.buildAxiosInstance();

        ToastService.showPromiseMessage(
            client.delete(`/api/system/assets/${encodeURIComponent(btoa(assetFileName))}/delete`).then(() => {
                ToastService.showMessage(MessageType.Success, "Asset: " + assetFileName + " cancellato");
                setShowConfirmDialog(false);
                currentPage.current?.reloadData();
            })
            .catch(() => {
                ToastService.showMessage(MessageType.Error, "Impossibile cancellare il file");
                setShowConfirmDialog(false);
            }),
            "Cancellazione in corso...",
            "",
            ""
        );
    }
    
    return (
        <>
            <PageContainer endpoint={'/api/system/assets/all'} pageType={SystemPageType.List} title={"Lista assets"} ref={currentPage}>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Process}>
                        <ActionEntry label="Carica" iconName="Upload" name="UploadAssets" isPromoted onClick={(req:any)=>{
                            setFileDialogHidden(false);
                        }}>
                        </ActionEntry>
                        <ActionEntry label="Scarica" iconName="Download" name="DownloadAsset" runOnRec isPromoted onClick={(req:any)=>{
                            downloadAsset(req);
                        }}>
                        </ActionEntry>
                        <ActionEntry label="Cancella" iconName="Delete" name="DeleteAsset" runOnRec isPromoted onClick={(req:any)=>{
                            setShowConfirmDialog(true);
                            setAssetFileName(req.name);
                        }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry fieldName="name" name="Nome" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="contentType" name="Content type" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="lastModified" name="Ultima modifica" minWidth={100} maxWidth={200} />
                </PageDefinitionContainer>
            </PageContainer>
            <CommonUploadFileDialog
                ext=".png"
                hidden={!fileDialogHidden}
                onDismiss={() => setFileDialogHidden(true)}
                onSuccess={(file, customName) => {
                    if(!customName){
                        ToastService.showMessage(MessageType.Error, "Il nome del file non può essere vuoto!");
                    }
                    else{
                        setSaveInProgress(true);
                        uploadAssets(file, customName);
                    }
                }}
                saveInProgress={saveInProgress}
                customName
                subText="Carica un nuovo asset"
                title="Nuovo asset"
                txtLabel="Carica un file"
            />
            <ConfirmDialog 
                cancelButtonText="Annulla"
                confirmButtonText="Si, cancella"
                showDialog={showConfirmDialog}
                title="Sei sicuro di voler cancellare questo asset?"
                subText="Scegliendo si il sistema cencellerà il file direttamente sul blob storage"
                onCancel={()=>{setShowConfirmDialog(false)}}
                onConfirm={()=>{deleteAsset()}}
            /> 
        </>
    );
}