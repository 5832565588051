import { forwardRef } from "react";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import { CardField } from "../../../Common/Template/Card/CardField";

export const AdminDigitalContractCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {

    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda template contratto"} endpoint="odata/digitalcontract" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen>
                <CardField name="id" type={SystemFieldType.Number} label="Id" readonly />
                <CardField name="code" type={SystemFieldType.String} label="Codice" />
                <CardField name="description" type={SystemFieldType.String} label="Descrizione" />
                <CardField name="mandatory" type={SystemFieldType.Boolean} label="Obbligatorio" />
                <CardField name="adminRequired" type={SystemFieldType.Boolean} label="Admin richiesto" />
                <CardField name="functionCode" type={SystemFieldType.String} label="Codice funzione" />
                <CardField name="entityRelation" type={SystemFieldType.Option} label="Relazione entità" cmbOptions={[
                    { key: 10, text: "Cliente" },
                    { key: 11, text: "Tenant" },
                    { key: 12, text: "Company" },
                    { key: 13, text: "User" },
                ]} />
                <CardField name="templateName" type={SystemFieldType.String} label="Nome template" />
                <CardField name="templateBlob" type={SystemFieldType.Attachment} label="Blob template" ext=".json" valueWithoutBlobType />
                <CardField name="namirialTemplateId" type={SystemFieldType.String} label="Id template Namirial" />
                <CardField name="expirationDelayDays" type={SystemFieldType.Number} label="Giorni scadenza" />
                <CardField name="canDelete" type={SystemFieldType.Boolean} label="Cancellazione permessa" />
            </CardGroup> 
        </PageContainer>
    );
});