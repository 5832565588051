import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminApplicationSetupCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda Application Setup"} endpoint="odata/application/setup" ref={ref}>
            <CardGroup name="general" label="General" isOpen={true}>
                <CardField name="primaryKey" type={SystemFieldType.String} label="Id" />
                <CardField name="stepperGroupCode" type={SystemFieldType.String} label="Codice Gruppo STEPPER" />
                <CardField name="dayApplicationLogLimit" type={SystemFieldType.Number} label="Giorni Limite Application Log Entry" />
                <CardField name="dayHttpLogLimit" type={SystemFieldType.Number} label="Giorni Limite Http Log Entry" />
                <CardField name="defaultPartnerLeadOriginCode" type={SystemFieldType.String} label="Codice origine Partner Lead default" hasTableRelation tableRelationEndpoint="/odata/crm/source" tableRelationKey="code" tableRelationField="description" />
                <CardField name="ticketChatbotProfile" type={SystemFieldType.String} label="Cod. chatbot ticket" hasTableRelation tableRelationEndpoint="/odata/chatbot/profile" tableRelationKey="code" tableRelationField="description" />
                <CardField name="vatControlEnabled" type={SystemFieldType.Boolean} label="Controllo P.IVA abilitato" />
                <CardField name="getOpenApiDataAutomatically" type={SystemFieldType.Boolean} label="Dati Open API in automatico" />
            </CardGroup>
            <CardGroup name="stripeNotification" label="Notifiche Stripe" isOpen={false}>
                <CardField name="stripeNotificationSetupType" type={SystemFieldType.Option} label="Tipo setup Stripe" cmbOptions={[
                    {key: 0, text: "Email"},
                    {key: 1, text: "Chat"}
                ]} />
                <CardField name="stripeNotificationSetupCode" type={SystemFieldType.String} label="Codice setup Stripe" />
                <CardField name="stripeNotificationTemplateCode" type={SystemFieldType.String} label="Codice template Stripe" />
                <CardField name="stripeNotificationGroupCode" type={SystemFieldType.String} label="Codice gruppo Stripe" />
                <CardField name="stripeNotificationInvoicePaymentFailedEnabled" type={SystemFieldType.Boolean} label="Abilita notifiche Stripe fattura insoluta" />
                <CardField name="stripeNotificationInvoicePaidEnabled" type={SystemFieldType.Boolean} label="Abilita notifiche Stripe fattura pagata" />
                <CardField name="stripeNotificationCustomerSubscriptionCreatedEnabled" type={SystemFieldType.Boolean} label="Abilita notifiche Stripe sottoscrizione creata" />
                <CardField name="stripeNotificationCustomerSubscriptionUpdatedEnabled" type={SystemFieldType.Boolean} label="Abilita notifiche Stripe sottoscrizione aggiornata" />
                <CardField name="stripeNotificationCustomerSubscriptionDeletedEnabled" type={SystemFieldType.Boolean} label="Abilita notifiche Stripe sottoscrizione cancellata" />
                <CardField name="stripeNotificationCustomerRMRLedgerEntryEnabled" type={SystemFieldType.Boolean} label="Abilita notifiche Stripe RMR ledger entry" />
            </CardGroup>
            <CardGroup name="customerModule" label="Moduli cliente" isOpen={false}>
                <CardField name="customerAbleTechItemId" type={SystemFieldType.Number} label="Id articolo AbleTech" hasTableRelation tableRelationEndpoint="odata/item" tableRelationKey="id" tableRelationField="description" />
                <CardField name="customerAbleTechModuleCode" type={SystemFieldType.String} label="Codice modulo AbleTech" />
                <CardField name="customerBaseModuleCode" type={SystemFieldType.String} label="Codice modulo base" />
            </CardGroup>
        </PageContainer>
    );
});