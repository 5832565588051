import { UserInfo, ConversationRequest, Conversation, ChatMessage, CosmosDBHealth, CosmosDBStatus, CrmIncidentType, SupportType, SupportEntry, BCNProcessTest, BCNProcessModule, BCNProcess } from "./models";
import { chatHistorySampleData } from "../../../../Components/Chatbot/constants/chatHistory";
import { Item } from "../../../../Model/PartnerModels";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";

export const buildBaseEndpoint = (): string => {
    let backendHost: string;
    
    if (process.env.REACT_APP_SYSTEM_BACKEND_HOST!.indexOf('localhost') >= 0) {
      backendHost = process.env.REACT_APP_SYSTEM_BACKEND_HOST!;
    }
    else {
        let hostname: string = window.location.hostname;
        if(hostname.indexOf('localhost') >= 0){
            backendHost = process.env.REACT_APP_SYSTEM_BACKEND_HOST! + process.env.REACT_APP_SYSTEM_BACKEND_DEFAULT_HOST_EXTENSION!;
        }
        else {
            const parts = hostname.split('.');
            const domainExtension = parts[parts.length - 1];
            backendHost = process.env.REACT_APP_SYSTEM_BACKEND_HOST! + '.' + domainExtension;
        }
    }
    
    let serverBaseEndpoint: string = 'https';
    serverBaseEndpoint += "://" + backendHost;

    let port = Number(process.env.REACT_APP_SYSTEM_BACKEND_PORT!);
    if (port !== 0)
        serverBaseEndpoint += ":" + port;
    
    return serverBaseEndpoint;
}

const baseUrl: string = buildBaseEndpoint() + "/api/chat";
export const crmBaseUrl: string = buildBaseEndpoint() + "/api/crm";
const loginBaseUrl: string = buildBaseEndpoint() + "/api/system";
export const odataBaseUrl: string = buildBaseEndpoint() + "/odata";

export const getSoSmartToken = async (): Promise<string> => {
    const response = await fetch(loginBaseUrl + "/login", {
        method: "POST",
        body: JSON.stringify({
            username: 'sosmart.shop@so-smart.it',
            password: 'ShopSoSmart123456789!'
        }),
        headers: {
            "Content-Type": "application/json"
        },
    }).then(async (resp) => {
        const data = await resp.json();
        return data.value;
    })
    .catch((err) => {
        console.error("There was an issue loggin in.");
        return err;
    });

    return response;
}

export const getQuestions = async (options: ConversationRequest): Promise<string[]> => {
    const response = await fetch(buildBaseEndpoint() + "/api/eoschat/generate", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            messages: options.messages
        }),
    });

    if (response.status != 200)
        return [];

    return response.json();
}

export async function conversationApi(options: ConversationRequest, abortSignal: AbortSignal): Promise<Response> {
    const response = await fetch(buildBaseEndpoint() + "/api/eoschat/conversation", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            messages: options.messages,
            languageCode: options.languageCode
        }),
        signal: abortSignal
    });

    return response;
}

export async function getUserInfo(): Promise<UserInfo[]> {
    const response = await fetch('/.auth/me');
    if (!response.ok) {
        console.log("No identity provider found. Access to chat will be blocked.")
        return [];
    }

    const payload = await response.json();
    return payload;
}

// export const fetchChatHistoryInit = async (): Promise<Conversation[] | null> => {
export const fetchChatHistoryInit = (): Conversation[] | null => {
    // Make initial API call here

    // return null;
    return chatHistorySampleData;
}

export const historyList = async (): Promise<Conversation[] | null> => {
    const response = await fetch("/history/list", {
        method: "GET",
    }).then(async (res) => {
        const payload = await res.json();
        if (!Array.isArray(payload)) {
            console.error("There was an issue fetching your data.");
            return null;
        }
        const conversations: Conversation[] = await Promise.all(payload.map(async (conv: any) => {
            let convMessages: ChatMessage[] = [];
            convMessages = await historyRead(conv.id)
            .then((res) => {
                return res
            })
            .catch((err) => {
                console.error("error fetching messages: ", err)
                return []
            })
            const conversation: Conversation = {
                id: conv.id,
                title: conv.title,
                languageCode: conv.languageCode,
                date: conv.createdAt,
                messages: convMessages
            };
            return conversation;
        }));
        return conversations;
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        return null
    })

    return response
}

export const historyRead = async (convId: string): Promise<ChatMessage[]> => {
    const response = await fetch("/history/read", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
    .then(async (res) => {
        if(!res){
            return []
        }
        const payload = await res.json();
        let messages: ChatMessage[] = [];
        if(payload?.messages){
            payload.messages.forEach((msg: any) => {
                const message: ChatMessage = {
                    id: msg.id,
                    role: msg.role,
                    date: msg.createdAt,
                    content: msg.content,
                    type: msg.type
                }
                messages.push(message)
            });
        }
        return messages;
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        return []
    })
    return response
}

export const historyGenerate = async (options: ConversationRequest, abortSignal: AbortSignal, convId?: string): Promise<Response> => {
    let body;
    if(convId){
        body = JSON.stringify({
            conversation_id: convId,
            messages: options.messages
        })
    }else{
        body = JSON.stringify({
            messages: options.messages
        })
    }
    const response = await fetch("/history/generate", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: body,
        signal: abortSignal
    }).then((res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        return new Response;
    })
    return response
}

export const historyUpdate = async (messages: ChatMessage[], convId: string): Promise<Response> => {
    const instance = HttpHelper.buildAxiosInstance();

    return new Promise((resolve,reject)=> {
        instance.post(
            "/api/chat/history/update",
            JSON.stringify({
                conversation_id: convId,
                messages: messages
            }),
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        ).then((res) => {
            return res;
        })
        .catch((err) => {
            console.error("There was an issue fetching your data.");
            let errRes: Response = {
                ...new Response,
                ok: false,
                status: 500,
            }
            return errRes;
        });
    })
}

export const historyDelete = async (convId: string) : Promise<Response> => {
    const response = await fetch("/history/delete", {
        method: "DELETE",
        body: JSON.stringify({
            conversation_id: convId,
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
    .then((res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response;
}

export const historyDeleteAll = async () : Promise<Response> => {
    const response = await fetch("/history/delete_all", {
        method: "DELETE",
        body: JSON.stringify({}),
        headers: {
            "Content-Type": "application/json"
        },
    })
    .then((res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response;
}

export const historyClear = async (convId: string) : Promise<Response> => {
    const response = await fetch("/history/clear", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId,
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
    .then((res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response;
}

export const historyRename = async (convId: string, title: string) : Promise<Response> => {
    const response = await fetch("/history/rename", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId,
            title: title
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
    .then((res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response;
}

export const historyEnsure = async (): Promise<CosmosDBHealth> => {
    const response = await fetch("/history/ensure", {
        method: "GET",
    })
    .then(async res => {
        let respJson = await res.json();
        let formattedResponse;
        if(respJson.message){
            formattedResponse = CosmosDBStatus.Working
        }else{
            if(res.status === 500){
                formattedResponse = CosmosDBStatus.NotWorking
            }else{
                formattedResponse = CosmosDBStatus.NotConfigured
            }
        }
        if(!res.ok){
            return {
                cosmosDB: false,
                status: formattedResponse
            }
        }else{
            return {
                cosmosDB: true,
                status: formattedResponse
            }
        }
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        return {
            cosmosDB: false,
            status: err
        }
    })
    return response;
}

export const getSupportTypes = async (): Promise<CrmIncidentType[]> => {
    let token = await getSoSmartToken();
    const response = await fetch(odataBaseUrl + "/crm/incident/type", {
        method: "GET",
        headers: {
            Authorization: "Bearer " + token
        }
    })
    .then(async (resp) => {
        const data = await resp.json();
        return data;
    })
    .catch((err) => {
        console.error("There was an issue fetching incident types.");
        return err;
    });
    
    return response;
}

export const getSupportEntry = async (supportId: string): Promise<SupportEntry> => {
    let token = await getSoSmartToken();
    const response = await fetch(odataBaseUrl + "/support/entry?filter=id eq " + supportId, {
        method: "GET",
        headers: {
            Authorization: "Bearer " + token
        }
    })
    .then(async (resp) => {
        const data = await resp.json();
        return data[0];
    })
    .catch((err) => {
        console.error("There was an issue fetching support entry.");
        return err;
    });
    
    return response;
}

export const getBCNProcessModule = async (): Promise<BCNProcessModule[]> => {    
    let token = await getSoSmartToken();
    const response = await fetch(odataBaseUrl + "/bcn/process/module", {
        method: "GET",
        headers: {
            Authorization: "Bearer " + token
        }
    })
    .then(async (resp) => {
        const data = await resp.json();
        return data;
    })
    .catch((err) => {
        console.error("There was an issue fetching bcn process module.");
        return err;
    });
    
    return response;
}

export const getBCNProcess = async (): Promise<BCNProcess[]> => {    
    let token = await getSoSmartToken();
    const response = await fetch(odataBaseUrl + "/bcn/process", {
        method: "GET",
        headers: {
            Authorization: "Bearer " + token
        }
    })
    .then(async (resp) => {
        const data = await resp.json();
        return data;
    })
    .catch((err) => {
        console.error("There was an issue fetching bcn process.");
        return err;
    });
    
    return response;
}

export const getBCNProcessTest = async (): Promise<BCNProcessTest[]> => {    
    let token = await getSoSmartToken();
    const response = await fetch(odataBaseUrl + "/bcn/process/test", {
        method: "GET",
        headers: {
            Authorization: "Bearer " + token
        }
    })
    .then(async (resp) => {
        const data = await resp.json();
        return data;
    })
    .catch((err) => {
        console.error("There was an issue fetching bcn process.");
        return err;
    });
    
    return response;
}

export const getItems = async (category: string): Promise<Item[]> => {
    let filter: string = "?filter=category eq '"+category+"'";
    if (category === "EosApps") {
        filter += " and itemErpNo ne null and itemErpNo gt ''";
    }

    let token = await getSoSmartToken();
    const response = await fetch(odataBaseUrl + "/item"+filter, {
        method: "GET",
        headers: {
            Authorization: "Bearer " + token
        }
    })
    .then(async (resp) => {
        const data = await resp.json();
        return data;
    })
    .catch((err) => {
        console.error("There was an issue fetching incident types.");
        return err;
    });
    
    return response;
}

export function updateCurrentChat(payload: Conversation) : Conversation { 
    const messages: ChatMessage[] =  payload.messages.filter((message) => message.isLogged === undefined || message.isLogged === false);
    if((messages.length === 1) && (messages[0].subType === 'welcomeMessage'))
        return payload;

    historyUpdate(messages, payload.id);

    let updatedConversation: Conversation = payload;
    messages.forEach((message) => {
        updatedConversation.messages.find((msgToUpdate) => msgToUpdate.id === message.id)!.isLogged = true;
    });

    return updatedConversation;
}
