import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { t } from "i18next";

export const AdminProspectLeadAutomationSetupCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda Prospect lead automation"} endpoint="odata/prospect/lead/automation/setup" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>                
                <CardField name="code" label="Codice" type={SystemFieldType.String} />
                <CardField name="description" label="Descrizione" type={SystemFieldType.String} />
                <CardField name="enabled" label="Abilitato" type={SystemFieldType.Boolean} />
                <CardField name="oneShot" label="One Shot" type={SystemFieldType.Boolean} />
                <CardField name="partnerBusinessContractCode" label="Partner Business Contract (divisi con '|')" type={SystemFieldType.String} />
                <CardField name="dataSecurityCodes" label="Codici stato (divisi con '|')" type={SystemFieldType.String} />
                <CardField name="subjectCodes" label="Oggetti (divisi con '|')" type={SystemFieldType.String} />
                <CardField name="autoAccept" label="Accettazione automatica" type={SystemFieldType.Boolean} />
            </CardGroup>
            <CardGroup name="owner" label="Proprietario" isOpen={true}>
                <CardField name="suggestedOwnerType" type={SystemFieldType.Option} label="Tipo proprietario suggerito" cmbOptions={[
                    {key: 0, text: "Utente"},
                    {key: 1, text: "Entità"}
                ]} />
                <CardField name="suggestedOwnerId" label="Id proprietario suggerito" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/user" tableRelationKey="id" tableRelationField="displayName" tableRelationFilter="?$filter=crmSystemUserId ne null" />
                <CardField name="linkedEntityName" label="Nome entità correlata" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/system/entity" tableRelationKey="name" tableRelationField="name" tableRelationFilter="?$filter=name eq 'Lead' or name eq 'Customer'" />
                
            </CardGroup>
            <CardGroup name="actions" label="Azioni" isOpen={true}>
                <CardField name="suggestedActionType" type={SystemFieldType.Option} label="Tipo azione suggerita" cmbOptions={[
                    {key: 0, text: "Task"},
                    {key: 1, text: "Email"},
                    {key: 2, text: "Email CRM"},
                    {key: 3, text: "Aggiunta contatto a segmento"},
                    {key: 4, text: "Cambio stato DS"},
                    {key: 5, text: "Conversioni lead"}
                ]} />
                <CardField name="emailTemplateCode" label="Codice template email" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/email/template" tableRelationKey="emailTemplateCode" tableRelationField="emailTemplateCode" />
                <CardField name="subjectFromLead" label="Oggetto email da lead" type={SystemFieldType.Boolean} />
                <CardField name="taskTemplateMessage" label="Codice template task" type={SystemFieldType.String} />
                <CardField name="crmSegmentId" label="Id segmento CRM" type={SystemFieldType.String} />
                <CardField name="suggestedDelayDays" label="Giorni ritardo azione" type={SystemFieldType.Number} />
                <CardField name="destinationRecordStatusCode" label="Codice cambio stato" type={SystemFieldType.Option} cmbOptions={[
                    {key: "85", text: "Parked"},
                    {key: "96", text: "Lost"},
                    {key: "97", text: "Unqualified"}
                ]} />
                <CardField name="lossCauseCode" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="odata/crm/losscause" tableRelationKey="code" tableRelationField="description" label={t("adminCenter:leadCard:lossCauseCode")} />
                <CardField name="crmStatusCode" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="odata/crm/status/code" tableRelationKey="code" tableRelationField="description" tableRelationFilter="?$filter=entityName eq 'Lead'" label={t("adminCenter:leadCard:crmStatusCode")} />
                <CardField name="skipTimeZoneCheck" label="Salta il controllo per Time Zone" type={SystemFieldType.Boolean} />
                <CardField name="leadConversionEndpoint" label="Endpoint conversioni lead" type={SystemFieldType.String} />
                <CardField name="leadConversionToken" label="Token conversioni lead" type={SystemFieldType.String} />
            </CardGroup>
            <CardGroup name="filters" label="Filtri orari" isOpen={true}>
                <CardField name="lastDataSecurityChangeDateDelayHours" label="Ore da ultimo cambio stato" type={SystemFieldType.Number} />
            </CardGroup>
            <CardGroup name="conditions" label="Condizioni (OLD)" isOpen={false}>
                <CardField name="demoBooked" label="Estrae lead con demo prenotata" type={SystemFieldType.Boolean} onValidate={(fieldName: string, record: any, oldValue: any, newValue: any) => {
                    if (record.demoBooked)
                        record.demoNotBooked = false;
                    
                    return record;
                }} />
                <CardField name="demoNotBooked" label="Estrae lead senza demo prenotata" type={SystemFieldType.Boolean} onValidate={(fieldName: string, record: any, oldValue: any, newValue: any) => {
                    if (record.demoNotBooked)
                        record.demoBooked = false;
                    
                    return record;
                }} />
                <CardField name="demoCarriedOut" label="Estrae lead con demo effettuata" type={SystemFieldType.Boolean} onValidate={(fieldName: string, record: any, oldValue: any, newValue: any) => {
                    if (record.demoCarriedOut)
                        record.demoNotCarriedOut = false;
                    
                    return record;
                }} />
                <CardField name="demoNotCarriedOut" label="Estrae lead senza demo effettuata" type={SystemFieldType.Boolean} onValidate={(fieldName: string, record: any, oldValue: any, newValue: any) => {
                    if (record.demoNotCarriedOut)
                        record.demoCarriedOut = false;
                    
                    return record;
                }} />
                <CardField name="demoFeedbackRequired" label="Estrae lead con feedback demo richiesto" type={SystemFieldType.Boolean} onValidate={(fieldName: string, record: any, oldValue: any, newValue: any) => {
                    if (record.demoFeedbackRequired)
                        record.demoFeedbackNotRequired = false;
                    
                    return record;
                }} />
                <CardField name="demoFeedbackNotRequired" label="Estrae lead senza feedback demo richiesto" type={SystemFieldType.Boolean} onValidate={(fieldName: string, record: any, oldValue: any, newValue: any) => {
                    if (record.demoFeedbackNotRequired)
                        record.demoFeedbackRequired = false;
                    
                    return record;
                }} />
                <CardField name="demoFeedbackReceived" label="Estrae lead con feedback demo ricevuto" type={SystemFieldType.Boolean} onValidate={(fieldName: string, record: any, oldValue: any, newValue: any) => {
                    if (record.demoFeedbackReceived)
                        record.demoFeedbackNotReceived = false;
                    
                    return record;
                }} />
                <CardField name="demoFeedbackNotReceived" label="Estrae lead senza feedback demo ricevuto" type={SystemFieldType.Boolean} onValidate={(fieldName: string, record: any, oldValue: any, newValue: any) => {
                    if (record.demoFeedbackNotReceived)
                        record.demoFeedbackReceived = false;
                    
                    return record;
                }} />
                <CardField name="marketingQualificationCarriedOut" label="Estrae lead con qualifica marketing effettuata" type={SystemFieldType.Boolean} onValidate={(fieldName: string, record: any, oldValue: any, newValue: any) => {
                    if (record.marketingQualificationCarriedOut)
                        record.marketingQualificationNotCarriedOut = false;
                    
                    return record;
                }} />
                <CardField name="marketingQualificationNotCarriedOut" label="Estrae lead senza qualifica marketing effettuata" type={SystemFieldType.Boolean} onValidate={(fieldName: string, record: any, oldValue: any, newValue: any) => {
                    if (record.marketingQualificationNotCarriedOut)
                        record.marketingQualificationCarriedOut = false;
                    
                    return record;
                }} />
                <CardField name="trialRequested" label="Estrae lead con data richiesta trial" type={SystemFieldType.Boolean} onValidate={(fieldName: string, record: any, oldValue: any, newValue: any) => {
                    if (record.trialRequested)
                        record.trialNotRequested = false;
                    
                    return record;
                }} />
                <CardField name="trialNotRequested" label="Estrae lead senza data richiesta trial" type={SystemFieldType.Boolean} onValidate={(fieldName: string, record: any, oldValue: any, newValue: any) => {
                    if (record.trialNotRequested)
                        record.trialRequested = false;
                    
                    return record;
                }} />
                <CardField name="trialStarted" label="Estrae lead con data partenza trial" type={SystemFieldType.Boolean} onValidate={(fieldName: string, record: any, oldValue: any, newValue: any) => {
                    if (record.trialStarted)
                        record.trialNotStarted = false;
                    
                    return record;
                }} />
                <CardField name="trialNotStarted" label="Estrae lead senza data partenza trial" type={SystemFieldType.Boolean} onValidate={(fieldName: string, record: any, oldValue: any, newValue: any) => {
                    if (record.trialNotStarted)
                        record.trialStarted = false;
                    
                    return record;
                }} />
                <CardField name="riskLineExists" label="Estrae lead con rischi perdita" type={SystemFieldType.Boolean} onValidate={(fieldName: string, record: any, oldValue: any, newValue: any) => {
                    if (record.riskLineExists)
                        record.riskLineNotExists = false;
                    
                    return record;
                }} />
                <CardField name="riskLineNotExists" label="Estrae lead senza rischi perdita" type={SystemFieldType.Boolean} onValidate={(fieldName: string, record: any, oldValue: any, newValue: any) => {
                    if (record.riskLineNotExists)
                        record.riskLineExists = false;
                    
                    return record;
                }} />
                <CardField name="riskLineHandled" label="Estrae lead con rischi perdita gestiti" type={SystemFieldType.Boolean} onValidate={(fieldName: string, record: any, oldValue: any, newValue: any) => {
                    if (record.riskLineHandled)
                        record.riskLineNotHandled = false;
                    
                    return record;
                }} />
                <CardField name="riskLineNotHandled" label="Estrae lead senza rischi perdita gestiti" type={SystemFieldType.Boolean} onValidate={(fieldName: string, record: any, oldValue: any, newValue: any) => {
                    if (record.riskLineNotHandled)
                        record.riskLineHandled = false;
                    
                    return record;
                }} />
                <CardField name="decisionDatePresent" label="Estrae lead con data decisione" type={SystemFieldType.Boolean} onValidate={(fieldName: string, record: any, oldValue: any, newValue: any) =>{
                    if (record.decisionDatePresent)
                        record.decisionDateNotPresent = false;

                    return record;
                }} />
                <CardField name="decisionDateNotPresent" label="Estrae lead senza data decisione" type={SystemFieldType.Boolean} onValidate={(fieldName: string, record: any, oldValue: any, newValue: any) =>{
                    if (record.decisionDateNotPresent)
                        record.decisionDatePresent = false;

                    return record;
                }} />
                <CardField name="expectedLiveDatePresent" label="Estrae lead con data aspettativa live" type={SystemFieldType.Boolean} onValidate={(fieldName: string, record: any, oldValue: any, newValue: any) =>{
                    if (record.expectedLiveDatePresent)
                        record.expectedLiveDateNotPresent = false;

                    return record;
                }} />
                <CardField name="expectedLiveDateNotPresent" label="Estrae lead senza data aspettativa live" type={SystemFieldType.Boolean} onValidate={(fieldName: string, record: any, oldValue: any, newValue: any) =>{
                    if (record.expectedLiveDateNotPresent)
                        record.expectedLiveDatePresent = false;

                    return record;
                }} />
                <CardField name="isTrialLead" label="Estrae lead in stato Trial" type={SystemFieldType.Boolean} />
                <CardField name="trackingCodePresent" label="Estrae lead con Tracking code" type={SystemFieldType.Boolean} />
                <CardField name="ratingFilter" label="Effettua filtro su temperatura lead" type={SystemFieldType.Boolean} />
                <CardField name="leadRating" type={SystemFieldType.Option} label="Filtro temperatura lead" cmbOptions={[
                    {key: 0, text: "Fredda"},
                    {key: 1, text: "Tiepida"},
                    {key: 2, text: "Calda"}
                ]} />
            </CardGroup>
            <CardGroup name="dateFilters" label="Filtri su date (OLD)" isOpen={false}>
                <CardField name="activityDateDelayDays" label="Giorni limite ultima interazione" type={SystemFieldType.Number} />
                <CardField name="demoDateDelayDays" label="Giorni limite data demo" type={SystemFieldType.Number} />
                <CardField name="creationDaysStart" label="Giorni creazione lead da" type={SystemFieldType.Number} />
                <CardField name="creationDaysEnd" label="Giorni creazione lead a" type={SystemFieldType.Number} />
                <CardField name="trialRequestedDelayDays" label="Giorni limite data richiesta trial" type={SystemFieldType.Number} />
                <CardField name="trialStartedDelayDays" label="Giorni limite data partenza trial" type={SystemFieldType.Number} />
                <CardField name="customerInteractionDateDelayDays" label="Giorni limite data interazioni cliente" type={SystemFieldType.Number} />
                <CardField name="decisionDateDelayDays" label="Giorni limite data decisione" type={SystemFieldType.Number} />
                <CardField name="expectedLiveDateDelayDays" label="Giorni limite data aspettativa live" type={SystemFieldType.Number} />
            </CardGroup>
            <CardGroup name="quantityFilters" label="Filtri su quantità (OLD)" isOpen={false}>
                <CardField name="weekInteractionsLimit" label="Limite massimo interazioni settimanali" type={SystemFieldType.Number} />
                <CardField name="monthInteractionsLimit" label="Limite massimo interazioni mensili" type={SystemFieldType.Number} />
                <CardField name="minimumBudgetLimit" label="Estrae lead con budget maggiore di questo valore" type={SystemFieldType.Number} />
                <CardField name="maximumBudgetLimit" label="Estrae lead con budget minore di questo valore" type={SystemFieldType.Number} />
                <CardField name="scoreModelId" label="Effettua filtri su questo score model" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="/odata/crm/lead/score/model" tableRelationKey="crmReferenceId" tableRelationField="name" />
                <CardField name="minimumModelScoreLimit" label="Estrae lead con score maggiore di questo valore" type={SystemFieldType.Number} />
                <CardField name="maximumModelScoreLimit" label="Estrae lead con score minore di questo valore" type={SystemFieldType.Number} />
            </CardGroup>
        </PageContainer>
    );
});