import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import { SystemActionCategory,SystemDataLoadingStatus,SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminAzureSetupCard } from "../AdminAzureSetupCard/AdminAzureSetupCard";
import { AdminChatbotSetupCard } from "../AdminChatbotSetupCard/AdminChatbotSetupCard";
import { AdminCrmSetupCard } from "../AdminCrmSetupCard/AdminCrmSetupCard";


export const AdminChatbotSetupList : React.FC = () => {
    const cardRef = useRef<PageContainerBase>(null);
    
        return (
            <>
                <PageContainer endpoint={'/odata/chatbot/setup'} pageType={SystemPageType.List} title={"Chatbot setup"} cardRef={cardRef} backAllowed insertAllowed updateAllowed>               
                    <PageDefinitionContainer type={ContainerType.Action}>
                    </PageDefinitionContainer>
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry fieldName="primaryKey" name="Chiave primaria" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="botName" name="Nome Bot" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="welcomeMessage" name="Messaggio di benvenuto" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="languageEndpointKey" name="Chiave lingua endpoint" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="languageEndpointHostName" name="Nome host endpoint linguaggio" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="scoreThreshold" name="Soglia punteggio" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="topAnswers" name="Migliori risposte" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="isTest" name="Test" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="enablePreciseAnswer" name="Abilita risposte precise" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="videoLinkMinutesDuration" name="video link duration" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="includeUnstructuredSources" name="Includi sorgenti non strutturate" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="activeLearningCardText" name="Testo card" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="activeLearningCardTitle" name="Titolo card" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="cardNoMatchText" name="Card senza corrispondenza" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="noAnswerText" name="Testo senza risposta" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="displayPreciseAnswerOnly" name="Visualizza solo risposte precise" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="qaResourceName" name="Risorsa qa" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="qaBaseEndpoint" name="Endpoint qa" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="qaApiKey" name="Qa api key" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="qaDefaultLanguage" name="Ligua default qa" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="qaProjectName" name="Nome progetto qa" minWidth={100} maxWidth={200} />
                    </PageDefinitionContainer>
                </PageContainer>
                <AdminChatbotSetupCard ref={cardRef} />
            </>
        );
}