import { Dialog, DialogFooter, DialogType, DirectionalHint, Label, mergeStyles, PrimaryButton, Stack } from "@fluentui/react";
import { AxiosInstance } from "axios";
import React from "react";
import { DayBlock } from "../DayBlock/DayBlock";
import { DemoContainer } from "../DemoContainter/DemoContainer";
import { DemoSalePopup } from "../DemoSalePopup/DemoSalePopup";
import { DemoVideoModal } from "../DemoVideoModal/DemoVideoModal";
import { DemoUIHelper, IDemoDashboardData } from "../DemoUIHelper";
import { DemoTeachingQuestion } from "../DemoTeachingQuestion/DemoTeachingQuestion";
import { TrialModal } from "../TrialModal/TrialModal";
import { BookingModal } from "../BookingModal/BookingModal";
import { DayEntry, ECommerceCustomer, ECommerceUser, GuideTourEntry, ShowcaseEntry, TrialData } from "../../../Model/DemoModels";
import { ISessionStateContext, SessionStateContext } from "../../../Core/State/SessionStateContext";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { User } from "../../../Model/SystemModels";
import { ShowcaseComponent } from "../VideoShowcase/VideoShowcase";
import { FeatureOverviewContainer } from "../FeatureOverviewContainer/FeatureOverviewContainer";
import { Loader } from "../../Common/Loader/Loader";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { SystemCore } from "../../../Core/System/SystemCore";
import { HttpConstants } from "../../../Core/Http/HttpConstants";
import { CommonFunctions } from "../../../ApplicationCode/Common/CommonFunctions";
import { Lead } from "../../../Model/PartnerModels";
import { CustomerTrialStatus } from "../../../Model/CustomerModels";
import { ChatBot } from "../../Common/Dashboard/DashboardComponents";
import Plyr, { APITypes } from 'plyr-react';
import "plyr-react/plyr.css"
import { Navigate, NavigateFunction, useNavigate } from "react-router-dom";
import { CustomerService } from "../../../ApplicationCode/Customer/CustomerService";
import { t } from "i18next";
import { googleAnalyticsManagement } from "../../../ApplicationCode/Common/GoogleAnalyticsManagement";
 
interface DemoDashboardState {
    showGuideTour: boolean;
    dataLoading: boolean;
    showContent: boolean;
    showQuestion: boolean;
    currentGuideEntryIndex: number;
    scrollTimer: number;
    videoUrl: string;
    videoDescription: string;
    dashboardData: IDemoDashboardData;
    trialData: TrialData;
    trialDataSaving: boolean;
    showTrialModal: boolean;
    showBookingModal: boolean;
    trialValidationErrors: object[];
};
    
const botButtonClassName = mergeStyles([{
    backgroundColor: '#007bff !important',
    borderColor: '#007bff !important',
    width: "100px",
    height: "100px",
    borderRadius:"100%",
    fontSize: 16,
    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
    selectors:{
        ':hover':{
            backgroundColor: '#007bfg !important',
            borderColor: '#007bfg, 99) !important'
        }
    }
}])

const chatContainerClassName = mergeStyles([{
    backgroundColor:'#007bff',
    padding:2,
    height:450
}])

export class DemoDashboard extends React.Component<{}, DemoDashboardState> {
    
    static contextType = SessionStateContext;

    constructor(props: {})
    {
        super(props);
        
        this.state = {
            showGuideTour: false, 
            showQuestion: ! this.isHelpSession(),
            showContent: false, 
            showTrialModal: false,
            showBookingModal: false,
            dataLoading: false,
            trialDataSaving: false,
            currentGuideEntryIndex: 0,
            scrollTimer: 0,
            videoUrl: "",
            videoDescription: "",
            dashboardData: {} as IDemoDashboardData,
            trialData: new TrialData(),
            trialValidationErrors: []
        };
    }

    componentDidMount = () => {
        //Google analytics
        const analytics = new googleAnalyticsManagement();
        analytics.sendPageViewLog();
    }

    isHelpSession = () : boolean => {
        let sourceAddress = HttpHelper.getParameter("sourceAddress");
        return ((sourceAddress != undefined) && (sourceAddress != null) && (sourceAddress != ""));
    }

    onNextTuireGuide = () => {
        let index = this.state.currentGuideEntryIndex + 1;

        if (index == this.state.dashboardData.guideTourEntries.length)
        {
            window.scrollTo(0,0);
            this.setState({
                showGuideTour: false
            });
            CommonFunctions.logEvent("Conclusione tour guidato", "");
        }
        else
        {
        
            this.setState({
                currentGuideEntryIndex: index
            });
            CommonFunctions.logEvent("Inizio step " + index + " del tour guidato", "");
        }
    }

    onCancelGuideTour = () => {
        this.setState({
            showGuideTour: false,
            currentGuideEntryIndex: 0
        });

        CommonFunctions.logEvent("Chiusura del tour guidato", "");
    }

    onFinishScroll = () => {
        this.setState({
            scrollTimer: 0
        });
    }

    onClickTrialPopup = () => {
        if (this.state.dashboardData.lead.id == 0)
            ToastService.showMessage(MessageType.Error, t("demoCenter:Err00001Text"));
        else
        {
            CommonFunctions.logEvent("Apertura form per prova gratuita", "");
            DemoUIHelper.loadLead(HttpHelper.buildAxiosInstance(), this.state.trialData.currentLead.id)
                .then((resp) => {
                    if(resp!.isTrial > 0){
                        let core: SystemCore = new SystemCore(); 
                        window.location.replace((process.env.REACT_APP_CUSTOMER_PORTAL_ADDRESS+btoa(core.getUserToken())))
                    }
                    else
                    {
                        let currentResp = resp!;
                        currentResp.city = "";
                        currentResp.county = "";
                        currentResp.country = "";

                        this.setState({
                            trialData: {
                                confirmPassword: this.state.trialData.confirmPassword,
                                currentLead: currentResp!,
                                customerUser: this.state.trialData.customerUser
                            },
                            showTrialModal: true
                        });
                    }
                });
        }
    }

    onSaveTrialData = (trialData: TrialData) => {
        this.setState({
            trialDataSaving: true,
            trialValidationErrors: []
        });

        let lead = trialData.currentLead;
        delete (lead as any).timestamp;
        trialData.currentLead = lead;

        trialData.customerUser.customer.isTrial = CustomerTrialStatus.On;
        DemoUIHelper.createTrialCustomerUser(trialData, HttpHelper.buildAxiosInstance()).then((response) => {
            if (response.validationErrors.length > 0)
                this.setState({ 
                    trialValidationErrors: response.validationErrors, 
                    trialData: trialData,
                    trialDataSaving: false
                });
            else
            {
                this.setState({
                    showTrialModal: false,
                    trialDataSaving: false,
                    trialValidationErrors: [],
                    trialData: trialData
                });

                ToastService.showMessage(MessageType.Success, t('demoCenter:Text00001Lbl'));

                CommonFunctions.logEvent("Conferma prova gratuita", "");
                let core: SystemCore = new SystemCore(); 
                window.location.replace((process.env.REACT_APP_CUSTOMER_PORTAL_ADDRESS + btoa(core.getUserToken())));
            }
        });
    }

    onCloseTrialModal = async () => {
        let instance: ISessionStateContext = this.context;
        let trialData = this.initTrialData(this.state.dashboardData, instance.currentUser);

        this.setState({
            showTrialModal: false,
            trialData: trialData,
            trialValidationErrors: [],
            trialDataSaving: false
        });
        CommonFunctions.logEvent("Chiusura form trial senza conferma", "");
    }

    onDismissDialog = (ev: any) => {
        if (ev.type === 'scroll') {
            if (this.state.scrollTimer <= 0)
            {
                this.setState({
                    scrollTimer: window.setTimeout(this.onFinishScroll, 250)
                });
            }
            return;
        }
    };

    onRetryClick = () => {
        this.setState({
            showGuideTour: false, 
            showQuestion: true,
            showContent: false, 
            showTrialModal: false,
            showBookingModal: false,
            dataLoading: false,
            trialDataSaving: false,
            currentGuideEntryIndex: 0,
            scrollTimer: 0,
            videoUrl: "",
            videoDescription: "",
            dashboardData: {} as IDemoDashboardData,
            trialData: {} as TrialData,
            trialValidationErrors: []
        });
    }

    openDayVideo = async (entry: DayEntry) => {
        this.setState({
            videoUrl: '',
            videoDescription: entry.description
        });

        CommonFunctions.logEvent("Apertura video dialog: " + entry.description, entry.sourceFeature.videoLink)
            .then(async () => {
                let client: AxiosInstance = HttpHelper.buildAxiosInstance();
                let response = await client.get("api/csp/video/" + entry.sourceFeature.videoLink + "/generate");
                if (response.status == 200)
                {
                    this.setState({
                        videoUrl: response.data
                    });
                }
            });
    };

    onOpenBookingModal = () => {
        this.setState({
            showBookingModal: true
        });
        CommonFunctions.logEvent("Apertura form booking per prenotazione consulente", "");
    }

    onCloseBookingModal = () => {
        this.setState({
            showBookingModal: false
        });

        CommonFunctions.logEvent("Chiusura form booking per prenotazione consulente", "");
    }

    onTeachingQuestionSuccess = () => {
        this.setState({
            showGuideTour: true,
            showQuestion: false
        });
    };

    onTeachingQuestionDismiss = () => {
        this.setState({
            showGuideTour: false,
            showQuestion: false
        });
    };

    initTrialData = (data: IDemoDashboardData, currentUser: User) : TrialData => {
        let trialData = new TrialData();
        trialData.confirmPassword = "";
        trialData.currentLead = data.lead;
        trialData.customerUser.customer = new ECommerceCustomer();
        trialData.customerUser.user = new ECommerceUser();
        trialData.customerUser.user.firstName = currentUser.firstName;
        trialData.customerUser.user.lastName = currentUser.lastName;
        trialData.customerUser.user.privateEmail = currentUser.email;
        trialData.customerUser.user.leadId = currentUser.referenceId;
        return trialData;
    };

    loadDashboardData = async () => {
        this.setState({
            dataLoading: true
        });

        CommonFunctions.logEvent("Apertura dashboard", "")
            .then((resp) => {
                let currentUser: User = this.context.currentUser;
                DemoUIHelper.loadData(HttpHelper.buildAxiosInstance(), currentUser,this.context).then((data) => {
                    let trialData = this.initTrialData(data, currentUser);
        
                    this.setState({
                        dashboardData: data,
                        trialData: trialData,
                        dataLoading: false,
                        showContent: true
                    });
                });
            });
    };

    render() {
        document.title = this.context.currentApp.title;
        const ref = React.createRef<APITypes>();
    
        ref.current?.plyr?.on('ready',()=>alert());

        var containerStyle = mergeStyles([{
            top: '50px', 
            position: 'absolute', 
            maxHeight: 'calc(100vh - 50px)',
            overflowY: "auto", 
            width: '100%'
        }]);

        if (this.state.showContent)
        {
            let teachingGuideTour: JSX.Element = <></>;
            if (this.state.showGuideTour)  {
                containerStyle = mergeStyles([{
                    top: '50px', 
                    position: 'absolute', 
                    maxHeight: 'calc(100vh - 50px)',
                    overflowY: "auto", 
                    width: '100%',
                    overflow: 'hidden'
                }]);

                let currentEntry: GuideTourEntry = this.state.dashboardData.guideTourEntries[this.state.currentGuideEntryIndex];
                teachingGuideTour = DemoUIHelper.createDemoTeachingGuideTour(currentEntry, this.state.currentGuideEntryIndex + 1, this.state.dashboardData.guideTourEntries.length, this.onNextTuireGuide, this.onDismissDialog, this.onCancelGuideTour);
            }

            let dayBlockContent = DemoUIHelper.createDayBlockEntries(this.state.dashboardData.dayEntryies, this.openDayVideo);
            let msFeaturesBlocks = DemoUIHelper.createMsFeatureBlocks(this.state.dashboardData.microsoftFeatures);
            
            let errorPopup = <></>;
            let currentContext: ISessionStateContext = this.context;

            let chatBot = <></>;
            if (currentContext.currentApp.showChatBot){
                chatBot = <>
                    <ChatBot
                        showOpen={this.isHelpSession()}
                        targetId="chatbot"
                        botTitle="So Smart - Chat Bot"
                        chatContainerStyle={chatContainerClassName}
                        botButtonStyle={botButtonClassName}
                    />
                </>;
            }

            if (this.state.dashboardData.hasError)
            {
                errorPopup = <>
                    <Dialog
                        hidden={false}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: t('demoCenter:ErrorDialog:title')!,
                            subText: t('demoCenter:ErrorDialog:subText')!,
                        }}
                        modalProps={{
                            styles: {
                                main: {
                                    minWidth: '500px !important',
                                    maxWidth: '800px !important'
                                }
                            }
                        }}
                    >
                        <DialogFooter>
                            <PrimaryButton onClick={this.onRetryClick} text={t('demoCenter:ErrorDialog:primaryButtonText')!} />
                        </DialogFooter>
                    </Dialog>
                </>;
            }

            //#region Shortcut TRIAL

            const shortCutTrial = HttpHelper.getParameter('shortCutTrial');
    
            if((shortCutTrial === 'true') && (shortCutTrial != null)){
                DemoUIHelper.loadLead(HttpHelper.buildAxiosInstance(), this.state.trialData.currentLead.id)
                    .then((resp) => {
                        if(resp!.isTrial > 0){
                            ToastService.showMessage(MessageType.Error,t('demoCenter:Err00002Text'))
                        }
                        else
                            this.setState({showTrialModal: true});
                        
                        window.history.replaceState('', '', window.location.origin+window.location.pathname);
                    });
            }

            //#endregion

            return (
                <div className={containerStyle}>
                    <DemoContainer id="showcaseProcess" linkToId="featuresOverview" scrollId="showcaseProcessScroll" title={t('demoCenter:DemoContainer:showcaseProcessTitle')!}>
                        <ShowcaseComponent listElementId="listElement1" videoElementId="videoElement1" currentEntry={{} as ShowcaseEntry} entries={this.state.dashboardData.integratedSystemEntries}></ShowcaseComponent>
                    </DemoContainer>
                    <FeatureOverviewContainer linkToId="showcaseIntegratedSystem" modules={this.state.dashboardData.modules} features={this.state.dashboardData.features} refreshData={() => {}}></FeatureOverviewContainer>
                    <DemoContainer linkToId="timelineSoSmart" id="showcaseIntegratedSystem" scrollId="showcaseIntegratedSystemScroll" title={t('demoCenter:DemoContainer:showcaseIntegratedSystemTitle')!}>
                        <ShowcaseComponent listElementId="listElement2" videoElementId="videoElement2" currentEntry={{} as ShowcaseEntry} entries={this.state.dashboardData.productivityEntries}></ShowcaseComponent>
                    </DemoContainer>
                    <DemoContainer scrollId="" linkToId="msFeatures" id="timelineSoSmart" title={t('demoCenter:DemoContainer:timelineSoSmartTitle')!}>
                        <Stack style={{width: '100%'}} id="timelineContent">
                            <Stack.Item>
                                <Stack horizontal style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    padding: 15,
                                    paddingTop: '40px',
                                    paddingBottom: '40px',
                                    maxHeight: 180
                                }}>
                                    {dayBlockContent}
                                </Stack>
                            </Stack.Item>
                        </Stack>
                    </DemoContainer>
                    <DemoContainer id="msFeatures" scrollId="" linkToId="" title={t('demoCenter:DemoContainer:msFeaturesTitle')!}>
                        <Stack style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: "center",
                            padding: 10
                        }}
                        id="a5a5a5"
                        horizontal
                        >
                            {msFeaturesBlocks}
                        </Stack>
                    </DemoContainer>
                    <DemoContainer scrollId="" linkToId="" id="" title="">
                        <Stack style={{
                            padding: '15px',
                            fontSize: 18,
                            backgroundColor: '#5f8ab8',
                            color: 'white',
                            fontWeight: 600,
                            width: '100%'
                        }}>
                            <label>
                                {t('demoCenter:DemoContainer:Stack:bookingLabelContent')!}&nbsp;
                                <a href="#"
                                style={{
                                    color: 'white',
                                    textDecoration: 'underline'
                                }} 
                                   onClick={this.onOpenBookingModal}>{t('demoCenter:DemoContainer:Stack:bookingLabelLink')!}</a> 
                            </label>
                        </Stack>
                    </DemoContainer>
                    <DemoVideoModal
                                videoUrl={this.state.videoUrl} 
                                videoName={this.state.videoDescription} 
                                hideModal={(this.state.videoDescription != "" && this.state.videoDescription != undefined)} 
                                onCloseModal={() => {
                                    CommonFunctions.logEvent("Chiusura video dilog:"+this.state.videoDescription,this.state.videoUrl);
                                    this.setState({ videoUrl: "", videoDescription: "" });
                                }} ></DemoVideoModal>                    
                    {teachingGuideTour}
                    <DemoTeachingQuestion showDialog={this.state.showQuestion} 
                                          onSuccess={this.onTeachingQuestionSuccess}
                                          OnDismiss={this.onTeachingQuestionDismiss}></DemoTeachingQuestion>
                    <DemoSalePopup onClick={this.onClickTrialPopup}></DemoSalePopup>
                    <BookingModal onSave={this.onCloseBookingModal} 
                                  showModal={this.state.showBookingModal}></BookingModal>
                    {chatBot}
                    {errorPopup}
                </div>
            );
        }
        else {
            if (! this.state.dataLoading)
                this.loadDashboardData();

            return <>
                <Loader text={t('demoCenter:Loader:messageContent')!}/>
            </>;
        }
    }

}