import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminWizardLineStructureCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda struttura step wizard"} endpoint="/odata/ui/wizard/line/structure" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="name" type={SystemFieldType.String} label="Nome" />
                <CardField name="label" type={SystemFieldType.String} label="Caption" />
                <CardField name="type" type={SystemFieldType.Option} label="Tipo" cmbOptions={[{
                    key: 0,
                    text: "Testo"
                },
                {
                    key: 1,
                    text: "Numero"
                },
                {
                    key: 2,
                    text: "Boolean"
                },
                {
                    key: 3,
                    text: "Data"
                }]} />
                <CardField name="order" type={SystemFieldType.Number} label="Ordinamento" />
            </CardGroup>
        </PageContainer>
    );
});