import React, { useRef, useState } from "react";
import { SystemActionCategory, SystemDataLoadingStatus, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { useNavigate } from "react-router-dom";
import { AdminChatbotProfileCard } from "../AdminChatbotProfileCard/AdminChatbotProfileCard";
import { AdminChatbotBusinesLineCard } from "../AdminChatbotBusinessLineCard/AdminChatbotBusinessLineCard";
import { ToastService } from "../../../../Core/Toast/ToastService";
import { AssignedChatbotBusinessLine } from "../../../../Model/AdminModel";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";

export const AdminChatbotProfileList : React.FC = () => {
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [profileCode, setProfileCode] = useState<string>("");
    const navigate = useNavigate();

    const cardRef = useRef<PageContainerBase>(null);
    const adminService = new AdminService();

    const onSavechatbotBusinessLine = (modifiedBusinessLines: AssignedChatbotBusinessLine[]) => {
        adminService.modifyAssignedBusinessLine(modifiedBusinessLines)
            .then(() => {
                setShowDialog(false);
            });
    }

    return (
        <>
            <PageContainer endpoint="/odata/chatbot/profile" pageType={SystemPageType.List} title="Profili chatbot" backAllowed insertAllowed updateAllowed deleteAllowed cardRef={cardRef}>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Process}>
                        <ActionEntry name="openChatbotBusinessLine" isPromoted  label="Associa business line" iconName="Link" onClick={(req:any)=>{
                            setProfileCode(req.code);
                            setShowDialog(true);
                        }}>
                        </ActionEntry>
                    </ActionArea>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="translations" isPromoted  label="Traduzioni" iconName="Translate" onClick={(req:any)=>{
                            navigate("/app/admin/administrative/translations?sourceId=" + req.systemId);
                        }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry fieldName="code" name="Codice" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="description" name="Descrizione" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry fieldName="origin" name="Origine" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry fieldName="defaultContext" name="Default context" minWidth={100} maxWidth={200} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminChatbotProfileCard ref={cardRef} />
            <AdminChatbotBusinesLineCard 
                showDialog={showDialog}
                profileCode={profileCode}
                onSuccessModal={(result)=>{
                    onSavechatbotBusinessLine(result);
                }}
                onCancelModal={() => {
                    setShowDialog(false);
                }}
            />
        </>
    );
}