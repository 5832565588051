import { createContext, useState } from "react";
import { MenuEntry, SystemApp, User, UserSettings } from "../../Model/SystemModels";
import { SystemCore } from "../System/SystemCore";

export interface ISessionStateContext {
    isAuthenticated: boolean;
    currentUser: User;
    currentApp: SystemApp;
    currentUserSettings: UserSettings;
    entries: MenuEntry[];
    requestedPath: string;
}

let systemCore: SystemCore = new SystemCore();
let defaultValue: ISessionStateContext = {} as ISessionStateContext;
defaultValue.currentApp = new SystemApp();
defaultValue.currentUser = new User();
defaultValue.currentUserSettings = new UserSettings();
defaultValue.entries = [];

// Check if is authenticated
let accessToken = systemCore.getUserToken();
if (accessToken != "")
{
    // TODO: Add access token verify
    defaultValue.isAuthenticated = true;
}
else
{
    defaultValue.isAuthenticated = false;
}

export const SessionStateContext = createContext<ISessionStateContext>(defaultValue);
export const SessionStateContextProvider : React.FC = ({ children }) => {
    const [isAuthenticated, setIsAuthenricated] = useState(defaultValue.isAuthenticated);
    const [currentUser, setCurrentUser] = useState(defaultValue.currentUser);
    const [currentApp, setCurrentApp] = useState(defaultValue.currentApp);
    const [currentUserSettings, setCurrentUserSettings] = useState(defaultValue.currentUserSettings);
    const [entries, setEntries] = useState(defaultValue.entries);
    const [currentEntry, setCurrentEntry] = useState(new MenuEntry());
    const [requestedPath, setRequestedPath] = useState("");

    return (
        <SessionStateContext.Provider value={{
            currentApp: currentApp,
            currentUser: currentUser,
            currentUserSettings: currentUserSettings,
            isAuthenticated: isAuthenticated,
            entries: entries,
            requestedPath: requestedPath
        }}>
            {children}
        </SessionStateContext.Provider>
    );

}