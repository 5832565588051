import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { SystemActionCategory, SystemOperation, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminNotificationGroupMemberCard } from "../AdminNotificationGroupMemberCard/AdminNotificationGroupMemberCard";
import { AdminWizardHeaderCard } from "./AdminWizardHeaderCard";
import { AdminWizardLineCard } from "./AdminWizardLineCard";

export const AdminWizardLineList : React.FC = () => {
    const wizardCode = HttpHelper.getParameter("wizardCode");
    const cardRef = useRef<PageContainerBase>(null);
    const navigate = useNavigate();

    return (
        <>
            <PageContainer endpoint={"/odata/ui/wizard/line?$filter=wizardCode eq '" + wizardCode + "'&$orderby=order"} pageType={SystemPageType.List} title={"Step wizard " + wizardCode} cardRef={cardRef} backAllowed insertAllowed updateAllowed deleteAllowed onNewRecord={() => {
                cardRef.current?.setRecord({
                    wizardCode: wizardCode
                });
                cardRef.current?.openPage(SystemOperation.Insert);
            }}>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="SystemCaption" label="Traduzioni" iconName="Globe2" runOnRec onClick={(rec:any)=>{
                            navigate('/app/admin/related/systemCaption?sourceId='+rec.systemId+"&sourceEntity=WizardLine")
                        }}>
                        </ActionEntry>
                        <ActionEntry label="Struttura" iconName="Design" name="ViewStructure" runOnRec isPromoted onClick={(record) => {
                            navigate("/app/admin/ui/wizard/structures?wizardCode=" + record.wizardCode + "&wizardLineNo=" + record.lineNo);
                        }} />
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Tipo" fieldName="type" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Titolo" fieldName="title" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Sotto titolo" fieldName="subtitle" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Ignorabile" fieldName="canSkip" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Durata" fieldName="duration" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Ordinamento" fieldName="order" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Gruppo" fieldName="isGroup" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Tipo input" fieldName="inputType" minWidth={100} maxWidth={200} onRender={(item: any, index, column) => {
                            if (item.inputType === 0)
                                return "Testo"
                            else if (item.inputType === 1)
                                return "Numero"
                            else if (item.inputType === 2)
                                return "Data"
                            else if (item.inputType === 3)
                                return "Booleano"
                        }} 
                    />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminWizardLineCard ref={cardRef} />
        </>
    );
}