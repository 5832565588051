import { mergeStyles } from "@fluentui/react";
import React, { forwardRef } from "react";
import { PageStructureMetadata, SystemFieldType, SystemPage, SystemPageStructureType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";

export interface IAdminPageDesignerFieldListProps 
{
    page: SystemPage;
}

const detailsListClass = mergeStyles({
  maxHeight: 'calc(100vh - 150px)',
  overflowY: 'auto',
  overflowX: 'hidden'
});

const dragEnterClass = mergeStyles({
  backgroundColor: 'red',
  cursor: 'pointer !important'
});

export const AdminPageDesignerAddPanel = forwardRef<PageContainerBase, React.PropsWithChildren<IAdminPageDesignerFieldListProps>>((props, ref) => {
  return (
    <PageContainer pageType={SystemPageType.CardPanel} endpoint="" title={"Aggiungi elemento"} ref={ref}>
      <CardGroup name="general" label="Generale" isOpen>
        <CardField name="pageStructure.type" label="Tipo" type={SystemFieldType.Option} cmbOptions={[
          {key: -1, text: 'Seleziona un valore' },
          {key: 0, text: 'Campo' },
          {key: 1, text: 'Gruppo'}
        ]} />
        <CardField name="pageStructure.name" label="Nome campo" type={SystemFieldType.String}
          hasTableRelation
          tableRelationEndpoint="odata/system/entity/property"
          tableRelationFilter={"?$filter=entityName eq '" + props.page.sourceEntityName + "'"}
          tableRelationField="name"
          tableRelationKey="name"
          onGetIsVisible={(record: PageStructureMetadata) => {
            if (record.pageStructure == undefined)
              return false;
            
            if (record.pageStructure.type == SystemPageStructureType.Field)
              return true;
              
            return false;
        }}/>
        <CardField name="pageStructure.name" label="Nome gruppo" type={SystemFieldType.String} onGetIsVisible={(record: PageStructureMetadata) => {
          if (record.pageStructure == undefined)
            return false;
            
          return (record.pageStructure.type == SystemPageStructureType.Group);
        }}/>
        <CardField name="userLanguageCaption" label="Caption" type={SystemFieldType.String} onGetIsVisible={(record: PageStructureMetadata) => {
          if (record.pageStructure == undefined)
            return false;
          
          return (record.pageStructure.type == SystemPageStructureType.Group);
        }}/>
      </CardGroup>
    </PageContainer>
  );
});