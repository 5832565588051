import React, { useRef } from "react";
import { SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminPartnerBusinessContractCard } from "../AdminPartnerBusinessContractCard/AdminPartnerBusinessContractCard";

export const AdminPartnerBusinessContractList : React.FC = () => {

    const cardRef = useRef<PageContainerBase>(null);
    
    return (
        <>
            <PageContainer endpoint="/odata/partner/business/contract" pageType={SystemPageType.List} title="Partner business contract" cardRef={cardRef} backAllowed updateAllowed insertAllowed>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Codice" fieldName="code" minWidth={200} maxWidth={200} />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={200} maxWidth={200} />
                    <ListHeaderEntry name="Sincronizzazione partner account attiva" fieldName="partnerAccountCrmSyncActive" minWidth={80} maxWidth={80} />
                    <ListHeaderEntry name="Filtro sincronizzazione partner account CRM" fieldName="partnerAccountCrmOdataFilter" minWidth={350} maxWidth={350} />
                    <ListHeaderEntry name="Sincronizzazione lead attiva" fieldName="leadCrmSyncActive" minWidth={80} maxWidth={80} />
                    <ListHeaderEntry name="Filtro sincronizzazione lead CRM" fieldName="leadCrmOdataFilter" minWidth={350} maxWidth={350} />
                    <ListHeaderEntry name="Data ultima sincronizzazione lead" fieldName="leadLastSyncDate" minWidth={350} maxWidth={350} />
                    <ListHeaderEntry name="Gestione utente da lead" fieldName="leadManageUser" minWidth={350} maxWidth={350} />
                    <ListHeaderEntry name="Lead deal category Id" fieldName="leadDealCategoryId" minWidth={350} maxWidth={350} />
                    <ListHeaderEntry name="Sincronizzazione interazioni lead attiva" fieldName="leadInteractionCrmSyncActive" minWidth={80} maxWidth={80} />
                    <ListHeaderEntry name="Codici stato" fieldName="dataSecurityCodes" minWidth={80} maxWidth={80} />
                    <ListHeaderEntry name="Sincronizzazione incident attiva" fieldName="incidentCrmSyncActive" minWidth={350} maxWidth={350} />
                    <ListHeaderEntry name="Filtro sincronizzazione incident CRM" fieldName="incidentCrmOdataFilter" minWidth={350} maxWidth={350} />
                    <ListHeaderEntry name="Filtro sincronizzazione entitlement CRM" fieldName="entitlementCrmOdataFilter" minWidth={350} maxWidth={350} />
                    <ListHeaderEntry name="Data ultima sincronizzazione incident" fieldName="incidentLastSyncDate" minWidth={350} maxWidth={350} />
                    <ListHeaderEntry name="Data ultima sincronizzazione entitlement" fieldName="entitlementLastSyncDate" minWidth={350} maxWidth={350} />
                    <ListHeaderEntry name="Incident queue Id" fieldName="incidentQueueId" minWidth={350} maxWidth={350} />
                    <ListHeaderEntry name="Incident entitlement name" fieldName="incidentEntitlementName" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Incident service scope Id" fieldName="incidentServiceScopeId" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Default app Id" fieldName="defaultAppId" minWidth={250} maxWidth={250} />
                    <ListHeaderEntry name="Default codice Ruolo" fieldName="defaultRoleCode" minWidth={250} maxWidth={250} />
                    <ListHeaderEntry name="ERP channel dimension" fieldName="erpChannelDimension" minWidth={200} maxWidth={200} />
                    <ListHeaderEntry name="Articolo richiesto" fieldName="isItemRequired" minWidth={150} maxWidth={200} />
                    <ListHeaderEntry name="Id calendario Booking" fieldName="bookingCalendarId" minWidth={150} maxWidth={200} />
                    <ListHeaderEntry name="Id gruppo Sharepoint" fieldName="sharepointGroupId" minWidth={150} maxWidth={200} />
                    <ListHeaderEntry name="Sospensione Lead automatica" fieldName="suspendedLeadAutomaticEnabled" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Giorni sospensione da creazione" fieldName="suspendedLeadDaysCreation" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Giorni sospensione da ultimo inbound" fieldName="suspendedLeadDaysLastInbound" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Giorni sospensione da data scelta" fieldName="suspendedLeadDaysFromDecisionDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Data Security Code sospensione" fieldName="suspendedLeadDataSecurityCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Codice wizard qualifica lead" fieldName="leadQualificationWizardCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Codice wizard checklist lead" fieldName="leadChecklistWizardCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Perdita Lead automatica" fieldName="lostLeadAutomaticEnabled" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Giorni perdita da creazione" fieldName="lostLeadDaysCreation" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Giorni perdita da ultimo inbound" fieldName="lostLeadDaysLastInbound" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Giorni perdita da data scelta" fieldName="lostLeadDaysFromDecisionDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Data Security Code perdita" fieldName="lostLeadDataSecurityCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Business line" fieldName="businessLineCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Gestione licenze CSP abilitata" fieldName="cspLicenseManagementEnable" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminPartnerBusinessContractCard ref={cardRef} /> 
        </>
    );
}