import React, { useRef } from "react";
import { SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminChatbotTicketInputCard } from "../AdminChatbotTicketInputCard/AdminChatbotTicketInputCard";

export const AdminChatbotTicketInputList : React.FC = () => {
    const cardRef = useRef<PageContainerBase>(null);

    return (
        <>
            <PageContainer endpoint="/odata/chatbot/ticket/input" pageType={SystemPageType.List} title="Ticket chatbot" backAllowed showAllowed cardRef={cardRef}>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry fieldName="entryNo" name="Id" minWidth={50} maxWidth={200} />
                    <ListHeaderEntry fieldName="uploadState" name="Upload State" minWidth={100} maxWidth={200} onRender={(item: any, index, column) => {
                        if (item.uploadState === 0)
                            return "In coda"
                        else if (item.uploadState === 1)
                            return "Caricato"
                        else if (item.uploadState === 2)
                            return "In errore"
                    }} />
                    <ListHeaderEntry fieldName="origin" name="Origin" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry fieldName="email" name="Email" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry fieldName="leadId" name="Lead Id" minWidth={270} maxWidth={400} />
                    <ListHeaderEntry fieldName="itemId" name="Item Id" minWidth={100} maxWidth={400} />
                    <ListHeaderEntry fieldName="incidentType" name="Incident Type" minWidth={100} maxWidth={400} />
                    <ListHeaderEntry fieldName="customerId" name="Customer Id" minWidth={270} maxWidth={400} />
                    <ListHeaderEntry fieldName="customerType" name="Customer Type" minWidth={100} maxWidth={400} onRender={(item: any, index, column)=>{
                        if (item.customerType === 0)
                            return "OnPrem"
                        else if (item.customerType === 1)
                            return "Saas"
                    }} />
                    <ListHeaderEntry fieldName="subscriptionType" name="Subscription Type" minWidth={120} maxWidth={400} onRender={(item: any, index, column)=>{
                        if (item.subscriptionType === 0)
                            return "Free"
                        else if (item.subscriptionType === 1)
                            return "Full"
                    }} />
                    <ListHeaderEntry fieldName="appVersion" name="App Version" minWidth={120} maxWidth={400} />
                    <ListHeaderEntry fieldName="title" name="Title" minWidth={500} maxWidth={400} />
                    <ListHeaderEntry fieldName="description" name="Description" minWidth={500} maxWidth={400} />
                    <ListHeaderEntry fieldName="crmReferenceId" name="CRM Reference Id" minWidth={270} maxWidth={400} />
                    <ListHeaderEntry fieldName="errorDescription" name="Error Description" minWidth={500} maxWidth={400} />
                    <ListHeaderEntry fieldName="totalSeconds" name="Total Seconds" minWidth={80} maxWidth={400} />
                    <ListHeaderEntry fieldName="contactSeconds" name="Contact Seconds" minWidth={80} maxWidth={400} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminChatbotTicketInputCard ref={cardRef} />
        </>
    );
}