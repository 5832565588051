import { useContext, useEffect, useRef, useState } from "react";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { PartnerService } from "../../../../ApplicationCode/Partner/PartnerService";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { ISessionStateContext, SessionStateContext } from "../../../../Core/State/SessionStateContext";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { CrmSetup } from "../../../../Model/PartnerModels";
import { SystemActionCategory, SystemDataLoadingStatus, SystemPageType } from "../../../../Model/SystemModels";
import { Loader } from "../../../Common/Loader/Loader";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminCrmIncidentCard } from "../AdminCrmIncidentCard/AdminCrmIncidentCard";
import { Dialog, DialogFooter, PrimaryButton, DefaultButton, DialogType, TextField } from "@fluentui/react";
import React from "react";

export const AdminCrmIncidentList : React.FC = () => {
    const cardRef = useRef<PageContainerBase>(null);
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [crmSetup, setCrmSetup] = useState(new CrmSetup());
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [currRec, setCurrRec] = useState<any>();
    const adminService = new AdminService();
    const sessionInfoContext = useContext<ISessionStateContext>(SessionStateContext);
    const [loaderMessage,setLoaderMessage] = useState("Stiamo caricando la lista richiesta ...");
    let contextUserId = sessionInfoContext.currentUser.userId;

    let today = new Date();
    let expirationDate = new Date();
    let takeInChargeDate = new Date();
    let lastMonthDate = new Date(today.setMonth(today.getMonth()-1));
    
    let incidentDayLimitForExpiration = crmSetup.incidentDayLimitForExpiration;
    expirationDate.setDate(today.getDate() - incidentDayLimitForExpiration);
    
    let incidentDayLimitForTakeInCharge = crmSetup.incidentDayLimitForTakeInCharge;
    takeInChargeDate.setDate(today.getDate() - incidentDayLimitForTakeInCharge);
        
    const buildEndpoint = () : string => {
        let baseEndpoint: string = "/odata/crm/user/incident";
        let filterEndpoint: string = "";
        const sourceCode = HttpHelper.getParameter('source');
        let filterStateCode: string = HttpHelper.getParameter('stateCode');
        let filterIncidentOwner: string = HttpHelper.getParameter('incidentOwner');
        let filterIncidentWorkingBy: string = HttpHelper.getParameter('incidentWorkingBy');
        let interactionLate: boolean = HttpHelper.getParameter('interactionLate');
        let takeInChargeLate: boolean = HttpHelper.getParameter('takeInChargeLate');
        let notAssocied: boolean = HttpHelper.getParameter('notAssocied');
        let notMapped: boolean = HttpHelper.getParameter('notMapped');
        let faqMissing: boolean = HttpHelper.getParameter('faqMissing');
        let lastMonth: boolean = HttpHelper.getParameter('lastMonth');

        if ((filterStateCode !== undefined && filterStateCode > "") || (filterIncidentOwner !== undefined && filterIncidentOwner > "") || (notAssocied) || (notMapped) || (faqMissing))
            baseEndpoint += "?$filter=";
            
        if (filterStateCode !== undefined && filterStateCode > "")
            filterEndpoint += "stateCode eq " + filterStateCode;
            
        if ((filterIncidentOwner !== undefined && filterIncidentOwner > "") && filterIncidentOwner === "0") {
            if (filterEndpoint === undefined || filterEndpoint === "")
                filterEndpoint += "owningUserId eq " + contextUserId;
            else
                filterEndpoint += " and owningUserId eq " + contextUserId;
        }

        if ((filterIncidentOwner !== undefined && filterIncidentOwner > "") && filterIncidentOwner === "1") {
            if (filterEndpoint === undefined || filterEndpoint === "")
                filterEndpoint += "owningUserId gt 0";
            else
                filterEndpoint += " and owningUserId gt 0";
        }

        if (filterIncidentWorkingBy !== undefined && filterIncidentWorkingBy > "") {
            if (filterIncidentWorkingBy === "0"){
                if (filterEndpoint === undefined || filterEndpoint === "")
                    filterEndpoint += "workingByUserId eq 0";
                else
                    filterEndpoint += " and workingByUserId eq 0";
            }
            else if (filterIncidentWorkingBy === "1"){
                if (filterEndpoint === undefined || filterEndpoint === "")
                    filterEndpoint += "workingByUserId gt 0";
                else
                    filterEndpoint += " and workingByUserId gt 0";
            }
            else if (filterIncidentWorkingBy === "2"){
                if (filterEndpoint === undefined || filterEndpoint === "")
                    filterEndpoint += "workingByUserId eq " + contextUserId;
                else
                    filterEndpoint += " and workingByUserId eq " + contextUserId;
            }
        }

        if (interactionLate) {
            if (filterEndpoint === undefined || filterEndpoint === "")
                filterEndpoint += "internalInteractions gt 0 and createdOn lt "+expirationDate.toISOString()
            else
                filterEndpoint += " and internalInteractions gt 0 and createdOn lt "+expirationDate.toISOString()
        }

        if (takeInChargeLate) {
            if (filterEndpoint === undefined || filterEndpoint === "")
                filterEndpoint += "createdOn lt "+takeInChargeDate.toISOString()
            else
                filterEndpoint += " and createdOn lt "+takeInChargeDate.toISOString()
        }

        if (notAssocied) {
            if (filterEndpoint === undefined || filterEndpoint === "")
                filterEndpoint += "customerId eq 0"
            else
                filterEndpoint += " and customerId eq 0"
        }

        if (notMapped) {
            if (filterEndpoint === undefined || filterEndpoint === "")
                filterEndpoint += "((moduleCode eq '' or moduleCode eq null) or (processCode eq '' or processCode eq null))"
            else
                filterEndpoint += " and ((moduleCode eq '' or moduleCode eq null) or (processCode eq '' or processCode eq null))"
        }

        if (faqMissing) {
            if (filterEndpoint === undefined || filterEndpoint === "")
                filterEndpoint += "(processTestLineNo eq 0 or processFaqLineNo eq 0)"
            else
                filterEndpoint += " and (processTestLineNo eq 0 or processFaqLineNo eq 0)"
        }

        if (lastMonth) {
            if (filterEndpoint === undefined || filterEndpoint === "")
                filterEndpoint += "createdOn gt "+lastMonthDate.toISOString()
            else
                filterEndpoint += " and createdOn gt "+lastMonthDate.toISOString()
        }

        if (sourceCode) {
            if (filterEndpoint === undefined || filterEndpoint === "")
                filterEndpoint += "partnerBusinessContractCode eq '"+sourceCode+"'"
            else
                filterEndpoint += " and partnerBusinessContractCode eq '"+sourceCode+"'"
        }

        if (filterEndpoint === undefined || filterEndpoint === "")
            filterEndpoint += "?$orderby=createdOn desc"
        else
            filterEndpoint += "&$orderby=createdOn desc"
        
        return baseEndpoint+filterEndpoint;
    }
    
    useEffect(()=>{
        const partnerService = new PartnerService();

        if(dataStatus === SystemDataLoadingStatus.ToLoad){
            partnerService.getCrmLeadData().then((resp)=>{
                setCrmSetup(resp);
                setDataStatus(SystemDataLoadingStatus.Loaded);
            })
            .catch((jqXHR)=>{
                ToastService.showMessage(MessageType.Error, "Attenzione: non è stato possibile recuperare i dati del CRM, le azioni basate su ciò potrebbero non funzionare");
                setDataStatus(SystemDataLoadingStatus.Loaded);
            })
        }
    },[dataStatus]);
    
    const uploadCrmIncidents = (incidentId?: number) => {
        setLoaderMessage("Upload ticket in corso ...");
        setDataStatus(SystemDataLoadingStatus.Loading);

        adminService.updloadCrmIncidents(incidentId)
        .then((resp)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Success, "Upload ticket completato con successo");
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error,jqXHR.message);
        })
    }

    const suggetAnswer = (rec: any, content: string) => {
        setLoaderMessage("Generazione risposta in corso ...");
        setDataStatus(SystemDataLoadingStatus.Loading);

        adminService.suggestAnswer(rec, content)
        .then((resp)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Success, "Risposta generata con successo");
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error, "Impossibile generare la risposta");
        })
    }
    
    if (dataStatus !== SystemDataLoadingStatus.Loaded)
        return <Loader text={loaderMessage}></Loader>;
    else
    return (
        <>
            <PageContainer endpoint={buildEndpoint()} pageType={SystemPageType.List} title={"Lista CRM Incident"} cardRef={cardRef} backAllowed showAllowed updateAllowed>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="CRMEOSIncident" label="Apri nel CRM EOS" iconName="Repair" runOnRec isPromoted onClick={(req:any)=>{
                            window.open(crmSetup.incidentBaseAddress+req.crmReferenceId);
                        }}>
                        </ActionEntry>
                        <ActionEntry name="uploadCRM" label="Upload singolo ticket sul CRM" iconName="Upload" runOnRec onClick={(req:any)=>{
                            uploadCrmIncidents(req.entryNo);
                        }}>
                        </ActionEntry>
                        <ActionEntry name="uploadCRM" label="Upload ticket in coda sul CRM" iconName="BulkUpload" onClick={()=>{
                            uploadCrmIncidents();
                        }}>
                        </ActionEntry>
                    </ActionArea>
                    <ActionArea category={SystemActionCategory.Process}>
                        <ActionEntry name="suggestAnswer" label="Suggerisci risposta (AI)" iconName="NewsSearch" runOnRec onClick={(rec)=>{
                                setCurrRec(rec);
                                setShowDialog(true);
                            }} isPromoted>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Stato Upload CRM" fieldName="uploadState" minWidth={100} maxWidth={200} onRender={(item: any, index, column) => {
                        if (item.uploadState === 0)
                            return "In coda"
                        else if (item.uploadState === 1)
                            return "Caricato"
                        else if (item.uploadState === 2)
                            return "In errore"
                    }} />
                    <ListHeaderEntry name="Tipo Utente" fieldName="userType" minWidth={100} maxWidth={200} onRender={(item: any, index, column) =>{
                        if (item.customerId > 0)
                            return "Cliente"
                        else if (item.partnerId > 0)
                            return "Partner"
                    }} />
                    <ListHeaderEntry name="Nome Utente" fieldName="userDisplayName" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Nome Cliente" fieldName="customerCompanyName" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Nome Partner" fieldName="partnerName" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Partner Business Contract" fieldName="partnerBusinessContractCode" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Articolo" fieldName="itemDescription" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Titolo" fieldName="title" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Data Creazione" fieldName="createdOn" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Data Modifica" fieldName="modifiedOn" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Utente Proprietario" fieldName="owningUserDisplayName" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Utente Lavorazione" fieldName="workingByUserDisplayName" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Minuti Lavorazione" fieldName="workingMinutes" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Stato" fieldName="stateDescription" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Avanzamento" fieldName="crmStatusDescription" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Tipo" fieldName="crmIncidentTypeName" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Priorità Interna" fieldName="crmInternalPriorityName" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Codice processo" fieldName="processCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Codice modulo" fieldName="moduleCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Nr. riga test" fieldName="processTestLineNo" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Nr. riga FAQ" fieldName="processFAQLineNo" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Origine CRM" fieldName="caseOriginCode" minWidth={100} maxWidth={200} onRender={(item: any, index, column) => {
                        if (item.caseOriginCode === 1)
                            return "Manual"
                        else if (item.caseOriginCode === 2)
                            return "Email"
                        else if (item.caseOriginCode === 3)
                            return "Web"
                        else if (item.caseOriginCode === 2483)
                            return "Facebook"
                        else if (item.caseOriginCode === 3986)
                            return "Twitter"
                        else if (item.caseOriginCode === 700610000)
                            return "IoT"
                        else
                            return "Undefined"
                    }} />
                    <ListHeaderEntry name="Errore" fieldName="isError" minWidth={100} maxWidth={100} />
                    <ListHeaderEntry name="Critico" fieldName="isCritical" minWidth={100} maxWidth={100} />
                    <ListHeaderEntry name="Id supporto" fieldName="supportId" minWidth={150} maxWidth={200} />
                    <ListHeaderEntry name="Documentazione gestita" fieldName="documentationManaged" minWidth={150} maxWidth={200} />
                    <ListHeaderEntry name="Proposta test documentazione mancante" fieldName="missingDocsTestProposal" minWidth={150} maxWidth={200} />
                    <ListHeaderEntry name="Descrizione soluzione" fieldName="resolutionDescription" minWidth={200} maxWidth={250} />
                    <ListHeaderEntry name="Minuti soluzione" fieldName="resolutionMinutes" minWidth={100} maxWidth={150} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminCrmIncidentCard ref={cardRef} />
            <DialogText show={showDialog} onDismiss={() => {
                setShowDialog(false);
            }}
            onSuccess={(text) => {
                setShowDialog(false);
                suggetAnswer(currRec, text);
            }}
            ></DialogText>
        </>
    );
}

interface IDialogTextProps {
    show: boolean;
    onDismiss: () => void;
    onSuccess: (text: string) => void;
}

const DialogText: React.FC<IDialogTextProps> = (props) => {
    const [text, setText] = useState<string>("");
    
    const dialogStyles = { main: { maxWidth: "1000px !important", minWidth: "40vw !important" } };

    const modalProps = React.useMemo(
        () => ({
          isBlocking: true,
          styles: dialogStyles,
          dragOptions:  undefined,
        }),
        [],
      );

    useEffect(() => {
        if (! props.show)
            setText("");
    }, [props.show]);

    return (
        <Dialog
        hidden={! props.show}
        onDismiss={() => {
            props.onDismiss();
        }}
        dialogContentProps={{
            type: DialogType.normal,
            title: 'Generazione risposta',
            closeButtonAriaLabel: 'Close',
            subText: 'Inserisci il testo della richiesta',
        }}
        modalProps={modalProps}
      >
        <TextField value={text} onChange={(e, data) => {
            setText(data!);
        }} multiline />
        <DialogFooter>
          <PrimaryButton onClick={() => props.onSuccess(text)} text="Genera" />
        </DialogFooter>
      </Dialog>
    );
}