import React, { useRef } from "react";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminSystemEventParameterCard } from "./AdminSystemEventParameterCard";

export const AdminSystemEventParameterList : React.FC = () => {
    
    const cardRef = useRef<PageContainerBase>(null);
        
    const eventName = HttpHelper.getParameter('eventName')

    const buildEndpoint = () : string => {
    
        let endpoint: string = "/odata/system/event/parameter";
        
        if (eventName !== undefined && eventName > "")
            endpoint += "?$filter=eventName eq '" + eventName + "'"
                
        return endpoint;
    }
    
    return(
        <>
            <PageContainer endpoint={buildEndpoint()} pageType={SystemPageType.List} title="Lista System Event Parameter" cardRef={cardRef} backAllowed showAllowed>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Nome Evento" fieldName="eventName" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry name="Nome Parametro" fieldName="parameterName" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry name="Tipo" fieldName="type" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry name="Entità" fieldName="isEntity" minWidth={80} maxWidth={100} />
                    <ListHeaderEntry name="Nome Entità" fieldName="entityName" minWidth={300} maxWidth={400} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminSystemEventParameterCard ref={cardRef} />
        </>
    );
}