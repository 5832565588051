import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminNotificationSetupCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda notification setup"} endpoint="/odata/notification/setup" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="type" type={SystemFieldType.Option} label="Tipo" cmbOptions={[
                    {key: 0, text: "Email"},
                    {key: 1, text: "Chat"}
                ]} />
                <CardField name="code" type={SystemFieldType.String} label="Codice" />
                <CardField name="description" type={SystemFieldType.String} label="Descrizione" />
                <CardField name="instance" type={SystemFieldType.String} label="Istanza" />
                <CardField name="clientId" type={SystemFieldType.String} label="Id Client" />
                <CardField name="clientSecret" type={SystemFieldType.String} label="Secret" />
                <CardField name="tenantId" type={SystemFieldType.String} label="Tenant Id" />
                <CardField name="redirectUrl" type={SystemFieldType.String} label="Redirect URL" />
                <CardField name="active" type={SystemFieldType.Boolean} label="Attivo" />
                <CardField name="teamId" type={SystemFieldType.String} label="Id Team" />
            </CardGroup>
        </PageContainer>
    );
});