import { forwardRef } from "react";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { SystemCaption, SystemCaptionType, SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminSystemCaptionCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    const adminService = new AdminService();
    
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda system caption"} endpoint="/odata/system/caption" ref={ref}>
            <CardGroup name="general" label="General" isOpen={true}>
                <CardField name="languageCode" type={SystemFieldType.Option} label="Codice Lingua" cmbOptions={[
                    {
                        key: 'IT',
                        text: 'IT - Italiano'
                    },
                    {
                        key: 'EN',
                        text: 'EN - English'
                    }
                ]} />
                <CardField name="sourceSubId" type={SystemFieldType.String} label="Source Sub Id" />
                <CardField name="translatedValue" type={SystemFieldType.String} label="Valore Tradotto" />
            </CardGroup>
        </PageContainer>
    );
});