import * as React from 'react';
import { useId, useBoolean } from '@fluentui/react-hooks';
import {
  getTheme,
  mergeStyleSets,
  FontWeights,
  ContextualMenu,
  Modal,
  IDragOptions,
  IIconProps,
  mergeStyles,
  RefObject,
} from '@fluentui/react';
import { DefaultButton, IconButton, IButtonStyles } from '@fluentui/react/lib/Button';
import Plyr, {APITypes} from 'plyr-react';
import "plyr-react/plyr.css"
import './DemoVideoModal.css';
import { DemoUIHelper } from '../DemoUIHelper';
import { render } from '@testing-library/react';
import { CommonFunctions } from '../../../ApplicationCode/Common/CommonFunctions';
import { create } from 'domain';
import { createRef, SyntheticEvent } from 'react';
import ReactPlayer from 'react-player';
import { OnProgressProps } from 'react-player/base';

export interface IDemoVideoModalProps {
  videoUrl: string;
  videoName: string;
  hideModal: boolean;
  onCloseModal: (videoName: string) => void;
}


interface IDemoVideoModalState {
  videoType: string;
  playerRef?: APITypes | null;
  endLogged: boolean;
}

export class DemoVideoModal extends React.Component<IDemoVideoModalProps, IDemoVideoModalState>  {

  constructor(props: IDemoVideoModalProps)
  {
      super(props);

      let videoType = "";
      if (this.props.videoUrl.indexOf(".mp4") >= 0)
        videoType = "video/mp4";
      else if (this.props.videoUrl.indexOf(".webm") >= 0)
        videoType = "video/webm";

      this.state = {
        videoType: videoType,
        endLogged: false
      };
  }
  
  shouldComponentUpdate = (nextProps: Readonly<IDemoVideoModalProps>, nextState: Readonly<IDemoVideoModalState>, nextContext: any) => {
    if (nextProps.videoUrl != this.props.videoUrl)
      return true;
    
    return false;
  }


  // Use useId() to ensure that the IDs are unique on the page.
  // (It's also okay to use plain strings and manually ensure uniqueness.)

  public render() {
    let displayDuration:number = 0;

    return (
      <div>
        <Modal
          isOpen={this.props.hideModal}
          onDismiss={() => {
            if (! this.state.endLogged)
                CommonFunctions.logEvent("Fine video: "+this.props.videoName+" - Secondi visti: "+displayDuration,this.props.videoUrl);

            this.props.onCloseModal(this.props.videoName)
          }}
          isBlocking={false}
          containerClassName={contentStyles.container}
          dragOptions={true ? {
            moveMenuItemText: 'Move',
            closeMenuItemText: 'Close',
            menu: ContextualMenu,
            keepInBounds: true,
          } : undefined}
        >
          <div className={contentStyles.header}>
            <span>{this.props.videoName}</span>
            <IconButton
              styles={iconButtonStyles}
              iconProps={cancelIcon}
              ariaLabel="Chiudi"
              onClick={() => {
                if (! this.state.endLogged)
                  CommonFunctions.logEvent("Fine video: "+this.props.videoName+" - Secondi visti: "+displayDuration,this.props.videoUrl);

                this.props.onCloseModal(this.props.videoUrl)
              }}
            />
          </div>
          <div style={{
                height: 'calc(100vh - 100px)'
              }}>
                <ReactPlayer 
                  width={"100%"} 
                  height={"calc(100vh - 110px)"} 
                  controls={true}
                  pip={true} 
                  playing={true}
                  config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                  light={DemoUIHelper.getVideoSource(this.props.videoUrl, this.props.videoName).poster} 
                  url={this.props.videoUrl}
                  onProgress={(state) => {displayDuration = Math.round(state.playedSeconds)}}
                  onPlay={()=>{
                    CommonFunctions.logEvent("Inizio video: "+this.props.videoName+ " - Secondi visti: "+displayDuration,this.props.videoUrl);
                    this.setState({
                      videoType: this.state.videoType,
                      endLogged: false
                    });
                  }}
                  onPause={()=>{
                    CommonFunctions.logEvent("Fine video: "+this.props.videoName+" - Secondi visti: "+displayDuration,this.props.videoUrl);
                    this.setState({
                      videoType: this.state.videoType,
                      endLogged: true
                    });
                  }} 
                />
          </div>
        </Modal>
      </div>
    );
  }
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: '100%'
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    height: "500px",
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
      '&:hover': {
        cursor: 'pointer'
      }
    },
  },
});

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};