import { useRef } from "react";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { SystemOperation, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminWizardLineStructureCard } from "./AdminWizardLineStructureCard";

export const AdminWizardLineStructureList : React.FC = () => {
    const wizardCode = HttpHelper.getParameter("wizardCode");
    const wizardLineNo = HttpHelper.getParameter("wizardLineNo");
    const cardRef = useRef<PageContainerBase>(null);

    return (
        <>
            <PageContainer endpoint={"/odata/ui/wizard/line/structure?$filter=wizardCode eq '" + wizardCode + "' and wizardLineNo eq " + wizardLineNo+"&$orderby=order, lineNo"} pageType={SystemPageType.List} title={"Struttura step wizard - " + wizardCode + " " + wizardLineNo} cardRef={cardRef} backAllowed insertAllowed updateAllowed deleteAllowed onNewRecord={() => {
                cardRef.current?.setRecord({
                    wizardCode: wizardCode,
                    wizardLineNo: wizardLineNo
                });
                cardRef.current?.openPage(SystemOperation.Insert);
            }}>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Nome" fieldName="name" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Caption" fieldName="label" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Tipo" fieldName="type" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Ordinamento" fieldName="order" minWidth={100} maxWidth={200} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminWizardLineStructureCard ref={cardRef} />
        </>
    );
}