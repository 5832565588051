import React, { useRef } from "react";
import { SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminQualifiedAnswerCard } from "../AdminQualifiedAnswerCard/AdminQualifiedAnswerCard";

export const AdminQualifiedAnswerList : React.FC = () => {
    
    const cardRef = useRef<PageContainerBase>(null);
    
    return (
        <>
            <PageContainer endpoint="/odata/qualified/answer" pageType={SystemPageType.List} title={"Lista risposte qualifica"} cardRef={cardRef} backAllowed insertAllowed updateAllowed deleteAllowed>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Codice" fieldName="code" minWidth={250} maxWidth={300} />
                    <ListHeaderEntry name="Tipologia" fieldName="type" minWidth={350} maxWidth={400} />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={450} maxWidth={500} />
                    <ListHeaderEntry name="Valore default" fieldName="defaultValue" minWidth={450} maxWidth={500} />
                    <ListHeaderEntry name="Codice wizard" fieldName="wizardCode" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminQualifiedAnswerCard ref={cardRef} />
        </>
    );
}