import React from "react";
import { useEffect, useState } from "react";
import MicrosoftLogin from "react-microsoft-login";
import { useLocation, useNavigate } from "react-router-dom";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { ISessionStateContext, SessionStateContext } from "../../../Core/State/SessionStateContext";
import { SystemCore } from "../../../Core/System/SystemCore";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { SystemDataLoadingStatus } from "../../../Model/SystemModels";
import { Loader } from "../Loader/Loader";

export const MicrosoftLoginDataBox: React.FC = () => {
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.Loading);
    const [state,setState] = useState(HttpHelper.getParameter('state'));
    
    const navigate = useNavigate();
    const systemCore = new SystemCore();
    const location = useLocation();
    const sessionStateContext: ISessionStateContext = React.useContext(SessionStateContext);

    const getRequestedResource = () => {
        let requestedResource = HttpHelper.getParameter("resource");
        if ((requestedResource != undefined) && (requestedResource != ""))
            return atob(requestedResource);

        return undefined;
    }

    const buildRedirectUrl = (path: string) : string => {
        let requestedResource = getRequestedResource();
        if (requestedResource)
            return path += "?resource=" + btoa(requestedResource);
        return path;
    }

    useEffect(() => {
        let data = window.location.hash;
        systemCore.authenticateMicrosoftUser(data)
            .then((resp) => {
                if (! resp.success)
                {
                    ToastService.showMessage(MessageType.Error, resp.error);
                    let toUrl = "/login";

                    if (data.indexOf("&state=") >= 0)
                    {
                        let rawUrl=data.split('&')[1].substring(6,data.split('&')[1].length);
                        let callbackUrl=decodeURIComponent(rawUrl);
                    
                        if(callbackUrl != null && callbackUrl !== "")
                            toUrl += "?resource=" + callbackUrl;
                    }
                    navigate(toUrl);
                }
                else
                {
                    sessionStateContext.isAuthenticated = true;
                    let toUrl = "/app";
                    if (data.indexOf("&state=") >= 0)
                    {
                        let rawUrl=data.split('&')[1].substring(6,data.split('&')[1].length);
                        let callbackUrl=decodeURIComponent(rawUrl);
                    
                        if(callbackUrl != null && callbackUrl !== "")
                            toUrl += "?resource=" + callbackUrl;
                    }
                    navigate(toUrl);
                }
            })  
            .catch((err) => {
                ToastService.showMessage(MessageType.Error, "Autenticazione non riuscita");
                navigate("/login");
            }) 

    }, [dataStatus]);
    
    return (
        <>
            <Loader text="Stiamo verificando il tuo profilo Microsoft..." />
        </>
    );
}