import React, {forwardRef} from "react";
import {SystemFieldType,SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, {PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminAuthorizedApiKeyCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} endpoint="/odata/authorized/api/key" title={'Authorized Api Key List'} ref={ref}>
            <CardGroup name="general" label="General" isOpen>
                <CardField name="entryNo" label="Entry No" type={SystemFieldType.Number} readonly />
                <CardField name="description" label="Description" type={SystemFieldType.String} />
                <CardField name="category" label="Category" type={SystemFieldType.Option} cmbOptions={[
                    {key: 0, text: "Business Central"}
                ]} defaultSelectedKey={0} />
                <CardField name="apiKey" label="Api Key" type={SystemFieldType.String} readonly />
                <CardField name="revoked" label="Revoked" type={SystemFieldType.Boolean} />
            </CardGroup>
        </PageContainer>    
    );
});