import React, { forwardRef} from "react";
import {SystemFieldType, SystemPageType} from "../../../../Model/SystemModels";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import { CardField } from "../../../Common/Template/Card/CardField";

export const AdminDataSecurityEntityCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} 
                       title={"DS - Scheda entità "} 
                       endpoint="odata/datasecurity/entity"
                       ref={ref}>
            <CardGroup name="Generale" label="general" isOpen={true}>
                <CardField name="entityName" type={SystemFieldType.String} label="Nome entità" hasTableRelation tableRelationEndpoint="odata/system/entity" tableRelationKey="name" tableRelationField="name" />
                <CardField name="enabled" type={SystemFieldType.Boolean} label="Abilitato"/>
            </CardGroup> 
        </PageContainer>
    );
});