import React, { useEffect, useState } from "react";
import { AnimationClassNames, IStackTokens, Label, mergeStyles, SearchBox, Separator, Stack,Image,ImageFit,DefaultButton,PrimaryButton,Text,TextView, TextField,TooltipHost,DirectionalHint, Toggle,BaseButton,Link } from "@fluentui/react";
import { SystemDataLoadingStatus} from "../../../Model/SystemModels";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { Feature, Module } from "../../../Model/DemoModels";
import { DemoContainer } from "../../Demo/DemoContainter/DemoContainer";
import { FeatureList } from "../../Demo/Feature/FeatureList";
import ModuleBox from "../../Demo/Feature/ModuleBox";
import { CustomerService } from "../../../ApplicationCode/Customer/CustomerService";
import { Loader } from "../../Common/Loader/Loader";
import { SystemCore } from "../../../Core/System/SystemCore";
import { CustomerSendTicket } from "../CustomerSendTicket/CustomerSendTicket";

export interface FeatureOverviewContainerProps {
    modules: Module[];
    features: Feature[];
    linkToId: string;
    refreshData: () => void;
}

export interface FeatureOverviewContainerState {
    modules: Module[];
    features: Feature[];
    selectedModule: Module;
    showAllFeatures: boolean;
    searchKeyWorld: string;
    selectedFeature: Feature;
    featureVideoUrl: string;
}


const mainStackStyle = mergeStyles([{
    padding: 10
},
AnimationClassNames.fadeIn500]);

const moduleStackStyle = mergeStyles([{
    width: '60%',
    height: '100%',
    borderRight: '1px solid rgb(0 0 0 / 20%)',
    padding: '5px',
    display: 'flex'
}]);

const moduleButtonStyle={
    customWidth:150,
    customHeight:100,
    fontSize:40
}

const fixedContainer = mergeStyles([{
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-around',
    position:'relative',
    '@media (max-width:1100px)': {
        flexWrap: 'wrap'
      },
}])

const wrapper = mergeStyles([{
    position: 'absolute',
    top: '30%',
    width:'100%',
    bottom: 10,
    transform:'translateY(-30%)'
}])

const flexContainer = mergeStyles([{
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
}])

const flexContainerAction = mergeStyles([{
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    flexWrap: 'wrap'
}])

const scrollContainer = mergeStyles([{
    overflowY:'auto',
    height:'calc(100vh - 250px)',
}])


const featureStackStyle = mergeStyles([{
    width: '60%',
    height: '100%'
}]);
const stackTokens: Partial<IStackTokens> = { childrenGap: 20 };

const checkboxStyle = {
    root: {
      margin: '0 30px 20px 0',
      maxWidth: '300px',
    },
};

export const CustomerHelpPage : React.FC = () => {
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [modulesArray,setModulesArray]=useState([] as Module[]);
    const [featuresArray,setFeaturesArray]=useState([] as Feature[]);
    const [selectedApp,setSelectedApp]=useState(new Module());
    const [showTicket,setShowTicket]=useState(false);
    const [checked,setChecked]=useState(true);
    const [filteredFeatures,setFilteredFeatures] = useState([] as Feature[]);
    const [searchKey,setSearchKey] = useState("");

    const customerService=new CustomerService();
    
    const handleModuleClick = (module:any)=>{
        setSelectedApp(module);
        setChecked(false);
    };

    const handleCheckedChange = (event: React.MouseEvent<HTMLElement, MouseEvent>, value?:boolean)=>{
        setChecked(!checked);
        if(value){
            setFilteredFeatures(featuresArray);
            setSelectedApp(new Module());
        }
    };

    const handleSearchChange = (event?: React.ChangeEvent<HTMLInputElement>,value?:string)=>{
        let stringKey="";
        stringKey = value ? value : "";
        setSearchKey(stringKey);
    };

    const sortArray = (a:Feature,b:Feature)=>{
        if (a.rankingAbsolute > b.rankingAbsolute)
            return -1;
        else if (a.rankingAbsolute < b.rankingAbsolute)
            return 1;
        return 0;
    }

    const filterArray = (f: Feature) => {
        if (selectedApp.code != null && selectedApp.code != "")
            if (f.processModule != selectedApp.code)
                return false;
        return (f.processDescrption.toLocaleLowerCase().indexOf(searchKey.toLocaleLowerCase()) >= 0);
    }

    const filterFaqs = ()=>{
        if(searchKey !="" || selectedApp.code !=null )
            setFilteredFeatures(featuresArray.filter(filterArray).sort(sortArray));
        else
            setFilteredFeatures(featuresArray);
    }


    const sendTicketRequest=(ticket:any)=>{
        if(ticket.Mail.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
            if(ticket.Title != "")
                if(ticket.Description != ""){
                    ToastService.showMessage(MessageType.Success,"Richiesta di aiuto inviata correttamente");
                    //TO DO
                    setShowTicket(false);
                }
                else
                    ToastService.showMessage(MessageType.Error,"La descrizione non può essere vuota");
            else
                ToastService.showMessage(MessageType.Error,"Il titolo non può essere vuoto");
        else
            ToastService.showMessage(MessageType.Error,ticket.Mail != "" ? "La mail: \""+ticket.Mail+"\" non è valida" : "Il campo \"Mail\" non può essere vuoto");
    }


    useEffect(() => {
        if (dataStatus == SystemDataLoadingStatus.ToLoad)
        {
            customerService.loadHelpModule().then((resp)=>{
                setModulesArray(resp);

                customerService.loadHelpFeature().then((resp)=>{
                    setFeaturesArray(resp);
                    setDataStatus(SystemDataLoadingStatus.Loaded);
                }).catch((jqXHR)=>{
                    console.error(jqXHR)
                })
            })
            .catch((err)=>{
                console.error(err);
                setDataStatus(SystemDataLoadingStatus.Loaded)
            });
        }
    },[dataStatus]);

    useEffect(()=>{filterFaqs();},[selectedApp]);

    useEffect(()=>{filterFaqs();},[searchKey]);

    if (!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text="Stiamo caricando le informazioni per te"></Loader>;
    else
    return (
        <div className={wrapper}>
            <Stack className={fixedContainer}>
                <Stack style={{display:'flex',flexDirection:'row',justifyContent:'center',justifyItems:'center',alignItems:'center',flexWrap:'wrap',marginBottom:10}}>
                    <DemoContainer customHeight="calc(100vh - 150px)" linkToId={''} id="featuresOverview" title="Seleziona l'app che preferisci per filtrare le FAQ" scrollId="scroll0">
                        <Stack horizontal className={mainStackStyle} key="featureDataContainer">
                            <Stack.Item align="start" className={moduleStackStyle} verticalFill={true} id="moduleOverview" key="moduleOverview">
                                <Stack key="containerGroup" className={scrollContainer}>
                                    <Stack wrap style={{width: '100%', display: 'flex', padding: '10px', height: 'auto'}}>
                                    </Stack>
                                    <Stack horizontal className={flexContainer}  key="modulesContainer">
                                        {modulesArray.map(module=>{return (<ModuleBox customStyle={moduleButtonStyle} selected={selectedApp==module && !checked} sourceModule={module} onSelect={(moduleContent)=>{handleModuleClick(moduleContent)}}></ModuleBox>)})}
                                    </Stack>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item grow align="start" className={featureStackStyle} id="searchBox" key="searchBox">
                                <Stack.Item grow >
                                    <Stack key="searcBoxMainContainer">
                                        <Stack key="titleConainter" wrap style={{width: '100%', display: 'flex', paddingLeft: '10px', paddingTop: '15px'}}>
                                            <Label style={{fontSize: '20px'}}>
                                                Le FAQ più ricercate dai nostri clienti
                                                <Separator className="separator"></Separator>
                                            </Label>
                                        </Stack>
                                        <Stack tokens={stackTokens} key="searchBoxConainter" id="searchBoxTxt" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                                            <SearchBox placeholder="Ricerca una funzionalità"
                                                    value={searchKey}
                                                    onChange={(e,value)=>{handleSearchChange(e,value)}}  />
                                            <Stack className={flexContainerAction}>
                                                <Stack.Item>
                                                    <Toggle
                                                        label=""
                                                        checked={checked}
                                                        onChange={(chk,val)=>{handleCheckedChange(chk,val)}}
                                                        onText="Mostra tutto"
                                                        offText="Mostra tutto"
                                                        styles={checkboxStyle}
                                                    />
                                                </Stack.Item>
                                                <Stack.Item style={{marginBottom:20}}>
                                                    Non hai trovato cosa stavi cercando? <Link onClick={()=>{setShowTicket(true)}}>Apri un ticket</Link>
                                                </Stack.Item>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item style={{
                                    maxHeight: 'calc(100vh - 440px)',
                                    overflowY: 'auto'
                                }}>
                                    <FeatureList onItemInvoked={()=>{}} features={filteredFeatures.length ? filteredFeatures : featuresArray}></FeatureList>
                                </Stack.Item>
                            </Stack.Item>
                        </Stack>
                    </DemoContainer>
                </Stack>
                {showTicket ? <CustomerSendTicket onDismiss={()=>{setShowTicket(false)}} onSuccess={(ticket:any)=>{sendTicketRequest(ticket)}} /> : "" }
            </Stack>
        </div>
    );
}