import { DefaultButton, Dialog, DialogFooter, DialogType, Icon, PrimaryButton, ProgressIndicator, Separator, Stack, mergeStyles } from "@fluentui/react";
import { SystemCore } from "../../../Core/System/SystemCore";
import React, { useContext, useEffect, useState } from "react";
import { SystemDataLoadingStatus } from "../../../Model/SystemModels";
import { Loader } from "../../Common/Loader/Loader";
import { CustomerService } from "../../../ApplicationCode/Customer/CustomerService";
import { SessionStateContext } from "../../../Core/State/SessionStateContext";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { CustomerDigitalContract, StripeSubscription } from "../../../Model/CustomerModels";
import { useNavigate } from "react-router-dom";
import ReactHtmlParser from 'html-react-parser';

export class UnsubscribeChecklistStep {
    public id: number = 0;
    public name: string = "";
    public active: boolean = false;
    public completed: boolean = false;
    public canProceed: boolean = true;
}

export class UnsubscribeChecklistContent {
    public id: number = 0;
    public title: string = "";
    public text: string = "";
    public active: boolean = false;
    public actionLink: string = "";
    public actionText: string = "";
    public stripeSubscription: StripeSubscription = new StripeSubscription();
    public customerMainContract: CustomerDigitalContract = new CustomerDigitalContract();
    public customerCancellationContract: CustomerDigitalContract = new CustomerDigitalContract();
}

export class UnsubscribeChecklistSteps {
    public steps: UnsubscribeChecklistStep[] = [];
    public contents: UnsubscribeChecklistContent[] = [];
}

export const CustomerUnsubscribeCard : React.FC = () => {
    
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [isLoading, setIsLoading] = useState(SystemDataLoadingStatus.Loaded);
    const [stepItems, setStepItems] = useState<UnsubscribeChecklistStep[]>([]);
    const [contentItems, setContentItems] = useState<UnsubscribeChecklistContent[]>([]);
    const [activeStep, setActiveStep] = useState<UnsubscribeChecklistStep>(new UnsubscribeChecklistStep());
    const [stripeSubscription, setStripeSubscription] = useState<StripeSubscription>(new StripeSubscription());
    const [mainContract, setMainContract] = useState<CustomerDigitalContract>(new CustomerDigitalContract());
    const [cancellationContract, setCancellationContract] = useState<CustomerDigitalContract>(new CustomerDigitalContract());
    const [showDialog, setShowDialog] = useState(false);
    const customerService = new CustomerService();
    const sessionInfoContext = useContext(SessionStateContext);
    const navigate = useNavigate();
    const systemCore = new SystemCore();
    
    useEffect(() => {
        if (dataStatus === SystemDataLoadingStatus.ToLoad)
        {
            getUnsubscribeSteps();
        }
    }, [dataStatus])

    const getUnsubscribeSteps = () => {
        customerService.getUnsubscribeSteps(sessionInfoContext.currentUser.referenceId)
        .then((resp) => {
            setStepItems(resp.steps);
            setContentItems(resp.contents);
            setCurrentActiveStep(resp.steps);
            
            let mainContractStep: UnsubscribeChecklistStep | undefined = resp.steps.find(e => e.id === 1);
            let cancellationContractStep: UnsubscribeChecklistStep | undefined = resp.steps.find(e => e.id === 2);
            let subscriptionStep: UnsubscribeChecklistStep | undefined = resp.steps.find(e => e.id === 3);
            
            if (subscriptionStep !== undefined) {
                let subscriptionContent: UnsubscribeChecklistContent | undefined = resp.contents.find(e => e.id === subscriptionStep!.id);
                setStripeSubscription(subscriptionContent!.stripeSubscription);
            }

            if (mainContractStep !== undefined) {
                let mainContractContent: UnsubscribeChecklistContent | undefined = resp.contents.find(e => e.id === mainContractStep!.id);
                setMainContract(mainContractContent!.customerMainContract);
            }

            if (cancellationContractStep !== undefined) {
                let cancellationContractContent: UnsubscribeChecklistContent | undefined = resp.contents.find(e => e.id === cancellationContractStep!.id);
                setCancellationContract(cancellationContractContent!.customerCancellationContract);
            }

            setDataStatus(SystemDataLoadingStatus.Loaded);
        })
        .catch((err) => {
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Error, err.message);
        })
    }

    const setCurrentActiveStep = (stepItems: UnsubscribeChecklistStep[]) => {
        let currentItem: UnsubscribeChecklistStep | undefined = stepItems.find(e => e.active);

        if (currentItem)
            setActiveStep(currentItem);
    }

    const buildChecklistStepElements = () : JSX.Element[] => {
        let currentElements: JSX.Element[] = [];

        for (let i: number = 0; i < stepItems.length; i++) {
            let currentItem: UnsubscribeChecklistStep = stepItems[i];
            
            currentElements.push(
                <UnsubscribeChecklistItem
                    id={currentItem.id}
                    name={currentItem.name}
                    active={currentItem.active}
                    completed={currentItem.completed}
                    nextCompleted={currentItem.completed && activeStep.id < currentItem.id} />
            );
            
            if (i < stepItems.length - 1)
                currentElements.push(<UnsubscribeChecklistSeparator id={currentItem.id} completed={currentItem.completed && activeStep.id > currentItem.id} />);
        }
        
        return currentElements;
    }

    const buildChecklistContentElements = () : JSX.Element[] => {
        let currentElements: JSX.Element[] = [];
        
        for (let i: number = 0; i < stepItems.length; i++) {
            currentElements.push(
                <UnsubscribeContent
                    isLoading={isLoading}
                    id={i}
                    active={contentItems[i].active}
                    title={contentItems[i].title}
                    text={contentItems[i].text}
                    actionText={contentItems[i].actionText}
                    actionLink={contentItems[i].actionLink}
                />
            );
        }

        return currentElements;
    }

    const sleep = (ms: number) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
    };

    const handleNextClicked = async () => {
        setIsLoading(SystemDataLoadingStatus.Loading);
        
        setShowDialog(false);
        
        await sleep(200);

        let currentActiveStep: UnsubscribeChecklistStep = activeStep;

        if (currentActiveStep !== undefined) {
            if (currentActiveStep.completed) {
                setNextStep(currentActiveStep);
                setIsLoading(SystemDataLoadingStatus.Loaded);
            }
            else if (currentActiveStep.id === 1) {
                customerService.sendNamirialContractCancellation(mainContract)
                .then(() => {
                    getUnsubscribeSteps();
                    setIsLoading(SystemDataLoadingStatus.Loaded);
                })
                .catch((err) => {
                    setIsLoading(SystemDataLoadingStatus.Loaded);
                    ToastService.showMessage(MessageType.Error, err.message);
                });
            }
            else if (currentActiveStep.id === 2) {
                customerService.getNamirialEnvelopeStatus(cancellationContract.envelopeId)
                .then((resp) => {
                    if (resp === "Completed")
                        setNextStep(currentActiveStep!);
                    else
                        ToastService.showMessage(MessageType.Information, "Il contratto non risulta ancora firmato, procedere alla firma oppure attendere qualche secondo e riprovare");
                    
                    setIsLoading(SystemDataLoadingStatus.Loaded);
                })
                .catch((err) => {
                    setIsLoading(SystemDataLoadingStatus.Loaded);
                    ToastService.showMessage(MessageType.Error, err.message);
                });
            }
            else if (currentActiveStep.id === 3) {
                customerService.cancelCustomerStripeSubscription(stripeSubscription.id)
                .then(() => {
                    setNextStep(currentActiveStep!);
                    setIsLoading(SystemDataLoadingStatus.Loaded);
                })
                .catch((err) => {
                    setIsLoading(SystemDataLoadingStatus.Loaded);
                    ToastService.showMessage(MessageType.Error, err.message);
                });
            }
            else if (currentActiveStep.id === 4) {
                systemCore.logOutUser(sessionInfoContext, navigate)
            }
        }
    }

    const setNextStep = (currentActiveStep: UnsubscribeChecklistStep) => {
        let currentItems: UnsubscribeChecklistStep[] = [];
        let currentContents: UnsubscribeChecklistContent[] = [];

        let nextActiveStep: UnsubscribeChecklistStep = new UnsubscribeChecklistStep();
        for (let i: number = 0; i < stepItems.length; i++) {            
            let currentItem: UnsubscribeChecklistStep = stepItems[i];
            if (currentItem.id > currentActiveStep.id) {
                nextActiveStep = currentItem;
                break;
            }
        }

        for (let i: number = 0; i < stepItems.length; i++) {
            let currentItem: UnsubscribeChecklistStep = stepItems[i];
            let currentContent: UnsubscribeChecklistContent = contentItems[i];
            
            if (currentItem.active) {
                currentItem.active = false;
                currentItem.completed = true;
            }
            
            if (currentItem.id === nextActiveStep.id)
                currentItem.active = true;

            if (currentContent.active)
                currentContent.active = false;
            
            if (currentContent.id === nextActiveStep.id)
                currentContent.active = true;

            currentItems.push(currentItem);
            currentContents.push(currentContent);
        }

        setStepItems(currentItems);
        setContentItems(currentContents);
        setCurrentActiveStep(currentItems);
    }
    
    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Vuoi veramente procedere? La procedura è irreversibile',
    };
    
    const separatorStyles = {
        root: [{
            selectors: {
                '::before': {
                    background: '#bebebe',
                }
            }
        }]
    };
    
    if (!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text="Stiamo caricando i tuoi dati..." />
    else
        return (
            <UnsubscribeContainer canProceed={activeStep.canProceed} isLoading={isLoading} onNextClicked={() => {setShowDialog(true)}}>
                <UnsubscribeChecklistContainer>
                    {buildChecklistStepElements()}
                </UnsubscribeChecklistContainer>
                <Separator styles={separatorStyles}/>
                <UnsubscribeContentContainer>
                    {buildChecklistContentElements()}
                </UnsubscribeContentContainer>
                <Dialog
                    hidden={!showDialog}
                    onDismiss={() => {setShowDialog(false)}}
                    dialogContentProps={dialogContentProps}>
                    <DialogFooter>
                        <DefaultButton onClick={() => {setShowDialog(false)}} text="Annulla" />
                        <PrimaryButton onClick={handleNextClicked} text="Conferma" />
                    </DialogFooter>
                </Dialog>
            </UnsubscribeContainer>
        )
}

interface IUnsubscribeContent {
    id: number;
    title: string;
    text: string;
    active: boolean;
    actionText: string;
    actionLink: string;
    isLoading: SystemDataLoadingStatus;
}

const UnsubscribeContent : React.FC<IUnsubscribeContent> = (props) => {

    let contentStyle = mergeStyles([{
        width: '100%',
        display: 'none'
    }]);

    if (props.active)
    {
        contentStyle = mergeStyles([{
            width: '100%',
            display: 'block'
        }]);
    }

    let actionStyle = mergeStyles([{
        display: 'block'
    }]);

    const titleStyle = mergeStyles([{
        fontFamily: 'Nunito',
        fontWeight: '700',
        fontSize: 20,
        color: '#2A7DE1'
    }]);

    const textStyle = mergeStyles([{
        fontFamily: 'Nunito',
        fontSize: 16,
        color: '#424241'
    }]);
    
    let actionContainer = mergeStyles([{
        paddingTop: '20px'
    }]);

    if (!props.actionText || !props.actionLink)
    {
        actionContainer = mergeStyles([{
            display: 'none'
        }]);        
    }
    
    const separatorStyles = {
        root: [{
            selectors: {
                '::before': {
                    background: '#bebebe',
                }
            }
        }]
    };

    if (props.isLoading === SystemDataLoadingStatus.Loading && props.active)
        return(
            <ProgressIndicator label={props.title + " in corso"} description="Attendi per favore" />
        )
    else
        return (
            <Stack key={"unsubscribeContent-" + props.id} className={contentStyle}>
                <Stack.Item className={titleStyle}>
                    {props.title}
                </Stack.Item>
                <Separator styles={separatorStyles} />
                <Stack key={"unsubscribeContentAction-" + props.id} className={actionStyle}>
                    <Stack.Item className={textStyle}>
                        {ReactHtmlParser(props.text)}
                    </Stack.Item>
                    <Stack.Item className={actionContainer}>
                        <PrimaryButton text={props.actionText} onClick={() => {window.open(props.actionLink)}}></PrimaryButton>
                    </Stack.Item>
                </Stack>
                {props.children}
            </Stack>
        )
}

const UnsubscribeContentContainer : React.FC<{}> = (props) => {

    const unsubscribeContentContainer = mergeStyles([{
        width: '100%',
        margin: '0 auto',
        marginTop: '20px',
        padding: '20px'
    }]);

    return (
        <Stack key={"unsubscribeContentContainer"} className={unsubscribeContentContainer}>
            <Stack.Item>
                {props.children}
            </Stack.Item>
        </Stack>
    )
}

const UnsubscribeChecklistContainer : React.FC<{}> = (props) => {

    const unsubscribeChecklistContainer = mergeStyles([{
        width: '100%',
        margin: '0 auto'
    }]);

    return (
        <Stack horizontal horizontalAlign="center" key="unsubscribeChecklistContainer" className={unsubscribeChecklistContainer}>
            {props.children}
        </Stack>
    );
}

interface IUnsubscribeChecklistSeparator {
    id: number;
    completed?: boolean;
}

const UnsubscribeChecklistSeparator: React.FC<IUnsubscribeChecklistSeparator> = (props) => {

    const checklistSeparatorContainer = mergeStyles([{
        display: 'flex',
        alignItems: "center",
        lineHeight: 20,
        marginBottom: 20
    }]);

    const checklistInnerSeparatorContainer = mergeStyles([{
        display: 'flex',
        alignItems: "center",
        justifyContent: "center"
    }]);

    let checklistSeparator = mergeStyles([{
        backgroundColor: '#bebebe',
        width: 150,
        height: 5,
        borderRadius: 8
    }]);

    if (props.completed) {
        checklistSeparator = mergeStyles([{
            backgroundColor: '#2A7DE1',
            width: 150,
            height: 5,
            borderRadius: 8
        }]);
    }

    return (
        <Stack.Item className={checklistSeparatorContainer}>
            <Stack key={"checklistInnerSeparatorContainer-" + props.id} className={checklistInnerSeparatorContainer}>
                <Stack.Item grow className={checklistSeparator}>
                    &nbsp;
                </Stack.Item>
            </Stack>
        </Stack.Item>
    )
}

interface IUnsubscribeChecklistItem {
    id: number;
    name: string;
    active?: boolean;
    completed?: boolean;
    nextCompleted?: boolean;
}

const UnsubscribeChecklistItem: React.FC<IUnsubscribeChecklistItem> = (props) => {
    const checklistContentContainer = mergeStyles([{
        display: 'flex',
        alignItems: "center"
    }]);
    
    let iconName = "CircleRing";
    let iconStyle = mergeStyles([{
        fontSize: 30,
        color: '#838382'
    }]);

    let stepStyle = mergeStyles([{
        fontFamily: 'Nunito',
        fontWeight: '500',
        fontSize: 12,
        color: '#838382'
    }]);

    if (props.nextCompleted) {
        stepStyle = mergeStyles([{
            fontFamily: 'Nunito',
            fontWeight: '500',
            fontSize: 12,
            color: '#838382'
        }]);

        iconName = "SkypeCircleCheck";
    }
    else if (props.active)
    {
        stepStyle = mergeStyles([{
            fontFamily: 'Nunito',
            fontWeight: '700',
            fontSize: 12,
            color: '#2A7DE1'
        }]);

        iconStyle = mergeStyles([{
            fontSize: 30,
            color: '#2A7DE1'
        }]);
    }
    else if (props.completed)
    {
        stepStyle = mergeStyles([{
            fontFamily: 'Nunito',
            fontWeight: '700',
            fontSize: 12,
            color: '#2A7DE1'
        }]);

        iconName = "SkypeCircleCheck";
        iconStyle = mergeStyles([{
            fontSize: 30,
            color: '#2A7DE1'
        }]);
    }
    
    return (
        <Stack.Item>
            <Stack className={checklistContentContainer} key={"checklistContentContainer-" + props.id}>
                <Stack.Item>
                    <Icon iconName={iconName} className={iconStyle} />
                </Stack.Item>
                <Stack.Item className={stepStyle}> 
                    {props.name}
                </Stack.Item>
            </Stack>
        </Stack.Item>
    )
}

export interface IUnsubscribeContainer {
    isLoading: SystemDataLoadingStatus;
    canProceed: boolean;
    onNextClicked: () => void;
}

const UnsubscribeContainer : React.FC<IUnsubscribeContainer> = (props) => {

    const unsubscribeContainer = mergeStyles([{
        width: '90%',
        margin: '0 auto',
        marginTop: '20px',
        border: '1px solid #f2f2f2',
        borderRadius: 10,
        boxShadow: '0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%)',
        display: 'flex',
        alignItems: 'center',
        padding: 20
    }]);

    const unsubscribeContainerActions = mergeStyles([{
        width: '100%',
        paddingTop: 20,
    }]);

    const unsubscribeActions = mergeStyles([{
        borderRadius: 8
    }]);
    
    return (
        <Stack key={"UnsubscribeContainer"} className={unsubscribeContainer}>
            <Stack.Item className={unsubscribeContainerActions}>
                {props.children}
            </Stack.Item>
            <Stack key={"UnsubscribeContainerAction"} horizontal horizontalAlign="end" className={unsubscribeContainerActions}>
                <Stack.Item>
                    <PrimaryButton
                        className={unsubscribeActions}
                        styles={{flexContainer: {flexDirection: 'row-reverse'}, rootDisabled: {borderColor: '#838382'}, labelDisabled: {color: '#bebebe'}}}
                        text="Procedi"
                        iconProps={{iconName: 'ChromeBackMirrored'}}
                        onClick={props.onNextClicked}
                        disabled={props.isLoading === SystemDataLoadingStatus.Loading || !props.canProceed} />
                </Stack.Item>
            </Stack>
        </Stack>
    );

}