import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import {SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminDataSecuritySetupCard } from "./AdminDatasecuritySetupCard";

export const AdminDataSecuritySetupList : React.FC = () => {

    const cardRef = useRef<PageContainerBase>(null);
    const navigate = useNavigate();

    return (
        <>
            <PageContainer endpoint="/odata/datasecurity/setup" pageType={SystemPageType.List} title="Setup Data Security" backAllowed insertAllowed updateAllowed cardRef={cardRef}>               
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="DSEntity" label="Entità" iconName="SecurityGroup" onClick={(rec: any) => {
                            navigate("/app/admin/datasecurity/entity");
                        }} />
                    </ActionArea>
                </PageDefinitionContainer>                             
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Chiave primaria" fieldName="primaryKey" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Abilitato" fieldName="enabled" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Ammetti cambiamenti senza setup" fieldName="allowChangesWithoutSetup" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminDataSecuritySetupCard ref={cardRef}/>
        </>
    );
}