import React, { useRef } from "react";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminBusinessLineCard } from "../AdminBusinessLineCard/AdminBusinessLineCard";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { useNavigate } from "react-router-dom";

export const AdminBusinessLineList : React.FC = () => {
    const cardRef = useRef<PageContainerBase>(null);
    const navigate = useNavigate();

    return (
        <>
            <PageContainer endpoint="/odata/business/line" pageType={SystemPageType.List} title="Lista business line" backAllowed insertAllowed updateAllowed deleteAllowed cardRef={cardRef}>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="openBusinessLineTag" label="Tag" iconName="Tag" runOnRec onClick={(req:any)=>{
                                navigate("/app/admin/administrative/businessLine/tag?businessLineCode="+req.code);
                            }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry fieldName="code" name="Codice" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="description" name="Descrizione" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry fieldName="blobConnectionString" name="Blob connection string" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry fieldName="containerName" name="Nome container contenuti" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry fieldName="videoSourceContainerName" name="Nome container sorgente video" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry fieldName="videoDestinationContainerName" name="Nome container destinazione video" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry fieldName="indexName" name="Nome indice" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry fieldName="extensionType" name="Tipo Estensione" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry fieldName="azureCognitiveSearchKey" name="Chiave Azure cognitive service" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry fieldName="azureCognitiveQueryType" name="Tipo Azure cognitive query" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry fieldName="semanticConfiguration" name="Configurazione semantica" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry fieldName="inScope" name="In scope" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry fieldName="roleInformation" name="Informazioni ruolo" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry fieldName="defaultPartnerBusinessContractCode" name="Default partner business contract" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry fieldName="notificationSetupType" name="Tipo setup" minWidth={100} maxWidth={200} onRender={(item: any, index, column)=>{
                        if (item.notificationSetupType === 0)
                            return "Email"
                        else if (item.notificationSetupType === 1)
                            return "Chat"
                    }} />
                    <ListHeaderEntry fieldName="notificationSetupCode" name="Codice setup" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="notificationTemplateCode" name="Codice template" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="notificationGroupCode" name="Codice gruppo" minWidth={100} maxWidth={200} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminBusinessLineCard ref={cardRef} />
        </>
    );
}