import {mergeStyles, Stack } from "@fluentui/react";
import React, {useEffect, useState } from "react";
import { AdminService } from "../../../ApplicationCode/Admin/AdminService";
import { SystemCore } from "../../../Core/System/SystemCore";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { AdminDashboardCue } from "../../../Model/AdminModel";
import { SystemDataLoadingStatus } from "../../../Model/SystemModels";
import { Banner, DashboardCarousel, DashboardContainer, DashboardContentColumn, DashboardContentContainer, DashboardContentRow, DataBox, UserProfileCard } from "../../Common/Dashboard/DashboardComponents";
import { Loader } from "../../Common/Loader/Loader";
import { CarouselItem } from "../../Partner/PartnerDashboard/PartnerDashboardComponents";
import { t } from "i18next";

const dashboardCarouselClassName = mergeStyles([{
    width: "95%",
    height: "auto",
    position: "relative",
    margin: "auto"
}])

export const AdminDashboard : React.FC = () => {
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [dashboardCue, setAdminDashboardCue]= useState(new AdminDashboardCue())
    const adminService = new AdminService();
    
    useEffect(() => {
        if (dataStatus == SystemDataLoadingStatus.ToLoad)
        {
            setDataStatus(SystemDataLoadingStatus.Loading);

            adminService.getAdminDashboardCue().then((resp)=>{
                setAdminDashboardCue(resp);
                setDataStatus(SystemDataLoadingStatus.Loaded);
            })
            .catch((jqXHR)=>{
                setDataStatus(SystemDataLoadingStatus.Loaded);
                ToastService.showMessage(MessageType.Error,jqXHR.message);
            })
        }
    }, [dataStatus]);

    const formatNumber = (value: number) =>{
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    if (! SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={t("adminCenter:loaderText")!} />
    else
    {
        return (
            <DashboardContainer>
                    <Banner 
                        leftContent={
                            <UserProfileCard/>
                        }
                        rightContent={
                            <DashboardCarousel containerClassName={dashboardCarouselClassName} activeSlide={1}>
                                <CarouselItem
                                    title={t("adminCenter:newTrial")!}
                                    description={t("adminCenter:newTrialDescription", {value: dashboardCue.newTrial.toString()})!}
                                    iconName="UserGauge"
                                />
                                <CarouselItem
                                    title={t("adminCenter:newCustomer")}
                                    description={t("adminCenter:newCustomerDescription", {value: dashboardCue.newCustomer.toString()})}
                                    iconName="UserFollowed"
                                />
                                <CarouselItem
                                    title={t("adminCenter:revenue")}
                                    description={t("adminCenter:revenueDescription", {value: formatNumber(dashboardCue.newRevenue)})}
                                    iconName="Money"
                                />
                                <CarouselItem
                                    title={t("adminCenter:subscription", {value: formatNumber(dashboardCue.subscriptionSum)})}
                                    description={t("adminCenter:subscriptionDescription", {value: formatNumber(dashboardCue.newSubscriptionSum)})!}
                                    iconName="Subscribe"
                                />
                            </DashboardCarousel>
                        }
                    />
                    <DashboardContentRow id={'1'}>
                        <Stack key={"dashboardVerticalWidgets"} styles={{root: {width: '50%'}}} tokens={{childrenGap: 20}}>
                            <DashboardContentColumn>
                                <DashboardContentContainer  title="Lead (So Smart)" name="leadWorkflow">
                                    <Stack horizontal wrap tokens={{childrenGap: 10}}>
                                        <DataBox
                                            name="awareness"
                                            title="Awareness"
                                            value={dashboardCue.awareness}
                                            link={"/app/admin/common/leads?source=SO-SMART&code=00&codeName=Awareness"}
                                        />
                                        <DataBox
                                            name="subscribed"
                                            title="Subscribed"
                                            value={dashboardCue.subscribed} 
                                            link={"/app/admin/common/leads?source=SO-SMART&code=01&codeName=Subscribed"} 
                                        />
                                        <DataBox 
                                            name="interest" 
                                            title="Interest" 
                                            value={dashboardCue.interest} 
                                            link={"/app/admin/common/leads?source=SO-SMART&code=02&codeName=Interest"} 
                                        />
                                        <DataBox
                                            name="marketingQualified"
                                            title="Marketing Qualified"
                                            value={dashboardCue.marketingQualified}
                                            link={"/app/admin/common/leads?source=SO-SMART&code=03&codeName=Marketing Qualified"}
                                        />
                                        <DataBox
                                            name="firstLogin"
                                            title="First Login"
                                            value={dashboardCue.firstLogin}
                                            link={"/app/admin/common/leads?source=SO-SMART&code=05&codeName=First Login"}
                                        />
                                    </Stack>
                                    <Stack horizontal wrap tokens={{childrenGap: 10}}>
                                        <DataBox
                                            name="selfServiceQualified"
                                            title="Self Service Qualified"
                                            value={dashboardCue.selfServiceQualified}
                                            link={"/app/admin/common/leads?source=SO-SMART&code=10&codeName=Self Service Qualified"}
                                        />
                                        <DataBox
                                            name="demoWaiting"
                                            title="Demo Waiting"
                                            value={dashboardCue.demoWaiting}
                                            link={"/app/admin/common/leads?source=SO-SMART&code=20&codeName=Demo Waiting"}
                                        />
                                        <DataBox
                                            name="demoCompleted"
                                            title="Demo Completed"
                                            value={dashboardCue.demoCompleted}
                                            link={"/app/admin/common/leads?source=SO-SMART&code=50&codeName=Demo Completed"}
                                        />
                                        <DataBox
                                            name="trial"
                                            title="Trial"
                                            value={dashboardCue.trial}
                                            link={"/app/admin/common/leads?source=SO-SMART&code=60&codeName=Trial"}
                                        />
                                        <DataBox 
                                            name="buy" 
                                            title="Buy" 
                                            value={dashboardCue.buy} 
                                            link={"/app/admin/common/leads?source=SO-SMART&code=80&codeName=Buy"} 
                                        />
                                    </Stack>
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                            <DashboardContentColumn>
                                <DashboardContentContainer title={t("adminCenter:cueCustomer")!} name="WorkflowCustomer">
                                    <Stack horizontal wrap tokens={{childrenGap: 10}}>
                                        <DataBox 
                                            name="newCustomers" 
                                            title="New"
                                            value={dashboardCue.newDsCustomer} 
                                            iconName="UserGauge" 
                                            link={"/app/admin/customer?dsStatus=50&dsCodeName=New"} 
                                        />
                                        <DataBox 
                                            name="provisioningCustomer" 
                                            title="Provisioning"
                                            value={dashboardCue.customerProvisioning} 
                                            iconName="People" 
                                            link={"/app/admin/customer?dsStatus=60&dsCodeName=Provisioning"} 
                                        />
                                        <DataBox 
                                            name="onboardingCustomer" 
                                            title="OnBoarding"
                                            value={dashboardCue.customerOnboarding} 
                                            iconName="EntitlementPolicy" 
                                            link={"/app/admin/customer?dsStatus=70&dsCodeName=OnBoarding"}
                                        />
                                        <DataBox 
                                            name="customerLive" 
                                            title="Support"
                                            value={dashboardCue.customerLive} 
                                            iconName="People" 
                                            link={"/app/admin/customer?dsStatus=80&dsCodeName=Support"} 
                                        />
                                    </Stack> 
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                            <DashboardContentColumn>
                                <DashboardContentContainer  title="Lead (Eos Apps)" name="leadEosApps">
                                    <Stack horizontal wrap tokens={{childrenGap: 10}}>
                                        <DataBox 
                                            name="eosApps" 
                                            title="Eos Apps" 
                                            iconName="ContactHeart" 
                                            value={dashboardCue.eosAppLeads} 
                                            link={"/app/admin/common/leads?source=EOSAPPS"} 
                                        />
                                    </Stack> 
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                        </Stack>
                        <Stack key={"dashboardSharepointDocs"} styles={{root: {width: '50%'}}} tokens={{childrenGap: 20}}>
                            <DashboardContentColumn>
                                <DashboardContentContainer title="Trial" name="WorkflowTrial">
                                    <Stack horizontal wrap tokens={{childrenGap: 10}}>
                                        <DataBox 
                                            name="newTrialCustomers" 
                                            title="New"
                                            value={dashboardCue.newTrialCustomer} 
                                            iconName="UserGauge" 
                                            link={"/app/admin/customer?dsStatus=10&dsCodeName=New"} 
                                        />
                                        <DataBox 
                                            name="trialProvisioning" 
                                            title="Provisioning"
                                            value={dashboardCue.trialProvisioning} 
                                            iconName="People" 
                                            link={"/app/admin/customer?dsStatus=20&dsCodeName=Provisioning"} 
                                        />
                                        <DataBox 
                                            name="trialOnboarding" 
                                            title="OnBoarding"
                                            value={dashboardCue.trialOnboarding} 
                                            iconName="EntitlementPolicy" 
                                            link={"/app/admin/customer?dsStatus=30&dsCodeName=OnBoarding"}
                                        />
                                        <DataBox 
                                            name="trialSwitch" 
                                            title="Switch"
                                            value={dashboardCue.trialSwitch} 
                                            iconName="EntitlementPolicy" 
                                            link={"/app/admin/customer?dsStatus=40&dsCodeName=Switch"}
                                        />
                                    </Stack> 
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                            <DashboardContentColumn>
                                <DashboardContentContainer title={t("adminCenter:cueIndicatorRisk")} name="riskAnalitics">
                                    <Stack horizontal wrap tokens={{childrenGap: 10}}>
                                        <DataBox 
                                            name="leadLost" 
                                            title={t("adminCenter:cueLeadLost")!} 
                                            value={dashboardCue.leadLost} 
                                            iconName="People" 
                                            link={"/app/admin/common/leads?source=SO-SMART&leadFilter=leadLost"} 
                                        />
                                        <DataBox 
                                            name="customerSignedContract" 
                                            title={t("adminCenter:cueNotQualifiedLead")!} 
                                            value={dashboardCue.leadUnqualified} 
                                            iconName="People" 
                                            link={"/app/admin/common/leads?source=SO-SMART&leadFilter=leadUnqualified"} 
                                        />
                                        <DataBox 
                                            name="trialLost" 
                                            title={t("adminCenter:cueTrialLost")!} 
                                            iconName="EntitlementPolicy" 
                                            value={dashboardCue.trialLost} 
                                            link={"/app/admin/customer?dsStatus=35&dsCodeName=Lost"} 
                                        />
                                        <DataBox 
                                            name="customerRisk" 
                                            title={t("adminCenter:cueCustomerRisk")} 
                                            iconName="EntitlementPolicy" 
                                            value={dashboardCue.customerRisk} 
                                            link={"/app/admin/customer?customerFilter=risk&filterPageName=Rischio"} 
                                        />
                                        <DataBox 
                                            name="customerLost" 
                                            title={t("adminCenter:cueCustomerLost")}
                                            value={dashboardCue.customerLost} 
                                            iconName="EntitlementPolicy" 
                                            link={"/app/admin/customer?dsStatus=90&dsCodeName=Lost"}
                                        />
                                    </Stack> 
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                            <DashboardContentColumn>
                                <DashboardContentContainer title={t("adminCenter:generalIndicators")} name="generalIndicators">
                                    <Stack horizontal wrap tokens={{childrenGap: 10}}>
                                        <DataBox 
                                            name="leadWin" 
                                            title={t("adminCenter:cueLeadWin")} 
                                            value={dashboardCue.leadWin} 
                                            iconName="People" 
                                            link={"/app/admin/common/leads?source=SO-SMART&leadFilter=leadWin"} 
                                        />
                                        <DataBox 
                                            name="activeLeads" 
                                            title={t("adminCenter:cueActiveLeads")}
                                            value={dashboardCue.leadActive} 
                                            iconName="EntitlementPolicy" 
                                            link={"/app/admin/common/leads?source=SO-SMART&activeLeads=true"}
                                        />
                                        <DataBox 
                                            name="leadParked" 
                                            title={t("adminCenter:cueLeadParked")}
                                            value={dashboardCue.parkedLead} 
                                            iconName="EntitlementPolicy" 
                                            link={"/app/admin/common/leads?source=SO-SMART&leadFilter=leadParked"}
                                        />
                                        <DataBox 
                                            name="activeCustomer" 
                                            title={t("adminCenter:cueActiveCustomer")}
                                            value={dashboardCue.customerActive} 
                                            iconName="EntitlementPolicy" 
                                            link={"/app/admin/customer?customerFilter=active"}
                                        />
                                        <DataBox 
                                            name="subscription" 
                                            title={t("adminCenter:cueSubscription")}
                                            value={dashboardCue.subscriptionCount}
                                            iconName="People" 
                                            link={"/app/admin/administrative/subscriptionHeader?subscriptionFilter=active"} 
                                        />
                                        <DataBox 
                                            name="invoice" 
                                            title={t("adminCenter:cueInvoice")} 
                                            value={dashboardCue.invoice} 
                                            iconName="People" 
                                            link={"/app/admin/administrative/invoiceHeaderList"} 
                                        />
                                    </Stack> 
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                            <DashboardContentColumn>
                                <DashboardContentContainer title="Contratti" name="contractPages">
                                    <Stack horizontal wrap tokens={{childrenGap: 10}}>
                                        <DataBox 
                                            name="baseUnsignedContracts" 
                                            title={t("adminCenter:cueBaseUnsignedContracts")}
                                            iconName="EntitlementPolicy" 
                                            value={dashboardCue.customerUnsignedBaseContract} 
                                            link={"/app/admin/customerDigitalContract?contractFilter=baseUnsigned"} 
                                        />
                                        <DataBox 
                                            name="sendNamirialFeContract" 
                                            title={t("adminCenter:cueSendNamirialFeContract")}
                                            value={dashboardCue.customerNamirialFEContract} 
                                            iconName="CheckList" 
                                            link={"/app/admin/customerDigitalContract?contractFilter=sendToNamirial"} 
                                        />
                                        <DataBox 
                                            name="feUnsignedContracts" 
                                            title={t("adminCenter:cueFeUnsignedContracts")}
                                            value={dashboardCue.customerUnsignedFEContract} 
                                            iconName="People" 
                                            link={"/app/admin/customerDigitalContract?contractFilter=feUnsigned"} 
                                        />
                                        <DataBox 
                                            name="customerSignedContract" 
                                            title={t("adminCenter:cueCustomerSignedContract")}
                                            value={dashboardCue.signedContract} 
                                            iconName="People" 
                                            link={"/app/admin/customerDigitalContract?contractFilter=signed"} 
                                        />
                                    </Stack> 
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                        </Stack>
                    </DashboardContentRow>
                </DashboardContainer>
        );
    }
}
