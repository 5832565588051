import { forwardRef } from "react";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminCustomerProgressChecklistTemplateCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    const adminService = new AdminService();

    return (
        <PageContainer pageType={SystemPageType.Card}
                title={"Scheda Customer Progress Checklist Template"} 
                endpoint="odata/customer/progress/checklist/template"
                ref={ref}>
            <CardGroup name="general" label="General" isOpen={true}>
                <CardField name="code" type={SystemFieldType.String} label="Codice" />
                <CardField name="description" type={SystemFieldType.String} label="Descrizione" />
                <CardField name="classification1" type={SystemFieldType.String} label="Classificazione 1" />
                <CardField name="classification2" type={SystemFieldType.String} label="Classificazione 2" />
                <CardField name="weight" type={SystemFieldType.Number} label="Peso" />
                <CardField name="forUserType" type={SystemFieldType.Option} label="Tipologia Utente" cmbOptions={[
                    {key: 0, text: "Utente Admin"},
                    {key: 1, text: "Utente Normale"},
                    {key: 2, text: "Entrambi i tipi"}
                ]} />
                <CardField name="actionType" type={SystemFieldType.Option} label="Tipologia Azione" cmbOptions={[
                    {key: 0, text: "Apertura Link"},
                    {key: 1, text: "Apertura Pagina"}
                ]} />
                <CardField name="priority" type={SystemFieldType.Number} label="Priorità" />
                <CardField name="groupCode" type={SystemFieldType.String} label="Codice Gruppo" />
                <CardField name="externalLink" type={SystemFieldType.String} label="Link Esterno" />
                <CardField name="pageEndpoint" type={SystemFieldType.String} label="EndPoint Pagina" />
                <CardField name="detailedDescription" type={SystemFieldType.String} label="Descrizione Dettagliata" />
                <CardField name="primaryButtonText" type={SystemFieldType.String} label="Testo Bottone Principale" />
                <CardField name="showAsTask" type={SystemFieldType.Boolean} label="Mostra come Task" />
                <CardField name="showNextButton" type={SystemFieldType.Boolean} label="Mostra Bottone Task successivo" />
                <CardField name="nextButtonText" type={SystemFieldType.String} label="Testo Bottone Task successivo" />
                <CardField name="sourceEntityName" type={SystemFieldType.String} label="Entità sorgente" hasTableRelation tableRelationEndpoint="odata/system/entity" tableRelationKey="name" tableRelationField="name" />
                <CardField name="sourceId" type={SystemFieldType.String} label="Id Source" />
                <CardField name="enabled" type={SystemFieldType.Boolean} label="Abilitato"/>
                <CardField name="waitingTitle" type={SystemFieldType.String} label="Titolo messaggio attesa"/>
                <CardField name="waitingMessage" type={SystemFieldType.String} label="Testo messaggio attesa"/>
            </CardGroup> 
        </PageContainer>
    );
});