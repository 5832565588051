import React, { forwardRef, useEffect, useState} from "react";
import { PostalCode, SystemFieldType, SystemOperation, SystemPageType } from "../../../Model/SystemModels";
import PageContainer, { PageContainerBase } from "../../Common/Template/PageContainer";
import { CardGroup } from "../../Common/Template/Card/CardGroup";
import { CardField } from "../../Common/Template/Card/CardField";
import { CountryRegion, Customer } from "../../../Model/CustomerModels";
import { t } from "i18next";
import { AxiosInstance } from "axios";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { AdminService } from "../../../ApplicationCode/Admin/AdminService";
import { ToastService } from "../../../Core/Toast/ToastService";
import { IComboBoxOption } from "@fluentui/react";
import { OpenAPICompanyITAdvancedData } from "../../../Model/OpenAPIModel";

export interface ICustomerBillingProps{
    onSuccess:(operationType: SystemOperation, customer:Customer)=>void
    record: Customer;
}

export const CustomerBillingInfoCard = forwardRef<PageContainerBase, React.PropsWithChildren<ICustomerBillingProps>>((props, ref) => {
    const [allowFreeForm, setAllowFreeForm] = useState<boolean>(props.record.foreignAddress);
    const [updating, setUpdating] = useState<boolean>(false);
    const [validatedVat, setValidatedVat] = useState<string>(props.record.vat);
    const [countryOptions, setCountryOptions] = useState<IComboBoxOption[]>([]);
    const [cityOptions, setCityOptions] = useState<IComboBoxOption[]>([]);
    const [countyOptions, setCountyOptions] = useState<IComboBoxOption[]>([]);
    const [postalCodeData, setPostalCodeData] = useState<PostalCode[]>([]);   

    const generateCompanyAddress = (companyData: OpenAPICompanyITAdvancedData): string => {
        let address = companyData?.address.registeredOffice;
        if (address?.toponym && address.street && address.streetNumber)
            return address.toponym + ' ' + address.street + ', ' + address.streetNumber;

        return address?.streetName ?? '';
    }

    const checkCompanyData = (vat: string, currentRecord: Customer): Customer | undefined => {
        if (vat === validatedVat)
            return currentRecord;

        if (!vat || vat.length !== 11)
            return;
        
        setValidatedVat(vat);
        setUpdating(true);
        
        let cityOptions: IComboBoxOption[] = [];
        let countyOptions: IComboBoxOption[] = [];

        const adminService = new AdminService();
        var promise = adminService.getVatOpenAPIData(vat, 0, currentRecord.id)
        .then((resp)=>{
            if (resp.success) {
                let companyData: OpenAPICompanyITAdvancedData = resp.data[0];

                if (companyData?.companyName)
                    currentRecord.companyName = companyData.companyName;
                if (companyData?.sdiCode)
                    currentRecord.sdi = companyData.sdiCode;
                if (companyData?.pec)
                    currentRecord.pecEmail = companyData.pec;
                if (generateCompanyAddress(companyData))
                    currentRecord.streetAddress = generateCompanyAddress(companyData);
                if (companyData?.address.registeredOffice.town)
                    currentRecord.city = companyData.address.registeredOffice.town;
                if (companyData?.address.registeredOffice.province)
                    currentRecord.county = companyData.address.registeredOffice.province;
                if (companyData?.address.registeredOffice.zipCode)
                    currentRecord.zipCode = companyData.address.registeredOffice.zipCode;
                
                if (currentRecord.zipCode !== "") {
                    let postalCodeOptions: PostalCode[] = postalCodeData.filter(item => item.zipCode == currentRecord.zipCode);
                    postalCodeOptions.forEach((value: PostalCode, index: number, array: PostalCode[]) => {
                        if(countyOptions.findIndex(item => item.key == value.county) < 0) {
                            countyOptions.push({
                                key: value.county,
                                text: value.county
                            });
                        }
                        cityOptions.push({
                            key: value.city,
                            text: value.city
                        });
                    })
                    
                    if (countryOptions.length > 0) {
                        if (countryOptions.find(item => item.key.toString().toUpperCase() === 'IT'))
                            currentRecord.country = countryOptions.find(item => item.key.toString() === 'IT')!.key.toString();
                    }
                    
                    if (cityOptions.length === 1) {
                        currentRecord.city = cityOptions[0].key.toString();
                    }
                    else {
                        if (cityOptions.findIndex(item => item.key.toString().toUpperCase() === currentRecord.city.toUpperCase()) >= 0)
                            currentRecord.city = cityOptions[cityOptions.findIndex(item => item.key.toString().toUpperCase() === currentRecord.city.toUpperCase())].key.toString();
                    }
                    
                    if (countyOptions.length === 1) {
                        currentRecord.county = countyOptions[0].key.toString();
                    }
                    else {
                        if (countyOptions.findIndex(item => item.key.toString().toUpperCase() === currentRecord.county.toUpperCase()))
                            currentRecord.county = countyOptions[cityOptions.findIndex(item => item.key.toString() === currentRecord.county.toUpperCase())].key.toString();
                    }
                }
                
                setCityOptions(cityOptions);
                setCountyOptions(countyOptions);                

                return currentRecord;
            }
        })
        .catch((err)=>{
            console.log(err);
        })
        .finally(() => {
            setUpdating(false);
        });

        ToastService.showPromiseMessage(promise, t('common:qualification:Text0067Lbl')!, "", "");
    }
    
    const initCountryRegionData = (): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            let client: AxiosInstance = HttpHelper.buildAxiosInstance();
            client.get<CountryRegion[]>('/odata/CountryRegion?$filter=enabled eq true').then((resp) => {
                let options: IComboBoxOption[] = [];
                resp.data.forEach((value: CountryRegion, index: number, array: CountryRegion[]) => {
                    options.push({
                        key: value.countryCode,
                        text: value.country
                    })
                });
                console.log(options);
                setCountryOptions(options);
                resolve(true);
            })
            .catch((err) => {
                reject(err.response.data.message);
            });
        });
    }

    const getPostalCodes = (countryCode: string, clearPostalCode: boolean): Promise<PostalCode[]> => {
        return new Promise<PostalCode[]>((resolve, reject) => {
            setCityOptions([]);
            setCountyOptions([]);
            
            if(clearPostalCode) {
                validateLocationData("", postalCodeData);
            }
            
            const client = HttpHelper.buildAxiosInstance();
            client.get<PostalCode[]>(`/odata/PostalCode?$filter=countryCode eq '${countryCode}'`).then((resp) => {
                setPostalCodeData(resp.data);
                setAllowFreeForm(resp.data.length === 0);
                resolve(resp.data);
            })
            .catch((err) => {
                console.log(err);
                reject(err.response.data.message);
            });
        });
    }

    const validateLocationData = (postalCode: string, postalCodes: PostalCode[]) => {
        let cityOptions: IComboBoxOption[] = [];
        let countyOptions: IComboBoxOption[] = [];
        
        props.record.zipCode = postalCode;
        props.record.city = "";
        props.record.county = "";
        
        if(postalCode !== ""){
            let postalCodeOptions: PostalCode[] = postalCodes.filter(item => item.zipCode == postalCode);
            postalCodeOptions.forEach((value: PostalCode, index: number, array: PostalCode[]) => {
                if(countyOptions.findIndex(item => item.key == value.county) < 0){
                    countyOptions.push({
                        key: value.county,
                        text: value.county
                    });
                }
    
                cityOptions.push({
                    key: value.city,
                    text: value.city
                });
            })
    
            if(cityOptions.length == 1) {
                props.record.city = cityOptions[0].key.toString();
            }
            else {
                if (cityOptions.findIndex(item => item.key.toString().toUpperCase() === props.record.city.toUpperCase()) >= 0)
                    props.record.city = cityOptions[cityOptions.findIndex(item => item.key.toString().toUpperCase() === props.record.city.toUpperCase())].key.toString();
            }

            if(countyOptions.length == 1) {
                props.record.county = countyOptions[0].key.toString();
            }
            else {
                if (countyOptions.findIndex(item => item.key.toString().toUpperCase() === props.record.county.toUpperCase()))
                    props.record.county = countyOptions[cityOptions.findIndex(item => item.key.toString() === props.record.county.toUpperCase())].key.toString();
            }
        }

        setCityOptions(cityOptions);
        setCountyOptions(countyOptions);
    }

    useEffect(()=>{
        setUpdating(true);
        initCountryRegionData().then(() => {
            getPostalCodes(props.record.country, false).then((resp) => {
                validateLocationData(props.record.zipCode, resp);
                setUpdating(false);
            });
        });
    }, [props.record]);
        
    return (
        <>
            <PageContainer pageType={SystemPageType.Card}
                title={t('customerCenter:CustomerBillingInfoCard:title')} 
                endpoint="odata/customer"
                ref={ref}
                onSuccess={props.onSuccess}>
                <CardGroup name="general" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:label')} isOpen={true}>
                    {
                        (props.record.legalEntityCode !== 'ESS')
                        ?
                        <CardField maxLength={11} name="vat" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:vat')} type={SystemFieldType.String} readonly={updating} placeholder={t('common:qualification:Text0066Lbl')!}
                            onValidate={(fieldName: string, record: any, oldValue: any, newValue: any) => {
                                if (oldValue === newValue)
                                    return;
                                
                                return checkCompanyData(newValue, record);
                            }}
                        />
                        : undefined
                    }
                    <CardField name="companyName" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:companyName')} type={SystemFieldType.String} readonly={updating} />
                    <CardField name="firstName" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:firstName')} type={SystemFieldType.String} readonly={updating} />
                    <CardField name="lastName" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:lastName')} type={SystemFieldType.String} readonly={updating} />
                    <CardField name="phoneNo" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:phoneNo')} type={SystemFieldType.String} readonly={updating} />
                    <CardField name="legalRepresentativeFirstName" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:legalRepresentativeFirstName')} type={SystemFieldType.String} readonly={updating} />
                    <CardField name="legalRepresentativeLastName" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:legalRepresentativeLastName')} type={SystemFieldType.String} readonly={updating} />
                    <CardField maxLength={2} name="country" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:country')} cmbOptions={countryOptions} type={SystemFieldType.Option} readonly={updating} onValidate={(fieldName: string, record: any, oldValue: any, newValue: any)=>{
                        getPostalCodes(newValue, true);
                    }} />
                    <CardField name="streetAddress" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:streetAddress')} type={SystemFieldType.String} readonly={updating} />
                    <CardField maxLength={10} name="zipCode" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:zipCode')} type={SystemFieldType.String} readonly={updating} onValidate={(fieldName: string, record: any, oldValue: any, newValue: any)=>{
                        validateLocationData(newValue, postalCodeData);
                    }} />
                    {
                        allowFreeForm
                        ? <CardField name="city" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:city')} type={SystemFieldType.String} readonly={updating} />
                        : <CardField name="city" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:city')} cmbOptions={cityOptions} type={SystemFieldType.Option} readonly={updating} />
                    }
                    {
                        allowFreeForm
                        ? <CardField name="county" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:county')} type={SystemFieldType.String} readonly={updating} />
                        : <CardField name="county" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:county')} cmbOptions={countyOptions} type={SystemFieldType.Option} readonly={updating} />
                    }
                    <CardField name="email" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:email')} type={SystemFieldType.String} readonly={updating} />
                    {
                        (props.record.legalEntityCode !== 'ESS')
                        ?
                        <CardField name="sdi" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:sdi')} type={SystemFieldType.String} readonly={updating} />
                        : undefined
                    }
                    {                        
                        (props.record.legalEntityCode !== 'ESS')
                        ?
                        <CardField name="pecEmail" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:pecEmail')} type={SystemFieldType.String} readonly={updating} />
                        : undefined
                    }
                </CardGroup>
            </PageContainer>
        </>
    );
});