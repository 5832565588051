import React, { forwardRef} from "react";
import {SystemFieldType, SystemPageType} from "../../../../Model/SystemModels";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import { CardField } from "../../../Common/Template/Card/CardField";

export const AdminCrmSetupCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda setup CRM "} endpoint="odata/CRMSetup" ref={ref}>
            <CardGroup name="setup" label="Setup" isOpen={false}>
                <CardField name="primaryKey" label="Chiave primaria" type={SystemFieldType.String} />
                <CardField name="authType" label="Auth Type" type={SystemFieldType.Number} />
                <CardField name="resource" label="Risorsa" type={SystemFieldType.String} />
                <CardField name="tenantId" label="Id Tenant" type={SystemFieldType.String} />
                <CardField name="clientId" label="Client Id" type={SystemFieldType.String} />
                <CardField name="clientSecret" label="Client Seecreet" type={SystemFieldType.String} />
                <CardField name="redirectUrl" label="Redirect Url" type={SystemFieldType.String} />
                <CardField name="aadInstance" label="Instance AAD" type={SystemFieldType.String} />
                <CardField name="oDataVersion" label="Odata Version" type={SystemFieldType.String} />
                <CardField name="oDataMaxVersion" label="Odata max version" type={SystemFieldType.String} />
                <CardField name="defaultServiceLevel" label="Default Service Level" type={SystemFieldType.String} />
                <CardField name="crmSyncEnabled" label="Crm sync enabled" type={SystemFieldType.Boolean} />
            </CardGroup>
            <CardGroup name="ticketChatbot" label="Ticket chatbot" isOpen={false}>
                <CardField name="commercialSupportUserId" label="Utente supporto commerciale" type={SystemFieldType.Number} hasTableRelation tableRelationEndpoint="/odata/user" tableRelationKey="id" tableRelationField="displayName" tableRelationFilter="?$filter=crmSystemUserId ne null" />
                <CardField name="technicalSupportUserId" label="Utente supporto tecnico" type={SystemFieldType.Number} hasTableRelation tableRelationEndpoint="/odata/user" tableRelationKey="id" tableRelationField="displayName" tableRelationFilter="?$filter=crmSystemUserId ne null" />
                <CardField name="taskDueDays" label="Giorni scadenza tasks" type={SystemFieldType.Number} />
            </CardGroup>
            <CardGroup name="account" label="Account" isOpen={false}>
                <CardField name="defaultCustomerTypeCode" label="Default Customer Type Code" type={SystemFieldType.String} />
                <CardField name="defaultProspectTypeCode" label="Default Prospect Type Code" type={SystemFieldType.String} />
                <CardField name="defaultCountryId" label="Default Country Id" type={SystemFieldType.Number} />
                <CardField name="defaultLegalEntityId" label="Default Legal Entity Id" type={SystemFieldType.String} />
                <CardField name="serviceScopeEOSName" label="Service Scope Eos Name" type={SystemFieldType.String} />
                <CardField name="serviceScopeQueueId" label="Service Scope Queue Id" type={SystemFieldType.String} />
                <CardField name="serviceScopeId" label="Service Scope Id" type={SystemFieldType.String} />
            </CardGroup>
            <CardGroup name="lead" label="Lead" isOpen={false}>
                <CardField name="baseAddress" label="Base Address Lead" type={SystemFieldType.String} />
                <CardField name="defaultCRMTeamId" label="Default CRM Team Id" type={SystemFieldType.String} />
                <CardField name="leadLogLimitLowUse" label="Limite Log Basso Utilizzo" type={SystemFieldType.Number} />
                <CardField name="temporaryLeadLimitDay" label="Giorni limite Lead temporanee" type={SystemFieldType.Number} />
                <CardField name="leadReferralLinkTemplateCode" label="Template referral link" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="odata/email/template" tableRelationKey="emailTemplateCode" tableRelationField="description" />
            </CardGroup>
            <CardGroup name="entitlement" label="Entitlement" isOpen={false}>
                <CardField name="defaultEntitlementName" label="Default Entity Element Name" type={SystemFieldType.String} />
            </CardGroup>
            <CardGroup name="incident" label="Incident" isOpen={false}>
                <CardField name="incidentBaseAddress" label="Base Address Incident" type={SystemFieldType.String} />
                <CardField name="incidentDayLimitForExpiration" label="Giorni Ritardo Apertura" type={SystemFieldType.Number} />
                <CardField name="incidentDayLimitForTakeInCharge" label="Giorni Ritardo Presi In Carico" type={SystemFieldType.Number} />
                <CardField name="defaultCRMIncidentTeamId" label="Default CRM Incident Team Id" type={SystemFieldType.String} />             
            </CardGroup>
            <CardGroup name="systemUser" label="System User" isOpen={false}>
                <CardField name="crmSystemUserFilterSyncronization" label="Filtro Sincronizzazione System Users" type={SystemFieldType.String} />
            </CardGroup>
            <CardGroup name="notification" label="Notifiche" isOpen={false}>
                <CardField name="notificationEmail" label="E-mail notifica" type={SystemFieldType.String} />
                <CardField name="partnerAppId" label="App Partner" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="odata/system/app" tableRelationKey="id" tableRelationField="name" />
                <CardField name="adminAppId" label="App Admin" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="odata/system/app" tableRelationKey="id" tableRelationField="name" />
                <CardField name="enableNotification" label="Abilita notifica" type={SystemFieldType.Boolean} />
            </CardGroup>
            <CardGroup name="leadNotification" label="Notifiche Lead" isOpen={false}>
                <CardField name="leadNotificationSetupType" type={SystemFieldType.Option} label="Tipo setup Lead" cmbOptions={[
                    {key: 0, text: "Email"},
                    {key: 1, text: "Chat"}
                ]} />
                <CardField name="leadNotificationSetupCode" type={SystemFieldType.String} label="Codice setup Lead"></CardField>
                <CardField name="leadNotificationTemplateCode" type={SystemFieldType.String} label="Codice template Lead"></CardField>
                <CardField name="leadNotificationGroupCode" type={SystemFieldType.String} label="Codice gruppo Lead"></CardField>
                <CardField name="leadProspectNotificationTemplateCode" type={SystemFieldType.String} label="Codice template prospetto lead automation"></CardField>
            </CardGroup>
            <CardGroup name="customerNotification" label="Notifiche Customer" isOpen={false}>
                <CardField name="customerNotificationSetupType" type={SystemFieldType.Option} label="Tipo setup Customer" cmbOptions={[
                    {key: 0, text: "Email"},
                    {key: 1, text: "Chat"}
                ]} />
                <CardField name="customerNotificationSetupCode" type={SystemFieldType.String} label="Codice setup Customer" />
                <CardField name="customerNotificationTemplateCode" type={SystemFieldType.String} label="Codice template Customer" />
                <CardField name="customerNotificationGroupCode" type={SystemFieldType.String} label="Codice gruppo Customer" />
            </CardGroup>
            <CardGroup name="knowledgeArticle" label="Knowledge article" isOpen={false}>
                <CardField name="crmKnowledgeArticleFilterSyncronization" type={SystemFieldType.String} label="Filtro sincronizzazione knowledge article" />
                <CardField name="lastKnowledgeArticleSyncDate" label="Data ultima sincronizzazione" type={SystemFieldType.Date} />
                <CardField name="crmKnowledgeArticleHtmlTemplate" type={SystemFieldType.String} label="HTML Template" />
                <CardField name="knowledgeArticleBaseAddress" type={SystemFieldType.String} label="Knowledge article base address" />
                <CardField name="defaultBusinessLineCode" type={SystemFieldType.Option} label="Codice default business line" hasTableRelation tableRelationEndpoint="odata/business/line" tableRelationKey="code" tableRelationField="description" />
            </CardGroup>
            <CardGroup name="leadAutomation" label="Lead automation" isOpen={false}>
                <CardField name="leadQualificationInteractionLimitDays" label="Giorni limite qualifica" type={SystemFieldType.Number} />
                <CardField name="leadGetDemoFeedbackLimitDays" label="Giorni limite richiesta feedback" type={SystemFieldType.Number} />
                <CardField name="leadWithoutDemoFeedbackLimitDays" label="Giorni limite demo feedback" type={SystemFieldType.Number} />
                <CardField name="leadQualifiedWithoutInteractionLimitDays" label="Giorni limite qualificate senza interazioni" type={SystemFieldType.Number} />
            </CardGroup>
        </PageContainer>
    );
});