import { SetStateAction } from "react";
import { IAppStateContext } from "../../../../Core/State/ChatbotAppProvider";
import { Item } from "../../../../Model/PartnerModels";

export type AskResponse = {
    answer: string;
    citations: Citation[];
    error?: string;
};

export type Citation = {
    content: string;
    id: string;
    title: string | null;
    filepath: string | null;
    url: string | null;
    metadata: string | null;
    chunk_id: string | null;
    reindex_id: string | null;
}

export type ToolMessageContent = {
    citations: Citation[];
    intent: string;
}

export type ChatMessage = {
    id: string;
    role: string;
    content: string;
    end_turn?: boolean;
    date: string;
    type: ChatMessageType;
    subType?: string;
    positiveFeedback?: boolean;
    negativeFeedback?: boolean;
    isLogged?: boolean;
    followupQuestions?: string[];
};

export enum ChatMessageType {
    Conversation = "chat.conversation",
    BotRequest = "chat.bot.request"
}

export type ToolContentEntry = {
    citations: string[];
    intent: string;
}

export type Conversation = {
    id: string;
    title: string;
    messages: ChatMessage[];
    languageCode: string;
    date: string;
}

export enum ChatCompletionType {
    ChatCompletion = "chat.completion",
    ChatCompletionChunk = "chat.completion.chunk"
}

export type ChatResponseChoice = {
    messages: ChatMessage[];
}

export type ChatResponse = {
    id: string;
    model: string;
    created: number;
    object: ChatCompletionType;
    choices: ChatResponseChoice[];
    history_metadata: {
        conversation_id: string;
        title: string;
        date: string;
    }
    error?: any;
}

export type ConversationRequest = {
    messages: ChatMessage[];
    languageCode: string;
};

export type UserInfo = {
    access_token: string;
    expires_on: string;
    id_token: string;
    provider_name: string;
    user_claims: any[];
    user_id: string;
};

export enum CosmosDBStatus {
    NotConfigured = "CosmosDB is not configured",
    NotWorking = "CosmosDB is not working",
    Working = "CosmosDB is configured and working",
}


export type SupportUserInput = {
    key: string;
    value: any;
    systemGenerated?: boolean;
}

export interface ISupportFlow {
    handleStep(app: Item, stepId: number, userInput: SupportUserInput | undefined, appStateContext: IAppStateContext, conversation: Conversation, setMessages: (value: SetStateAction<ChatMessage[]>) => void, openAiAPICall: (question: string, conversationId?: string, skipMessageCreation?: boolean) => Promise<void>) : Promise<number>;
    initializeStartStep(getConditions?: () => boolean): number;
    handleElement(msg: ChatMessage, onUserInput: (key: any, value: any) => void) : JSX.Element;
    handleFastActions(stepId: number, onClickHandler: (key: any, value: any) => void) : JSX.Element | undefined;
    loadRequiredData(input?: any): Promise<void>;
    showUserMessage(stepId: number): boolean;
    isFlowCompleted(stepId: number): boolean;
    isErrorHandled(stepId: number): boolean;
}

export enum SupportFlowType {
    SoSmartSimple,
    SoSmartAdvanced,
    EosApps
}

export type CosmosDBHealth = {
    cosmosDB: boolean,
    status: string
}

export enum ChatHistoryLoadingState {
    Loading = "loading",
    Success = "success",
    Fail = "fail",
    NotStarted = "notStarted"
}

export type ErrorMessage = {
    title: string,
    subtitle: string
}

export enum EosAppSupportStep {
    Email = "support.email",
    App = "support.app",
    SupportType = "support.type",
    CustomerId = "support.customerid",
    InstallationType = "support.installationtype",
    AppVersion = "support.app.version",
    SupportRequest = "support.request"
}

export enum SupportType
{
    EosApp,
    SoSmart
}


export class CrmIncidentType {
    public entryNo: number = 0;
    public name: string = "";
    public toShow: boolean = false;
}

export class SupportEntry {
    public id: string = "";
    public bcBaseVersion: string = "";
    public bcSystemVersion: string = "";
    public pageName: string = "";
    public sourceRecord: string = "";
    public url: string = "";
    public lastError: string = "";
    public lastErrorDate: Date = new Date();
    public role: string = "";
    public license: string = "";
    public processModule = "";
    public processCode: string = "";
    public processLineNo: number = 0;
    public newProcessModule = "";
    public newProcessCode: string = "";
    public newProcessLineNo: number = 0;
    public customerComment: string = "";
    public processModuleOther: boolean = false;
    public processOther: boolean = false;
    public processTestOther: boolean = false;
    public userFullName: string = "";
    public tenantId: string = "";
    public environmentName: string = "";
    public companyName: string = "";
    public systemId: string = "";
}

export class BCNProcessModule {
    public code: string = "";
    public description: string = "";
    public enabled: boolean = false;
}

export class BCNProcess {
    public processModule: string = "";
    public processCode: string = "";
    public soSmartEnabled: boolean = false;
    public sequence: number = 0;
    public description: string = "";
}

export class BCNProcessTest {
    public processModule: string = "";
    public processCode: string = "";
    public lineNo: number = 0;
    public description: string = "";
}