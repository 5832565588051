import React, { useRef }  from "react";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminItemCard } from "../AdminItemCard/AdminItemCard";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { useNavigate } from "react-router-dom";

export const AdminItemList : React.FC = () => {
    
    const cardRef = useRef<PageContainerBase>(null);
    const navigate = useNavigate();

    return (
        <>
            <PageContainer endpoint="/odata/item" pageType={SystemPageType.List} title="Articoli" cardRef={cardRef} backAllowed showAllowed insertAllowed updateAllowed>               
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="OpenItemProductSkuId" iconName="ProductList" label="Product Sku Id articoli" runOnRec onClick={(req:any)=>{
                            navigate("/app/admin/related/itemProductSkuId?itemId="+req.id);
                        }}></ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>                                 
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Id" fieldName="id" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Legal Entity" fieldName="legalEntityCode" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Categoria" fieldName="category" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Ciclo Fatturazione" fieldName="billingCycle" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Tipo Item" fieldName="itemType" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Codice item ecommerce" fieldName="itemEcommerceCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Codice Modulo" fieldName="moduleCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Durata" fieldName="termDuration" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Numero ERP Item" fieldName="itemErpNo" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Descrizione ERP" fieldName="erpDescription" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id gruppo Aad" fieldName="aadGroupId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id Prodotto CSP" fieldName="productId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id Sku Prodotto CSP" fieldName="productSkuId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id Sku Available CSP" fieldName="productSkuAvailabilityId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Nome Prodotto CSP" fieldName="cspProductName" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Prezzo Unitario" fieldName="unitPrice" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Costo Unitario" fieldName="unitCost" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Tipo Prezzo" fieldName="priceType" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Trial Id Prodotto CSP" fieldName="trialProductId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Trial Id Sku Prodotto CSP" fieldName="trialProductSkuId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Trial Quantità Prodotto CSP" fieldName="trialProductQuantity" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Mostra su offerta lead" fieldName="showInLeadQuote" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Priorità" fieldName="priority" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Obbligatorio" fieldName="mandatory" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminItemCard ref={cardRef} />
        </>
    );
}