import { Stack, Icon, Label } from "@fluentui/react";
import { useState } from "react";

export interface ICustomAttachmentFileProps {
    id: string;
    fileName: string;
    fileExtension: string;
    onRemoveClick: (id: string) => void;
}

export const CustomAttachmentFile: React.FC<ICustomAttachmentFileProps> = (props) =>{
    const [hover, setHover] = useState(false);

    let iconName: string = "";
    switch (props.fileExtension.toLowerCase()) {
        case "html": case "htm":
            iconName = "FileHTML";
            break;
        case "pdf":
            iconName = "PDF";
            break;
        case "jpg": case "jpeg": case "png":
            iconName = "FileImage";
            break;
        case "xls": case "xlsx":
            iconName = "ExcelDocument";
            break;
        case "ppt": case "pptx":
            iconName = "PowerPointDocument";
            break;
        case "doc": case "docx":
            iconName = "WordDocument";
            break;
        default:
            break;
    }

    return(
        <Stack key={props.id.toString()} horizontal styles={{
            root: {
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #bebebe',
                borderRadius: 10,
                padding: 10,
                cursor: 'pointer',
                selectors: {
                    "&:hover": {
                        backgroundColor: '#f2f2f2'
                    }
                }
            }
        }}
        onMouseEnter={()=>{setHover(true)}} onMouseLeave={()=>{setHover(false)}}
        tokens={{
            childrenGap: 5
        }}>
            <Stack.Item>
                <Icon iconName={iconName} style={{
                    fontSize: 20,
                    cursor: 'inherit'
                }} /> 
            </Stack.Item>
            <Stack.Item>
                <Label style={{
                    fontSize: 12,
                    cursor: 'inherit'
                }}>{props.fileName}</Label>
            </Stack.Item>
            {
                hover ?
                <Stack.Item style={{
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 15,
                    width: 30,
                    height: 30,
                    top: -10,
                    right: -10,
                    backgroundColor: '#fff',
                    border: '1px solid #bebebe',
                    borderRadius: '50%'
                }}
                onClick={() => props.onRemoveClick(props.id)}>
                    <Icon iconName="Delete" style={{
                        color: 'red',
                        cursor: 'inherit'
                        }} />
                </Stack.Item>
                :
                <></>
            }
        </Stack>
    );
}