import { forwardRef } from "react";
import { SystemPageType } from "../../../../Model/SystemModels";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { CustomerQueuedOperationStructure } from "../AdminCustomerQueuedOperationList/AdminCustomerQueuedOperationList";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import { CardField } from "../../../Common/Template/Card/CardField";

export interface CustomerQueuedOperationCardProps {
    openBy: "customer" | "environment";
    displayTitle: string;
    structure: CustomerQueuedOperationStructure[];
}

export const AdminCustomerQueuedOperationCard = forwardRef<PageContainerBase, React.PropsWithChildren<CustomerQueuedOperationCardProps>>((props, ref) => {

    function getStructure(): JSX.Element[] {
        let currentStructure: JSX.Element[] = [];

        props.structure.forEach((element: CustomerQueuedOperationStructure) => {
            if(element.fieldName === "environmentName"){
                if(props.openBy === "customer")
                    currentStructure.push(
                        <CardField 
                            label={element.displayName}
                            name={element.fieldName}
                            type={element.fieldType}
                        />
                    );
            }
            else
                currentStructure.push(
                    <CardField 
                        label={element.displayName}
                        name={element.fieldName}
                        type={element.fieldType}
                    />
                );
        });

        return currentStructure;
    }

    return (
        <PageContainer
            ref={ref}
            endpoint="/odata/customer/queuedoperation"
            pageType={SystemPageType.Card}
            title={"Coda operazioni: " + props.displayTitle}
        >
            <CardGroup
                isOpen
                label="Generale"
                name="general"
            >
                {getStructure()}
            </CardGroup>
        </PageContainer>
    );
});