import { AskResponse, Citation } from "../../../../ApplicationCode/Common/Chatbot/api";
import { cloneDeep } from "lodash-es";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";


type ParsedAnswer = {
    citations: Citation[];
    markdownFormatText: string;
};

export function parseAnswer(answer: AskResponse): ParsedAnswer {
    const embedded: boolean = (HttpHelper.getParameter("embedded") === "true");
    const clearTypeErrRegEp = new RegExp(/\ \.|\ ,|\ ;|\ !|\ \?|\ :/g);
    let replaceText: string = "";
    let answerText = answer.answer;
    const citationLinks = answerText.match(/\[(.*?)\]\((.*?)\)/g);

    let filteredCitations = [] as Citation[];
    let citationReindex = 0;
    citationLinks?.forEach((link, index) => {
        // Replacing the links/citations with number
        let i = 0;
        let start = false;
        let title = "";
        for (i = 0; i < link.length; i++)
        {
            if (! start)
            {
                if (link[i] == '[')
                    start = true;
            }
            else
            {
                if (link[i] == ']')
                    break;
                title += link[i];
            }
        }

        start = false;
        let url = "";
        for (i = 0; i < link.length; i++)
        {
            if (! start)
            {
                if (link[i] == '(')
                    start = true;
            }
            else
            {
                if (link[i] == ')')
                    break;
                url += link[i];
            }
        }

        citationReindex++;
        let newCItation: Citation = {
            title: title,
            id: index.toString(),
            reindex_id: citationReindex.toString(),
            content: title,
            filepath: '',
            url: url,
            metadata: null, 
            chunk_id: title
        };
        
        let check = filteredCitations.findIndex(e => e.title === newCItation.title);
        if (check < 0)
        {
            filteredCitations.push(newCItation);
            replaceText = ` [^${citationReindex}^](${newCItation.url}) `;
        }
        else
        {
            let currentCit = filteredCitations[check];
            replaceText = ` [^${currentCit.reindex_id}^](${currentCit.url}) `;
        }

            
          answerText = answerText.replaceAll(link, replaceText);
    });


    return {
        citations: filteredCitations,
        markdownFormatText: answerText
    };
}
