import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import {SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";

export const AdminDataSecurityRecordLogList : React.FC = () => {
    const cardRef = useRef<PageContainerBase>(null);
    const entityName = HttpHelper.getParameter('entityName')
    const recordId = HttpHelper.getParameter('recordId')
    const navigate = useNavigate();

    return (
        <>
            <PageContainer endpoint={"/odata/datasecurity/record/log?$filter=entityName eq '" + entityName + "' and recordId eq '" + recordId + "'"} pageType={SystemPageType.List} title="Log Data Security" backAllowed>               
                <PageDefinitionContainer type={ContainerType.Action}>
                </PageDefinitionContainer>                             
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Da stato" fieldName="fromCode" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="A stato" fieldName="toCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Data creazione" fieldName="systemCreationDate" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
        </>
    );
}