import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";

export const AdminUserApplicationTaskList : React.FC = () => {
    
    const userId = HttpHelper.getParameter('userId')
    
    return (
        <>
            <PageContainer endpoint={"/odata/user/application/task?$filter=userId eq " + userId} pageType={SystemPageType.List} title="Task Utente" backAllowed>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Codice" fieldName="Code" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Descrizione" fieldName="Description" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Codice Gruppo" fieldName="GroupCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Priorità" fieldName="Priority" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Tipologia Azione" fieldName="ActionType" minWidth={150} maxWidth={250} onRender={(item: any, index, column) => {
                        if (item.ForUserType === 0)
                            return "Utente Admin"
                        else if (item.ForUserType === 1)
                            return "Utente Normale"
                        else if (item.ForUserType === 2)
                            return "Entrambi i tipi"
                    }} />
                    <ListHeaderEntry name="Link Esterno" fieldName="ExternalLink" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="EndPoint Pagina" fieldName="PageEndPoint" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Completato" fieldName="Completed" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Data/ora creazione" fieldName="SystemCreationDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Data/ora modifica" fieldName="SystemUpdateDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Non mostrare più" fieldName="DoNotShowAgain" minWidth={100} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
        </>
    );
}