import { useRef } from "react";
import { SystemOperation, SystemPageType } from "../../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../../Common/Template/PageDefinitionContainer";
import { AdminAzureGDAPSecurityGroupRoleCard } from "../AdminAzureGDAPSecurityGroupRoleCard/AdminAzureGDAPSecurityGroupRoleCard";

export const AdminAzureGDAPSecurityGroupRoleList : React.FC = () => {
    
    const cardRef = useRef<PageContainerBase>(null);
    
    return (
        <>
            <PageContainer endpoint={'/odata/azure/gdap/security/group/role'} pageType={SystemPageType.List} title={"Lista gruppi sicurezza / ruoli azure GDAP"} cardRef={cardRef} backAllowed showAllowed insertAllowed updateAllowed
                onNewRecord={() => {
                    cardRef.current?.openPage(SystemOperation.Insert);
                    cardRef.current?.setRecord({
                        languageCode: ''
                });
            }}>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry fieldName="legalEntityCode" name="Legal entity" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry fieldName="distributorCode" name="Distributore" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry fieldName="partnerBusinessContractCode" name="Partner business contract" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry fieldName="languageCode" name="Lingua" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry fieldName="securityGroupCode" name="Codice gruppo sicurezza" minWidth={300} maxWidth={900} />
                    <ListHeaderEntry fieldName="securityGroupName" name="Nome gruppo sicurezza" minWidth={300} maxWidth={900} />
                    <ListHeaderEntry fieldName="roleCode" name="Codice ruolo" minWidth={300} maxWidth={900} />
                    <ListHeaderEntry fieldName="roleName" name="Nome ruolo" minWidth={300} maxWidth={900} />
                    <ListHeaderEntry fieldName="active" name="Attivo" minWidth={100} maxWidth={200} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminAzureGDAPSecurityGroupRoleCard ref={cardRef} />
        </>
    );
}