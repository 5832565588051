import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminBookingSetupCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Booking setup"} endpoint="/odata/booking/setup" ref={ref}>
            <CardGroup name="localization" label="Localizzazione" isOpen={true}>
                <CardField name="legalEntityCode" label="Legal entity" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/crm/legal/entity" tableRelationKey="code" tableRelationField="name"/>
                <CardField name="distributorCode" label="Distributore" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/distributor" tableRelationKey="code" tableRelationField="name"/>
                <CardField name="partnerBusinessContractCode" label="Partner business contract" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/partner/business/contract" tableRelationKey="code" tableRelationField="description"/>
                <CardField name="languageCode" label="Lingua" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/system/language" tableRelationKey="code" tableRelationField="name"/>
            </CardGroup>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="instance" type={SystemFieldType.String} label="Istanza" />
                <CardField name="clientId" type={SystemFieldType.String} label="Client Id" />
                <CardField name="clientSecret" type={SystemFieldType.String} label="Client secret" />
                <CardField name="clientIdApplication" type={SystemFieldType.String} label="Client Id application" />
                <CardField name="clientSecretApplication" type={SystemFieldType.String} label="Client secret application" />
                <CardField name="tenantId" type={SystemFieldType.String} label="Tenant Id" />
                <CardField name="redirectUrl" type={SystemFieldType.String} label="Redirect URL" />
                <CardField name="scope" type={SystemFieldType.String} label="Scope" />
                <CardField name="pageFilter" type={SystemFieldType.Number} label="Filtro paginazione" />
                <CardField name="soSmartCalendarId" type={SystemFieldType.String} label="SoSmart calendar Id" />
                <CardField name="lastSyncronizationDate" type={SystemFieldType.Date} label="Data ultima sincronizzazione" />
                <CardField name="syncronizationDelayDays" type={SystemFieldType.Number} label="Giorni ritardo sincronizzazione" />
                <CardField name="syncActive" type={SystemFieldType.Boolean} label="Sincronizzazione attiva" />
            </CardGroup>
        </PageContainer>
    );
});