import React, { forwardRef } from "react";
import { SystemFieldType,SystemOperation,SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import PageContainer, {PageContainerBase } from "../../../Common/Template/PageContainer";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";

export interface IAdminDataSecurityStatusChangePanelProps {
    fromCode: string;
    entityName: string;
}

export const AdminDataSecurityStatusChangePanel = forwardRef<PageContainerBase, React.PropsWithChildren<IAdminDataSecurityStatusChangePanelProps>>((props, ref) => {

    const OnOpenPage = (record: any, operationType: SystemOperation) : any => {
        record.entityName = props.entityName;
        record.fromCode = props.fromCode;
        return record;
    }

    return (
        <PageContainer pageType={SystemPageType.CardPanel} endpoint="/odata/datasecurity/status/change" title={"DS - Creazione cambio stato"} ref={ref} OnOpenPage={OnOpenPage}>
            <CardGroup name="general" label="Generale" isOpen>
                <CardField name="toCode" label="A stato" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint={"odata/datasecurity/status?$filter=entityName eq '" + props.entityName  + "' and code ne '" + props.fromCode + "'"} tableRelationKey="code" tableRelationField="code" />
            </CardGroup>
        </PageContainer>
    );
});