import React, { useState} from "react";
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, TextField } from "@fluentui/react";

export interface IDialogProps {
    hidden:boolean;
    title:string;
    subText:string;
    txtLabel:string;
    errorMessage?:string;
    customName?:boolean;
    ext:string;
    saveInProgress:boolean;
    onSuccess: (file:File, customName?: string)=>void;
    onDismiss: ()=>void;
}

export const CommonUploadFileDialog : React.FC<IDialogProps> = (props:IDialogProps) => {
    const [value, setValue] = useState<File>(new File([""], ""));
    const [customName, setCustomName] = useState<string>("");

    return (
        <>
            <Dialog
                hidden={!props.hidden}
                onDismiss={props.onDismiss}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title:props.title,
                    subText:props.subText
                }}
            >
                <TextField label={props.txtLabel} placeholder="Clicca per caricare un file" iconProps={{
                    iconName: "Upload",
                    styles: {
                        root: {
                            cursor: 'pointer'
                        }
                    }
                }}
                onClick={() => {
                    let input = document.createElement('input');
                    input.type = 'file';
                    input.accept=props.ext;
                    input.onchange = _this => {
                        setValue(input.files![0]);
                    };
                    input.click();
                }}
                value={value.name!} 
                disabled={props.saveInProgress}  />
                <TextField 
                    label={"Nome file"} 
                    placeholder="Imposta nome file" 
                    value={customName} 
                    onChange={(ev, newValue) => {
                        setCustomName(newValue!)
                    }}
                    disabled={props.saveInProgress}
                    styles={{
                        root:{
                            display: props.customName ? 'block' : 'none'
                        }
                    }}
                />
                <DialogFooter>
                <PrimaryButton onClick={()=>{
                        props.onSuccess(value!, customName);
                        setValue(new File([""], ""));
                        setCustomName("");
                    }} 
                    text="Carica"
                    disabled={props.saveInProgress} 
                />
                <DefaultButton onClick={props.onDismiss} text="Cancella" disabled={props.saveInProgress}  />
                </DialogFooter>
            </Dialog>
        </>
    );
}