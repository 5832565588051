import React, { forwardRef} from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import { CardField } from "../../../Common/Template/Card/CardField";

export const AdminCrmStatusCodeCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda causali squalifica"} endpoint="odata/crm/status/code" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="entityName" type={SystemFieldType.String} label="Entità" hasTableRelation tableRelationEndpoint="odata/system/entity" tableRelationKey="name" tableRelationField="name" />
                <CardField name="code" type={SystemFieldType.String} label="Codice" />
                <CardField name="description" type={SystemFieldType.String} label="Descrizione"/>
                <CardField name="crmId" type={SystemFieldType.Number} label="Id CRM"/>
            </CardGroup> 
        </PageContainer>
    );
});
