import { mergeStyles, Stack } from "@fluentui/react";
import { AxiosInstance } from "axios";
import React, { createRef } from "react";
import YouTube from "react-youtube";
import { ShowcaseEntryItem } from "../ShowcaseEntryItem/ShowcaseEntryItem";
import Plyr, { APITypes } from 'plyr-react'
import "plyr-react/plyr.css"
import './VideoShowcase.css';
import { ShowcaseEntry } from "../../../Model/DemoModels";
import { SessionStateContext } from "../../../Core/State/SessionStateContext";
import { DemoUIHelper } from "../DemoUIHelper";
import { CommonFunctions } from "../../../ApplicationCode/Common/CommonFunctions";
import ReactPlayer from "react-player";

const mainContainerStyles = mergeStyles([{
    width: '100%'
}]);

const entryListContainerStyles = mergeStyles([{
    width: '40%'
}]);

const videoContainerStyles = mergeStyles([{
    width: '60%',
    height: '100%',
    minHeight: '520px',
}]);

export interface IVideoShowcaseProps {
    entries: ShowcaseEntry[];
    currentEntry: ShowcaseEntry;
    listElementId: string;
    videoElementId: string;
}

export class ShowcaseComponent extends React.Component<IVideoShowcaseProps, IVideoShowcaseProps> {

    static contextType = SessionStateContext;
    player: React.RefObject<APITypes>;

    constructor(props: IVideoShowcaseProps)
    {
        super(props);

        let firstEntry = props.entries.filter(e => e.startAsDefault)[0];
        this.state = {
            entries: props.entries,
            currentEntry: firstEntry,
            listElementId: props.listElementId,
            videoElementId: props.videoElementId
        };

        this.player = createRef();
    }

    onSelectEntry = async (item: ShowcaseEntry) => {
        this.setState({
            currentEntry: item
        });
    };

    shouldComponentUpdate = (nextProps: Readonly<IVideoShowcaseProps>, nextState: Readonly<IVideoShowcaseProps>, nextContext: any) => {
        if (nextState.currentEntry.id != this.state.currentEntry.id)
            return true;
        else if (nextState.entries.length != this.props.entries.length)
            return true;
        else
            return false;
    }

    onLoadVideo = (id: string) => {
        /*console.log(id);
        let videos = document.getElementsByTagName('video');
        for(let i = 0; i < videos.length; i++)
            videos[i].setAttribute("poster", "https://www.instantlove.it/wp-content/uploads/2020/12/sosmart-branding-evidenza.jpg");*/
        //this.player.current?.plyr.source?.poster 
    };

    render() {
        let displayDuration:number = 0;
    
        let itemList: JSX.Element[] = [];
        this.props.entries.forEach(element => {
            itemList.push(<ShowcaseEntryItem isActive={(element == this.state.currentEntry)} item={element} onClick={this.onSelectEntry}></ShowcaseEntryItem>)
        });
        

        if (this.state.currentEntry != undefined)
        {
            let source = DemoUIHelper.getVideoSource(this.state.currentEntry.videoId, this.state.currentEntry.name);
            let options: Plyr.Options = {
                clickToPlay: true
            };

            return (
                <Stack horizontal className={mainContainerStyles}>
                    <Stack.Item align="start" className={entryListContainerStyles} id={this.props.listElementId}>
                        {itemList}
                    </Stack.Item>
                    <Stack.Item align="start" className={"videoContainerStyles"} verticalFill={true} id={this.props.videoElementId}>
                        <Stack.Item id={this.props.currentEntry.id}>
                            <ReactPlayer
                            width={"100%"} 
                            height={"500px"} 
                            controls={true}
                            pip={true}
                            playing={true}
                            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                            light={source.poster} 
                            url={this.state.currentEntry.videoId}
                            onProgress={(state) => {displayDuration = Math.round(state.playedSeconds)}}
                            onPlay={()=>{CommonFunctions.logEvent("Inizio video: "+this.state.currentEntry.name+ " - Secondi visti: "+displayDuration,this.state.currentEntry.videoId)}}
                            onPause={()=>{CommonFunctions.logEvent("Fine video: "+this.state.currentEntry.name+" - Secondi visti: "+displayDuration,this.state.currentEntry.videoId)}} 
                            />
                        </Stack.Item>
                    </Stack.Item>
                </Stack>
            );
        }
        else
            return <></>;
    }
};