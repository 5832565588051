import { useRef } from "react";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { ToastService } from "../../../../Core/Toast/ToastService";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminUserRefreshTokenCard } from "../AdminUserRefreshTokenCard/AdminRefreshTokenEntryCard";

export const AdminUserRefreshTokenList : React.FC = () => {
    const cardRef = useRef<PageContainerBase>(null);
    const adminService = new AdminService();
    
    return (
        <>
            <PageContainer endpoint="/odata/user/refresh/token?$orderby= userId desc" pageType={SystemPageType.List} title="User Refresh Token" backAllowed updateAllowed deleteAllowed cardRef={cardRef}>
                <PageDefinitionContainer type={ContainerType.Action}>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Nome Utente" fieldName="userName" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="App" fieldName="appTitle" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id Utente" fieldName="userId" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Id App" fieldName="appId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Accesso Revocato" fieldName="revokedAccess" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminUserRefreshTokenCard ref={cardRef} />
        </>
    );
}