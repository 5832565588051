import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminSystemEventPublisherCard } from "./AdminSystemEventPublisherCard";

export const AdminSystemEventPublisherList : React.FC = () => {
    
    const navigate = useNavigate();
    const cardRef = useRef<PageContainerBase>(null);
    
    return(
        <>
            <PageContainer endpoint="/odata/system/event/publisher" pageType={SystemPageType.List} title="Lista System Event Publisher" cardRef={cardRef} backAllowed showAllowed>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="ViewType" label="Type" iconName="GroupList" onClick={() => {
                            navigate("/app/admin/systemevent/type");
                        }} />
                        <ActionEntry name="ViewSubscriber" label="Subcriber" runOnRec iconName="Subscribe" onClick={(rec: any) => {
                            navigate("/app/admin/systemevent/subscriber?eventName=" + rec.name);
                        }} />
                        <ActionEntry name="ViewSetup" label="Setup" runOnRec iconName="Settings" onClick={(rec: any) => {
                            navigate("/app/admin/systemevent/setup?eventName=" + rec.name);
                        }} />
                        <ActionEntry name="ViewParameter" runOnRec label="Parametri" iconName="Parameter" onClick={(rec: any) => {
                            navigate("/app/admin/systemevent/parameter?eventName=" + rec.name);
                        }} />
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Nome" fieldName="name" minWidth={300} maxWidth={400} />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={400} maxWidth={500} />
                    <ListHeaderEntry name="Tipo" fieldName="type" minWidth={250} maxWidth={300} onRender={(item: any, index, column) => {
                        if (item.type === 0)
                            return "Tabella"
                        else if (item.type === 1)
                            return "Integrazione"
                        }} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminSystemEventPublisherCard ref={cardRef} />
        </>
    );
}