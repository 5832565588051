import React, {useEffect, useRef, useState} from "react";
import { SystemActionCategory,SystemDataLoadingStatus,SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminCrmSourceCard } from "../AdminCrmSourceCard/AdminCrmSourceCard";


export const AdminCrmSourceList : React.FC = () => {
    const cardRef = useRef<PageContainerBase>(null);

        return (
            <>
                <PageContainer endpoint={'/odata/crm/source'} pageType={SystemPageType.List} title={"Lista sorgenti CRM"} cardRef={cardRef} backAllowed insertAllowed updateAllowed deleteAllowed>               
                    <PageDefinitionContainer type={ContainerType.Action}>
                    </PageDefinitionContainer>
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry name="Codice" fieldName="code" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name="Id Referenza CRM" fieldName="crmReferenceId" minWidth={100} maxWidth={200} />
                    </PageDefinitionContainer>
                </PageContainer>
                <AdminCrmSourceCard ref={cardRef} />
            </>
        );
}