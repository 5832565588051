import React, { useRef, useState } from "react";
import { SystemActionCategory, SystemDataLoadingStatus, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { Loader } from "../../../Common/Loader/Loader";
import { AdminBusinessCentralCompanyCard } from "../AdminBusinessCentralCompanyCard/AdminBusinessCentralCompanyCard";

export const AdminCustomerBusinessCentralCompanyList : React.FC = () => {
    const [loaderMessage,setLoaderMessage] = useState<string>("");
    const [customerId,setCustomerId] = useState<string>(HttpHelper.getParameter("customerId"))
    const [envName,setEnvName] = useState<string>(HttpHelper.getParameter("envName"))
    const [dataStatus,setDataStatus] = useState(SystemDataLoadingStatus.Loaded);
    const cardRef = useRef<PageContainerBase>(null);
    const adminService = new AdminService();
    let listTitle = "Lista società Business Central";

    const buildListEndpoint = () : string =>{
        let endpoint = "/odata/businesscentral/company";
        
        if ((customerId !== null && customerId !== "") && (envName !== null && envName !== ""))
            endpoint += "?$filter=customerId eq "+customerId+" and environmentName eq '"+envName+"'";
        
        return endpoint;
    }

    const syncDSYDataFromBcCompany = (customerId:number,environmentName:string,companyId:string)=>{
        setLoaderMessage("Aggiornamento DSY in corso ...");
        setDataStatus(SystemDataLoadingStatus.Loading);
        adminService.syncDSYFromBcCompany(customerId,environmentName,companyId).then((resp)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Success,"Aggiornamento DSY completata con successo.");
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
             ToastService.showMessage(MessageType.Error,"Errore nell'aggiornamento delle app: "+jqXHR.message);
        });
    }
    
    if(dataStatus === SystemDataLoadingStatus.ToLoad)
        return <Loader text={loaderMessage}/>

    return (
        <>
            <PageContainer endpoint={buildListEndpoint()} pageType={SystemPageType.List} title={listTitle} cardRef={cardRef} backAllowed showAllowed updateAllowed>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Process}>
                        <ActionEntry iconName="Sync" label="Aggiorna DSY" name="SyncDSYDataFromBcComapny" runOnRec onClick={(rec:any)=>{
                            syncDSYDataFromBcCompany(rec.customerId,rec.environmentName,rec.id)
                        }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Nome" fieldName="name" minWidth={50} maxWidth={50} />
                    <ListHeaderEntry name="Id" fieldName="id" minWidth={90} maxWidth={90} />
                    <ListHeaderEntry name="Nr. Versione" fieldName="versionNo" minWidth={90} maxWidth={90} />
                    <ListHeaderEntry name="Aggiornamento disabilitato" fieldName="disableUpdate" minWidth={100} maxWidth={100} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminBusinessCentralCompanyCard ref={cardRef} />
        </>
    );
}