import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { SystemActionCategory, SystemOperation, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminNotificationGroupMemberCard } from "../AdminNotificationGroupMemberCard/AdminNotificationGroupMemberCard";
import { AdminWizardHeaderCard } from "./AdminWizardHeaderCard";
import { AdminWizardLineCard } from "./AdminWizardLineCard";
import { AdminWizardMappingCard } from "./AdminWizardMappingCard";

export const AdminWizardMappingList : React.FC = () => {
    const wizardCode = HttpHelper.getParameter("wizardCode");
    const cardRef = useRef<PageContainerBase>(null);
    const navigate = useNavigate();

    return (
        <>
            <PageContainer endpoint={"/odata/ui/wizard/mapping?$filter=wizardCode eq '" + wizardCode + "'"} pageType={SystemPageType.List} title={"Mapping wizard " + wizardCode} cardRef={cardRef} backAllowed insertAllowed updateAllowed deleteAllowed onNewRecord={() => {
                cardRef.current?.setRecord({
                    wizardCode: wizardCode
                });
                cardRef.current?.openPage(SystemOperation.Insert);
            }}>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Endpoint" fieldName="endpoint" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Azione completamento" fieldName="completeAction" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Attivo" fieldName="active" minWidth={100} maxWidth={200} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminWizardMappingCard ref={cardRef} />
        </>
    );
}