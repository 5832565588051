import React, { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../Model/SystemModels";
import PageContainer, { PageContainerBase } from "../../Common/Template/PageContainer";
import { CardField } from "../../Common/Template/Card/CardField";
import { CardGroup } from "../../Common/Template/Card/CardGroup";
import { t } from "i18next";

export const PartnerLeadCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} endpoint="/odata/lead" title={t('partnerCenter:partnerLeadCard:title')!} ref={ref}>
            <CardGroup name="general" label={t('partnerCenter:partnerLeadCard:groupGeneral')!} isOpen={true}>
                <CardField name="companyName" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:companyName')!}/>
                <CardField name="email" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:email')!}/>
                <CardField name="firstname" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:firstname')!} />
                <CardField name="lastname" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:lastname')!} />
                <CardField name="phone" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:phone')!} />
                <CardField name="statusCode" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:statusCode')!} hasTableRelation tableRelationEndpoint="/odata/datasecurity/status" tableRelationFilter={"?$filter=entityName eq 'Lead'"} tableRelationKey="code" tableRelationField="description" readonly />
            </CardGroup>
            <CardGroup name="principalQualification" label={t('partnerCenter:partnerLeadCard:groupPrimaryQualification')!} isOpen={true}>
                {/* <CardField name="salesHeaderTemplateId" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="odata/salesheadertemplate" tableRelationFilter={"?$filter=Source eq 'Industry'"} tableRelationKey="id" tableRelationField="description" label={t('partnerCenter:partnerLeadCard:fields:salesHeaderTemplateId')!} /> */}
                <CardField name="full_Users_No" type={SystemFieldType.Number} label={t('partnerCenter:partnerLeadCard:fields:fullUsersNo')!} />
                <CardField name="limited_User_No" type={SystemFieldType.Number} label={t('partnerCenter:partnerLeadCard:fields:limitedUsersNo')!} />
                <CardField name="noOfCompany" type={SystemFieldType.Number} label={t('partnerCenter:partnerLeadCard:fields:noOfCompany')!} />
                <CardField name="budget" type={SystemFieldType.Number} label={t('partnerCenter:partnerLeadCard:fields:budget')!} readonly />
                <CardField name="registerRequest" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:registerRequest')!} readonly />
            </CardGroup>
            <CardGroup name="surveyQualification" label={t('partnerCenter:partnerLeadCard:groupSurveyQualification')!} isOpen={true}>
                <CardField name="atecoCode" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:atecoCode')!} readonly />
                <CardField name="atecoDescription" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:atecoDescription')!} readonly />
                <CardField name="companyStartDate" type={SystemFieldType.Date} label={t("adminCenter:leadCard:companyStartDate")} readonly />
                <CardField name="companyRegistrationDate" type={SystemFieldType.Date} label={t("adminCenter:leadCard:companyRegistrationDate")} readonly />
                <CardField name="companyEndDate" type={SystemFieldType.Date} label={t("adminCenter:leadCard:companyEndDate")} readonly />
                <CardField name="balanceYear" type={SystemFieldType.Number} label={t("adminCenter:leadCard:balanceYear")} readonly />
                <CardField name="netWorth" type={SystemFieldType.Number} label={t("adminCenter:leadCard:netWorth")} readonly />
                <CardField name="shareCapital" type={SystemFieldType.Number} label={t("adminCenter:leadCard:shareCapital")} readonly />
                <CardField name="totalStaffCost" type={SystemFieldType.Number} label={t("adminCenter:leadCard:totalStaffCost")} readonly />
                <CardField name="totalAssets" type={SystemFieldType.Number} label={t("adminCenter:leadCard:totalAssets")} readonly />
                <CardField name="avgGrossSalary" type={SystemFieldType.Number} label={t("adminCenter:leadCard:avgGrossSalary")} readonly />
                <CardField name="noOfEmployees" type={SystemFieldType.Number} label={t('partnerCenter:partnerLeadCard:fields:noOfEmployees')!} />
                <CardField name="currentERPName" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:currentERPName')!} />
                <CardField name="decisionDate" type={SystemFieldType.Date} label={t('partnerCenter:partnerLeadCard:fields:decisionDate')!} />
                <CardField name="expectedLiveDate" type={SystemFieldType.Date} label={t('partnerCenter:partnerLeadCard:fields:expectedLiveDate')!} />
                <CardField name="monthlyBudget" type={SystemFieldType.Number} label={t('partnerCenter:partnerLeadCard:fields:monthlyBudget')!} />
                <CardField name="note" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:note')!} />
                <CardField name="isAlreadyCustomer" type={SystemFieldType.Boolean} label={t('partnerCenter:partnerLeadCard:fields:isAlreadyCustomer')!} />
                <CardField name="activeMicrosoftServices" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:activeMicrosoftServices')!} />
            </CardGroup>
            <CardGroup name="bookInternalMeeting" label={t('partnerCenter:partnerLeadCard:groupInternalMeeting')!} isOpen={true}>
                <CardField name="internalMeetingDate" type={SystemFieldType.Date} label={t('partnerCenter:partnerLeadCard:fields:internalMeetingDate')!} readonly />
            </CardGroup>
            <CardGroup name="bookCustomerDemo" label={t('partnerCenter:partnerLeadCard:groupCustomerDemo')!} isOpen={true}>
                <CardField name="demoDate" type={SystemFieldType.Date} label={t('partnerCenter:partnerLeadCard:fields:demoDate')!} readonly />
            </CardGroup>
            <CardGroup name="personalData" label={t('partnerCenter:partnerLeadCard:personalData')!} isOpen={true}>
                <CardField name="address" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:address')!} />
                <CardField name="zipCode" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:zipCode')!} />
                <CardField name="city" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:city')!} />
                <CardField name="county" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:county')!} />
                <CardField name="country" type={SystemFieldType.String} label={t('partnerCenter:partnerLeadCard:fields:country')!} />
            </CardGroup>
        </PageContainer>    
    );
});