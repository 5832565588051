import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { t } from "i18next";

export const AdminLossRiskLineCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={t("adminCenter:lossRiskLineCard:title")!} endpoint="odata/crm/lossriskline" ref={ref}>
            <CardGroup name="general" label="General" isOpen={true}>
                <CardField name="lossCauseCode" type={SystemFieldType.String} label={t("adminCenter:lossRiskLineCard:fields:lossCauseDescription")!} hasTableRelation tableRelationEndpoint="odata/crm/losscause" tableRelationKey="code" tableRelationField="description" />
                <CardField name="description" type={SystemFieldType.String} label={t("adminCenter:lossRiskLineCard:fields:description")!} />
                <CardField name="notes" type={SystemFieldType.String} label={t("adminCenter:lossRiskLineCard:fields:notes")!} />
                <CardField name="processModule" type={SystemFieldType.String} label={t("adminCenter:lossRiskLineCard:fields:processModule")!} />
                <CardField name="processCode" type={SystemFieldType.String} label={t("adminCenter:lossRiskLineCard:fields:processCode")!} />
                <CardField name="lineScore" type={SystemFieldType.Number} label={t("adminCenter:lossRiskLineCard:fields:lineScore")!} />
                <CardField name="handled" type={SystemFieldType.Boolean} label={t("adminCenter:lossRiskLineCard:fields:handled")!} />
                <CardField name="handleDate" type={SystemFieldType.Date} label={t("adminCenter:lossRiskLineCard:fields:handleDate")!} />
                <CardField name="sourceTable" type={SystemFieldType.String} label={t("adminCenter:lossRiskLineCard:fields:sourceTable")!} readonly />
                <CardField name="sourceId" type={SystemFieldType.Number} label={t("adminCenter:lossRiskLineCard:fields:sourceId")!} readonly />
                <CardField name="sourceExternalId" type={SystemFieldType.Number} label={t("adminCenter:lossRiskLineCard:fields:sourceExternalId")!} readonly />
                <CardField name="entryNo" type={SystemFieldType.Number} label={t("adminCenter:lossRiskLineCard:fields:entryNo")!} readonly />
            </CardGroup> 
        </PageContainer>
    );
});