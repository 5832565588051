import { useRef } from "react";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { SystemPageType } from "../../../Model/SystemModels";
import { ListHeaderEntry } from "../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../Common/Template/PageDefinitionContainer";
import { AdminCustomerProgressChecklistCard } from "../Administrative/AdminCustomerProgressChecklistCard/AdminCustomerProgressChecklistCard";

export const AdminTaskSetupInProgressList : React.FC = () => {
    
    const groupCode = HttpHelper.getParameter('groupCode');
    const cardRef = useRef<PageContainerBase>(null);
    
    return (
        <>
            <PageContainer endpoint={"/odata/customer/progress/checklist?$filter=groupCode eq '" + groupCode + "' and completed eq false and enabled eq true"} pageType={SystemPageType.List} title={"Lista Task Setup In Corso"} backAllowed updateAllowed deleteAllowed cardRef={cardRef}>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>       
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Id Cliente" fieldName="customerId" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Cliente" fieldName="customerCompanyName" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Codice" fieldName="code" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Stato" fieldName="status" minWidth={150} maxWidth={250} onRender={(item: any, index, column) => {
                        if (item.status === 0)
                        return "In attesa"
                        else if (item.status === 1)
                        return "In progress"
                        else if (item.status === 2)
                        return "Completato"
                    }} />
                    <ListHeaderEntry name="Tipo Utente" fieldName="forUserType" minWidth={150} maxWidth={250} onRender={(item: any, index, column) => {
                        if (item.forUserType === 0)
                        return "Utente Admin"
                        else if (item.forUserType === 1)
                        return "Utente Normale"
                        else if (item.forUserType === 2)
                        return "Entrambi i tipi"
                    }} />
                    <ListHeaderEntry name="Tipo Azione" fieldName="actionType" minWidth={150} maxWidth={250} onRender={(item: any, index, column) => {
                        if (item.actionType === 0)
                        return "Apertura Link"
                        else if (item.actionType === 1)
                        return "Apertura Pagina"
                    }} />
                    <ListHeaderEntry name="Id Tenant" fieldName="tenantId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Data Inizio" fieldName="startDateTime" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Priorità" fieldName="priority" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Link Esterno" fieldName="externalLink" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Endpoint Pagina" fieldName="pageEndpoint" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Descrizione Dettagliata" fieldName="detailedDescription" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Testo Bottone Principale" fieldName="primaryButtonText" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Mostra come Task" fieldName="showAsTask" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Mostra Bottone Task successivo" fieldName="showNextButton" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Testo Bottone Task successivo" fieldName="nextButtonText" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Titolo Attesa" fieldName="waitingTitle" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Messaggio Attesa" fieldName="waitingMessage" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Codice Gruppo" fieldName="groupCode" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminCustomerProgressChecklistCard ref={cardRef} />
        </>
    );
}