import { useEffect, useRef, useState } from "react";
import { HttpHelper } from "../../../../../Core/Http/HttpHelper";
import { SystemDataLoadingStatus, SystemPageType } from "../../../../../Model/SystemModels";
import { Loader } from "../../../../Common/Loader/Loader";
import { ListHeaderEntry } from "../../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../../Common/Template/PageDefinitionContainer";
import { AdminAzureGDAPCustomerRelationshipRoleDetailCard } from "../AdminAzureGDAPCustomerRelationshipRoleDetailCard/AdminAzureGDAPCustomerRelationshipRoleDetailCard";

export const AdminAzureGDAPCustomerRelationshipRoleDetailList : React.FC = () => {
    
    const cardRef = useRef<PageContainerBase>(null);
    const [dataStatus,setDataStatus]= useState(SystemDataLoadingStatus.ToLoad);
    const [endpoint, setEndpoint] = useState('');
    const parameter = HttpHelper.getParameter('entryNo');

    useEffect(()=>{
        if(parameter!=null)
            setEndpoint("/odata/azure/gdap/customer/relationship/role/detail?$filter=headerId eq "+parameter);
        else{
            setEndpoint("/odata/azure/gdap/customer/relationship/role/detail");
        }
        setDataStatus(SystemDataLoadingStatus.Loaded);
    },[dataStatus]);
    

    if (dataStatus!==SystemDataLoadingStatus.Loaded)
        return <Loader text="Stiamo caricando i dati richiesti..."></Loader>;
    else
        return (
            <>
                <PageContainer endpoint={endpoint} pageType={SystemPageType.List} title={"Lista dettaglio ruoli relazioni cliente azure GDAP"} cardRef={cardRef} backAllowed showAllowed updateAllowed>
                    <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry fieldName="headerId" name="Id testata" minWidth={100} maxWidth={900} />
                        <ListHeaderEntry fieldName="customerId" name="Id cliente" minWidth={150} maxWidth={900} />
                        <ListHeaderEntry fieldName="customerName" name="Cliente" minWidth={400} maxWidth={900} />
                        <ListHeaderEntry fieldName="roleCode" name="Codice ruolo" minWidth={200} maxWidth={900} />
                        <ListHeaderEntry fieldName="roleName" name="Nome ruolo" minWidth={300} maxWidth={900} />
                        <ListHeaderEntry fieldName="active" name="Attivo" minWidth={100} maxWidth={900} />
                        <ListHeaderEntry fieldName="relationshipId" name="Id relazione" minWidth={450} maxWidth={900} />
                    </PageDefinitionContainer>
                </PageContainer>
                <AdminAzureGDAPCustomerRelationshipRoleDetailCard ref={cardRef} />
            </>
        );
}