import { Icon, Label, mergeStyles, Stack } from "@fluentui/react";
import { ShowcaseEntry } from "../../../Model/DemoModels";
import './ShowcaseEntryItem.css';

export interface IShowcaseEntryItemProps {
    item: ShowcaseEntry;
    isActive: boolean;
    onClick: (item: ShowcaseEntry) => void;
}

export const ShowcaseEntryItem = (props: IShowcaseEntryItemProps) => {

    let itemContainerClass = "item-container";
    if (props.isActive)
        itemContainerClass += " active";

    return (
        <Stack horizontal className={itemContainerClass} key={props.item.id} onClick={() => props.onClick(props.item)}>
            <Stack.Item className="item-icon-container">
                <Icon className="item-icon" iconName={props.item.iconName}></Icon>
            </Stack.Item>
            <Stack.Item className="item-information-container">
                <Stack>
                    <Stack.Item className="item-name">
                        <Label>{props.item.name}</Label>
                    </Stack.Item>
                    <Stack.Item className="item-description">
                        <small>{props.item.description}</small>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    );

};

