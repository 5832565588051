import { useRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import { AdminCustomerQueuedOperationCard } from "../AdminCustomerQueuedOperationCard/AdminCustomerQueuedOperationCard";
import { IColumn } from "@fluentui/react";

export interface CustomerQueuedOperationOptions {
    openBy: "customer" | "environment";
    environmentName?: string;
    customerId?: number;
    customerName?: string;
}

export interface CustomerQueuedOperationStructure {
    displayName: string;
    fieldName: string;
    fieldType: SystemFieldType;
    render?: (item?: any, index?: number | undefined, column?: IColumn | undefined) => any;
    minWidth: number;
    maxWidth: number;
}

const structure: CustomerQueuedOperationStructure[] = [
    {
        displayName: "Nome ambiente",
        fieldName: "environmentName",
        fieldType: SystemFieldType.String,
        minWidth: 200,
        maxWidth: 250
    },
    {
        displayName: "Descrizione",
        fieldName: "description",
        fieldType: SystemFieldType.String,
        minWidth: 350,
        maxWidth: 400
    },
    {
        displayName: "ID Referenza",
        fieldName: "referenceId",
        fieldType: SystemFieldType.String,
        minWidth: 250,
        maxWidth: 300
    },
    {
        displayName: "Stato",
        fieldName: "status",
        fieldType: SystemFieldType.String,
        render: (item, index, column) => {
            switch(item.status){
                case 0:
                    return <span style={{color: "gray"}}>Queued</span>;
                case 1:
                    return <span style={{color: "#F0DE36"}}>In progress</span>;
                case 2:
                    return <span style={{color: "#5B9A8B"}}>Completed</span>;
                case 3:
                    return <span style={{color: "red"}}>Error</span>;
                case 4:
                    return <span style={{color: "#CEE6F3"}}>Skipped</span>;
            }
        },
        minWidth: 80,
        maxWidth: 100
    },
    {
        displayName: "Durata Microsoft",
        fieldName: "microsoftDuration",
        fieldType: SystemFieldType.Number,
        minWidth: 120,
        maxWidth: 200
    },
    {
        displayName: "Durata",
        fieldName: "duration",
        fieldType: SystemFieldType.Number,
        minWidth: 70,
        maxWidth: 150
    },
    {
        displayName: "Creato da",
        fieldName: "createdBy",
        fieldType: SystemFieldType.String,
        minWidth: 200,
        maxWidth: 250
    },
    {
        displayName: "Creato il",
        fieldName: "createdAt",
        fieldType: SystemFieldType.Date,
        minWidth: 150,
        maxWidth: 200
    },
    {
        displayName: "Iniziato il",
        fieldName: "startedAt",
        fieldType: SystemFieldType.Date,
        minWidth: 150,
        maxWidth: 200
    },
    {
        displayName: "Iniziato il (Microsoft)",
        fieldName: "microsoftStartedAt",
        fieldType: SystemFieldType.Date,
        minWidth: 150,
        maxWidth: 200
    },
    {
        displayName: "Completato il",
        fieldName: "completedAt",
        fieldType: SystemFieldType.Date,
        minWidth: 150,
        maxWidth: 200
    },
    {
        displayName: "Messaggio di errore",
        fieldName: "errorMessage",
        fieldType: SystemFieldType.String,
        minWidth: 600,
        maxWidth: 700
    },
]

export const AdminCustomerQueuedOperationList: React.FC = (props) => {
    const pageParams: CustomerQueuedOperationOptions = JSON.parse(atob(HttpHelper.getParameter('pageParams')));
    const cardRef = useRef<PageContainerBase>(null); 
    
    function getEndpoint(): string {

        let baseEndpoint = "/odata/customer/queuedoperation?$orderby=createdAt desc";
        if(pageParams.openBy === "customer")
            baseEndpoint += "&$filter=customerId eq " + pageParams.customerId;
        else if(pageParams.openBy === "environment")
            baseEndpoint += "&$filter=customerId eq " + pageParams.customerId + " and environmentName eq '" + pageParams.environmentName + "'";
        
        return baseEndpoint;
    }

    function getStructure(): JSX.Element[] {
        let currentStructure: JSX.Element[] = [];

        structure.forEach((element: CustomerQueuedOperationStructure) => {
            if(element.fieldName === "environmentName"){
                if(pageParams.openBy === "customer")
                    currentStructure.push(
                        <ListHeaderEntry 
                            key={element.fieldName} 
                            name={element.displayName} 
                            fieldName={element.fieldName} 
                            minWidth={element.minWidth} 
                            maxWidth={element.maxWidth}
                            onRender={element.render}
                        />
                    );
            }
            else
                currentStructure.push(
                    <ListHeaderEntry 
                        key={element.fieldName} 
                        name={element.displayName} 
                        fieldName={element.fieldName}
                        minWidth={element.minWidth} 
                        maxWidth={element.maxWidth}
                        onRender={element.render}
                    />
                );
        });

        return currentStructure;
    }

    return (
        <>
            <PageContainer
                cardRef={cardRef}
                endpoint={getEndpoint()}
                pageType={SystemPageType.List}
                title={"Coda operazioni: " + pageParams.customerName}
                backAllowed
                showAllowed
                deleteAllowed
            >
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    {getStructure()}
                </PageDefinitionContainer>
            </PageContainer>
            <AdminCustomerQueuedOperationCard
                ref={cardRef}
                displayTitle={pageParams.customerName!}
                openBy={pageParams.openBy}
                structure={structure}
            />
        </>
    );
};