import React, { useRef }  from "react";
import { SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminProspectCard } from "../AdminProspectCard/AdminProspectCard";

export const AdminProspectList : React.FC = () => {
    
    const cardRef = useRef<PageContainerBase>(null);

    return (
        <>
            <PageContainer endpoint="/odata/prospect" pageType={SystemPageType.List} title="Lista Prospect" cardRef={cardRef} backAllowed showAllowed insertAllowed updateAllowed deleteAllowed>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Codice" fieldName="code" minWidth={350} maxWidth={500} />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={350} maxWidth={500} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminProspectCard ref={cardRef} />
        </>
    );
}