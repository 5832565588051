import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { t } from "i18next";

export const AdminLeadIndustryLineCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={t("adminCenter:leadIndustryCard:title")!} endpoint="odata/lead/industry" ref={ref}>
            <CardGroup name="general" label={t("adminCenter:leadIndustryCard:general")!} isOpen={true}>
                <CardField name="salesChannel" type={SystemFieldType.String} label={t("adminCenter:leadIndustryCard:salesChannel")!}/>
                <CardField name="description" type={SystemFieldType.String} label={t("adminCenter:leadIndustryCard:titldescriptione")!} />
                <CardField name="typologyId" type={SystemFieldType.Option} label={t("adminCenter:leadIndustryCard:typologyId")!} 
                    cmbOptions={[
                        {
                            key: 0,
                            text: t("adminCenter:leadIndustryCard:typologyId0")!
                        },
                        {
                            key: 1,
                            text: t("adminCenter:leadIndustryCard:typologyId1")!,
                            
                        },
                        {
                            key: 2,
                            text: t("adminCenter:leadIndustryCard:typologyId2")!,
                            
                        },
                        {
                            key: 3,
                            text: t("adminCenter:leadIndustryCard:typologyId3")!
                        },
                        {
                            key: 4,
                            text: t("adminCenter:leadIndustryCard:typologyId4")!
                        }
                    ]}
                />
                <CardField name="revenue" type={SystemFieldType.Number} label={t("adminCenter:leadIndustryCard:revenue")!} />
            </CardGroup> 
        </PageContainer>
    );
});