import React, { forwardRef } from "react";
import { CommonFunctions } from "../../../ApplicationCode/Common/CommonFunctions";
import { PageMetadata, SystemFieldType, SystemOperation, SystemPageType } from "../../../Model/SystemModels";
import { CardField } from "../../Common/Template/Card/CardField";
import PageContainer, { PageContainerBase } from "../../Common/Template/PageContainer";
import { CardGroup } from "../../Common/Template/Card/CardGroup";

export interface IAdminPagePanelProps {
    title: string;
}

export const AdminPagePanel = forwardRef<PageContainerBase, React.PropsWithChildren<IAdminPagePanelProps>>((props, ref) => {
    const handleOnSuccess = (operationType: SystemOperation, record: PageMetadata, callback?: () => void) => {
        switch(operationType)
        {
            case SystemOperation.Delete:
                CommonFunctions.deletePageMetadata(record)
                    .then((resp) => {
                        callback!();
                    });
                break;
            case SystemOperation.Insert:
                record.captions = [];
                CommonFunctions.createPageMetadata(record)
                    .then((resp) => {
                        callback!();
                    });
                break;
        }
    }
    
    return (
        <PageContainer pageType={SystemPageType.CardPanel} endpoint="odata/system/page" title="Nuova pagina" ref={ref} onSuccess={handleOnSuccess}>
            <CardGroup name="general" label="Generale" isOpen>
                <CardField name="page.type" label="Tipo" type={SystemFieldType.Option} cmbOptions={[
                    {key: 0, text: 'Custom'},
                    {key: 1, text: 'List'},
                    {key: 2, text: 'Card'}
                ]} />
                <CardField name="page.appId" label="Id app" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="odata/system/app" tableRelationKey="id" tableRelationField="name" />
                <CardField name="page.name" label="Nome" type={SystemFieldType.String} />
                <CardField name="userLanguageCaption" label="Caption" type={SystemFieldType.String} />
                <CardField name="page.sourceEntityName" label="Entità sorgente" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="odata/system/entity" tableRelationKey="name" tableRelationField="name" />
                <CardField name="page.endpoint" label="Endpoint" type={SystemFieldType.String} />
                <CardField name="page.category" label="Categoria" type={SystemFieldType.Option} cmbOptions={[
                    {key: 0, text: 'None'},
                    {key: 1, text: 'Administrator'},
                    {key: 2, text: 'List'},
                    {key: 3, text: 'History'}
                ]} />
                <CardField name="page.enabled" label="Abilitato" type={SystemFieldType.Boolean} />
            </CardGroup>
        </PageContainer>
    );
});