import { Checkbox, DefaultButton, Dialog, DialogFooter, IColumn, IModalProps, mergeStyles, PrimaryButton, SelectionMode, ShimmeredDetailsList, Stack, TextField } from "@fluentui/react";
import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import { AssignedChatbotBusinessLine } from "../../../../Model/AdminModel";
import { SystemDataLoadingStatus } from "../../../../Model/SystemModels";
import { SystemCore } from "../../../../Core/System/SystemCore";
import { Loader } from "../../../Common/Loader/Loader";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";

const modalProps: Partial<IModalProps> = {
    isBlocking: true,
    dragOptions: undefined,
    styles: {
        main: {
            width: '95% !important',
            maxWidth: 'calc(95%) !important',
            position: 'absolute',
            top: 50
        }
    }
  };

const contentBodyClassName = mergeStyles([{
    width: '100%',
    maxHeight: 'calc(100vh - 200px)',
    overflowY: "auto",
    overflowX: "hidden"
}]);

const modalContentClassName = mergeStyles([{
    width: '100%'
}]);


export interface IAdminChatbotBusinesLineCardProps {
    showDialog: boolean;
    profileCode: string;
    onSuccessModal: (modifiedBusinessLine: AssignedChatbotBusinessLine[]) => void;
    onCancelModal: () => void;
}

export const AdminChatbotBusinesLineCard : React.FC<IAdminChatbotBusinesLineCardProps> = (props) => {
    const [businessLineAssigned, setAssignedBusinessLine] = useState([] as AssignedChatbotBusinessLine[]);
    const [showShimmer, setShowShimmer] = useState<boolean>(false);

    const adminService = new AdminService();
    
    const buildListColumns = (): IColumn[] => {
        return [
            {
                key: "enabled",
                fieldName: "enabled",
                name: "",
                minWidth: 50,
                maxWidth: 50,
                onRender: (item: AssignedChatbotBusinessLine | undefined, index?, column?) => {

                    return (
                        <Checkbox 
                                checked={item?.enabled}
                                onChange={(ev, checked) => {
                                    let newArray: AssignedChatbotBusinessLine[] = JSON.parse(JSON.stringify(businessLineAssigned));

                                    let index = newArray.findIndex(e => e.businessLineCode == item!.businessLineCode && e.profileCode == item!.profileCode);
                                    if (index >= 0)
                                    {
                                        item!.enabled = checked!;
                                        newArray[index] = item!;
                                        setAssignedBusinessLine(newArray);
                                    }
                                }} />
                    );
                }
            },
            {
                key: "description",
                fieldName: "description",
                name: "Business line",
                minWidth: 100,
                maxWidth: 200
            }
        ];
    };

    useEffect(() => {
        if (props.showDialog)
        {
            setShowShimmer(true);
            adminService.getCurrentAssignedBusinessLines(props.profileCode)
                .then((resp) => {
                    setAssignedBusinessLine(resp);
                    setShowShimmer(false);
                })
                .catch((err) => {
                    ToastService.showMessage(MessageType.Error, err);
                    props.onCancelModal();
                });
        }
        else
            setShowShimmer(false);
    }, [props.showDialog]);

    return (
        <Dialog hidden={! props.showDialog}
                modalProps={modalProps}
                onDismiss={props.onCancelModal}
                dialogContentProps={{
                    title: "Associa Business line a profilo " + props.profileCode
                }}>
                <Stack key="modalContent" className={modalContentClassName}>
                    <Stack.Item key="modalBodyContent" className={contentBodyClassName}>
                        <ShimmeredDetailsList items={businessLineAssigned}
                            columns={buildListColumns()}
                            selectionMode={SelectionMode.none}
                            enableShimmer={showShimmer}
                        />
                    </Stack.Item>
                    <Stack.Item key="modalFooterContent">
                        <DialogFooter>
                            <DefaultButton text={t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerLicenseCard:Dialog:defaultButtonText')!}  onClick={() => {
                                props.onCancelModal();
                            }} />
                            <PrimaryButton text={t('customerCenter:CustomerUserSetupList:CustomerUserList:CustomerLicenseCard:Dialog:primaryButtonText')!} onClick={() => {
                                setShowShimmer(true);
                                props.onSuccessModal(businessLineAssigned);
                            } } />
                        </DialogFooter>
                    </Stack.Item>
                </Stack>
        </Dialog>
    );

}