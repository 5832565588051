import { useContext, useEffect, useRef, useState } from "react";
import PageContainer, { PageContainerBase } from "../../Common/Template/PageContainer";
import { Lead, Partner } from "../../../Model/PartnerModels";
import { SystemActionCategory, SystemDataLoadingStatus, SystemOperation, SystemPageType } from "../../../Model/SystemModels";
import { useNavigate } from "react-router-dom";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { SystemCore } from "../../../Core/System/SystemCore";
import { Loader } from "../../Common/Loader/Loader";
import { ContainerType, PageDefinitionContainer } from "../../Common/Template/PageDefinitionContainer";
import { ActionArea } from "../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../Common/Template/List/ListHeaderEntry";
import { PartnerLeadCard } from "../PartnerLeadCard/PartnerLeadCard";
import { t } from "i18next";
import { IPartnerInformationContext, PartnerInformationContext } from "../../../ApplicationCode/Partner/PartnerInformationContext";

export const PartnerLeadList : React.FC = () => {
    const cardRef = useRef<PageContainerBase>(null);
    const [dataStatus, setDataStatus] = useState<SystemDataLoadingStatus>(SystemDataLoadingStatus.ToLoad);
    const [message, setMessage] = useState<string>(t('partnerCenter:partnerLeadList:loading')!);
    const [endpoint, setEndpoint] = useState<string>("");
    const [listTitle, setListTitle] = useState<string>("");
    const [partner, setPartner] = useState<Partner>(new Partner());
    let partnerStateContext: IPartnerInformationContext = useContext(PartnerInformationContext);

    const navigate = useNavigate();

    const buildListEndpoint = () =>{
        setDataStatus(SystemDataLoadingStatus.Loading);

        const workflowCode = HttpHelper.getParameter('code');
        const workflowCodeName = HttpHelper.getParameter('codeName');
        const leadFilter = HttpHelper.getParameter('leadFilter');
        const activeLeads = HttpHelper.getParameter('activeLeads');
        let buildedEndpoint = "/odata/lead";
        let currentTitle = t('partnerCenter:partnerLeadList:leadList');
        
        if (partnerStateContext.partner.id === 1) {
            if (workflowCode != null && workflowCode !== "") {
                currentTitle += " ("+workflowCodeName+")";
                buildedEndpoint += '?$filter=(email gt \'\') and businessLineCode eq \'SO-SMART\' and statusCode eq \''+workflowCode+'\'&$orderby=createdOn desc';
            }
            else if (activeLeads != null && activeLeads !== "") {
                currentTitle += t('partnerCenter:partnerLeadList:active');
                buildedEndpoint += '?$filter=(email gt \'\') and businessLineCode eq \'SO-SMART\' and statusCode le \'85\'&$orderby=createdOn desc';
            }
            else if ((leadFilter !== null) && (leadFilter !== "")) {
                switch(leadFilter) {
                    case "leadWin":
                        currentTitle += " (Win)";
                        buildedEndpoint += '?$filter=(email gt \'\') and statusCode eq \'95\' or finalOutcome eq \'Win\'&$orderby=systemCreationDate desc';
                        break;
                    case "leadLost":
                        currentTitle += " (Lost)";
                        buildedEndpoint += '?$filter=(email gt \'\') and statusCode eq \'96\' or finalOutcome eq \'Lost\'&$orderby=systemCreationDate desc';
                        break;
                    case "leadUnqualified":
                        currentTitle += " (Unqualified)";
                        buildedEndpoint += '?$filter=(email gt \'\') and statusCode eq \'97\' or finalOutcome eq \'Unqualified\'&$orderby=systemCreationDate desc';
                        break;
                }
            }
            else {
                currentTitle += t('partnerCenter:partnerLeadList:total');
                buildedEndpoint += '?filter=(email gt \'\')&$orderby=systemCreationDate desc';
            }
        }
        else {
            if (workflowCode != null && workflowCode !== "") {
                currentTitle += " ("+workflowCodeName+")";
                buildedEndpoint += '?$filter=(email gt \'\') and statusCode eq \''+workflowCode+'\' and partnerId eq '+partnerStateContext.partner.id+'&$orderby=createdOn desc';
            }
            else if (activeLeads != null && activeLeads !== "") {
                currentTitle += t('partnerCenter:partnerLeadList:active');
                buildedEndpoint += '?$filter=(email gt \'\') and partnerId eq '+partnerStateContext.partner.id+' and statusCode le \'85\'&$orderby=createdOn desc';
            }
            else {
                currentTitle += t('partnerCenter:partnerLeadList:total');
                buildedEndpoint += '?$filter=(email gt \'\') and partnerId eq '+partnerStateContext.partner.id+'&$orderby=systemCreationDate desc';
            }
        }
        
        setPartner(partnerStateContext.partner);
        setEndpoint(buildedEndpoint);
        setListTitle(currentTitle);
        setDataStatus(SystemDataLoadingStatus.Loaded);
    }
    
    useEffect(() => {
        if (dataStatus === SystemDataLoadingStatus.ToLoad) {
            buildListEndpoint();
        }
    }, [dataStatus]);
    
    const setNewRecord = () => {
        let newRecord = new Lead();
        newRecord.partnerId = partner.id;
        newRecord.marketingQualificationCarriedOut = true;
        cardRef.current?.setRecord(newRecord);
        cardRef.current?.openPage(SystemOperation.Insert);
    }
    
    const buildShopUrl = (): string => {
        if (partnerStateContext.partner.legalEntityCode === 'ESS')
            return process.env.REACT_APP_SHOP_URL+".us/";
        else
            return process.env.REACT_APP_SHOP_URL+".it/";
    }

    const weekday = [
        t('partnerCenter:partnerLeadList:weekday:sunday')!,
        t('partnerCenter:partnerLeadList:weekday:monday')!,
        t('partnerCenter:partnerLeadList:weekday:tuesday')!,
        t('partnerCenter:partnerLeadList:weekday:wednesday')!,
        t('partnerCenter:partnerLeadList:weekday:thursday')!,
        t('partnerCenter:partnerLeadList:weekday:friday')!,
        t('partnerCenter:partnerLeadList:weekday:saturday')!,
    ];
    
    if (!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={message}></Loader>;
    else
    return (
        <>
            <PageContainer onNewRecord={setNewRecord} endpoint={endpoint} pageType={SystemPageType.List} title={listTitle} cardRef={cardRef} backAllowed insertAllowed updateAllowed>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="OpenShopAddress" label={t('partnerCenter:partnerLeadList:actions:configureOffer')!} iconName="ConfigurationSolid" runOnRec isPromoted onClick={(req:any)=>{
                                window.open(buildShopUrl());
                            }}>
                        </ActionEntry>
                        <ActionEntry name="OpenBookingLeadAvailability" label={t('partnerCenter:partnerLeadList:actions:bookCustomerDemo')!} iconName="JoinOnlineMeeting" isPromoted runOnRec onClick={(req:any)=>{
                                navigate("/app/partner/bookingAvailability?source=Partner&mode=lead&leadId="+req.id+"&leadName="+req.companyName);
                            }}>
                        </ActionEntry>
                        <ActionEntry name="OpenBookingInternalAvailability" label={t('partnerCenter:partnerLeadList:actions:bookInternalMeeting')!} iconName="AddOnlineMeeting" isPromoted runOnRec onClick={(req:any)=>{
                                navigate("/app/partner/bookingAvailability?source=Partner&mode=internal&leadId="+req.id+"&leadName="+req.companyName);
                            }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadList:fields:firstName')!} fieldName="firstname" minWidth={200} maxWidth={250} />
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadList:fields:lastName')!} fieldName="lastname" minWidth={200} maxWidth={250} />
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadList:fields:companyName')!} fieldName="companyName" minWidth={200} maxWidth={250} />
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadList:fields:email')!} fieldName="email" minWidth={300} maxWidth={350} />
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadList:fields:phone')!} fieldName="phone" minWidth={150} maxWidth={200} />
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadList:fields:createdOn')!} fieldName="createdOn" minWidth={200} maxWidth={250} onRender={(item: any, index, column)=>{
                        if (item.createdOn !== undefined)
                        {
                            let convert: Date = new Date(item.createdOn);
                            let day, month, year, hours, minutes;
                            
                            if (convert.getFullYear() === 1)
                                return undefined;
                                
                            if (convert !== undefined)
                            {
                                day = convert.getDate();
                                month = convert.getMonth() + 1;
                                year = convert.getFullYear();
                                hours = ("0" + convert.getHours()).slice(-2);
                                minutes = ("0" + convert.getMinutes()).slice(-2);
                                return weekday[convert.getDay()] + " " + day + "/" + month + "/" + year + " " + hours + ":" + minutes;
                            }
                        }
                        
                        return undefined;
                    }} />
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadList:fields:internalMeetingDate')!} fieldName="internalMeetingDate" minWidth={200} maxWidth={250} onRender={(item: any, index, column)=>{
                        if (item.internalMeetingDate !== undefined)
                        {
                           let convert: Date = new Date(item.internalMeetingDate);
                           let day, month, year, hours, minutes;

                           if (convert.getFullYear() === 1)
                               return undefined;
                               
                           if (convert !== undefined)
                           {
                               day = convert.getDate();
                               month = convert.getMonth() + 1;
                               year = convert.getFullYear();
                               hours = ("0" + convert.getHours()).slice(-2);
                               minutes = ("0" + convert.getMinutes()).slice(-2);
                               return weekday[convert.getDay()] + " " + day + "/" + month + "/" + year + " " + hours + ":" + minutes;
                           }
                       }
               
                       return undefined;
                    }} />
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadList:fields:demoDate')!} fieldName="demoDate" minWidth={200} maxWidth={250} onRender={(item: any, index, column)=>{
                        if (item.demoDate !== undefined)
                        {
                           let convert: Date = new Date(item.demoDate);
                           let day, month, year, hours, minutes;

                           if (convert.getFullYear() === 1)
                               return undefined;
                               
                           if (convert !== undefined)
                           {
                               day = convert.getDate();
                               month = convert.getMonth() + 1;
                               year = convert.getFullYear();
                               hours = ("0" + convert.getHours()).slice(-2);
                               minutes = ("0" + convert.getMinutes()).slice(-2);
                               return weekday[convert.getDay()] + " " + day + "/" + month + "/" + year + " " + hours + ":" + minutes;
                           }
                       }
               
                       return undefined;
                    }} />
                </PageDefinitionContainer>
            </PageContainer>
            <PartnerLeadCard ref={cardRef} />
        </>
    );
}