import React, { useRef } from "react";
import { SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminSystemEventLogCard } from "./AdminSystemEventLogCard";
import { Lead } from "../../../../Model/PartnerModels";

export interface IAdminSystemEventLogListProps {
    lead?: Lead,
    creationDate?: string,
    isModal?: boolean
}

export const AdminSystemEventLogList : React.FC<IAdminSystemEventLogListProps> = (props) => {
    
    const cardRef = useRef<PageContainerBase>(null);

    const buildListEndpoint = () : string =>{

        if(props.lead !== undefined){
            let filterDate = new Date(props.creationDate!);
            filterDate.setDate(filterDate.getDate() + 1);

            return '/odata/system/event/log?$filter=systemCreationDate gt '+props.creationDate+'T00:00:00Z and systemCreationDate lt '+filterDate.toISOString()+' and userId eq '+props.lead.linkUserId+'&$orderby=entryNo desc'
        } 
        
        return "/odata/system/event/log?$orderby=entryNo desc";
    }

    return(
        <>
            <PageContainer endpoint={buildListEndpoint()} pageType={SystemPageType.List} title="Lista System Event Log" cardRef={cardRef} backAllowed showAllowed>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Id" fieldName="entryNo" minWidth={40} maxWidth={50} />
                    <ListHeaderEntry name="Titolo" fieldName="title" minWidth={400} maxWidth={500} />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={400} maxWidth={500} />
                    <ListHeaderEntry name="Data evento" fieldName="performedOn" minWidth={400} maxWidth={500} />
                    <ListHeaderEntry name="Durata evento" fieldName="duration" minWidth={400} maxWidth={500} />
                    <ListHeaderEntry name="Dati" fieldName="data" minWidth={400} maxWidth={500} />
                    <ListHeaderEntry name="Nome evento pubblicatore" fieldName="publisherEventName" minWidth={400} maxWidth={500} />
                    <ListHeaderEntry name="Oggetto sottoscrittore" fieldName="subscribedByObject" minWidth={400} maxWidth={500} />
                    <ListHeaderEntry name="Funzione sottoscrittrice" fieldName="subscribedByFunction" minWidth={400} maxWidth={500} />
                    <ListHeaderEntry name="Nome sottoscrittore" fieldName="subscriberName" minWidth={400} maxWidth={500} />
                    <ListHeaderEntry name="Nome entità origine" fieldName="sourceEntityName" minWidth={400} maxWidth={500} />
                    <ListHeaderEntry name="Id origine" fieldName="sourceId" minWidth={400} maxWidth={500} />
                    <ListHeaderEntry name="Id utente" fieldName="userId" minWidth={400} maxWidth={500} />
                    <ListHeaderEntry name="Codice tipo" fieldName="typeCode" minWidth={400} maxWidth={500} />
                    <ListHeaderEntry name="Unità di misura" fieldName="unitOfMeasure" minWidth={400} maxWidth={500} />
                    <ListHeaderEntry name="Score per unità di misura" fieldName="scorePerUnitOfMeasure" minWidth={400} maxWidth={500} />
                    <ListHeaderEntry name="Dimensione 1" fieldName="dimension1" minWidth={400} maxWidth={500} />
                    <ListHeaderEntry name="Dimensione 2" fieldName="dimension2" minWidth={400} maxWidth={500} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminSystemEventLogCard ref={cardRef} />
        </>
    );
}