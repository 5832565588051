import React, {useRef} from "react";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminOpenAISetupCard } from "../AdminOpenAISetupCard/AdminOpenAISetupCard";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { ToastService } from "../../../../Core/Toast/ToastService";

export const AdminOpenAISetupList : React.FC = () => {
    
    const cardRef = useRef<PageContainerBase>(null);
    
    return (
        <>
            <PageContainer endpoint={'/odata/openai/setup'} pageType={SystemPageType.List} title={"Setup OpenAI"} cardRef={cardRef} backAllowed insertAllowed updateAllowed>
                <PageDefinitionContainer type={ContainerType.Action}>
                        <ActionArea category={SystemActionCategory.Process}>
                            <ActionEntry name="CalculateAIScore" label="Calcola score AI" iconName="Calculator" onClick={(req:any)=>{
                                let instance = HttpHelper.buildAxiosInstance();
                                let endpoint = "/api/csp/azure/openai/score/calculate";
                                var promise = new Promise((resolve,reject)=>{
                                    instance.post(endpoint)
                                    .then((resp)=>{
                                        resolve(true);
                                    })
                                    .catch((resp)=>{
                                        console.log(resp.response.data.message);
                                        reject(resp.response.data.message);
                                    })
                                })
                        
                                ToastService.showPromiseMessage(promise, "Calcolo in corso ...", "Calcolo completato con successo", "Errore durante il calcolo");
                            }}>
                            </ActionEntry>                        
                        </ActionArea></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>                    
                    <ListHeaderEntry fieldName="primaryKey" name="Chiave primaria" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="azureKeyCredential" name="Azure key credential" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="azureServiceEndpoint" name="Azure service endpoint" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="azureEmbeddingDeploymentName" name="Azure embedding deployment name" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="azureSearchDeploymentName" name="Azure search deployment name" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="scoreLimit" name="Score limit" minWidth={100} maxWidth={200} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminOpenAISetupCard ref={cardRef} />
        </>
    );
}