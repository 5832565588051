import { HttpHelper } from "../../Core/Http/HttpHelper";
import { Lead, Partner, SalesHeaderTemplate,PartnerDashboardCue, CrmSetup, Item, PartnerBusinessContract, BookingAvailabilityItem, SharepointFile, BookingAvailabilityObject } from "../../Model/PartnerModels";
import { Ticket } from "../../Model/SystemModels";

export class PartnerService {

    public getIndustryTemplates(): Promise<SalesHeaderTemplate[]>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "odata/salesheadertemplate?$filter=Source eq 'Industry'";

        return new Promise((resolve, reject) => {
            instance.get(url).then((resp) => {
                let resultArray: SalesHeaderTemplate[] = resp.data;
                resolve(resultArray);
            })
            .catch((resp) => {
                reject(resp.response.data);
            });
        });
    }

    public getPartnerDashboardCue() : Promise<PartnerDashboardCue>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/System/partner/dashboard/cue";
        return new Promise((resolve,reject)=>{
            instance.get(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public getSharepointFiles(partnerId: number) : Promise<SharepointFile[]>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/csp/sharepoint/files/partner/"+partnerId+"/get";
        return new Promise((resolve,reject)=>{
            instance.get(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject(resp.response.data);
            })
        })
    }

    public getPartners(): Promise<Partner[]>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "odata/partner";

        return new Promise((resolve, reject) => {
            instance.get(url).then((resp) => {
                let result: Partner[] = resp.data;
                resolve(result);
            })
            .catch((resp) => {
                reject(resp.response.data);
            });
        });
    }

    public loadPartner(partnerId: number): Promise<Partner>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "odata/partner?$filter=id eq " + partnerId;

        return new Promise((resolve, reject) => {
            instance.get(url).then((resp) => {
                let result: Partner = resp.data[0];
                resolve(result);
            })
            .catch((resp) => {
                reject(resp.response.data);
            });
        });
    }

    public getPartnerBusinessContract(partnerBusinessContractCode: string): Promise<PartnerBusinessContract>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "odata/partner/business/contract?$filter=code eq '"+partnerBusinessContractCode+"'";

        return new Promise((resolve, reject) => {
            instance.get(url).then((resp) => {
                let result: PartnerBusinessContract = resp.data[0];
                resolve(result);
            })
            .catch((resp) => {
                reject(resp.response.data);
            });
        });
    }

    public saveLeadData(lead: Lead) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "odata/lead";
        if (lead.id == 0)
        {
            return new Promise((resolve, reject) => {
                instance.post(url, lead).then((resp) => {
                    resolve(true);
                })
                .catch((resp) => {
                    reject(resp.response.data);
                });
            });
        }
        else
        {
            return new Promise((resolve, reject) => {
                instance.patch(url + "/" + lead.systemId, lead).then((resp) => {
                    resolve(true);
                })
                .catch((resp) => {
                    reject(resp.response.data);
                });
            });
        }
    }

    public getCrmLeadData(): Promise<CrmSetup>
    {
        let instance = HttpHelper.buildAxiosInstance();
        
        return new Promise((resolve, reject) => {
            instance.get("/odata/CRMSetup").then((resp) => {
                resolve(resp.data[0]);
            })
            .catch((resp) => {
                reject(resp.response.data);
            });
        })
    }

    public syncCalculatedFields(): Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();

        return new Promise((resolve, reject) => {
            instance.post('/api/CRM/lead/updatecalculatedfields').then((resp) => {
                resolve(true);
            })
            .catch((jqXHR)=>{
                reject(jqXHR.response.data);
            })
        })
    }

    public syncLeadCalculatedFields(leadId:number): Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();

        return new Promise((resolve, reject) => {
            instance.post('/api/CRM/lead/updatecalculatedfields/'+leadId).then((resp) => {
                resolve(true);
            })
            .catch((jqXHR)=>{
                reject(jqXHR.response.data);
            })
        })
    }

    public saveActiveLeads(): Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();

        return new Promise((resolve, reject) => {
            instance.post('/api/CRM/lead/active/save').then((resp) => {
                resolve(true);
            })
            .catch((jqXHR)=>{
                reject(jqXHR.response.data);
            })
        })
    }

    public syncCRMLeadData(leadId?:string): Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url="/api/CRM/lead/syncronize";

        if(leadId)
            url+="/"+leadId;

        return new Promise((resolve, reject) => {
            instance.post(url).then((resp) => {
                resolve(true);
            })
            .catch((jqXHR)=>{
                reject(jqXHR.response.data);
            })
        })
    }

    public syncCRMLeadInteraction(leadId?:string): Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url="/api/CRM/lead/interaction/all/syncronize";

        if(leadId)
            url+="/"+leadId;

        return new Promise((resolve, reject) => {
            instance.post(url).then((resp) => {
                resolve(true);
            })
            .catch((jqXHR)=>{
                reject(jqXHR.response.data);
            })
        })
    }

    public syncSpecificCRMLeadData(leadId:string): Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();

        return new Promise((resolve, reject) => {
            instance.post('/api/CRM/lead/syncronize/'+leadId).then((resp) => {
                resolve(true);
            })
            .catch((jqXHR)=>{
                reject(jqXHR.response.data);
            })
        })
    }

    public createIncident(ticket: Ticket) : Promise<boolean> {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/crm/partner/incident/create/"+ticket.partnerId+"/"+ticket.userId;
        return new Promise<boolean>((resolve, reject) => {
            instance.post(url, ticket.ticketInfo).then((resp) => {
                resolve(true);
            })
            .catch((err) => {
                reject(err.response.data);
            });
        });
    }

    public getEOSAppsItems() : Promise<Item[]> {
        let instance = HttpHelper.buildAxiosInstance();

        let url = "/odata/item?$filter=category eq 'EosApps'&$orderby=description";
        return new Promise<Item[]>((resolve, reject) => {
            instance.get(url).then((resp) => {
                resolve(resp.data);
            })
            .catch((err) => {
                reject(err.response.data);
            });
        });
    }

    public bookLeadAppointment(availabilityItem: BookingAvailabilityItem, leadId: number, isInternal: boolean, ianaTimeZone: string) : Promise<boolean>
    {
        let jsonObject = new BookingAvailabilityObject();
        jsonObject.ianaTimeZone = ianaTimeZone;
        jsonObject.bookingAvailabilityItem = availabilityItem;

        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/csp/booking/book/"+leadId+"/"+isInternal;

        return new Promise((resolve, reject) => {
            instance.post(url, jsonObject).then((resp) => {
                resolve(true);
            })
            .catch((resp) => {
                reject(resp.response.data);
            });
        });
    }
}