import React, { useRef } from "react";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminCustomerInteractionTelemetryCard } from "../AdminCustomerInteractionTelemetryCard/AdminCustomerInteractionTelemetryCard";

export const AdminCustomerInteractionTelemetryList : React.FC = () => {
    
    const customerId = HttpHelper.getParameter('customerId');
    const name = HttpHelper.getParameter('name');
    const cardRef = useRef<PageContainerBase>(null);
    
    return (
        <>
            <PageContainer endpoint={"/odata/customer/interaction/telemetry?$filter=customerId eq "+customerId} pageType={SystemPageType.List} title={"Lista Customer Interaction Telemetry per " + name} backAllowed updateAllowed deleteAllowed cardRef={cardRef}>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Id" fieldName="entryNo" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id Customer" fieldName="customerId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id Tenant" fieldName="tenantId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="System Id Sorgente" fieldName="sourceSystemId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id Sorgente" fieldName="sourceId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Session" fieldName="session" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Main Phase" fieldName="mainPhase" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Phase" fieldName="phase" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Titolo Task" fieldName="taskTitle" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Sottotitolo Task" fieldName="taskSubtitle" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Dimensione 1" fieldName="dimension1" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Dimensione 2" fieldName="dimension2" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Messaggio" fieldName="message" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Tipo Azione" fieldName="actionType" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id User" fieldName="userId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Ip Address" fieldName="ipAddress" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Data/Ora Inizio" fieldName="startDateTime" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Data/Ora fine" fieldName="endDateTime" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminCustomerInteractionTelemetryCard ref={cardRef} />
        </>
    );
}