import { Stack, Icon, TextField, List, Image, ImageFit, ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";
import { FormEvent, SetStateAction, useState } from "react";
import styles from "./Answer.module.css";
import Azure from "../../../../Assets/Images/favicon.png";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { t } from "i18next";
import { ChatMessage, ChatMessageType, Conversation, CrmIncidentType, ISupportFlow, SupportUserInput, crmBaseUrl, getSoSmartToken, odataBaseUrl } from "../../../../ApplicationCode/Common/Chatbot/api";
import uuid from "react-uuid";
import { IAppStateContext } from "../../../../Core/State/ChatbotAppProvider";
import { Answer } from "./Answer";
import { Item } from "../../../../Model/PartnerModels";
import { YesNoConfirmationForm } from "./SoSmartAdvancedSupport";

enum EosSupportStep {
    Email,
    App,
    FlowType,
    AppIsInstalled,
    CustomerId,
    InstallationType,
    SubscriptionType,
    AppVersion,
    SupportRequest,
    End
}

export enum SupportFlowType {
    Commercial = "commercial",
    Technical = "technical"
}

export enum EosAppsCustomerType
{
    OnPrem = 0,
    Saas = 1
}

export enum EosAppsSubscriptionType
{
    Free = 0,
    Full = 1
}

export class EosAppsTicket {
    public email: string;
    public leadId: string;
    public itemId: number;
    public flowType: SupportFlowType;
    public incidentType: number;
    public customerId: string;
    public customerType: EosAppsCustomerType;
    public subscriptionType: EosAppsSubscriptionType;
    public appVersion: string;
    public title: string;
    public description: string;
    public appIsInstalled: boolean;

    constructor()
    {
        this.email = "";
        this.leadId = "";
        this.itemId = 0;
        this.incidentType = 0;
        this.customerId = "";
        this.customerType = EosAppsCustomerType.OnPrem;
        this.subscriptionType = EosAppsSubscriptionType.Free;
        this.appVersion = "";
        this.title = "";
        this.description = "";
        this.appIsInstalled = false;
        this.flowType = SupportFlowType.Commercial;
    }
}


export class EosSupportFlow implements ISupportFlow {
    
    supportRequest: EosAppsTicket;
    items: Item[] = [];
    supportTypeOptions: CrmIncidentType[] = [];

    constructor()
    {
        this.supportRequest = new EosAppsTicket();
    }
    setApp(item: Item): void {
        console.log(item);
        this.supportRequest.itemId = item.id;
    }

    isErrorHandled(stepId: number): boolean {
        return false;
    }

    isFlowCompleted(stepId: number): boolean {
        let currentStep: EosSupportStep = stepId;
        return (currentStep === EosSupportStep.End);
    }

    handleFastActions(stepId: number, onClickHandler: (key: any, value: any) => void): JSX.Element | undefined {
        return undefined;
    }

    showUserMessage(stepId: number): boolean {
        return true;
    }
    async loadRequiredData(): Promise<void> {
        this.items = await this.getItems();
        this.supportTypeOptions = await this.getSupportTypes();
    }

    handleElement(msg: ChatMessage, onUserInput: (key: any, value: any) => void): JSX.Element {
        let supportStep: EosSupportStep;

        try {
            supportStep = this.convertMessageSubTypeToStep(msg);
        }
        catch {
            return <></>;
        }
        
        
        switch (supportStep)
        {
            case EosSupportStep.Email:
                return <Answer answer={{
                    answer: msg.content.toString(),
                    citations: [],
                }}
                onCitationClicked={() => {}}
                onOpenSupportRequestClick={() => {}}
                isLastIteraction={false}
            /> 
            case EosSupportStep.App:
                return <EosAppForm items={this.items} OnSelectApp={(value, id) => {
                    this.supportRequest.itemId = Number(id);
                    onUserInput(value, value);
                }}/>
            case EosSupportStep.FlowType:
                return <FlowTypeForm onSelectFlowType={(value) => {
                    this.supportRequest.flowType = value;
                    switch (value)
                    {
                        case SupportFlowType.Commercial:
                            onUserInput(t('chatbot:openSupportRequest:flowCommercial'), t('chatbot:openSupportRequest:flowCommercial'));
                            break;
                        case SupportFlowType.Technical:
                            onUserInput(t('chatbot:openSupportRequest:flowTechnical'), t('chatbot:openSupportRequest:flowTechnical'));
                            break;
                    }
                }} />
            case EosSupportStep.AppIsInstalled:
                return <YesNoConfirmationForm onSelectYesNo={(value, id) => {
                    let boolValue = id == "true";
                    this.supportRequest.appIsInstalled = boolValue;
                    if (boolValue)
                        onUserInput(t("common:question:yes")!, t("common:question:yes")!);
                    else
                        onUserInput(t("common:question:no")!, t("common:question:no")!);

                }} question={t('chatbot:openSupportRequest:alreadyInstalledApp') + this.items.filter(e => e.id == this.supportRequest.itemId)[0].description + '?'}></YesNoConfirmationForm>
            case EosSupportStep.CustomerId:
                return <CustomerIdForm />
            case EosSupportStep.InstallationType:
                return <InstallationTypeForm onSelectInstallationType={(key, value) => {
                    onUserInput(key, value);
                }} />
            case EosSupportStep.SubscriptionType:
                return <SubscriptionTypeForm onSelectSubscriptionType={(key, value) => {
                    onUserInput(key, value);
                }} />
            case EosSupportStep.AppVersion:
                return <AppVersionForm />;
            case EosSupportStep.SupportRequest: 
                return <SupportRequestTextForm />
            case EosSupportStep.End:
                return <SuccessForm />
        }
    }

    initializeStartStep(): number {
        return -1;
    }

    async handleStep(app: Item, stepId: number, userInput: SupportUserInput | undefined, appStateContext: IAppStateContext, conversation: Conversation, setMessages: (value: SetStateAction<ChatMessage[]>) => void): Promise<number> {
        let currentStep: EosSupportStep = stepId;
        let increment: number = 1;

        switch (currentStep)
        {
            case EosSupportStep.Email:
                let result: boolean = this.validateEmail(userInput!.value);
                if (! result)
                {
                    let message = t("chatbot:chat:assistantEmailError",{email: userInput!.value});
                    throw new Error(message);
                }

                this.supportRequest.email = userInput!.value;
                if (app.id != 0)
                {
                    this.supportRequest.itemId = app.id;
                    increment += 1;
                }
                break;
            case EosSupportStep.App:
                break;
            case EosSupportStep.AppIsInstalled:
                console.log(this.supportRequest.appIsInstalled);
                if (! this.supportRequest.appIsInstalled)
                    increment += 4;

                break;
            case EosSupportStep.FlowType:
                break;
            case EosSupportStep.CustomerId:
                this.supportRequest.customerId = userInput!.value;
                switch (this.supportRequest.flowType)
                {
                    case "commercial":
                        console.log(this.supportRequest.flowType);
                        increment += 3;
                        break;
                }
                break;
            case EosSupportStep.InstallationType:
                this.supportRequest.customerType = userInput!.value;
                break;
            case EosSupportStep.SubscriptionType:
                this.supportRequest.subscriptionType = userInput!.value;
                break;
            case EosSupportStep.AppVersion:
                this.supportRequest.appVersion = userInput!.value;
                break;
            case EosSupportStep.SupportRequest:
                this.supportRequest.title = userInput!.value;
                this.supportRequest.description = userInput!.value;
                await this.createTicket();
                break;
            case EosSupportStep.End:
                break;
        }

        conversation.messages.push(this.generateStepMessage(currentStep + increment));
        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
        setMessages(conversation.messages);
        return increment;
    }

    private createBotRequestMessage(content: string, subType: string) : ChatMessage
    {
        return {
            id: uuid(),
            role: "assistant",
            content: content,
            date: new Date().toISOString(),
            type: ChatMessageType.BotRequest,
            subType: subType
        };
    }

    private generateStepMessage(step: EosSupportStep) : ChatMessage {
        switch(step)
        {
            case EosSupportStep.Email:
                return this.createBotRequestMessage(t("chatbot:chat:assistantEmailMessage"), "email");
            case EosSupportStep.App:
                return this.createBotRequestMessage("Phase 1 - App", "app");
            case EosSupportStep.FlowType:
                return this.createBotRequestMessage("Phase 2 - Flow type", "flowType");
            case EosSupportStep.AppIsInstalled:
                return this.createBotRequestMessage("Phase 3 - Is App Installed", "appIsInstalled");
            case EosSupportStep.CustomerId:
                return this.createBotRequestMessage("Phase 4 - Customer ID", "customerId");
            case EosSupportStep.InstallationType:
                return this.createBotRequestMessage("Phase 5 - Installation type", "installationType");
            case EosSupportStep.SubscriptionType:
                return this.createBotRequestMessage("Phase 6 - Subscription type", "subscriptionType");
            case EosSupportStep.AppVersion:
                return this.createBotRequestMessage("Phase 7 - App version", "appVersion");
            case EosSupportStep.SupportRequest: 
                return this.createBotRequestMessage("Phase 8 - Support request", "supportRequest");
            case EosSupportStep.End:
                return this.createBotRequestMessage("Phase 9 - Success", "success");
        }
    }

    private validateEmail(value: string) : boolean {
        let isValid = value.toLocaleLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        if (! isValid)
            return false;
        return true;
    }

    private convertMessageSubTypeToStep(msg: ChatMessage) : EosSupportStep {
        switch (msg.subType)
        {
            case "email":
                return EosSupportStep.Email;
            case "flowType":
                return EosSupportStep.FlowType;
            case "appIsInstalled":
                return EosSupportStep.AppIsInstalled;
            case "customerId":
                return EosSupportStep.CustomerId;
            case "installationType":
                return EosSupportStep.InstallationType;
            case "subscriptionType":
                return EosSupportStep.SubscriptionType;
            case "app":
                return EosSupportStep.App;
            case "appVersion":
                return EosSupportStep.AppVersion;
            case "supportRequest":
                return EosSupportStep.SupportRequest;
            case "success":
                return EosSupportStep.End;
        }

        throw new Error("Invalid message subtype");
    }

    private async getItems(): Promise<Item[]> {
        return await this.performODataHttpRequest("/item?filter=category eq 'EosApps' and itemErpNo ne null and itemErpNo gt ''");
    }

    private async getSupportTypes(): Promise<CrmIncidentType[]> {
        return await this.performODataHttpRequest("/crm/incident/type");
    }

    private async performODataHttpRequest(path: string): Promise<any> {
        let token = await getSoSmartToken();
        const response = await fetch(odataBaseUrl + path, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token
            }
        });

        if (response.status == 200)
            return response.json();
        
        throw new Error("Cannot read data from server API " + odataBaseUrl + path);
    }

    private async createTicket(): Promise<boolean>  {
        try {
            let token = await getSoSmartToken();
            const response = await fetch(crmBaseUrl + "/incident/chatbot/create", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
                body: JSON.stringify(this.supportRequest)
            });

            if (!(response.status === 200))
                throw new Error();

            return true;
        }   
        catch
        {
            throw new Error("Mi scuso ma non sono riuscito a creare la richiesta di supporto. Attendi qualche minuto e riprova.");
        }
        
    }
}



export const CustomerIdForm = ({
}: {}) => {
    const getContext = () : string => {
        let context = HttpHelper.getParameter("context");
        if((context === "") || (context === null))
            context = "SoSmart";
        
        
        if (window.location.origin === "https://eosapps.so-smart.it")
            return "Eos";

        return context;
    }


    return (
        <>
            <Stack className={styles.answerContainer} tabIndex={0}>
                <Stack.Item grow>
                    <Stack className={styles.answerText} tokens={{
                        childrenGap: 5
                    }}>
                        <Stack.Item>
                            {t("chatbot:customerIdForm:title")!}
                        </Stack.Item>
                        <Stack.Item style={{
                            fontStyle: 'italic',
                            fontSize: 12
                        }}>
                            {t("chatbot:customerIdForm:subTitle")!} <a style={{
                                paddingLeft: 0,
                                textDecoration: 'none',
                                color: '#2A7DE1'
                            }} href="https://helpbc.eos-solutions.it/main.aspx?lang=en&content=EOS%20APPS%20Customer%20ID%20and%20Subscriptions%20management.html" target="_blank">{t("chatbot:customerIdForm:link")!}</a>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        </>
    );
};


interface FlowTypeProps {
    onSelectFlowType: (flowType: SupportFlowType) => void;
}

export const FlowTypeForm = ({
    onSelectFlowType
}: FlowTypeProps) => {

    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    return (
        <>
            <Stack className={styles.answerContainer} tabIndex={0}>
                <Stack.Item grow>
                    <Stack className={styles.answerText} tokens={{
                        childrenGap: 20
                    }}>
                        <Stack.Item>
                            {t("chatbot:supportTypeForm:title")!}
                        </Stack.Item>
                        <Stack.Item>
                            <ChoiceGroup disabled={isDisabled} style={{
                                overflowY: 'hidden'
                            }} onChange={(ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) => {
                                ev?.preventDefault();

                                setIsDisabled(true);
                                onSelectFlowType(option?.key as SupportFlowType);
                            }}
                            options={[{
                                key: SupportFlowType.Commercial,
                                text: t('chatbot:openSupportRequest:flowCommercial'),
                            },
                            {
                                key: SupportFlowType.Technical,
                                text: t('chatbot:openSupportRequest:flowTechnical'),
                            }]}
                            >
                            </ChoiceGroup>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        </>
    );
};

interface Props {
    onSelectSupportType: (supportType: string, id: string) => void;
    supportTypeOptions: CrmIncidentType[];
}

export const SupportTypeForm = ({
    onSelectSupportType, supportTypeOptions
}: Props) => {

    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const getContext = () : string => {
        let context = HttpHelper.getParameter("context");
        if((context === "") || (context === null))
            context = "SoSmart";
        
        
        if (window.location.origin === "https://eosapps.so-smart.it")
            return "Eos";

        return context;
    }

    const convertIncidentTypes = () : IChoiceGroupOption[] => {
        let result : IChoiceGroupOption[] = [];

        supportTypeOptions.forEach(element => {
            if (element.toShow) {
                let option: IChoiceGroupOption = {key: element.name, text: element.name, id: element.entryNo.toString()}
                result.push(option);
            }
        });

        return result;
    }

    return (
        <>
            <Stack className={styles.answerContainer} tabIndex={0}>
                <Stack.Item grow>
                    <Stack className={styles.answerText} tokens={{
                        childrenGap: 20
                    }}>
                        <Stack.Item>
                            {t("chatbot:supportTypeForm:title")!}
                        </Stack.Item>
                        <Stack.Item>
                            <ChoiceGroup disabled={isDisabled} style={{
                                overflowY: 'hidden'
                            }} onChange={(ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) => {
                                ev?.preventDefault();

                                setIsDisabled(true);
                                onSelectSupportType(option?.key!, option?.id!);
                            }}
                            options={convertIncidentTypes()}
                            >
                            </ChoiceGroup>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        </>
    );
};


interface InstallationTypeProps {
    onSelectInstallationType: (key: any, value: string) => void;
}

export const InstallationTypeForm = ({
    onSelectInstallationType
}: InstallationTypeProps) => {

    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const bcInstallationTypeOptions: IChoiceGroupOption[] = [
        {
            key: 'OnPrem',
            text: 'OnPrem',
        },
        {
            key: 'SaaS',
            text: 'SaaS',
        }
    ]

    return (
        <>
            <Stack className={styles.answerContainer} tabIndex={0}>
                <Stack.Item grow>
                    <Stack className={styles.answerText} tokens={{
                        childrenGap: 20
                    }}>
                        <Stack.Item>
                            {t("chatbot:installationTypeForm:title")!}
                        </Stack.Item>
                        <Stack.Item>
                            <ChoiceGroup disabled={isDisabled} style={{
                                overflowY: 'hidden'
                            }} onChange={(ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) => {
                                ev?.preventDefault();

                                setIsDisabled(true);
                                onSelectInstallationType(option?.value, option?.key!);
                            }}
                            options={bcInstallationTypeOptions}
                            >
                            </ChoiceGroup>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        </>
    );
};

interface SubscriptionTypeProps {
    onSelectSubscriptionType: (key: any, value: string) => void;
}

export const SubscriptionTypeForm = ({
    onSelectSubscriptionType
}: SubscriptionTypeProps) => {

    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const subscriptionTypeOptions: IChoiceGroupOption[] = [
        {
            key: 'Full',
            text: 'Full',
        },
        {
            key: 'Free',
            text: 'Free',
        }
    ]

    return (
        <>
            <Stack className={styles.answerContainer} tabIndex={0}>
                <Stack.Item grow>
                    <Stack className={styles.answerText} tokens={{
                        childrenGap: 20
                    }}>
                        <Stack.Item>
                            {t("chatbot:subscriptionTypeForm:title")!}
                        </Stack.Item>
                        <Stack.Item>
                            <ChoiceGroup disabled={isDisabled} style={{
                                overflowY: 'hidden'
                            }} onChange={(ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) => {
                                ev?.preventDefault();

                                setIsDisabled(true);
                                onSelectSubscriptionType(option?.value, option?.key!);
                            }}
                            options={subscriptionTypeOptions}
                            >
                            </ChoiceGroup>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        </>
    );
};

export const AppVersionForm = ({
}: {}) => {
    return (
        <>
            <Stack className={styles.answerContainer} tabIndex={0}>
                <Stack.Item grow>
                    <Stack className={styles.answerText} tokens={{
                        childrenGap: 5
                    }}>
                        <Stack.Item>
                            {t("chatbot:appVersionForm:title")!}
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        </>
    );
};

interface EosAppFormProps {
    OnSelectApp: (app: string, id: string) => void;
    items: Item[];
}

export const EosAppForm = ({
    OnSelectApp, items
}: EosAppFormProps) => {
    const [searchText, setSearchText] = useState<string>("");
    const [enabled, setEnabled] = useState<boolean>(true);

    const onRenderCell = (item: Item, index: number, isScrolling: boolean): JSX.Element => {
        if (enabled)
            return (
            <Stack horizontal data-is-focusable={true} styles={{
                root: {
                    borderRadius: 20,
                    minWidth: '25vw',
                    selectors: {
                        "&:hover": {
                            backgroundColor: '#f2f2f2',
                            cursor: 'pointer'
                        }
                    }
                }
            }} onClick={() => {
                setEnabled(false);
                OnSelectApp(item.description, item.id.toString());
            }}> 
                <Stack.Item>
                    <Image src={Azure} imageFit={ImageFit.centerContain} width={70} height={70} />
                </Stack.Item>
                <Stack.Item style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <Stack>
                        <Stack.Item style={{
                            fontSize: 13,
                            fontWeight: 500
                        }}>{item.description}</Stack.Item>
                        <Stack.Item>{item.itemErpNo}</Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
            );
        else
        return (
            <Stack horizontal data-is-focusable={true} styles={{
                root: {
                    borderRadius: 20,
                    minWidth: '25vw'
                }
                }
            }> 
                <Stack.Item>
                    <Image src={Azure} imageFit={ImageFit.centerContain} width={70} height={70} />
                </Stack.Item>
                <Stack.Item style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <Stack>
                        <Stack.Item style={{
                            fontSize: 13,
                            fontWeight: 500
                        }}>{item.description}</Stack.Item>
                        <Stack.Item>{item.itemErpNo}</Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
            );
      };
   
    const getItems = () : Item[] => {
        return items.filter(e => e.itemErpNo.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 || e.description.toLowerCase().indexOf(searchText.toLowerCase()) >= 0);
    }


    return (
        <>
            <Stack className={styles.answerContainer} tabIndex={0}>
                <Stack.Item grow>
                    <Stack className={styles.answerText} tokens={{
                        childrenGap: 20
                    }}>
                        <Stack.Item>
                            {t("chatbot:eosAppForm:title")!}
                        </Stack.Item>
                        <Stack.Item>
                            <TextField label="" disabled={!enabled} placeholder={t("chatbot:eosAppForm:placeholder")!} underlined onChange={(e, val) => {
                                setSearchText(val!);
                            }} />
                        </Stack.Item>
                        <Stack.Item>
                            <List items={getItems()} onRenderCell={(item, index, isScrolling) => onRenderCell(item!, index!, isScrolling!)}  style={{
                            maxHeight: '20vh',
                            overflowY: 'auto'
                        }}/>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        </>
    );
};

interface EosAppWelcomeMessageProps {
    OnSelectApp: (item: Item) => void;
    items: Item[];
}

export const EosAppWelcomeMessage = ({
    OnSelectApp, items
}: EosAppWelcomeMessageProps) => {
    const [searchText, setSearchText] = useState<string>("");
    const [enabled, setEnabled] = useState<boolean>(true);

    const onRenderCell = (item: Item, index: number, isScrolling: boolean): JSX.Element => {
        if (enabled)
            return (
            <Stack horizontal data-is-focusable={true} styles={{
                root: {
                    borderRadius: 20,
                    minWidth: '25vw',
                    selectors: {
                        "&:hover": {
                            backgroundColor: '#f2f2f2',
                            cursor: 'pointer'
                        }
                    }
                }
            }} onClick={() => {
                setEnabled(false);
                OnSelectApp(item);
            }}> 
                {
                    (HttpHelper.getParameter("embedded") === "true") ?
                    <>
                        <Stack.Item style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: 10
                        }}>
                            <Stack>
                                <Stack.Item style={{
                                    fontSize: 12,
                                    fontWeight: 500
                                }}>
                                    {item.description} <b>({item.itemErpNo})</b>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                    </>
                    :
                    <>
                        <Stack.Item>
                            <Image src={Azure} imageFit={ImageFit.centerContain} width={70} height={70} />
                        </Stack.Item>
                        <Stack.Item style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Stack>
                                <Stack.Item style={{
                                    fontSize: 12,
                                    fontWeight: 500
                                }}>{item.description}</Stack.Item>
                                <Stack.Item>{item.itemErpNo}</Stack.Item>
                            </Stack>
                        </Stack.Item>
                    </>
                }
            </Stack>
            );
        else
        return (
            <Stack horizontal data-is-focusable={true} styles={{
                root: {
                    borderRadius: 20,
                    minWidth: '25vw'
                }
                }
            }> 
            {
                (HttpHelper.getParameter("embedded") === "true") ?
                <>
                    <Stack.Item style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: 10
                        }}>
                            <Stack>
                                <Stack.Item style={{
                                    fontSize: 12,
                                    fontWeight: 500
                                }}>
                                    {item.description} <b>({item.itemErpNo})</b>
                                </Stack.Item>
                            </Stack>
                    </Stack.Item>
                </>
                :
                <>
                <   Stack.Item>
                        <Image src={Azure} imageFit={ImageFit.centerContain} width={70} height={70} />
                    </Stack.Item>
                    <Stack.Item style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <Stack>
                            <Stack.Item style={{
                                fontSize: 12,
                                fontWeight: 500
                            }}>{item.description}</Stack.Item>
                            <Stack.Item>{item.itemErpNo}</Stack.Item>
                        </Stack>
                    </Stack.Item>
                </>
            }
            </Stack>
            );
      };
   
    const getItems = () : Item[] => {
        return items.filter(e => e.itemErpNo.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 || e.description.toLowerCase().indexOf(searchText.toLowerCase()) >= 0);
    }


    return (
        <>
            <Stack className={styles.answerContainer} tabIndex={0}>
                <Stack.Item grow>
                    <Stack className={styles.answerText} tokens={{
                        childrenGap: 20
                    }}>
                        <Stack.Item>
                            <h2>{t("chatbot:eosAppWelcome:header")!}</h2>
                        </Stack.Item>
                        <Stack.Item className={styles.answerSubtext}>
                            {t("chatbot:eosAppWelcome:title")!}
                        </Stack.Item>
                        <Stack.Item>
                            <TextField label="" disabled={!enabled} placeholder={t("chatbot:eosAppWelcome:placeholder")!} underlined onChange={(e, val) => {
                                setSearchText(val!);
                            }} />
                        </Stack.Item>
                        <Stack.Item>
                            <List items={getItems()} onRenderCell={(item, index, isScrolling) => onRenderCell(item!, index!, isScrolling!)}  style={{
                            maxHeight: '20vh',
                            overflowY: 'auto'
                        }}/>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        </>
    );
};

export const SupportRequestTextForm = ({
}: {}) => {    
    return (
        <>
            <Stack className={styles.answerContainer} tabIndex={0}>
                <Stack.Item grow>
                    <Stack className={styles.answerText} tokens={{
                        childrenGap: 5
                    }}>
                        <Stack.Item>
                            {t("chatbot:supportRequestTextForm:title")!}
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        </>
    );
};

export const SuccessForm = ({
}: {}) => {
    return (
        <>
            <Stack className={styles.answerContainer} tabIndex={0}> 
                <Stack.Item style={{
                    padding: 10
                }}>
                    <h2>
                        👍
                        {t("chatbot:openSupportRequest:successTitle")!}
                    </h2>
                    <br/>
                    <span>{t("chatbot:openSupportRequest:successText")!}</span><br/>
                    <span>{t("chatbot:openSupportRequest:successSubText")!}</span>
                </Stack.Item>
            </Stack>
        </>
    );
};