import React, { useRef, useState } from "react";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { SystemCore } from "../../../../Core/System/SystemCore";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import {SystemActionCategory, SystemDataLoadingStatus, SystemPageType } from "../../../../Model/SystemModels";
import { Loader } from "../../../Common/Loader/Loader";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminContractSetupCard } from "../AdminContractSetupCard/AdminContractSetupCard";
import { useNavigate } from "react-router-dom";

export const AdminContractSetupList : React.FC = () => {

    const cardRef = useRef<PageContainerBase>(null);
    const adminService = new AdminService();
    const [dataStatus,setDataStatus] = useState(SystemDataLoadingStatus.Loaded);
    const [loaderMessage,setLoaderMessage] = useState("");
    const navigate = useNavigate();

    const initContractData = () => {
        adminService.initContractData().then((resp:string)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Success, "Inizializzazione Contract Data completata con successo");
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error,jqXHR.message);
        })
    }

    const reloadContractsStatus = () => {
        adminService.reloadContractsStatus().then((resp:Boolean)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Success, "Aggiornamento Stato Contratti completato con successo");
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error,jqXHR.message);
        })
    }
    
    if (!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={loaderMessage}></Loader>;
    else
        return (
            <>
                <PageContainer endpoint="/odata/contract/setup" pageType={SystemPageType.List} title="Contract setup" cardRef={cardRef} backAllowed showAllowed insertAllowed updateAllowed deleteAllowed>               
                    <PageDefinitionContainer type={ContainerType.Action}>
                        <ActionArea category={SystemActionCategory.Navigation}>
                            <ActionEntry label="Digital contract" iconName="TextDocumentShared" name="openDigitalContract" onClick={(req:any)=>{
                                    navigate("/app/admin/administrative/digitalContract");
                                }}>
                            </ActionEntry>
                        </ActionArea>
                        <ActionArea category={SystemActionCategory.Process}>
                            <ActionEntry name="reloadContractsStatus" label="Aggiorna Stato Contratti" iconName="Refresh" onClick={(req:any)=>{
                                setDataStatus(SystemDataLoadingStatus.ToLoad);
                                setLoaderMessage("Aggiornamento Stato Contratti in corso, Attendere...");
                                reloadContractsStatus();
                            }}>                            
                            </ActionEntry>
                            <ActionEntry name="initContractData" label="Inizializza Contract Data" iconName="RecurringTask" onClick={(req:any)=>{
                                setDataStatus(SystemDataLoadingStatus.ToLoad);
                                setLoaderMessage("Inizializzazione Contract Data in corso, Attendere...");
                                initContractData();
                            }}>                            
                            </ActionEntry>
                        </ActionArea>
                    </PageDefinitionContainer>                             
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry name="Chiave Primaria" fieldName="primaryKey" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Ix Fe Client Id" fieldName="ixFeClientId" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Ix Fe Client Secret" fieldName="ixFeClientSecret" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Ix Fe Username" fieldName="ixFeUserName" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Ix Fe Password" fieldName="ixFePassword" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Ix Fe Url Web Api OAuth" fieldName="ixFeUrlWebApiOAuth" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Ix Fe Url Web Api Retailer" fieldName="ixFeUrlWebApiRetailer" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Ix Fe Item Document Number" fieldName="ixFeItemDocumentNumber" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Ix Fe Item Company Number" fieldName="ixFeItemCompanyNumber" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Abletech Reseller Identifier" fieldName="abletechResellerIdentifier" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Abletech Distributor Identifier" fieldName="abletechDistributorIdentifier" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Namirial Url" fieldName="namirialUrl" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Namirial User Account" fieldName="namirialUserAccount" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Namirial User Password" fieldName="namirialUserPassword" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Namirial Organization Key" fieldName="namirialOrganizationKey" minWidth={150} maxWidth={250} />                    
                        <ListHeaderEntry name="Namirial Api Key" fieldName="namirialApiKey" minWidth={150} maxWidth={250} />                    
                        <ListHeaderEntry fieldName="contractNotificationSetupType" name="Tipo setup" minWidth={100} maxWidth={200} onRender={(item: any, index, column)=>{
                            if (item.type === 0)
                                return "Email"
                            else if (item.type === 1)
                                return "Chat"
                        }} />
                        <ListHeaderEntry fieldName="contractNotificationSetupCode" name="Codice setup" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="contractNotificationTemplateCode" name="Codice template" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="contractNotificationGroupCode" name="Codice gruppo" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="adminAppId" name="Id App Admin" minWidth={100} maxWidth={200} />
                    </PageDefinitionContainer>
                </PageContainer>
                <AdminContractSetupCard ref={cardRef} />
            </>
        );
}