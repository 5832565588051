import React, { FormEvent, SetStateAction, useState } from "react";
import { BCNProcess, BCNProcessModule, BCNProcessTest, ChatMessage, ChatMessageType, Conversation, ISupportFlow, SupportEntry, SupportUserInput, crmBaseUrl, getSoSmartToken, odataBaseUrl } from "../../../../ApplicationCode/Common/Chatbot/api";
import { IAppStateContext } from "../../../../Core/State/ChatbotAppProvider";
import uuid from "react-uuid";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { Checkbox, ChoiceGroup, ComboBox, DefaultButton, IChoiceGroupOption, IComboBoxOption, Icon, Label, Separator, Spinner, Stack, mergeStyles } from "@fluentui/react";
import styles from "./Answer.module.css";
import { t } from "i18next";
import { SuccessForm } from "./EosAppSupport";
import { Item } from "../../../../Model/PartnerModels";

enum SoSmartAdvancedSupportStep {
    QuestionLastError,
    ConfirmTest,
    SupportRequest,
    ConfirmQuestion,
    TicketQuestion,
    End,
    TicketCreated
}

export class SoSmartAdvancedTicket {
    public userId: string;
    public tenantId: string;
    public supportId: string;
    public question: string;
    public useLastError: boolean;
    public bcnProcessModule: BCNProcessModule;
    public bcnProcess: BCNProcess;
    public bcnProcessTest: BCNProcessTest;
    public bcnProcessModuleOther: boolean;
    public bcnProcessOther: boolean;
    public bcnProcessTestOther: boolean;

    constructor()
    {
        this.userId = "";
        this.tenantId = "";
        this.supportId = "";
        this.question = "";
        this.useLastError = false;
        this.bcnProcessModule = new BCNProcessModule();
        this.bcnProcess = new BCNProcess();
        this.bcnProcessTest = new BCNProcessTest();
        this.bcnProcessModuleOther = false;
        this.bcnProcessOther = false;
        this.bcnProcessTestOther = false;
    }
}

export class SoSmartAdvancedSupportFlow implements ISupportFlow {
    
    supportRequest: SoSmartAdvancedTicket;
    supportEntry: SupportEntry = new SupportEntry();

    constructor()
    {
        this.supportRequest = new SoSmartAdvancedTicket();        
    }
    setApp(item: Item): void {
        
    }

    isErrorHandled(stepId: number): boolean {
        return false;
    }

    isFlowCompleted(stepId: number): boolean {
        let currentStep: SoSmartAdvancedSupportStep = stepId;
        return ((currentStep === SoSmartAdvancedSupportStep.End) || (currentStep === SoSmartAdvancedSupportStep.TicketCreated));
    }

    handleFastActions(stepId: number, onClickHandler: (key: any, value: any) => void): JSX.Element | undefined {
        let currentStep: SoSmartAdvancedSupportStep = stepId;
        const primary = mergeStyles([{
            color: "#2A7DE1",
            backgroundColor: '#ffffff',
            border: "1px solid #2A7DE1",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "5px 16px",
            gap: 4,
            fontFamily: 'Nunito',
            borderRadius: 16,
            cursor: "pointer",
            selectors: {
                "&:hover" : {
                    backgroundColor: "#2A7DE1",
                    color: "white",
                    transition: "all 0.4s"
                }
            }
        }]);

        const spanStyle = mergeStyles([{
            fontSize: 'calc(0.1vw + 10px)',
            textTransform: 'uppercase',
            fontWeight: 600,
            marginLeft: 5
        }]);

        switch(currentStep)
        {
            case SoSmartAdvancedSupportStep.ConfirmQuestion:
                return (
                    <>
                    <Stack.Item
                        className={primary}
                        role="button"
                        aria-label="Stop generating"
                        tabIndex={0}
                        onClick={() => {
                            onClickHandler("correct", t("chatbot:openSupportRequest:satisfied")!);
                        }}
                        >
                            <Icon iconName="SkypeCheck" aria-hidden="true"/>
                            <span aria-hidden="true" className={spanStyle}>{t("chatbot:openSupportRequest:satisfied")!}</span>
                    </Stack.Item>
                    <Stack.Item
                        className={primary}
                        role="button"
                        aria-label="Stop generating"
                        tabIndex={0}
                        onClick={() => {
                            onClickHandler("wrong", t("chatbot:openSupportRequest:openTicket")!);
                        }}
                        >
                            <Icon iconName="StatusErrorFull" aria-hidden="true"/>
                            <span aria-hidden="true" className={spanStyle}>{t("chatbot:openSupportRequest:openTicket")!}</span>
                    </Stack.Item>
                    </>
                );
        }

        return undefined;
    }

    showUserMessage(stepId: number): boolean {
        let supportStep: SoSmartAdvancedSupportStep = stepId;
        if (supportStep === SoSmartAdvancedSupportStep.SupportRequest)
            return false;
        return true;
    }

    handleElement(msg: ChatMessage, onUserInput: (key: any, value: any) => void): JSX.Element {
        let supportStep: SoSmartAdvancedSupportStep;

        try {
            supportStep = this.convertMessageSubTypeToStep(msg);
        }
        catch {
            return <></>;
        }
        
        switch (supportStep)
        {
            case SoSmartAdvancedSupportStep.QuestionLastError:
                return <LastErrorConfirmationForm subText={this.supportEntry.lastError} question={t("chatbot:openSupportRequest:supportQuestion")!} printInformation={true} supportEntry={this.supportEntry}
                            onSelectYesNo={(value, id) => {
                                let key = "Sì";
                                if (id === "false")
                                    key = "No";

                                onUserInput(id, key);
                        }} />
            case SoSmartAdvancedSupportStep.ConfirmTest:
                return <TestConfirmationForm
                            printInformation={!(this.supportEntry.lastError)}
                            supportEntry={this.supportEntry}
                            bcnProcessModule={this.supportRequest.bcnProcessModule}
                            bcnProcess={this.supportRequest.bcnProcess}
                            bcnProcessTest={this.supportRequest.bcnProcessTest}
                            onConfirm={(process, module, test, moduleOtherAction, processOtherAction, testOtherAction) => {
                                let value: string = this.composeQuestionFromSupportEntry(module, process, test, testOtherAction, processOtherAction, this.supportEntry.pageName, "", "");

                                this.supportRequest.bcnProcess = process;
                                this.supportRequest.bcnProcessModule = module;
                                this.supportRequest.bcnProcessTest = test;
                                this.supportRequest.bcnProcessModuleOther = moduleOtherAction;
                                this.supportRequest.bcnProcessOther = processOtherAction;
                                this.supportRequest.bcnProcessTestOther = testOtherAction;
                                onUserInput(module.code + "#" + process.processCode + "#" + test.lineNo + "#" + moduleOtherAction + '#' + processOtherAction + '#' + testOtherAction, value);
                            }} />
            case SoSmartAdvancedSupportStep.SupportRequest:
                return <QuestionStep />;
            case SoSmartAdvancedSupportStep.TicketQuestion:
                return <YesNoConfirmationForm question={t("chatbot:openSupportRequest:ticketCreationQuestion")!} onSelectYesNo={(value, id) => {
                    let key = "Sì";
                    if (id === "false")
                        key = "No";

                    onUserInput(id, key);
                }} />
            case SoSmartAdvancedSupportStep.TicketCreated:
                return <SuccessForm />
        }

        return <></>;
    }

    async loadRequiredData(supportId: string): Promise<void> {
        this.supportEntry = await this.getSupportEntry(supportId);

        this.supportRequest.bcnProcessModule = (await this.getBCNProcessModules());
        this.supportRequest.bcnProcess = (await this.getBCNProcesses());
        this.supportRequest.bcnProcessTest = (await this.getBCNProcessTests());
    }

    initializeStartStep(): number {
        if (this.supportEntry.lastError)
            return -1
        
        return 0;
    }

    async handleStep(app: Item, stepId: number, userInput: SupportUserInput | undefined, appStateContext: IAppStateContext, conversation: Conversation, setMessages: (value: SetStateAction<ChatMessage[]>) => void, openAiAPICall: (question: string, conversationId?: string, skipMessageCreation?: boolean) => Promise<void>): Promise<number> {
        let currentStep: SoSmartAdvancedSupportStep = stepId;
        let increment: number = 1;

        switch (currentStep)
        {
            case SoSmartAdvancedSupportStep.QuestionLastError:
                if (this.supportEntry.lastError)
                {

                    let value = true;
                    if (userInput?.key === "false")
                    value = false;
                
                    this.supportRequest.useLastError = value;
                    this.supportEntry.newProcessCode = this.supportRequest.bcnProcess.processCode;
                    this.supportEntry.newProcessModule = this.supportRequest.bcnProcessModule.code;
                    this.supportEntry.newProcessLineNo = this.supportRequest.bcnProcessTest.lineNo;
                    await this.updateSupportEntry();
                    
                    if (this.supportRequest.useLastError)
                    {
                        openAiAPICall(this.composeQuestionFromSupportEntry(this.supportRequest.bcnProcessModule, this.supportRequest.bcnProcess, this.supportRequest.bcnProcessTest, false, false, this.supportEntry.pageName, "", this.supportEntry.lastError), conversation.id);
                        return 3;
                    }
                }

                break;
            case SoSmartAdvancedSupportStep.ConfirmTest:
                this.supportEntry.newProcessCode = this.supportRequest.bcnProcess.processCode;
                this.supportEntry.newProcessModule = this.supportRequest.bcnProcessModule.code;
                this.supportEntry.newProcessLineNo = this.supportRequest.bcnProcessTest.lineNo;
                this.supportEntry.processModuleOther = this.supportRequest.bcnProcessModuleOther;
                this.supportEntry.processOther = this.supportRequest.bcnProcessOther;
                this.supportEntry.processTestOther = this.supportRequest.bcnProcessTestOther;
                
                await this.updateSupportEntry();
                break;
            case SoSmartAdvancedSupportStep.SupportRequest:
                this.supportRequest.question = userInput!.value;
                this.supportEntry.customerComment = userInput!.value;
                await this.updateSupportEntry();
                console.log(this.supportEntry);
                console.log(this.supportRequest);
                openAiAPICall(this.composeQuestionFromSupportEntry(this.supportRequest.bcnProcessModule, this.supportRequest.bcnProcess, this.supportRequest.bcnProcessTest, this.supportEntry.processTestOther, this.supportEntry.processOther, this.supportEntry.pageName, this.supportEntry.customerComment, this.supportEntry.lastError), conversation.id);
                return 1;
            case SoSmartAdvancedSupportStep.ConfirmQuestion:
                if (! userInput?.systemGenerated)
                {
                    openAiAPICall(userInput?.value, conversation.id, true);
                    return 0;
                }

                if (userInput?.key == "correct")
                    increment = 2;
                break;
            case SoSmartAdvancedSupportStep.TicketQuestion:
                let answer = true;
                if (userInput?.key == "false")
                    answer = false;

                if (answer)
                {
                    await this.createTicket(conversation.id);
                    increment = 2;
                }
                else
                    increment = 1;
                break;
        }

        conversation.messages.push(this.generateStepMessage(stepId + increment)!);
        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
        setMessages(conversation.messages);
        return increment;
    }

    private async createTicket(conversationId: string): Promise<boolean>  {
        try {
            let token = await getSoSmartToken();
            let url = crmBaseUrl + "/incident/user/support/create/" + this.supportEntry.id + "/" + conversationId;
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + token
                },
                body: null,
            });
            if (! (response.status === 200))
                throw new Error("");

            return true;
        }   
        catch
        {
            throw new Error(t("chatbot:openSupportRequest:ticketCreationError")!);
        }
        
    }

    private async updateSupportEntry(): Promise<boolean> {
        let token = await getSoSmartToken();
        let requestData: any = this.supportEntry;
        delete requestData["timestamp"];
        
        try {
            const response = await fetch(odataBaseUrl + "/support/entry/" + this.supportEntry.systemId, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
                body: JSON.stringify(requestData),
            });
            if (! (response.status == 204))
                throw new Error("");

            return true;
        }   
        catch
        {
            throw new Error(t("chatbot:openSupportRequest:updateSupportEntryError")!);
        }
    }

    private createErrorMessage(errorMessage: string) : ChatMessage {
        return {
            id: uuid(),
            role: "error",
            content: errorMessage,
            date: new Date().toISOString(),
            type: ChatMessageType.Conversation
        };
    }

    private createUserMessage(message: string) : ChatMessage {
        return {
            id: uuid(),
            role: "user",
            content: message,
            date: new Date().toISOString(),
            type: ChatMessageType.Conversation
        };
    }

    private createAssistantMessage(content: string) : ChatMessage
    {
        return {
            id: uuid(),
            role: "assistant",
            content: content,
            date: new Date().toISOString(),
            type: ChatMessageType.Conversation
        };
    }

    private createBotRequestMessage(content: string, subType: string) : ChatMessage
    {
        return {
            id: uuid(),
            role: "assistant",
            content: content,
            date: new Date().toISOString(),
            type: ChatMessageType.BotRequest,
            subType: subType
        };
    }

    private generateStepMessage(step: SoSmartAdvancedSupportStep) : ChatMessage | undefined {
        switch(step)
        {
            case SoSmartAdvancedSupportStep.QuestionLastError:
                return this.createBotRequestMessage("Phase 1 - Question last error", "questionLastError");
            case SoSmartAdvancedSupportStep.ConfirmTest:
                return this.createBotRequestMessage("Phase 2 - Confirm test", "confirmTest");
            case SoSmartAdvancedSupportStep.SupportRequest:
                return this.createBotRequestMessage("Phase 3 - Question", "supportRequest");
            case SoSmartAdvancedSupportStep.TicketQuestion:
                return this.createBotRequestMessage("Phase 4 - Ticket question", "ticketQuestion");
            case SoSmartAdvancedSupportStep.TicketCreated:
                return this.createBotRequestMessage("Phase 5 - Ticket created", "ticketCreated");
            case SoSmartAdvancedSupportStep.End:
                return this.createAssistantMessage(t("chatbot:openSupportRequest:feedbackMessage")!);
        }

        return undefined;
    }

    private composeQuestionFromSupportEntry = (module: BCNProcessModule, process: BCNProcess, test: BCNProcessTest, testOtherAction: boolean, processOtherAction: boolean, pageName: string, question: string, lastError: string): string => {
        let value: string = "";
        if (pageName)
            value += t("chatbot:openSupportRequest:page")! + " " + pageName + ",";

        if (! testOtherAction)
        {
            if (value)
                value += " " + t("chatbot:openSupportRequest:useActionLC")! + " " + test.description;
            else
                value += t("chatbot:openSupportRequest:useActionUC")! + " " + test.description;
        }

        if (! processOtherAction)
        {
            if (value)
                value += " " + t("chatbot:openSupportRequest:useProcessLC")! + " " + process.description;
            else
                value += t("chatbot:openSupportRequest:useProcessUC")! + "" + process.description;
        }

        if (value)
        {
            value +=  " " + t("chatbot:openSupportRequest:useModuleLC")! + " " + module.description;
        }
        else
            value += t("chatbot:openSupportRequest:useModuleUC")! + " " + module.description;

        if (question)
            value += ", " + t("chatbot:openSupportRequest:listQuestion")! + " " + question;
        else if (lastError)
            value += ", " + t("chatbot:openSupportRequest:listError")! + " " + lastError;

        return value;
    }

    private convertMessageSubTypeToStep(msg: ChatMessage) : SoSmartAdvancedSupportStep {
        switch (msg.subType)
        {
            case "questionLastError":
                return SoSmartAdvancedSupportStep.QuestionLastError;
            case "confirmTest":
                return SoSmartAdvancedSupportStep.ConfirmTest;
            case "supportRequest":
                return SoSmartAdvancedSupportStep.SupportRequest;
            case "confirmQuestion":
                return SoSmartAdvancedSupportStep.ConfirmQuestion;
            case "ticketQuestion":
                return SoSmartAdvancedSupportStep.TicketQuestion;
            case "ticketCreated":
                return SoSmartAdvancedSupportStep.TicketCreated;
        }

        throw new Error("Invalid message subtype");
    }

    private async getBCNProcessModules(): Promise<BCNProcessModule> {
        if (!this.supportEntry.processModule)
            return new BCNProcessModule();

        return (await this.performODataHttpRequest("/bcn/process/module?$filter=code eq '" + this.supportEntry.processModule + "'"))[0];
    }

    private async getBCNProcesses(): Promise<BCNProcess> {
        if (!this.supportEntry.processModule && !this.supportEntry.processCode)
            return new BCNProcess();

        let filter: string = "?filter=processModule eq '" + this.supportEntry.processModule + "' and processCode eq '" + this.supportEntry.processCode + "'";
        return (await this.performODataHttpRequest("/bcn/process" + filter))[0];
    }

    private async getBCNProcessTests(): Promise<BCNProcessTest> {
        if (!this.supportEntry.processModule && !this.supportEntry.processCode && !(this.supportEntry.processLineNo > 0))
            return new BCNProcessTest();
        
        let filter: string = "?filter=processModule eq '" + this.supportEntry.processModule + "' and processCode eq '" + this.supportEntry.processCode + "' and lineNo eq " + this.supportEntry.processLineNo;
        return (await this.performODataHttpRequest("/bcn/process/test" + filter))[0];
    }

    private async getSupportEntry(supportId: string): Promise<SupportEntry> {
        let data: SupportEntry[] = await this.performODataHttpRequest("/support/entry?filter=id eq " + supportId);
        return data[0];
    }

    private async performODataHttpRequest(path: string): Promise<any> {
        let token = await getSoSmartToken();
        const response = await fetch(odataBaseUrl + path, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token
            }
        });

        if (response.status === 200)
            return response.json();
        
        throw new Error("Cannot read data from server API " + odataBaseUrl + path);
    }
}

interface YesNoConfirmationProps {
    onSelectYesNo: (key: any, value: string) => void;
    question: string;
    subText?: string;
}

export const YesNoConfirmationForm: React.FC<YesNoConfirmationProps> = (props) => {
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const yesNoTypeOptions: IChoiceGroupOption[] = [
        {
            key: 'true',
            text: t("common:question:yes")!,
        },
        {
            key: 'false',
            text: t("common:question:no")!,
        }
    ]

    return (
        <>
            <Stack className={styles.answerContainer} tabIndex={0}>
                <Stack.Item grow>
                    <Stack className={styles.answerText} tokens={{
                        childrenGap: 20
                    }}>
                        <Stack.Item>
                            {props.question}
                        </Stack.Item>
                        {
                            props.subText ?
                            <Stack.Item>
                                {props.subText}
                            </Stack.Item>
                            : 
                            <></>
                        }
                        <Stack.Item>
                            <ChoiceGroup disabled={isDisabled} style={{
                                overflowY: 'hidden'
                            }} onChange={(ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) => {
                                ev?.preventDefault();

                                setIsDisabled(true);
                                props.onSelectYesNo(option?.value, option?.key!);
                            }}
                            options={yesNoTypeOptions}
                            >
                            </ChoiceGroup>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        </>
    );
};

interface LastErrorConfirmationProps {
    onSelectYesNo: (key: any, value: string) => void;
    question: string;
    subText?: string;
    printInformation?: boolean;
    supportEntry?: SupportEntry;
}

export const LastErrorConfirmationForm: React.FC<LastErrorConfirmationProps> = (props) => {
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const yesNoTypeOptions: IChoiceGroupOption[] = [
        {
            key: 'true',
            text: t("common:question:yes")!,
        },
        {
            key: 'false',
            text: t("common:question:no")!,
        }
    ]

    return (
        <>
            <Stack className={styles.answerContainer} tabIndex={0}>         
                <Stack.Item grow>
                    {
                        props.printInformation ?
                        <WelcomeMessageForm supportEntry={props.supportEntry!} />
                        :
                        <></>
                    }       
                    <Stack className={styles.answerText} tokens={{
                        childrenGap: 20
                    }}>
                        <Stack.Item>
                            {props.question}
                        </Stack.Item>
                        {
                            props.subText ?
                            <Stack.Item>
                                {props.subText}
                            </Stack.Item>
                            : 
                            <></>
                        }
                        <Stack.Item>
                            <ChoiceGroup disabled={isDisabled} style={{
                                overflowY: 'hidden'
                            }} onChange={(ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) => {
                                ev?.preventDefault();

                                setIsDisabled(true);
                                props.onSelectYesNo(option?.value, option?.key!);
                            }}
                            options={yesNoTypeOptions}
                            >
                            </ChoiceGroup>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        </>
    );
};

interface WelcomeMessageProps {
    supportEntry: SupportEntry;
}

export const WelcomeMessageForm: React.FC<WelcomeMessageProps> = (props) => {
    const [expanded, setExpanded] = useState(false);
    
    return (
        <>
            <Stack>
                <Stack.Item style={{
                    fontFamily: 'Nunito',
                    fontSize: 20,
                    fontWeight: 700,
                    padding: 5
                }}>
                    {
                        props.supportEntry.userFullName ? 
                        <label>Ciao {props.supportEntry.userFullName}</label>
                        :
                        <label>Benvenuto</label>
                    }
                </Stack.Item>
                <Stack.Item style={{
                    paddingLeft: 5,
                    paddingRight: 5
                }}>
                    <Stack key={"informationContainer"} style={{
                        marginTop: 20,
                        marginBottom: 20,
                        backgroundColor: '#2A7DE1',
                        borderRadius: 20,
                        paddingBottom: 10,
                        paddingLeft: 10,
                        paddingTop: 10

                    }}>
                        <Stack.Item>
                            <InformationRow id={0}>
                                <Stack horizontal styles={{ root: {
                                    fontFamily: 'Nunito',
                                    width: '100%',
                                    fontSize: 15,
                                    fontWeight: 700,
                                    paddingBottom: 10,
                                    paddingTop: 10,
                                    paddingLeft: 10,
                                    color: "white",
                                    textTransform: 'uppercase',
                                    selectors: {
                                        "&:hover": {
                                            color: '#fafafa',
                                            cursor: 'pointer'
                                        }
                                    }
                                }}}
                                onClick={() => {
                                    setExpanded(! expanded);
                                }}>
                                    {t("chatbot:openSupportRequest:sessionInformation")!}
                                    <Icon iconName={ expanded ? "ChevronUpSmall" : "ChevronDownSmall"} styles={{
                                        root: {
                                            paddingLeft: 12,
                                            fontSize: 12,
                                            paddingTop: 3,

                                        }
                                    }} />
                                </Stack>
                            </InformationRow>
                            {
                                expanded ?
                                <>
                                    <InformationRow id={1}>
                                        <InformationField label={t("chatbot:openSupportRequest:tenantId")!} value={props.supportEntry.tenantId} width={33} />  
                                        <InformationField label={t("chatbot:openSupportRequest:environment")!} value={props.supportEntry.environmentName} width={33} />  
                                        <InformationField label={t("chatbot:openSupportRequest:company")!} value={props.supportEntry.companyName} width={34 } />  
                                    </InformationRow>
                                    <InformationRow id={2}>
                                        <InformationField label={t("chatbot:openSupportRequest:pageInfo")!} value={props.supportEntry.pageName} width={33} />  
                                        <InformationField label={t("chatbot:openSupportRequest:license")!} value={props.supportEntry.license} width={66} />  
                                    </InformationRow>
                                </>
                                : 
                                <></>
                            }
                        </Stack.Item>
                    </Stack>
                </Stack.Item>                
            </Stack>
        </>
    );
}

export interface IInformationRow {
    id: number;
}

export const InformationRow: React.FC<IInformationRow> = (props) => {
    return (
        <Stack horizontal key={"informationRow" + props.id.toString()} 
            style={{
                display: 'flex',
                alignItems: "center",
                justifyContent: "start"
            }}
            tokens={{
            }}
            wrap>
                {props.children}
            </Stack>
    );
}

export interface IInformationFieldProps {
    label: string;
    value: string;
    width: number;
}

export const InformationField: React.FC<IInformationFieldProps> = (props) => {
    let itemStyle = mergeStyles([{
        padding: 3,
        paddingLeft: 10,
        minWidth: 'calc(' + props.width + '%)'
    }]);
    
    return (
        <Stack.Item 
            className={itemStyle}>
            <Stack 
                tokens={{
                    childrenGap: 5
                }} horizontal>
                    <Stack.Item style={{
                        display: 'flex',
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <div style={{
                        width: 3,
                        height: '100%',
                        backgroundColor: '#8ab6e6',
                    }}></div>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack>
                            <Stack.Item style={{
                                textTransform: "uppercase",
                                fontFamily: 'Nunito',
                                fontWeight: 800,
                                fontSize: 12,
                                color: 'white'
                            }}>
                                {props.label}
                            </Stack.Item>
                            <Stack.Item style={{
                                fontFamily: 'Nunito',
                                fontWeight: 400,
                                fontSize: 12,
                                color: 'white'
                            }}>
                                {
                                    props.value ?
                                    props.value
                                    :
                                    t("common:noInformation")!
                                }
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
    );
}

interface TestConfirmationProps {
    bcnProcessModule: BCNProcessModule;
    bcnProcess: BCNProcess;
    bcnProcessTest: BCNProcessTest;
    onConfirm: (process: BCNProcess, module: BCNProcessModule, test: BCNProcessTest, moduleOther: boolean, processOther: boolean, testOther: boolean) => void;
    printInformation?: boolean;
    supportEntry?: SupportEntry;
}

export const TestConfirmationForm: React.FC<TestConfirmationProps> = (props) => {
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [currentModule, setCurrentModule] = useState<BCNProcessModule>(props.bcnProcessModule);
    const [currentProcess, setCurrentProcess] = useState<BCNProcess>(props.bcnProcess);
    const [currentTest, setCurrentTest] = useState<BCNProcessTest>(props.bcnProcessTest);
    const [modules, setModules] = useState<BCNProcessModule[]>([]);
    const [processes, setProcesses] = useState<BCNProcess[]>([]);
    const [tests, setTests] = useState<BCNProcessTest[]>([]);
    const [moduleOtherAction, setModuleOtherAction] = useState<boolean>(false);
    const [processOtherAction, setProcessOtherAction] = useState<boolean>(false);
    const [testOtherAction, setTestOtherAction] = useState<boolean>(false);
    const [mandatoryModuleError, setMandatoryModuleError] = useState<string>("");

    const performODataHttpRequest = async (path: string): Promise<any> => {
        let token = await getSoSmartToken();
        const response = await fetch(odataBaseUrl + path, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token
            }
        });

        if (response.status === 200)
            return response.json();
        
        throw new Error("Cannot read data from server API " + odataBaseUrl + path);
    }

    const getModulesOptions = () : IComboBoxOption[] => {
        let resultArray: IComboBoxOption[] = [];
        modules.forEach(element => {
            resultArray.push({
                key: element.code,
                text: element.description
            });
        });

        if ((resultArray.length == 0) && (currentModule.code))
            resultArray.push({
                key: currentModule.code,
                text: currentModule.description
            });

        return resultArray;
    }

    const loadModules = async () => {
        setModules(await performODataHttpRequest("/bcn/process/module?$filter=enabled eq true"));
    }

    const getProcessesOptions = () : IComboBoxOption[] => {
        let resultArray: IComboBoxOption[] = [];
        processes.forEach(element => {
            resultArray.push({
                key: element.processCode,
                text: element.description
            });
        });

        if ((resultArray.length == 0) && (currentProcess.processCode))
            resultArray.push({
                key: currentProcess.processCode,
                text: currentProcess.description
            });

        return resultArray;
    }

    const loadProcesses = async () => {
        let newProcesses = await performODataHttpRequest("/bcn/process?$filter=processModule eq '" + currentModule.code + "' and soSmartEnabled eq true");
        let otherProcess :BCNProcess = {
            processCode: 'other',
            description: 'Altro',
            processModule: '',
            sequence: 0,
            soSmartEnabled: true
        };
        setProcesses([...newProcesses, otherProcess]);
    }

    const getTestsOptions = () : IComboBoxOption[] => {
        let resultArray: IComboBoxOption[] = [];
        tests.forEach(element => {
            resultArray.push({
                key: element.lineNo,
                text: element.description
            });
        });

        if ((resultArray.length == 0) && (currentTest.description))
            resultArray.push({
                key: currentTest.lineNo,
                text: currentTest.description
            });

        return resultArray;
    }

    const loadTests = async () => {
        let newTests = await performODataHttpRequest("/bcn/process/test?$filter=processModule eq '" + currentModule.code + "' and processCode eq '" + currentProcess.processCode + "' and enabled eq true");
        let otherTest = new BCNProcessTest();
        otherTest.lineNo = 0;
        otherTest.description = 'Altro';

        setTests([...newTests, otherTest]);
    }

    return (
        <>
            <Stack className={styles.answerContainer} tabIndex={0}>
                <Stack.Item grow>
                    {
                        props.printInformation ?
                        <WelcomeMessageForm supportEntry={props.supportEntry!} />
                        :
                        <></>
                    }   
                    <Stack className={styles.answerText} tokens={{
                        childrenGap: 10
                    }}>
                        <Stack.Item>
                            {t("chatbot:openSupportRequest:dataRequestText")!}
                        </Stack.Item>
                        <Stack.Item>
                            <ComboBox label={t("chatbot:openSupportRequest:area")!} selectedKey={currentModule.code} options={getModulesOptions()}
                            useComboBoxAsMenuWidth
                            onRenderLowerContent={() => {
                                if (modules.length == 0)
                                {
                                    loadModules();
                                    return (<Spinner label={t("common:loadingData")!} ariaLive="assertive" labelPosition="right" style={{
                                        padding: 10,
                                        width: '200px'
                                    }} />);
                                }
                                return <></>;
                            }}
                            errorMessage={mandatoryModuleError}
                            onChange={(e, opt, context) => {
                                let module: BCNProcessModule = new BCNProcessModule();
                                module.code = opt?.key.toString()!;
                                module.description = opt?.text.toString()!;

                                setCurrentModule(module);

                                let process = new BCNProcess();
                                setCurrentProcess(process);
                                setProcesses([]);

                                let test = new BCNProcessTest();
                                setCurrentTest(test);
                                setTests([]);
                                setMandatoryModuleError("");
                            }}
                            disabled={isDisabled}/>
                        </Stack.Item>
                        <Stack.Item>
                            <ComboBox label={t("chatbot:openSupportRequest:process")!} selectedKey={processOtherAction ? 'other' : currentProcess.processCode} options={getProcessesOptions()}
                                useComboBoxAsMenuWidth
                                onRenderLowerContent={() => {
                                    if (processes.length == 0)
                                    {
                                        loadProcesses();
                                        return (<Spinner label={t("common:loadingData")!} ariaLive="assertive" labelPosition="right" style={{
                                            padding: 10,
                                            width: '200px'
                                        }} />);
                                    }
                                    return <></>;
                                }}
                                onChange={(e, opt, context) => {
                                    if (opt?.key == 'other')
                                    {
                                        setCurrentProcess(new BCNProcess());
                                        setProcessOtherAction(true);
                                    }
                                    else
                                    {
                                        setProcessOtherAction(false);

                                        let process: BCNProcess = new BCNProcess();
                                        process.processCode = opt?.key.toString()!;
                                        process.description = opt?.text.toString()!;
                                        setCurrentProcess(process);
                                    }

                                    let test = new BCNProcessTest();
                                    setCurrentTest(test);
                                    setTests([]);
                                }}
                                disabled={currentModule.code == "" || isDisabled} />
                        </Stack.Item>
                        <Stack.Item>
                            <ComboBox label={t("chatbot:openSupportRequest:action")!} selectedKey={currentTest.lineNo} options={getTestsOptions()}
                                useComboBoxAsMenuWidth
                                onRenderLowerContent={() => {
                                    if (tests.length == 0)
                                    {
                                        loadTests();
                                        return (<Spinner label={t("common:loadingData")!} ariaLive="assertive" labelPosition="right" style={{
                                            padding: 10,
                                            width: '200px'
                                        }} />);
                                    }
                                    return <></>;
                                }}
                                onChange={(e, opt, context) => {
                                    let currentKey = Number(opt?.key);
                                    if (currentKey == 0)
                                    {
                                        setCurrentTest(new BCNProcessTest());
                                        setTestOtherAction(true);
                                    }
                                    else
                                    {
                                        let test: BCNProcessTest = new BCNProcessTest();
                                        test.lineNo = Number(opt?.key!);
                                        test.description = opt?.text.toString()!;

                                        setTestOtherAction(false);
                                        setCurrentTest(test);
                                    }
                                }}
                                disabled={processOtherAction || currentModule.code == "" || isDisabled} />
                        </Stack.Item>
                        <Stack.Item style={{
                            paddingTop: 15,
                        }}>
                            <DefaultButton text="Conferma" style={{
                                backgroundColor: '#2A7DE1',
                                borderRadius: 25,
                                borderWidth: 0,
                                fontFamily: 'Nunito',
                                color: 'white',
                                textTransform: 'uppercase',
                                padding: 18,
                                fontWeight: 500,
                                width: '100%'
                            }} 
                            onClick={() => {
                                setIsDisabled(true);
                                if (! currentModule.code)
                                {
                                    setIsDisabled(false);
                                    setMandatoryModuleError(t("chatbot:openSupportRequest:selectAreaError")!);
                                }
                                else
                                    props.onConfirm(currentProcess, currentModule, currentTest, moduleOtherAction, processOtherAction, currentTest.lineNo == 0);
                            }}
                            disabled={isDisabled}/>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        </>
    );
};

export const QuestionStep = ({
}: {}) => {
    return (
        <>
            <Stack className={styles.answerContainer} tabIndex={0}>
                <Stack.Item grow>
                    <Stack className={styles.answerText} tokens={{
                        childrenGap: 5
                    }}>
                        <Stack.Item>
                            {t("chatbot:questionText:title")!}
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        </>
    );
};