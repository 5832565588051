import { ContextualMenu, DefaultButton, Dialog, DialogContent, DialogFooter, DialogType, FontWeights, getTheme, IButtonStyles, IconButton, mergeStyles, mergeStyleSets, Modal, PrimaryButton, ResponsiveMode, Stack, Text } from "@fluentui/react";
import { t } from "i18next";
import React, { useState } from "react";
import { JsonToTable } from "react-json-to-table";
import { Row } from "../CommonPages/LeadSurveyList/LeadSurveyComponent";

//#region TableWrapper

export interface IJsonToTableProps {
    json: any;
}

export const CommonJsonToTable: React.FC<IJsonToTableProps> = (props) => {
    let elements:any[] = [];
    let columns:any[] = [];
    let ausColumns:any[] = [];

    const ContainerClassName = mergeStyles([{
        position: 'relative'
    }])

    const TableClassName = mergeStyles([{
        color: "black !important",
        'table, th, td': {
            borderCollapse: 'collapse !important',
            borderBottom: '1px solid #ddd !important'
            //border: '1px solid black !important'
        },
        'table':{
            border:"1px solid black !important",
            borderCollapse: 'collapse !important'
        },
        'td': {
            textAlign: 'center !important',
            verticalAlign: 'middle !important'
        },
        'tr:hover': {
            backgroundColor: '#ddd !important',
            color: 'black !important',
        },
        'th':{
            position: 'sticky',
            top: '0',
            paddingLeft: '15px !important',
            paddingRight: '15px !important',
            paddingTop: '0px !important',
            paddingBottom: '0px !important',
            backgroundColor: '#0078d4 !important',
            color: 'white !important'
        },
        'tbody':{
            overflowY: 'auto !important'
        }
        /*'tr:nth-child(even)': {
            borderBottom:'1px solid black !important',
            backgroundColor: '#ddd !important',
            color: 'black !important',
        },
        'tr:nth-child(odd)': {
            borderBottom:'1px solid black !important',
            backgroundColor: 'white !important',
            color: 'balack !important',
        },
        //'tr:nth-child(2)': {borderBottom: '1px solid #ddd !important'},
        'td':{
            borderTop: 'none !important',
            borderLeft: 'none !important',
            borderRight: 'none !important',
            paddingLeft: '20px !important',
            paddingRight: '20px !important',
            textAlign: 'left !important',
            borderBottom: '1px solid #ddd !important'
        },
        'thead > tr:first-child': {
            color: 'black !important',
            fontWeight:'bold !important',
            textDecoration:"underline !important"
        },
        'thead > tr > th':{
            paddingLeft: '20px !important',
            paddingRight: '20px !important',
        }*/
    }])

    const loopJsonObject = (jsonObject:any) => {
        for(let Key in jsonObject) {
            if(typeof jsonObject[Key] === 'object'){
                parseJsonObject(jsonObject[Key]);
                columns.push(ausColumns);
                ausColumns = [];
            }
            else{
                ausColumns.push(Key);
                columns.push(ausColumns);
                elements.push(jsonObject[Key]);
            }
        };
    }

    const findIndex = (key: string):boolean => {
        let index:number = 0;
        index = ausColumns.findIndex((value: any, index: number, obj: any[]) =>{
            return value === key;
        })

        return index > -1 ? true : false;
    }

    const parseJsonObject = (jsonObject:any) => {
        for(var key in jsonObject){
            if (typeof jsonObject[key] === 'object') {
                parseJsonObject(jsonObject[key]);
                elements.push(jsonObject[key]);
            } else{
                if(!findIndex(key))
                    ausColumns.push(key);
            }
        }
    }

    const createTableFromJson = (): JSX.Element => {
        let tColumns:JSX.Element = <></>;
        let tRows:JSX.Element[] = [];

        loopJsonObject(props.json);

        if(columns[0].length > 1)
            for(let i = 0; i < columns.length; i++) { //Questo dovrebbe essere giusto prende le colonne corrette 
                tColumns = 
                    <tr>
                        {columns[i].map((value: any, index: number, obj: any[]) => {return <th>{value}</th>})}
                    </tr>
            }
        else
            tColumns = 
                <tr>
                    <th>
                        {columns[0]}
                    </th>
                </tr>
        
        if(elements.length > 1)
            for(let i = 0; i < elements.length; i++) {//Qui ce qualche errore
                let td:JSX.Element[] = [];

                Object.values(elements[i]).forEach((value:any,index:any,array:any[])=>{td.push(<td>{value}</td>)})

                tRows.push(<tr>{td}</tr>)
            }
        else
            tRows.push(<tr><td>{elements[0]}</td></tr>)

        return (
            <table cellSpacing={0} className={TableClassName}>
                <thead>
                    {tColumns}
                </thead>
                <tbody>
                    {tRows}
                </tbody>
            </table>
        )
    }

    return (
        <Stack className={ContainerClassName}>
            {createTableFromJson()}
        </Stack>
    )
}

//#endregion

//#region TableHeader

export const TableHeader: React.FC<{}> = (props) => {

    const WrapperClassName = mergeStyles([{
        border:"1px solid black",
        width:"100%",
        height:"auto",
        overflow:"auto"
    }])

    return (
        <Stack className={WrapperClassName}>
            {props.children}
        </Stack>
    )
}

//#endregion

//#region TableRow

export const TableRow: React.FC<{}> = (props) => {

    const WrapperClassName = mergeStyles([{
        border:"1px solid black",
        width:"100%",
        height:"auto",
        overflow:"auto"
    }])

    return (
        <Stack className={WrapperClassName}>
            {props.children}
            
        </Stack>
    )
}

//#endregion


/*
  Object.keys(this.state.birth_details).map(function (element) {
    return (
      <tr key={element}>
        <td>{element}</td>
        <td>{this.state.birth_details[element]}</td>
      </tr>
    );
  })

  */