import React, { useContext, useEffect, useRef, useState } from "react";
import { useForceUpdate } from "@fluentui/react-hooks";
import { useNavigate } from "react-router-dom";
import PageContainer from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { SystemActionCategory, SystemDataLoadingStatus, SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import { AdminCustomerListDialog } from "../../AdminCustomerListDialog/AdminCustomerListDialog";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { IComboBoxOption } from "@fluentui/react";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { SystemCore } from "../../../../Core/System/SystemCore";
import { Loader } from "../../../Common/Loader/Loader";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { t } from "i18next";

export const AdminSalesHeaderList : React.FC = () => {    
    const navigate = useNavigate();
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [loaderMessage, setLoaderMessage] = useState("");
    const [customerCmbOptions, setCustomerCmbOptions] = useState<IComboBoxOption[]>([]);
    const [showCustomerDialog, setShowCustomerDialog] = useState(false);
    const [dialogError, setDialogError] = useState("");
    const [templateId, setTemplateId] = useState(0);
    
    const adminService = new AdminService();

    useEffect(() => {
        if(dataStatus === SystemDataLoadingStatus.ToLoad){
            buildCustomerCmbOptions();
        }
    }, [dataStatus])

    const buildCustomerCmbOptions = ()=>{
        setLoaderMessage(t("adminCenter:salesHeaderList:loaderText")!);
        setDataStatus(SystemDataLoadingStatus.Loading);
        
        adminService.getCustomers().then((resp)=>{
            let options : IComboBoxOption[] = [];
            resp.forEach((item:any)=>{
                options.push({key: item.id, text: item.companyName});
            })
            setCustomerCmbOptions(options);
            setLoaderMessage("");
            setDataStatus(SystemDataLoadingStatus.Loaded);
        })
        .catch(()=>{
            setLoaderMessage("");
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Error, t("adminCenter:salesHeaderList:text0001Err")!);
        })
    }
    
    const createCustomerSubscriptionFromTemplate = (customerId: number, customerName: string, templateId: number) => {
        setDialogError("");
        if (templateId !== undefined && templateId > 0){
            if (customerId !== undefined && customerId > 0){
                setLoaderMessage(t("adminCenter:salesHeaderList:text0001Err", {customerName: customerName})!);
                setDataStatus(SystemDataLoadingStatus.Loading);
                
                adminService.createCustomerSubscriptionFromTemplate(customerId, templateId)
                .then(() => {
                    setDataStatus(SystemDataLoadingStatus.Loaded);
                    setLoaderMessage("");
                    setShowCustomerDialog(false);
                    
                    ToastService.showMessage(MessageType.Success, t("adminCenter:salesHeaderList:text0002Msg")!);
                })
                .catch((err) => {
                    setDataStatus(SystemDataLoadingStatus.Loaded);
                    setLoaderMessage("");
                    setShowCustomerDialog(false);
                    ToastService.showMessage(MessageType.Error, t("adminCenter:salesHeaderList:text0002Err")! + err.message);
                })
            }
            else
                setDialogError(t("adminCenter:salesHeaderList:text0003Err")!);
        }
        else
            ToastService.showMessage(MessageType.Error, t("adminCenter:salesHeaderList:text0004Err")!);
    }

    const buildEndpoint = (): string => {
        let baseEndpoint = "/odata/SalesHeaderTemplate";
        const templateCode: string = HttpHelper.getParameter("saleHeaderCode");
        if((templateCode !== null) && (templateCode !== ""))
            baseEndpoint += "?$filter=code eq '"+templateCode+"'";

        return baseEndpoint;
    }

    if (!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={loaderMessage}></Loader>;
    else
        return (
            <>
                <PageContainer endpoint={buildEndpoint()} pageType={SystemPageType.List} title={t("adminCenter:salesHeaderList:title")!} backAllowed>               
                    <PageDefinitionContainer type={ContainerType.Action}>
                        <ActionArea category={SystemActionCategory.Navigation}>
                            <ActionEntry name="OpenLineTemplateList" label={t("adminCenter:salesHeaderList:actions:openLineTemplateList")!} iconName="ContactLink" runOnRec isPromoted onClick={(req:any)=>{
                                    navigate('/app/admin/administrative/salesLine?Id='+req.id);
                                }}>
                            </ActionEntry>
                            <ActionEntry name="SystemCaption" label={t("adminCenter:salesHeaderList:actions:systemCaption")!} iconName="Globe2" runOnRec onClick={(rec:any)=>{
                                navigate('/app/admin/related/systemCaption?sourceId='+rec.systemId+"&sourceEntity=SalesHeaderTemplate")
                            }}>
                            </ActionEntry>
                        </ActionArea>
                        <ActionArea category={SystemActionCategory.Process}>
                            <ActionEntry name="createCustomerSubscriptionFromTemplate" label={t("adminCenter:salesHeaderList:actions:createCustomerSubscriptionFromTemplate")!} iconName="SaveTemplate" runOnRec isPromoted onClick={(req: any) => {
                                setDialogError("");
                                setTemplateId(req.id);
                                setShowCustomerDialog(true);
                            }}></ActionEntry>   
                        </ActionArea>
                    </PageDefinitionContainer>                   
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry name={t("adminCenter:salesHeaderList:fields:id")} fieldName="id" minWidth={50} maxWidth={100} />
                        <ListHeaderEntry name={t("adminCenter:salesHeaderList:fields:createdAt")} fieldName="createdAt" minWidth={200} maxWidth={250} />
                        <ListHeaderEntry name={t("adminCenter:salesHeaderList:fields:expirationDate")} fieldName="expirationDate" minWidth={200} maxWidth={250} />
                        <ListHeaderEntry name={t("adminCenter:salesHeaderList:fields:source")} fieldName="source" minWidth={100} maxWidth={200} onRender={(item: any, index, column)=>{
                            if (item.source === 0)
                                return "Web"
                            else if (item.source === 1)
                                return "Industry"
                            else if (item.source === 2)
                                return "Qualifica"
                        }} />
                        <ListHeaderEntry name={t("adminCenter:salesHeaderList:fields:code")} fieldName="code" minWidth={250} maxWidth={300} />
                        <ListHeaderEntry name={t("adminCenter:salesHeaderList:fields:description")} fieldName="description" minWidth={350} maxWidth={400} />
                        <ListHeaderEntry name={t("adminCenter:salesHeaderList:fields:budgetPerFullUser")} fieldName="budgetPerFullUser" minWidth={200} maxWidth={250} />
                        <ListHeaderEntry name={t("adminCenter:salesHeaderList:fields:budgetPerLimitedUser")} fieldName="budgetPerLimitedUser" minWidth={200} maxWidth={250} />
                    </PageDefinitionContainer>
                </PageContainer>
                <AdminCustomerListDialog hidden={showCustomerDialog}
                    title={t("adminCenter:salesHeaderList:dialogTitle")}
                    subText={t("adminCenter:salesHeaderList:dialogSubtext")}
                    txtLabel={t("adminCenter:salesHeaderList:dialogTxtLabel")}
                    cmbOptions={customerCmbOptions}
                    fieldType={SystemFieldType.Option}
                    onSuccess={(customerId: number, customerName: string)=>{createCustomerSubscriptionFromTemplate(customerId, customerName, templateId)}}
                    onDismiss={()=>{setShowCustomerDialog(false)}}
                    errorMessage={dialogError} />
            </>
        );
}