import { forwardRef } from "react";
import { SystemPageType } from "../../../../Model/SystemModels";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CustomerQueuedStatusStructure } from "../AdminCustomerQueuedStatusList/AdminCustomerQueuedStatusList";

export interface CustomerQueuedOperationCardProps {
    structure: CustomerQueuedStatusStructure[];
}

export const AdminCustomerQueuedStatusCard = forwardRef<PageContainerBase, React.PropsWithChildren<CustomerQueuedOperationCardProps>>((props, ref) => {

    function getStructure(): JSX.Element[] {
        let currentStructure: JSX.Element[] = [];

        props.structure.forEach((element: CustomerQueuedStatusStructure) => {
            currentStructure.push(
                <CardField 
                    label={element.displayName}
                    name={element.fieldName}
                    type={element.fieldType}
                    readonly={element.readonly}
                    cmbOptions={element.cmbOptions}
                />
            );
        });

        return currentStructure;
    }

    return (
        <PageContainer
            ref={ref}
            endpoint="/odata/customer/queued/status"
            pageType={SystemPageType.Card}
            title={"Scheda stato coda cliente"}
        >
            <CardGroup
                isOpen
                label="Generale"
                name="general"
            >
                {getStructure()}
            </CardGroup>
        </PageContainer>
    );
});