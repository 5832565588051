import { NavigateFunction, NavigateOptions } from "react-router-dom";
import { CryptoHelper } from "../../Core/Crypto/CryptoHelper";
import { MessageType, ToastService } from "../../Core/Toast/ToastService";
import { SystemBookmark } from "../../Model/SystemModels";

export class BookmarkManagement {

    private navigate: NavigateFunction;

    constructor(_navigate: NavigateFunction) {
        this.navigate = _navigate;
    }

    public setCurrentBookmark(paramName:string,value: any) 
    {    
        let urlParams = new URLSearchParams(window.location.search);

        if (value === undefined){
            urlParams.delete(paramName);
            this.navigate(this.deleteBookmarkValue(urlParams), { replace: true });
        }
        else
            this.navigate(this.buildBookmarkValue(urlParams,value), {replace: true});
    }

    public getCurrentBookmark(bookmark?: string) : string | undefined {
        if (bookmark === undefined)
            return undefined;
        else
        {
            const decryptedValue = atob(bookmark);
            if (decryptedValue === undefined)
                return undefined;

            return decryptedValue;
        }
    }

    public setVideoParameters(videoContent?: string,description?:string) {
        let urlParams = new URLSearchParams(window.location.search);

        if (videoContent === undefined || description === undefined)
            this.deleteVideoParametersValue(urlParams);
        else{
            urlParams.delete('videoContent');
            urlParams.delete('description');
            let forewardedParams = "";
            if(urlParams.toString() !== "")
                forewardedParams = "&"+urlParams.toString();
            else
                forewardedParams = "";
            this.navigate('?videoContent='+encodeURIComponent(btoa(videoContent))+"&description="+description+forewardedParams, {replace: true});
        }
    }

    private buildBookmarkValue(urlParams:URLSearchParams,value:string) : string {
        let forewardedParams = "";
        urlParams.delete('bookmark');

        if(urlParams.toString() !== "")
            forewardedParams = "&"+urlParams.toString();
        else
            forewardedParams = "";
        
        const encryptedValue = btoa(value);

        return "?bookmark="+encryptedValue+forewardedParams;
    }

    private deleteBookmarkValue(urlParams:URLSearchParams):string {
        urlParams.delete('bookmark');
        return "?"+urlParams.toString();
    }

    private deleteVideoParametersValue(urlParams:URLSearchParams){
        urlParams.delete('videoContent');
        urlParams.delete('description');
        this.navigate('?'+urlParams.toString(), { replace: true });
    }
}