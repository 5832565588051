import { ReactText } from "react";
import { toast, ToastContent, ToastOptions, ToastPromiseParams, UpdateOptions } from "react-toastify";

export enum MessageType {
    Error,
    Information,
    Warning,
    Success,
    Promise,
    Pending
}

export class ToastService {

    public static showMessage(type: MessageType, content: ToastContent, id?: string | undefined) {

        switch (type) {
            case MessageType.Error:
                toast.error(content, { toastId: id });
                break;
            case MessageType.Information:
                toast.info(content, { toastId: id });
                break;
            case MessageType.Warning:
                toast.warn(content, { toastId: id });
                break;
            case MessageType.Success:
                toast.success(content, { toastId: id });
                break;
            case MessageType.Pending:
                toast.info(content, { isLoading: true, toastId: id });
                break;
        }
    }

    public static update(id: ReactText, options: UpdateOptions) {
        toast.update(id, options);
    }

    public static close(id: ReactText) {
        toast.dismiss(id);
    }

    public static showPromiseMessage(promise:Promise<any>, pending:string, success:string, error:string, successCallback?: (resp: any) => void, errorCallback?: (err:any) => void){
        let options: ToastPromiseParams = {};
        if(pending !== "")
            options.pending = pending;
        if(error !== "")
            options.error = error;
        if(success !== "")
            options.success = success;

        if (!successCallback && !errorCallback)
            toast.promise(promise, options);

        if (successCallback && !errorCallback)
            toast.promise(promise.then((resp) => successCallback(resp)), options);

        if (successCallback && errorCallback)
            toast.promise(promise.then((resp) => successCallback!(resp)).catch((err) => errorCallback!(err)), options);
    }
}