import { Icon, Spinner, SpinnerSize } from "@fluentui/react";
import { Label } from "@fluentui/react/lib/Label";
import React from "react";

export interface ILoaderProps {
    text?: string;
}

export const Loader: React.FC<ILoaderProps> = (props: ILoaderProps) => {
    return (
        <div className="loader" style={
            {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100vh'
            }
        }>
            <div>
                <Spinner label={props.text}
                         size={SpinnerSize.large} 
                         styles={{
                             label: {
                                 fontSize: 13
                             },
                             circle: {
                                 width: 70,
                                 height: 70,
                                 borderWidth: 2.5,
                                 marginBottom: 10
                             }
                         }}/>
            </div>
        </div>
    );
}