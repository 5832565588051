import React, { useRef } from "react";
import { SystemOperation, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { AdminBusinessLineTagCard } from "../AdminBusinessLineTagCard/AdminBusinessLineTagCard";

export const AdminBusinessLineTagList : React.FC = () => {
    const parentBusinessLineCode = HttpHelper.getParameter("businessLineCode");
    const cardRef = useRef<PageContainerBase>(null);
    
    const setParentParameters = () => {
        cardRef.current?.setRecord({
            businessLineCode: parentBusinessLineCode
        });
        
        cardRef.current?.openPage(SystemOperation.Insert);
    }

    const buildEndpoint = () => {
        let endpoint = "/odata/business/line/tag";
        if (parentBusinessLineCode)
            endpoint += "?filter=businessLineCode eq '"+parentBusinessLineCode+"'"

        return endpoint;
    }
    
    return (
        <>
            <PageContainer onNewRecord={setParentParameters} endpoint={buildEndpoint()} pageType={SystemPageType.List} title="Lista business line tag" backAllowed insertAllowed updateAllowed deleteAllowed cardRef={cardRef}>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry fieldName="businessLineCode" name="Codice business line" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry fieldName="tagName" name="Nome tag" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry fieldName="tagValue" name="Valore tag" minWidth={300} maxWidth={400} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminBusinessLineTagCard ref={cardRef} />
        </>
    );
}