import { SetStateAction } from "react";
import { ChatMessage, ChatMessageType, Conversation, CrmIncidentType, ISupportFlow, SupportUserInput } from "../../../../ApplicationCode/Common/Chatbot/api/models";
import { IAppStateContext } from "../../../../Core/State/ChatbotAppProvider";
import { t } from "i18next";
import uuid from "react-uuid";
import { SuccessForm, SupportRequestTextForm, SupportTypeForm } from "./EosAppSupport";
import { Answer } from "./Answer";
import { crmBaseUrl, getSoSmartToken, odataBaseUrl } from "../../../../ApplicationCode/Common/Chatbot/api";
import { Item } from "../../../../Model/PartnerModels";

enum SoSmartSimpleSupportStep {
    Email,
    SupportType,
    SupportRequest,
    End
}

export class SoSmartSimpleTicket {
    public email: string;
    public incidentType: number;
    public title: string;
    public description: string;

    constructor()
    {
        this.email = "";
        this.incidentType = 0;
        this.title = "";
        this.description = "";
    }
}

export class SoSmartSimpleSupportFlow implements ISupportFlow {
    
    supportRequest: SoSmartSimpleTicket;
    supportTypeOptions: CrmIncidentType[] = [];

    constructor()
    {
        this.supportRequest = new SoSmartSimpleTicket();    
    }

    setApp(item: Item): void {
    }

    isErrorHandled(stepId: number): boolean {
        return false;
    }
    
    isFlowCompleted(stepId: number): boolean {
        let currentStep: SoSmartSimpleSupportStep = stepId;
        return (currentStep === SoSmartSimpleSupportStep.End);
    }

    handleFastActions(stepId: number, onClickHandler: (key: any, value: any) => void): JSX.Element | undefined {
        return undefined;
    }

    showUserMessage(stepId: number): boolean {
        return true;
    }

    handleElement(msg: ChatMessage, onUserInput: (key: any, value: any) => void): JSX.Element {
        let supportStep: SoSmartSimpleSupportStep;

        try {
            supportStep = this.convertMessageSubTypeToStep(msg);
        }
        catch {
            return <></>;
        }        
        
        switch (supportStep)
        {
            case SoSmartSimpleSupportStep.Email:
                return <Answer answer={{
                    answer: msg.content.toString(),
                    citations: [],
                }}
                onCitationClicked={() => {}}
                onOpenSupportRequestClick={() => {}}
                isLastIteraction={false}
            />
            case SoSmartSimpleSupportStep.SupportType:
                return <SupportTypeForm supportTypeOptions={this.supportTypeOptions} onSelectSupportType={(value, id) => {
                    onUserInput(value, Number(id));
                }} />
            case SoSmartSimpleSupportStep.SupportRequest: 
                return <SupportRequestTextForm />
            case SoSmartSimpleSupportStep.End:
                return <SuccessForm />
        }
    }

    async loadRequiredData(): Promise<void> {
        this.supportTypeOptions = await this.getSupportTypes();
    }
    
    initializeStartStep(): number {
        return -1;
    }
    
    async handleStep(app: Item, stepId: number, userInput: SupportUserInput | undefined, appStateContext: IAppStateContext, conversation: Conversation, setMessages: (value: SetStateAction<ChatMessage[]>) => void): Promise<number> {
        let currentStep: SoSmartSimpleSupportStep = stepId;
        
        switch (currentStep)
        {
            case SoSmartSimpleSupportStep.Email:
                let result: boolean = this.validateEmail(userInput!.value);
                if (! result)
                {
                    let message = t("chatbot:chat:assistantEmailError",{email: userInput!.value});
                    throw new Error(message);
                }

                this.supportRequest.email = userInput!.value;
                break;
            case SoSmartSimpleSupportStep.SupportType:
                this.supportRequest.incidentType = userInput!.value;
                break;
            case SoSmartSimpleSupportStep.SupportRequest:
                this.supportRequest.title = userInput!.value;
                this.supportRequest.description = userInput!.value;
                await this.createTicket();
                break;
            case SoSmartSimpleSupportStep.End:
                break;
        }

        conversation.messages.push(this.generateStepMessage(currentStep + 1));
        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
        setMessages(conversation.messages);
        return 1;
    }

    private createBotRequestMessage(content: string, subType: string) : ChatMessage
    {
        return {
            id: uuid(),
            role: "assistant",
            content: content,
            date: new Date().toISOString(),
            type: ChatMessageType.BotRequest,
            subType: subType
        };
    }

    private generateStepMessage(step: SoSmartSimpleSupportStep) : ChatMessage {
        switch(step)
        {
            case SoSmartSimpleSupportStep.Email:
                return this.createBotRequestMessage(t("chatbot:chat:assistantEmailMessage"), "email");
            case SoSmartSimpleSupportStep.SupportType:
                return this.createBotRequestMessage("Phase 1 - Support type", "supportType");
            case SoSmartSimpleSupportStep.SupportRequest: 
                return this.createBotRequestMessage("Phase 2 - Support request", "supportRequest");
            case SoSmartSimpleSupportStep.End:
                return this.createBotRequestMessage("Phase 3 - Success", "success");
        }
    }

    private convertMessageSubTypeToStep(msg: ChatMessage) : SoSmartSimpleSupportStep {
        switch (msg.subType)
        {
            case "email":
                return SoSmartSimpleSupportStep.Email;
            case "supportType":
                return SoSmartSimpleSupportStep.SupportType;
            case "supportRequest":
                return SoSmartSimpleSupportStep.SupportRequest;
            case "success":
                return SoSmartSimpleSupportStep.End;
        }

        throw new Error("Invalid message subtype");
    }

    private async getSupportTypes(): Promise<CrmIncidentType[]> {
        return await this.performODataHttpRequest("/crm/incident/type");
    }

    private async performODataHttpRequest(path: string): Promise<any> {
        let token = await getSoSmartToken();
        const response = await fetch(odataBaseUrl + path, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token
            }
        });

        if (response.status == 200)
            return response.json();
        
        throw new Error("Cannot read data from server API " + odataBaseUrl + path);
    }

    private async createTicket(): Promise<boolean>  {
        try {
            let token = await getSoSmartToken();
            const response = await fetch(crmBaseUrl + "/incident/chatbot/create", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
                body: JSON.stringify(this.supportRequest)
            });

            if (!(response.status === 200))
                throw new Error();

            return true;
        }   
        catch
        {
            throw new Error("Mi scuso ma non sono riuscito a creare la richiesta di supporto. Attendi qualche minuto e riprova.");
        }        
    }

    private validateEmail(value: string) : boolean {
        let isValid = value.toLocaleLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        if (! isValid)
            return false;
        return true;
    }    
}