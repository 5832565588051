import { useRef } from "react";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { SystemOperation, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminItemProductSkuIdCard } from "../AdminItemProductSkuIdCard/AdminItemProductSkuIdCard";

export const AdminItemProductSkuIdList : React.FC = () => {
    const currentItemId = HttpHelper.getParameter("itemId");
    const cardRef = useRef<PageContainerBase>(null);
    
    const buildListEndpoint = () :string => {
        let endpoint = "/odata/item/product/sku/id";

        if((currentItemId !== null) && (currentItemId !== "") && (currentItemId > 0))
            endpoint += "?$filter=itemId eq " + currentItemId;

        return endpoint
    }

    const setParentParameters = ()=>{
        cardRef.current?.setRecord({
            itemId: currentItemId
        });
        
        cardRef.current?.openPage(SystemOperation.Insert);
    }

    return (
        <>
            <PageContainer onNewRecord={setParentParameters} endpoint={buildListEndpoint()} pageType={SystemPageType.List} title={"Product Sku Id articoli"} cardRef={cardRef} backAllowed insertAllowed deleteAllowed>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Id Articolo" fieldName="itemId" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Product Sku Id" fieldName="productSkuId" minWidth={300} maxWidth={200} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminItemProductSkuIdCard ref={cardRef} />
        </>
    );
}