import React from "react";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";

export const AdminSalesLineList : React.FC = () => {
    let Id=HttpHelper.getParameter('Id');

    return (
        <>
            <PageContainer endpoint={"odata/SalesLineTemplate?$filter=salesHeaderTemplateId eq "+Id} pageType={SystemPageType.List} title="Dettaglio ordine" backAllowed>               
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Action}>
                    </ActionArea>
                </PageDefinitionContainer>            
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Id" fieldName="id" minWidth={50} maxWidth={100} />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={300} maxWidth={350} />
                    <ListHeaderEntry name="Note" fieldName="notes" minWidth={300} maxWidth={350} />
                    <ListHeaderEntry name="Codice Articolo Ecommerce" fieldName="itemId" minWidth={250} maxWidth={300} />
                    <ListHeaderEntry name="Id Articolo" fieldName="eolItemId" minWidth={100} maxWidth={150} />
                    <ListHeaderEntry name="Descrizione Articolo" fieldName="eolItemDescription" minWidth={500} maxWidth={600} />
                    <ListHeaderEntry name="Quantità" fieldName="quantity" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
        </>
    );
}