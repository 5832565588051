import React, { useEffect, useState} from "react";
import { ComboBox, DefaultButton, Dialog, DialogFooter, DialogType, IComboBoxOption, PrimaryButton, TextField } from "@fluentui/react";
import { SystemDataLoadingStatus, SystemFieldType } from "../../../Model/SystemModels";

export interface ICustomerListDialogProps {
    hidden:boolean;
    title:string;
    subText:string;
    txtLabel:string;
    errorMessage?:string;
    fieldType:SystemFieldType;
    cmbOptions?:IComboBoxOption[];
    onSuccess: (customerId:number, customerName: string)=>void;
    onDismiss: ()=>void;
}

export const AdminCustomerListDialog : React.FC<ICustomerListDialogProps> = (props:ICustomerListDialogProps) => {
    const [dataStatus,setDataStatus] = useState<SystemDataLoadingStatus>(SystemDataLoadingStatus.ToLoad);
    const [inputField, setInputField] = useState<JSX.Element>(<></>);
    const [value, setValue] = useState<any>("");
    const [text, setText] = useState<any>("");

    useEffect(()=>{
        if(dataStatus === SystemDataLoadingStatus.ToLoad){
            switch(props.fieldType){
                case SystemFieldType.Blob:
                    break;
                case SystemFieldType.Boolean:
                    break;
                case SystemFieldType.Date:
                    break;
                case SystemFieldType.Number:
                    break;
                case SystemFieldType.Option:
                    setInputField(
                        <ComboBox options={props.cmbOptions!} label={props.txtLabel} onChange={(ev,opt,index)=>{setValue(opt?.key!); setText(opt?.text)}} required errorMessage={props.errorMessage} />
                    )
                    break;
                case SystemFieldType.String:
                    setInputField(<TextField label={props.txtLabel} onChange={(e,val)=>{setValue(val!)}} required errorMessage={props.errorMessage}/>)
                    break;
                case SystemFieldType.TimeSpan:
                    break;
                default:
                    setInputField(<TextField label={props.txtLabel} onChange={(e,val)=>{setValue(val!)}} required errorMessage={props.errorMessage}/>)
                    break;
            }
            setDataStatus(SystemDataLoadingStatus.Loaded);
        }
    },[dataStatus])

    const dismiss = () =>{
        setValue("");
        setText("");
        props.onDismiss()
    }

    return (
        <>
            <Dialog
                hidden={!props.hidden}
                onDismiss={dismiss}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title:props.title,
                    subText:props.subText
                }}>
                {inputField}
                <DialogFooter>
                    <PrimaryButton onClick={()=>props.onSuccess(value, text)} text="Crea" />
                    <DefaultButton onClick={dismiss} text="Cancella" />
                </DialogFooter>
            </Dialog>
        </>
    );
}