import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminBusinessCentralSetupCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda Business Central setup"} endpoint="odata/businesscentral/setup" ref={ref}>
            <CardGroup name="setup" label="Configurazione" isOpen={true}>
                <CardField name="primaryKey" type={SystemFieldType.String} label="Chiave primaria" />
                <CardField name="appName" type={SystemFieldType.String} label="Nome app" />
                <CardField name="grantType" type={SystemFieldType.String} label="Grant type" />
                <CardField name="clientId" type={SystemFieldType.String} label="Id client" />
                <CardField name="clientSecret" type={SystemFieldType.String} label="Secret" />
                <CardField name="scope" type={SystemFieldType.String} label="Scope" />
                <CardField name="authCode" type={SystemFieldType.String} label="Auth Code" />
                <CardField name="automationRedirectUrl" type={SystemFieldType.String} label="Automation Redirect Url" />
                <CardField name="AutomationGrantType" type={SystemFieldType.String} label="Automation Grant Type" />
                <CardField name="automationClientId" type={SystemFieldType.String} label="Automation Client Id" />
                <CardField name="automationClientSecret" type={SystemFieldType.String} label="Automation Client Secret" />
                <CardField name="automationScope" type={SystemFieldType.String} label="AutomationScope" />
                <CardField name="baseAutomationEndpoint" type={SystemFieldType.String} label="Base Automation Endpoint" />
                <CardField name="automationTemplateCompanyName" type={SystemFieldType.String} label="Automation Template Company Name" />
                <CardField name="environmentPollingWaitTime" type={SystemFieldType.Number} label="Environment Polling Wait Time" />
                <CardField name="baseDemoEndpoint" type={SystemFieldType.String} label="Base Demo Endpoint" />
                <CardField name="demoTenantId" type={SystemFieldType.String} label="Demo Tenant Id" />
                <CardField name="demoEnvironmentName" type={SystemFieldType.String} label="Demo Environment Name" />
                <CardField name="demoCompanyName" type={SystemFieldType.String} label="Demo Company Name" />
                <CardField name="demoCompanyId" type={SystemFieldType.String} label="Demo Company Id" />
                <CardField name="adminNotificationName" type={SystemFieldType.String} label="Nome notifiche admin" />
                <CardField name="adminNotificationEmail" type={SystemFieldType.String} label="Email notifiche admin" />
            </CardGroup>
            <CardGroup name="customerUpdate" label="Aggiornamento clienti" isOpen={true}>
                <CardField name="scheduleCustomerUpdateOnInsertDSYVersion" type={SystemFieldType.Boolean} label="Schedula aggiornamento clienti all'inserimento versione DSY" />
                <CardField name="operationQueuesDisabled" type={SystemFieldType.Boolean} label="Code aggiornamento clienti disabilitate" />
                <CardField name="customerUpdateMinHour" type={SystemFieldType.Number} label="Ora minima per l'aggiornamento" />
                <CardField name="customerUpdateContemporaryRecords" type={SystemFieldType.Number} label="Clienti contemporanei" />
                <CardField name="customerUpdateDelaySeconds" type={SystemFieldType.Number} label="Ritardo aggiornamento (in secondi)" />
                <CardField name="environmentUpgradeDelayDays" type={SystemFieldType.Number} label="Ritardo aggiornamento ambienti (in giorni)" />
            </CardGroup>
            <CardGroup name="customerNotification" label="Notifiche clienti" isOpen={true}>
                <CardField name="customerNotificationSetupType" type={SystemFieldType.Option} label="Tipo setup" cmbOptions={[
                    {key: 0, text: "Email"},
                    {key: 1, text: "Chat"}
                ]} />
                <CardField name="customerNotificationSetupCode" type={SystemFieldType.String} label="Codice setup"></CardField>
                <CardField name="customerNotificationTemplateCode" type={SystemFieldType.String} label="Codice template"></CardField>
                <CardField name="customerNotificationGroupCode" type={SystemFieldType.String} label="Codice gruppo"></CardField>
            </CardGroup>
        </PageContainer>
    );
});